import { useRecoilState, useRecoilValueLoadable } from 'recoil';

import { IconFps } from '@assets/icons';

import InputSelect from '@components/controls/InputSelect';

import { fpsAtom, frameRatesSelector } from '@store/Output';

const DEFAULT_FPS = 25;

function FrameRateSelect() {
  const [fps, setFps] = useRecoilState(fpsAtom);
  const { state, contents } = useRecoilValueLoadable(frameRatesSelector);
  const frameRates = state === 'hasValue' ? contents : [];

  const handleChange = (event) => {
    const { value } = event.target;
    setFps(value.length > 0 ? parseFloat(value) : DEFAULT_FPS);
  };

  return (
    <InputSelect
      id="frame-rate"
      title="Frame Rate"
      selected={fps || DEFAULT_FPS}
      values={frameRates}
      prefix={<IconFps size={18} />}
      handleChange={handleChange}
    />
  );
}

export default FrameRateSelect;
