import cn from 'classnames';
import { memo, useCallback, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';

import {
  IconAudio,
  IconCaptions,
  IconImage,
  IconMagicWand,
  IconMask,
  IconOverlay,
  IconSoundEffect,
  IconVideo,
} from '@assets/icons';

import useCaptureFrames from '@hooks/useCaptureFrames';
import useGenerateThumbnail from '@hooks/useGenerateThumbnail';

import getFileTypeFromUrl from '@utils/getFileTypeFromUrl';

const videoIconMap = {
  Image: IconImage,
  Video: IconVideo,
  Mask: IconMask,
  Overlay: IconOverlay,
};

const AudioPreview = ({}) => {
  return (
    <div className="media-listing__preview audio">
      <div className="media-listing__tag">
        <span className="media-listing__tag-icon">{<IconAudio size={12} />}</span>
        <span className="media-listing__tag-type">Audio</span>
      </div>
      <IconSoundEffect size={72} className="media-listing__preview-icon" />
    </div>
  );
};

const ImagePreviewContainer = memo(({ type, thumbnail, loadingThumbnail }) => {
  const PreviewIcon = videoIconMap[type || 'Image'];

  return (
    <div
      className="media-listing__preview image"
      style={{
        backgroundImage: `url(${thumbnail?.url || ''})`,
      }}
    >
      <div className="media-listing__tag">
        <span className="media-listing__tag-icon">{loadingThumbnail ? <Spinner /> : <PreviewIcon size={12} />}</span>
        <span className="media-listing__tag-type">{type || 'Image'}</span>
      </div>
    </div>
  );
});

const ImagePreview = ({ media }) => {
  const { thumbnail } = useGenerateThumbnail({ src: media?.source });
  return <ImagePreviewContainer thumbnail={thumbnail?.data} loadingThumbnail={thumbnail?.loading} />;
};

const VideoPreviewContainer = memo(({ type, loading, data, defaultMiddle = false }) => {
  const initialPosition = defaultMiddle ? (data?.count ? -Math.floor(data.count / 2) * data.width : 0) : 0;
  const [backgroundPosition, setBackgroundPosition] = useState(initialPosition);
  const [playheadPosition, setPlayheadPosition] = useState(0);
  const PreviewIcon = videoIconMap[type];

  const handleMouseMove = useCallback(
    (event) => {
      if (loading) return;
      const { left, width } = event.currentTarget.getBoundingClientRect();
      const mousePosition = event.clientX - left;
      const percentage = mousePosition / width;
      const frameIndex = Math.floor(percentage * (data?.count - 1));
      setBackgroundPosition(-frameIndex * data?.width);
      setPlayheadPosition(mousePosition);
    },
    [loading, data?.count, data?.width]
  );

  const handleMouseLeave = useCallback(() => {
    if (loading) return;
    setBackgroundPosition(initialPosition);
    setPlayheadPosition(0);
  }, [loading, initialPosition]);

  return (
    <div
      className="media-listing__preview video"
      style={{
        backgroundImage: `url(${data?.url || ''})`,
        '--video-position': `${backgroundPosition || initialPosition}px center`,
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="media-listing__preview-playhead"
        style={{
          '--playhead-position': `${playheadPosition}px`,
        }}
      />
      <div className="media-listing__tag">
        <span className="media-listing__tag-icon">{loading ? <Spinner /> : <PreviewIcon size={12} />}</span>
        <span className="media-listing__tag-type">{type}</span>
      </div>
    </div>
  );
});

const VideoPreview = ({ media }) => {
  const { frames } = useCaptureFrames({
    src: media?.proxy?.url || media?.source,
  });

  return <VideoPreviewContainer type="Video" loading={frames.loading} data={frames?.data} />;
};

const OverlayPreview = ({ media }) => {
  const { frames } = useCaptureFrames({
    src: media?.proxy?.url || media?.source,
    options: { frameRate: 25 },
  });

  return <VideoPreviewContainer type="Overlay" loading={frames.loading} data={frames?.data} defaultMiddle={true} />;
};

const MaskPreview = ({ media }) => {
  const src = media?.proxy?.url || media?.source;
  const fileType = getFileTypeFromUrl(src);

  switch (fileType) {
    case 'video': {
      const { frames } = useCaptureFrames({
        src,
        options: { frameRate: 25 },
      });

      return <VideoPreviewContainer type="Mask" loading={frames.loading} data={frames?.data} defaultMiddle={true} />;
    }
    case 'image': {
      const { thumbnail } = useGenerateThumbnail({ src });
      return <ImagePreviewContainer type="Mask" thumbnail={thumbnail?.data} loadingThumbnail={thumbnail?.loading} />;
    }
    default: {
      return null;
    }
  }
};

const ElementPreview = ({ media }) => {
  const { frames } = useCaptureFrames({
    src: media?.thumbnail,
    options: { frameRate: 25 },
  });

  return <VideoPreviewContainer type="Overlay" loading={frames.loading} data={frames?.data} defaultMiddle={true} />;
};

const CaptionPreview = () => {
  return (
    <div className="media-listing__preview">
      <div className="media-listing__tag">
        <span className="media-listing__tag-icon">{<IconCaptions size={12} />}</span>
        <span className="media-listing__tag-type">Caption</span>
      </div>
      <IconCaptions size={72} className="media-listing__preview-icon" />
    </div>
  );
};

const TextToSpeechPreview = () => {
  return (
    <div className="media-listing__preview">
      <div className="media-listing__tag">
        <span className="media-listing__tag-icon">{<IconMagicWand size={12} />}</span>
        <span className="media-listing__tag-type">Caption</span>
      </div>
      <IconMagicWand size={72} className="media-listing__preview-icon" />
    </div>
  );
};

const previewComponentMap = {
  audio: AudioPreview,
  image: ImagePreview,
  video: VideoPreview,
  overlay: OverlayPreview,
  mask: MaskPreview,
  element: ElementPreview,
  caption: CaptionPreview,
  'text-to-speech': TextToSpeechPreview,
};

function MediaListingPreview({ media, loading, onClick, onSelect }) {
  const { id, type } = media;

  const handleOnClick = () => {
    if (!onClick) return;
    onClick();
  };

  const handleOnSelect = (e) => {
    if (!onSelect) return;
    onSelect({ id, selected: e.target.checked });
  };

  const PreviewComponent = previewComponentMap[type];
  if (!PreviewComponent) {
    return null;
  }

  return (
    <div className="media-listing__item-container">
      <button className={cn('media-listing__item', type)} onClick={handleOnClick}>
        {loading && (
          <div className="media-listing__loader">
            <Spinner size="sm" variant="light" />
          </div>
        )}
        <PreviewComponent media={media} />
      </button>
      <div className="media-listing__delete">
        <Form.Check type="checkbox" onChange={handleOnSelect} />
      </div>
    </div>
  );
}

export default MediaListingPreview;
