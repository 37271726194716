import config from '@config';
import axios from 'axios';
import { useState } from 'react';
import { Alert, Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { CardElement } from 'react-stripe-elements';
import { useRecoilValue } from 'recoil';

import '@css/BillingCardForm.css';

import { useStripe } from '@context/StripeHookProvider';

import BillingAddressFields from '@feature/billing/BillingAddressFields';

import { useAnalytics } from '@components/analytics/Analytics';

import { authTokenAtom } from '@store/Auth';

const INTENT_ENDPOINT = 'payment/intent';
const SAVE_ENDPOINT = 'payment/save';
const DEFAULT_ERROR = 'There was an error adding your card or setting up your plan.';
const DEFAULT_ERROR_SUFFIX = 'Please try again or contact us if the issue continues.';

function BillingCardForm({ user, subscription, onCardChange, discountCode }) {
  const token = useRecoilValue(authTokenAtom);
  const [stripe, elements] = useStripe();
  const [billingDetails, setBillingDetails] = useState({});
  const [isBusy, setBusy] = useState(false);
  const [error, setError] = useState();
  const [cardInstance, setCardInstance] = useState();
  const { trackEvent } = useAnalytics();

  const resetForm = () => {
    cardInstance?.clear();
    document.getElementById('creditCardForm').reset();
    setBillingDetails({});
  };

  const handleUpdate = (data) => {
    setBillingDetails(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setBusy(true);

    let intentToken;
    const cardElement = elements.getElement('card');

    if (!token || !token.length) {
      throw new Error('Not authorised');
    }

    try {
      const response = await axios.get(config.onboarding.url + INTENT_ENDPOINT, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      intentToken = response.data.data;
    } catch (err) {
      setError(`${err.response.data.data || DEFAULT_ERROR} ${DEFAULT_ERROR_SUFFIX}`);
      setBusy(false);
      return;
    }

    const cardSetupResult = await stripe.confirmCardSetup(intentToken, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: billingDetails.cardName,
        },
      },
    });

    if (cardSetupResult.error) {
      setError(`${cardSetupResult.error.message} ${DEFAULT_ERROR_SUFFIX}`);
      setBusy(false);
      return;
    }

    try {
      const response = await axios.post(
        config.onboarding.url + SAVE_ENDPOINT,
        {
          payment_method: cardSetupResult.setupIntent.payment_method,
          name: user.name,
          email: user.email,
          subscription: subscription ? subscription.code : null,
          discountCode,
          referral: window.Rewardful?.referral ?? null,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      trackEvent('Add/Update Credit Card');

      resetForm();

      if (response.status !== 200) {
        throw new Error('Credit card details could not be saved');
      }

      onCardChange(true);
    } catch (err) {
      setError(`${err.response.data.data || DEFAULT_ERROR} ${DEFAULT_ERROR_SUFFIX}`);
    }

    setBusy(false);
  };

  return (
    <Row className="mb-4">
      <Col>
        <Card className="payment-card">
          <Card.Body>
            <Form onSubmit={handleSubmit} id="creditCardForm">
              <Row>
                <Col xs={5}>
                  <BillingAddressFields user={billingDetails} onAddressChange={handleUpdate} />
                </Col>
                <Col xs={7}>
                  <Form.Label>Card Details</Form.Label>
                  <CardElement
                    style={{ base: { fontSize: '16px', color: '#495057' } }}
                    onReady={(c) => setCardInstance(c)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mt-3 d-flex justify-content-end">
                  <Button variant="primary" type="submit" disabled={isBusy} className="float-right">
                    {isBusy ? <Spinner animation="border" size="sm" role="status" aria-hidden="true" /> : 'Confirm'}
                  </Button>
                </Col>
              </Row>
              {error && (
                <Alert variant="danger" onClose={() => setError(false)} dismissible>
                  {error}
                </Alert>
              )}
              <small>
                By adding a payment method you authorize Shotstack to send instructions to the issuer to process
                payments in accordance with Shotstack&apos;s terms and conditions.
              </small>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default BillingCardForm;
