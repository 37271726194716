function getImageDimensions(source) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const url = source instanceof File ? URL.createObjectURL(source) : source;

    img.onload = () => {
      if (source instanceof File) {
        URL.revokeObjectURL(url);
      }
      resolve({
        width: img.width,
        height: img.height,
      });
    };

    img.onerror = (error) => {
      if (source instanceof File) {
        URL.revokeObjectURL(url);
      }
      reject(new Error('getImageDimensions: There was an error loading the image.', { cause: error }));
    };

    img.src = url;
  }).catch((error) => {
    throw error;
  });
}

export default getImageDimensions;
