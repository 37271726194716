export const formatDate = (dateStr, withTimeStamp) => {
  if (!dateStr) {
    return null;
  }
  const date = new Date(dateStr);
  const formattedDate = date.toLocaleDateString();
  if (withTimeStamp) {
    const formattedTime = `${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
    return `${formattedDate} - ${formattedTime}`;
  }
  return formattedDate;
};
export const formatTime = (dateStr) => {
  if (!dateStr) {
    return null;
  }
  const date = new Date(dateStr);
  const formattedTime = `${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
  return formattedTime;
};
