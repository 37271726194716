import onboardingClient from '@api/clients/onboarding';

type ApiResponse<T> = {
  data?: {
    body?: {
      data: T;
    };
  };
};

type User = {
  id: string;
  email: string;
  stripe_id?: string | null;
  // Add other user fields as needed
};

type Key = {
  id: string;
  name: string;
  key: string;
  created_at: string;
  // Add other key fields as needed
};

type Subscription = {
  id: string;
  status: string;
  current_period_end: string;
  current_period_start: string;
  plan?: {
    id: string;
    name: string;
    amount: number;
  };
};

type Subscriber = {
  id: string;
  email?: string;
  stripe_id?: string | null;
  subscriptions?: Subscription[];
};

type SubscribeParams = {
  subscriptionCode: string;
  discountCode?: string;
};

const getUser = async (): Promise<User> => {
  const { data } = await onboardingClient.get<ApiResponse<User>>('userinfo');
  return data?.body?.data as User;
};

const getKeys = async (): Promise<Key[]> => {
  const { data } = await onboardingClient.get<ApiResponse<Key[]>>('keys');
  return data?.body?.data as Key[];
};

const subscribeUser = async (subscriptionCode: string, discountCode?: string): Promise<Subscription> => {
  const { data } = await onboardingClient.post<ApiResponse<Subscription>>('payment/subscribe', {
    subscriptionCode,
    discountCode,
  });
  return data?.body?.data as Subscription;
};

const getSubscription = async (subscriptionCode: string): Promise<Subscription> => {
  const { data } = await onboardingClient.get<ApiResponse<Subscription>>(
    `payment/subscription/${subscriptionCode}`
  );
  return data?.body?.data as Subscription;
};

const getSubscriptions = async (): Promise<Subscription[]> => {
  const { data } = await onboardingClient.get<ApiResponse<Subscription[]>>('payment/subscriptions');
  return data?.body?.data as Subscription[];
};

const getSubscriber = async (stripeId: string | null): Promise<Subscriber | null> => {
  const { data } = await onboardingClient.get<ApiResponse<Subscriber>>(`payment/customer`, {
    params: { customerId: stripeId },
  });
  return data?.body?.data as Subscriber;
};

export type { User, Key, Subscription, Subscriber };

export default {
  getUser,
  getKeys,
  subscribeUser,
  getSubscription,
  getSubscriptions,
  getSubscriber,
}; 