import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import LinkElement from '@components/atoms/LinkElement';
import SelectElement from '@components/atoms/SelectElement';

function PaygListItem({ subscriptions, paygPlans, user, disabled = false }) {
  const [selectedPaygPlan, setSelectedPaygPlan] = useState(subscriptions?.payg250);
  if (!subscriptions) {
    return null;
  }

  function onPaygSelectChange(event) {
    const subscription = subscriptions[event.target.value];
    setSelectedPaygPlan(subscription);
  }

  return (
    <Row>
      <Col sm={12}>
        <Row>
          <Col sm={12}>
            <h3 className="text-xl font-bold text-center">Pay as you go</h3>
          </Col>
          <Col sm={12} style={{ height: '100px' }}>
            <p className="text-center">Credits are available for use anytime within one year of purchase</p>
            <p className="text-center">Limited styling options for the white label video editor</p>
          </Col>
          <Col sm={12}>
            <p className="text-xl text-center mt-5">
              <span className="font-bold" style={{ fontSize: '3em', color: '#25d3d0' }}>
                ${selectedPaygPlan?.unitPrice?.toFixed(2) || '0.30'}
              </span>{' '}
              / minute
            </p>
          </Col>
        </Row>

        <Col className="m-auto" sm={6}>
          <>
            <SelectElement
              data-testid="select-stage"
              options={paygPlans
                .filter((subscription) => subscription)
                .map((subscription) => ({
                  value: subscription?.code || 'payg250',
                  text: subscription?.name || 'Payg 250',
                }))}
              selected={selectedPaygPlan?.code || 'payg250'}
              handleEvent={onPaygSelectChange}
            />
          </>
          <LinkElement
            type="button"
            to={
              `${selectedPaygPlan?.price_ids[0]?.price}?prefilled_email=${user.email}` ||
              'https://buy.stripe.com/test_8wMcNT8f6bov15SeUX'
            }
            variant="primary"
            className="mt-3 w-100"
            disabled={disabled}
            external
          >
            Pay ${selectedPaygPlan?.price?.toFixed(2) || '75.00'}
          </LinkElement>
          {disabled && (
            <p className="uppercase-label text-center mt-5 text-warning">Not available while on a subscription</p>
          )}
        </Col>
      </Col>
    </Row>
  );
}

export default PaygListItem;
