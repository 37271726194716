import { InternalAxiosRequestConfig, AxiosHeaders } from 'axios';
import { getHeaders } from '@api/utils';

type RequestInterceptor = [
  (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>,
  (error: any) => Promise<never>
];

const requestInterceptors: RequestInterceptor = [
  (config) => {
    const withStage = !/workflow.shotstack|integrate.shotstack|onboard.shotstack/.test(config.baseURL ?? '');
    const withUserAgent =
      !/integrate.shotstack|onboard.shotstack|data-api.shotstack|api.shotstack.io|workflow.shotstack.io/.test(
        config.baseURL ?? ''
      );

    const headers = new AxiosHeaders({
      ...config.headers,
      ...getHeaders({ withStage, withUserAgent }),
    });

    return {
      ...config,
      headers,
    };
  },
  (error) => {
    return Promise.reject(error);
  },
];

export default requestInterceptors; 