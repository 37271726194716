import Credits from '@feature/usage/Credits';
import LegacyCredits from '@feature/usage/LegacyCredits';

function CreditsPanel({ user, subscription, currentMonthStart }) {
  const { usageCycle, tier, planVersion } = user || {};

  if (usageCycle || tier === 'freeTrial' || (tier === 'free' && planVersion)) {
    return <Credits subscription={subscription} usageCycle={usageCycle} planVersion={planVersion} />;
  }

  return <LegacyCredits from={currentMonthStart} subscription={subscription} />;
}

export default CreditsPanel;
