import { memo } from 'react';
import { Dropdown, Nav, NavItem, NavLink, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useIntercom } from 'react-use-intercom';
import { useRecoilValue } from 'recoil';

import { IconBolt, IconQuestionCircle } from '@assets/icons';

import LinkElement from '@components/atoms/LinkElement';

import { authenticatedUserAtom } from '@store/Auth';

import PlanCreditsIndicator from './PlanCreditsIndicator';

function NavbarUser({ logout }) {
  const { show } = useIntercom();
  const user = useRecoilValue(authenticatedUserAtom);

  const { name, tier } = user || {};
  const [nameInitial] = (name || '').toUpperCase();

  const isFreeTier = tier === 'free';

  return (
    <Navbar className="gap-3">
      <Nav>
        {isFreeTier && (
          <LinkElement
            to="/subscription"
            tracking={{ name: 'Select Upgrade' }}
            title="Upgrade your subscription"
            type="button"
          >
            Upgrade <IconBolt size={18} className="ms-1" />
          </LinkElement>
        )}
      </Nav>
      <Nav className="d-none d-md-block">
        <PlanCreditsIndicator />
      </Nav>
      <Nav>
        <div onClick={show} style={{ cursor: 'pointer' }}>
          <IconQuestionCircle size={18} />
        </div>
      </Nav>
      <Nav>
        <Dropdown as={NavItem} align="end">
          <Dropdown.Toggle data-testid="nav-user-toggle" as={NavLink} className="navbar-avatar">
            <div>
              <span>{nameInitial}</span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu data-testid="nav-user-menu" align="end">
            <Dropdown.Header className="navbar-avatar__name" title={name}>
              {name}
            </Dropdown.Header>
            <Dropdown.Divider />
            <LinkContainer to="/keys">
              <Dropdown.Item data-testid="nav-user-keys">API Keys</Dropdown.Item>
            </LinkContainer>
            <LinkContainer to="/subscription">
              <Dropdown.Item data-testid="nav-user-subscription">Subscription</Dropdown.Item>
            </LinkContainer>
            <LinkContainer to="/usage">
              <Dropdown.Item data-testid="nav-user-usage">Usage</Dropdown.Item>
            </LinkContainer>
            <LinkContainer to="/payment-details">
              <Dropdown.Item data-testid="nav-user-payment">Payment Details</Dropdown.Item>
            </LinkContainer>
            <Dropdown.Divider />
            <Dropdown.Item data-testid="nav-user-logout" onClick={() => logout({ returnTo: 'https://shotstack.io' })}>
              Log out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </Navbar>
  );
}

export default memo(NavbarUser);
