import { Form } from 'react-bootstrap';

import { useAnalytics } from '@components/analytics/Analytics';

import capitaliseWords from '@utils/text/capitaliseWords';

function SelectElement(props) {
  const { disabled = false, name, handleEvent, options, grouped, selected, tracking } = props;
  const { trackEvent } = useAnalytics();

  const handleChange = (event) => {
    if (tracking) {
      trackEvent(tracking.name, { ...tracking.properties, Selected: capitaliseWords(event.target.value) });
    }
    handleEvent(event);
  };

  if (grouped) {
    return (
      <Form.Select as="select" onChange={handleChange} name={name} value={selected} disabled={disabled}>
        {Object.entries(options).map(([label, groupOptions]) => (
          <optgroup label={label.toUpperCase()} key={label}>
            {groupOptions.map((option) => (
              <option key={option.key || option.value} value={option.value} disabled={option.disabled}>
                {option.name || option.text}
              </option>
            ))}
          </optgroup>
        ))}
      </Form.Select>
    );
  }

  return (
    <Form.Select as="select" onChange={handleChange} name={name} value={selected} disabled={disabled}>
      {options.map((option) => (
        <option key={`${option.value}.${option.text}`} value={option.value} disabled={option.disabled}>
          {option.text}
        </option>
      ))}
    </Form.Select>
  );
}

export default SelectElement;
