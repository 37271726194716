import cn from 'classnames';
import { useRecoilState } from 'recoil';

import { IconDrag, IconInvisible, IconLocked, IconMask, IconTrash, IconUnlocked, IconVisible } from '@assets/icons';

import ButtonElement from '@components/atoms/ButtonElement';

import {
  toggleTrackLockedSelectorFamily,
  toggleTrackMaskVisibleSelectorFamily,
  toggleTrackVisibleSelectorFamily,
  useDeleteTrackState,
} from '@store/Timeline';

function TimelineTrackHeader({ id }) {
  const [isVisible, toggleVisibility] = useRecoilState(toggleTrackVisibleSelectorFamily(id));
  const [isLocked, toggleLocked] = useRecoilState(toggleTrackLockedSelectorFamily(id));
  const [isMaskVisible, toggleMaskVisible] = useRecoilState(toggleTrackMaskVisibleSelectorFamily(id));
  const deleteTrack = useDeleteTrackState();

  const handleDeleteTrack = () => {
    deleteTrack(id);
  };

  return (
    <div className="track-header">
      <div className="track-header__controls track-header__controls--left">
        <div className="track-header__drag-handle">
          <IconDrag size={12} />
        </div>
        <ButtonElement variant="ghost" handleEvent={handleDeleteTrack} className="track-header__delete-button">
          <IconTrash size={16} />
        </ButtonElement>
      </div>
      <div className="track-header__controls track-header__controls--right">
        <ButtonElement variant="ghost" handleEvent={toggleMaskVisible} className={cn({ active: isMaskVisible })}>
          <IconMask size={12} />
        </ButtonElement>
        <ButtonElement variant="ghost" handleEvent={toggleVisibility} className={cn({ active: !isVisible })}>
          {isVisible ? <IconVisible size={16} /> : <IconInvisible size={16} />}
        </ButtonElement>
        <ButtonElement variant="ghost" handleEvent={toggleLocked} className={cn({ active: isLocked })}>
          {isLocked ? <IconLocked size={16} /> : <IconUnlocked size={16} />}
        </ButtonElement>
      </div>
    </div>
  );
}

export default TimelineTrackHeader;
