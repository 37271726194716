import config from '@config';
import axios from 'axios';
import { Deserializer } from 'jsonapi-serializer';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { authTokenAtom, stageAtom } from '@store/Auth';

import RequestStatus from '@constants/RequestStatus';

const buildUrl = (apiName, path, query) => {
  const basePath = config[apiName].url;

  let url = `${basePath}${path}`;

  if (query) {
    url = `${url}?${query}`;
  }

  return url;
};

const formatAPIResponse = async (api, response) => {
  let data;
  switch (api) {
    case 'edit':
      data = response.response;
      break;
    case 'onboarding':
      data = response.data;
      break;
    default:
      data = await new Deserializer({ keyForAttribute: 'camelCase' }).deserialize(
        response.body,
        (error, deserialized) => {
          return !error ? deserialized : undefined;
        }
      );
  }

  return data;
};

function useApi(props) {
  const { api, ignoreSelectedStage, ignoreStageUpdates } = { ...props };
  const token = useRecoilValue(authTokenAtom);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.NOT_REQUESTED);
  const [response, setResponse] = useState(undefined);
  const stage = useRecoilValue(stageAtom);
  const [parameters, setParameters] = useState();
  const [isProtectedPath, setIsProtectedPath] = useState(true);
  const [requestedStage, setRequestedStage] = useState();

  const request = async (requestParams, protectedPath = true) => {
    const { headers, path, params, query, method, body } = requestParams;

    if (protectedPath && !token) {
      return;
    }

    if (requestStatus === RequestStatus.REQUESTED) {
      return;
    }

    setRequestStatus(RequestStatus.REQUESTED);

    const url = buildUrl(api, path, query);

    const properties = {
      headers: { ...headers },
      data: body || undefined,
    };

    if (token) {
      properties.headers.Authorization = `Bearer ${token}`;
    }

    if (!ignoreSelectedStage && !properties.headers.stage) {
      properties.headers.stage = stage;
    }

    try {
      const { status, data } = await axios({
        data: body || undefined,
        headers: properties.headers,
        method,
        params,
        url,
      });

      if (status >= 200 && status < 300) {
        const formattedResponse = await formatAPIResponse(api, data);
        if (formattedResponse) {
          setResponse(formattedResponse);
          setRequestStatus(RequestStatus.SUCCESS);
        } else {
          setRequestStatus(RequestStatus.NOT_FOUND);
        }
        setParameters(requestParams);
        setIsProtectedPath(protectedPath);
      }
    } catch (error) {
      setRequestStatus(RequestStatus.FAILED);
    }
  };

  useEffect(() => {
    if (ignoreStageUpdates) {
      return;
    }

    if (stage && !requestedStage) {
      setRequestedStage(stage);
    }

    if (requestedStage && stage !== requestedStage && parameters) {
      setRequestedStage(stage);
      request(parameters, isProtectedPath);
    }
  }, [stage, requestedStage, parameters]);

  return {
    request,
    setRequestStatus,
    status: requestStatus,
    tokenLoaded: token,
    data: response,
  };
}

export default useApi;
