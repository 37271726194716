import { shotstackTextToImageData, shotstackTextToSpeechData } from '@data/console/create';
import {
  helloWorldMergeFieldTemplate,
  helloWorldVideoTemplate,
  renderATemplate,
  videoCallback,
  videoMergeFieldVimeoDestination,
  videoTextToImage,
  videoTextToSpeech,
} from '@data/console/edit';
import {
  ingestConvertImageFormat,
  ingestConvertVideoFormat,
  ingestCreateTranscriptData,
  ingestResizeCropVideoData,
  ingestResizeVideoData,
} from '@data/console/ingest';
import {
  sendUrlToGCP,
  sendUrlToGoogleDrive,
  sendUrlToS3,
  sendUrlToShotstack,
  sendUrlToVimeo,
} from '@data/console/serve';

export default {
  ingestConvertImageFormat: {
    api: 'ingest-api',
    name: 'Convert image format',
    description: 'Converts an image to a different format.',
    url: '/sources',
    data: ingestConvertImageFormat,
  },
  ingestConvertVideoFormat: {
    api: 'ingest-api',
    name: 'Convert video format',
    description: 'Converts a video to a different format.',
    url: '/sources',
    data: ingestConvertVideoFormat,
  },
  ingestResizeCropVideoData: {
    api: 'ingest-api',
    name: 'Resize and crop video',
    description: 'Resizes and crops a video to the specified dimensions.',
    url: '/sources',
    data: ingestResizeCropVideoData,
  },
  ingestResizeVideoData: {
    api: 'ingest-api',
    name: 'Resize video',
    description: 'Resizes a video to the specified dimensions.',
    url: '/sources',
    data: ingestResizeVideoData,
  },
  ingestCreateTranscript: {
    api: 'ingest-api',
    name: 'Create a transcript from a video',
    description: 'Generates a transcript for the given video file.',
    url: '/sources',
    data: ingestCreateTranscriptData,
  },
  createShotstackTextToSpeech: {
    api: 'create-api',
    name: 'Generate a voiceover from text',
    description:
      'Converts text into speech using Shotstack\'s <a href="https://shotstack.io/docs/guide/generating-assets/providers/shotstack/#text-to-speech">text-to-speech service</a>.',
    url: '/assets',
    data: shotstackTextToSpeechData,
  },
  // createShotstackImageToVideo: {
  //   api: 'create-api',
  //   name: 'Generate a video from an image',
  //   description:
  //     'Converts an image into a video using Shotstack\'s <a href="https://shotstack.io/docs/guide/generating-assets/providers/shotstack/#image-to-video">image-to-video service</a>.',
  //   url: '/assets',
  //   data: shotstackImageToVideoData,
  // },
  // createHeyGenAvatar: {
  //   api: 'create-api',
  //   name: 'Generate Avatar with HeyGen',
  //   description: "Creates a personalized avatar using HeyGen's avatar generation service.",
  //   url: '/assets',
  //   data: heyGenTextToAvatarData,
  //   integrationLink: '/heygen',
  // },
  createShotstackTextToImage: {
    api: 'create-api',
    name: 'Generate an image from a prompt',
    description:
      'Converts text into an image using Shotstack\'s <a href="https://shotstack.io/docs/guide/generating-assets/providers/shotstack/#text-to-image">image generation service</a>.',
    url: '/assets',
    data: shotstackTextToImageData,
  },
  // createStabilityAiTextToImage: {
  //   api: 'create-api',
  //   name: 'Generate an Image with Stability AI',
  //   description: "Generates an image from text using Stability AI's image generation capabilities.",
  //   url: '/assets',
  //   data: stabilityAiTextToImageData,
  //   integrationLink: '/stability-ai',
  // },
  editRenderHelloWorldVideo: {
    api: 'edit-api',
    name: 'Render a video',
    description:
      'Renders a video <a href="https://shotstack.io/docs/guide/getting-started/hello-world-using-curl/">with JSON</a>.',
    url: '/render',
    data: helloWorldVideoTemplate,
  },
  editRenderHelloWorldMergeField: {
    api: 'edit-api',
    name: 'Render a video with merge fields',
    description:
      'Renders a video with JSON using <a href="https://shotstack.io/docs/guide/architecting-an-application/merging-data/">merge fields</a>.',
    url: '/render',
    data: helloWorldMergeFieldTemplate,
  },
  editRenderATemplate: {
    api: 'edit-api',
    name: 'Render a video via a template',
    description: 'Renders a video by referencing a template designed in the <a href="/studio">Studio</a>.',
    url: '/templates/render',
    data: renderATemplate,
  },
  editRenderAVideoWithTextToSpeech: {
    api: 'edit-api',
    name: 'Render a video with an AI voiceover and captions',
    description:
      'Renders a video with a voiceover using Shotstack\'s <a href="https://shotstack.io/docs/guide/generating-assets/ai-speech-generation/">text-to-speech service</a> and auto generated captions.',
    url: '/render',
    data: videoTextToSpeech,
  },
  editRenderAVideoWithTextToImage: {
    api: 'edit-api',
    name: 'Render a video with an AI image',
    description:
      'Renders a video with a AI generated image using Shotstack\'s <a href="https://shotstack.io/docs/guide/generating-assets/ai-image-generation/">AI image generation service</a>.',
    url: '/render',
    data: videoTextToImage,
  },
  editRenderAVideoWithDestination: {
    api: 'edit-api',
    name: 'Render a video and upload to Vimeo',
    description: 'Renders a video and uploads it to Vimeo.',
    url: '/render',
    data: videoMergeFieldVimeoDestination,
    integrationLink: '/vimeo',
  },
  editRenderAVideoWithCallback: {
    api: 'edit-api',
    name: 'Render a video with a callback request',
    description:
      'Renders a video and sends a request to your <a href="https://shotstack.io/docs/guide/architecting-an-application/webhooks/">callback</a> URL with its status.',
    url: '/render',
    data: videoCallback,
  },
  serveSendUrlToS3: {
    api: 'serve-api',
    name: 'Send asset to AWS S3',
    description: 'Uploads a video asset to an Amazon S3 bucket.',
    url: '/assets',
    data: sendUrlToS3,
    payloadHelperText: 'Make sure to add a unique ID.',
    integrationLink: '/s3',
  },
  serveSendUrlToGCP: {
    api: 'serve-api',
    name: 'Send asset to GCP',
    description: 'Uploads a video asset to a Google Cloud Storage bucket.',
    url: '/assets',
    data: sendUrlToGCP,
    payloadHelperText: 'Make sure to add a unique ID.',
    integrationLink: '/gcp',
  },
  serveSendUrlToShotstack: {
    api: 'serve-api',
    name: 'Send asset to Shotstack Hosting',
    description: "Hosts a video on Shotstack's platform.",
    url: '/assets',
    data: sendUrlToShotstack,
    payloadHelperText: 'Make sure to add a unique ID.',
  },
  serveSendUrlToVimeo: {
    api: 'serve-api',
    name: 'Send a video to Vimeo',
    description: 'Uploads a video to Vimeo.',
    url: '/assets',
    data: sendUrlToVimeo,
    integrationLink: '/vimeo',
  },
  serveSendUrlToGoogleDrive: {
    api: 'serve-api',
    name: 'Send asset to Google Drive',
    description: "Uploads a video on Shotstack's platform.",
    url: '/assets',
    data: sendUrlToGoogleDrive,
    integrationLink: '/google-drive',
  },
  // serveSendUrlToTikTok: {
  //   api: 'serve-api',
  //   name: 'Upload a video to TikTok',
  //   description: 'Uploads a video to TikTok.',
  //   url: '/assets',
  //   data: sendUrlToTikTok,
  //   integrationLink: '/tiktok',
  // },
};
