export const getPanelType = (clipType) => {
  let ret = clipType;
  if (['video', 'image', 'audio'].includes(clipType)) {
    ret = 'media';
  }
  if (['html', 'text'].includes(clipType)) {
    ret = 'text';
  }
  return ret;
};
