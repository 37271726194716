import { Col, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { useRecoilState, useRecoilValue } from 'recoil';

import WorkflowModuleName from '@feature/workflows/module/WorkflowModuleName';
import WorkflowModuleOutputs from '@feature/workflows/module/WorkflowModuleOutputs';
import WorkflowPanelHeader from '@feature/workflows/panel/WorkflowPanelHeader';
import WorkflowPanelMessage from '@feature/workflows/panel/WorkflowPanelMessage';

import {
  activeTaskAtom,
  taskInputsAtomFamily,
  taskOptionsAtomFamily,
  workflowDataListSelector,
} from '@store/Workflows';

import { PRESETS_SELECT } from '@constants/Dolby';
import { noOptionsMessage } from '@constants/Workflows';

function WorkflowModuleDolby() {
  const activeTask = useRecoilValue(activeTaskAtom);
  const [taskInputs, setTaskInputs] = useRecoilState(taskInputsAtomFamily(activeTask));
  const [taskOptions, setTaskOptions] = useRecoilState(taskOptionsAtomFamily(activeTask));
  const { list, grouped } = useRecoilValue(workflowDataListSelector);

  const setStateMap = {
    inputs: setTaskInputs,
    options: setTaskOptions,
  };

  const getSelectedValue = (data, key) => {
    return data.find(({ value }) => value === key) || null;
  };

  const handleChange = (field, type) => {
    const setState = setStateMap[type];
    return ({ value }) => {
      setState((prevState) => ({ ...prevState, [field]: value }));
    };
  };

  return (
    <>
      <div className="form-container">
        <WorkflowModuleName />
      </div>
      <div className="form-container sm">
        <WorkflowPanelHeader
          name="Configuration"
          description="This action enhances the audio quality of the input file."
        />
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>File URL</label>
          </Col>
          <Col>
            <ReactSelect
              unstyled
              className="react-select__inputs form-control"
              classNamePrefix="react-select"
              id="url"
              name="url"
              placeholder="Select input"
              noOptionsMessage={noOptionsMessage('data')}
              defaultValue={getSelectedValue(list, taskInputs?.url)}
              options={grouped}
              onChange={handleChange('url', 'inputs')}
            />
          </Col>
        </Row>
        <hr />
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Preset</label>
          </Col>
          <Col>
            <ReactSelect
              unstyled
              className="form-control"
              classNamePrefix="react-select"
              id="preset"
              name="preset"
              placeholder="Select preset"
              noOptionsMessage={noOptionsMessage()}
              value={getSelectedValue(PRESETS_SELECT, taskOptions?.preset)}
              options={PRESETS_SELECT}
              onChange={handleChange('preset', 'options')}
            />
          </Col>
        </Row>
        <WorkflowPanelMessage
          name="API KEYS"
          message="Add your Dolby.io keys in the <a href='/integrations/dolby' target='_blank' rel='noopener'>integrations page</a> for this module to work."
        />
      </div>
      <div className="form-container">
        <WorkflowModuleOutputs name="Output" description="This action enhances the audio and outputs the file URL." />
      </div>
    </>
  );
}

export default WorkflowModuleDolby;
