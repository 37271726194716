import { Col, Row } from 'react-bootstrap';

import ContentCard from '@components/cards/ContentCard';
import Header from '@components/header/Header';

function Zapier() {
  return (
    <>
      <Header title="Zapier">Automate your workflows and tasks using Zapier.</Header>
      <Row className="mb-4">
        <Col md={4}>
          <ContentCard image={`/img/provider/zapier.png`}>
            Zapier is a platform that allows users to automate their workflows and tasks by connecting various apps and
            services through integrations called Zaps.
          </ContentCard>
        </Col>
        <Col md={8}>
          <ContentCard heading="SETUP">
            <p>
              The <a href="https://zapier.com/apps/shotstack/integrations">Zapier</a> integration for Shotstack allows
              users to automate the process of creating videos. By connecting Shotstack to other apps through Zapier,
              users can trigger the creation of a video whenever a specific event occurs in another app.
            </p>
            <p>
              For example, you could set up a Zap to create a new video every time you receive a new customer order, or
              every time you update a record in your CRM. This can help save time and streamline the media generation
              process, allowing you to focus on other tasks while still producing high-quality videos.
            </p>
            <p>
              Whether you are a marketer, salesperson, or content creator, the Shotstack and Zapier integration can
              automate your video production and take your media strategy to the next level.
            </p>
          </ContentCard>
          <ContentCard heading="INTEGRATION">
            <h5>Basic setup</h5>
            <p>
              Create a template in the Shotstack Studio and add merge fields to the elements of your video that you wish
              to customize with data from another app available in Zapier. Then, set up a Zap in Zapier and select
              Shotstack as the action app.
            </p>
            <p>
              From there, select the trigger app of your choice and the Dynamic Template event. Assign the relevant data
              from the trigger app to the merge fields in your template.
            </p>
          </ContentCard>
        </Col>
      </Row>
    </>
  );
}

export default Zapier;
