import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import '@css/TemplateConfiguration.css';

import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedIn,
  IconBrandPinterest,
  IconBrandTikTok,
  IconBrandX,
  IconBrandYouTube,
  IconVideo,
} from '@assets/icons';

import { useIsTrackingHistory } from '@context/RecoilUndo';

import OutputPresetButtons from '@feature/studio/configuration/OutputPresetButtons';

import ButtonElement from '@components/atoms/ButtonElement';

import { formatAtom, sizeAtomFamily } from '@store/Output';
import { useSaveTemplate } from '@store/Template';
import { showTemplateConfigurationAtom } from '@store/UI';

const presets = {
  video: {
    icon: <IconVideo size={44} color="#4A90E2" />,
    title: 'Video',
    format: 'mp4',
    caption: '1920px x 1080px',
    width: 1920,
    height: 1080,
  },
  mobile: {
    icon: <IconVideo size={44} color="#4A90E2" />,
    title: 'Mobile Video',
    format: 'mp4',
    caption: '1080px x 1920px',
    width: 1080,
    height: 1920,
  },
  tiktok: {
    icon: <IconBrandTikTok size={44} color="#000000" />,
    title: 'TikTok',
    format: 'mp4',
    caption: '1080px x 1920px',
    width: 1080,
    height: 1920,
  },
  x: {
    icon: <IconBrandX size={44} color="#121212" />,
    title: 'X',
    format: 'mp4',
    caption: '1080px x 1080px',
    width: 1080,
    height: 1080,
  },
  instagram: {
    icon: <IconBrandInstagram size={44} color="#E1306C" />,
    title: 'Instagram Post',
    format: 'mp4',
    caption: '1080px x 1080px',
    width: 1080,
    height: 1080,
  },
  instagramReel: {
    icon: <IconBrandInstagram size={44} color="#E1306C" />,
    title: 'Instagram Reel',
    format: 'mp4',
    caption: '1080px x 1920px',
    width: 1080,
    height: 1920,
  },
  facebook: {
    icon: <IconBrandFacebook size={44} color="#1877F2" />,
    title: 'Facebook Video',
    format: 'mp4',
    caption: '1280px x 720px',
    width: 1280,
    height: 720,
  },
  linkedin: {
    icon: <IconBrandLinkedIn size={44} color="#0A66C2" />,
    title: 'LinkedIn',
    format: 'mp4',
    caption: '1920px x 1920px',
    width: 1920,
    height: 1920,
  },
  youtube: {
    icon: <IconBrandYouTube size={44} color="#FF0000" />,
    title: 'YouTube',
    format: 'mp4',
    caption: '1920px x 1080px',
    width: 1920,
    height: 1080,
  },
  pinterest: {
    icon: <IconBrandPinterest size={44} color="#E60023" />,
    title: 'Pinterest',
    format: 'mp4',
    caption: '1000px x 1500px',
    width: 1000,
    height: 1500,
  },
};

function TemplateConfiguration() {
  const [selectedType, setSelectedType] = useState('');
  const [showConfiguration, setShowConfiguration] = useRecoilState(showTemplateConfigurationAtom);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { id } = useParams();
  const { setIsTrackingHistory } = useIsTrackingHistory();
  const setFormat = useSetRecoilState(formatAtom);
  const setWidth = useSetRecoilState(sizeAtomFamily('width'));
  const setHeight = useSetRecoilState(sizeAtomFamily('height'));
  const saveTemplate = useSaveTemplate();

  useEffect(() => {
    if (searchParams.has('isNew')) {
      searchParams.delete('isNew');
      setShowConfiguration(true);
      navigate(`/studio/editor/${id}`, {
        replace: true,
      });

      setIsTrackingHistory(true);
    }
  }, [searchParams, navigate, id, setIsTrackingHistory]);

  const handleCloseConfiguration = () => {
    setShowConfiguration(false);
    saveTemplate();
  };
  const handleSelectPreset = (type, closeOnSelect) => {
    const { width, height, format } = presets[type];
    setSelectedType(type);
    setFormat(format);
    setWidth(width);
    setHeight(height);

    if (closeOnSelect) {
      handleCloseConfiguration();
    }
  };

  if (!showConfiguration) return null;

  return (
    <div className="template-configuration">
      <div className="template-configuration__scrim" onClick={handleCloseConfiguration}></div>
      <div className="template-configuration__inner">
        <div className="output-types">
          <OutputPresetButtons presets={presets} selectedType={selectedType} handleSelectPreset={handleSelectPreset} />
        </div>
        <div className="template-configuration__actions">
          <ButtonElement handleEvent={handleCloseConfiguration}>Save</ButtonElement>
        </div>
      </div>
    </div>
  );
}

export default TemplateConfiguration;
