const MergeFieldVideo = 'https://shotstack-assets.s3.amazonaws.com/onboarding/merge-fields.mp4';
const RenderVideo = 'https://shotstack-assets.s3.amazonaws.com/onboarding/render.mp4';
const SidebarVideo = 'https://shotstack-assets.s3.amazonaws.com/onboarding/sidebar.mp4';
const TimelineVideo = 'https://shotstack-assets.s3.amazonaws.com/onboarding/timeline.mp4';
const TopbarVideo = 'https://shotstack-assets.s3.amazonaws.com/onboarding/topbar.mp4';

export const greetingsList = [
  'Hello',
  'Bonjour',
  'Ciao',
  'Konnichiwa',
  'Namaste',
  'Guten Tag',
  'Olá',
  'Merhaba',
  'Salam',
  'Hallo',
  'Aloha',
  'Hej',
  'Aloha',
];

export const useCaseVideos = {
  'Social Media Automation': [
    'https://videos.pexels.com/video-files/8626763/8626763-sd_640_360_25fps.mp4',
    'https://videos.pexels.com/video-files/6271219/6271219-sd_640_360_25fps.mp4',
    'https://videos.pexels.com/video-files/13633630/13633630-sd_640_360_30fps.mp4',
  ],
  'Classifieds & Listing Video Automation': [
    'https://videos.pexels.com/video-files/4185235/4185235-sd_640_360_25fps.mp4',
    'https://videos.pexels.com/video-files/7578544/7578544-sd_640_360_30fps.mp4',
    'https://videos.pexels.com/video-files/4431695/4431695-sd_640_360_30fps.mp4',
  ],
  'News Video Automation': [
    'https://videos.pexels.com/video-files/5286261/5286261-sd_640_360_30fps.mp4',
    'https://videos.pexels.com/video-files/10464571/10464571-sd_960_506_25fps.mp4',
    'https://videos.pexels.com/video-files/3433789/3433789-sd_640_360_25fps.mp4',
  ],
  'Automated Trimming, Concatenation & Stitching': [
    'https://videos.pexels.com/video-files/8100336/8100336-sd_960_506_25fps.mp4',
    'https://videos.pexels.com/video-files/1998560/1998560-sd_640_360_24fps.mp4',
    'https://videos.pexels.com/video-files/8319435/8319435-sd_640_360_30fps.mp4',
  ],
  'Creating Video Editing Capabilities': [
    'https://videos.pexels.com/video-files/27153539/12085853_640_360_30fps.mp4',
    'https://videos.pexels.com/video-files/3015482/3015482-sd_640_360_24fps.mp4',
    'https://videos.pexels.com/video-files/19197555/19197555-sd_640_360_30fps.mp4',
  ],
  'Other Media Automation Workflow': [
    'https://videos.pexels.com/video-files/2889410/2889410-sd_640_360_30fps.mp4',
    'https://videos.pexels.com/video-files/7313686/7313686-sd_960_506_25fps.mp4',
    'https://videos.pexels.com/video-files/8100341/8100341-sd_960_506_25fps.mp4',
  ],
};

export const getStudioSteps = ({ renderId }) => [
  {
    text: "<strong class='text-xl'>Welcome to the studio!</strong> 👋 <br /> <span class='text-lg'>Here's a video we've prepared just for you based on the information you've provided. <br /> Follow the steps to see how you can create videos like this! <br /><br />Sound on 🔊</span>",
    center: true,
    size: 'lg',
    media: { renderId },
  },
  {
    text: 'This is the Timeline — Arrange and trim clips to structure your video.',
    targetSelector: '[data-tour="timeline"]',
    media: { url: TimelineVideo },
  },
  {
    text: 'Your toolbox — Add text, media, overlays, and more to shape your video.',
    targetSelector: '[data-tour="sidebar"]',
    media: { url: SidebarVideo },
  },
  {
    text: 'From here, you can switch between <strong>designing</strong>, <strong>connecting</strong> your destinations, and <strong>automating</strong> your workflow.',
    targetSelector: '[data-tour="topbar"]',
    media: { url: TopbarVideo },
  },
  {
    text: 'Use this button to insert custom fields into your video to truly personalise it.',
    targetSelector: '[data-tour="merge-fields"]',
    media: { url: MergeFieldVideo },
  },
  {
    text: 'Click here to preview a render of your completed video.',
    targetSelector: '[data-tour="render"]',
    media: { url: RenderVideo },
  },
  {
    text: "<strong class='text-xl'>Tour complete 🎉 </strong> <br /><br />Now to start creating your dynamic video! What will you create?",
    center: true,
  },
];
