import { ButtonToolbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { IconChevronLeft } from '@assets/icons';

import WorkflowName from '@feature/workflows/toolbar/WorkflowName';

import { activeWorkflowAtom } from '@store/Workflows';

function EditorToolbarLeft() {
  const activeWorkflowId = useRecoilValue(activeWorkflowAtom);
  const navigate = useNavigate();

  const handleBackClick = () => {
    const confirmLeave = window.confirm('Are you sure you want to leave? Make sure your changes have been saved.');
    if (confirmLeave) {
      if (window.history.length > 2) {
        navigate(-1);
      } else {
        navigate(`/workflows/${activeWorkflowId}`);
      }
    }
  };

  return (
    <ButtonToolbar className="align-items-center gap-3">
      <span className="link" onClick={handleBackClick}>
        <IconChevronLeft size={18} />
      </span>
      <div className="d-flex">
        <WorkflowName />
      </div>
    </ButtonToolbar>
  );
}

export default EditorToolbarLeft;
