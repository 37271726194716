import { useEffect } from 'react';

const usePopoverPosition = (target, popoverRef) => {
  useEffect(() => {
    if (!target || !popoverRef.current) return;

    const updatePosition = () => {
      const targetRect = target.getBoundingClientRect();
      const popoverRect = popoverRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      let translateX, translateY;
      let positionClass = '';

      const isCloserToBottom = targetRect.bottom > viewportHeight / 2;

      if (isCloserToBottom) {
        translateY = -(popoverRect.height + 10);
        positionClass = 'top';
      } else {
        translateY = targetRect.height + 10;
        positionClass = 'bottom';
      }

      translateX = targetRect.width / 2 - popoverRect.width / 2;

      if (targetRect.left + translateX < 0) {
        translateX = -targetRect.left;
      } else if (targetRect.left + translateX + popoverRect.width > viewportWidth) {
        translateX = viewportWidth - (targetRect.left + popoverRect.width);
      }

      if (targetRect.top + translateY < 0 || targetRect.bottom + translateY > viewportHeight) {
        if (targetRect.left > viewportWidth / 2) {
          translateX = -(popoverRect.width + 10);
          positionClass = 'left';
        } else {
          translateX = targetRect.width + 10;
          positionClass = 'right';
        }
        translateY = targetRect.height / 2 - popoverRect.height / 2;

        if (targetRect.top + translateY < 0) {
          translateY = -targetRect.top;
        } else if (targetRect.top + translateY + popoverRect.height > viewportHeight) {
          translateY = viewportHeight - (targetRect.top + popoverRect.height);
        }
      }

      popoverRef.current.style.transform = `translate(${translateX}px, ${translateY}px)`;
      popoverRef.current.style.top = `${targetRect.top}px`;
      popoverRef.current.style.left = `${targetRect.left}px`;
      popoverRef.current.dataset.position = positionClass;
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => window.removeEventListener('resize', updatePosition);
  }, [target, popoverRef]);
};

export default usePopoverPosition;
