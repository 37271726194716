const getResolution = (resolution) => {
  switch (resolution) {
    case 'preview':
      return [512, 288];
    case 'mobile':
      return [640, 360];
    case 'sd':
      return [1024, 576];
    case 'hd':
      return [1280, 720];
    case '1080':
      return [1920, 1080];
    default:
      return [1024, 576];
  }
};

export default getResolution;
