import { startPolling } from '@api/utils/axiosPoll';

const removePollParam = (url) => {
  return url.replace(/(polling=[^&]*&?)|(&?polling=[^&]*)/, '');
};

const defaultPollPredicate = (response) => {
  const { status } = response.data?.body?.data || {};
  return !status || ['done', 'ready', 'failed'].includes(status);
};

const workflowTaskPollPredicate = (response) => {
  const tasks = response.data?.body?.data || [];
  const statuses = tasks.map(({ status }) => status);
  return statuses.every((s) => ['done', 'ready', 'failed'].includes(s));
};

const renditionsPollPredicate = (response) => {
  const {
    status: fileStatus,
    outputs: { renditions, transcription },
  } = response.data?.body?.data;

  let statuses = [fileStatus];

  if (renditions) {
    const renditionStatuses = (renditions || []).map(({ status }) => status);
    statuses = [...statuses, ...renditionStatuses];
  }

  if (transcription) {
    const { status } = transcription;
    statuses = [...statuses, status];
  }

  return statuses.every((s) => ['ready', 'failed'].includes(s));
};

const pollConfigs = {
  ingest: {
    renditions: { predicate: renditionsPollPredicate, delayTime: 5000 },
  },
  workflows: {
    tasks: { predicate: workflowTaskPollPredicate },
  },
  edit: {
    render: { predicate: defaultPollPredicate, delayTime: 1500 },
  },
  default: { predicate: defaultPollPredicate },
};

const getPollConfig = (origin, resource) => {
  return pollConfigs[origin]?.[resource] || pollConfigs.default;
};

const responsePollingInterceptors = (client) => [
  async (response) => {
    if (!response.request) {
      return response;
    }

    try {
      const { searchParams } = new URL(response.request?.responseURL);

      if (searchParams.has('polling')) {
        const dataKey = searchParams.get('polling');
        const [origin, resource] = dataKey.split('/');

        const { predicate, delayTime } = getPollConfig(origin, resource);
        const url = removePollParam(response.config?.url);

        return startPolling(client, url, predicate, dataKey, delayTime);
      }

      return response;
    } catch (error) {
      console.error(error);
      return response;
    }
  },
  (error) => Promise.reject(error),
];

export default responsePollingInterceptors;
