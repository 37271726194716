import { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useRecoilState } from 'recoil';

import '@css/PanelClipProperties.css';

import Effects from '@components/effects/Effects';

import { clipSettingsSelectorFamily } from '@store/EditSelectors';

const getFullEffectValue = ({ value, speed }) => {
  if (!value) {
    return undefined;
  }
  return [value, speed].join('');
};

const getBaseEffectValue = (clip) => {
  const effect = clip?.effect;
  if (!effect) {
    return undefined;
  }
  return effect?.replace(/Fast|Slow/, '');
};

const getBaseEffectSpeed = (clip) => {
  const effect = clip?.effect;
  if (!effect) {
    return '';
  }
  if (effect?.includes('Fast')) {
    return 'Fast';
  }
  if (effect?.includes('Slow')) {
    return 'Slow';
  }
  return '';
};

const effectSpeedOptions = [
  { value: '', text: 'Normal' },
  { value: 'Slow', text: 'Slow' },
  { value: 'Fast', text: 'Fast' },
];

function PanelClipEffects({ id }) {
  const [clip, setClip] = useRecoilState(clipSettingsSelectorFamily(id));
  const [speed, setSpeed] = useState(getBaseEffectSpeed(clip));
  const [baseEffect, setBaseEffect] = useState(getBaseEffectValue(clip));

  useEffect(() => {
    setBaseEffect(getBaseEffectValue(clip));
    setSpeed(getBaseEffectSpeed(clip));
  }, [clip]);

  const handleEffectChange = (value) => {
    const newBaseEffect = baseEffect !== value ? value : undefined;
    setBaseEffect(newBaseEffect);

    setClip({
      effect: getFullEffectValue({ value: newBaseEffect, speed }),
    });
  };

  const handleSpeedChange = (newSpeed) => {
    setSpeed(newSpeed);

    setClip({
      effect: getFullEffectValue({ value: baseEffect, speed: newSpeed }),
    });
  };

  return (
    <>
      <div className="transition-options mb-4">
        <ToggleButtonGroup type="radio" name="speed" onChange={handleSpeedChange}>
          {effectSpeedOptions.map(({ value, text }) => {
            return (
              <ToggleButton
                key={`speed-${text}`}
                id={text}
                className={speed === value ? 'active' : ''}
                value={value}
                disabled={!baseEffect}
              >
                {text}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </div>
      <Effects selected={baseEffect ?? ''} speed={speed} onEffectChange={handleEffectChange} />
    </>
  );
}

export default PanelClipEffects;
