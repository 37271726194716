import { useNavigate } from 'react-router-dom';

import ButtonElement from '@components/atoms/ButtonElement';

import useCreateWorkflow from '@hooks/useCreateWorkflow';

function WorkflowCreateButton({ tracking, btnVariant = 'primary', children }) {
  const history = useNavigate();
  const { handleCreate, loading } = useCreateWorkflow({
    onSuccess: (workflowId) => history(`/workflows/${workflowId}/edit?isNew`),
  });

  const createWorkflowTracking = {
    name: 'Select Create workflow',
    properties: {
      ...tracking,
    },
  };

  return (
    <ButtonElement
      handleEvent={() => handleCreate()}
      variant={btnVariant}
      style={{ minWidth: 100 }}
      loading={loading}
      tracking={createWorkflowTracking}
    >
      {children || 'Create Blank Workflow'}
    </ButtonElement>
  );
}

export default WorkflowCreateButton;
