import { useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { useIntercom } from 'react-use-intercom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { IconBraces } from '@assets/icons';
import inputOverride from '@assets/videos/input-override.mp4';

import SettingMergeFields from '@feature/studio/setting/SettingMergeFields';

import ButtonElement from '@components/atoms/ButtonElement';
import Dismissible from '@components/atoms/Dismissible.tsx';

import { workspaceTabAtom } from '@store/Edit';
import { mergePopoverAtom } from '@store/Merge';
import { templateIdAtom } from '@store/Template';

const INTERCOM_ARTICLE_ID = 8010130;

const MergeFieldsInfoPopover = () => {
  const { showArticle } = useIntercom();
  return (
    <Dismissible id="merge-fields-info-popover">
      <div className="popover-info d-flex gap-4 mt-4">
        <div>
          <video
            src={inputOverride}
            alt="Input Override"
            className="merge-fields__input-override"
            autoPlay
            loop
            muted
          />
        </div>
        <div className="popover-info__content">
          <p>
            <strong>Build dynamic videos by replacing placeholders with variables via the API or workflows.</strong>
          </p>
          <p>
            Assign a merge field to a clip property using the merge button
            <div className="popover-merge-fields__braces-icon">
              <IconBraces size="18" alt="Braces merge field icon" />
            </div>
            in the clip properties panel or wrap text in double braces.
            <button onClick={() => showArticle(INTERCOM_ARTICLE_ID)} className="text-link ml-2">
              Learn More
            </button>
          </p>
        </div>
      </div>
    </Dismissible>
  );
};

function MergeFieldsButton() {
  const documentRef = useRef(document);
  const [show, setShow] = useRecoilState(mergePopoverAtom);
  const [target, setTarget] = useState(null);
  const workspaceTab = useRecoilValue(workspaceTabAtom);
  const templateId = useRecoilValue(templateIdAtom);

  const tracking = {
    name: 'Select Merge Fields Panel',
    properties: {
      Id: templateId,
    },
  };

  const handleClick = (event) => {
    setShow(!show);
    setTarget(show ? null : event.target);
  };

  return (
    <>
      <ButtonElement
        title="Merge Fields"
        handleEvent={handleClick}
        tracking={tracking}
        variant="light"
        type="button"
        disabled={workspaceTab !== 'design'}
        data-tour="merge-fields"
      >
        <IconBraces size="18" /> Merge Fields
      </ButtonElement>

      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={documentRef.current?.body}
        containerPadding={20}
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover className="popover-controls popover-merge-fields">
          <Popover.Body>
            <SettingMergeFields />
            <MergeFieldsInfoPopover />
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
}

export default MergeFieldsButton;
