import { useCallback, useEffect, useMemo, useState } from 'react';

const SPACE_KEY = ' ';

const keyMap = {
  [SPACE_KEY]: 'Space',
  Backspace: 'Delete',
  Delete: 'Delete',
};

function useKeyboardShortcuts(shortcuts) {
  const [initialGroup] = Object.keys(shortcuts);
  const [activeGroup, setGroup] = useState(initialGroup);

  const handleKeyDown = useCallback(
    (event) => {
      const { key: eventKey, ctrlKey, altKey, shiftKey, metaKey, target } = event;

      if (target.tagName === 'INPUT' && target.type !== 'range') {
        return;
      }

      const key = keyMap[eventKey] || eventKey;
      const modifiers = [(ctrlKey || metaKey) && 'super', altKey && 'alt', shiftKey && 'shift'].filter(Boolean);
      const combination = [...modifiers, key].join('+');

      const cuts = { ...(shortcuts.global || {}), ...(shortcuts[activeGroup] || {}) };
      try {
        const action = cuts[combination] || cuts[key];
        if (action) {
          event.preventDefault();
          action();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [activeGroup, shortcuts]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [shortcuts]);

  return useMemo(() => ({ setGroup }), []);
}

export default useKeyboardShortcuts;
