function WorkflowPanelHeader({ name, description }) {
  return (
    <div>
      <h6>{name}</h6>
      {description && <small className="d-block" dangerouslySetInnerHTML={{ __html: description }} />}
    </div>
  );
}

export default WorkflowPanelHeader;
