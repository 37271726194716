import { Graphics } from '@inlet/react-pixi';
import { useEffect, useRef } from 'react';

const CanvasSnapLines = ({ width, height, coords }) => {
  const graphicsRef = useRef(null);

  useEffect(() => {
    const graphics = graphicsRef.current;
    graphics.clear();

    const drawLine = (startX, startY, endX, endY, lineThickness, lineColor) => {
      graphics.lineStyle(lineThickness, lineColor, 1);
      graphics.moveTo(startX, startY);
      graphics.lineTo(endX, endY);
    };

    if (coords.x !== false) {
      const lineColor = coords.x === 'center' ? 0xeb5757 : 0x9747ff;
      const xCoord = coords.x === 'center' ? width / 2 : coords.x;
      drawLine(xCoord, 0, xCoord, height, 2, lineColor);
    }

    if (coords.y !== false) {
      const lineColor = coords.y === 'center' ? 0xeb5757 : 0x9747ff;
      const yCoord = coords.y === 'center' ? height / 2 : coords.y;
      drawLine(0, yCoord, width, yCoord, 2, lineColor);
    }
  }, [width, height, coords]);

  return <Graphics ref={graphicsRef} />;
};

export default CanvasSnapLines;
