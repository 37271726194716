/**
 * @typedef {import('@animation/animate').AnimationProperties} AnimationProperties
 */

/**
 * @template T
 * @typedef {import('@animation/keyframe').Keyframe<T>} Keyframe<T>
 */

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ opacity: number; }>}
 */
export const fadeTransitionInKeyframe = (start, end, properties) => {
  const { clip } = properties;

  return {
    from: {
      opacity: 0,
    },
    to: {
      opacity: Math.max(0, Math.min(clip.opacity ?? 1, 1)),
    },
    start,
    end,
    interpolation: {
      opacity: 'linear',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ opacity: number }>}
 */
export const fadeTransitionOutKeyframe = (start, end, properties) => {
  const { clip } = properties;

  return {
    from: {
      opacity: Math.max(0, Math.min(clip.opacity ?? 1, 1)),
    },
    to: {
      opacity: 0,
    },
    start,
    end,
    interpolation: {
      opacity: 'linear',
    },
  };
};
