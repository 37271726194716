import { Form } from 'react-bootstrap';

import camelCase from '@utils/text/camelCase';

function InputTextarea({
  id,
  label,
  value,
  rows,
  placeholder,
  disabled = false,
  handleChange,
  handleBlur,
  ...restProps
}) {
  const inputId = id || camelCase(label);
  return (
    <Form.Group controlId={inputId}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        as="textarea"
        rows={rows}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        placeholder={placeholder}
        {...restProps}
      />
    </Form.Group>
  );
}

export default InputTextarea;
