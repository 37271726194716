import { memo } from 'react';
import { Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

import '@css/NavBar.css';

import { IconMenu } from '@assets/icons';
import CombinationMark from '@assets/img/logo/combination-mark.svg';
import Logo from '@assets/img/logo/emblem.png';

import { useAuth0 } from '@context/Auth0ReactSPA';

import NavbarEnvIndicator from '@components/navbar/NavbarEnvIndicator';
import NavbarUser from '@components/navbar/NavbarUser';

function NavbarMain() {
  const location = useLocation();
  const { logout } = useAuth0();

  if (location.pathname === '/onboarding') {
    return null;
  }

  const handleToggleSidebar = () => {
    document.body.classList.toggle('sidebar-open');
  };

  return (
    <header className="site-header">
      <Navbar className="navbar-main">
        <button className="d-md-none unstyled" onClick={handleToggleSidebar}>
          <IconMenu size={24} />
        </button>
        <Navbar.Brand>
          <Link to="/">
            <>
              <img src={CombinationMark} alt="Shotstack" style={{ height: '33px' }} className="d-none d-md-block" />
              <img src={Logo} alt="Shotstack" className="d-md-none" style={{ width: '65px' }} />
            </>
          </Link>
        </Navbar.Brand>
        <NavbarUser logout={logout} />
        <NavbarEnvIndicator />
      </Navbar>
    </header>
  );
}

export default memo(NavbarMain);
