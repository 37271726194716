import config from '@config';
import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import onboardingService from '@api/services/onboarding';

const { persistAtom } = recoilPersist();

type AuthUser = {
  plans?: {
    v1?: string;
  };
  [key: string]: any; // For other potential user properties
};

type AuthKeys = {
  [key: string]: any; // Define specific key structure if known
};

type UserDetails = {
  [key: string]: any; // Define specific user details structure if known
};

export const authTokenAtom = atom<string | null>({
  key: 'auth/token',
  default: null,
});

export const authenticatedAtom = atom<boolean>({
  key: 'auth/state',
  default: false,
});

export const authenticatedUserAtom = atom<AuthUser | null>({
  key: 'auth/user',
  default: null,
});

export const stageAtom = atom<string>({
  key: 'stage/state',
  default: config.headers.stage,
  effects_UNSTABLE: [persistAtom],
});

export const showStageConfirmationModalAtom = atom<string>({
  key: 'stage/warning',
  default: '',
});

export const authReadySelector = selector<boolean | Promise<never>>({
  key: 'auth/ready',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    const token = get(authTokenAtom);
    const stage = get(stageAtom);
    const ready = Boolean(token && stage);

    if (!ready) {
      return new Promise(() => {}); // Returns an unresolved promise if not ready
    }
    return ready;
  },
});

export const authKeysSelector = selector<AuthKeys>({
  key: 'auth/keys',
  get: async ({ get }) => {
    await get(authReadySelector);
    const keys = await onboardingService.getKeys();
    return keys;
  },
});

export const authPlanSelector = selector<string>({
  key: 'auth/plan',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    await get(authReadySelector);
    const authUser = get(authenticatedUserAtom);

    if (authUser?.plans?.v1) {
      return authUser.plans.v1;
    }

    return new Promise((resolve) => setTimeout(() => resolve('free'), 5000));
  },
});

export const userDetailsSelector = selector<UserDetails>({
  key: 'auth/details/selector',
  get: async ({ get }) => {
    await get(authReadySelector);
    const user = await onboardingService.getUser();
    return user;
  },
});
