export const heyGenTextToAvatarData = {
  provider: 'heygen',
  options: {
    type: 'text-to-avatar',
    avatar: 'Angela',
    voice: 'Abbi - Natural',
    text: 'This is an avatar video asset generated from heygen by shotstack',
  },
};

export const shotstackImageToVideoData = {
  provider: 'shotstack',
  options: {
    type: 'image-to-video',
    imageUrl: 'https://shotstack-assets.s3.amazonaws.com/images/wave-barrel-sm.jpg',
    guidanceScale: 1.8,
    motion: 127,
  },
};

export const shotstackTextToImageData = {
  provider: 'shotstack',
  options: {
    type: 'text-to-image',
    prompt: 'A realistic photo of the planet Mars with a black outer space background',
    width: 1024,
    height: 1024,
  },
};

export const stabilityAiTextToImageData = {
  provider: 'stability-ai',
  options: {
    type: 'text-to-image',
    engine: 'stable-diffusion-xl-1024-v1-0',
    prompt: 'A realistic photo of the planet Mars with a black outer space background',
    width: 1024,
    height: 1024,
    steps: 20,
    cfgScale: 12,
    stylePreset: 'photographic',
  },
};

export const shotstackTextToSpeechData = {
  provider: 'shotstack',
  options: {
    type: 'text-to-speech',
    text: 'This is a text to speech asset generated by shotstack',
    voice: 'Matthew',
  },
};
