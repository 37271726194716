import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import sourcesService from '@api/services/sources';

import SourceDetailsAssets from '@feature/sources/SourceDetailsAssets';
import SourceDetailsSource from '@feature/sources/SourceDetailsSource';

import { useAnalytics } from '@components/analytics/Analytics';
import Header from '@components/header/Header';
import Layout from '@components/layout/Layout';
import ResourceDangerZone from '@components/resources/ResourceDangerZone';

import useFetchAndPoll from '@hooks/useFetchAndPoll';

function IngestApiDetail() {
  const { id } = useParams();
  const { trackEvent } = useAnalytics();
  const { data: source } = useFetchAndPoll({ id, key: `ingest/source/${id}`, fetch: sourcesService.get });
  const { data: renditions } = useFetchAndPoll({
    id,
    key: `ingest/renditions/${id}`,
    fetch: sourcesService.renditions.getAll,
  });

  useEffect(() => {
    trackEvent('View Source Details', {
      Id: id,
    });
  }, []);

  return (
    <Layout className="pb-4">
      <Header title="Source Details" />
      <SourceDetailsSource source={source} url={renditions?.source} loaded={!!source} />
      <SourceDetailsAssets outputs={renditions?.outputs} loaded={!!renditions} />

      <ResourceDangerZone id={id} service="sources" resource={source} />
    </Layout>
  );
}

export default IngestApiDetail;
