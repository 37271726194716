import { Col, Row } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';

import OutputThumbnailCaptureInput from '@feature/studio/setting/output/ThumbnailCaptureInput';
import OutputThumbnailScaleInput from '@feature/studio/setting/output/ThumbnailScaleInput';

import { templateIdAtom } from '@store/Template';

function SettingThumbnailFields() {
  const templateId = useRecoilValue(templateIdAtom);

  const tracking = {
    properties: {
      Id: templateId,
    },
  };

  return (
    <div>
      <h6>Thumbnail</h6>
      <Row>
        <Col>
          <OutputThumbnailCaptureInput tracking={tracking} />
        </Col>
        <Col>
          <OutputThumbnailScaleInput tracking={tracking} />
        </Col>
      </Row>
    </div>
  );
}

export default SettingThumbnailFields;
