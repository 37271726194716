import { AxiosResponse } from 'axios';
import { deserialize } from '@api/utils/deserializer';

type ResponseInterceptor = [
  (response: AxiosResponse) => Promise<AxiosResponse>,
  (error: any) => Promise<any>
];

type ApiResponse<T = any> = {
  body?: {
    data: T;
    [key: string]: any;
  };
  [key: string]: any;
};

const responseInterceptors: ResponseInterceptor = [
  async (response) => {
    const hasBodyStructure = Boolean(response?.data?.body);
    const { data, ...restBodyContent } = hasBodyStructure 
      ? response.data.body 
      : { data: response.data };
    const deserializedData = await deserialize(data);

    const newResponse: AxiosResponse<ApiResponse> = {
      ...response,
      data: {
        body: {
          ...restBodyContent,
          data: deserializedData,
        },
      },
    };

    return newResponse;
  },
  (error) => {
    if (!error.toJSON) {
      return Promise.reject(error);
    }
    const errorJson = error.toJSON();
    const { status, config } = errorJson;

    if (status === 404) {
      if (config.url.includes('templates')) {
        return Promise.resolve({ 
          config, 
          status: 200, 
          data: { body: [] } 
        } as AxiosResponse);
      }
    }

    return Promise.reject(error);
  },
];

export type { ApiResponse };
export default responseInterceptors; 