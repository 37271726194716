import { useCallback, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import '@css/WorkflowList.css';

import WorkflowCreate from '@feature/workflows/buttons/WorkflowCreateButton';
import WorkflowDeleteBulkButton from '@feature/workflows/buttons/WorkflowDeleteBulkButton';
import WorkflowListItems from '@feature/workflows/list/WorkflowListItems';

import { useAnalytics } from '@components/analytics/Analytics';
import Layout from '@components/layout/Layout';

import useCreateWorkflow from '@hooks/useCreateWorkflow';

const clipboardSupported = 'clipboard' in navigator;

function Workflows() {
  const { trackEvent } = useAnalytics();
  const history = useNavigate();
  const { handleCreate } = useCreateWorkflow({
    onSuccess: (workflowId) => history(`/workflows/${workflowId}/edit?isNew`),
  });

  const handlePaste = useCallback(async () => {
    try {
      const clipboardContent = await navigator.clipboard.readText();

      let pastedWorkflow;
      try {
        pastedWorkflow = JSON.parse(clipboardContent);
      } catch (error) {
        console.error('Failed to parse clipboard content as JSON:', error);
        return;
      }
      const isValidWorkflow = ['name', 'configuration'].every((key) => key in pastedWorkflow);
      if (isValidWorkflow) {
        const { name, configuration } = pastedWorkflow;
        handleCreate({
          name: `${name} (Copy)`,
          configuration,
        });
      } else {
        console.error('Invalid workflow pasted. Missing name or configuration fields.');
      }
    } catch (err) {
      console.error('Failed to read clipboard contents:', err);
    }
  }, [handleCreate]);

  useEffect(() => {
    const onKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'v') {
        handlePaste();
      }
    };

    if (clipboardSupported) {
      document.addEventListener('keydown', onKeyDown);
    }
    return () => {
      if (clipboardSupported) {
        document.removeEventListener('keydown', onKeyDown);
      }
    };
  }, [handlePaste]);

  useEffect(() => {
    trackEvent('View Workflow List');
  }, []);

  return (
    <Layout>
      <Card className="p-3 mb-10">
        <Card.Body>
          <div className="flex justify-between items-center">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <span className="uppercase-label">Workflows</span>
                </li>
              </ol>

              <h1>My Workflows</h1>
            </div>

            <div className="flex gap-2">
              <WorkflowDeleteBulkButton />
              <WorkflowCreate tracking={{ Position: 'Above Workflow List' }} />
            </div>
          </div>
        </Card.Body>
      </Card>

      <WorkflowListItems />
    </Layout>
  );
}

export default Workflows;
