import config from '@config';
import axios from 'axios';
import { useRecoilValue } from 'recoil';

import { authTokenAtom, stageAtom } from '@store/Auth';

import useAxiosPoll from '@hooks/useAxiosPoll';

import { RENDITION_WIDTH } from '@constants/Uploads';

const UPLOAD_STATUS = {
  READY: 'ready',
  FAILED: 'failed',
};

const UPLOAD_LINK_URL = `${config.ingest.url}sources`;
const UPLOAD_POLLING_INTERVAL = 1500;

const requestOptions = {
  outputs: {
    renditions: [
      {
        format: 'webm',
        filename: 'shotstack-overlay',
        keyframeInterval: 25,
        size: {
          width: RENDITION_WIDTH,
        },
      },
    ],
  },
  destinations: [
    {
      provider: 'shotstack',
    },
  ],
};

function useIngestElement() {
  const token = useRecoilValue(authTokenAtom);
  const stage = useRecoilValue(stageAtom);
  const { startPolling } = useAxiosPoll();

  const requestHeaders = {
    headers: {
      Authorization: `Bearer ${token}`,
      stage,
    },
  };

  const isUploadComplete = (statuses) => {
    return statuses.every((status) => [UPLOAD_STATUS.READY, UPLOAD_STATUS.FAILED].includes(status));
  };

  const getStatuses = (attributes) => {
    const { status: fileStatus, outputs } = attributes;
    let statuses = [fileStatus];
    if (outputs?.renditions.length) {
      const renditionStatuses = outputs.renditions.map((r) => r.status);
      statuses = [...statuses, ...renditionStatuses];
    }
    return statuses;
  };

  const onPollCheck = (response) => {
    const { attributes } = response.data.data;
    const statuses = getStatuses(attributes);
    return isUploadComplete(statuses);
  };

  const getStatus = async ({ sourceId }) => {
    const url = `${config.ingest.url}sources/${sourceId}`;
    return startPolling({
      url,
      config: requestHeaders,
      interval: UPLOAD_POLLING_INTERVAL,
      onPollCheck,
    });
  };

  const ingestURL = async ({ url }) => {
    const response = await axios.post(UPLOAD_LINK_URL, { url, ...requestOptions }, requestHeaders);
    const { id: sourceId } = response.data.data;
    if (!sourceId) {
      throw new Error('Upload request failed. No source id returned');
    }
    return response.data.data;
  };

  const ingestElement = async ({ source }) => {
    if (!source) return;

    try {
      const { id: sourceId } = await ingestURL({ url: source });
      const response = await getStatus({ sourceId });
      const { attributes } = response.data.data;
      const statuses = getStatuses(attributes);

      if (statuses.includes(UPLOAD_STATUS.FAILED)) {
        throw new Error('Upload failed.');
      }

      const [proxy] = attributes?.outputs?.renditions;

      return {
        ...attributes,
        proxy,
      };
    } catch (error) {
      console.error('Ingest element error:', error);
    }
  };

  return { ingestElement };
}

export default useIngestElement;
