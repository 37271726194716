import { Form } from 'react-bootstrap';

import { useAnalytics } from '@components/analytics/Analytics';

function InputTextElement({
  className = '',
  disabled = false,
  isInvalid = false,
  defaultValue,
  handleChange,
  handleOnBlur,
  inputType,
  tracking,
  name,
  value,
  placeholder,
  maxLength,
}) {
  const { trackEvent } = useAnalytics();

  const handleBlur = (event) => {
    const inputValue = event.target.value;

    if (tracking && inputValue) {
      trackEvent(tracking.name, {
        ...tracking.properties,
        Value: inputValue,
      });
    }

    if (handleOnBlur) {
      handleOnBlur(event);
    }
  };

  return (
    <Form.Control
      type={inputType}
      className={className}
      defaultValue={defaultValue}
      name={name}
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      onBlur={handleBlur}
      onChange={handleChange}
      isInvalid={isInvalid}
      maxLength={maxLength}
    />
  );
}

export default InputTextElement;
