import { transformConfiguration, transformOptionsRequest } from '@api/transform/utils/workflow';

import { jsonParse } from '@utils/jsonParse';

export const transformWorkflowAxiosResponse = [
  (response) => {
    const { data: workflowData } = jsonParse(response);
    const { id, attributes } = workflowData || {};
    const { configuration } = attributes || {};
    const { tasks, data } = transformConfiguration(configuration);

    return {
      id,
      tasks,
      data,
      ...attributes,
    };
  },
];

export const transformWorkflowAxiosRequest = [
  (data, headers) => {
    const { name: workflowName, tasks, sampleData, mappings } = data || {};
    const [trigger, ...steps] = tasks || [];

    const configuration = {
      trigger: {
        module: trigger?.module,
        options: trigger?.options,
        sampleData,
        mappings,
      },
      steps: steps.map(({ name, module, inputs, outputs, options }) => ({
        name,
        module,
        options: transformOptionsRequest(module, options),
        mappings: {
          inputs: Object.entries(inputs || {})
            .filter(([mapsTo, key]) => mapsTo && key)
            .map(([mapsTo, key]) => ({ key, mapsTo })),
          outputs: Object.entries(outputs || {}).map(([mapsFrom, key]) => ({ key, mapsFrom })),
        },
      })),
    };

    // eslint-disable-next-line no-param-reassign
    headers['Content-Type'] = 'application/json';

    return JSON.stringify({
      name: workflowName,
      configuration,
    });
  },
];

export const transformWorkflowJobs = (response) => {
  const { links, meta, data } = response;

  const processing = data.filter((job) => job.status === 'processing');

  const groupedJobs = data
    .filter((job) => job.status !== 'processing')
    .reduce((acc, job) => {
      const date = new Date(job.created).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(job);
      return acc;
    }, {});

  const groupsOrderedByDateDesc = Object.keys(groupedJobs).sort((a, b) => new Date(b) - new Date(a));
  const groupedByDate = groupsOrderedByDateDesc.reduce((acc, group) => {
    acc[group] = groupedJobs[group];
    return acc;
  }, {});

  return {
    jobs: {
      groupedByDate,
      processing,
    },
    links,
    meta,
  };
};
