import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import {
  IconAddMedia,
  IconCode,
  IconElements,
  IconGear,
  IconMagicWand,
  IconMask,
  IconOverlay,
  IconShapes,
  IconText,
} from '@assets/icons';
import LogoEmblem from '@assets/img/logo/emblem.svg?react';

import WorkspaceSidebarPanel from '@feature/studio/workspace/WorkspaceSidebarPanel';

import { activeClipAtom } from '@store/Clips';
import { sdkOptionsSelector } from '@store/Sdk';
import { studioActiveTabAtom, studioActiveTabHoverAtom } from '@store/UI';

function getLogoComponent(isSdkCustomer, isLogoUrl, isSdkEnabled) {
  if (!isSdkCustomer || (isSdkCustomer && !isLogoUrl) || !isSdkEnabled) {
    return (
      <a href="https://shotstack.io" target="_blank" rel="noreferrer">
        <LogoEmblem />
      </a>
    );
  }
  return <img src={isLogoUrl} alt="Logo" />;
}

function getTopButtons(isSdkEnabled, logo) {
  const defaultButtons = [
    { tabId: 'text', label: 'Text', Icon: <IconText size={20} /> },
    { tabId: 'shape', label: 'Shapes', Icon: <IconShapes size={20} /> },
    { tabId: 'media', label: 'Media', Icon: <IconAddMedia size={20} /> },
    { tabId: 'overlay', label: 'Overlay', Icon: <IconOverlay size={20} /> },
    { tabId: 'mask', label: 'Mask', Icon: <IconMask size={20} /> },
    { tabId: 'elements', label: 'Elements', Icon: <IconElements size={20} /> },
    { tabId: 'ai', label: 'AI', Icon: <IconMagicWand size={20} /> },
  ].filter(Boolean);

  const sdkButtons = [
    { tabId: 'back', Icon: logo },
    { tabId: 'text', label: 'Text', Icon: <IconText size={20} /> },
    { tabId: 'shape', label: 'Shapes', Icon: <IconShapes size={20} /> },
  ];

  return isSdkEnabled ? sdkButtons : defaultButtons;
}

function getBottomButtons(isSdkEnabled, isSettings) {
  const buttons = [];

  if (!isSdkEnabled) {
    buttons.push({ tabId: 'json', label: 'JSON', Icon: <IconCode size={20} /> });
  }

  if (isSettings) {
    buttons.push({ tabId: 'setting', label: 'Settings', Icon: <IconGear size={20} /> });
  }

  return buttons;
}

function TabGroup({ buttons, activeTab, onTabClick, onTabHover }) {
  return (
    <div className="workspace__tabs">
      {buttons.map(({ tabId, label, Icon }) => (
        <button
          key={tabId}
          name={tabId}
          type="button"
          className={cn('workspace__tab', tabId, { active: activeTab === tabId })}
          onClick={onTabClick}
          onMouseEnter={onTabHover}
          onMouseLeave={onTabHover}
        >
          {Icon}
          {label && <span>{label}</span>}
        </button>
      ))}
    </div>
  );
}

function WorkspaceSidebar() {
  const navigate = useNavigate();
  const activeClipId = useRecoilValue(activeClipAtom);
  const [activeTab, setActiveTab] = useRecoilState(studioActiveTabAtom);
  const setActiveTabHover = useSetRecoilState(studioActiveTabHoverAtom);
  const { isSdkEnabled, isSdkCustomer, isLogoUrl, isSettings } = useRecoilValue(sdkOptionsSelector);
  const resetActiveClip = useResetRecoilState(activeClipAtom);

  const logo = getLogoComponent(isSdkCustomer, isLogoUrl, isSdkEnabled);
  const buttonsTop = getTopButtons(isSdkEnabled, logo);
  const buttonsBottom = getBottomButtons(isSdkEnabled, isSettings);

  const handleTabClick = (event) => {
    const clickedTabId = event.currentTarget.name;
    if (clickedTabId === 'back') {
      if (!isSdkEnabled) {
        navigate('/studio');
      }
      return;
    }
    if (clickedTabId === activeTab) {
      setActiveTab(null);
    } else {
      setActiveTab(clickedTabId);
    }
    resetActiveClip(null);
  };

  const handleTabHover = (event) => {
    if (!activeTab) {
      const hoveredTabId = event.currentTarget.name;
      if (hoveredTabId !== 'back') {
        setActiveTabHover(hoveredTabId);
      }
    }
  };

  return (
    <div className="workspace__sidebar">
      <div className={cn('workspace__tabs-container', { 'workspace__tabs-container--sdk': isSdkEnabled })}>
        <TabGroup buttons={buttonsTop} activeTab={activeTab} onTabClick={handleTabClick} onTabHover={handleTabHover} />
        <TabGroup
          buttons={buttonsBottom}
          activeTab={activeTab}
          onTabClick={handleTabClick}
          onTabHover={handleTabHover}
        />
      </div>
      <WorkspaceSidebarPanel activeClipId={activeClipId} activeTab={activeTab} />
    </div>
  );
}
export default WorkspaceSidebar;
