import axios from 'axios';
import type { AxiosInstance, AxiosRequestConfig } from 'axios';

import requestInterceptors from '@api/interceptors/request';
import responseInterceptors from '@api/interceptors/response';
import pollingInterceptors from '@api/interceptors/responsePolling';
import { transformResponseAxiosDefault } from '@api/transform/default';

type ApiConfig = AxiosRequestConfig & {
  baseURL: string;
};

export default {
  init: (config: ApiConfig): AxiosInstance => {
    const axiosConfig: AxiosRequestConfig = {
      transformResponse: transformResponseAxiosDefault,
      ...config,
    };

    const instance = axios.create(axiosConfig);
    instance.interceptors.request.use(...requestInterceptors);
    instance.interceptors.response.use(...responseInterceptors);
    instance.interceptors.response.use(...pollingInterceptors(instance));

    return instance;
  },
};
export type { ApiConfig, AxiosInstance as ApiInstance };
