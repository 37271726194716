import OverviewCreateApi from '@pages/Overview/OverviewCreateApi';
import OverviewEditApi from '@pages/Overview/OverviewEditApi';
import OverviewIngestApi from '@pages/Overview/OverviewIngestApi';
import OverviewServeApi from '@pages/Overview/OverviewServeApi';

const ApiOverviewComponents = {
  'edit-api': OverviewEditApi,
  'ingest-api': OverviewIngestApi,
  'create-api': OverviewCreateApi,
  'serve-api': OverviewServeApi,
  default: () => null,
};

function OverviewApis({ product }) {
  const ApiOverviewComponent = ApiOverviewComponents[product] || ApiOverviewComponents.default;
  return <ApiOverviewComponent />;
}

export default OverviewApis;
