import config from '@config';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useRecoilRefresher_UNSTABLE, useRecoilState, useResetRecoilState } from 'recoil';

import { showStageConfirmationModalAtom, stageAtom } from '@store/Auth';
import { allTemplatesSelector, bulkTemplateDeleteAtom } from '@store/Template';
import { allWorkflowsListSelector } from '@store/Workflows';

const isEditor = (path) => {
  return path.startsWith('/studio/editor');
};

function SaveToStage() {
  const [stage, setStage] = useRecoilState(stageAtom);
  const [showStageConfirmationModal, setShowStageConfirmationModal] = useRecoilState(showStageConfirmationModalAtom);
  const resetBulkDeleteTemplateState = useResetRecoilState(bulkTemplateDeleteAtom);
  const refreshAllTemplateState = useRecoilRefresher_UNSTABLE(allTemplatesSelector);
  const refreshAllWorkflowsState = useRecoilRefresher_UNSTABLE(allWorkflowsListSelector);

  const location = useLocation();
  const [tracking, setTracking] = useState({
    name: 'Select Environment',
    properties: {
      Location: location.pathname,
    },
  });

  useEffect(() => {
    const updatedTracking = { ...tracking };
    updatedTracking.properties = {
      Location: location.pathname,
    };

    setTracking({ ...updatedTracking });

    if (showStageConfirmationModal && !isEditor(location.pathname)) {
      setShowStageConfirmationModal(false);
    }
  }, [location.pathname, showStageConfirmationModal]);

  const handleSelect = (value) => {
    if (isEditor(location.pathname)) {
      setShowStageConfirmationModal(value);
    } else {
      setStage(value);
      refreshAllTemplateState();
      resetBulkDeleteTemplateState();
      refreshAllWorkflowsState();
    }
  };

  return (
    <div className="d-flex flex-col">
      {config.isDev && (
        <Button onClick={() => handleSelect('dev')} size="sm" variant="secondary" disabled={stage === 'dev'}>
          Development
        </Button>
      )}
      <Button onClick={() => handleSelect(stage === 'stage' ? 'v1' : 'stage')} size="sm">
        {stage === 'stage' ? 'Production' : 'Sandbox'}
      </Button>
    </div>
  );
}

export default SaveToStage;
