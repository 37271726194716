import { Col, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { useRecoilState, useRecoilValue } from 'recoil';

import WorkflowModuleName from '@feature/workflows/module/WorkflowModuleName';
import WorkflowModuleOutputs from '@feature/workflows/module/WorkflowModuleOutputs';
import WorkflowPanelHeader from '@feature/workflows/panel/WorkflowPanelHeader';

// import InputText from '@components/controls/InputText';
import {
  activeTaskAtom,
  taskInputsAtomFamily,
  taskOptionsAtomFamily,
  workflowDataListSelector,
} from '@store/Workflows';

import { SHOTSTACK_TTS_LANGUAGES, SHOTSTACK_TTS_VOICES } from '@constants/TextToSpeech';
import { noOptionsMessage } from '@constants/Workflows';

function WorkflowModuleTtsShotstack() {
  const activeTask = useRecoilValue(activeTaskAtom);
  const [taskInputs, setTaskInputs] = useRecoilState(taskInputsAtomFamily(activeTask));
  const [taskOptions, setTaskOptions] = useRecoilState(taskOptionsAtomFamily(activeTask));
  const { list, grouped } = useRecoilValue(workflowDataListSelector);

  const setStateMap = {
    inputs: setTaskInputs,
    options: setTaskOptions,
  };

  const getSelectedValue = (data, key) => {
    return data.find(({ value }) => value === key) || null;
  };

  const getVoicesOptions = () => {
    return SHOTSTACK_TTS_VOICES.filter(({ languageCode }) => languageCode === taskOptions?.language);
  };

  const handleChange = (field, type) => {
    const setState = setStateMap[type];
    return ({ value }) => {
      if (field === 'language') {
        setTaskOptions((prevState) => ({ ...prevState, voice: null }));
      }
      setState((prevState) => ({ ...prevState, [field]: value }));
    };
  };

  return (
    <>
      <div className="form-container">
        <WorkflowModuleName />
      </div>
      <div className="form-container sm">
        <WorkflowPanelHeader
          name="Configuration"
          description="The input text to convert and voice configuration settings."
        />
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Text</label>
          </Col>
          <Col>
            <ReactSelect
              unstyled
              className="react-select__inputs form-control"
              classNamePrefix="react-select"
              id="text"
              name="text"
              placeholder="Select input"
              noOptionsMessage={noOptionsMessage('data')}
              defaultValue={getSelectedValue(list, taskInputs?.text)}
              options={grouped}
              onChange={handleChange('text', 'inputs')}
            />
          </Col>
        </Row>
        <hr />
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Language</label>
          </Col>
          <Col>
            <ReactSelect
              unstyled
              className="form-control"
              classNamePrefix="react-select"
              id="language"
              name="language"
              placeholder="Select language"
              noOptionsMessage={noOptionsMessage()}
              defaultValue={getSelectedValue(SHOTSTACK_TTS_LANGUAGES, taskOptions?.language)}
              options={SHOTSTACK_TTS_LANGUAGES}
              onChange={handleChange('language', 'options')}
            />
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Voice</label>
          </Col>
          <Col>
            <ReactSelect
              unstyled
              className="form-control"
              classNamePrefix="react-select"
              id="voice"
              name="voice"
              placeholder="Select voice"
              noOptionsMessage={noOptionsMessage()}
              value={getSelectedValue(SHOTSTACK_TTS_VOICES, taskOptions?.voice)}
              options={getVoicesOptions()}
              onChange={handleChange('voice', 'options')}
            />
          </Col>
        </Row>
      </div>
      <div className="form-container">
        <WorkflowModuleOutputs
          name="Output"
          description="This action generates an MP3 audio file and outputs the file URL."
        />
      </div>
    </>
  );
}

export default WorkflowModuleTtsShotstack;
