function EmptyState({ Icon, children }) {
  return (
    <div className="emptystate">
      <div className="emptystate__icon">{Icon && <Icon size={142} />}</div>
      <div className="emptystate__text">{children}</div>
    </div>
  );
}

export default EmptyState;
