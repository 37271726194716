import { Container, Graphics } from '@inlet/react-pixi';
import { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';

import withCanvasMovable from '@feature/studio/canvas/CanvasMovable';

import ArtboardMask from '@components/masks/ArtboardMask';

import formatWebGlColor from '@utils/formatWebGlColor';

const CanvasShapePlayer = forwardRef((props, ref) => {
  const { clip, visible, x, y, width, height, alpha, scale, zIndex, angle } = props;
  const maskRef = useRef(null);

  const {
    ['asset:shape']: shape,
    ['asset:fill:color']: fillColor,
    ['asset:fill:opacity']: fillOpacity = 1,
    ['asset:stroke:color']: strokeColor,
    ['asset:stroke:width']: strokeWidth = 0,
    ['asset:rectangle:cornerRadius']: cornerRadius = 0,
    ['asset:circle:radius']: circleRadius,
    ['asset:line:length']: lineLength,
    ['asset:line:thickness']: lineThickness,
  } = clip;

  const formattedFillColor = useMemo(() => formatWebGlColor(fillColor), [fillColor]);
  const formattedStrokeColor = useMemo(() => formatWebGlColor(strokeColor), [strokeColor]);
  const adjustedStrokeWidth = useMemo(() => {
    const parsedWidth = parseInt(strokeWidth, 10);
    return isNaN(parsedWidth) ? 0 : parsedWidth;
  }, [strokeWidth]);

  const drawShape = useCallback(
    (graphics) => {
      graphics.clear();

      if (fillColor) {
        graphics.beginFill(formattedFillColor, fillOpacity);
      }

      if (adjustedStrokeWidth && strokeColor) {
        graphics.lineStyle(adjustedStrokeWidth, formattedStrokeColor);
      }

      switch (shape) {
        case 'rectangle':
          const adjustedWidth = width - adjustedStrokeWidth;
          const adjustedHeight = height - adjustedStrokeWidth;
          if (cornerRadius > 0) {
            graphics.drawRoundedRect(
              -adjustedWidth / 2,
              -adjustedHeight / 2,
              adjustedWidth,
              adjustedHeight,
              cornerRadius
            );
          } else {
            graphics.drawRect(-adjustedWidth / 2, -adjustedHeight / 2, adjustedWidth, adjustedHeight);
          }
          break;
        case 'circle':
          graphics.drawCircle(0, 0, circleRadius);
          break;
        case 'line':
          const lineWidth = Math.max(1, lineLength);
          const lineHeight = Math.max(1, lineThickness);
          graphics.drawRect(-lineWidth / 2, -lineHeight / 2, lineWidth, lineHeight);
          break;
      }

      if (fillColor) {
        graphics.endFill();
      }
    },
    [
      shape,
      formattedFillColor,
      fillOpacity,
      formattedStrokeColor,
      strokeWidth,
      width,
      height,
      cornerRadius,
      circleRadius,
      lineLength,
      lineThickness,
    ]
  );

  useEffect(() => {
    if (ref.current && maskRef.current) {
      ref.current.mask = maskRef.current;
    }
  }, [ref]);

  return (
    <>
      <Container
        ref={ref}
        anchor={0.5}
        visible={visible}
        x={x}
        y={y}
        width={width}
        height={height}
        alpha={alpha}
        scale={scale}
        zIndex={zIndex}
        angle={angle}
      >
        <Graphics draw={drawShape} />
      </Container>
      <ArtboardMask ref={maskRef} />
    </>
  );
});

export default withCanvasMovable(CanvasShapePlayer);
