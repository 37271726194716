import WorkflowTaskBlock from '@feature/workflows/task/WorkflowTaskBlock';

function End({ active, handleEditTask, name }) {
  return (
    <WorkflowTaskBlock type="end" active={active} connected={false} onClick={handleEditTask}>
      <WorkflowTaskBlock.Header type="end">{name || 'Set where your video is hosted'}</WorkflowTaskBlock.Header>
    </WorkflowTaskBlock>
  );
}

export default End;
