import { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import WorkflowPanelHeader from '@feature/workflows/panel/WorkflowPanelHeader';

import InputTextarea from '@components/controls/InputTextarea';

import { activeWorkflowAtom, workflowDataMappingAtomFamily, workflowDataSampleAtomFamily } from '@store/Workflows';

import { jsonParse } from '@utils/jsonParse';

const workflowDataPlaceholder = JSON.stringify(
  {
    name: 'John',
    company: 'Shotstack',
    message: 'Hi John, welcome to Shotstack',
  },
  null,
  2
);

function WorkflowPanelTriggerData() {
  const activeWorkflow = useRecoilValue(activeWorkflowAtom);
  const setWorkflowMapping = useSetRecoilState(workflowDataMappingAtomFamily(activeWorkflow));
  const [workflowData, setWorkflowData] = useRecoilState(workflowDataSampleAtomFamily(activeWorkflow));
  const [validJson, setValidJson] = useState(true);

  const handleDataChange = (e) => {
    const { value } = e.currentTarget;
    setValidJson(true);
    setWorkflowData(value);
  };

  const handleDataBlur = () => {
    if (!workflowData) {
      setWorkflowMapping({});
      return;
    }

    try {
      const jsonData = jsonParse(workflowData);
      setWorkflowMapping(Object.keys(jsonData).reduce((acc, key) => ({ ...acc, [key]: key }), {}));
      setValidJson(true);
      setWorkflowData(JSON.stringify(jsonData, null, 2));
    } catch (error) {
      console.error(error);
      setValidJson(false);
      setWorkflowMapping({});
    }
  };

  return (
    <div className="form-container">
      <WorkflowPanelHeader
        name="Test Data"
        description="Enter sample JSON data to use as the test data source for this workflow."
      />
      <InputTextarea
        id="workflow-sample-data"
        className="workflow-sample-data"
        rows="10"
        placeholder={workflowDataPlaceholder}
        value={workflowData}
        handleChange={handleDataChange}
        handleBlur={handleDataBlur}
      />
      {!validJson && (
        <span className="input-error">
          The data you entered is not valid JSON. Ensure it is formatted correctly, i.e. {`{ "key": "value" }`}. Check
          for missing commas, quotes, brackets, etc.
        </span>
      )}
    </div>
  );
}

export default WorkflowPanelTriggerData;
