import cn from 'classnames';
import { Button } from 'react-bootstrap';

import WorkflowTaskIcon from '@feature/workflows/task/WorkflowTaskIcon';

import { useActivateTaskOfType } from '@store/Workflows';

import { PANEL_INTRO_CARDS } from '@constants/Workflows';

function WorkflowIntro() {
  const activateTask = useActivateTaskOfType();

  const handleCardClick = (type) => () => {
    activateTask(type);
  };

  return (
    <div className="workflow-intro form-container">
      <p>
        Quickly and easily build workflows without code by combining Triggers and Actions. Start with a Trigger and
        combine together as many Actions as you need to build new media experiences.
      </p>
      {PANEL_INTRO_CARDS.map(({ type, title, description, buttonText }) => {
        return (
          <div key={title}>
            <div className={cn('workflow-intro__card', { [type]: true })}>
              <div className="workflow-intro__title">
                <WorkflowTaskIcon type={type} containerClass="workflow-taskblock__icon" />
                <h2>{title}</h2>
              </div>
              <p>{description}</p>
              <Button variant="light" onClick={handleCardClick(type)}>
                {buttonText}
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default WorkflowIntro;
