import { startCase } from 'lodash-es';

export const FORMAT_VIDEO = ['avi', 'gif', 'mkv', 'mp3', 'mp4', 'mov', 'ogv', 'webm', 'wav'];
export const FORMAT_IMAGE = ['avif', 'gif', 'jpg', 'png', 'tif', 'webp'];
export const FIT = ['crop', 'cover', 'contain'];
export const FPS = [12, 15, 24, 23.976, 25, 29.97, 30, 48, 50, 59.94, 60];

export const FORMAT_VIDEO_SELECT = [
  {
    label: 'Maintain Format',
    value: undefined,
  },
  ...FORMAT_VIDEO.map((format) => ({
    label: format.toUpperCase(),
    value: format,
  })),
];

export const FORMAT_IMAGE_SELECT = [
  {
    label: 'Maintain Format',
    value: undefined,
  },
  ...FORMAT_IMAGE.map((format) => ({
    label: format.toUpperCase(),
    value: format,
  })),
];

export const FIT_SELECT = [
  {
    label: 'Maintain Aspect Ratio',
    value: undefined,
  },
  ...FIT.map((fit) => ({
    label: startCase(fit),
    value: fit,
  })),
];

export const FPS_SELECT = [
  {
    label: 'Maintain FPS',
    value: undefined,
  },
  ...FPS.map((fps) => ({
    label: fps,
    value: fps,
  })),
];
