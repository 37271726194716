import config from '@config';
import { Col, Row } from 'react-bootstrap';

import ElevenLabsForm from '@feature/integrations/providers/elevenlabs/ElevenLabsForm';

import AlphaBadge from '@components/badge/alpha';
import ContentCard from '@components/cards/ContentCard';
import Header from '@components/header/Header';

function ElevenLabs() {
  const Forms = config.integration.stages.map((stage, index) => (
    <ElevenLabsForm key={stage.stage} stage={stage.stage} stageName={stage.name} index={index} />
  ));

  return (
    <div className="integration">
      <Header title="ElevenLabs Text-to-Speech">
        ElevenLabs brings the most compelling, rich and lifelike voices to creators and publishers seeking the ultimate
        tools for storytelling.
      </Header>
      <Row className="mb-4">
        <Col md={4}>
          <ContentCard image={`/img/provider/elevenlabs.png`}>
            <AlphaBadge />
            ElevenLabs Voice Library provides you with the ability to personalise a wide range of voices for your video
            or application. Integrate with your ElevenLabs account to use their text-to-speech functionality with
            Shotstack.
            <br /> <br />
            *Note that this integration is currently on alpha and is subject to change.
          </ContentCard>
        </Col>
        <Col md={8}>
          <ContentCard heading="SETUP">
            <p>
              Sign up to <a href="https://beta.elevenlabs.io">ElevenLabs</a> if you do not already have an account. Copy
              your API Key and paste it in the form below.
            </p>
          </ContentCard>
          <ContentCard heading="CREDENTIALS">{Forms}</ContentCard>
          <ContentCard heading="INTEGRATION">
            <p>
              Select a <a href="https://shotstack.io/docs/api#tocs_elevenlabstexttospeechoptions">voice</a> and send the
              following JSON payload in the body of the request to generate an asset:
            </p>
            <pre>
              <b>POST</b> https://api.shotstack.io/create/&#123;ENV&#125;/assets
            </pre>
            <p>
              Replace <code>&#123;ENV&#125;</code> with either stage or v1 in the URL above depending on the environment
              and API key you are using.
            </p>
            <p>Send the following JSON payload in the body of the request to generate an asset:</p>
            <pre>{`{
    "provider": "elevenlabs",
    "options": {
        "type": "text-to-speech",
        "text": "This is a text to speech asset generated by ElevenLabs",
        "voice": "Adam"
    }
}`}</pre>
            <p>
              Check the <a href="https://shotstack.io/docs/api/#tocs_elevenlabsgeneratedasset">API docs</a> for more
              information.
            </p>
          </ContentCard>
        </Col>
      </Row>
    </div>
  );
}

export default ElevenLabs;
