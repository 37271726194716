import { AxiosResponse } from 'axios';

type Listener = (data: AxiosResponse) => void;
type Topics = { [key: string]: Listener[] };
type Unsubscribe = () => void;

class PollDataManager {
  private topics: Topics;

  constructor() {
    this.topics = {};
  }

  subscribe(topic: string, listener: Listener): Unsubscribe {
    if (!this.topics[topic]) {
      this.topics[topic] = [];
    }
    this.topics[topic].push(listener);
    const index = this.topics[topic].length - 1;

    // Return unsubscribe function
    return () => {
      this.topics[topic].splice(index, 1);
    };
  }

  publish(topic: string, data: AxiosResponse): void {
    if (!this.topics[topic] || this.topics[topic].length === 0) {
      return;
    }
    this.topics[topic].forEach((listener) => listener(data));
  }
}

export const pollDataManager = new PollDataManager();
