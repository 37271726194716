import { useState } from 'react';
import { useRecoilRefresher_UNSTABLE, useResetRecoilState, useSetRecoilState } from 'recoil';

import { createTemplate, createTemplateFromDefault } from '@api/services/template';

import { allTemplatesSelector } from '@store/Template';
import { errorAtom } from '@store/UI';

const useCreateTemplate = ({ onSuccess } = {}) => {
  const [loading, setLoading] = useState(false);
  const setErrorMessage = useSetRecoilState(errorAtom);
  const resetErrorMessage = useResetRecoilState(errorAtom);
  const refreshAllTemplateState = useRecoilRefresher_UNSTABLE(allTemplatesSelector);

  const handleCreate = async () => {
    setLoading(true);
    try {
      const templateId = await createTemplateFromDefault();
      refreshAllTemplateState();
      onSuccess(templateId);
    } catch (error) {
      setErrorMessage({ message: error.message });
      setTimeout(() => {
        resetErrorMessage();
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateWithData = async (name, template) => {
    setLoading(true);
    try {
      const templateId = await createTemplate({ name, template });
      refreshAllTemplateState();
      onSuccess(templateId);
    } catch (error) {
      setErrorMessage({ message: error.message });
      setTimeout(() => {
        resetErrorMessage();
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  return { loading, handleCreate, handleCreateWithData };
};

export default useCreateTemplate;
