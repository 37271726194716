import cn from 'classnames';

import '@css/Skeleton.css';

function Skeleton({ length = 1, width = '100%', height = '80px', className }) {
  return (
    <div className={cn('skeleton-wrapper', className)}>
      {Array.from({ length }).map((_, index) => (
        <div key={`skeleton-${index}`} className="skeleton-item" style={{ width, height }} />
      ))}
    </div>
  );
}

export default Skeleton;
