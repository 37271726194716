import * as videoEffects from '@assets/videos/effects';

import Effect from '@components/effects/Effect';

import allMotionEffects from '@constants/MotionEffects';

const getFullEffectValue = ({ value, speed }) => {
  if (!value) {
    return undefined;
  }
  return [value, speed].join('');
};

function Effects({ selected, speed, onEffectChange }) {
  return (
    <div className="property-previews effects">
      {allMotionEffects.map(({ value, text }) => {
        const effectLabel = getFullEffectValue({ value, speed });
        const effectVideo = videoEffects[effectLabel];
        return (
          <Effect
            key={value}
            label={text}
            value={value}
            active={selected === value}
            effect={effectVideo}
            onSelect={onEffectChange}
          />
        );
      })}
    </div>
  );
}

export default Effects;
