import { Deserializer } from 'jsonapi-serializer';

export const ApiDeserializer = new Deserializer({ keyForAttribute: (key) => key });

export const deserialize = async (data) => {
  const processData = async (item) => {
    if (!item?.attributes) {
      return item;
    }

    try {
      const deserialized = await ApiDeserializer.deserialize({ data: item });
      return deserialized;
    } catch (error) {
      console.error('Error deserializing response', error);
      return item;
    }
  };

  if (Array.isArray(data)) {
    return Promise.all(data.map(processData));
  } else {
    return processData(data);
  }
};
