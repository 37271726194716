import { Col, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { useRecoilState, useRecoilValue } from 'recoil';

import WorkflowModuleName from '@feature/workflows/module/WorkflowModuleName';
import WorkflowModuleOutputs from '@feature/workflows/module/WorkflowModuleOutputs';
import WorkflowPanelHeader from '@feature/workflows/panel/WorkflowPanelHeader';

import { activeTaskAtom, taskInputsAtomFamily, workflowDataListSelector } from '@store/Workflows';

import { noOptionsMessage } from '@constants/Workflows';

function WorkflowModuleCaptions() {
  const activeTask = useRecoilValue(activeTaskAtom);
  const [taskInputs, setTaskInputs] = useRecoilState(taskInputsAtomFamily(activeTask));
  const { list, grouped } = useRecoilValue(workflowDataListSelector);

  const getSelectedValue = (data, key) => {
    return data.find(({ value }) => value === key) || undefined;
  };

  const handleUrlChange = ({ value }) => {
    setTaskInputs((prevState) => ({ ...prevState, url: value }));
  };

  return (
    <>
      <div className="form-container">
        <WorkflowModuleName />
      </div>
      <div className="form-container sm">
        <WorkflowPanelHeader
          name="Configuration"
          description="Select the audio or video file you'd like to create an SRT transcript from."
        />
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>File URL</label>
          </Col>
          <Col>
            <ReactSelect
              unstyled
              className="react-select__inputs form-control"
              classNamePrefix="react-select"
              id="url"
              name="url"
              placeholder="Select input"
              noOptionsMessage={noOptionsMessage('data')}
              defaultValue={getSelectedValue(list, taskInputs?.url)}
              options={grouped}
              onChange={handleUrlChange}
            />
          </Col>
        </Row>
      </div>
      <div className="form-container">
        <WorkflowModuleOutputs name="Output" description="his action generates an SRT file and outputs the file URL." />
      </div>
    </>
  );
}

export default WorkflowModuleCaptions;
