import {
  carouselDownTransitionInKeyframe,
  carouselDownTransitionOutKeyframe,
  carouselLeftTransitionInKeyframe,
  carouselLeftTransitionOutKeyframe,
  carouselRightTransitionInKeyframe,
  carouselRightTransitionOutKeyframe,
  carouselUpTransitionInKeyframe,
  carouselUpTransitionOutKeyframe,
} from '@animation/preset/transition/carousel';
import { fadeTransitionInKeyframe, fadeTransitionOutKeyframe } from '@animation/preset/transition/fade';
import {
  slideDownTransitionInKeyframe,
  slideDownTransitionOutKeyframe,
  slideLeftTransitionInKeyframe,
  slideLeftTransitionOutKeyframe,
  slideRightTransitionInKeyframe,
  slideRightTransitionOutKeyframe,
  slideUpTransitionInKeyframe,
  slideUpTransitionOutKeyframe,
} from '@animation/preset/transition/slide';
import { zoomTransitionInKeyframe, zoomTransitionOutKeyframe } from '@animation/preset/transition/zoom';

/**
 * @typedef {'fade' | 'zoom' | 'slideLeft' | 'slideRight' | 'slideUp' | 'slideDown' | 'carouselLeft' | 'carouselRight' | 'carouselUp' | 'carouselDown'} Transition
 * @typedef {'normal' | 'fast' | 'slow'} TransitionSpeed
 * @typedef {'in' | 'out'} TransitionType
 */

const DURATION_VERY_FAST = 0.25;
const DURATION_FASTER = 0.4;
const DURATION_FAST = 0.5;
const DURATION_NORMAL = 1.0;
const DURATION_SLOW = 2.0;

/**
 * @type {{ [key in Transition]?: { [key in TransitionSpeed]?: number } }}
 */
const transitionDurationMap = {
  zoom: { normal: DURATION_FASTER, slow: DURATION_FASTER, fast: DURATION_FASTER },
  fade: { normal: DURATION_NORMAL, slow: DURATION_SLOW, fast: DURATION_FAST },
  slideLeft: { normal: DURATION_FAST, slow: DURATION_NORMAL, fast: DURATION_VERY_FAST },
  slideRight: { normal: DURATION_FAST, slow: DURATION_NORMAL, fast: DURATION_VERY_FAST },
  slideUp: { normal: DURATION_FAST, slow: DURATION_NORMAL, fast: DURATION_VERY_FAST },
  slideDown: { normal: DURATION_FAST, slow: DURATION_NORMAL, fast: DURATION_VERY_FAST },
  carouselLeft: { normal: DURATION_FAST, slow: DURATION_NORMAL, fast: DURATION_VERY_FAST },
  carouselRight: { normal: DURATION_FAST, slow: DURATION_NORMAL, fast: DURATION_VERY_FAST },
  carouselUp: { normal: DURATION_FAST, slow: DURATION_NORMAL, fast: DURATION_VERY_FAST },
  carouselDown: { normal: DURATION_FAST, slow: DURATION_NORMAL, fast: DURATION_VERY_FAST },
};

/**
 * @type {(transition: string, speed: string) => number}
 */
export const getTransitionDuration = (transition, speed) => {
  const transitionSpeed = transitionDurationMap[transition]?.[speed];
  return transitionSpeed ?? DURATION_NORMAL;
};

/**
 * @type {(preset: string) => ({ transition: Transition, transitionSpeed: TransitionSpeed })}
 */
export const parseTransition = (preset) => {
  /** @ts-ignore @type {Transition} */
  const transition = preset.split(/Fast|Slow/).shift();

  /** @type {TransitionSpeed} */
  let transitionSpeed = 'normal';
  if (preset.endsWith('Fast')) transitionSpeed = 'fast';
  if (preset.endsWith('Slow')) transitionSpeed = 'slow';

  return { transition, transitionSpeed };
};

/**
 * @type {(preset: Transition, type: TransitionType) => (start: number, end: number, properties: import('./animate').AnimationProperties) => import('@animation/keyframe').Keyframe<{}>}
 */
export const getTransitionKeyframePreset = (preset, type) => {
  switch (preset) {
    case 'fade':
      return type === 'in' ? fadeTransitionInKeyframe : fadeTransitionOutKeyframe;
    case 'zoom':
      return type === 'in' ? zoomTransitionInKeyframe : zoomTransitionOutKeyframe;
    case 'slideLeft':
      return type === 'in' ? slideLeftTransitionInKeyframe : slideLeftTransitionOutKeyframe;
    case 'slideRight':
      return type === 'in' ? slideRightTransitionInKeyframe : slideRightTransitionOutKeyframe;
    case 'slideUp':
      return type === 'in' ? slideUpTransitionInKeyframe : slideUpTransitionOutKeyframe;
    case 'slideDown':
      return type === 'in' ? slideDownTransitionInKeyframe : slideDownTransitionOutKeyframe;
    case 'carouselLeft':
      return type === 'in' ? carouselLeftTransitionInKeyframe : carouselLeftTransitionOutKeyframe;
    case 'carouselRight':
      return type === 'in' ? carouselRightTransitionInKeyframe : carouselRightTransitionOutKeyframe;
    case 'carouselUp':
      return type === 'in' ? carouselUpTransitionInKeyframe : carouselUpTransitionOutKeyframe;
    case 'carouselDown':
      return type === 'in' ? carouselDownTransitionInKeyframe : carouselDownTransitionOutKeyframe;
    default:
      return null;
  }
};

export const getTransitionKeyframes = ({ clip, canvas }) => {
  const presetKeyframes = [];

  if (clip['transition:in']) {
    const { transition: transitionIn, transitionSpeed: transitionInSpeed } = parseTransition(clip['transition:in']);
    const transitionInDuration = getTransitionDuration(transitionIn, transitionInSpeed);
    const transitionInKeyframePreset = getTransitionKeyframePreset(transitionIn, 'in');

    if (transitionInKeyframePreset) {
      presetKeyframes.push(transitionInKeyframePreset(0, transitionInDuration, { canvas, clip }));
    }
  }

  if (clip['transition:out']) {
    const { transition: transitionOut, transitionSpeed: transitionOutSpeed } = parseTransition(clip['transition:out']);
    const transitionOutDuration = getTransitionDuration(transitionOut, transitionOutSpeed);
    const transitionOutKeyframePreset = getTransitionKeyframePreset(transitionOut, 'out');

    if (transitionOutKeyframePreset)
      presetKeyframes.push(
        transitionOutKeyframePreset(clip.length - transitionOutDuration, clip.length, { canvas, clip })
      );
  }

  return presetKeyframes;
};
