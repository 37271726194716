import ModuleConfiguration from '@feature/workflows/module/WorkflowModuleConfiguration';
import ModuleSelector from '@feature/workflows/module/WorkflowModuleSelector';

function WorkflowPanelAction() {
  return (
    <div className="settings">
      <ModuleSelector />
      <ModuleConfiguration />
    </div>
  );
}

export default WorkflowPanelAction;
