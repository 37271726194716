import { Col, Row } from 'react-bootstrap';

import AfterEffectsForm from '@feature/integrations/providers/afterEffects/AfterEffectsForm';

import AlphaBadge from '@components/badge/alpha';
import ContentCard from '@components/cards/ContentCard';
import Header from '@components/header/Header';

function AfterEffects() {
  return (
    <div className="integration">
      <Header title="After Effects">Ingest your After Effect compositions.</Header>
      <Row className="mb-4">
        <Col md={4}>
          <ContentCard image={`/img/provider/after-effects.png`}>
            <AlphaBadge />
            Adobe After Effects is a digital visual effects, motion graphics, and compositing application developed by
            Adobe Inc.; it is used in the post-production process of film making, video games and television production.
            Among other things, After Effects can be used for keying, tracking, compositing, and animation.
            <br /> <br />
            *Note that this integration is currently on alpha and is subject to change.
          </ContentCard>
        </Col>
        <Col md={8}>
          <ContentCard heading="SETUP">
            <p>
              Download the Shotstack{' '}
              <a href="https://shotstack-assets.s3.amazonaws.com/scripts/Export%20Shotstack%20JSON.jsx">
                After Effects plugin{' '}
              </a>
              and add this to your Scripts folder. Your scripts folder can be found in the following locations:
            </p>
            <h5>Windows</h5>
            <pre>C:\Program Files\Adobe\Adobe After Effects {'<version>'}\Support Files\Scripts</pre>
            <h5>Mac</h5>
            <pre>/Applications/Adobe After Effects {'<version>'}/Scripts/</pre>
          </ContentCard>
          <ContentCard heading="RUNNING THE SCRIPT">
            <p>
              You will need to restart After Effects for the plugin to appear in the Window menu. You can then run the
              plugin from the File menu by navigating to Scripts and selecting the &quot;Export to Shotstack JSON&quot;
              script.
            </p>
            <p>
              This will generate a JSON file you can use inside of this integration. Make sure to save the file with a
              .json extension.
            </p>
          </ContentCard>
          <ContentCard heading="IMPORTING YOUR COMPOSITION">
            <p>
              Upload the JSON file below. This will provide you with a list of assets used in the composition and the
              ability to upload these to Shotstack. After uploading your assets you can create a template and start
              creating videos.
            </p>
            <p>
              You do not have to upload your assets. By creating a skeleton template we will only import timings with
              placeholder assets. This is useful if you want to create a template and add the media later.
            </p>
          </ContentCard>
          <ContentCard heading="LIMITATIONS">
            <ul>
              <li>
                The script currently only supports image, video, audio, solid and text layers. All other layers will be
                ignored.
              </li>
              <li>
                This script has been tested on After Effects version 18. It may not work as expected on other versions.
              </li>
            </ul>
          </ContentCard>
        </Col>
      </Row>
      <Row>
        <Col>
          <ContentCard heading="UPLOAD FROM AFTER EFFECTS">
            <AfterEffectsForm />
          </ContentCard>
        </Col>
      </Row>
    </div>
  );
}

export default AfterEffects;
