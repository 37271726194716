import { memo } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

import { IconRedo, IconUndo } from '@assets/icons';

function UndoRedoButtons({ undo, undoDisabled, redo, redoDisabled }) {
  return (
    <ButtonGroup className="workspace__actionbuttons">
      <Button title="Undo" variant="light" onClick={undo} disabled={undoDisabled}>
        <IconUndo size={20} />
      </Button>
      <Button title="Redo" variant="light" onClick={redo} disabled={redoDisabled}>
        <IconRedo size={20} />
      </Button>
    </ButtonGroup>
  );
}

export default memo(UndoRedoButtons);
