import { interpolateKeyframe } from '@animation/keyframe';

import { enabledKeyframeProperties } from '@constants/Keyframes';

const animationCache = {};

/**
 * Creates interpolated values for a given set of keyframes at a specific time.
 *
 * @template {Record<string, number>} T
 * @param {Object} props - The properties for creating interpolated values.
 * @param {import('@animation/keyframe').Keyframe<T>[]} props.keyframes - The keyframes to interpolate.
 * @param {number} props.time - The current time to interpolate at.
 * @returns {Record<string, number>} - The interpolated values.
 */
export const createInterpolatedValues = (props) => {
  if (!props.keyframes.length) return {};

  const { keyframes, time } = props;
  return keyframes.reduce((acc, keyframe) => {
    const { start, end } = keyframe;
    const isKeyFrameActive = start <= time && end >= time;
    if (!isKeyFrameActive) return acc;

    const newInterpolatedValues = interpolateKeyframe(keyframe, time);
    return { ...acc, ...newInterpolatedValues };
  }, {});
};

/**
 * Retrieves the initial or interpolated value for a specific property of a clip.
 *
 * @param {string} clipId - The ID of the clip.
 * @param {string} property - The property to retrieve the value for.
 * @param {number} time - The current time.
 * @param {import('@animation/keyframe').Keyframe<any>[]} keyframes - The keyframes of the clip.
 * @param {Record<string, number>} [interpolatedValues] - The interpolated values.
 * @returns {number | undefined} - The initial or interpolated value for the property.
 */
const getInitialOrInterpolatedValue = (clipId, property, time, keyframes, interpolatedValues) => {
  if (!Array.isArray(keyframes) || !keyframes.length) {
    return null;
  }

  const relevantKeyframe = keyframes.find((kf) => kf.from && kf.from[property] !== undefined);
  if (relevantKeyframe && time < relevantKeyframe.start) {
    return relevantKeyframe.from[property];
  }
  return (
    (interpolatedValues && interpolatedValues[property]) ?? (animationCache[clipId] && animationCache[clipId][property])
  );
};

/**
 * Updates the animation cache with the provided values for a specific clip.
 *
 * @param {string} clipId - The ID of the clip.
 * @param {Record<string, number>} values - The values to update the cache with.
 */
const updateAnimationCache = (clipId, values) => {
  if (!animationCache[clipId]) {
    animationCache[clipId] = {};
  }

  animationCache[clipId] = values;
};

/**
 * Creates the animation values for a specific clip at a given time.
 *
 * @param {Object} config - The configuration for creating the clip animation.
 * @param {number} config.playhead - The current playhead time.
 * @param {Object} config.keyframes - The keyframes of the clip.
 * @param {import('@animation/keyframe').Keyframe<any>[]} config.keyframes.values - The array of keyframes.
 * @param {number} config.keyframes.count - The number of keyframes.
 * @param {Object} config.clip - The clip object.
 * @param {Object} [config.asset] - The asset object.
 * @param {number} [config.asset.width] - The width of the asset.
 * @param {number} [config.asset.height] - The height of the asset.
 * @param {Object} config.canvas - The canvas dimensions.
 * @param {number} config.canvas.width - The width of the canvas.
 * @param {number} config.canvas.height - The height of the canvas.
 * @returns {Object} - The animated values for the clip.
 */
export const createClipAnimation = (config) => {
  const { playhead, keyframes, clip, asset } = config;

  const staticValues = {
    ...clip,
    width: asset?.width,
    height: asset?.height,
  };

  if (keyframes.count === 0) {
    return staticValues;
  }

  const time = playhead - clip.start;

  const interpolatedValues = createInterpolatedValues({
    keyframes: keyframes.values,
    time,
  });

  if (keyframes.custom) {
    const animatedValues = enabledKeyframeProperties.reduce((acc, prop) => {
      const value = getInitialOrInterpolatedValue(clip.id, prop, time, keyframes.values, interpolatedValues);
      if (value !== null && value !== undefined) {
        acc[prop] = value;
      }
      return acc;
    }, {});

    updateAnimationCache(clip.id, animatedValues);

    return { ...staticValues, ...animatedValues };
  }

  return { ...staticValues, ...interpolatedValues };
};
