import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth0 } from '@context/Auth0ReactSPA';
import Auth0TokenContext from '@context/Auth0TokenContext';

import View from '@feature/integrations/IntegrationsView';

import Layout from '@components/layout/Layout';

function Integration() {
  const { loading, user, getTokenSilently } = useAuth0();
  const { provider } = useParams();
  const [token, setToken] = useState();

  useEffect(() => {
    async function getToken() {
      const auth0Token = await getTokenSilently();
      setToken(auth0Token);
    }

    if (!loading) {
      getToken();
    }
  }, [loading, getTokenSilently]);

  if (loading || !user) {
    return <div>Loading...</div>;
  }

  return (
    <Auth0TokenContext.Provider value={token}>
      <Layout className="integrations">
        <View provider={provider} />
      </Layout>
    </Auth0TokenContext.Provider>
  );
}

export default Integration;
