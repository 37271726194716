import { Link } from 'react-router-dom';
import { Scrollbar } from 'react-scrollbars-custom';
import { useRecoilValue } from 'recoil';

import SettingDestination from '@feature/studio/setting/SettingDestination';

import { destinationIdsAtom } from '@store/Output';

function SettingDestinations() {
  const destinationIds = useRecoilValue(destinationIdsAtom);

  return (
    <Scrollbar noScrollX style={{ width: '100%', height: '100%' }}>
      <div className="workspace__panel-inner">
        <div className="mb-4">Integrations</div>

        {destinationIds.map((id) => (
          <SettingDestination key={id} id={id} />
        ))}

        <p className="d-block mt-3 text-center p-4">
          <small>
            Make sure to configure your destinations on the <Link to="/integrations">integrations</Link> page first.
          </small>
        </p>
      </div>
    </Scrollbar>
  );
}

export default SettingDestinations;
