import { Sound } from '@pixi/sound';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

// @ts-ignore TODO: fix this
import { clipCanvasSelectorFamily, clipVisibilitySelectorFamily } from '@store/EditSelectors';
// @ts-ignore TODO: fix this
import { playbackMuteAtom, playheadAtom, playheadIsPausedAtom, playheadIsStoppedAtom } from '@store/Timeline';

// @ts-ignore TODO: fix this
import roundToPrecision from '@utils/math/roundToPrecision';

type ClipCanvasData = {
  start: number;
  'asset:src': string;
  'asset:trim': number;
  'asset:volume': number;
};

type CanvasAudioPlayerProps = {
  id: string;
};

type PlayOptions = {
  start: number;
};

function CanvasAudioPlayer({ id }: CanvasAudioPlayerProps) {
  const {
    start,
    ['asset:src']: src,
    ['asset:trim']: trim,
    ['asset:volume']: volume,
  } = useRecoilValue<ClipCanvasData>(clipCanvasSelectorFamily(id));
  const playhead = useRecoilValue<number>(playheadAtom);
  const isPaused = useRecoilValue<boolean>(playheadIsPausedAtom);
  const isStopped = useRecoilValue<boolean>(playheadIsStoppedAtom);
  const isMuted = useRecoilValue<boolean>(playbackMuteAtom);
  const [sound, setSound] = useState<Sound | undefined>();
  const [isSoundReady, setIsSoundReady] = useState(false);
  const visible = useRecoilValue<boolean>(clipVisibilitySelectorFamily(id));

  useEffect(() => {
    let pixiSound: Sound | undefined;

    async function initSound() {
      try {
        if (!src) {
          return;
        }

        const soundSrc = new URL(src).href;
        pixiSound = Sound.from({
          url: soundSrc,
          preload: true,
          singleInstance: false,
          loaded: (err, sound) => {
            if (err) {
              console.error('Error loading sound:', err);
              return;
            }
            if (sound) {
              setSound(sound);
              setIsSoundReady(true);
            }
          },
        });
      } catch (error) {
        console.error('Failed to initialise sound:', error);
        setSound(undefined);
        setIsSoundReady(false);
      }
    }

    initSound();

    return () => {
      if (pixiSound) {
        try {
          pixiSound.stop();
          pixiSound.destroy();
        } catch (error) {
          console.error('Error cleaning up sound:', error);
        }
      }
      setSound(undefined);
      setIsSoundReady(false);
    };
  }, [src]);

  const play = useCallback(
    ({ start: startTime }: PlayOptions) => {
      if (!sound?.isLoaded) return;
      sound.play({
        start: startTime,
        volume: volume >= 0 && !isMuted ? volume : 0,
      });
    },
    [volume, isMuted, sound]
  );

  const stop = useCallback(() => {
    if (!sound?.isLoaded || !sound?.isPlaying || isMuted) return;
    sound.stop();
  }, [isMuted, sound]);

  useEffect(() => {
    try {
      if (sound?.isPlaying && (!visible || isPaused || isStopped)) {
        stop();
        return;
      }

      if (visible && !isPaused && !isStopped) {
        const soundStart = (trim || 0) + roundToPrecision(playhead - start);
        play({ start: soundStart });
      }
    } catch (error) {
      console.error(error);
    }
  }, [isMuted, isPaused, isStopped, visible]);

  return null;
}

export default CanvasAudioPlayer;
