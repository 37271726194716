export const stageMap = {
  dev: 'dev',
  v1: 'prod',
  stage: 'stage',
};

export const stageLabelsMap = {
  DEV: 'Development',
  STAGE: 'Sandbox',
  PROD: 'Production',
  v1: 'Production',
};

export const stageColorsMap = {
  DEV: 'dark',
  STAGE: 'warning',
  PROD: 'primary',
};
