import { useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';

import OverrideMenu from '@components/mergeFields/OverrideMenu';

import { useAddFontState, usePurgeUnusedFontsState } from '@store/Edit';
import { clipSettingsSelectorFamily, fontListByCategorySelector } from '@store/EditSelectors';

function FontSelect({ id, type }) {
  const [clip, setClip] = useRecoilState(clipSettingsSelectorFamily(id));
  const fontsListByCategory = useRecoilValueLoadable(fontListByCategorySelector);
  const addFont = useAddFontState();
  const purgeUnusedFonts = usePurgeUnusedFontsState();
  const documentRef = useRef(document);
  const fontFamilyPath = type === 'html' ? 'asset:fontFamily' : 'asset:font:family';

  const processSelectedFont = async (selectedOption) => {
    if (/https:\/\/fonts\.gstatic\.com\//.test(selectedOption.value)) {
      try {
        const fontFace = new FontFace(selectedOption.family, `url(${selectedOption.value})`);
        const loadedFace = await fontFace.load();
        await loadedFace.loaded;
        documentRef.current.fonts.add(loadedFace);
      } catch (error) {
        console.error('Failed to load font:', error);
      }

      try {
        await purgeUnusedFonts();
        addFont({ src: selectedOption.value });
      } catch (error) {
        console.error('Failed to add font', error);
      }
    }
  };

  const handleFontFamilyUpdate = async (selectedOption) => {
    await processSelectedFont(selectedOption);
    // console.log({ selectedOption });
    setClip({ [fontFamilyPath]: selectedOption.family });
  };

  const loadFontOptions = async (inputValue) => {
    const data = await fontsListByCategory.contents;
    const { uploaded = [], default: defaultFonts = [], google = [] } = data;
    const allFonts = [...uploaded, ...defaultFonts, ...google];
    const filteredFonts = allFonts.filter((font) => font.label.toLowerCase().includes(inputValue.toLowerCase()));

    return [
      { label: 'Uploaded Fonts', options: filteredFonts.filter((font) => uploaded.includes(font)) },
      { label: 'Default Fonts', options: filteredFonts.filter((font) => defaultFonts.includes(font)) },
      { label: 'Google Fonts', options: filteredFonts.filter((font) => google.includes(font)) },
    ].filter((group) => group.options.length > 0);
  };

  return (
    <Row className="mb-2">
      <Col md={12} className="d-flex align-items-center justify-content-between gap-2">
        <AsyncSelect
          cacheOptions
          defaultOptions
          loadOptions={loadFontOptions}
          onChange={handleFontFamilyUpdate}
          placeholder="Select or type a font"
          className="w-100"
          value={{
            value: clip[fontFamilyPath],
            label: clip[fontFamilyPath],
            family: clip[fontFamilyPath],
          }}
          styles={{
            option: (provided, state) => ({
              ...provided,
              fontFamily: state.data.family,
            }),
            singleValue: (provided, state) => ({
              ...provided,
              fontFamily: state.data.family,
            }),
          }}
        />
        <OverrideMenu path={fontFamilyPath} />
      </Col>
    </Row>
  );
}

export default FontSelect;
