import { ButtonGroup, ButtonToolbar } from 'react-bootstrap';

import PreviewSubmit from '@components/controls/PreviewSubmit';
import RenderStatus from '@components/controls/RenderStatus';

function WorkspaceActionsRight() {
  return (
    <ButtonToolbar>
      <ButtonGroup className="me-1">
        <PreviewSubmit />
        <RenderStatus />
      </ButtonGroup>
    </ButtonToolbar>
  );
}

export default WorkspaceActionsRight;
