import { useRecoilValue } from 'recoil';

import WorkflowModuleDestination from '@feature/workflows/module/WorkflowModuleDestinations';
import WorkflowModuleDolby from '@feature/workflows/module/WorkflowModuleDolby';
import WorkflowModuleHttp from '@feature/workflows/module/WorkflowModuleHttp';
import WorkflowModuleITVShotstack from '@feature/workflows/module/WorkflowModuleItvShotstack';
import WorkflowModuleMetadataShotstack from '@feature/workflows/module/WorkflowModuleMetadataShotstack';
import WorkflowModuleRenderShotstack from '@feature/workflows/module/WorkflowModuleRenderShotstack';
import WorkflowModuleTIShotstack from '@feature/workflows/module/WorkflowModuleTiShotstack';
import WorkflowModuleTranscript from '@feature/workflows/module/WorkflowModuleTranscript';
import WorkflowModuleTTIShotstack from '@feature/workflows/module/WorkflowModuleTtiShotstack';
import WorkflowModuleTTIStabilityAi from '@feature/workflows/module/WorkflowModuleTtiStabilityAi';
import WorkflowModuleTTSElevanlabs from '@feature/workflows/module/WorkflowModuleTtsElevenLabs';
import WorkflowModuleTTSShotstack from '@feature/workflows/module/WorkflowModuleTtsShotstack';
import WorkflowModuleTTTOpenAi from '@feature/workflows/module/WorkflowModuleTttOpenAi';
import WorkflowModuleTTTShotstack from '@feature/workflows/module/WorkflowModuleTttShotstack';
import WorkflowModuleTTVDid from '@feature/workflows/module/WorkflowModuleTtvDid';
import WorkflowModuleTTVHeyGen from '@feature/workflows/module/WorkflowModuleTtvHeyGen';
import WorkflowModuleTVShotstack from '@feature/workflows/module/WorkflowModuleTvShotstack';

import { activeTaskAtom, taskModuleAtomFamily } from '@store/Workflows';

const ModuleComponents = {
  'shotstack:text-to-speech': WorkflowModuleTTSShotstack,
  'elevenlabs:text-to-speech': WorkflowModuleTTSElevanlabs,
  'shotstack:render-template': WorkflowModuleRenderShotstack,
  'shotstack:destination': WorkflowModuleDestination,
  'tiktok:destination': WorkflowModuleDestination,
  's3:destination': WorkflowModuleDestination,
  'google-cloud-storage:destination': WorkflowModuleDestination,
  'mux:destination': WorkflowModuleDestination,
  'google-drive:destination': WorkflowModuleDestination,
  'vimeo:destination': WorkflowModuleDestination,
  'shotstack:http': WorkflowModuleHttp,
  'shotstack:text-generator': WorkflowModuleTTTShotstack,
  'stability-ai:text-to-image': WorkflowModuleTTIStabilityAi,
  'shotstack:text-to-image': WorkflowModuleTTIShotstack,
  'd-id:text-to-avatar': WorkflowModuleTTVDid,
  'shotstack:image-to-video': WorkflowModuleITVShotstack,
  'shotstack:metadata': WorkflowModuleMetadataShotstack,
  'heygen:text-to-avatar': WorkflowModuleTTVHeyGen,
  'openai:text-generator': WorkflowModuleTTTOpenAi,
  'dolby:audio-enhance': WorkflowModuleDolby,
  'shotstack:video-transform': WorkflowModuleTVShotstack,
  'shotstack:image-transform': WorkflowModuleTIShotstack,
  'shotstack:transcript': WorkflowModuleTranscript,
};

function WorkflowModuleConfiguration() {
  const activeTask = useRecoilValue(activeTaskAtom);
  const taskModule = useRecoilValue(taskModuleAtomFamily(activeTask));

  const ModuleComponent = ModuleComponents[taskModule];
  if (!ModuleComponent) {
    return null;
  }

  return <ModuleComponent />;
}

export default WorkflowModuleConfiguration;
