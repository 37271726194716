import config from '@config';
import { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil';

import onboardingService from '@api/services/onboarding';

import StripeHookProvider from '@context/StripeHookProvider';

import BillingCardForm from '@feature/billing/BillingCardForm';
import SubscriptionPromotionCode from '@feature/subscription/SubscriptionPromotionCode';

import AlertBox from '@components/alert/AlertBox';
import { useAnalytics } from '@components/analytics/Analytics';
import Content from '@components/content/Content';
import Header from '@components/header/Header';
import Layout from '@components/layout/Layout';
import Skeleton from '@components/skeletons/Skeleton';

import { authenticatedUserAtom, userDetailsSelector } from '@store/Auth';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const FREE_PLAN_NAME = 'freeTrial';

function ActionButtons({ isLoading, onCancel, onConfirm }) {
  return (
    <Row>
      <Col className="d-flex justify-content-end">
        <Button onClick={onCancel} variant="light" type="button" className="me-3" disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="primary" type="button" disabled={isLoading}>
          {isLoading ? <Spinner animation="border" size="sm" /> : 'Confirm'}
        </Button>
      </Col>
    </Row>
  );
}

function SuccessAlert({ navigate }) {
  return (
    <>
      <Alert variant="success">Your subscription has been successfully updated.</Alert>
      <Button onClick={() => navigate('/')} variant="light">
        Return to Dashboard
      </Button>
      <Button onClick={() => navigate('/subscription')} variant="light" className="ms-3">
        Manage Subscription
      </Button>
    </>
  );
}

function PageHeader() {
  return (
    <Header title="Order Confirmation">
      <p>Confirm your new subscription.</p>
    </Header>
  );
}

function Subscribe() {
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const { code: subscriptionCode } = useParams();
  const [subscription, setSubscription] = useState();
  const authUser = useRecoilValue(authenticatedUserAtom);
  const userDetails = useRecoilValueLoadable(userDetailsSelector);
  const refreshUserDetails = useRecoilRefresher_UNSTABLE(userDetailsSelector);
  const [discountCode, setDiscountCode] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);

  const handleUpgrade = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(false);

    try {
      await onboardingService.subscribeUser(subscriptionCode, discountCode);
      trackEvent(`Purchase`, {
        Type: 'Subscription',
        Plan: subscriptionCode,
      });
      await delay(2000);
      refreshUserDetails();
      setSuccess(true);
    } catch (error) {
      console.error(error);
      setError('An error occurred setting up your subscription. Please try again or contact support.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchSubscription = async () => {
      const data = await onboardingService.getSubscription(subscriptionCode);
      setSubscription(data);
    };

    fetchSubscription();

    return () => {
      refreshUserDetails();
    };
  }, [subscriptionCode]);

  if (userDetails.state === 'loading' || !subscription) {
    return (
      <Layout>
        <PageHeader />
        <Skeleton length={3} />
      </Layout>
    );
  }

  return (
    <Layout>
      <PageHeader />
      <Content heading={subscriptionCode !== FREE_PLAN_NAME ? 'YOUR NEW PLAN' : 'CANCELLATION CONFIRMATION'}>
        <Card>
          <Card.Body>
            {subscriptionCode !== FREE_PLAN_NAME ? (
              <h3>
                {subscription.name} / month at ${subscription.price}
              </h3>
            ) : (
              !isSuccess && (
                <AlertBox
                  type="warning"
                  name={'Warning: Loss of Credits'}
                  message={
                    'Are you sure you want to cancel? All banked credits will be lost immediately. This action cannot be undone. Proceed?'
                  }
                ></AlertBox>
              )
            )}
          </Card.Body>
        </Card>
        {subscriptionCode !== FREE_PLAN_NAME && !isSuccess && (
          <SubscriptionPromotionCode onDiscountCodeChange={setDiscountCode} subscriptionCode={subscriptionCode} />
        )}
        {!userDetails.contents.stripe_id && subscriptionCode !== FREE_PLAN_NAME && !isSuccess && (
          <StripeProvider apiKey={config.stripe.publishableKey}>
            <Elements>
              <StripeHookProvider>
                <BillingCardForm
                  user={authUser}
                  subscription={subscription}
                  discountCode={discountCode}
                  onCardChange={() => setSuccess(true)}
                />
              </StripeHookProvider>
            </Elements>
          </StripeProvider>
        )}
        {(userDetails.contents.stripe_id || subscriptionCode === FREE_PLAN_NAME) && !isSuccess && (
          <ActionButtons isLoading={isLoading} onCancel={() => navigate('/subscription')} onConfirm={handleUpgrade} />
        )}
        {isSuccess && <SuccessAlert navigate={navigate} />}
        {isError && (
          <Alert variant="danger" onClose={() => setError(false)} dismissible>
            {isError}
          </Alert>
        )}
      </Content>
    </Layout>
  );
}

export default Subscribe;
