import { Table } from 'react-bootstrap';
import { useRecoilValueLoadable } from 'recoil';

import KeysData from '@components/keys/KeysData';
import KeysError from '@components/keys/KeysError';
import Skeleton from '@components/skeletons/Skeleton';

import { authKeysSelector } from '@store/Auth';

function Keys() {
  const { state, contents } = useRecoilValueLoadable(authKeysSelector);

  switch (state) {
    case 'hasError':
      return <KeysError error={contents?.response?.data?.data} />;
    case 'hasValue':
      return (
        <Table bordered responsive className="keys-table rounded">
          <thead>
            <tr>
              <th>ENV</th>
              <th>OWNER ID</th>
              <th>API KEY</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(contents || {}).map(([env, keys]) => (
              <KeysData key={env} testid={`keys-${env.toUpperCase()}`} label={env.toUpperCase()} keys={keys} />
            ))}
          </tbody>
        </Table>
      );
    case 'loading':
      return <Skeleton length={2} />;
    default:
      return null;
  }
}

export default Keys;
