import cn from 'classnames';
import { Card, Col, Row } from 'react-bootstrap';

function ItemHeaderLeft({ children }) {
  return (
    <Col xs="auto" className="align-self-center">
      {children}
    </Col>
  );
}

function ItemHeaderMiddle({ children }) {
  return <Col>{children}</Col>;
}

function ItemHeaderRight({ children }) {
  return <Col className="float-right text-end align-self-center">{children}</Col>;
}

function ItemHeader({ children, ...restProps }) {
  return <Row {...restProps}>{children}</Row>;
}

function ItemBody({ children }) {
  return <Row>{children}</Row>;
}

function Item({ children, className, ...restProps }) {
  return (
    <Card className={cn(className, 'item mb-2')} {...restProps}>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
}

Item.Header = ItemHeader;
Item.HeaderLeft = ItemHeaderLeft;
Item.HeaderMiddle = ItemHeaderMiddle;
Item.HeaderRight = ItemHeaderRight;
Item.Body = ItemBody;

export default Item;
