import { useRecoilCallback } from 'recoil';

import { userDetailsSelector } from '@store/Auth';
import { canvasAtom, canvasStageAtom } from '@store/Canvas';
import {
  assetIdsAtom,
  callbackAtom,
  clipIdsAtom,
  clipsAtomFamily,
  fontIdsAtom,
  fontsAtomFamily,
  overridesAtomFamily,
  trackIdsAtom,
} from '@store/Edit';
import { mergeFamily, mergeIdsAtom } from '@store/Merge';
import { resetOutputCallback } from '@store/Output';
import { renderPreviewLengthAtom, renderPreviewStartAtom, rendersAtom } from '@store/Renders';
import {
  allTemplatesSelector,
  templateIdAtom,
  templateJsonEditAtom,
  templateNameAtom,
  templateReadyAtom,
} from '@store/Template';
import { studioActiveTabAtom, studioActiveTabHoverAtom } from '@store/UI';

export const resetStudioCallback = (callbackArgs) => {
  const { refresh, reset, snapshot } = callbackArgs;
  return () => {
    reset(templateIdAtom);
    reset(templateNameAtom);
    reset(templateReadyAtom);
    reset(templateJsonEditAtom);

    reset(renderPreviewStartAtom);
    reset(renderPreviewLengthAtom);
    reset(canvasAtom);
    reset(canvasStageAtom);
    reset(callbackAtom);

    reset(studioActiveTabAtom);
    reset(studioActiveTabHoverAtom);
    reset(rendersAtom);

    resetOutputCallback(callbackArgs)();

    const currentMergeIdsState = snapshot.getLoadable(mergeIdsAtom).contents;
    currentMergeIdsState.forEach((mergeId) => reset(mergeFamily(mergeId)));

    const currentClipIds = snapshot.getLoadable(clipIdsAtom).contents;
    currentClipIds.forEach((clipId) => {
      reset(clipsAtomFamily(clipId));
      reset(overridesAtomFamily(clipId));
    });

    const currentFontIds = snapshot.getLoadable(fontIdsAtom).contents;
    currentFontIds.forEach((fontId) => reset(fontsAtomFamily(fontId)));

    reset(overridesAtomFamily('callback'));
    reset(overridesAtomFamily('soundtrack'));

    reset(trackIdsAtom);
    reset(clipIdsAtom);
    reset(assetIdsAtom);
    reset(fontIdsAtom);
    reset(mergeIdsAtom);

    refresh(userDetailsSelector);
    refresh(allTemplatesSelector);
  };
};

export const useResetStudio = () => useRecoilCallback(resetStudioCallback);
