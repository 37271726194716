export default [
  { value: 12, text: '12 fps' },
  { value: 15, text: '15 fps' },
  { value: 24, text: '24 fps' },
  { value: 23.976, text: '23.976 fps' },
  { value: 25, text: '25 fps' },
  { value: 29.97, text: '29.97 fps' },
  { value: 30, text: '30 fps' },
  { value: 48, text: '48 fps' },
  { value: 50, text: '50 fps' },
  { value: 59.94, text: '59.94 fps' },
  { value: 60, text: '60 fps' },
];
