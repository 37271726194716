import config from '@config';
import { Col, Row } from 'react-bootstrap';

import VimeoForm from '@feature/integrations/providers/vimeo/VimeoForm';

import ContentCard from '@components/cards/ContentCard';
import Header from '@components/header/Header';

function Vimeo() {
  const Forms = config.integration.stages.map((stage, index) => (
    <VimeoForm key={stage.stage} stage={stage.stage} stageName={stage.name} index={index} />
  ));

  return (
    <>
      <Header title="Vimeo">Transfer files to your Vimeo.</Header>
      <Row className="mb-4">
        <Col md={4}>
          <ContentCard image={'/img/provider/vimeo.png'}>
            Vimeo is a video hosting, sharing, and services platform.
          </ContentCard>
        </Col>
        <Col md={8}>
          <ContentCard heading="SETUP">
            <p>Upload videos straight to Vimeo by authorising Shotstack to access your Vimeo account.</p>
          </ContentCard>
          <ContentCard heading="CREDENTIALS">{Forms}</ContentCard>
          <ContentCard heading="INTEGRATION">
            <h5>Basic setup</h5>
            <p>To send files to Vimeo add the following to the output parameter of the render payload:</p>
            <pre>{`"destinations": [{
    "provider": "vimeo"
}]`}</pre>
            <p>
              The following will send the rendered files to your Vimeo account with a name and description. If no name
              is provided the `id` of the video will be used as the name:
            </p>
            <pre>{`"destinations": [{
    "provider": "vimeo",
    "options": {
        "name": "My Shotstack Video",
        "description": "This video is uploaded by Shotstack",
    }
  }]`}</pre>

            <h5>Privacy Settings</h5>
            <p>You can also set the privacy settings of your videos:</p>
            <pre>{`"destinations": [{
    "provider": "vimeo",
    "options": {
        "privacy": {
            "view": "anybody",
            "embed": "public",
            "comments": "anybody"
        }
    }
  }]`}</pre>

            <h5>Opting out from the Shotstack destination</h5>
            <p>
              By default all generated files are sent to the Shotstack destination. You may wish to opt out from hosting
              you videos with Shotstack.
            </p>
            <pre>{`"destinations": [{
    "provider": "vimeo",
    "options": {
      "name": "My Shotstack Video",
      "description": "This video is uploaded by Shotstack",
    }
},{
    "provider": "shotstack",
    "exclude": true
}]`}</pre>
          </ContentCard>
        </Col>
      </Row>
    </>
  );
}

export default Vimeo;
