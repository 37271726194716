import { useRecoilState } from 'recoil';

import InputNumber from '@components/controls/InputNumber';

import { sizeAtomFamily } from '@store/Output';

import { parseNumberOrEmptyString } from '@utils/numbers';

function WidthInput() {
  const [width, setWidth] = useRecoilState(sizeAtomFamily('width'));

  const handleChange = (event) => {
    const { value } = event.target;
    setWidth(parseNumberOrEmptyString(value, false));
  };

  return (
    <InputNumber
      id="output-width"
      title="Width"
      value={width}
      min={0}
      prefix="W"
      step={1}
      handleChange={handleChange}
    />
  );
}

export default WidthInput;
