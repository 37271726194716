import { Card, Col, Row } from 'react-bootstrap';

import Content from '@components/content/Content';

function ContentCard({ heading, image, children }) {
  return (
    <Content heading={heading}>
      <Row>
        <Col>
          <Card>
            {image && <Card.Img variant="top" src={image} />}
            <Card.Body>{children}</Card.Body>
          </Card>
        </Col>
      </Row>
    </Content>
  );
}

export default ContentCard;
