import { useEffect } from 'react';
import { Card } from 'react-bootstrap';

import WorkflowRecipeGallery from '@feature/workflows/gallery/WorkflowRecipeGallery';

import { useAnalytics } from '@components/analytics/Analytics';
import Layout from '@components/layout/Layout';

function Studio() {
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent('View Templates');
  }, []);

  return (
    <Layout>
      <Card className="p-3 mb-10">
        <Card.Body>
          <div className="text-prose">
            <p className="uppercase-label">Workflows</p>
            <h1>Workflow Templates</h1>
            <p className="text-gray-500">Use our pre-built templates to get started.</p>
          </div>
        </Card.Body>
      </Card>

      <WorkflowRecipeGallery baseUrl="/workflows/templates" randomize={false} responsive />
    </Layout>
  );
}

export default Studio;
