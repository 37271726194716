import { useEffect, useMemo, useState } from 'react';

function useDestinationFormValidation(initialOptions, destination, setDestination, onValidationChange) {
  const [validationState, setValidationState] = useState({ isValid: false, invalidFields: [] });

  const requiredFields = useMemo(() => initialOptions.filter((option) => option.required), [initialOptions]);

  const handleOptionsChange = (event) => {
    const { name, value } = event.target;
    setDestination((prevState) => ({
      ...prevState,
      options: {
        ...prevState.options,
        [name]: value,
      },
    }));
  };

  useEffect(() => {
    const allFieldsFilled = requiredFields.every(
      (field) => destination?.options?.[field.label] && destination.options[field.label].trim() !== ''
    );

    const invalidFields = requiredFields
      .filter((field) => !(destination?.options?.[field.label] && destination.options[field.label].trim() !== ''))
      .map((field) => field.label);

    setValidationState({ isValid: allFieldsFilled, invalidFields });
    onValidationChange(allFieldsFilled);
  }, [destination?.options, requiredFields, onValidationChange]);

  return {
    isValid: validationState.isValid,
    invalidFields: validationState.invalidFields,
    handleOptionsChange,
  };
}

export default useDestinationFormValidation;
