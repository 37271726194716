import { atom } from 'recoil';

type AssetLoaderRegistry = string[];

/**
 * Loading state of the asset loader
 * Used by AssetLoader and AssetLoaderBadge components to track if the asset loader is loading assets
 */
export const assetLoaderIsLoadingAtom = atom<boolean>({
  key: 'assetLoaderIsLoadingAtom',
  default: false,
});

/**
 * Error state of the asset loader
 * Used by AssetLoader and AssetLoaderBadge components to track if there was an error loading assets
 */
export const assetLoaderErrorAtom = atom<boolean>({
  key: 'assetLoaderErrorAtom',
  default: false,
});

/**
 * Progress of the asset loader
 * Used by AssetLoader and AssetLoaderBadge components to track the progress of the asset loader
 */
export const assetLoaderProgressAtom = atom<number>({
  key: 'assetLoaderProgressAtom',
  default: 0,
});

/**
 * Registry of loaded assets to prevent duplicate loading
 * Used by AssetLoader and AssetLoaderBadge components to track which assets have been loaded
 * and avoid reloading them unnecessarily
 */
export const assetLoaderRegistryAtom = atom<AssetLoaderRegistry>({
  key: 'assetLoaderRegistryAtom',
  default: [],
});
