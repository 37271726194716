import config from '@config';
import axios from 'axios';
import { Deserializer } from 'jsonapi-serializer';
import { useEffect, useRef, useState } from 'react';
import { Alert, Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';

import { authTokenAtom } from '@store/Auth';
import { integrationsSelector } from '@store/Integrations';

const INTEGRATION_ENDPOINT = 'integration/s3';
const SAVE_ERROR =
  'There was an error adding your S3 credentials. Please try again or contact us if the issue continues.';
const LOAD_ERROR =
  'There was an error retrieving your S3 credentials. Please try again or contact us if the issue continues.';

/**
 * // todo:
 * - Only update secret passed to API, otherwise update key
 * - Error handling
 */
function S3Form({ stage, stageName, index, onFormFilled }) {
  const bearerToken = useRecoilValue(authTokenAtom);
  const refreshIntegrations = useRecoilRefresher_UNSTABLE(integrationsSelector);
  const keyIdRef = useRef('');
  const keySecretRef = useRef('');
  const [keyId, setKeyId] = useState('');
  const [isBusy, setBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [isPopulated, setIsPopulated] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setBusy(true);

    if (!bearerToken.length) {
      throw new Error('Not authorised');
    }

    const payload = {
      credentials: {
        accessKeyId: keyIdRef.current.value,
        secretAccessKey: keySecretRef.current.value,
      },
      stage,
    };

    try {
      const response = await axios.put(config.integration.url + INTEGRATION_ENDPOINT, payload, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });

      const data = await new Deserializer({ keyForAttribute: 'camelCase' }).deserialize(response.data);
      setKeyId(data.credentials.accessKeyId);

      setIsPopulated(true);
      setIsEditing(false);
      refreshIntegrations();
    } catch (error) {
      setErrorMessage(SAVE_ERROR);
      setBusy(false);
    }

    setBusy(false);
  };

  useEffect(() => {
    async function getCredentials() {
      if (bearerToken && bearerToken.length) {
        try {
          const response = await axios.get(config.integration.url + INTEGRATION_ENDPOINT, {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          });

          new Deserializer({ keyForAttribute: 'camelCase' }).deserialize(response.data, (error, data) => {
            const integration = Array.isArray(data) ? data.find((integration) => integration.stage === stage) : null;
            setIsPopulated(Boolean(integration));
            setIsReady(true);

            if (!integration) {
              setIsEditing(true);
            }

            if (integration) {
              onFormFilled(true);
              setKeyId(integration.credentials.accessKeyId);
            }
          });
        } catch (error) {
          setErrorMessage(LOAD_ERROR);
          setBusy(false);
        }
      }
    }

    getCredentials();
  }, [index, bearerToken]);

  return (
    <Row className="mb-4">
      <Col>
        <Card>
          <Card.Body>
            {!isReady && <Spinner animation="border" variant="secondary" />}
            {isReady && isPopulated && !isEditing && (
              <>
                <Row>
                  <Col className="mb-2">
                    <b>{stageName}</b>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    Access Key ID
                    <br />
                    <p>
                      <b>{keyId || '-----'}</b>
                    </p>
                  </Col>
                  <Col md={12}>
                    Secret Access Key
                    <br />
                    <p>
                      <b dangerouslySetInnerHTML={{ __html: '&#9679;'.repeat(24) }} />
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" onClick={() => setIsEditing(true)}>
                      Edit
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {isReady && isEditing && (
              <Form onSubmit={handleSubmit} id="s3CredentialsForm">
                <Row>
                  <Col className="mb-2">
                    <b>{stageName}</b>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group controlId="keyId">
                      <Form.Label>Access Key ID</Form.Label>
                      <Form.Control defaultValue={keyId} type="text" ref={keyIdRef} required autoComplete="off" />
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mt-2">
                    <Form.Group controlId="secretKey">
                      <Form.Label>Secret Access Key</Form.Label>
                      <Form.Control type="password" ref={keySecretRef} required autoComplete="off" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="mt-3 d-flex justify-content-end">
                    {isPopulated && (
                      <Button variant="light" onClick={() => setIsEditing(false)} className="me-3 fixed">
                        Cancel
                      </Button>
                    )}
                    <Button variant="primary" type="submit" disabled={isBusy} className="fixed">
                      {isBusy ? <Spinner animation="border" size="sm" role="status" aria-hidden="true" /> : 'Save'}
                    </Button>
                  </Col>
                </Row>
                {errorMessage && (
                  <Alert variant="danger" onClose={() => setErrorMessage(false)} dismissible>
                    {errorMessage}
                  </Alert>
                )}
              </Form>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default S3Form;
