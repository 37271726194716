import { memo } from 'react';
import { v4 as uuid } from 'uuid';

function TimelineClipSprite({ frames = [], width = 100, children }) {
  return (
    <div className="clip__background">
      {frames.map((frame, index) => (
        <img key={uuid()} src={frame} style={{ width }} alt={`frame ${index}`} />
      ))}
      {children}
    </div>
  );
}

export default memo(TimelineClipSprite);
