import { load as loadFont } from 'opentype.js';

const opentypeErrorsMap = {
  unsupported: 'Only OpenType fonts (TTF, OTF) are supported.',
  notbeloaded: 'Font file could not be loaded. Is the url correct?',
  default: 'Font file could not be loaded.',
};

const fontBaseUrl = 'https://templates.shotstack.io/basic/asset/font/';

const defaultFonts = [
  { fileName: 'arapey-regular', label: 'Arapey Regular', family: 'Arapey' },
  { fileName: 'clearsans-regular', label: 'Clear Sans', family: 'Clear Sans' },
  { fileName: 'didactgothic-regular', label: 'Didact Gothic Regular', family: 'Didact Gothic' },
  { fileName: 'montserrat-semibold', label: 'Montserrat SemiBold', family: 'Montserrat SemiBold' },
  { fileName: 'montserrat-extrabold', label: 'Montserrat ExtraBold', family: 'Montserrat ExtraBold' },
  { fileName: 'permanentmarker-regular', label: 'Permanent Marker Regular', family: 'Permanent Marker' },
  { fileName: 'roboto-blackitalic', label: 'Roboto Black Italic', family: 'Roboto Black' },
  { fileName: 'sueellenfrancisco-regular', label: 'Sue Ellen Francisco ', family: 'Sue Ellen Francisco ' }, // keep space at end
  { fileName: 'worksans-light', label: 'Work Sans Light', family: 'Work Sans Light' },
];

const getOpenTypeError = (error) => {
  let originalMessage;
  let newMessage;

  if (error?.message && typeof error?.message === 'string') {
    originalMessage = error.message;
  } else if (error && typeof error === 'string') {
    originalMessage = error;
  }

  if (!originalMessage) {
    return new Error(opentypeErrorsMap.default);
  }

  const openTypeMessage = originalMessage.replace(/\s/g, '').toLowerCase();

  Object.entries(opentypeErrorsMap).forEach(([key, message]) => {
    if (openTypeMessage.includes(key)) {
      newMessage = message;
    }
  });

  if (newMessage) {
    return new Error(newMessage);
  }
  return error;
};

export const getFont = async ({ src }) => {
  if (!src) {
    throw Error('Font source cannot be empty');
  }

  try {
    const font = await loadFont(src);
    const { fullName, fontFamily } = font.names;

    const label = fullName?.en;
    const family = fontFamily?.en;

    if (!label && !family) {
      return [null, 'Font file could not be loaded.'];
    }

    const data = { src, family, label };
    return [data, null];
  } catch (error) {
    return [null, getOpenTypeError(error)];
  }
};

export const getFontFace = async ({ src }) => {
  try {
    const font = await loadFont(src);
    const { fontFamily } = font.names;

    const label = fontFamily?.en;

    if (!label) {
      return [null, 'Font file could not be loaded.'];
    }
    return new FontFace(label, `url(${src})`);
  } catch (error) {
    throw Error(error);
  }
};

export default defaultFonts.map(({ fileName, family, label }) => ({
  src: `${fontBaseUrl}${fileName}.ttf`,
  family,
  label,
}));
