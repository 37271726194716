import cn from 'classnames';

import { IconInfo } from '@assets/icons';

const typeIconMap = {
  info: IconInfo,
};

function WorkflowPanelMessage({ name, message, type = 'info' }) {
  const Icon = typeIconMap[type];
  return (
    <div className={cn('workflow-panel__message', { [type]: true })}>
      <div className="workflow-message__icon">{Icon && <Icon size={24} />}</div>
      <div>
        <h6>{name}</h6>
        {message && <small className="d-block" dangerouslySetInnerHTML={{ __html: message }} />}
      </div>
    </div>
  );
}

export default WorkflowPanelMessage;
