import config from '@config';
import { getRecoil } from 'recoil-nexus';

import { authTokenAtom, stageAtom } from '@store/Auth';

type HeaderOptions = {
  withStage?: boolean;
  withUserAgent?: boolean;
};

type Headers = {
  Authorization: string;
  stage?: string;
  'User-Agent'?: string;
};

export const getHeaders = ({ withStage, withUserAgent }: HeaderOptions): Headers => {
  const token = getRecoil(authTokenAtom);
  const stage = getRecoil(stageAtom);

  const headers: Headers = {
    Authorization: `Bearer ${token}`,
  };

  if (withStage) {
    headers.stage = stage;
  }

  if (withUserAgent) {
    headers['User-Agent'] = config.headers.userAgent;
  }

  return headers;
};

export const getKsuidFromAssetSrc = (src: string): string => {
  const parts = src.replace('https://', '').replace('/source.mp4', '').split('/');
  return parts[parts.length - 1];
};

export const isIngestSrc = (src: string): boolean => {
  return /shotstack-ingest-api-stage-sources/.test(src);
};

interface Asset {
  src?: string;
}

export const getAsseyKsuid = (asset: Asset | null): string | null => {
  if (!asset || !asset.src || !isIngestSrc(asset.src)) {
    return null;
  }

  return getKsuidFromAssetSrc(asset.src);
};
