import { useCallback } from 'react';

import getImageDimensions from '@utils/editor/getImageDimensions';
import getVideoDimensions from '@utils/editor/getVideoDimensions';

function useGetMediaDimensions() {
  const getMediaDimensions = useCallback(async (source, type) => {
    try {
      if (!type && source instanceof File) {
        [type] = source.type.split('/');
      }

      switch (type) {
        case 'image':
          return await getImageDimensions(source);
        case 'video':
          return await getVideoDimensions(source);
        default:
          return {};
      }
    } catch (error) {
      console.error('Error getting media dimensions:', error);
      return {};
    }
  }, []);

  return {
    getMediaDimensions,
  };
}

export default useGetMediaDimensions;
