import { IconVoiceChat } from '@assets/icons';

import Asset from '@components/asset/Asset';

function AssetTextToSpeech() {
  return (
    <Asset icon={<IconVoiceChat />}>
      <IconVoiceChat size={16} color="#212529" className="p-2" />
    </Asset>
  );
}

export default AssetTextToSpeech;
