import React from 'react';

import ButtonElement from '@components/atoms/ButtonElement';

function OverrideMenuToggle({ isButton, isActive, disabled, Icon, IconActive }) {
  if (isButton) {
    const tracking = {
      name: 'Override',
      properties: {
        Action: isActive ? 'Remove Override' : 'Add Override',
      },
    };

    return (
      <ButtonElement className="overrideMenu__toggle" size="sm" variant="light" tracking={tracking} disabled={disabled}>
        {isActive ? IconActive && <IconActive size={18} className="text-primary" /> : Icon && <Icon size={18} />}
      </ButtonElement>
    );
  }
  return isActive ? IconActive && <IconActive size={10} className="text-primary" /> : Icon && <Icon size={10} />;
}

export default OverrideMenuToggle;
