import { useParams } from 'react-router-dom';

import InteractiveConsole from '@components/console/InteractiveConsole';
import Layout from '@components/layout/Layout';

function ApiPlayground() {
  const { product } = useParams();

  return (
    <Layout>
      <InteractiveConsole product={product} />
    </Layout>
  );
}

export default ApiPlayground;
