import '@fontsource/kanit/700.css';
import '@fontsource/nunito-sans';
import '@fontsource/nunito-sans/700.css';
import '@fontsource/nunito-sans/800.css';
import { reactRouterV6Instrumentation, init as sentryInit } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import * as serviceWorker from '@src/serviceWorker';

import App from '@components/app/App';

const { SENTRY_RELEASE, VITE_STAGE } = import.meta.env;

const sentryConfig = {
  dsn: 'https://963a0af050574dfa884ef1564101460b@sentry.io/1852016',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  environment: VITE_STAGE || 'development',
};

if (VITE_STAGE === 'production') {
  if (SENTRY_RELEASE) {
    sentryConfig.release = SENTRY_RELEASE;
  }

  sentryInit(sentryConfig);
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);

serviceWorker.unregister();
