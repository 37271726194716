import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import rendersService from '@api/services/renders';

import RenderDetailsAssets from '@feature/renders/RenderDetailsAssets';
import RenderDetailsRender from '@feature/renders/RenderDetailsRender';

import { useAnalytics } from '@components/analytics/Analytics';
import Header from '@components/header/Header';
import Layout from '@components/layout/Layout';

import useFetchAndPoll from '@hooks/useFetchAndPoll';

function EditApiDetail() {
  const { id } = useParams();
  const { trackEvent } = useAnalytics();
  const { data: render } = useFetchAndPoll({ id, key: `edit/render/${id}`, fetch: rendersService.get });
  const { data: assets } = useFetchAndPoll({
    id,
    key: `edit/render-assets/${id}`,
    fetch: rendersService.assets.getAll,
  });

  useEffect(() => {
    trackEvent('View Render Details', {
      Id: id,
    });
  }, []);

  return (
    <Layout className="pb-4">
      <Header title="Render Details" />
      <RenderDetailsRender render={render} />
      <RenderDetailsAssets assets={assets} />
    </Layout>
  );
}

export default EditApiDetail;
