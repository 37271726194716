import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { useRedo, useUndo } from '@context/RecoilUndo';
import { useTimelinePlaybackContext } from '@context/TimelinePlaybackProvider';

import { isKeyframesTabSelector } from '@store/Edit';
import { useSaveTemplate } from '@store/Template';
import { useCopyClipState, useDeleteClipOrTrackState, usePasteClipState } from '@store/Timeline';

import useKeyboardShortcuts from '@hooks/useKeyboardShortcuts';

const studioAreas = {
  workspace__left: 'sidebar',
  workspace__right: 'main',
};

function useStudioShortcuts() {
  const deleteClipOrTrack = useDeleteClipOrTrackState();
  const copyClip = useCopyClipState();
  const pasteClip = usePasteClipState();
  const saveTemplate = useSaveTemplate();
  const { handlePlayPause, handleStop, setPlayheadStart, setPlayheadEnd, seek } = useTimelinePlaybackContext();
  const isKeyframesTab = useRecoilValue(isKeyframesTabSelector);
  const { undo } = useUndo();
  const { redo } = useRedo();

  const { setGroup } = useKeyboardShortcuts({
    global: {
      'super+s': () => {
        saveTemplate();
      },
      'super+z': () => undo(),
      'super+shift+z': () => redo(),
    },
    main: {
      // 'super+d': () => console.log('duplicate clip'),
      'super+c': () => copyClip(),
      'super+v': () => pasteClip(),
      // 'alt+super+c': () => console.log('copy clip styles'),
      // 'alt+super+v': () => console.log('paste clip styles'),
      'super+ArrowLeft': () => setPlayheadStart(),
      'super+ArrowRight': () => setPlayheadEnd(),
      'super+shift+ArrowLeft': () => seek({ dir: 'back', snap: 'points' }),
      'super+shift+ArrowRight': () => seek({ dir: 'forward', snap: 'points' }),
      'alt+shift+ArrowLeft': () => seek({ dir: 'back', snap: 'fps' }),
      'alt+shift+ArrowRight': () => seek({ dir: 'forward', snap: 'fps' }),
      'shift+ArrowLeft': () => seek({ dir: 'back', snap: 'percentage' }),
      'shift+ArrowRight': () => seek({ dir: 'forward', snap: 'percentage' }),
      Delete: () => !isKeyframesTab && deleteClipOrTrack(),
      Enter: () => handleStop(),
      Space: () => handlePlayPause(),
    },
  });

  useEffect(() => {
    const handleClick = (event) => {
      let target = event.target;
      while (target) {
        if (Object.keys(studioAreas).includes(target.className)) {
          setGroup(studioAreas[target.className]);
          break;
        }
        target = target.parentNode;
      }
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);
}

export default useStudioShortcuts;
