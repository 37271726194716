export const transformServeAsset = async (response) => {
  const { data } = response;
  const [asset] = data;
  return asset || {};
};

export const transformServeAssets = async (response) => {
  const { links, meta, data } = response;
  return {
    assets: data.length ? data : [],
    links,
    meta,
  };
};
