class IndexedDBUtil {
  constructor({ db, table, version }) {
    this.db = db;
    this.table = table;
    this.version = version;
    this.name = `${table}_${version}`;
  }

  async openDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.db, this.version);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;

        // Clean up old stores if they exist
        this.cleanupOldStores(db);

        // Create a new object store for the new version if it doesn't exist
        if (!db.objectStoreNames.contains(this.name)) {
          db.createObjectStore(this.name);
        }
      };
    });
  }

  cleanupOldStores(db) {
    const existingStores = Array.from(db.objectStoreNames);
    existingStores.forEach((store) => {
      if (store.startsWith(this.table) && store !== this.name) {
        db.deleteObjectStore(store);
      }
    });
  }

  async setItem(key, value) {
    const db = await this.openDB();
    const transaction = db.transaction(this.name, 'readwrite');
    const store = transaction.objectStore(this.name);
    const request = store.put(value, key);

    return new Promise((resolve, reject) => {
      request.onsuccess = () => resolve();
      request.onerror = () => reject(request.error);
    });
  }

  async getItem(key) {
    const db = await this.openDB();
    const transaction = db.transaction(this.name, 'readonly');
    const store = transaction.objectStore(this.name);
    const request = store.get(key);

    return new Promise((resolve, reject) => {
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }
}

export default IndexedDBUtil;
