import { groupBy } from 'lodash-es';
import { atom, atomFamily, selectorFamily, useRecoilCallback } from 'recoil';

import SourcesService, { deleteSources } from '@api/services/sources';
import { transformAssetIncoming } from '@api/transform/utils/assets';

import { authReadySelector } from '@store/Auth';
import { activeClipAtom } from '@store/Clips';
import { clipIdsAtom, clipsAtomFamily } from '@store/Edit';
import { mergeDataJsonSelectorFamily } from '@store/Merge';
import { addClipState, addElementClipState, addTrackState } from '@store/Timeline';
import { showTemplateConfigurationAtom, studioActiveTabAtom, studioActiveTabHoverAtom } from '@store/UI';
import { mediaCapturePersist } from '@store/effects/MediaEffects';

import {
  BOUNDING_BOX_HEIGHT_PIXELS,
  BOUNDING_BOX_WIDTH_PIXELS,
  DEFAULT_TEXT,
  FONT_COLOR,
  FONT_FAMILY,
  FONT_LINE_HEIGHT,
  FONT_SIZE,
  TEXT_ALIGN_HORIZONTAL,
  TEXT_ALIGN_VERTICAL,
} from '@constants/TextAssetDefaults';

import { getPanelType } from '@utils/studio/sidebar';

const getMediaClipProperties = ({ data }) => {
  const isProxied = Boolean(data?.proxy);
  const props = {
    length: data?.duration,
    type: data?.type,
    ['asset:type']: data?.type,
    ['asset:src']: isProxied ? data?.proxy?.url : data?.source,
    ['asset:meta']: {
      width: data?.width,
      height: data?.height,
      duration: data?.duration,
      source: data?.source,
      proxied: isProxied,
      proxyScaleMultiplier: isProxied ? data?.width / data?.proxy?.width : 1,
    },
  };
  return props;
};

const getOverlayClipProperties = ({ data }) => {
  const isProxied = Boolean(data?.proxy);
  const props = {
    length: data?.duration,
    fit: 'contain',
    type: 'overlay',
    ['asset:type']: 'overlay',
    ['asset:src']: isProxied ? data?.proxy?.url : data?.source,
    ['asset:meta']: {
      width: data?.width,
      height: data?.height,
      duration: data?.duration,
      source: data?.source,
      proxied: isProxied,
      proxyScaleMultiplier: isProxied ? data?.width / data?.proxy?.width : 1,
    },
  };
  return props;
};

const getMaskClipProperties = ({ data }) => {
  const isProxied = Boolean(data?.proxy);
  const props = {
    type: 'mask',
    ['asset:type']: 'mask',
    ['asset:src']: isProxied ? data?.proxy?.url : data?.source,
    ['asset:meta']: {
      width: data?.width,
      height: data?.height,
      duration: data?.duration,
      source: data?.source,
      proxied: isProxied,
      proxyScaleMultiplier: isProxied ? data?.width / data?.proxy?.width : 1,
    },
  };
  return props;
};

const getTextToImageClipProperties = ({ data }) => {
  const { duration, width, height, prompt, text } = data;
  return {
    length: duration,
    fit: 'crop',
    ['asset:width']: width,
    ['asset:height']: height,
    ['asset:type']: 'text-to-image',
    ['asset:prompt']: prompt,
    ['asset:meta']: {
      text,
    },
  };
};

const getTextToSpeechClipProperties = ({ data }) => {
  const { duration, text, voice, source } = data;
  return {
    length: duration || 7,
    ['asset:voice']: voice,
    ['asset:text']: text,
    ['asset:type']: 'text-to-speech',
    ['asset:src']: source,
    ['asset:meta']: {
      text,
    },
  };
};

const getImageToVideoClipProperties = ({ data }) => {
  const { src, prompt, text, aspectRatio, placeholder, width, height } = data;
  return {
    length: 5,
    fit: 'crop',
    ['asset:type']: 'image-to-video',
    ['asset:src']: src,
    ['asset:prompt']: prompt,
    ['asset:aspectRatio']: aspectRatio,
    ['asset:meta']: {
      proxied: false,
      text,
      placeholder,
      duration: 5,
      width,
      height,
    },
  };
};

const getCaptionClipProperties = async ({ data, mergeData }) => {
  const transformedData = await transformAssetIncoming(
    {
      type: 'caption',
      src: data?.source,
      placeholder: data?.placeholder,
    },
    mergeData
  );

  return {
    length: transformedData?.meta?.duration || 3,
    type: 'caption',
    ['asset:type']: 'caption',
    ['asset:src']: transformedData?.src,
    ['asset:font:color']: FONT_COLOR,
    ['asset:font:family']: FONT_FAMILY,
    ['asset:font:size']: 18,
    ['asset:font:lineHeight']: FONT_LINE_HEIGHT,
    ['asset:meta']: transformedData?.meta,
  };
};

const getAudioClipProperties = ({ data }) => {
  return {
    length: data?.duration,
    type: data?.type,
    ['asset:type']: data?.type,
    ['asset:src']: data?.proxy?.url || data?.source,
    ['asset:volume']: 1,
    ['asset:meta']: {
      duration: data?.duration,
      source: data?.source,
      proxied: Boolean(data?.proxy),
    },
  };
};

const getTextClipProperties = ({ data }) => {
  const {
    text = DEFAULT_TEXT,
    size = FONT_SIZE,
    family = FONT_FAMILY,
    width = BOUNDING_BOX_WIDTH_PIXELS,
    height = BOUNDING_BOX_HEIGHT_PIXELS,
  } = data;

  return {
    type: 'text',
    ['asset:type']: 'text',
    ['asset:text']: text,
    ['asset:alignment:horizontal']: TEXT_ALIGN_HORIZONTAL,
    ['asset:alignment:vertical']: TEXT_ALIGN_VERTICAL,
    ['asset:font:color']: FONT_COLOR,
    ['asset:font:family']: family,
    ['asset:font:size']: size,
    ['asset:font:lineHeight']: FONT_LINE_HEIGHT,
    ['asset:width']: width,
    ['asset:height']: height,
    ['asset:meta']: {
      text,
    },
  };
};

const convertToIngestAssetClip = ({ data }) => {
  return {
    type: data['asset:type'],
    source: data['asset:meta']?.source || data?.['asset:src'],
    ...(data['asset:meta']?.duration ? { duration: data['asset:meta'].duration } : {}),
    ...(data['asset:meta']?.proxied
      ? {
          proxy: {
            url: data?.['asset:src'],
            width: data?.['asset:meta']?.width,
            height: data?.['asset:meta']?.height,
          },
        }
      : {}),
  };
};

const getShapeClipProperties = ({ data }) => {
  const commonProperties = {
    type: 'shape',
    ['asset:type']: 'shape',
    ['asset:shape']: data.shape,
    ['asset:fill:color']: data.fill?.color || '#000000',
    ['asset:fill:opacity']: data.fill?.opacity || 1,
    ['asset:stroke:color']: data.stroke?.color || '#000000',
    ['asset:stroke:width']: data.stroke?.width || 0,
    ['start']: data.start || 0,
    ['length']: data.length || 3,
    ['offset:x']: data.offset?.x,
    ['offset:y']: data.offset?.y,
  };

  switch (data.shape) {
    case 'rectangle':
      const width = data.rectangle?.width || 100;
      const height = data.rectangle?.height || 100;
      return {
        ...commonProperties,
        ['asset:width']: width,
        ['asset:height']: height,
        ['asset:rectangle:width']: width,
        ['asset:rectangle:height']: height,
        ['asset:rectangle:cornerRadius']: data.rectangle?.cornerRadius || 0,
      };
    case 'circle':
      const strokeWidth = data.stroke?.width || 0;
      const radius = data.circle?.radius || 100;
      return {
        ...commonProperties,
        ['asset:width']: radius * 2 + strokeWidth * 2,
        ['asset:height']: radius * 2 + strokeWidth * 2,
        ['asset:circle:radius']: radius,
      };
    case 'line':
      const lineLength = data.line?.length || 200;
      const lineThickness = data.line?.thickness || 4;
      return {
        ...commonProperties,
        ['asset:width']: lineLength,
        ['asset:height']: lineThickness,
        ['asset:line:length']: lineLength,
        ['asset:line:thickness']: lineThickness,
      };
    default:
      return commonProperties;
  }
};

const clipPropertiesMap = {
  overlay: getOverlayClipProperties,
  mask: getMaskClipProperties,
  video: getMediaClipProperties,
  image: getMediaClipProperties,
  text: getTextClipProperties,
  audio: getAudioClipProperties,
  element: getOverlayClipProperties,
  caption: getCaptionClipProperties,
  shape: getShapeClipProperties,
  'text-to-speech': getTextToSpeechClipProperties,
  'text-to-image': getTextToImageClipProperties,
  'image-to-video': getImageToVideoClipProperties,
};

export const mediaLoadingAtom = atom({
  key: 'studio/media/loading',
  default: false,
});

export const mediaAssetsAtom = atom({
  key: 'studio/media/assets',
  default: [],
});

export const mediaLinksAtom = atom({
  key: 'studio/media/links',
  default: {
    next: undefined,
  },
});

export const mediaMetaAtom = atom({
  key: 'studio/media/meta',
  default: {
    next: undefined,
  },
});

export const mediaUploadingAtom = atom({
  key: 'studio/media/uploading',
  default: false,
});

export const mediaCaptureAtomFamily = atomFamily({
  key: 'studio/media/capture',
  default: { loading: false, status: 'init', data: null },
  effects_UNSTABLE: mediaCapturePersist,
});

export const templateMediaSelectorFamily = selectorFamily({
  key: 'templateMediaSelectorFamily',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get:
    (type) =>
    ({ get }) => {
      const filterTypes = type === 'media' ? ['image', 'video', 'audio'] : [type];
      const clipIds = get(clipIdsAtom);
      const mediaMap = new Map();
      clipIds
        .map((clipId) => get(clipsAtomFamily(clipId)))
        .filter(Boolean)
        .forEach((data) => {
          if (!filterTypes.includes(data['asset:type'])) {
            return;
          }
          const clip = convertToIngestAssetClip({ data });
          mediaMap.set(clip.source, clip);
        });
      return Array.from(mediaMap.values());
    },
});

export const mediaSelectorFamily = selectorFamily({
  key: 'mediaSelectorFamily',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get:
    (type) =>
    ({ get }) => {
      const filterTypes = type === 'media' ? ['image', 'video', 'audio'] : [type];
      const mediaAssets = get(mediaAssetsAtom);
      const filteredAssets = mediaAssets.filter((asset) => filterTypes.includes(asset.type));

      if (type === 'media') {
        return {
          assets: groupBy(filteredAssets, 'type'),
          length: filteredAssets.length,
        };
      }

      return {
        assets: filteredAssets,
        length: filteredAssets.length,
      };
    },
});

export const useFetchMediaCallback = () => {
  return useRecoilCallback(({ snapshot, set }) => async ({ next }) => {
    set(mediaLoadingAtom, true);
    await snapshot.getPromise(authReadySelector);
    const response = await SourcesService.getAllByCursor({ next });

    set(mediaAssetsAtom, (currentState) => [...currentState, ...response.assets]);
    set(mediaLinksAtom, response.links);
    set(mediaMetaAtom, response.meta);
    set(mediaLoadingAtom, false);
  });
};

export const useAddMediaCallback = () => {
  return useRecoilCallback(({ set }) => (asset) => {
    try {
      let newAsset = asset;
      if (asset.type !== 'caption') {
        const {
          outputs: {
            renditions: [proxy],
          },
        } = asset;
        newAsset = { ...asset, proxy };
      }

      set(mediaAssetsAtom, (currentState) => [newAsset, ...currentState]);
    } catch (error) {
      console.error('Unable to add media to library: ', error);
    }
  });
};

export const useAddClipToTimelineCallback = () => {
  return useRecoilCallback((callbackArgs) => async ({ data, type }) => {
    const { set, snapshot } = callbackArgs;
    const addTrack = addTrackState(callbackArgs);
    const addClip = addClipState(callbackArgs);
    const mergeData = snapshot.getLoadable(mergeDataJsonSelectorFamily(false)).contents;

    const getClipProperties = clipPropertiesMap[type];
    if (!getClipProperties) {
      return;
    }
    const clip = await getClipProperties({ data, mergeData });
    const { id: clipId } = addClip({ toTrackId: addTrack(type), clip });

    const setActiveTimeout = setTimeout(() => {
      clearTimeout(setActiveTimeout);
      set(activeClipAtom, clipId);
      set(studioActiveTabAtom, getPanelType(clip['asset:type']));
      set(studioActiveTabHoverAtom, null);
      set(showTemplateConfigurationAtom, false);
    }, 0);
  });
};

export const useDeleteMediaCallback = () => {
  return useRecoilCallback(({ set }) => (mediaIds) => {
    set(mediaAssetsAtom, (currentState) => currentState.filter((asset) => !mediaIds.includes(asset.id)));
    deleteSources(mediaIds);
  });
};

export const useAddElementsToTimelineCallback = () => {
  return useRecoilCallback((callbackArgs) => ({ elements }) => {
    const { set } = callbackArgs;
    const addTrack = addTrackState(callbackArgs);
    const addElementClip = addElementClipState(callbackArgs);

    const elementClips = elements
      .map((element) => {
        if (element.type === 'overlay') return getOverlayClipProperties({ data: element });
        if (element.type === 'mask') return getMaskClipProperties({ data: element });
        return null;
      })
      .filter((clip) => clip);

    const { ids: clipIds } = addElementClip({ toTrackId: addTrack(), clips: elementClips });

    const [inClipId] = clipIds;
    const setActiveTimeout = setTimeout(() => {
      set(activeClipAtom, inClipId);
      clearTimeout(setActiveTimeout);
    }, 0);
  });
};
