import cn from 'classnames';
import dayjs from 'dayjs';
import { Badge } from 'react-bootstrap';

import { IconMedia } from '@assets/icons';

import Item from '@components/listing/Item';
import StatusBadge from '@components/status/StatusBadge';

import { imageFormats } from '@constants/Formats';

import formatTimecode from '@utils/formatTimeCode';

const isImage = (format) => imageFormats.includes(format);

function ResourceListItemSources({ item, onItemClick }) {
  const { created, id, duration, status, width, height, extension } = item;
  const dimentions = width && height && `${width}x${height}`;

  const meta = [extension, dimentions, !isImage(extension) && duration > 0 ? formatTimecode(duration, 4) : false];
  const metaHtml = meta
    .filter((value) => value)
    .map((value) => value)
    .join(' | ');

  return (
    <Item className="list-item">
      <Item.Header style={{ cursor: 'pointer' }} onClick={() => onItemClick(id)}>
        <Item.HeaderLeft>
          <div className="list-item__icon">
            <IconMedia />
            <div
              className={cn('list-item__indicator', {
                [status]: true,
              })}
            />
          </div>
        </Item.HeaderLeft>
        <Item.HeaderMiddle>
          <div>
            <Badge className="bg-subtle">ID: {id}</Badge>
          </div>
          <div>
            <span className="list-item__text">{metaHtml}</span> {' — '}
            <StatusBadge status={status} />
          </div>
        </Item.HeaderMiddle>
        <Item.HeaderRight>
          <div className="list-item__text">
            <strong>Created:</strong> {dayjs(created).format('DD MMM YYYY HH:mm:ss.SSS A')} <br />
          </div>
        </Item.HeaderRight>
      </Item.Header>
    </Item>
  );
}

export default ResourceListItemSources;
