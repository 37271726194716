import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { IconCheck } from '@assets/icons';

import SubscriptionDowngradeModal from '@feature/subscription/SubscriptionDowngradeModal';

import ButtonElement from '@components/atoms/ButtonElement';

const getPriceIds = (items) => (Array.isArray(items) ? items.map(({ price }) => price) : []);

function SubscriptionListItemDetails({ subscription, subscriber, hideButton }) {
  const navigate = useNavigate();
  const [showDowngradeModal, setShowDowngradeModal] = useState(false);
  const priceIds = getPriceIds(subscription?.price_ids);
  const customerSubscriptionPriceIds = getPriceIds(subscriber?.subscription?.price_ids);

  const isCurrentPlan =
    (subscription?.code === 'free' && subscriber?.subscription?.code === 'free') ||
    customerSubscriptionPriceIds.some((id) => priceIds.includes(id));

  const handleSelectSubscription = () => {
    if (subscription?.code === 'free') {
      setShowDowngradeModal(true);
    } else {
      navigate(`/subscribe/${subscription?.code}`);
    }
  };

  const handleDowngradeSubscription = () => navigate('/subscribe/freeTrial');

  return (
    <>
      <Row>
        <Col sm={7}>
          {!isCurrentPlan ? (
            <>
              {subscription?.code !== 'free' && <b>${subscription.price}/month</b>}
              {!hideButton && (
                <ButtonElement handleEvent={handleSelectSubscription} variant="light">
                  {subscription?.code === 'free' ? 'Cancel Subscription' : 'Select'}
                </ButtonElement>
              )}
            </>
          ) : (
            <p>
              <IconCheck color="#18c96e" size="24px" /> Your current plan
            </p>
          )}
        </Col>
      </Row>

      <SubscriptionDowngradeModal
        showDowngradeModal={showDowngradeModal}
        setShowDowngradeModal={setShowDowngradeModal}
        onSubmitSuccess={handleDowngradeSubscription}
      />
    </>
  );
}

export default SubscriptionListItemDetails;
