import { Form } from 'react-bootstrap';

function BillingAddressFields({ onAddressChange }) {
  const handleFieldChange = (event) => {
    onAddressChange({
      cardName: event.target.value,
    });
  };

  return (
    <Form.Group controlId="billingName">
      <Form.Label>Cardholder Name</Form.Label>
      <Form.Control type="text" placeholder="Name on card" onChange={handleFieldChange} required />
    </Form.Group>
  );
}

export default BillingAddressFields;
