import '@css/Editor.css';

import { RecoilUndoRoot } from '@context/RecoilUndo';
import { TimelinePlaybackProvider } from '@context/TimelinePlaybackProvider';

import WorkspaceRoot from '@feature/studio/workspace/WorkspaceRoot';

const allowedKeys = ['edit/timeline/**', 'keyframes/**'];

function Editor() {
  return (
    <RecoilUndoRoot allowedKeys={allowedKeys}>
      <TimelinePlaybackProvider>
        <WorkspaceRoot />
      </TimelinePlaybackProvider>
    </RecoilUndoRoot>
  );
}

export default Editor;
