import { Graphics } from '@inlet/react-pixi';
import { useCallback, useEffect, useRef, useState } from 'react';

function FrameOutline(props) {
  const {
    bounds,
    handleGrab,
    handleDrag,
    handleDrop,
    inView,
    isAdjusting,
    setVisible,
    visible,
    zIndex,
    rotationAngle,
  } = props;

  const graphicsRef = useRef();
  const [lineSize, setLineSize] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const handlePointerDown = useCallback(
    (event) => {
      if (handleGrab) {
        setIsDragging(true);
        handleGrab(event);
      }
    },
    [handleGrab]
  );

  const handlePointerOut = useCallback(() => {
    if (!isAdjusting) {
      setVisible(false);
    }
  }, [isAdjusting, setVisible]);

  const handlePointerOver = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const handlePointerUp = useCallback(
    (event) => {
      if (handleDrop) {
        setIsDragging(false);
        handleDrop(event);
      }
    },
    [handleDrop]
  );

  useEffect(() => {
    setLineSize(visible ? 2.4 : 0);
  }, [visible]);

  const rotatePoint = (cx, cy, x, y, angle) => {
    const radians = (Math.PI / 180) * angle;
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);

    const dx = x - cx;
    const dy = y - cy;

    const xNew = dx * cos - dy * sin;
    const yNew = dx * sin + dy * cos;

    return { x: xNew + cx, y: yNew + cy };
  };

  const draw = useCallback(
    (graphic) => {
      graphic.clear();
      graphic.beginFill(0x0396ff, 0.000001);
      graphic.lineStyle(lineSize, 0x0396ff, 1);

      const { topLeft, topRight, bottomRight, bottomLeft } = bounds;

      const centerX = (topLeft.x + bottomRight.x) / 2;
      const centerY = (topLeft.y + bottomRight.y) / 2;

      const newTopLeft = rotatePoint(centerX, centerY, topLeft.x, topLeft.y, rotationAngle);
      const newTopRight = rotatePoint(centerX, centerY, topRight.x, topRight.y, rotationAngle);
      const newBottomRight = rotatePoint(centerX, centerY, bottomRight.x, bottomRight.y, rotationAngle);
      const newBottomLeft = rotatePoint(centerX, centerY, bottomLeft.x, bottomLeft.y, rotationAngle);

      const points = [
        newTopLeft.x,
        newTopLeft.y,
        newTopRight.x,
        newTopRight.y,
        newBottomRight.x,
        newBottomRight.y,
        newBottomLeft.x,
        newBottomLeft.y,
        newTopLeft.x,
        newTopLeft.y,
      ];

      graphic.drawPolygon(points);
      graphic.endFill();
    },
    [bounds, lineSize, rotationAngle]
  );

  return (
    <Graphics
      ref={graphicsRef}
      draw={draw}
      interactive
      moveWhenInside
      pointerdown={handlePointerDown}
      pointermove={handleDrag}
      pointerout={handlePointerOut}
      pointerover={handlePointerOver}
      pointerup={handlePointerUp}
      pointerupoutside={handleDrop}
      cursor={isDragging ? 'grabbing' : 'grab'}
      visible={inView}
      zIndex={zIndex}
    />
  );
}

export default FrameOutline;
