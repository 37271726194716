import { Card } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';

import SettingDestination from '@feature/studio/setting/SettingDestination';

import Skeleton from '@components/skeletons/Skeleton';

import { destinationIdsAtom } from '@store/Output';

function Connect({ integrations }) {
  const destinationIds = useRecoilValue(destinationIdsAtom);

  return (
    <div className="max-w-screen-lg mx-auto p-10">
      <p className="text-lg text-center font-bold mb-8">
        Connect to hosting providers and social media platforms to distribute your videos automatically.
      </p>

      <div className="d-flex flex-col gap-4">
        {integrations.state === 'loading' ? (
          <Skeleton length={6} />
        ) : (
          destinationIds.map((id) => (
            <Card key={id}>
              <Card.Body>
                <SettingDestination id={id} integrations={integrations.contents} />
              </Card.Body>
            </Card>
          ))
        )}
      </div>
    </div>
  );
}

export default Connect;
