export const masks = [
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/box-radius.png',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/box-shadow.jpg',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/brush-strokes/brush-strokes-left-fast.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/brush-strokes/brush-strokes-left-flip-fast.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/brush-strokes/brush-strokes-left.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/brush-strokes/brush-strokes-right-fast.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/brush-strokes/brush-strokes-right-flip-fast.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/circle-400x400.jpg',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/circle-400x400.png',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/circle.jpg',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/circles/center-double-invert.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/circles/center-double.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/circles/center-small-to-large.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/circles/circle-half-left.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/circles/circle-half-right.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/circles/enter-large-to-small.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/do4x_30.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/doors/doors-diagonal-left.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/doors/doors-diagonal-right.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/doors/doors-horizontal.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/doors/doors-vertical.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/double-arrow-right.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/effects/popup.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/effects/wobble.mov',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/effects/wobble.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/fades/1-second.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/fades/2-second.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/fades/3-second.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/grids/pattern-1-invert.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/grids/pattern-1.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/grids/pattern-2-invert.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/grids/pattern-2.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/grids/pattern-3-invert.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/grids/pattern-3.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/hard-wipes/up.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/mask-circle-1024.jpeg',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/melts/melting-center-flipped.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/melts/melting-center.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/paris.png',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/rounded-box.jpg',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/single-arrow-left.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/single-arrow-right.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/snowflakes-invert.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/snowflakes.mov',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/snowflakes.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/soft-wipes/down-left.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/soft-wipes/down-right.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/soft-wipes/down.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/soft-wipes/left.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/soft-wipes/right.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/soft-wipes/up-left.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/soft-wipes/up-right.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/soft-wipes/up.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/static/circle-sd.jpg',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/waves/double-horizontal.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/waves/double-vertical.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/waves/single-down.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/waves/single-left.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/waves/single-right.mp4',
  'https://shotstack-assets.s3.ap-southeast-2.amazonaws.com/luma-mattes/waves/single-up.mp4',
];
