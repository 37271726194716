import cn from 'classnames';
import { Button } from 'react-bootstrap';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import WorkflowPanelHeader from '@feature/workflows/panel/WorkflowPanelHeader';
import WorkflowPanelTriggerCSV from '@feature/workflows/panel/WorkflowPanelTriggerCSV';
import WorkflowPanelTriggerWebhook from '@feature/workflows/panel/WorkflowPanelTriggerWebhook';

import ButtonElement from '@components/atoms/ButtonElement';

import {
  activeTaskAtom,
  activeWorkflowAtom,
  taskModuleAtomFamily,
  useResetModule,
  workflowDataMappingAtomFamily,
  workflowDataSampleAtomFamily,
} from '@store/Workflows';

import { TRIGGER_OPTIONS } from '@constants/Workflows';

const WorkflowTriggerPanelComponentMap = {
  'shotstack:csv': WorkflowPanelTriggerCSV,
  'shotstack:webhook': WorkflowPanelTriggerWebhook,
};

function WorkflowPanelTrigger() {
  const activeTask = useRecoilValue(activeTaskAtom);
  const activeWorkflow = useRecoilValue(activeWorkflowAtom);
  const setWorkflowData = useSetRecoilState(workflowDataSampleAtomFamily(activeWorkflow));
  const setWorkflowMapping = useSetRecoilState(workflowDataMappingAtomFamily(activeWorkflow));
  const [taskModule, setTaskModule] = useRecoilState(taskModuleAtomFamily(activeTask));
  const selectedModuleDetail = TRIGGER_OPTIONS[taskModule];
  const resetModule = useResetModule();

  const handleSelectModule = (e) => {
    const { name } = e.currentTarget;
    setTaskModule(name);
    setWorkflowMapping({});
    setWorkflowData('');
  };

  const onReset = () => {
    // eslint-disable-next-line no-alert
    const confirmChange = window.confirm(
      'Warning: All changes will be lost. \nAre you sure you want to change the trigger?'
    );
    if (confirmChange) {
      resetModule();
    }
  };

  const WorkflowTriggerPanelComponent = WorkflowTriggerPanelComponentMap[taskModule];

  if (taskModule && selectedModuleDetail) {
    return (
      <div className="settings">
        <div className="form-container">
          <div className="tile-button selected">
            <div className="tile-button__icons">
              {selectedModuleDetail.logoUrl && (
                <img
                  src={selectedModuleDetail.logoUrl}
                  alt={selectedModuleDetail.label}
                  className="tile-button__logo"
                />
              )}
            </div>

            <div>
              <div className="d-flex align-items-center gap-2 mb-1">
                <span className="text-sm font-bold">{selectedModuleDetail.label}</span>
                <span className="workflow__tag">{selectedModuleDetail.type}</span>
              </div>
              <p>
                <span className="description" dangerouslySetInnerHTML={{ __html: selectedModuleDetail.description }} />
              </p>
            </div>
            <Button variant="light" size="sm" onClick={onReset}>
              Change
            </Button>
          </div>
        </div>
        {WorkflowTriggerPanelComponent && <WorkflowTriggerPanelComponent />}
      </div>
    );
  }

  return (
    <div className="settings">
      <div className="form-container">
        <WorkflowPanelHeader name="Select Trigger" description="Choose the event that will start your workflow." />

        <div className="tile-buttons">
          {Object.entries(TRIGGER_OPTIONS).map(([slug, { label, type, description, logoUrl }]) => (
            <ButtonElement
              key={label}
              name={slug}
              type="button"
              bsPrefix="tile-button"
              className={cn({ selected: taskModule === slug })}
              handleEvent={handleSelectModule}
            >
              <div className="tile-button__icons">
                {logoUrl && <img src={logoUrl} alt={label} className="tile-button__logo" />}
              </div>
              <div>
                <div className="d-flex align-items-center gap-2 mb-1">
                  <span className="text-sm font-bold">{label}</span>
                  <span className="workflow__tag">{type}</span>
                </div>
                <p>
                  <span className="description" dangerouslySetInnerHTML={{ __html: description }} />
                </p>
              </div>
            </ButtonElement>
          ))}
        </div>
      </div>
    </div>
  );
}

export default WorkflowPanelTrigger;
