import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { controllerManager } from '@api/utils/AbortControllerManager';
import { pollDataManager } from '@api/utils/PollDataManager';

import { delay } from '@utils/delay';

type PollPredicate = (response: AxiosResponse) => boolean;

/**
 * Starts polling an endpoint until the predicate returns true or polling is cancelled
 */
const startPolling = async (
  client: AxiosInstance,
  url: string,
  predicate: PollPredicate,
  dataKey: string,
  delayTime: number = 1000
): Promise<AxiosResponse> => {
  const controller = controllerManager.getController(dataKey);

  while (true) {
    try {
      const response = await client.request({
        url,
        signal: controller.signal,
      });

      pollDataManager.publish(dataKey, response);

      if (predicate(response)) {
        return response;
      }
      await delay(delayTime);
    } catch (error) {
      if (axios.isCancel(error)) {
        break;
      }
      console.error('Polling error:', error);
      throw error;
    }
  }

  // This is only reached if polling is cancelled
  throw new axios.Cancel('Polling cancelled');
};

export { startPolling };
export type { PollPredicate };
