export default [
  { value: 'custom', text: 'Custom' },
  { value: '576p_16_9', text: '576p SD (16:9)', width: 1024, height: 576 },
  { value: '720p_16_9', text: '720p HD (16:9)', width: 1280, height: 720 },
  { value: '1080p_16_9', text: '1080p Full HD (16:9)', width: 1920, height: 1080 },
  { value: '576p_9_16', text: '576p SD (9:16)', width: 576, height: 1024 },
  { value: '720p_9_16', text: '720p HD (9:16)', width: 720, height: 1280 },
  { value: '1080p_9_16', text: '1080p Full HD (9:16)', width: 1080, height: 1920 },
  { value: '576p_4_5', text: '576p SD (4:5)', width: 461, height: 576 },
  { value: '720p_4_5', text: '720p HD (4:5)', width: 576, height: 720 },
  { value: '1080p_4_5', text: '1080p Full HD (4:5)', width: 864, height: 1080 },
  { value: '576p_1_1', text: '576p SD (1:1)', width: 576, height: 576 },
  { value: '720p_1_1', text: '720p HD (1:1)', width: 720, height: 720 },
  { value: '1080p_1_1', text: '1080p Full HD (1:1)', width: 1080, height: 1080 },
];
