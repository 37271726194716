import { useState } from 'react';
import { useRecoilRefresher_UNSTABLE, useResetRecoilState, useSetRecoilState } from 'recoil';

import WorkflowService from '@api/services/workflow';

import { errorAtom } from '@store/UI';
import { allWorkflowsListSelector } from '@store/Workflows';

const useDeleteTemplate = ({ id, onSuccess } = {}) => {
  const [loading, setLoading] = useState(false);
  const setErrorMessage = useSetRecoilState(errorAtom);
  const resetErrorMessage = useResetRecoilState(errorAtom);
  const refreshAllWorkflowsState = useRecoilRefresher_UNSTABLE(allWorkflowsListSelector);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await WorkflowService.delete(id);
      refreshAllWorkflowsState();
      onSuccess();
    } catch (error) {
      setErrorMessage({ message: error.message });
      setTimeout(() => {
        resetErrorMessage();
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  return { loading, handleDelete };
};

export default useDeleteTemplate;
