import { atom, selector } from 'recoil';

import { authKeysSelector, stageAtom } from '@store/Auth';
import { derivedJsonSelectorFamily } from '@store/EditSelectors';
import { templateIdAtom } from '@store/Template';

import { stageMap } from '@constants/Stage';

import { getSnippet } from '@utils/editor/getSnippet';
import { jsonParse } from '@utils/jsonParse';

export const snippetLangAtom = atom({
  key: 'snippetLangAtom',
  default: 'http',
});

export const snippetTypeAtom = atom({
  key: 'snippetTypeAtom',
  default: 'id',
});

export const snippetPanelAtom = atom({
  key: 'snippetPanelAtom',
  default: false,
});

export const snippetSelector = selector({
  key: 'snippetSelector',
  get: ({ get }) => {
    const id = get(templateIdAtom);
    const language = get(snippetLangAtom);
    const type = get(snippetTypeAtom);
    const keys = get(authKeysSelector);
    const currentStage = get(stageAtom);
    const derivedJson = get(derivedJsonSelectorFamily());
    const derivedJsonParsed = jsonParse(derivedJson);
    const { merge } = derivedJsonParsed;

    const jsonMap = {
      blob: () => derivedJsonParsed,
      id: () => ({
        id: get(templateIdAtom),
        ...(merge && merge.length && { merge }),
      }),
    };

    const json = jsonMap[type]();
    const stage = currentStage || '';
    const key = keys[stageMap[stage]]?.key || '';
    const url = `https://api.shotstack.io/${stage}${type === 'id' ? '/templates' : ''}/render`;

    return getSnippet({
      data: {
        id,
        json,
        merge,
        stage,
        key,
        url,
      },
      language,
    });
  },
});

export const snippetAutomateSelector = selector({
  key: 'snippetAutomateSelector',
  get: ({ get }) => {
    const type = get(snippetTypeAtom);
    const derivedJson = get(derivedJsonSelectorFamily());
    const derivedJsonParsed = jsonParse(derivedJson);
    const { merge } = derivedJsonParsed;

    const jsonMap = {
      blob: () => derivedJsonParsed,
      id: () => ({
        id: get(templateIdAtom),
        ...(merge && merge.length && { merge }),
      }),
    };

    const json = jsonMap[type]();
    return json;
  },
});
