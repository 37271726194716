import { useMemo } from 'react';

import '@css/TemplateGallery.css';

import TemplateGalleryCard from '@feature/studio/template/gallery/TemplateGalleryCard';

import useStudioTemplates from '@hooks/useStudioTemplates';

function TemplateGallery({ baseUrl, numTemplates = 999, randomize = false, withDetails = false, onClick, category }) {
  const studioTemplates = useStudioTemplates();

  const templatesToDisplay = useMemo(() => {
    let filteredTemplates = studioTemplates ?? [];

    if (category) {
      filteredTemplates = filteredTemplates.filter((template) => {
        return template.tags?.includes(category);
      });
    }

    if (randomize) {
      filteredTemplates = filteredTemplates.sort(() => 0.5 - Math.random());
    } else {
      filteredTemplates = filteredTemplates.sort((a, b) => a.id.localeCompare(b.id));
    }
    return filteredTemplates;
  }, [studioTemplates, category, randomize]);

  return (
    <div className="template-gallery">
      {templatesToDisplay?.slice(0, numTemplates).map((data) => (
        <div className="template-gallery__item" key={data.id}>
          <TemplateGalleryCard data={data} baseUrl={baseUrl} withDetails={withDetails} onClick={onClick} />
        </div>
      ))}
    </div>
  );
}

export default TemplateGallery;
