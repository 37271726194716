import { memo } from 'react';
import { useRecoilValue } from 'recoil';

import ClipReactable from '@feature/studio/timeline/TimelineClipReactable';
import TrackReactable from '@feature/studio/timeline/TimelineTrackReactable';

import { clipIdsAtom, trackIdsAtom } from '@store/Edit';
import { useMoveClipState, useMoveTrackState, useResetClipState } from '@store/Timeline';

function TimelineTracks({ tracksElmRef }) {
  const moveTrack = useMoveTrackState();
  const moveClip = useMoveClipState();
  const resetClip = useResetClipState();

  const trackIds = useRecoilValue(trackIdsAtom);
  const clipIds = useRecoilValue(clipIdsAtom);

  return (
    <>
      <div className="track__clips">
        {clipIds.map((id) => (
          <ClipReactable id={id} key={id} />
        ))}
      </div>
      {trackIds.map((id, index) => (
        <TrackReactable
          id={id}
          key={id}
          index={index}
          moveTrack={moveTrack}
          moveClip={moveClip}
          resetClip={resetClip}
          tracksElmRef={tracksElmRef}
        />
      ))}
    </>
  );
}

export default memo(TimelineTracks);
