import { transformTemplate } from '@api/transform/utils/template';

export const transformTemplateAxios = [
  async (response) => {
    const { response: responseJson } = JSON.parse(response);
    const { template: data } = responseJson;
    const template = await transformTemplate(data);
    return { ...responseJson, template };
  },
];
