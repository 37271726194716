import config from '@config';
import axios from 'axios';
import { Deserializer } from 'jsonapi-serializer';

const getBuiltInTemplatesBaseUrl = () => {
  return config.isDev
    ? 'https://ck7deud1dc.execute-api.ap-southeast-2.amazonaws.com/dev'
    : 'https://ljc79ust33.execute-api.ap-southeast-2.amazonaws.com/v1';
};

/**
 * @typedef {'studio' | 'workflow'} TemplateType
 * @typedef {{
 *  id: string;
 *  heading: string;
 *  body: string;
 *  data: object;
 *  thumbnail: string;
 *  media: string;
 *  tags: string;
 *  updated: string;
 * }} Template
 */

/**
 * @type {(templateType: TemplateType, templateId: string) => Promise<Template | null>}
 */
export const getBuiltInTemplate = async (templateType, templateId) => {
  try {
    const url = `${getBuiltInTemplatesBaseUrl()}/public/templates/${templateType}/${templateId}?includeConfig=true`;
    const response = await axios.get(url);

    return new Deserializer({ keyForAttribute: (key) => key }).deserialize(response.data);
  } catch (error) {
    return null;
  }
};

/**
 * @type {(templateType: TemplateType) => Promise<Template[]>}
 */
export const listBuiltInTemplates = async (templateType) => {
  try {
    const url = `${getBuiltInTemplatesBaseUrl()}/public/templates/${templateType}?includeConfig=true`;
    const response = await axios.get(url);

    return new Deserializer({ keyForAttribute: (key) => key }).deserialize(response.data);
  } catch (error) {
    console.error(error);
    return [];
  }
};
