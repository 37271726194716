export default [
  { value: '', text: 'None' },
  { value: 'fade', text: 'Fade / Dissolve' },
  // { value: 'reveal', text: 'Reveal' },
  // { value: 'wipeLeft', text: 'Wipe Left' },
  // { value: 'wipeRight', text: 'Wipe Right' },
  { value: 'slideLeft', text: 'Slide Left' },
  { value: 'slideRight', text: 'Slide Right' },
  { value: 'slideUp', text: 'Slide Up' },
  { value: 'slideDown', text: 'Slide Down' },
  { value: 'carouselLeft', text: 'Carousel Left' },
  { value: 'carouselRight', text: 'Carousel Right' },
  { value: 'carouselUp', text: 'Carousel Up' },
  { value: 'carouselDown', text: 'Carousel Down' },
  { value: 'zoom', text: 'Zoom' },
  // { value: 'shuffleTopRight', text: 'Shuffle Top Right' },
  // { value: 'shuffleRightTop', text: 'Shuffle Right Top' },
  // { value: 'shuffleRightBottom', text: 'Shuffle Right Bottom' },
  // { value: 'shuffleBottomRight', text: 'Shuffle Bottom Right' },
  // { value: 'shuffleBottomLeft', text: 'Shuffle Bottom Left' },
  // { value: 'shuffleLeftBottom', text: 'Shuffle Left Bottom' },
  // { value: 'shuffleLeftTop', text: 'Shuffle Left Top' },
  // { value: 'shuffleTopLeft', text: 'Shuffle Top Left' },
  // { value: 'zoom', text: 'Zoom' },
];
