import { useEffect, useState } from 'react';

import useApi from '@hooks/useApi';

const useUserOnboarding = () => {
  const { data, request } = useApi({
    api: 'onboarding',
    ignoreSelectedStage: true,
    ignoreStageUpdates: true,
  });
  const [userData, setUserData] = useState();

  const onboardUser = async (userData) => {
    await request({
      path: 'userinfo',
      method: 'patch',
      body: {
        action: 'onboard',
        onboarded: true,
      },
    });

    await request({
      path: 'tracking/trackUser',
      method: 'post',
      body: {
        event: 'Submit Onboarding Form',
        userData,
      },
    });
  };

  useEffect(() => {
    if (data) {
      setUserData(data);
    }
  }, [data]);

  return {
    onboardUser,
    userData,
  };
};

export default useUserOnboarding;
