import { createContext, useContext } from 'react';
import { injectStripe } from 'react-stripe-elements';

const Context = createContext();

export const useStripe = () => useContext(Context);

function HookProvider({ stripe, elements, children }) {
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <Context.Provider value={[stripe, elements]}>{children}</Context.Provider>;
}

export default injectStripe(HookProvider);
