import config from '@config';
import { Col, Row } from 'react-bootstrap';

import DolbyForm from '@feature/integrations/providers/dolby/DolbyForm';

import ContentCard from '@components/cards/ContentCard';
import Header from '@components/header/Header';

function GoogleCloudStorage() {
  const Forms = config.integration.stages.map((stage, index) => (
    <DolbyForm key={stage.stage} stage={stage.stage} stageName={stage.name} index={index} />
  ));

  return (
    <>
      <Header title="Dolby">Enhance your media using Dolby API.</Header>
      <Row className="mb-4">
        <Col md={4}>
          <ContentCard image={`/img/provider/dolby.png`}>
            Dolby offers industry-leading audio optimization and enhancement services. Elevate your audio content by
            selecting it as an enhancement with the Ingest API.
          </ContentCard>
        </Col>
        <Col md={8}>
          <ContentCard heading="SETUP">
            <p>
              Sign up to <a href="https://dashboard.dolby.io/">Dolby</a> if you do not already have an account. Copy
              your API Key and paste it in the form below.
            </p>
          </ContentCard>
          <ContentCard heading="CREDENTIALS">{Forms}</ContentCard>
          <ContentCard heading="INTEGRATION">
            <h5>Basic setup</h5>
            <p>
              Select a preset `conference, interview, lecture, meeting, mobile_phone, music, podcast, studio,
              voice_over` and send the following JSON payload in the body of the request.
            </p>
            <pre>
              <b>POST</b>https://api.shotstack.io/ingest/&#123;ENV&#125;/sources
            </pre>
            <p>
              Replace <code>&#123;ENV&#125;</code> with either stage or v1 in the URL above depending on the environment
              and API key you are using.
            </p>
            <pre>{`{
    "url": "https://github.com/shotstack/test-media/raw/main/captioning/scott-ko.mp4",
    "outputs": {
        "renditions": [
            {
                "format": "webm",
                "enhance": {
                    "audio": {
                        "provider": "dolby",
                        "options": {
                            "preset": "studio"
                        }
                    }
                }
            }
        ]
    }
}`}</pre>
          </ContentCard>
        </Col>
      </Row>
    </>
  );
}

export default GoogleCloudStorage;
