import { useRef } from 'react';

const withVideoHover = (VideoComponent) => {
  function WithVideoHover(props) {
    const videoRef = useRef(null);

    const handleMouseEnter = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    };

    const handleMouseLeave = () => {
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.pause();
      }
    };

    return (
      <div className="video-wrapper" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <VideoComponent {...props} ref={videoRef} />
      </div>
    );
  }

  return WithVideoHover;
};

export default withVideoHover;
