import { useEffect, useState } from 'react';

import LegacyCreditsData from '@feature/usage/LegacyCreditsData';

import Skeleton from '@components/skeletons/Skeleton';

import useGetUsageData from '@hooks/useGetUsageData';

import RequestStatus from '@constants/RequestStatus';

/** @deprecated Old way to calculate from & to before the balance API */
function LegacyCredits({ from, to, subscription }) {
  const [credits, setCredits] = useState();
  const { data, loaded, status } = useGetUsageData({
    ignoreSelectedStage: true,
    path: 'credits',
    filter: {
      filter: {
        created: {
          since: from,
          until: to,
        },
      },
    },
  });

  useEffect(() => {
    if (status !== RequestStatus.NOT_REQUESTED || status !== RequestStatus.REQUESTED) {
      if (data?.length > 0) {
        setCredits(data[0].credits || 0);
      }
    }
  }, [status, data]);

  if (!loaded || !subscription) {
    return <Skeleton />;
  }

  return <LegacyCreditsData credits={credits} subscription={subscription} loaded={loaded} />;
}

export default LegacyCredits;
