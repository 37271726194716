export const phpsdk = ({ id, stage, key, merge = [] }) => {
  let mergeFields = '';
  const mergeSetters = [];

  merge.forEach((field, index) => {
    mergeFields += `
$mergeField${index + 1} = new MergeField();
$mergeField${index + 1}
  ->setFind('${field.find}')
  ->setReplace('${field.replace}');
`;

    mergeSetters.push(`$mergeField${index + 1}`);
  });

  return `<?php
require 'vendor/autoload.php'; // Install the Shotstack SDK using: \`composer require shotstack/shotstack-sdk-php\`

use Shotstack\\Client\\Api\\EditApi;
use Shotstack\\Client\\ApiException;
use Shotstack\\Client\\Configuration;
use Shotstack\\Client\\Model\\TemplateRender;
use Shotstack\\Client\\Model\\MergeField;

$config = Configuration::getDefaultConfiguration()
->setHost('https://api.shotstack.io/${stage}')
->setApiKey('x-api-key', '${key}');

$client = new EditApi(null, $config);
${mergeFields}
$template = new TemplateRender();
$template
  ->setId('${id}')${mergeSetters.length ? `\n  ->setMerge([${mergeSetters.join(', ')}]);` : ';'}

try {
    $response = $client->postTemplateRender($template)->getResponse();
} catch (ApiException $e) {
    die('Request failed: ' . $e->getMessage() . $e->getResponseBody());
}

echo $response->getMessage();`;
};
