import { Button, Card, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import '@css/Integrations.css';

import ButtonElement from '@components/atoms/ButtonElement';
import AlphaBadge from '@components/badge/alpha';

import capitaliseWords from '@utils/text/capitaliseWords';

function IntegrationButton({ provider, status }) {
  const history = useNavigate();

  const tracking = {
    name: 'Select Destination',
    properties: {
      Destination: capitaliseWords(provider),
    },
  };

  if (status === 'pending') {
    return (
      <Button variant="light" disabled>
        Coming soon
      </Button>
    );
  }

  return (
    <ButtonElement
      handleEvent={() => history(`/integrations/${provider}`)}
      tracking={tracking}
      className="d-flex align-items-center"
    >
      Configure
    </ButtonElement>
  );
}

function IntegrationsListItem({ title, description, provider, image, status, alpha }) {
  if (status === 'inactive') return null;

  return (
    <Col md={6} xl={4} className="mb-4">
      <Card className="integration-item h-100">
        {alpha && <AlphaBadge />}
        <Card.Img variant="top" src={`/img/provider/${image}`} />
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>{description}</Card.Text>
        </Card.Body>
        <Card.Footer>
          <IntegrationButton provider={provider} status={status} />
        </Card.Footer>
      </Card>
    </Col>
  );
}

export default IntegrationsListItem;
