import { deserialize } from '@api/utils/deserializer';

const getReadyShotstackProxyRendition = (data) => {
  const { status, outputs } = data;
  if (status !== 'ready') return null;
  const renditions = outputs?.renditions || [];
  return renditions.find((r) => r.status === 'ready' && /shotstack-(proxy|overlay|mask)/.test(r.url));
};

const getMediaType = ({ url }) => {
  try {
    const filename = url.split('/').pop();
    const extension = filename.split('.').pop();
    if (filename.includes('overlay')) return 'overlay';
    if (filename.includes('mask')) return 'mask';
    switch (extension) {
      case 'mp4':
      case 'webm':
        return 'video';
      case 'mp3':
        return 'audio';
      case 'png':
      case 'webp':
        return 'image';
      default:
        return 'unknown';
    }
  } catch (e) {
    console.error(e);
    return null;
  }
};

const mapAssetProxy = (sources) => {
  return (sources || [])
    .map((asset) => {
      const isCaption = /\.(srt|vtt)$/.test(asset.source);
      if (isCaption) return { ...asset, type: 'caption' };

      const proxy = getReadyShotstackProxyRendition(asset);
      if (!proxy) return null;
      const type = getMediaType(proxy);
      return { ...asset, proxy, type };
    })
    .filter(Boolean);
};

export const transformSources = async (response) => {
  const { links, meta, data } = response;

  return {
    assets: data?.length ? data : [],
    links,
    meta,
  };
};

export const transformSourcesByCursor = async (response) => {
  const { links, meta, data } = response;
  const assets = await deserialize(data || []);

  return {
    assets: mapAssetProxy(assets),
    links: links || { next: null },
    meta: meta || { next: null },
  };
};
