/**
 * @typedef {import('@animation/animate').AnimationProperties} AnimationProperties
 */

/**
 * @template T
 * @typedef {import('@animation/keyframe').Keyframe<T>} Keyframe<T>
 */

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:x']: number; }>}
 */
export const carouselLeftTransitionInKeyframe = (start, end, properties) => {
  const { clip } = properties;

  const slideFrom = 1;
  const slideTo = clip['offset:x'] || 0;

  return {
    from: {
      ['offset:x']: slideFrom,
    },
    to: {
      ['offset:x']: slideTo,
    },
    start,
    end,
    interpolation: {
      ['offset:x']: 'linear',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:x']: number; }>}
 */
export const carouselLeftTransitionOutKeyframe = (start, end, properties) => {
  const { clip } = properties;

  const slideFrom = clip['offset:x'] || 0;
  const slideTo = -1;

  return {
    from: {
      ['offset:x']: slideFrom,
    },
    to: {
      ['offset:x']: slideTo,
    },
    start,
    end,
    interpolation: {
      ['offset:x']: 'linear',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:x']: number; }>}
 */
export const carouselRightTransitionInKeyframe = (start, end, properties) => {
  const { clip } = properties;

  const slideFrom = -1;
  const slideTo = clip['offset:x'] || 0;

  return {
    from: {
      ['offset:x']: slideFrom,
    },
    to: {
      ['offset:x']: slideTo,
    },
    start,
    end,
    interpolation: {
      ['offset:x']: 'linear',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:x']: number; }>}
 */
export const carouselRightTransitionOutKeyframe = (start, end, properties) => {
  const { clip } = properties;

  const slideFrom = clip['offset:x'] || 0;
  const slideTo = -1;

  return {
    from: {
      ['offset:x']: slideFrom,
    },
    to: {
      ['offset:x']: slideTo,
    },
    start,
    end,
    interpolation: {
      ['offset:x']: 'linear',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:y']: number; }>}
 */
export const carouselUpTransitionInKeyframe = (start, end, properties) => {
  const { clip } = properties;

  const slideFrom = -1;
  const slideTo = clip['offset:y'] || 0;

  return {
    from: {
      ['offset:y']: slideFrom,
    },
    to: {
      ['offset:y']: slideTo,
    },
    start,
    end,
    interpolation: {
      ['offset:y']: 'linear',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:y']: number; }>}
 */
export const carouselUpTransitionOutKeyframe = (start, end, properties) => {
  const { clip } = properties;

  const slideFrom = clip['offset:y'] || 0;
  const slideTo = 1;

  return {
    from: {
      ['offset:y']: slideFrom,
    },
    to: {
      ['offset:y']: slideTo,
    },
    start,
    end,
    interpolation: {
      ['offset:y']: 'linear',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:y']: number; }>}
 */
export const carouselDownTransitionInKeyframe = (start, end, properties) => {
  const { clip } = properties;

  const slideFrom = 1;
  const slideTo = clip['offset:y'] || 0;

  return {
    from: {
      ['offset:y']: slideFrom,
    },
    to: {
      ['offset:y']: slideTo,
    },
    start,
    end,
    interpolation: {
      ['offset:y']: 'linear',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:y']: number; }>}
 */
export const carouselDownTransitionOutKeyframe = (start, end, properties) => {
  const { clip } = properties;

  const slideFrom = clip['offset:y'] || 0;
  const slideTo = -1;

  return {
    from: {
      ['offset:y']: slideFrom,
    },
    to: {
      ['offset:y']: slideTo,
    },
    start,
    end,
    interpolation: {
      ['offset:y']: 'linear',
    },
  };
};
