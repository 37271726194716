export const nodesdk = ({ id, stage, key, merge = [] }) => {
  let mergeFields = '';
  const mergeSetters = [];

  merge.forEach((field, index) => {
    mergeFields += `
const mergeField${index + 1} = new Shotstack.MergeField;
mergeField${index + 1}
  .setFind('${field.find}')
  .setReplace('${field.replace}');
`;

    mergeSetters.push(`mergeField${index + 1}`);
  });

  return `const Shotstack = require('shotstack-sdk'); // Install the Shotstack SDK using: \`npm install shotstack-sdk\`

const defaultClient = Shotstack.ApiClient.instance;
defaultClient.basePath = 'https://api.shotstack.io/${stage}';

const DeveloperKey = defaultClient.authentications['DeveloperKey'];
DeveloperKey.apiKey = '${key}';

const api = new Shotstack.EditApi();
${mergeFields}
const template = new Shotstack.TemplateRender;
template
  .setId('${id}')${mergeSetters.length ? `\n  .setMerge([${mergeSetters.join(', ')}]);` : ';'}

api.postTemplateRender(template).then((data) => {
    console.log(JSON.stringify(data, null, 2));
}, (error) => {
    console.error(error);
});`;
};
