import { v4 as uuid } from 'uuid';

const BASE_URL_OVERLAYS = 'https://templates.shotstack.io/elements';
// [color - count (for that color in bucket)]
const LOWER_THIRDS = {
  misc: 10,
  monochrome: 16,
  blue: 12,
  neon: 6,
  pastel: 10,
  purple: 4,
  orange: 5,
  lightorange: 4,
  red: 15,
};

const generateLowerThird = (color, index) => {
  const paddedIndex = (index + 1).toString().padStart(2, '0');
  return {
    id: uuid(),
    type: 'element',
    thumbnail: `${BASE_URL_OVERLAYS}/lower-thirds/${color}/${paddedIndex}-thumbnail.mp4`,
    in: `${BASE_URL_OVERLAYS}/lower-thirds/${color}/${paddedIndex}-in-source.mov`,
    out: `${BASE_URL_OVERLAYS}/lower-thirds/${color}/${paddedIndex}-out-source.mov`,
  };
};

export const lowerThirds = Object.entries(LOWER_THIRDS).reduce((acc, [color, count]) => {
  acc[color] = Array.from({ length: count }, (_, index) => generateLowerThird(color, index));
  return acc;
}, {});
