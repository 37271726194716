import { Button, Offcanvas } from 'react-bootstrap';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { IconClearAll, IconCollectionPlay } from '@assets/icons';

import Thumbnail from '@feature/studio/render/RenderPreview';
import ThumbnailStatus from '@feature/studio/render/RenderPreviewStatus';

import EmptyState from '@components/atoms/EmptyState';

import { renderSubmittedAtom, rendersAtom, rendersPanelAtom } from '@store/Renders';
import { errorAtom } from '@store/UI';

function PanelRenders() {
  const renders = useRecoilValue(rendersAtom);
  const resetRenders = useResetRecoilState(rendersAtom);
  const submitted = useRecoilValue(renderSubmittedAtom);
  const [showPanel, setShowPanel] = useRecoilState(rendersPanelAtom);
  const setErrorMessage = useSetRecoilState(errorAtom);
  const handleClose = () => setShowPanel(false);

  const handleErrorMessageChange = (message) => {
    setErrorMessage({ message });
  };

  const handleClearAll = () => {
    resetRenders();
  };

  return (
    <Offcanvas show={showPanel} onHide={handleClose} placement="end" style={{ width: '600px' }}>
      <Offcanvas.Header closeButton>
        <div className="d-flex align-items-center justify-content-between w-100">
          <Offcanvas.Title>Renders</Offcanvas.Title>
          <Button variant="ghost" onClick={handleClearAll} disabled={renders.length === 0} title="Clear all renders">
            <IconClearAll size={24} />
          </Button>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {submitted && <ThumbnailStatus status="submitted" />}
        {renders.map((render) => (
          <Thumbnail key={render.id} renderData={render} handleErrors={handleErrorMessageChange} />
        ))}
        {!submitted && renders.length === 0 && (
          <EmptyState Icon={IconCollectionPlay}>
            <h5>Render queue empty</h5>
          </EmptyState>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default PanelRenders;
