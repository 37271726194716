import config from '@config';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import useHotjar from 'react-use-hotjar';
import { RecoilEnv, RecoilRoot } from 'recoil';
import { RecoilDevTools } from 'recoil-gear';
import RecoilNexus from 'recoil-nexus';

import '@css/index.css';

// @ts-ignore TODO: fix this
import { Routes, SdkRoute } from '@src/Routes';

// @ts-ignore TODO: fix this
import { Auth0Provider } from '@context/Auth0ReactSPA';
// @ts-ignore TODO: fix this
import { ShotstackProvider } from '@context/ShotstackContext';

// @ts-ignore TODO: fix this
import { AnalyticsProvider } from '@components/analytics/Analytics';
// @ts-ignore TODO: fix this
import PageTracking from '@components/analytics/PageTracking';
import ErrorToast from '@components/error/ErrorToast';
// @ts-ignore TODO: fix this
import Intercom from '@components/intercom/Intercom';

const { isDev } = config;
const { id: HOTJAR_ID, version: HOTJAR_VERSION } = config.analytics.hotjar;

if (isDev) {
  RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;
}

const isSdk = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  return params.get('sdk') === 'true';
};

function App() {
  const AppRoutes = isSdk() ? SdkRoute : Routes;

  const { initHotjar } = useHotjar();

  useEffect(() => {
    initHotjar(HOTJAR_ID, HOTJAR_VERSION);
  }, [initHotjar]);

  return (
    <BrowserRouter>
      <RecoilRoot>
        {config.env !== 'production' && <RecoilDevTools />}
        <RecoilNexus />
        <ErrorToast />
        <AnalyticsProvider isSdk={isSdk()}>
          <ShotstackProvider>
            <Auth0Provider
              domain={config.auth0.domain}
              client_id={config.auth0.clientId}
              audience={config.auth0.audience}
              redirect_uri={window.location.origin}
              leeway="360"
            >
              <Intercom isSdk={isSdk()}>
                <PageTracking />
                <AppRoutes />
              </Intercom>
            </Auth0Provider>
          </ShotstackProvider>
        </AnalyticsProvider>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
