import { selector } from 'recoil';

import { getAllIntegrations } from '@api/services/integration';

import { authReadySelector } from '@store/Auth';

export const integrationsSelector = selector({
  key: 'integrations',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    await get(authReadySelector);
    const response = await getAllIntegrations();
    return response;
  },
});
