import { jsonParse } from '@utils/jsonParse';

export const transformResponseAxiosDefault = [
  (response) => {
    // If the response is an empty string, return an empty object
    if (typeof response === 'string' && response.length === 0) {
      return {};
    }

    const responseJson = jsonParse(response);

    // If the parsed response is not an object, return it as is
    if (typeof responseJson !== 'object' || responseJson === null) {
      return response;
    }

    // Check if the expected properties exist, otherwise return the whole JSON object
    const { data, response: responseData } = responseJson;

    // If the 'data' property does not exist, fall back to 'responseData' or the entire JSON object
    if (data === undefined || data === null) {
      return responseData ?? responseJson;
    }

    // Return the 'data' property of the parsed object
    return data;
  },
];
