import config from '@config';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Elements, StripeProvider } from 'react-stripe-elements';

import { useAuth0 } from '@context/Auth0ReactSPA';
import Auth0TokenContext from '@context/Auth0TokenContext';
import StripeHookProvider from '@context/StripeHookProvider';

import BillingCardDefault from '@feature/billing/BillingCardDefault';
import BillingCardForm from '@feature/billing/BillingCardForm';
import CurrentSubscription from '@feature/subscription/SubscriptionCurrent';

import LinkElement from '@components/atoms/LinkElement';
import Content from '@components/content/Content';
import Layout from '@components/layout/Layout';
import Skeleton from '@components/skeletons/Skeleton';

const USERINFO_ENDPOINT = 'userinfo';
const DEFAULT_CARD_ENDPOINT = 'payment/default';
const CUSTOMER_ENDPOINT = 'payment/customer';

// eslint-disable-next-line no-promise-executor-return
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

function Billing() {
  const { loading, user, getTokenSilently } = useAuth0();
  const [token, setToken] = useState([]);
  const [defaultCard, setDefaultCard] = useState();
  const [defaultCardError, setDefaultCardError] = useState(false);
  const [subscription, setSubscription] = useState();

  const handleCardUpdate = useCallback(
    async (shouldRetry) => {
      const MAX_TRIES = 5;
      const DELAY = 2500;
      let currentTry = 1;
      let enableRetry = shouldRetry || false;

      axios.interceptors.response.use(async (response) => {
        if (response?.data?.data?.stripe_id) {
          enableRetry = false;
          currentTry = 1;
          return Promise.resolve(response);
        }

        if (enableRetry) {
          if (currentTry < MAX_TRIES) {
            await delay(DELAY);
            currentTry += 1;
            return axios.request(response.config);
          }
        }

        enableRetry = false;
        currentTry = 1;
        return Promise.resolve(response);
      });

      setDefaultCardError(false);
      setDefaultCard();

      if (token.length) {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        try {
          const userInfo = await axios.get(config.onboarding.url + USERINFO_ENDPOINT, { headers });

          enableRetry = false;
          currentTry = 1;

          if (!userInfo.data.data.stripe_id) {
            setDefaultCard('No default payment method found. Please add a new card.');

            const customer = await axios.get(config.onboarding.url + CUSTOMER_ENDPOINT, {
              params: { customerId: null },
              headers,
            });

            setSubscription(customer.data.data.subscription);
            return;
          }

          const cardRequest = axios.get(config.onboarding.url + DEFAULT_CARD_ENDPOINT, {
            params: { customerId: userInfo.data.data.stripe_id },
            headers,
          });

          const customerRequest = axios.get(config.onboarding.url + CUSTOMER_ENDPOINT, {
            params: { customerId: userInfo.data.data.stripe_id },
            headers,
          });

          const [card, customer] = await axios.all([cardRequest, customerRequest]);

          setDefaultCard(card.data.data);
          setSubscription(customer.data.data.subscription);
        } catch (error) {
          setDefaultCardError(
            'There was an error fetching your account details. Please refresh the page or contact us if the issue continues.'
          );
        }
      }
    },
    [token]
  );

  useEffect(() => {
    async function getToken() {
      const tokenResponse = await getTokenSilently();
      setToken(tokenResponse);
    }

    getToken();
  }, [getTokenSilently]);

  useEffect(() => {
    handleCardUpdate(false);
  }, [token, handleCardUpdate]);

  if (loading || !user) {
    return <div />;
  }

  return (
    <Auth0TokenContext.Provider value={token}>
      <Layout>
        <div className="text-prose mb-16">
          <p className="uppercase-label">Billing</p>
          <h1>Payment Details</h1>
          <p className="text-gray-500">
            Add or update your credit card details to make sure your paid plan stays active. Paid plans have no
            watermarks and scale to meet your usage, see{' '}
            <LinkElement to="https://shotstack.io/pricing/" external>
              pricing
            </LinkElement>{' '}
            for details and <Link to="/subscription">subscribe</Link> when you are ready.
          </p>
        </div>

        <Content heading="YOUR CURRENT SUBSCRIPTION">
          <CurrentSubscription subscription={subscription} />
        </Content>

        <Content heading="DEFAULT PAYMENT METHOD">
          <BillingCardDefault card={defaultCard} error={defaultCardError} />
        </Content>

        <Content heading="ADD NEW PAYMENT METHOD">
          {!defaultCard && !subscription ? (
            <Skeleton />
          ) : (
            <StripeProvider apiKey={config.stripe.publishableKey}>
              <Elements>
                <StripeHookProvider>
                  <BillingCardForm user={user} onCardChange={handleCardUpdate} />
                </StripeHookProvider>
              </Elements>
            </StripeProvider>
          )}
        </Content>
      </Layout>
    </Auth0TokenContext.Provider>
  );
}

export default Billing;
