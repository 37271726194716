import { pickBy } from 'lodash-es';
import { atom, atomFamily, selector, selectorFamily, useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';

import { assetIdsAtom, clipIdsAtom, clipsAtomFamily, overridesAtomFamily } from '@store/Edit';

import { formatMergeReplaceToImplicitType } from '@utils/merge';
import { removeMetaDataRecursive } from '@utils/template';

export const mergePopoverAtom = atom({
  key: 'mergePopoverAtom',
  default: false,
});

export const mergeFamily = atomFamily({
  key: 'merge',
  default: [],
});

export const mergeIdsAtom = atom({
  key: 'merge/ids',
  default: [],
});

export const mergeReplacementsSelector = selector({
  key: 'mergeReplacementsSelector',
  get: ({ get }) => {
    const mergeIds = get(mergeIdsAtom);
    const replacements = mergeIds.map((mergeId) => get(mergeFamily(mergeId)));
    return replacements.reduce((acc, { find, replace }) => {
      acc[find] = replace;
      return acc;
    }, {});
  },
});

export const mergeFieldKeysSelector = selector({
  key: 'edit/merge/mergeFieldKeysSelector',
  get: ({ get }) => {
    const mergeIds = get(mergeIdsAtom);
    const mergeFields = mergeIds.map((mergeId) => get(mergeFamily(mergeId)));
    const mergeFieldsWithKey = mergeFields.filter((mergeField) => mergeField.find);
    return mergeFieldsWithKey.map((mergeField) => mergeField.find);
  },
});

export const mergeDataJsonSelectorFamily = selectorFamily({
  key: 'mergeDataJsonSelectorFamily',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get:
    (debug) =>
    ({ get }) => {
      return get(mergeIdsAtom)
        .map((mergeId) => {
          const mergeField = get(mergeFamily(mergeId));
          const replace = formatMergeReplaceToImplicitType(mergeField.replace, mergeField?.meta?.type);
          const newField = { ...mergeField, replace: replace ?? '' };
          return !debug ? removeMetaDataRecursive(newField) : newField;
        })
        .filter(({ find }) => Boolean(find));
    },
});

const addMergeFieldCallback = (callbackArgs) => {
  const { set } = callbackArgs;
  return ({ find = '', replace = '' } = {}) => {
    const id = uuid();
    set(mergeIdsAtom, (currentState) => {
      return [...currentState, id];
    });
    set(mergeFamily(id), { id, find, replace, meta: { type: 'string' } });
  };
};

const updateMergeFieldCallback = (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return (id, update) => {
    const prevState = snapshot.getLoadable(mergeFamily(id)).contents;
    const newState = { ...prevState, ...update };
    const clips = snapshot.getLoadable(clipIdsAtom).contents;

    clips.forEach((clipId) => {
      const overrides = snapshot.getLoadable(overridesAtomFamily(clipId)).contents;
      const [property] = Object.entries(overrides).find(([, key]) => key === prevState.find) || [];

      if (!property) return;

      if (update.find) {
        set(overridesAtomFamily(clipId), (prevState) => ({ ...prevState, [property]: update.find }));
      }

      if (update.replace) {
        set(clipsAtomFamily(clipId), (prevState) => ({ ...prevState, [property]: newState.replace }));
      }
    });

    set(mergeFamily(id), newState);
  };
};

const deleteMergeFieldCallback = (callbackArgs) => {
  const { set, reset, snapshot } = callbackArgs;
  return (id) => {
    const collection = [snapshot.getLoadable(clipIdsAtom).contents, snapshot.getLoadable(assetIdsAtom).contents].flat();
    const { find } = snapshot.getLoadable(mergeFamily(id)).contents;

    collection.forEach((clipAssetId) => {
      const overrides = snapshot.getLoadable(overridesAtomFamily(clipAssetId)).contents;
      const pickedOverrides = pickBy(overrides, (val) => val !== find);
      set(overridesAtomFamily(clipAssetId), pickedOverrides);
    });

    set(mergeIdsAtom, (currentState) => currentState.filter((mergeId) => mergeId !== id));
    reset(mergeFamily(id));
  };
};

export const useAddMergeField = () => useRecoilCallback(addMergeFieldCallback);
export const useUpdateMergeField = () => useRecoilCallback(updateMergeFieldCallback);
export const useDeleteMergeField = () => useRecoilCallback(deleteMergeFieldCallback);
