import { isNumber } from 'lodash-es';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import '@css/TimelineRange.css';

import { timelineDurationSelector } from '@store/EditSelectors';
import { rangeAtomFamily } from '@store/Output';
import { renderPreviewLengthAtom, renderPreviewStartAtom } from '@store/Renders';
import { timelineScaleAtom } from '@store/Timeline';

import { TIMELINE_TRACK_OFFSET } from '@constants/Timeline';

import getPixelSeconds from '@utils/getPixelSeconds';

const PreviewRange = ({ pixelSeconds }) => {
  const rangeStart = useRecoilValue(rangeAtomFamily('start'));
  const rangeLength = useRecoilValue(rangeAtomFamily('length'));
  const previewRangeStart = useRecoilValue(renderPreviewStartAtom);
  const previewRangeLength = useRecoilValue(renderPreviewLengthAtom);
  const start = Boolean(previewRangeLength) ? previewRangeStart : rangeStart;
  const length = Boolean(previewRangeLength) ? previewRangeLength : rangeLength;

  if (!isNumber(start) || !isNumber(length)) {
    return null;
  }

  const rangeStartPx = pixelSeconds(start);
  const rangeLengthPx = pixelSeconds(length);

  return (
    <div
      className="timeline-range preview-range"
      style={{
        '--range-start': `${rangeStartPx}px`,
        '--range-length': `${rangeLengthPx}px`,
      }}
    />
  );
};

const DurationRange = ({ pixelSeconds }) => {
  const timelineDuration = useRecoilValue(timelineDurationSelector);
  const timelineDurationPx = pixelSeconds(timelineDuration);

  if (!isNumber(timelineDuration) || timelineDuration === 0) {
    return null;
  }

  return (
    <div
      className="timeline-range duration-range"
      style={{
        '--range-length': `${timelineDurationPx}px`,
      }}
    />
  );
};

const TimelineRange = () => {
  const timelineScale = useRecoilValue(timelineScaleAtom);

  const pixelSeconds = useMemo(() => getPixelSeconds(timelineScale), [timelineScale]);

  return (
    <div
      className="timeline-range-container"
      style={{
        transform: `translateX(${TIMELINE_TRACK_OFFSET}px)`,
      }}
    >
      <PreviewRange pixelSeconds={pixelSeconds} />
      <DurationRange pixelSeconds={pixelSeconds} />
    </div>
  );
};

export default TimelineRange;
