import roundToPrecision from '@utils/math/roundToPrecision';

export const transformProbeAxios = [
  (response) => {
    const { response: data } = JSON.parse(response);
    const { metadata } = data;
    const { format, streams } = metadata || {};

    if (format) {
      const { width, height } = (streams || []).find(({ codec_type }) => codec_type === 'video') || {};

      return {
        attributes: {
          ...format,
          width,
          height,
          duration: roundToPrecision(+format.duration),
          source: format.filename,
        },
      };
    }

    return {};
  },
];
