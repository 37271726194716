import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { mediaCaptureAtomFamily } from '@store/Media';

import generateThumbnail from '@utils/editor/generateThumbnail';

function useGenerateThumbnail({ src }) {
  const [thumbnail, setThumbnail] = useRecoilState(mediaCaptureAtomFamily(src));

  const capture = useCallback(async () => {
    try {
      setThumbnail({ loading: true, status: 'processing', data: null });
      const data = await generateThumbnail({ src });
      setThumbnail({ loading: false, status: 'done', data });
    } catch (error) {
      console.error(error);
      setThumbnail({ loading: false, status: 'failed-capture', data: null });
    }
  }, [src]);

  useEffect(() => {
    if (['failed-restore'].includes(thumbnail.status)) {
      capture();
    }
  }, [thumbnail]);

  return useMemo(() => ({ thumbnail }), [thumbnail]);
}

export default useGenerateThumbnail;
