import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';

import '@css/TemplateList.css';

import CreateBlankTemplateButton from '@feature/studio/template/TemplateCreate';
import BulkDeleteTemplateButton from '@feature/studio/template/TemplateDeleteBulk';
import TemplateListItems from '@feature/studio/template/list/TemplateListItems';

import { useAnalytics } from '@components/analytics/Analytics';
import Layout from '@components/layout/Layout';

function Studio() {
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent('View Studio');
  }, []);

  return (
    <Layout>
      <Card className="p-3 mb-10">
        <Card.Body>
          <div className="flex items-center justify-between">
            <div>
              <p className="uppercase-label">Studio</p>
              <h1>My Templates</h1>
            </div>
            <div>
              <div className="flex gap-2">
                <BulkDeleteTemplateButton />
                <CreateBlankTemplateButton tracking={{ Position: 'Above Template List' }} />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>

      <TemplateListItems />
    </Layout>
  );
}

export default Studio;
