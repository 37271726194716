import config from '@config';
import axios from 'axios';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { authTokenAtom, stageAtom } from '@store/Auth';
import { mediaUploadingAtom } from '@store/Media';

import useAxiosPoll from '@hooks/useAxiosPoll';

const UPLOAD_STATUS_READY = 'ready';
const UPLOAD_STATUS_FAILED = 'failed';
const UPLOAD_FILE_ENDPOINT = 'upload';

const UPLOAD_REQUEST_URL = `${config.ingest.url}${UPLOAD_FILE_ENDPOINT}`;

const UPLOAD_POLLING_INTERVAL = 2000;

const useAssetUpload = ({ inputRef, onUploadComplete } = {}) => {
  const token = useRecoilValue(authTokenAtom);
  const { startPolling } = useAxiosPoll();
  const [isUploading, setIsUploading] = useRecoilState(mediaUploadingAtom);
  const [uploadErorr, setUploadError] = useState();
  const stage = useRecoilValue(stageAtom);

  const requestHeaders = {
    headers: {
      Authorization: `Bearer ${token}`,
      stage,
    },
  };

  const getStatus = ({ sourceId }) => {
    const url = `${config.ingest.url}sources/${sourceId}`;
    const pollConfig = requestHeaders;
    const onPollCheck = (response) => {
      const { attributes } = response.data.data;
      return [UPLOAD_STATUS_READY, UPLOAD_STATUS_FAILED].includes(attributes?.status);
    };

    return startPolling({
      url,
      config: pollConfig,
      interval: UPLOAD_POLLING_INTERVAL,
      onPollCheck,
    });
  };

  const getFileUploadAttrs = async () => {
    const response = await axios.post(UPLOAD_REQUEST_URL, {}, requestHeaders);
    const { attributes } = response.data.data;
    if (!attributes?.id) {
      throw new Error('Upload request failed. No attribute id returned.');
    }
    return attributes;
  };

  const uploadFile = ({ sourceUrl, file }) =>
    axios.put(sourceUrl, file, {
      headers: {
        'Content-Type': '',
      },
    });

  const onBrowseFiles = (e) => {
    e.stopPropagation();
    inputRef.current.click();
  };

  const onFileChange = async () => {
    const [file] = inputRef.current?.files || [];
    if (!file) {
      return;
    }
    setUploadError();
    setIsUploading(true);

    try {
      const { id: sourceId, url: sourceUrl } = await getFileUploadAttrs();

      await uploadFile({ sourceUrl, file });

      const response = await getStatus({ sourceId });
      const { status } = response.data.data.attributes;
      if (status === UPLOAD_STATUS_FAILED) {
        throw new Error('Upload failed.');
      }

      onUploadComplete(response.data.data.attributes);
    } catch (error) {
      console.error(error);
      setUploadError('Sorry, there was a problem uploading your file.');
    }

    setIsUploading(false);
  };

  return {
    isUploading,
    uploadErorr,
    onBrowseFiles,
    onFileChange,
  };
};

export default useAssetUpload;
