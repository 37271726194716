export const theme = {
  base: 'vs-dark',
  inherit: true,
  rules: [
    { token: 'comment', foreground: '6272a4', fontStyle: 'italic' },
    { token: 'string', foreground: 'f1fa8c' },
    { token: 'keyword', foreground: '8be9fd' },
    { token: 'number', foreground: 'bd93f9' },
    { token: 'regexp', foreground: 'ffb86c' },
    { token: 'annotation', foreground: '50fa7b' },
    { token: 'type', foreground: 'ff79c6' },
    { token: 'tag', foreground: 'ff79c6' },
    { token: 'variable', foreground: 'f8f8f2' },
    { token: 'predefined', foreground: '8be9fd' },
  ],
  colors: {
    'editor.background': '#1e293b',
    'editor.foreground': '#f8f8f2',
    'editor.lineHighlightBackground': '#2c2e3e',
    'editorCursor.foreground': '#f8f8f2',
    'editor.selectionBackground': '#8be9fd55',
    'editorLineNumber.foreground': '#f8f8f255',
    'editorLineNumber.activeForeground': '#f8f8f2',
  },
};
