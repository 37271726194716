import { debounce } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import '@assets/css/PanelMediaListing.css';

import MediaListingPreview from '@components/asset/MediaListingPreview';

import {
  mediaLoadingAtom,
  mediaMetaAtom,
  mediaSelectorFamily,
  useAddClipToTimelineCallback,
  useDeleteMediaCallback,
  useFetchMediaCallback,
} from '@store/Media';

function PanelMediaListing({ type }) {
  const mediaList = useRecoilValue(mediaSelectorFamily(type));
  const mediaMeta = useRecoilValue(mediaMetaAtom);
  const mediaLoading = useRecoilValue(mediaLoadingAtom);
  const fetchMedia = useFetchMediaCallback();
  const addClipToTimeline = useAddClipToTimelineCallback();
  const deleteMedia = useDeleteMediaCallback();
  const [selectedMedia, setSelectedMedia] = useState([]);
  const endRef = useRef(null);

  const observerCallback = useCallback(
    debounce((entries) => {
      const [entry] = entries;
      if (entry?.isIntersecting && !mediaLoading && mediaMeta.next !== null) {
        fetchMedia(mediaMeta);
      }
    }, 500),
    [mediaLoading, fetchMedia, mediaMeta]
  );

  const fetchMediaButton = () => {
    if (mediaMeta.next !== null) {
      fetchMedia(mediaMeta);
    }
  };
  const handleOnSelect = ({ id, selected }) => {
    const media = mediaList.find((m) => m.id === id);
    const mediaIds = [media?.id, media?.proxy?.id].filter(Boolean);

    if (selected) {
      setSelectedMedia((prev) => [...prev, ...mediaIds]);
    } else {
      setSelectedMedia((prev) => prev.filter((i) => !mediaIds.includes(i)));
    }
  };

  const handleDeleteSelected = () => {
    deleteMedia(selectedMedia);
    setSelectedMedia([]);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, { threshold: 1 });
    if (endRef.current) observer.observe(endRef.current);

    return () => {
      if (endRef.current) observer.unobserve(endRef.current);
    };
  }, [observerCallback]);

  return (
    <div className="media-listing">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h6>Library</h6>
        <button onClick={handleDeleteSelected} className="btn btn-danger btn-sm" disabled={selectedMedia.length === 0}>
          Delete Selected
        </button>
      </div>
      <div className="media-listing__container">
        {(mediaList || []).map((media, idx) => (
          <MediaListingPreview
            key={media.id + idx}
            media={media}
            onClick={() => addClipToTimeline({ data: media, type: media.type })}
            onSelect={handleOnSelect}
          />
        ))}
      </div>

      {!mediaList.length && !mediaLoading && (
        <div className="text-center">
          <small>No Items</small>
        </div>
      )}

      {mediaLoading && (
        <div className="text-center">
          <small>Loading...</small>
        </div>
      )}

      <div ref={endRef} style={{ height: '20px' }} />

      {mediaMeta.next !== null && (
        <div className="text-center">
          <button onClick={() => fetchMediaButton()} className="btn btn-light mx-auto">
            Load more media
          </button>
        </div>
      )}
    </div>
  );
}

export default PanelMediaListing;
