import { useRecoilState, useRecoilValue } from 'recoil';

import InputTextElement from '@components/atoms/InputTextElement';

import { workspaceTabAtom } from '@store/Edit';
import { templateIdAtom, templateNameAtom, templateReadyAtom, useSaveTemplate } from '@store/Template';

function TemplateName({ className }) {
  const [templateName, setTemplateName] = useRecoilState(templateNameAtom);
  const templateId = useRecoilValue(templateIdAtom);
  const templateReady = useRecoilValue(templateReadyAtom);
  const workspaceTab = useRecoilValue(workspaceTabAtom);
  const saveTemplate = useSaveTemplate();

  const tracking = {
    name: 'Change Template Name',
    properties: {
      Id: templateId,
    },
  };

  const handleChange = (event) => {
    setTemplateName(event.target.value);
  };

  const handleOnBlur = () => {
    saveTemplate();
  };

  return (
    <div className={className}>
      <InputTextElement
        id="template-name"
        className="template-name"
        inputType="text"
        handleChange={handleChange}
        handleOnBlur={handleOnBlur}
        tracking={tracking}
        disabled={!templateReady || workspaceTab !== 'design'}
        value={!templateReady ? 'Loading...' : templateName}
      />
    </div>
  );
}

export default TemplateName;
