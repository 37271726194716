import config from '@config';
import { Col, Row } from 'react-bootstrap';

import S3Form from '@feature/integrations/providers/s3/S3Form';

import ContentCard from '@components/cards/ContentCard';
import Header from '@components/header/Header';

function S3() {
  const Forms = config.integration.stages.map((stage, index) => (
    <S3Form key={stage.stage} stage={stage.stage} stageName={stage.name} index={index} />
  ));

  return (
    <>
      <Header title="S3 Bucket">Send files directly to one or more S3 buckets directly from Shotstack.</Header>
      <Row className="mb-4">
        <Col md={4}>
          <ContentCard image={`/img/provider/aws.png`}>
            Amazon S3 is an object storage service offering industry-leading scalability, data availability, security,
            and performance. Host your assets in the cloud and serve them using CloudFormation as a CDN.
          </ContentCard>
        </Col>
        <Col md={8}>
          <ContentCard heading="SETUP">
            <p>
              You will need to create an S3 bucket in your own <a href="https://aws.amazon.com/">AWS account</a>. An AWS
              access key id and secret are needed to send files to your account from Shotstack. We recommend creating a
              new user with IAM permissions that only allow access to the buckets needed. Do not use a user with root or
              global permissions.
            </p>
            <p>
              The bucket itself also needs permissions to be configured correctly. For example you will need to allow
              files to be public if you want to use the <b>public-read</b> acl. Once the bucket is created you can add
              the access credentials below.
            </p>
          </ContentCard>
          <ContentCard heading="CREDENTIALS">{Forms}</ContentCard>
          <ContentCard heading="INTEGRATION">
            <h5>Basic setup</h5>
            <p>To send files to S3 add the following to the output parameter of the render request JSON payload:</p>
            <pre>{`"destinations": [{
    "provider": "s3",
    "options": {
        "region": "ap-southeast-2",
        "bucket": "my-s3-bucket"
    }
}]`}</pre>
            <p>
              This will send the rendered files to the <b>my-s3-bucket</b> in the <b>ap-southeast-2</b> region. The
              filename will be the render id plus extension, i.e. <b>edc47d30-a504-4f16-8439-24c863a7a782.mp4</b> and
              will be saved to the root level of the bucket. Thumbnail and poster images will also be transferred.
            </p>
            <h5>Custom setup</h5>
            <p>
              You can also optionally set a prefix (path), custom file name, and set an acl (the default is private):
            </p>
            <pre>{`"destinations": [{
    "provider": "s3",
    "options": {
        "region": "ap-southeast-2",
        "bucket": "my-s3-bucket",
        "prefix": "testing",
        "filename": "video",
        "acl": "public-read"
    }
}]`}</pre>
            <p>
              This will send the rendered files to the <b>my-s3-bucket</b> in the <b>ap-southeast-2</b> region. If the
              rendered file is an mp4, the filename will be <b>video.mp4</b> and will be saved with a prefix{' '}
              <b>testing</b> (virtual path in the console). The acl will also be set to <b>public-read</b>, which is
              suitable for public hosting.
            </p>
            <p>
              When using a custom filename omit the file extension, i.e. .mp4 or .jpg. This will be added based on the
              rendered file type. If a poster or thumbnail are created these will have <b>-poster.jpg</b> and{' '}
              <b>-thumbnail.jpg</b> appended.
            </p>
            <h5>Multiple buckets</h5>
            <p>It is also possible to send files to multiple buckets:</p>
            <pre>{`"destinations": [{
    "provider": "s3",
    "options": {
        "region": "ap-southeast-2",
        "bucket": "my-s3-bucket"
    }
},{
    "provider": "s3",
    "options": {
        "region": "us-east-1",
        "bucket": "my-us-s3-bucket"
    }
}]`}</pre>
            <p>
              This will send the rendered files to the <b>my-s3-bucket</b> in the <b>ap-southeast-2</b> region and to
              the <b>my-us-s3-bucket</b> in the <b>us-east-1</b> region. Buckets must be in the same AWS account and use
              the same IAM access credentials.
            </p>
            <h5>Opting out from the Shotstack destination</h5>
            <p>
              By default all generated files are sent to the Shotstack destination. If you are using S3 you may wish to
              opt out from hosting you videos with Shotstack.
            </p>
            <pre>{`"destinations": [{
    "provider": "s3",
    "options": {
        "region": "ap-southeast-2",
        "bucket": "my-s3-bucket"
    }
},{
    "provider": "shotstack",
    "exclude": true
}]`}</pre>
            <p>This will send a generated video to S3 but not to Shotstack.</p>
          </ContentCard>
        </Col>
      </Row>
    </>
  );
}

export default S3;
