import { useRecoilState } from 'recoil';

import { IconScreenshot } from '@assets/icons';

import InputNumber from '@components/controls/InputNumber';

import { posterAtomFamily } from '@store/Output';

import { parseNumberOrEmptyString } from '@utils/numbers';

function PosterCaptureInput() {
  const [capture, setCapture] = useRecoilState(posterAtomFamily('capture'));

  const handleChange = (event) => {
    const { value } = event.target;
    setCapture(parseNumberOrEmptyString(value));
  };

  return (
    <InputNumber
      id="capture-poster"
      title="Capture"
      value={capture ?? ''}
      min={0}
      prefix={<IconScreenshot size={18} />}
      step={0.01}
      handleChange={handleChange}
    />
  );
}

export default PosterCaptureInput;
