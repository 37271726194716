import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { delay } from '@utils/delay';

const useAxiosPoll = () => {
  const http = axios.create();
  const [isPolling, setIsPolling] = useState(false);

  const controller = new AbortController();

  const startPolling = ({ url = '', config = {}, interval = 3000, onPollCheck }) => {
    if (!onPollCheck) {
      throw Error('useAxiosPoll: missing required "onPollCheck" method.');
    }

    setIsPolling(true);

    http.interceptors.response.use(async (response) => {
      if (onPollCheck && onPollCheck(response)) {
        setIsPolling(false);
        return Promise.resolve(response);
      }

      await delay(interval);

      return http.request(response.config);
    });

    return http.get(url, {
      signal: controller.signal,
      ...config,
    });
  };

  const stopPolling = useCallback(() => controller.abort(), [controller]);

  useEffect(() => () => isPolling && stopPolling(), [isPolling, stopPolling]);

  return { startPolling, stopPolling, isPolling };
};

export default useAxiosPoll;
