/**
 * @typedef {import('@animation/animate').AnimationProperties} AnimationProperties
 */

/**
 * @template T
 * @typedef {import('@animation/keyframe').Keyframe<T>} Keyframe<T>
 */

/**
 * @type {(properties: AnimationProperties) => ({ scale: number; origin: number, destination: number })}
 */
const calculateTextSlideHorizontal = (properties) => {
  const { speed } = properties;

  let slideTo = 0.08;
  if (speed === 'slow') slideTo = 0.03;
  if (speed === 'fast') slideTo = 0.12;

  return {
    scale: 1,
    offset: 0.5 + slideTo,
  };
};

/**
 * @type {(properties: AnimationProperties) => ({ scale: number; origin: number, destination: number })}
 */
const calculateTextSlideVertical = (properties) => {
  const { speed } = properties;

  let slideTo = 0.08;
  if (speed === 'slow') slideTo = 0.03;
  if (speed === 'fast') slideTo = 0.12;

  return {
    scale: 1,
    offset: 0.1 + slideTo,
  };
};

/**
 * @type {(properties: AnimationProperties) => ({ scale: number; offset: number })}
 */
const calculateAssetSlideHorizontal = (properties) => {
  const { canvas, clip, speed } = properties;

  let slideTo = 0.08;
  if (speed === 'slow') slideTo = 0.03;
  if (speed === 'fast') slideTo = 0.12;

  const clipTargetWidth = 1 + slideTo * 2;
  const clipFitWidth = (clip['asset:meta'].width / clip['asset:meta'].height) * (canvas.height / canvas.width);
  const shouldScaleUp = clipFitWidth < clipTargetWidth;

  let scale = clip.scale || 1;
  if (shouldScaleUp) {
    const scaleToFillWidth = canvas.width / clip['asset:meta'].width;
    const scaleToSlide = clipTargetWidth / clipFitWidth;
    scale = Math.max(scaleToFillWidth, scaleToSlide);
  }

  const scaledSlideTo = scale * slideTo + slideTo;
  const offset = scale !== 1 ? scaledSlideTo : slideTo;

  return {
    scale,
    offset,
  };
};

/**
 * @type {(properties: AnimationProperties) => ({ scale: number; offset: number })}
 */
const calculateAssetSlideVertical = (properties) => {
  const { canvas, clip, speed } = properties;

  let slideTo = 0.08;
  if (speed === 'slow') slideTo = 0.03;
  if (speed === 'fast') slideTo = 0.12;

  const clipTargetHeight = 1 + slideTo * 2;
  const clipFitHeight = (clip['asset:meta'].height / clip['asset:meta'].width) * (canvas.width / canvas.height);
  const shouldScaleUp = clipFitHeight < clipTargetHeight;

  let scale = clip.scale || 1;
  if (shouldScaleUp) {
    const scaleToFillHeight = canvas.height / clip['asset:meta'].height;
    const scaleToSlide = clipTargetHeight / clipFitHeight;
    scale = Math.max(scaleToFillHeight, scaleToSlide);
  }

  const scaledSlideTo = scale * slideTo + slideTo;
  const offset = scale !== 1 ? scaledSlideTo : slideTo;

  return {
    scale,
    offset,
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:x']: number; scale: number; }>}
 */
export const slideLeftMotionKeyframe = (start, end, properties) => {
  const { scale, offset } = ['html', 'text'].includes(properties.clip['asset:type'])
    ? calculateTextSlideHorizontal(properties)
    : calculateAssetSlideHorizontal(properties);

  return {
    from: {
      scale,
      ['offset:x']: offset,
      ['offset:y']: 0,
    },
    to: {
      scale,
      ['offset:x']: -offset,
      ['offset:y']: 0,
    },
    start,
    end,
    interpolation: {
      ['offset:x']: 'linear',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:x']: number; scale: number }>}
 */
export const slideRightMotionKeyframe = (start, end, properties) => {
  const { scale, offset } = ['html', 'text'].includes(properties.clip['asset:type'])
    ? calculateTextSlideHorizontal(properties)
    : calculateAssetSlideHorizontal(properties);

  return {
    from: {
      scale,
      ['offset:x']: -offset,
      ['offset:y']: 0,
    },
    to: {
      scale,
      ['offset:x']: offset,
      ['offset:y']: 0,
    },
    start,
    end,
    interpolation: {
      ['offset:x']: 'linear',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:y']: number; scale: number; }>}
 */
export const slideUpMotionKeyframe = (start, end, properties) => {
  const { scale, offset } = ['html', 'text'].includes(properties.clip['asset:type'])
    ? calculateTextSlideVertical(properties)
    : calculateAssetSlideVertical(properties);

  return {
    from: {
      scale,
      ['offset:y']: -offset,
      ['offset:x']: 0,
    },
    to: {
      scale,
      ['offset:y']: offset,
      ['offset:x']: 0,
    },
    start,
    end,
    interpolation: {
      ['offset:y']: 'linear',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:y']: number; scale: number; }>}
 */
export const slideDownMotionKeyframe = (start, end, properties) => {
  const { scale, offset } = ['html', 'text'].includes(properties.clip['asset:type'])
    ? calculateTextSlideVertical(properties)
    : calculateAssetSlideVertical(properties);

  return {
    from: {
      scale,
      ['offset:y']: offset,
      ['offset:x']: 0,
    },
    to: {
      scale,
      ['offset:y']: -offset,
      ['offset:x']: 0,
    },
    start,
    end,
    interpolation: {
      ['offset:y']: 'linear',
    },
  };
};
