import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import ButtonElement from '@components/atoms/ButtonElement';
import InputTextElement from '@components/atoms/InputTextElement';

import useApi from '@hooks/useApi';

import RequestStatus from '@constants/RequestStatus';

function SubscriptionPromotionCode({ onDiscountCodeChange, subscriptionCode }) {
  const [promotionCode, setPromotionCode] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [promotionName, setPromotionName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { data, request, status } = useApi({
    api: 'onboarding',
    ignoreSelectedStage: true,
  });

  const tracking = {
    name: 'Submit Promotion Code',
    properties: {
      code: promotionCode,
    },
  };

  useEffect(() => {
    if ([RequestStatus.NOT_FOUND, RequestStatus.ERROR, RequestStatus.SUCCESS].includes(status)) {
      setIsLoading(false);
    }

    if (status === RequestStatus.NOT_FOUND || status === RequestStatus.ERROR) {
      setIsInvalid(true);
      setPromotionName('');
    }

    if (status === RequestStatus.SUCCESS && data?.plans?.length && !data?.plans?.includes(subscriptionCode)) {
      setIsInvalid(true);
      setPromotionName('');
    }

    if (
      status === RequestStatus.SUCCESS &&
      data?.active &&
      (!data?.plans?.length || data?.plans?.includes(subscriptionCode))
    ) {
      onDiscountCodeChange(data.code);
      setIsInvalid(false);
      setPromotionName(data.name);
    }
  }, [data, onDiscountCodeChange, status, subscriptionCode]);

  const handleClick = async () => {
    setPromotionName('');

    if (!promotionCode) {
      return;
    }

    setIsLoading(true);

    await request({
      method: 'get',
      path: `payment/discount/${promotionCode}`,
    });
  };

  const handleChange = (event) => {
    setIsInvalid(false);
    setPromotionName('');
    setPromotionCode(event.target.value);
  };

  return (
    <Row className="mb-4">
      <Col>
        <Card className="subscription">
          <Card.Body>
            <b>Promotion Code</b>
            <Row className="mt-2">
              <Col md={3}>
                <InputTextElement handleChange={handleChange} />
              </Col>
              <Col md="auto">
                <ButtonElement
                  title="Apply Discount"
                  handleEvent={handleClick}
                  tracking={tracking}
                  variant="light"
                  type="button"
                  loading={isLoading}
                  style={{ minWidth: 100 }}
                >
                  Apply
                </ButtonElement>
              </Col>
              <Col md="auto" className="pt-1">
                {isInvalid && (
                  <span className="text-danger">
                    <b>Invalid promotion code.</b> Check the code and plan are correct.
                  </span>
                )}
                {promotionName && <span className="text-success">{promotionName} applied</span>}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <small>Received a promotion or voucher code? Enter it here to apply the discount.</small>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default SubscriptionPromotionCode;
