function getVideoDimensions(source) {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    const url = source instanceof File ? URL.createObjectURL(source) : source;

    video.addEventListener('loadedmetadata', () => {
      const dimensions = {
        width: video.videoWidth,
        height: video.videoHeight,
      };

      if (source instanceof File) {
        URL.revokeObjectURL(url);
      }

      resolve(dimensions);
    });

    video.addEventListener('error', (error) => {
      if (source instanceof File) {
        URL.revokeObjectURL(url);
      }
      reject(new Error('getVideoDimensions: There was an error loading the video.', { cause: error }));
    });

    video.src = url;
  }).catch((error) => {
    throw error;
  });
}

export default getVideoDimensions;
