export const nodeaxios = ({
  json,
  key,
  url,
}) => `const axios = require('axios'); // Install Axios using: \`npm install axios\`
const data = JSON.stringify(${JSON.stringify(json, null, 2)});

const config = {
  method: 'post',
  url: '${url}',
  headers: { 
    'content-type': 'application/json', 
    'x-api-key': '${key}'
  },
  data: data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data, null, 2));
})
.catch(function (error) {
  console.log(error);
});`;
