function TableMetaData({ data }) {
  return (
    <table className="metadata">
      <tbody>
        {data.map(([label, value, key]) => (
          <tr key={key || label}>
            <td className="metadata__key">{label}</td>
            <td className="metadata__value">{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default TableMetaData;
