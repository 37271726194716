import { useEffect, useState } from 'react';

import '@css/Loader.css';

import Logo from '@assets/img/logo/emblem.png';

const movieWords = [
  'Cutting Clips',
  'Moving Tracks',
  'Editing Timelines',
  'Rendering at Scale',
  'Automating Campaigns',
  'Transitioning Clips',
  'Fading to Black',
  'Sliding Left',
  'Zooming In',
  'Sliding Right',
  'Zooming Out',
  'Overlaying It',
  'Generating with AI',
  'Snipping Away',
  'Fading In and Out',
  'Applying Chroma Key Magic',
  'Going Slow Mo Pro',
  'Mastering Quick Cuts',
  'Dancing the Transition Tango',
  'Grading Colours',
  'Syncing Audio',
];

function Loader() {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPosition((prevPosition) => (prevPosition + 1) % movieWords.length);
    }, 750); // Move to next word every 2 seconds

    return () => clearInterval(intervalId);
  }, []);

  const calculateTranslateY = () => {
    const percentage = (position / movieWords.length) * 100;
    return `translateY(-${percentage}%)`;
  };

  return (
    <div className="loader">
      <div className="spinner">
        <img src={Logo} alt="Shotstack" />
      </div>
      <div className="movie-words-container">
        <div className="movie-words-carousel" style={{ transform: calculateTranslateY() }}>
          {movieWords.map((word, index) => (
            <span key={index} className="movie-word">
              {word}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Loader;
