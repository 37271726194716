import config from '@config';
import axios from 'axios';
import { useRef, useState } from 'react';
import { ButtonGroup, ButtonToolbar, Form, InputGroup } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';

import '@css/AssetSourceInput.css';

import { IconChevronLeft, IconLink } from '@assets/icons';

import AssetPreview from '@components/asset/AssetPreview';
import ButtonElement from '@components/atoms/ButtonElement';
import AliasMenu from '@components/mergeFields/AliasMenu';
import OverrideMenu from '@components/mergeFields/OverrideMenu';

import { authTokenAtom, stageAtom } from '@store/Auth';
import { templateIdAtom } from '@store/Template';

import useAxiosPoll from '@hooks/useAxiosPoll';

const UPLOAD_STATUS_READY = 'ready';
const UPLOAD_STATUS_FAILED = 'failed';
const UPLOAD_FILE_ENDPOINT = 'upload';
const UPLOAD_LINK_ENDPOINT = 'sources';

const UPLOAD_REQUEST_URL = `${config.ingest.url}${UPLOAD_FILE_ENDPOINT}`;
const UPLOAD_LINK_URL = `${config.ingest.url}${UPLOAD_LINK_ENDPOINT}`;

const UPLOAD_POLLING_INTERVAL = 2000;

function AssetSourceInput({
  id,
  clip,
  type,
  accepts,
  requestOptions = {},
  uploadEnabled = true,
  linkUploadEnabled = true,
  aliasEnabled = false,
  onUploadComplete,
  overrideEnabled = true,
  helperText = '',
  assetHolder = true,
}) {
  const fileInput = useRef(null);
  const templateId = useRecoilValue(templateIdAtom);
  const token = useRecoilValue(authTokenAtom);
  const { startPolling } = useAxiosPoll();
  const [uploadViaLink, setUploadViaLink] = useState(false);
  const [uploadLink, setUploadLink] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadErorr, setUploadError] = useState();
  const stage = useRecoilValue(stageAtom);

  const { ['asset:src']: src } = clip || {};

  const tracking = {
    name: `Select Upload ${type}`,
    properties: {
      Id: templateId,
      Title: `Upload ${type}`,
      Method: uploadViaLink ? 'Pasted link' : 'File upload',
    },
  };

  const requestHeaders = {
    headers: {
      Authorization: `Bearer ${token}`,
      stage,
    },
  };

  const onPollCheck = (response) => {
    const { attributes } = response.data.data;
    const { status: fileStatus, outputs } = attributes;
    let statuses = [fileStatus];

    if (outputs?.renditions.length) {
      const renditionStatuses = outputs.renditions.map((r) => r.status);
      statuses = [...statuses, ...renditionStatuses];
    }

    return statuses.every((s) => s === UPLOAD_STATUS_READY) || statuses.some((s) => s === UPLOAD_STATUS_FAILED);
  };

  const getStatus = ({ sourceId }) => {
    const url = `${config.ingest.url}sources/${sourceId}`;
    const pollConfig = requestHeaders;

    return startPolling({
      url,
      config: pollConfig,
      interval: UPLOAD_POLLING_INTERVAL,
      onPollCheck,
    });
  };

  const getFileUploadAttrs = async () => {
    const response = await axios.post(UPLOAD_REQUEST_URL, { ...requestOptions }, requestHeaders);
    const { attributes } = response.data.data;
    if (!attributes?.id) {
      throw new Error('Upload request failed. No attribute id returned.');
    }
    return attributes;
  };

  const uploadFile = ({ sourceUrl, file }) =>
    axios.put(sourceUrl, file, {
      headers: {
        'Content-Type': '',
      },
    });

  const ingestURL = async ({ url }) => {
    const response = await axios.post(UPLOAD_LINK_URL, { url, ...requestOptions }, requestHeaders);
    const { id: sourceId } = response.data.data;
    if (!sourceId) {
      throw new Error('Upload request failed. No source id returned');
    }
    return response.data.data;
  };

  const handleBrowseFiles = (e) => {
    e.stopPropagation();
    fileInput.current.click();
  };

  const handleFileChange = async () => {
    const [file] = fileInput.current?.files || [];
    if (!file) {
      return;
    }
    setUploadError();
    setIsUploading(true);

    try {
      const { id: sourceId, url: sourceUrl } = await getFileUploadAttrs();

      await uploadFile({ sourceUrl, file });

      const response = await getStatus({ sourceId });
      const { attributes } = response.data.data;
      const { status: fileStatus, outputs } = attributes;
      let statuses = [fileStatus];
      if (outputs?.renditions.length) {
        const renditionStatuses = outputs.renditions.map((r) => r.status);
        statuses = [...statuses, ...renditionStatuses];
      }
      if (statuses.includes(UPLOAD_STATUS_FAILED)) {
        throw new Error('Upload failed.');
      }
      if (onUploadComplete) {
        onUploadComplete(response.data?.data.attributes);
      }
    } catch (error) {
      console.error(error);
      setUploadError('Sorry, there was a problem uploading your file.');
    }

    setIsUploading(false);
  };

  const handleLinkSubmit = async () => {
    if (!uploadLink) {
      return;
    }
    setUploadError();
    setIsUploading(true);

    try {
      const { id: sourceId } = await ingestURL({ url: uploadLink });

      const response = await getStatus({ sourceId });
      const { attributes } = response.data.data;
      const { status: fileStatus, outputs } = attributes;
      let statuses = [fileStatus];
      if (outputs?.renditions.length) {
        const renditionStatuses = outputs.renditions.map((r) => r.status);
        statuses = [...statuses, ...renditionStatuses];
      }
      if (statuses.includes(UPLOAD_STATUS_FAILED)) {
        throw new Error('Upload failed.');
      }
      onUploadComplete(response.data.data.attributes);
    } catch (error) {
      console.error(error);
      setUploadError('Sorry, there was a problem getting the requested file.');
    }

    setIsUploading(false);
  };

  const handleToggleLinkUpload = () => {
    setUploadViaLink(!uploadViaLink);
  };

  const handleLinkURLChange = (e) => {
    setUploadLink(e.target.value);
  };

  if (src && assetHolder) {
    return (
      <div className="asset-source">
        <div className="asset-source__body">
          <div className="asset-source__preview">
            <AssetPreview id={id} type={type} />
          </div>
          <div className="asset-source__actions">
            <ButtonGroup className="d-flex gap-2">
              <OverrideMenu path="asset:src" isButton />
              {aliasEnabled && <AliasMenu path="asset:src" isButton />}
            </ButtonGroup>
          </div>
        </div>
      </div>
    );
  }

  if (uploadViaLink) {
    return (
      <>
        <div className="asset-upload">
          <div className="asset-upload__panel">
            <ButtonElement handleEvent={handleToggleLinkUpload} variant="link" disabled={!type || isUploading}>
              <IconChevronLeft size={20} /> <span>Back to file upload</span>
            </ButtonElement>

            <InputGroup className="mt-2">
              <Form.Control placeholder="Paste your URL here.." onChange={handleLinkURLChange} />
              <ButtonElement
                handleEvent={handleLinkSubmit}
                variant="primary"
                tracking={tracking}
                loading={isUploading}
                disabled={!type || !uploadLink || isUploading}
              >
                Upload
              </ButtonElement>
            </InputGroup>
            {helperText && <p className="helper-text centered">{helperText}</p>}
          </div>
        </div>
        {uploadErorr && <p className="input-error">{uploadErorr}</p>}
      </>
    );
  }

  return (
    <>
      <div className="asset-upload">
        <div className="asset-upload__panel">
          <ButtonToolbar className="d-flex gap-2">
            <ButtonGroup className="asset-upload__group">
              <ButtonElement
                handleEvent={handleBrowseFiles}
                variant="primary"
                tracking={tracking}
                loading={isUploading}
                disabled={!uploadEnabled || !type || isUploading}
                className="asset-upload__button"
              >
                Upload
              </ButtonElement>
              {linkUploadEnabled && (
                <>
                  <div className="vr" />
                  <ButtonElement handleEvent={handleToggleLinkUpload} variant="light" disabled={!type || isUploading}>
                    <IconLink size={20} />
                  </ButtonElement>
                </>
              )}
            </ButtonGroup>
            {overrideEnabled && (
              <ButtonGroup className="d-flex gap-2">
                <OverrideMenu path="asset:src" isButton />
                {aliasEnabled && <AliasMenu path="asset:src" isButton />}
              </ButtonGroup>
            )}
          </ButtonToolbar>
          <input
            ref={fileInput}
            type="file"
            accept={accepts || '*'}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          {helperText && <p className="helper-text centered">{helperText}</p>}
        </div>
      </div>
      {uploadErorr && <p className="input-error">{uploadErorr}</p>}
    </>
  );
}

export default AssetSourceInput;
