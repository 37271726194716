import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { useRecoilState, useRecoilValue } from 'recoil';

import WorkflowModuleName from '@feature/workflows/module/WorkflowModuleName';
import WorkflowModuleOutputs from '@feature/workflows/module/WorkflowModuleOutputs';
import WorkflowPanelHeader from '@feature/workflows/panel/WorkflowPanelHeader';

import {
  activeTaskAtom,
  taskInputsAtomFamily,
  taskOptionsAtomFamily,
  workflowDataListSelector,
} from '@store/Workflows';

import { SIZES_SELECT } from '@constants/TextToImage';
import { noOptionsMessage } from '@constants/Workflows';

function WorkflowModuleTtiShotstack() {
  const activeTask = useRecoilValue(activeTaskAtom);
  const [taskInputs, setTaskInputs] = useRecoilState(taskInputsAtomFamily(activeTask));
  const [taskOptions, setTaskOptions] = useRecoilState(taskOptionsAtomFamily(activeTask));
  const { list, grouped } = useRecoilValue(workflowDataListSelector);
  const [promptDefaultValue, setPromptDefaultValue] = useState(null);

  useEffect(() => {
    setPromptDefaultValue(getSelectedValue(list, taskInputs?.prompt));
  }, [list, taskInputs?.prompt]);

  useEffect(() => {
    setTaskOptions((prevOptions) => ({
      ...prevOptions,
      family: prevOptions.family || 'essential-v2',
      style: prevOptions.style || 'photorealism',
      aspectRatio: prevOptions.aspectRatio || '1:1',
    }));
  }, [setTaskOptions]);

  const setStateMap = {
    inputs: setTaskInputs,
    options: setTaskOptions,
  };

  const getSelectedValue = (data, key) => {
    return data.find(({ value }) => value === key) || null;
  };

  const handleChange = (field, type) => {
    const setState = setStateMap[type];
    return ({ value }) => {
      setState((prevState) => ({ ...prevState, [field]: value }));
    };
  };

  const stableDiffusionModels = [
    { value: 'stable-diffusion-xl-v1-0', label: 'Stable Diffusion XL (general)' },
    { value: 'animagine-xl-v3-1', label: 'Animagine v3.1 (anime)' },
    { value: 'realvis-xl-v4', label: 'RealVisXL V4.0 (photorealism)' },
    { value: 'juggernaut-xl-v10', label: 'JuggernautXL XL (photorealism)' },
    { value: 'reproduction-v3-31', label: 'Reproduction (anime)' },
    { value: 'real-cartoon-xl-v6', label: 'RealCartoonXL (style)' },
  ];

  return (
    <>
      <div className="form-container">
        <WorkflowModuleName />
      </div>
      <div className="form-container sm">
        <WorkflowPanelHeader
          name="Configuration"
          description="The configuration to generate an image using Shotstack text-to-image service."
        />
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Prompt</label>
          </Col>
          <Col>
            <ReactSelect
              unstyled
              className="react-select__inputs form-control"
              classNamePrefix="react-select"
              id="prompt"
              name="prompt"
              placeholder="Select input"
              noOptionsMessage={noOptionsMessage('data')}
              value={promptDefaultValue}
              options={grouped}
              onChange={handleChange('prompt', 'inputs')}
            />
          </Col>
        </Row>
        <hr />
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Model Family</label>
          </Col>
          <Col>
            <ReactSelect
              unstyled
              className="form-control"
              classNamePrefix="react-select"
              id="family"
              name="family"
              placeholder="Select model family"
              noOptionsMessage={noOptionsMessage('data')}
              value={getSelectedValue(
                [
                  { value: 'essential-v2', label: 'Essential V2' },
                  { value: 'stable-diffusion-xl', label: 'Stable Diffusion XL' },
                ],
                taskOptions?.family
              )}
              options={[
                { value: 'essential-v2', label: 'Essential V2' },
                { value: 'stable-diffusion-xl', label: 'Stable Diffusion XL' },
              ]}
              onChange={handleChange('family', 'options')}
            />
          </Col>
        </Row>
        {taskOptions?.family === 'stable-diffusion-xl' && (
          <>
            <Row className="d-flex align-items-center">
              <Col xs={4}>
                <label>Model</label>
              </Col>
              <Col>
                <ReactSelect
                  unstyled
                  className="form-control"
                  classNamePrefix="react-select"
                  id="model"
                  name="model"
                  placeholder="Select model"
                  noOptionsMessage={noOptionsMessage('data')}
                  defaultValue={getSelectedValue(stableDiffusionModels, taskOptions?.model)}
                  options={stableDiffusionModels}
                  onChange={handleChange('model', 'options')}
                />
              </Col>
            </Row>
            <Row className="d-flex align-items-center">
              <Col xs={4}>
                <label>Width</label>
              </Col>
              <Col>
                <ReactSelect
                  unstyled
                  className="form-control"
                  classNamePrefix="react-select"
                  id="width"
                  name="width"
                  placeholder="Select width"
                  noOptionsMessage={noOptionsMessage('data')}
                  defaultValue={getSelectedValue(SIZES_SELECT, taskOptions?.width)}
                  options={SIZES_SELECT}
                  onChange={handleChange('width', 'options')}
                />
              </Col>
            </Row>
            <Row className="d-flex align-items-center">
              <Col xs={4}>
                <label>Height</label>
              </Col>
              <Col>
                <ReactSelect
                  unstyled
                  className="form-control"
                  classNamePrefix="react-select"
                  id="height"
                  name="height"
                  placeholder="Select height"
                  noOptionsMessage={noOptionsMessage('data')}
                  defaultValue={getSelectedValue(SIZES_SELECT, taskOptions?.height)}
                  options={SIZES_SELECT}
                  onChange={handleChange('height', 'options')}
                />
              </Col>
            </Row>
          </>
        )}
        {taskOptions?.family === 'essential-v2' && (
          <>
            <Row className="d-flex align-items-center">
              <Col xs={4}>
                <label>Style</label>
              </Col>
              <Col>
                <ReactSelect
                  unstyled
                  className="form-control"
                  classNamePrefix="react-select"
                  id="style"
                  name="style"
                  placeholder="Select style"
                  noOptionsMessage={noOptionsMessage('data')}
                  value={getSelectedValue(
                    [
                      { value: 'photorealism', label: 'Photorealism' },
                      { value: 'anime', label: 'Anime' },
                      { value: 'art', label: 'Art' },
                    ],
                    taskOptions?.style
                  )}
                  options={[
                    { value: 'photorealism', label: 'Photorealism' },
                    { value: 'anime', label: 'Anime' },
                    { value: 'art', label: 'Art' },
                  ]}
                  onChange={handleChange('style', 'options')}
                />
              </Col>
            </Row>
            <Row className="d-flex align-items-center">
              <Col xs={4}>
                <label>Aspect Ratio</label>
              </Col>
              <Col>
                <ReactSelect
                  unstyled
                  className="form-control"
                  classNamePrefix="react-select"
                  id="aspectRatio"
                  name="aspectRatio"
                  placeholder="Select aspect ratio"
                  noOptionsMessage={noOptionsMessage('data')}
                  value={getSelectedValue(
                    [
                      { value: '1:1', label: '1:1' },
                      { value: '4:5', label: '4:5' },
                      { value: '5:4', label: '5:4' },
                      { value: '2:3', label: '2:3' },
                      { value: '3:2', label: '3:2' },
                      { value: '4:7', label: '4:7' },
                      { value: '7:4', label: '7:4' },
                    ],
                    taskOptions?.aspectRatio
                  )}
                  options={[
                    { value: '1:1', label: '1:1' },
                    { value: '4:5', label: '4:5' },
                    { value: '5:4', label: '5:4' },
                    { value: '2:3', label: '2:3' },
                    { value: '3:2', label: '3:2' },
                    { value: '4:7', label: '4:7' },
                    { value: '7:4', label: '7:4' },
                  ]}
                  onChange={handleChange('aspectRatio', 'options')}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
      <div className="form-container">
        <WorkflowModuleOutputs
          name="Output"
          description="This action generates an image file and outputs the file URL."
        />
      </div>
    </>
  );
}

export default WorkflowModuleTtiShotstack;
