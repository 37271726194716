const TRANSFORM_CHAR_LIMIT = 50;

const getTransformStyleValues = (css) => {
  if (!css || (css.length && css.length >= TRANSFORM_CHAR_LIMIT)) {
    return {};
  }
  const regex = /translate\(([^,]+),?\s*([^)]+)?\)/;
  const match = css.match(regex);

  if (!match) {
    return {};
  }
  const x = parseFloat(match[1].replace('px', ''));
  const y = match[2] ? parseFloat(match[2].replace('px', '')) : 0;
  return { x, y };
};

export default getTransformStyleValues;
