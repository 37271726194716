import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';

import '@css/WorkflowActions.css';
import '@css/Workflows.css';

import tile from '@assets/img/tile.png';

import WorkflowCanvas from '@feature/workflows/WorkflowCanvas';
import UpgradeModal from '@feature/workflows/modal/UpgradeModal';
import WorkflowEditorToolbar from '@feature/workflows/toolbar/WorkflowEditorToolbar';

import { useAnalytics } from '@components/analytics/Analytics';
import Loader from '@components/loader/Loader';

import { useResetWorkflows, workflowSelectorFamily } from '@store/Workflows';

import useDuplicateWorkflow from '@hooks/useDuplicateWorkflow';
import useWorkflowTemplates from '@hooks/useWorkflowTemplates';

function WorkflowEdit() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { trackEvent } = useAnalytics();
  const { state, contents } = useRecoilValueLoadable(workflowSelectorFamily(id));

  const resetWorkflows = useResetWorkflows();
  const builtInWorkflowTemplates = useWorkflowTemplates();

  const { handleDuplicate } = useDuplicateWorkflow({
    onSuccess: (id) => navigate(`/workflows/${id}/edit`),
  });

  const isLoading = state === 'loading' || !(contents && contents.id);

  useEffect(() => {
    if (!id || !Array.isArray(builtInWorkflowTemplates)) return;

    const builtInWorkflowTemplateId = builtInWorkflowTemplates.find((template) => template.id === id)?.id;
    if (!builtInWorkflowTemplateId) return;

    handleDuplicate(builtInWorkflowTemplateId);
  }, [id, builtInWorkflowTemplates]);

  useEffect(() => {
    trackEvent('View Workflow Editor', {
      Id: id,
    });

    return () => resetWorkflows(id);
  }, [id]);

  if (state === 'hasError') {
    console.error(contents);
    return (
      <div className="editor" style={{ backgroundImage: `url(${tile})` }}>
        <div className="editor__main">
          <p>There was an error loading this workflow.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="editor" style={{ backgroundImage: `url(${tile})` }}>
      <div className="editor__main">
        <WorkflowEditorToolbar />
        {isLoading ? <Loader /> : <WorkflowCanvas workflow={contents} />}
      </div>
      <UpgradeModal />
    </div>
  );
}

export default WorkflowEdit;
