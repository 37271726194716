import { useCallback } from 'react';

function useJsonProcessor({ onProcess }) {
  const processJsonFile = useCallback((file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const jsonData = JSON.parse(event.target.result);
        onProcess(jsonData);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    };
    reader.readAsText(file);
  }, []);

  return { processJsonFile };
}

export default useJsonProcessor;
