import { sampleSize } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { renderTemplate } from '@api/services/template';

import { stageAtom } from '@store/Auth';
import { onboardingRenderAtom } from '@store/Renders';
import { errorAtom } from '@store/UI';

import { greetingsList, useCaseVideos } from '@constants/Onboarding';

import { getRenderErrorMessage } from '@utils/errors/render';

import OnboardingTemplateJson from '@data/template/onboarding.json';

const getTemplate = ({ name, useCase }) => {
  const uppercaseName = (name || '!!!!!').toUpperCase();
  const greetings = sampleSize(greetingsList, 4);
  const videos = useCaseVideos[useCase];

  const updatedTemplate = {
    ...OnboardingTemplateJson,
    merge: [
      { find: 'NAME', replace: uppercaseName },
      { find: 'HELLO_1', replace: greetings[0] },
      { find: 'HELLO_2', replace: greetings[1] },
      { find: 'HELLO_3', replace: greetings[2] },
      { find: 'HELLO_4', replace: greetings[3] },
      { find: 'VIDEO_1', replace: videos[0] },
      { find: 'VIDEO_2', replace: videos[1] },
      { find: 'VIDEO_3', replace: videos[2] },
    ],
  };

  return JSON.stringify(updatedTemplate);
};

const useRenderOnboarding = () => {
  const stage = useRecoilValue(stageAtom);
  const setErrorMessage = useSetRecoilState(errorAtom);
  const setOnboardingRender = useSetRecoilState(onboardingRenderAtom);

  const submitRender = useCallback(
    async ({ name, useCase }) => {
      try {
        const template = getTemplate({ name, useCase });
        const render = await renderTemplate(template);

        if (!render?.id) {
          throw new Error('Render request failed');
        }

        setOnboardingRender({ id: render.id });
        return render.id;
      } catch (error) {
        console.error({ error });
        setOnboardingRender({ status: 'error', error: getRenderErrorMessage(error) });
        return undefined;
      }
    },
    [setErrorMessage, setOnboardingRender, stage]
  );

  return useMemo(() => ({ submitRender }), [submitRender]);
};

export default useRenderOnboarding;
