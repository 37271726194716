import getCleanUrl from '@utils/getCleanUrl';
import IndexedDBUtil from '@utils/indexedDBUtils';

const dbMediaCaptures = new IndexedDBUtil({ db: 'media', table: 'captures', version: '004' });
const dbTemplateMedia = new IndexedDBUtil({ db: 'templates', table: 'media', version: '001' });

type MediaStatus = 'failed-restore' | 'restored' | 'failed-store';

type MediaCaptureState = {
  loading: boolean;
  status: MediaStatus;
  data: any;
};

type MediaCaptureOnSetParam = {
  data: any; // TODO: What is this?
};

export const mediaCapturePersist = (param: string) => [
  ({
    setSelf,
    onSet,
  }: {
    setSelf: (state: MediaCaptureState) => void;
    onSet: (fn: (param: MediaCaptureOnSetParam) => void) => void;
  }) => {
    const key = getCleanUrl(param);

    async function loadData() {
      let status: MediaStatus = 'failed-restore';
      let data = null;
      if (key) {
        const savedValue = await dbMediaCaptures.getItem(key);
        status = (!savedValue ? 'failed-restore' : 'restored') as MediaStatus;
        data = savedValue ?? null;
      }
      setSelf({ loading: false, status, data });
    }

    loadData();

    onSet(async ({ data }) => {
      try {
        if (key) {
          await dbMediaCaptures.setItem(key, data);
        }
      } catch (error) {
        setSelf({ loading: false, status: 'failed-store', data: null });
      }
    });
  },
];

export const templateMediaPersist = (key: string) => [
  ({
    setSelf,
    onSet,
  }: {
    setSelf: (state: MediaCaptureState) => void;
    onSet: (fn: (param: MediaCaptureOnSetParam) => void) => void;
  }) => {
    async function loadData() {
      const savedValue = await dbTemplateMedia.getItem(key);
      const status = !savedValue ? 'failed-restore' : 'restored';
      const data = savedValue ?? null;
      setSelf({ loading: false, status, data });
    }

    loadData();

    onSet(async ({ data }) => {
      try {
        await dbTemplateMedia.setItem(key, data);
      } catch (error) {
        setSelf({ loading: false, status: 'failed-store', data: null });
      }
    });
  },
];
