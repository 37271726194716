import { useEffect, useRef, useState } from 'react';
import { Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import TemplateGallery from '@feature/studio/template/gallery/TemplateGallery';

import ButtonElement from '@components/atoms/ButtonElement';
import PrismSnippet from '@components/code/PrismSnippet';
import Layout from '@components/layout/Layout';

import useDuplicateTemplate from '@hooks/useDuplicateTemplate';

const tabs = [
  { eventKey: 'socialmedia', title: 'Social Media' },
  { eventKey: 'ecommerce', title: 'E-Commerce' },
  { eventKey: 'listings', title: 'Classifieds & Listings' },
  { eventKey: 'travel', title: 'Travel' },
  { eventKey: 'news', title: 'News' },
  { eventKey: 'other', title: 'Other' },
];

function TemplatesGallery() {
  const [selectedTemplate, setSelectedTemplate] = useState({ data: {} });
  const [activeTab, setActiveTab] = useState('socialmedia');
  const history = useNavigate();
  const { handleDuplicate, loading: duplicateTemplateLoading } = useDuplicateTemplate({
    onSuccess: ({ id }) => history(`/studio/editor/${id}`),
  });

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && selectedTemplate.media) {
      videoRef.current.src = selectedTemplate.media || '';
    }
  }, [selectedTemplate.media]);

  const handleTemplateClick = (template) => {
    setSelectedTemplate(template);
  };

  const handleDesignClick = () => {
    if (selectedTemplate.id) {
      handleDuplicate(selectedTemplate.id);
    }
  };

  const createTemplateTracking = {
    name: 'Select Create Template',
    properties: {
      template_id: selectedTemplate.id,
    },
  };

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Layout>
      <div className="template-gallery__main">
        <div className="template-gallery__topbar">
          <Card>
            <Card.Body style={{ overflow: 'hidden' }}>
              <Tabs
                className="template-gallery__tabs"
                variant="underline"
                activeKey={activeTab}
                onSelect={handleTabSelect}
              >
                {tabs.map(({ eventKey, title }) => (
                  <Tab eventKey={eventKey} title={title} key={eventKey} />
                ))}
              </Tabs>

              <TemplateGallery numTemplates={999} onClick={handleTemplateClick} category={activeTab} />
            </Card.Body>
          </Card>
        </div>

        <div className="d-flex justify-content-end mt-8 mb-8">
          <ButtonElement
            disabled={!selectedTemplate.id || duplicateTemplateLoading}
            handleEvent={handleDesignClick}
            style={{ minWidth: 100 }}
            tracking={createTemplateTracking}
          >
            {duplicateTemplateLoading ? 'Loading...' : 'Customise this template'}
          </ButtonElement>
        </div>

        <div className="template-gallery__content">
          {selectedTemplate.media && (
            <Card>
              <Card.Body>
                <Row>
                  <Col md={6}>
                    <PrismSnippet
                      text={JSON.stringify(selectedTemplate.data, null, 2)}
                      language="javascript"
                      className="mb-2 template-gallery"
                    />
                  </Col>
                  <Col md={6}>
                    <video ref={videoRef} style={{ maxHeight: '790px' }} controls poster={selectedTemplate.poster} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}

          <div className="d-flex justify-content-end mt-5">
            <ButtonElement
              disabled={!selectedTemplate.id || duplicateTemplateLoading}
              handleEvent={handleDesignClick}
              style={{ minWidth: 100 }}
              tracking={createTemplateTracking}
            >
              {duplicateTemplateLoading ? 'Loading...' : 'Customise this template'}
            </ButtonElement>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TemplatesGallery;
