import config from '@config';
import { Col, Row } from 'react-bootstrap';

import MuxForm from '@feature/integrations/providers/mux/MuxForm';

import ContentCard from '@components/cards/ContentCard';
import Header from '@components/header/Header';

function Mux() {
  const Forms = config.integration.stages.map((stage, index) => (
    <MuxForm key={stage.stage} stage={stage.stage} stageName={stage.name} index={index} />
  ));

  return (
    <>
      <Header title="Mux Video">Automatically send videos generated by Shotstack to the Mux video platform.</Header>
      <Row className="mb-4">
        <Col md={4}>
          <ContentCard image={`/img/provider/mux.png`}>
            Mux is the developer video platform that takes the complexity out of live and on-demand video. Thousands of
            publishers including Robinhood, PBS, Paramount, Equinox+, and VSCO rely on Mux to deliver the highest
            quality video experience without having to hire a team of embedded video experts.
          </ContentCard>
        </Col>
        <Col md={8}>
          <ContentCard heading="SETUP">
            <p>
              Sign up to <a href="https://dashboard.mux.com/signup?type=video">Mux</a> if you do not already have an
              account.{' '}
              <a href="https://docs.mux.com/guides/video/stream-video-files#1-get-an-api-access-token">Generate</a> or
              look up your credentials and enter them below.
            </p>
            <p>
              Mux allows you to create multiple environments including test environments. You should create a test
              environment and a production environment and match them to the Shotstack Development (Sandbox) and
              Production environments.
            </p>
          </ContentCard>
          <ContentCard heading="CREDENTIALS">{Forms}</ContentCard>
          <ContentCard heading="INTEGRATION">
            <h5>Basic setup</h5>
            <p>
              To send video files to Mux add the following to the output parameter of the render request JSON payload:
            </p>
            <pre>{`"destinations": [{
    "provider": "mux"
}]`}</pre>
            <p>
              This will send the video file to Mux for encoding and hosting. Note that images, previews and thumbnails
              will not be sent to Mux.
            </p>
            <h5>Mux options</h5>
            <p>
              The playbackPolicy option is currently supported which allows you to create a video that is signed
              (private), public, or both. The default is public.
            </p>
            <pre>{`"destinations": [{
    "provider": "mux",
    "options": {
        "playbackPolicy": [
          "signed"
        ]
    }
}]`}</pre>
            <p>
              This will send the video to Mux and make it private and require a{' '}
              <a href="https://docs.mux.com/guides/video/secure-video-playback">signed key</a> to access the video.
            </p>
            <h5>Opting out from the Shotstack destination</h5>
            <p>
              By default all generated files are sent to the Shotstack destination. If you are using Mux you may wish to
              opt out from hosting you videos with Shotstack.
            </p>
            <pre>{`"destinations": [{
    "provider": "mux"
}, {
    "provider": "shotstack",
    "exclude": true
}]`}</pre>
            <p>This will send a generated video to Mux but not to Shotstack.</p>
          </ContentCard>
        </Col>
      </Row>
    </>
  );
}

export default Mux;
