import cn from 'classnames';

import { IconError, IconInfo, IconWarning } from '@assets/icons';

const typeIconMap = {
  info: IconInfo,
  warning: IconWarning,
  error: IconError,
};

function AlertBox({ name, message, type = 'info', className }) {
  const Icon = typeIconMap[type];
  return (
    <div className={cn('alertbox', type, className)}>
      <div className="alertbox__icon">{Icon && <Icon size={24} />}</div>
      <div className="alertbox__content">
        {name && <h6>{name}</h6>}
        {message && <span className="text-sm d-block" dangerouslySetInnerHTML={{ __html: message }} />}
      </div>
    </div>
  );
}

export default AlertBox;
