import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { useRecoilState, useRecoilValue } from 'recoil';

import WorkflowModuleName from '@feature/workflows/module/WorkflowModuleName';
import WorkflowModuleOutputs from '@feature/workflows/module/WorkflowModuleOutputs';
import WorkflowPanelHeader from '@feature/workflows/panel/WorkflowPanelHeader';

import InputNumber from '@components/controls/InputNumber';

import {
  activeTaskAtom,
  taskInputsAtomFamily,
  taskOptionsAtomFamily,
  workflowDataListSelector,
} from '@store/Workflows';

import { FIT_SELECT, FORMAT_IMAGE_SELECT } from '@constants/MediaTransformation';
import { noOptionsMessage } from '@constants/Workflows';

import { isNumber } from '@utils/isNumber';

function WorkflowModuleTiShotstack() {
  const activeTask = useRecoilValue(activeTaskAtom);
  const [taskInputs, setTaskInputs] = useRecoilState(taskInputsAtomFamily(activeTask));
  const [taskOptions, setTaskOptions] = useRecoilState(taskOptionsAtomFamily(activeTask));
  const [fitValue, setFitValue] = useState(taskOptions?.fit || FIT_SELECT[0]);
  const { list, grouped } = useRecoilValue(workflowDataListSelector);

  const setTaskOption = (field, value) => {
    setTaskOptions((prevState) => ({ ...prevState, [field]: value }));
  };

  const getSelectedValue = (data, key) => {
    return data.find(({ value }) => value === key) || undefined;
  };

  const handleNumberOptionChange = (field) => (e) => {
    const { value } = e.target;
    const valueInt = parseInt(value, 10);
    const newValue = isNumber(valueInt) ? valueInt : '';
    setTaskOption(field, newValue);
  };

  const handleUrlChange = ({ value }) => {
    setTaskInputs((prevState) => ({ ...prevState, url: value }));
  };

  const handleSelectOptionsChange = (field) => {
    return ({ value }) => setTaskOption(field, value);
  };

  const handleFitChange = ({ value }) => {
    setTaskOption('fit', value);
    setFitValue(getSelectedValue(FIT_SELECT, value));
  };

  const isOptionDisabled = (option) => {
    return option.value === undefined && taskOptions.width && taskOptions.height;
  };

  useEffect(() => {
    if (!(taskOptions.width && taskOptions.height)) {
      setTaskOption('fit', undefined);
      setFitValue(FIT_SELECT[0]);
    }
    if (taskOptions?.fit === undefined && taskOptions.width && taskOptions.height) {
      setTaskOption('fit', 'crop');
      setFitValue(FIT_SELECT.find(({ value }) => value === 'crop'));
    }
  }, [taskOptions.width, taskOptions.height]);

  return (
    <>
      <div className="form-container">
        <WorkflowModuleName />
      </div>
      <div className="form-container sm">
        <WorkflowPanelHeader name="Configuration" description="Select the image transformations to apply." />
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>File URL</label>
          </Col>
          <Col>
            <ReactSelect
              unstyled
              className="react-select__inputs form-control"
              classNamePrefix="react-select"
              id="url"
              name="url"
              placeholder="Select input"
              noOptionsMessage={noOptionsMessage('data')}
              defaultValue={getSelectedValue(list, taskInputs?.url)}
              options={grouped}
              onChange={handleUrlChange}
            />
          </Col>
        </Row>
        <hr />
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Format</label>
          </Col>
          <Col>
            <ReactSelect
              unstyled
              className="form-control"
              classNamePrefix="react-select"
              id="format"
              name="format"
              placeholder="Maintain Format"
              noOptionsMessage={noOptionsMessage('data')}
              defaultValue={getSelectedValue(FORMAT_IMAGE_SELECT, taskOptions?.format)}
              options={FORMAT_IMAGE_SELECT}
              onChange={handleSelectOptionsChange('format')}
            />
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Width</label>
          </Col>
          <Col>
            <InputNumber
              id="width"
              name="width"
              min={16}
              max={4096}
              value={taskOptions.width}
              placeholder={taskOptions.height ? 'Auto' : 'Maintain Width'}
              handleChange={handleNumberOptionChange('width')}
            />
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Height</label>
          </Col>
          <Col>
            <InputNumber
              id="height"
              name="height"
              min={16}
              max={4096}
              value={taskOptions.height}
              placeholder={taskOptions.width ? 'Auto' : 'Maintain Height'}
              handleChange={handleNumberOptionChange('height')}
            />
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Fit</label>
          </Col>
          <Col>
            <ReactSelect
              unstyled
              className="form-control"
              classNamePrefix="react-select"
              id="fit"
              name="fit"
              noOptionsMessage={noOptionsMessage('data')}
              value={fitValue}
              options={FIT_SELECT}
              onChange={handleFitChange}
              isOptionDisabled={isOptionDisabled}
              isDisabled={!(taskOptions.width && taskOptions.height)}
            />
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Quality</label>
          </Col>
          <Col>
            <InputNumber
              id="quality"
              name="quality"
              min={1}
              max={100}
              value={taskOptions.quality}
              placeholder="Maintain Quality"
              handleChange={handleNumberOptionChange('quality')}
            />
          </Col>
        </Row>
      </div>
      <div className="form-container">
        <WorkflowModuleOutputs
          name="Output"
          description="his action generates an image file and outputs the file URL."
        />
      </div>
    </>
  );
}

export default WorkflowModuleTiShotstack;
