import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import LinkElement from '@components/atoms/LinkElement';
import InteractiveConsole from '@components/console/InteractiveConsole';

function OverviewEditApi() {
  const { product } = useParams();
  return (
    <>
      <Card className="p-3 mb-10">
        <Card.Body>
          <p className="uppercase-label">Edit Api</p>
          <h1>Programmatic Video Editing At Scale</h1>
          <p className="text-prose text-gray-500">
            Programmatically edit videos, at scale, using the Edit API. Build video applications and automate workflows
            using JSON and the coding language of your choice, or use one of our SDK&apos;s.
          </p>
          <LinkElement
            to="https://shotstack.io/docs/guide/architecting-an-application/guidelines/"
            variant="light"
            type="button"
            external
            className="mr-2"
          >
            Documentation
          </LinkElement>
          <LinkElement to="/studio/my-templates" variant="light" type="button">
            Create a Template
          </LinkElement>
          <InteractiveConsole product={product} />
        </Card.Body>
      </Card>
    </>
  );
}

export default OverviewEditApi;
