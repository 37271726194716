import { useState } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import WorkflowService from '@api/services/workflow';

import { errorAtom } from '@store/UI';

const useRunWorkflow = ({ onSuccess } = {}) => {
  const [loading, setLoading] = useState(false);
  const setErrorMessage = useSetRecoilState(errorAtom);
  const resetErrorMessage = useResetRecoilState(errorAtom);

  const handleRun = async (id, data) => {
    setLoading(true);
    try {
      await WorkflowService.jobs.run(id, data);
      if (onSuccess) {
        onSuccess(id);
      }
    } catch (error) {
      setErrorMessage({ message: error.message });
      setTimeout(() => {
        resetErrorMessage();
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  return { loading, handleRun };
};

export default useRunWorkflow;
