import { isNumber } from '@utils/isNumber';
import { replaceTextOverrideValues } from '@utils/template';

const CONTAINS_MERGE_FIELD_REGEX = /{{\s*\w+\s*}}/;

const isTextFieldProp = (property) => ['html', 'text', 'css'].includes(property);
export const hasMergeField = (value) => CONTAINS_MERGE_FIELD_REGEX.test(value);

const propertyTransformMap = {
  fontSize: (value) => {
    try {
      const fontSize = parseInt(value, 10);
      if (!isNumber(fontSize)) {
        return value;
      }
      return String(fontSize);
    } catch {
      return value;
    }
  },
  default: (value) => value,
};

export const formatMergeReplaceToAssetType = (assetFieldType, value) => {
  const isValueNumber = isNumber(value);
  const isValueString = typeof value === 'string';

  if (assetFieldType === 'text' && isValueNumber) {
    return value.toString();
  }

  if (assetFieldType === 'number' && isValueString) {
    const number = Number(value);
    return Number.isNaN(number) ? 0 : number;
  }

  return value;
};

export const formatMergeReplaceToImplicitType = (value, type) => {
  if (!value || !type) {
    return value;
  }
  // eslint-disable-next-line valid-typeof
  if (typeof value === type) {
    return value;
  }
  switch (type) {
    case 'boolean':
      return value.toLowerCase() === 'true';
    case 'number':
      return Number(value);
    case 'string':
      return String(value);
    default:
      return value;
  }
};

// find props in the data that have a merge field
// and return an object with the [property]: [mergeField.find]
const getOverridePropMap = (data) => {
  return Object.entries(data)
    .filter(([property, value]) => isTextFieldProp(property) || hasMergeField(value))
    .reduce((acc, [property, value]) => {
      acc[property] = {
        find: value.replace(/{{\s*([^}\s]+)\s*}}.*/, '$1'),
        valueRaw: value,
      };
      return acc;
    }, {});
};

const replaceOverrideValues = (overrides, replacements) => {
  return Object.entries(overrides).reduce(
    (acc, [property, { find, valueRaw }]) => {
      if (isTextFieldProp(property)) {
        acc[property] = replaceTextOverrideValues(valueRaw, replacements);
        acc.meta[property] = valueRaw;
      } else {
        const transform = propertyTransformMap[property] || propertyTransformMap.default;
        acc[property] = transform(replacements[find]);
      }
      return acc;
    },
    {
      meta: {},
    }
  );
};

export const getReplacedData = (data, replacements) => {
  const { meta } = data || {};
  if (meta?.unsupported) {
    return { overrides: {}, merged: data };
  }

  const overridePropMap = getOverridePropMap(data || {});
  const replaced = replaceOverrideValues(overridePropMap, replacements);
  const overrides = Object.entries(overridePropMap)
    .filter(([property]) => !isTextFieldProp(property))
    .reduce((acc, [property, { find }]) => {
      acc[property] = find;
      return acc;
    }, {});

  const merged = {
    ...data,
    ...replaced,
    meta: {
      ...(data?.meta || {}),
      ...(replaced?.meta || {}),
    },
  };

  return {
    overrides,
    merged,
  };
};
