import { useEffect, useState } from 'react';

import { controllerManager } from '@api/utils/AbortControllerManager';
import { pollDataManager } from '@api/utils/PollDataManager';

function useFetchAndPoll({ id: resourceIds, key, fetch }) {
  const [data, setData] = useState();

  const startPolling = async () => {
    try {
      const data = await fetch(resourceIds, { params: { polling: key } });
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    startPolling();

    return () => controllerManager.abortAll();
  }, []);

  useEffect(() => {
    const unsubscribe = pollDataManager.subscribe(key, (response) => {
      setData(response?.data?.body?.data);
    });

    return () => unsubscribe();
  }, [key]);

  return { data };
}

export default useFetchAndPoll;
