import { EMPTY_ACTION_STEP } from '@constants/Workflows';

const transformTrigger = ({ module, options, sampleData, mappings } = {}) => {
  const triggerTask = {
    type: 'trigger',
    module: module || '',
    options: options || {},
  };

  return { trigger: triggerTask, sampleData, mappings };
};

const transformOptionsResponseMap = {
  'mux:destination': ({ playbackPolicy, ...rest }) => {
    const options = {
      ...rest,
      policyPublic: playbackPolicy.includes('policy-public'),
      policySigned: playbackPolicy.includes('policy-signed'),
    };

    return options;
  },
  default: (options) => options,
};

const transformOptionsResponse = (module, options) => {
  const transformOptions = transformOptionsResponseMap[module] || transformOptionsResponseMap.default;
  return transformOptions(options);
};

const transformSteps = (steps = []) => {
  if (!steps.length) {
    return [EMPTY_ACTION_STEP];
  }

  return steps.map(({ module, options, mappings, ...restStep }) => {
    const [provider] = module.split(':');
    const { provider: optionsProvider, ...restOptions } = options || {};
    const { inputs, outputs } = mappings || {};

    const actionTask = {
      type: 'action',
      module,
      provider: optionsProvider || provider,
      inputs: inputs.reduce((acc, { key, mapsTo }) => ({ ...acc, [mapsTo]: key }), {}),
      outputs: outputs.reduce((acc, { key, mapsFrom }) => ({ ...acc, [mapsFrom]: key }), {}),
      options: transformOptionsResponse(module, restOptions),
      ...(restStep || {}),
    };

    return actionTask;
  });
};

export const transformConfiguration = (configuration) => {
  if (!configuration) return [];
  const { trigger, sampleData, mappings } = transformTrigger(configuration?.trigger);
  const actions = transformSteps(configuration?.steps);

  let sample = '';
  try {
    if (sampleData) {
      sample = JSON.stringify(JSON.parse(sampleData), null, 2);
    }
  } catch (e) {
    console.error('transformConfiguration', e);
  }

  return {
    tasks: [trigger, ...actions],
    data: {
      sample,
      mappings,
    },
  };
};

const transformOptionsRequestMap = {
  'shotstack:image-to-video': ({ guidanceScale, motion, ...rest }) => {
    const options = {
      guidanceScale: guidanceScale || undefined,
      motion: motion || undefined,
      ...rest,
    };

    return options;
  },
  's3:destination': ({ prefix, acl, ...rest }) => {
    const options = {
      ...rest,
      prefix: prefix || undefined,
      acl: acl || undefined,
    };

    return options;
  },
  'google-cloud-storage:destination': ({ prefix, ...rest }) => {
    const options = {
      ...rest,
      prefix: prefix || undefined,
    };

    return options;
  },
  'google-drive:destination': ({ filename, folderId, ...rest }) => {
    const options = {
      ...rest,
      filename: filename || undefined,
      folderId: folderId || undefined,
    };

    return options;
  },
  'vimeo:destination': ({ name, description, view, embed, comments, ...rest }) => {
    const options = {
      ...rest,
      name: name || undefined,
      description: description || undefined,
      privacy: {
        view: view || undefined,
        embed: embed || undefined,
        comments: comments || undefined,
      },
    };

    return options;
  },
  'mux:destination': ({ policyPublic, policySigned, ...rest }) => {
    const playbackPolicy = [
      ['policy-public', policyPublic],
      ['policy-signed', policySigned],
    ]
      .map(([key, value]) => {
        return value ? key : undefined;
      })
      .filter(Boolean);

    const options = {
      ...rest,
      playbackPolicy,
    };

    return options;
  },
  'shotstack:video-transform': ({ width, height, quality, ...rest }) => {
    const options = {
      ...rest,
      width: width || undefined,
      height: height || undefined,
      quality: quality || undefined,
    };

    return options;
  },
  'shotstack:image-transform': ({ width, height, quality, ...rest }) => {
    const options = {
      ...rest,
      width: width || undefined,
      height: height || undefined,
      quality: quality || undefined,
    };

    return options;
  },
  default: (options) => options,
};

export const transformOptionsRequest = (module, options) => {
  const transformOptions = transformOptionsRequestMap[module] || transformOptionsRequestMap.default;
  return transformOptions(options);
};
