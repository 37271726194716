import { Col, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';

import WorkflowModuleName from '@feature/workflows/module/WorkflowModuleName';
import WorkflowModuleOutputs from '@feature/workflows/module/WorkflowModuleOutputs';
import WorkflowPanelHeader from '@feature/workflows/panel/WorkflowPanelHeader';

import {
  activeTaskAtom,
  taskInputsAtomFamily,
  taskRenderTemplateListSelectorFamily,
  useSelectRenderTemplate,
  workflowDataListSelector,
} from '@store/Workflows';

import { noOptionsMessage } from '@constants/Workflows';

function RenderShotstackMappings() {
  const activeTask = useRecoilValue(activeTaskAtom);
  const [taskInputs, setTaskInputs] = useRecoilState(taskInputsAtomFamily(activeTask));
  const { list, grouped } = useRecoilValue(workflowDataListSelector);

  const handleMappingParamChange = (name) => {
    return ({ value }) => setTaskInputs((prevState) => ({ ...prevState, [name]: value }));
  };

  if (!Object.keys(taskInputs || {}).length) {
    return <div className="text-center ">No merge fields found in this template.</div>;
  }

  return (
    <>
      <WorkflowPanelHeader name="Merge Fields" description="The data to populate each merge field." />
      <div>
        {Object.entries(taskInputs).map(([param, value]) => {
          const defaultValue = list?.find((option) => option.value === value);
          return (
            <Row key={param} className="mb-2 d-flex align-items-center">
              <Col xs={4}>
                <label htmlFor={param}>{param}</label>
              </Col>
              <Col>
                <ReactSelect
                  unstyled
                  className="react-select__inputs form-control"
                  classNamePrefix="react-select"
                  key={param}
                  id={param}
                  name={param}
                  placeholder="Select input"
                  defaultValue={defaultValue}
                  noOptionsMessage={noOptionsMessage('input')}
                  options={grouped}
                  onChange={handleMappingParamChange(param)}
                />
              </Col>
            </Row>
          );
        })}
      </div>
    </>
  );
}

function WorkflowModuleRenderShotstack() {
  const activeTask = useRecoilValue(activeTaskAtom);
  const templateList = useRecoilValueLoadable(taskRenderTemplateListSelectorFamily(activeTask));
  const selectRenderTemplate = useSelectRenderTemplate();

  return (
    <>
      <div className="form-container">
        <WorkflowModuleName />
      </div>
      <div className="form-container">
        <WorkflowPanelHeader name="Template" description="The template to render including merge fields to populate." />
        <div>
          <ReactSelect
            unstyled
            className="form-control"
            classNamePrefix="react-select"
            id="select-template"
            name="select-template"
            placeholder="Select template"
            noOptionsMessage={noOptionsMessage('template')}
            loadingMessage={() => 'Loading templates...'}
            isLoading={templateList.state === 'loading'}
            value={templateList.contents?.selected}
            options={templateList.contents?.options}
            onChange={({ value }) => selectRenderTemplate(value)}
          />
          <div className="d-flex justify-content-between mt-2">
            <small>
              {templateList.contents?.selected?.value && (
                <a href={`/studio/editor/${templateList.contents?.selected?.value}`} target="_blank" rel="noreferrer">
                  Edit template
                </a>
              )}
            </small>
            <small>
              {templateList.contents?.selected?.value && (
                <>
                  {/* Todo - fix redirect. <a href={`/studio/editor/${templateList.contents?.selected?.value}`} target="_blank" rel="noreferrer">
                  Edit template
                </a> */}
                  {/* <span className="ms-2 me-2">·</span> */}
                </>
              )}
              <a href="/studio" target="_blank" rel="noreferrer">
                Template Library
              </a>
            </small>
          </div>
        </div>
        {templateList.contents?.selected && <RenderShotstackMappings />}
      </div>
      <div className="form-container">
        <WorkflowModuleOutputs
          name="Output"
          description="This action generates a video or image file and outputs the file URL."
        />
      </div>
    </>
  );
}

export default WorkflowModuleRenderShotstack;
