import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import WorkflowModuleName from '@feature/workflows/module/WorkflowModuleName';
import WorkflowModuleOutputs from '@feature/workflows/module/WorkflowModuleOutputs';
import WorkflowPanelHeader from '@feature/workflows/panel/WorkflowPanelHeader';

import InputText from '@components/controls/InputText';

import {
  activeTaskAtom,
  taskInputsAtomFamily,
  taskOptionsAtomFamily,
  workflowDataListSelector,
} from '@store/Workflows';

import { CONTENT_TYPES, METHODS } from '@constants/HttpModule';
import { noOptionsMessage } from '@constants/Workflows';

function WorkflowModuleHttp() {
  const activeTask = useRecoilValue(activeTaskAtom);
  const setTaskInputs = useSetRecoilState(taskInputsAtomFamily(activeTask));
  const [taskOptions, setTaskOptions] = useRecoilState(taskOptionsAtomFamily(activeTask));
  const { list } = useRecoilValue(workflowDataListSelector);

  const setStateMap = {
    inputs: setTaskInputs,
    options: setTaskOptions,
  };

  const getSelectedValue = (data, key) => {
    return data.find(({ value }) => value === key) || null;
  };

  const handleChange = (field, type) => {
    const setState = setStateMap[type];
    return (e) => {
      if (!e) {
        setState((prevState) => ({ ...prevState, [field]: undefined }));
        return;
      }

      const { value } = e.target || e;
      setState((prevState) => ({ ...prevState, [field]: value }));
    };
  };

  useEffect(() => {
    const payload = list.reduce((acc, item) => {
      const { label, value } = item;
      acc[label] = value;
      return acc;
    }, {});
    setTaskInputs(() => ({ ...payload }));
  }, [list]);

  return (
    <>
      <div className="form-container">
        <WorkflowModuleName />
      </div>
      <div className="form-container sm">
        <WorkflowPanelHeader name="Configuration" description="Select the video transformations to apply." />
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>URL</label>
          </Col>
          <Col>
            <InputText
              key="url"
              id="url"
              name="URL"
              defaultValue={taskOptions['url'] || 'https://api.shotstaack.io'}
              placeholder={'https://api.shotstack.io'}
              handleChange={handleChange('url', 'options')}
            />
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Method</label>
          </Col>
          <Col>
            <ReactSelect
              unstyled
              className="form-control"
              classNamePrefix="react-select"
              id="method"
              name="method"
              placeholder="POST"
              defaultValue={getSelectedValue(METHODS, taskOptions?.method)}
              options={METHODS}
              onChange={handleChange('method', 'options')}
            />
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Content-Type</label>
          </Col>
          <Col>
            <ReactSelect
              unstyled
              className="form-control"
              classNamePrefix="react-select"
              id="contentType"
              name="contentType"
              placeholder="application/json"
              noOptionsMessage={noOptionsMessage('data')}
              defaultValue={getSelectedValue(CONTENT_TYPES, taskOptions?.contentType)}
              options={CONTENT_TYPES}
              onChange={handleChange('contentType', 'options')}
            />
          </Col>
        </Row>
      </div>
      <div className="form-container">
        <WorkflowModuleOutputs
          name="Output"
          description="This action returns the JSON data in the response of the request, if available."
        />
      </div>
    </>
  );
}

export default WorkflowModuleHttp;
