import { useCallback, useState } from 'react';
import reactable from 'reactablejs';

import '@css/TimelinePlayheadLine.css';

import WorkspaceTimeline from '@feature/studio/workspace/WorkspaceTimeline';

import { TIMELINE_HEIGHT } from '@constants/Timeline';

const resizableOptions = {
  edges: { left: false, right: false, bottom: false, top: '.timeline__resize-handle' },
  inertia: false,
};

const ReactableTimelineComponent = reactable(WorkspaceTimeline);

function WorkspaceTimelineReactable({ templateLoading }) {
  const [height, setHeight] = useState(TIMELINE_HEIGHT);

  const onResizeMove = useCallback((event) => {
    const { delta } = event;
    setHeight((prevHeight) => prevHeight - delta.y);
  }, []);

  const onDoubleTap = () => {
    setHeight(TIMELINE_HEIGHT);
  };

  return (
    <ReactableTimelineComponent
      resizable={resizableOptions}
      templateLoading={templateLoading}
      onResizeMove={onResizeMove}
      onDoubleTap={onDoubleTap}
      height={height}
    />
  );
}

export default WorkspaceTimelineReactable;
