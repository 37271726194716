function Header({ title, children, prepend }) {
  return (
    <section className="header">
      {prepend ? <div>{prepend}</div> : null}
      <h1>{title}</h1>
      {children ? <div>{children}</div> : null}
    </section>
  );
}

export default Header;
