import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useIntercom } from 'react-use-intercom';
import { useRecoilState } from 'recoil';

import { IconQuestionCircle, IconWebhook } from '@assets/icons';

import InputText from '@components/controls/InputText';

import { callbackAtom } from '@store/Edit';
import { useSaveTemplate } from '@store/Template';

function SettingWebhook() {
  const [callback, setCallback] = useRecoilState(callbackAtom);
  const [isInvalid, setIsInvalid] = useState(false); // Initialize isInvalid state

  const saveTemplate = useSaveTemplate();

  const { showArticle } = useIntercom();

  const isValidURL = (string) => {
    if (string.trim() === '') {
      return true;
    }
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  };

  const handleWebhookChange = (event) => {
    const { value } = event.target;
    setCallback({ src: value });
    setIsInvalid(!isValidURL(value));
  };

  const handleOnBlur = () => {
    if (!isInvalid) {
      saveTemplate();
    }
  };

  return (
    <div className="mb-3 w-100">
      <div className="mb-2">
        <p className="uppercase-label">Webhook</p>
      </div>
      <div className="d-flex">
        <div className="flex-grow-1 me-2">
          <InputText
            id="webhook"
            title="Callback URL"
            value={callback?.src || ''}
            placeholder="https://webhook.site/c3543d8c-beca-4707-a1c2-8a84545cdea0"
            prefix={<IconWebhook size={18} />}
            handleChange={handleWebhookChange}
            handleBlur={handleOnBlur}
            isInvalid={isInvalid}
          />
        </div>

        <Button variant="light" onClick={() => showArticle(9890694)}>
          <IconQuestionCircle />
        </Button>
      </div>
    </div>
  );
}

export default SettingWebhook;
