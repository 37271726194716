import { useRecoilValue } from 'recoil';

import InputCheckbox from '@components/controls/InputCheckbox';

import { templateIdAtom } from '@store/Template';

function Shotstack({ destination, setDestination }) {
  const templateId = useRecoilValue(templateIdAtom);

  const tracking = {
    name: 'Check Exclude',
    properties: {
      Id: templateId,
    },
  };

  const handleExcludeChange = (event) => {
    const { checked: exclude } = event.target;
    setDestination((prevState) => ({ ...prevState, options: { ...prevState.options, exclude } }));
  };

  return (
    <div className="d-flex flex-column gap-2">
      <p className="uppercase-label">Options</p>
      <InputCheckbox
        label="Opt out of Shotstack hosting"
        checked={destination?.options?.exclude}
        handleChange={handleExcludeChange}
        tracking={tracking}
      />
    </div>
  );
}

export default Shotstack;
