/**
 * @typedef {import('@animation/animate').AnimationProperties} AnimationProperties
 */

/**
 * @template T
 * @typedef {import('@animation/keyframe').Keyframe<T>} Keyframe<T>
 */

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ scale: number }>}
 */
export const zoomInMotionKeyframe = (start, end, properties) => {
  const { speed, clip, canvas } = properties;

  let zoomTo = 0.3;
  if (speed === 'slow') zoomTo = 0.1;
  if (speed === 'fast') zoomTo = 0.7;

  // Convert pixel values to -1/1 range
  const scaleFrom = 1;
  const scaleTo = 1 + (2 * zoomTo * canvas.width) / canvas.width;

  return {
    from: {
      scale: ['html', 'text'].includes(clip['asset:type']) ? scaleFrom : scaleFrom * (clip?.scale || 1),
    },
    to: {
      scale: ['html', 'text'].includes(clip['asset:type']) ? scaleTo : scaleTo * (clip?.scale || 1),
    },
    start,
    end,
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ scale: number }>}
 */
export const zoomOutMotionKeyframe = (start, end, properties) => {
  const { speed, clip, canvas } = properties;

  let zoomTo = 0.3;
  if (speed === 'slow') zoomTo = 0.1;
  if (speed === 'fast') zoomTo = 0.7;

  // Convert pixel values to -1/1 range
  const scaleFrom = 1 + (2 * zoomTo * canvas.width) / canvas.width;
  const scaleTo = 1;

  return {
    from: {
      scale: ['html', 'text'].includes(clip['asset:type']) ? scaleFrom : scaleFrom * (clip?.scale || 1),
    },
    to: {
      scale: ['html', 'text'].includes(clip['asset:type']) ? scaleTo : scaleTo * (clip?.scale || 1),
    },
    start,
    end,
  };
};
