import { Container } from '@inlet/react-pixi';
import { ImageResource } from 'pixi.js';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import CanvasImageToVideoPlayer from '@feature/studio/canvas/CanvasImageToVideoPlayer';

import { overridesAtomFamily } from '@store/Edit';
import { clipCanvasSelectorFamily, clipErrorsSelectorFamily } from '@store/EditSelectors';

function CanvasImageToVideo({ id, index }) {
  const clip = useRecoilValue(clipCanvasSelectorFamily(id));
  const { ['asset:src']: src, ['asset:meta']: meta } = clip;
  const assetOverrides = useRecoilValue(overridesAtomFamily(id));
  const setClipErrors = useSetRecoilState(clipErrorsSelectorFamily(id));
  const [resourceLoadError, setResourceLoadError] = useState(null);
  const [resource, setResource] = useState(null);

  const formatPromptForUrl = useCallback((text) => {
    if (text) {
      return text.replace(/\s+/g, '+');
    }
    return '';
  }, []);

  const fixedPreviewImageSrc = `https://placehold.co/${meta?.width}x${meta?.height}?text=${formatPromptForUrl(meta?.text)}`;

  const loadImage = useCallback(async () => {
    const imageSrc = meta.placeholder ? fixedPreviewImageSrc : src;
    if (!imageSrc) {
      setResource(null);
      return;
    }

    setResourceLoadError(false);

    try {
      const loadedResource = await new ImageResource(imageSrc).load();
      if (!loadedResource) throw new Error(`Failed to load image. ${imageSrc}`);

      setResource(loadedResource);
    } catch (error) {
      console.error(error);
      setResourceLoadError(true);
      setResource(null);
    }
  }, [src, meta.placeholder, fixedPreviewImageSrc]);

  useEffect(() => {
    loadImage();
  }, [loadImage]);

  useEffect(() => {
    const errors = [];

    if (resourceLoadError) {
      errors.push({
        intercomErrorKey: 'assetPreview',
        title: 'Preview Unavailable',
        detail: `Asset source is inaccessible.${
          assetOverrides.src ? ` The merge field ${assetOverrides.src} cannot be displayed.` : ''
        }`,
      });
    }

    setClipErrors(errors);
  }, [resourceLoadError, assetOverrides.src, setClipErrors]);

  if (!resource) {
    return <Container />;
  }

  return <CanvasImageToVideoPlayer id={id} index={index} resource={resource} />;
}

export default CanvasImageToVideo;
