import config from '@config';
import { Col, Row } from 'react-bootstrap';

import HeyGenForm from '@feature/integrations/providers/heygen/HeyGenForm';

import AlphaBadge from '@components/badge/alpha';
import ContentCard from '@components/cards/ContentCard';
import Header from '@components/header/Header';

function HeyGen() {
  const Forms = config.integration.stages.map((stage, index) => (
    <HeyGenForm key={stage.stage} stage={stage.stage} stageName={stage.name} index={index} />
  ));

  return (
    <div className="integration">
      <Header title="HeyGen Text-to-Speech">
        HeyGen is an AI video generation tool that allows users to create engaging business videos without the need for
        video editing skills.
      </Header>
      <Row className="mb-4">
        <Col md={4}>
          <ContentCard image={`/img/provider/heygen.png`}>
            <AlphaBadge />
            HeyGen allows you to use a text prompt to create speaking avatars with their text-to-avatar technology.
            Integrate your HeyGen account to add avatars to your videos or applications.
            <br /> <br />
            *Note that this integration is currently on alpha and is subject to change.
          </ContentCard>
        </Col>
        <Col md={8}>
          <ContentCard heading="SETUP">
            <p>
              Sign up to <a href="app.heygen.com/login">HeyGen</a> if you do not already have an account. Copy your API
              Key and paste it in the form below.
            </p>
          </ContentCard>
          <ContentCard heading="CREDENTIALS">{Forms}</ContentCard>
          <ContentCard heading="INTEGRATION">
            <p>
              Select a <a href="https://shotstack.io/docs/api/#tocs_heygentexttoavataroptions">voice</a> and send the
              following JSON payload in the body of the request to generate an asset:
            </p>
            <pre>
              <b>POST</b> https://api.shotstack.io/create/&#123;ENV&#125;/assets
            </pre>
            <p>
              Replace <code>&#123;ENV&#125;</code> with either stage or v1 in the URL above depending on the environment
              and API key you are using.
            </p>
            <p>Send the following JSON payload in the body of the request to generate an asset:</p>
            <pre>{`{
    "provider": "heygen",
    "options": {
        "type": "text-to-avatar",
        "text": "This is a text to avatar asset generated by HeyGen",
        "avatar": "Angela",
        "voice": "Abbi - Natural",
        "avatarStyle": "normal",
        "background": "#ffffff",
        "ratio": "16:9",
        "test": true
    }
}

`}</pre>
            <p>
              Check the <a href="https://shotstack.io/docs/api/#tocs_heygengeneratedasset">API docs</a> for more
              information.
            </p>
          </ContentCard>
        </Col>
      </Row>
    </div>
  );
}

export default HeyGen;
