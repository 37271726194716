import dayjs from 'dayjs';
import { Badge, Card, Col, Row, Stack } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import '@css/CardData.css';

import ButtonElement from '@components/atoms/ButtonElement';

/** @deprecated Old way to calculate from & to before the balance API */
function LegacyCreditsData({ credits, subscription, loaded }) {
  const location = useLocation();
  const history = useNavigate();
  const creditsResetDate = dayjs().add(1, 'months').startOf('month').format('D MMM.');
  const showManageSubscriptionButton = location.pathname !== '/subscription';

  const handleRoute = (path) => {
    history(path);
  };

  if (!subscription && !loaded) {
    return null;
  }

  return (
    <Card className="card-data">
      <Card.Body>
        <Row>
          <Col xs={12} lg={3}>
            <div className="mb-2">
              <p className="uppercase-label">CURRENT PLAN</p>
              <div className="text-lg font-bold d-flex align-items-center gap-2">
                <span className="whitespace-nowrap">{subscription?.name}</span>
              </div>
              <div>
                <Badge bg="warning" className="text-xs" style={{ position: 'relative', top: '-3px' }}>
                  Legacy Plan
                </Badge>
              </div>
            </div>
          </Col>
          {subscription?.code !== 'payg' && (
            <>
              <Col>
                <p className="uppercase-label">CREDITS REMAINING</p>
                <p className="text-lg font-bold">
                  {subscription?.credits !== undefined && credits !== undefined
                    ? (subscription?.credits - credits).toFixed(1)
                    : '—'}
                </p>
              </Col>
              <Col>
                <p className="uppercase-label">CREDITS RESET</p>
                <p className="text-lg font-bold">{creditsResetDate ? creditsResetDate.toUpperCase() : '—'}</p>
              </Col>
            </>
          )}
          <Col xs={12} lg={3}>
            <Stack direction="horizontal" gap={3} className="mt-2">
              {showManageSubscriptionButton && (
                <ButtonElement
                  handleEvent={() => handleRoute('/subscription')}
                  variant="light"
                  tracking={{ name: 'Select Manage Subscription' }}
                  className="w-full mb-2"
                >
                  Manage Subscription
                </ButtonElement>
              )}
              <ButtonElement
                handleEvent={() => handleRoute('/usage')}
                variant={showManageSubscriptionButton ? 'link' : 'light'}
                tracking={{ name: 'Select View Usage' }}
                className="w-full mb-2"
              >
                View Usage
              </ButtonElement>
            </Stack>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default LegacyCreditsData;
