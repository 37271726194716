import { Badge, Spinner } from 'react-bootstrap';

const statusMap = {
  queued: { bg: 'light', color: 'dark', text: 'QUEUED', spinner: true },
  default: { bg: 'light', color: 'dark', text: '—' },
  expired: { bg: 'light', color: 'warning', text: 'EXPIRED' },
  deleted: { bg: 'light', color: 'danger', text: 'DELETED' },
  failed: { bg: 'light', color: 'danger', text: 'FAILED' },
  overwritten: { bg: 'light', color: 'warning', text: 'OVERWRITTEN' },
  rendering: { bg: 'light', color: 'primary', text: 'RENDERING', spinner: true },
  processing: { bg: 'light', color: 'primary', text: 'PROCESSING', spinner: true },
  fetching: { bg: 'light', color: 'primary', text: 'FETCHING', spinner: true },
  saving: { bg: 'light', color: 'primary', text: 'SAVING', spinner: true },
  sending: { bg: 'light', color: 'primary', text: 'SENDING', spinner: true },
  importing: { bg: 'light', color: 'primary', text: 'IMPORTING', spinner: true },
  waiting: { bg: 'light', color: 'primary', text: 'WAITING', spinner: true },
  pending: { bg: 'light', color: 'primary', text: 'PENDING', spinner: true },
  loading: { bg: 'light', color: 'primary', text: 'LOADING', spinner: true },
  ready: { bg: 'light', color: 'success', text: 'READY' },
  done: { bg: 'light', color: 'success', text: 'DONE' },
  received: { bg: 'light', color: 'success', text: 'RECEIVED' },
};

const StatusBadge = ({ status }) => {
  const { bg, color, text, spinner } = statusMap[status] || statusMap.default;
  return (
    <Badge bg={bg} text={color}>
      {spinner && <Spinner animation="border" variant="secondary" size="sm" className="me-2" />}
      <span>{text}</span>
    </Badge>
  );
};

export default StatusBadge;
