import { memo } from 'react';

import { IconMagicWand, IconVideo } from '@assets/icons';

import TimelineClipInfo from '@feature/studio/timeline/TimelineClipInfo';

function TimelineClipImageToVideo({ id }) {
  return (
    <div className="clip__inner">
      <div className="clip__header">
        <span className="clip__icon">
          <IconMagicWand size={14} />
        </span>
        <IconVideo size={14} />
        <TimelineClipInfo id={id} />
      </div>
    </div>
  );
}

export default memo(TimelineClipImageToVideo);
