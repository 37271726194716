import { useRecoilState } from 'recoil';

import { dismissibleSelector } from '@store/UI';

type DismissibleProps = {
  id: string;
  children: React.ReactNode;
}

const Dismissible: React.FC<DismissibleProps> = ({ id, children }) => {
  const [isDismissed, setDismissed] = useRecoilState(dismissibleSelector(id));

  return (
    <div className={`dismissible mt-4 ${isDismissed ? 'dismissed' : ''}`}>
      <div className="flex justify-end">
        <button className="dismissible__toggle unstyled" onClick={() => setDismissed(!isDismissed)}>
          <small>{isDismissed ? 'Help' : 'Close'}</small>
        </button>
      </div>
      {!isDismissed && children}
    </div>
  );
};

export default Dismissible; 