const getAspectRatioResolutionSize = (width, height, aspectRatio) => {
  switch (aspectRatio) {
    case '9:16':
      return [height, width];
    case '1:1':
      return [height, height];
    case '4:5':
      return [height, parseInt(height * 1.25, 10)];
    case '4:3':
      return [height, parseInt(height * 0.75, 10)];
    default:
      return [width, height];
  }
};

export default getAspectRatioResolutionSize;
