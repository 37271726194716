import { Pagination } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ResourceListItemGenerated from '@components/resources/ResourceListItemGenerated';
import ResourceListItemRenders from '@components/resources/ResourceListItemRenders';
import ResourceListItemServe from '@components/resources/ResourceListItemServe';
import ResourceListItemSources from '@components/resources/ResourceListItemSources';
import Skeleton from '@components/skeletons/Skeleton';

const listItemComponents = {
  'edit-api': ResourceListItemRenders,
  'ingest-api': ResourceListItemSources,
  'create-api': ResourceListItemGenerated,
  'serve-api': ResourceListItemServe,
  default: () => null,
};

const convertLinkToRange = (link) => {
  try {
    const url = new URL(link);
    const linkParams = new URLSearchParams(url.search);
    const newLinkParams = new URLSearchParams({
      startDateRange: linkParams.get('filter[created][since]'),
      endDateRange: linkParams.get('filter[created][until]'),
      status: linkParams.get('status') || '',
      limit: linkParams.get('page[limit]'),
      offset: linkParams.get('page[offset]'),
    });
    return newLinkParams;
  } catch (error) {
    return null;
  }
};

function ResourceHistory({ data, loading, product }) {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const { assets, links, meta } = data;
  const resourceBasePath = `/apis/${product}/assets`;
  const ListItemComponent = listItemComponents[product] || listItemComponents.default;

  if (loading) {
    return <Skeleton length={5} />;
  }

  if ((!loading && assets?.length === 0) || !assets) {
    return <div className="text-center">No items found</div>;
  }

  const { next, prev } = links || {};
  const ranges = {
    next: convertLinkToRange(next),
    prev: convertLinkToRange(prev),
  };

  const handlePaginationClick = (range) => {
    setSearchParams(range, { replace: true });
  };

  const handleItemClick = (id) => {
    navigate(`${resourceBasePath}/${id}`);
  };

  return (
    <div>
      {(assets || []).map((item) => (
        <ListItemComponent key={item.id} item={item} onItemClick={handleItemClick} />
      ))}
      <div className="d-flex items-center justify-content-center my-4">
        <Pagination>
          {['Prev', 'Next'].map((type) => {
            const PaginationComponent = Pagination[type];
            const range = ranges[type.toLowerCase()];
            const disabled = !range || meta?.totalRecords <= 30;
            return (
              <PaginationComponent key={type} onClick={() => handlePaginationClick(range)} disabled={disabled}>
                {type}
              </PaginationComponent>
            );
          })}
        </Pagination>
      </div>
    </div>
  );
}

export default ResourceHistory;
