import { useRecoilState } from 'recoil';

import { IconCompress } from '@assets/icons';

import InputSelect from '@components/controls/InputSelect';

import { qualityAtom } from '@store/Output';

import Compression from '@constants/Compression';

const DEFAULT_QUALITY = 'medium';

function QualitySelect({}) {
  const [quality, setQuality] = useRecoilState(qualityAtom);

  const handleChange = (event) => {
    const { value } = event.target;
    setQuality(value.length > 0 ? value : DEFAULT_QUALITY);
  };

  return (
    <InputSelect
      id="format"
      title="Compression"
      selected={quality || DEFAULT_QUALITY}
      values={Compression}
      prefix={<IconCompress size={18} />}
      handleChange={handleChange}
    />
  );
}

export default QualitySelect;
