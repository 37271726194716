import InputSelect from '@components/controls/InputSelect';
import InputText from '@components/controls/InputText';

import useDestinationFormValidation from '@hooks/useDestinationFormValidation';

const aclOptions = [
  { value: 'private', text: 'Private' },
  { value: 'public-read', text: 'Public Read' },
];

const s3Options = [
  { label: 'region', type: 'text', placeholder: 'us-east-1', required: true },
  { label: 'bucket', type: 'text', placeholder: 'my-bucket', required: true },
  { label: 'prefix', type: 'text', placeholder: 'my-renders', required: false },
  { label: 'filename', type: 'text', placeholder: 'my-file', required: false },
  { label: 'acl', type: 'select', options: aclOptions, placeholder: 'public-read', required: false },
];

function S3({ destination, setDestination, onValidationChange }) {
  const { invalidFields, handleOptionsChange } = useDestinationFormValidation(
    s3Options,
    destination,
    setDestination,
    onValidationChange
  );

  return (
    <div className="d-flex flex-column gap-2">
      {s3Options.map(({ label, placeholder, type, options }) =>
        type === 'select' ? (
          <>
            <label htmlFor={label} className={`form-label ${invalidFields.includes(label) ? 'text-danger' : ''}`}>
              {label}
            </label>
            <InputSelect
              key={label}
              name={label}
              id={label}
              selected={destination?.options?.[label]}
              placeholder={''}
              title={label}
              values={options}
              handleChange={handleOptionsChange}
              isInvalid={invalidFields.includes(label)}
            />
          </>
        ) : (
          <InputText
            key={label}
            name={label}
            label={label}
            placeholder={placeholder}
            value={destination?.options?.[label]}
            handleChange={handleOptionsChange}
            isInvalid={invalidFields.includes(label)}
          />
        )
      )}
    </div>
  );
}

export default S3;
