import axios from 'axios';

import config from '@src/config';

export const getOwner = async (owner) => {
  try {
    const url = `${config.sdk.url}owner/${owner}`;
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const [ownerData] = response.data?.data;
    return ownerData?.plan || null;
  } catch (error) {
    console.error('Error fetching owner data:', error);
    return {};
  }
};

export default {
  getOwner,
};
