import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRecoilRefresher_UNSTABLE } from 'recoil';

import '@css/TemplateGallery.css';

import ButtonElement from '@components/atoms/ButtonElement';

import { allTemplatesSelector } from '@store/Template';

import useDuplicateWorkflow from '@hooks/useDuplicateWorkflow';
import useWorkflowTemplates from '@hooks/useWorkflowTemplates';

function WorkflowRecipeGalleryDetail({ workflowId }) {
  const history = useNavigate();
  const workflowTemplates = useWorkflowTemplates();
  const refreshAllTemplateState = useRecoilRefresher_UNSTABLE(allTemplatesSelector);

  const { handleDuplicate, loading: duplicateWorkflowLoading } = useDuplicateWorkflow({
    onSuccess: (id) => {
      refreshAllTemplateState();
      history(`/workflows/${id}/edit`);
    },
  });

  const workflowTemplate = workflowTemplates.find((template) => template.id === workflowId);
  const { heading, body, media, tags, thumbnail } = workflowTemplate || {};

  return (
    <div className="template-gallery-detail" data-testid="template-gallery-detail">
      <Row>
        <Col xs={12} md={12} xl={4}>
          <h1>{heading}</h1>
          {tags && (
            <p>
              <span className="tag">{tags}</span>
            </p>
          )}
          {body && <p dangerouslySetInnerHTML={{ __html: body }} />}
          <div className="d-flex align-items-center gap-2 mt-4 mb-4">
            <ButtonElement
              handleEvent={() => handleDuplicate(workflowId)}
              loading={duplicateWorkflowLoading}
              disabled={duplicateWorkflowLoading}
              tracking={{ name: 'Select Browse Use Workflow', workflowId }}
              title="Customise this workflow"
            >
              Use Workflow
            </ButtonElement>
          </div>
        </Col>
        <Col xs={12} md={12} xl={8} className="d-flex justify-content-center align-items-center">
          {media && media.endsWith('mp4') ? (
            <video src={media} muted autoPlay controls className="template-gallery-detail__video" />
          ) : (
            <img src={thumbnail} className="template-gallery-detail__video" />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default WorkflowRecipeGalleryDetail;
