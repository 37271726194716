import { useMemo } from 'react';
import Masonry from 'react-masonry-css';

import '@css/TemplateGallery.css';

import WorkflowRecipeGalleryCard from '@feature/workflows/gallery/WorkflowRecipeGalleryCard';
import WorkflowRecipeGalleryModal from '@feature/workflows/gallery/WorkflowRecipeGalleryModal';

import useWorkflowTemplates from '@hooks/useWorkflowTemplates';

function RecipeGallery({ baseUrl, numTemplates = 999, randomize = true, withDetails = false }) {
  const workflowTemplates = useWorkflowTemplates();

  const templatesToDisplay = useMemo(
    () => (workflowTemplates ?? []).sort((a, b) => (randomize ? 0.5 - Math.random() : a.id.localeCompare(b.id))),
    [workflowTemplates]
  );

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  return (
    <div className="template-gallery" data-testid="template-gallery">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {templatesToDisplay?.slice(0, numTemplates).map((data) => (
          <div key={data.id} className={`masonry-item ${data.orientation}`}>
            <WorkflowRecipeGalleryCard baseUrl={baseUrl} data={data} withDetails={withDetails} />
          </div>
        ))}
      </Masonry>
      <WorkflowRecipeGalleryModal baseUrl={baseUrl} />
    </div>
  );
}

export default RecipeGallery;
