import { atom, selector, useRecoilCallback } from 'recoil';

import { createTemplate, duplicateTemplate, getTemplateRaw, getTemplates, saveTemplate } from '@api/services/template';

import { authReadySelector, stageAtom } from '@store/Auth';
import { isValidJsonAtom } from '@store/Edit';
import { derivedJsonSelectorFamily } from '@store/EditSelectors';
import { renderFastPreviewAtom, renderPreviewLengthAtom, renderPreviewStartAtom } from '@store/Renders';
import { errorAtom } from '@store/UI';

import { getTemplateForPreview } from '@utils/editor/preview';

const GETTING_STARTED_TEMPLATE_ID = 'hello-world-title-video';
const GETTING_STARTED_TEMPLATE_NAME = 'Shotstack - Getting Started';

export const templateLoadingAtom = atom({
  key: 'template/loading',
  default: false,
});

export const templateSavingAtom = atom({
  key: 'template/saving',
  default: false,
});

export const templateReadyAtom = atom({
  key: 'template/ready',
  default: false,
});

export const templateJsonEditAtom = atom({
  key: 'template/json/ready',
  default: false,
});

export const templateNameAtom = atom({
  key: 'template/name',
  default: 'Untitled',
});

export const templateIdAtom = atom({
  key: 'template/id',
  default: null,
});

export const bulkTemplateDeleteAtom = atom({
  key: 'templates/delete/bulk',
  default: [],
});

export const templateSelector = selector({
  key: 'templateSelector',
  get: ({ get }) => {
    const name = get(templateNameAtom);
    const id = get(templateIdAtom);
    let template = get(derivedJsonSelectorFamily());

    if (template && typeof template === 'string') {
      template = JSON.parse(template);
    }

    return { id, name, template };
  },
  set: ({ set }, templateData) => {
    const { name, id } = templateData;

    set(templateNameAtom, name);
    set(templateIdAtom, id);
  },
});

export const allTemplatesSelector = selector({
  key: 'allTemplatesSelector',
  get: async ({ get }) => {
    const ready = get(authReadySelector);
    if (!ready) {
      return [];
    }
    const { templates } = await getTemplates();
    return templates || [];
  },
});

export const allTemplatesListSelector = selector({
  key: 'allTemplatesListSelector',
  get: async ({ get }) => {
    const allTemplates = await get(allTemplatesSelector);

    return {
      templates: allTemplates || [],
      isFreePlan: false,
      isRestricted: false,
    };
  },
});

export const gettingStartedTemplateSelector = selector({
  key: 'gettingStartedTemplateSelector',
  get: async ({ get }) => {
    const ready = get(authReadySelector);
    if (!ready) {
      return { data: undefined };
    }
    const templates = await get(allTemplatesSelector);

    const { id: existingTemplateId } =
      (templates || [])?.find(({ name }) => name === GETTING_STARTED_TEMPLATE_NAME) || {};

    if (existingTemplateId) {
      const data = await getTemplateRaw(existingTemplateId);
      return {
        isExisting: true,
        data,
      };
    }

    const data = await duplicateTemplate(GETTING_STARTED_TEMPLATE_ID, GETTING_STARTED_TEMPLATE_NAME);

    return {
      data,
    };
  },
});

export const useRenderTemplate = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const templateData = snapshot.getLoadable(templateSelector).contents;
        const previewRangeStart = snapshot.getLoadable(renderPreviewStartAtom).contents;
        const previewRangeLength = snapshot.getLoadable(renderPreviewLengthAtom).contents;
        const isPreviewQuality = snapshot.getLoadable(renderFastPreviewAtom).contents;

        const range = { start: previewRangeStart, length: previewRangeLength };
        const template = templateData.template;

        return getTemplateForPreview({ template, range, isPreviewQuality });
      },
    []
  );
};

export const useToggleDeleteTemplate = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (id) => {
        let updatedState = snapshot.getLoadable(bulkTemplateDeleteAtom).contents;
        if (updatedState.includes(id)) {
          updatedState = updatedState.filter((templateId) => templateId !== id);
        } else {
          updatedState = [...updatedState, id];
        }
        set(bulkTemplateDeleteAtom, updatedState);
        return updatedState;
      },
    []
  );
};

export const useRemoveTemplatesFromList = () => {
  return useRecoilCallback(
    ({ snapshot, refresh, reset }) =>
      async () => {
        const toggleTemplateDelete = snapshot.getLoadable(bulkTemplateDeleteAtom).contents;
        const templateList = snapshot.getLoadable(allTemplatesSelector).contents;
        const updatedState = templateList.filter((templateId) => !toggleTemplateDelete.includes(templateId));

        refresh(allTemplatesSelector);
        reset(bulkTemplateDeleteAtom);

        return updatedState;
      },
    []
  );
};

export const useSaveTemplate = () => {
  return useRecoilCallback(({ set, reset, snapshot }) => async ({ json, stage } = {}) => {
    const templateIsSaving = snapshot.getLoadable(templateSavingAtom).contents;
    if (templateIsSaving) {
      return;
    }

    const templateId = snapshot.getLoadable(templateIdAtom).contents;
    const templateData = snapshot.getLoadable(templateSelector).contents;
    const validJson = snapshot.getLoadable(isValidJsonAtom).contents;

    const saveTemplateData = {
      ...templateData,
      template: json || templateData?.template,
    };

    if (!validJson || !saveTemplateData?.template) {
      set(errorAtom, { message: 'Template contains invalid JSON and could not be saved' });
      return;
    }

    reset(errorAtom);
    set(templateSavingAtom, true);

    try {
      let saveResponse;
      // when users Save and copy to another stage
      if (stage) {
        // switch stage
        set(stageAtom, stage.toLowerCase());
        // create template
        saveResponse = await createTemplate(saveTemplateData);
      } else {
        // otherwise, save the template
        saveResponse = await saveTemplate(templateId, saveTemplateData);
      }
      set(templateSavingAtom, false);
      return saveResponse;
    } catch (error) {
      set(errorAtom, { message: 'Template could not be saved' });
    } finally {
      set(templateSavingAtom, false);
    }
  });
};
