import { useCallback } from 'react';

function useDropBoundary() {
  const handleDrop = useCallback((event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (!file) return;

    if (file.type === 'application/json' || file.type === 'text/plain') {
      return file;
    }
  }, []);

  // needed to hold the drag over state
  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  return { handleDrop, handleDragOver };
}

export default useDropBoundary;
