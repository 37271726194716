import { transformFonts } from '@api/transform/utils/fonts';
import { transformTracks } from '@api/transform/utils/tracks';

import { zipObject } from '@utils/zipObject';

const getTransformMap = (mergeData) => ({
  fonts: transformFonts(mergeData),
  tracks: transformTracks(mergeData),
  default: (value) => value,
});

export const transformTimeline = (template) => async (timeline) => {
  if (!timeline) {
    return {};
  }
  const mergeData = template?.merge || [];
  const transformFns = getTransformMap(mergeData);
  const timelineEntries = Object.entries(timeline);
  const transformPromises = timelineEntries.map(async ([key, value]) => {
    const transform = transformFns[key] || transformFns.default;
    return transform(value);
  });
  const timelineTransformed = await Promise.all(transformPromises);
  return zipObject(
    timelineEntries.map(([key]) => key),
    timelineTransformed
  );
};
