import { useEffect, useState } from 'react';

import { listBuiltInTemplates } from '@api/services/builtInTemplate';

/** @typedef {import('@api/services/builtInTemplate').Template} StudioTemplate */

const useStudioTemplates = () => {
  /** @type {ReturnType<typeof import('react').useState<StudioTemplate[]>>} */
  const [studioTemplates, setStudioTemplates] = useState([]);

  const loadTemplates = async () => {
    const templates = await listBuiltInTemplates('studio');
    setStudioTemplates(templates ?? []);
  };

  useEffect(() => {
    loadTemplates();
  }, []);

  return studioTemplates;
};

export default useStudioTemplates;
