class AbortControllerManager {
  private controllers: Map<string, AbortController>;

  constructor() {
    this.controllers = new Map();
  }

  getController(id: string): AbortController {
    if (!this.controllers.has(id)) {
      this.controllers.set(id, new AbortController());
    }
    return this.controllers.get(id)!;
  }

  abort(id: string): void {
    if (this.controllers.has(id)) {
      const controller = this.controllers.get(id)!;
      controller.abort();
      this.controllers.delete(id);
    }
  }

  abortAll(): void {
    for (let controller of this.controllers.values()) {
      controller.abort();
    }
    this.controllers.clear();
  }
}

export const controllerManager = new AbortControllerManager(); 