export const transformFonts = (mergeData) => async (fonts) => {
  if (!fonts) {
    return [];
  }

  const newFonts = fonts.map((font) => {
    let src = font?.src;
    const isMergeAsset = (src || '').trim().startsWith('{{');
    if (isMergeAsset) {
      const key = (src || '').replace(/{{\s*([^}\s]+)\s*}}/, '$1');
      src = (mergeData || []).find((field) => field.find === key)?.replace || '';
    }
    return { ...font, src, meta: { ...(font?.meta || {}), src: font?.src } };
  });

  return newFonts;
};
