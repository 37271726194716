import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import serveService from '@api/services/serve';

import ServeAssetDetails from '@feature/serve/ServeAssetDetails';

import { useAnalytics } from '@components/analytics/Analytics';
import Header from '@components/header/Header';
import Layout from '@components/layout/Layout';
import ResourceDangerZone from '@components/resources/ResourceDangerZone';

import useFetchAndPoll from '@hooks/useFetchAndPoll';

function ServeApiDetail() {
  const { id } = useParams();
  const { trackEvent } = useAnalytics();
  const { data: asset } = useFetchAndPoll({ id, key: `serve/asset/${id}`, fetch: serveService.get });

  useEffect(() => {
    trackEvent('View Serve Asset Details', {
      Id: id,
    });
  }, []);

  return (
    <Layout className="pb-4">
      <Header title="Serve Asset Details" />
      <ServeAssetDetails asset={asset} />

      <ResourceDangerZone id={id} service="serve" resource={asset} />
    </Layout>
  );
}

export default ServeApiDetail;
