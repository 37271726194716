import { Col, Row } from 'react-bootstrap';

import ContentCard from '@components/cards/ContentCard';
import Header from '@components/header/Header';

function Make() {
  return (
    <>
      <Header title="Make (formerly Integromat)">Automate your workflows and tasks using Make.</Header>
      <Row className="mb-4">
        <Col md={4}>
          <ContentCard image={`/img/provider/make.png`}>
            Make (formerly Integromat) is a platform that allows users to automate their workflows and tasks by
            connecting various apps and services through integrations called Scenarios.
          </ContentCard>
        </Col>
        <Col md={8}>
          <ContentCard heading="SETUP">
            <p>
              The <a href="https://www.make.com/en/integrations/shotstack?pc=shotstack">Make</a> integration for
              Shotstack allows users to automate the process of creating and managing videos and other media assets. By
              connecting Shotstack to other apps through Integromat, users can create custom triggers and actions to
              streamline their work.
            </p>
            <p>
              For example, you could set up a scenario to automatically create a new video every time you receive a new
              customer order, or every time you update a record in your CRM.
            </p>
            <p>
              Whether you are a developer or marketer, or content creator, the Shotstack and Make integration can help
              you build media-centric apps and workflows that save time and improve efficiency. Take your media strategy
              to the next level with the power of Make and Shotstack.
            </p>
          </ContentCard>
          <ContentCard heading="INTEGRATION">
            <h5>Basic setup</h5>
            <p>
              Create a template in the Shotstack Studio and add merge fields to the elements of your video that you wish
              to customise with data from another app available in Make. Then, create a scenario in Make and add
              Shotstack.
            </p>
            <p>
              From there, choose Dynamic Template event and assign the relevant data from yur other apps to the merge
              fields in your template.
            </p>
          </ContentCard>
        </Col>
      </Row>
    </>
  );
}

export default Make;
