import cn from 'classnames';
import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useRecoilState } from 'recoil';

import { IconTrimStart, IconVolumeFull } from '@assets/icons';

import OffsetFields from '@feature/studio/fields/OffsetFields';
import SizeFields from '@feature/studio/fields/SizeFields';
import TimingFields from '@feature/studio/fields/TimingFields';
import TransformFields from '@feature/studio/fields/TransformFields';
import VisualEffectsFields from '@feature/studio/fields/VisualEffectsFields';

import AssetSource from '@components/controls/AssetSource';
import InputNumber from '@components/controls/InputNumber';
import OverrideMenu from '@components/mergeFields/OverrideMenu';

import { clipSettingsSelectorFamily } from '@store/EditSelectors';

function SettingAssetOverlay({ id }) {
  const [clip, setClip] = useRecoilState(clipSettingsSelectorFamily(id));

  const handleTrimChange = useCallback(
    (event) => {
      const { value: trim } = event.target;

      let newValue;
      if (trim.length > 0 && trim >= 0) {
        newValue = parseFloat(trim);
      }

      setClip({ 'asset:trim': newValue });
    },
    [setClip]
  );

  const handleVolumeChange = useCallback(
    (event) => {
      const { value: volume } = event.target;

      let newValue;
      if (volume.length > 0 && volume >= 0 && volume <= 1) {
        newValue = parseFloat(volume);
      }
      setClip({ 'asset:volume': newValue });
    },
    [setClip]
  );

  return (
    <>
      <AssetSource id={id} type="overlay" />

      <div
        className={cn('mb-10', {
          'settings--disabled': !clip['asset:src'],
        })}
      >
        <Row className="mb-2">
          <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
            <InputNumber
              id="trim-start"
              title="Trim Start"
              placeholder="0"
              value={clip['asset:trim'] >= 0 ? clip['asset:trim'] : ''}
              min={0}
              step={0.01}
              handleChange={handleTrimChange}
              prefix={<IconTrimStart size={18} />}
            />
            <OverrideMenu path="asset:trim" />
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
            <InputNumber
              id="volume"
              title="Volume"
              placeholder="0"
              value={clip['asset:volume'] >= 0 ? clip['asset:volume'] : ''}
              max={1}
              min={0}
              step={0.01}
              handleChange={handleVolumeChange}
              prefix={<IconVolumeFull size={18} />}
            />
            <OverrideMenu path="asset:volume" />
          </Col>
        </Row>

        <VisualEffectsFields id={id} />
      </div>

      <h6>Size &amp; Position</h6>
      <div className="mb-10">
        <div className="mb-2">
          <SizeFields id={id} />
        </div>
        <OffsetFields id={id} />
      </div>

      <h6>Transform</h6>
      <div className="mb-10">
        <TransformFields id={id} />
      </div>

      <h6>Timing</h6>
      <TimingFields id={id} />
    </>
  );
}

export default SettingAssetOverlay;
