import { useCallback } from 'react';

import useDropBoundary from '@hooks/useDropBoundary';
import useJsonProcessor from '@hooks/useJsonProcessor';

function useJsonDropProcessor({ onProcess }) {
  const { processJsonFile } = useJsonProcessor({ onProcess });
  const { handleDrop: dropHandler, handleDragOver } = useDropBoundary();

  const handleDrop = useCallback(
    (event) => {
      const file = dropHandler(event);
      if (file) {
        processJsonFile(file);
      }
    },
    [dropHandler, processJsonFile]
  );

  return { handleDrop, handleDragOver };
}

export default useJsonDropProcessor;
