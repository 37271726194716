import { useNavigate } from 'react-router-dom';

import AfterEffects from '@feature/integrations/providers/afterEffects/AfterEffects';
import Did from '@feature/integrations/providers/did/Did';
import Dolby from '@feature/integrations/providers/dolby/Dolby';
import ElevenLabs from '@feature/integrations/providers/elevenlabs/ElevenLabs';
import GoogleCloudStorage from '@feature/integrations/providers/googleCloudStorage/GoogleCloudStorage';
import GoogleDrive from '@feature/integrations/providers/googledrive/GoogleDrive';
import HeyGen from '@feature/integrations/providers/heygen/HeyGen';
import Make from '@feature/integrations/providers/make/Make';
import Mux from '@feature/integrations/providers/mux/Mux';
import OpenAI from '@feature/integrations/providers/openai/OpenAI';
import S3 from '@feature/integrations/providers/s3/S3';
import Hosting from '@feature/integrations/providers/shotstack/Hosting';
import Studio from '@feature/integrations/providers/shotstack/Studio';
import TextToSpeech from '@feature/integrations/providers/shotstack/TextToSpeech';
import StabilityAi from '@feature/integrations/providers/stabilityai/StabilityAi';
import Tiktok from '@feature/integrations/providers/tiktok/Tiktok';
import Vimeo from '@feature/integrations/providers/vimeo/Vimeo';
import Zapier from '@feature/integrations/providers/zapier/Zapier';

function IntegrationsView({ provider }) {
  const navigate = useNavigate();

  switch (provider) {
    case 'mux':
      return <Mux />;
    case 'hosting':
      return <Hosting />;
    case 's3':
      return <S3 />;
    case 'google-cloud-storage':
      return <GoogleCloudStorage />;
    case 'google-drive':
      return <GoogleDrive />;
    case 'vimeo':
      return <Vimeo />;
    case 'tiktok':
      return <Tiktok />;
    case 'text-to-speech':
      return <TextToSpeech />;
    case 'd-id':
      return <Did />;
    case 'elevenlabs':
      return <ElevenLabs />;
    case 'heygen':
      return <HeyGen />;
    case 'zapier':
      return <Zapier />;
    case 'make':
      return <Make />;
    case 'afterEffects':
      return <AfterEffects />;
    case 'stability-ai':
      return <StabilityAi />;
    case 'dolby':
      return <Dolby />;
    case 'openai':
      return <OpenAI />;
    case 'studio':
      return <Studio />;

    default:
      navigate('/integrations');
  }
}

export default IntegrationsView;
