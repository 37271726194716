const DEFAULT_ERROR_TITLE = 'An unknown error occurred.';
const DEFAULT_ERROR_MESSAGE = 'Try again or contact support if the problem persists.';
const FORBIDDEN_ERROR_TITLE = 'You do not have permission to perform this action.';

export const getRenderErrorMessage = (error) => {
  try {
    const { response } = error;
    const { data, status } = response || {};

    if (data?.error?.name === 'ValidationError') {
      const [detail] = data.error.details;

      const message = `
        <b>An error occurred: ${detail.message}.</b><br>
        <small>Path: ${detail.path.join(' > ')}</small><br>
        <small>
          Value: ${detail.context.value},
          Type: ${typeof detail.context.value}</small>
      `;

      return { type: 'error', message };
    }

    if (status === 403) {
      return { type: 'warning', title: FORBIDDEN_ERROR_TITLE, message: data?.error };
    }

    return { type: 'error', title: DEFAULT_ERROR_TITLE, message: DEFAULT_ERROR_MESSAGE };
  } catch (error) {
    console.error(error);
    return { type: 'error', title: DEFAULT_ERROR_TITLE, message: DEFAULT_ERROR_MESSAGE };
  }
};
