import dayjs from 'dayjs';
import { groupBy, orderBy } from 'lodash-es';

const orderAssets = (assets = []) => {
  const orderedAssets = orderBy(assets, [
    (asset) => asset.provider !== 'shotstack',
    (asset) => asset.type !== 'render',
    'provider',
  ]);
  return Object.values(groupBy(orderedAssets, 'provider')).flat();
};

function determineAssetType(filename) {
  const splitFilename = filename.split('-');
  const lastElement = splitFilename[splitFilename.length - 1];
  if (lastElement.includes('poster.jpg') || lastElement.includes('thumb.jpg')) {
    return lastElement.split('.')[0]; // 'poster' or 'thumb'
  }
  return 'render';
}

export const transformTimeline = async (timeline) => {
  const { created } = timeline || {};
  const renderExpired = dayjs(created).add(24, 'hours') < dayjs();

  return {
    ...timeline,
    expired: renderExpired,
  };
};

export const transformTimelines = async (response) => {
  const { links, meta, data } = response;
  return {
    assets: data.length ? data : [],
    links,
    meta,
  };
};

export const transformTimelineAssets = async (assets) => {
  return orderAssets(
    assets.map((asset) => ({
      ...asset,
      type: determineAssetType(asset?.filename),
      filesize: asset?.status !== 'deleted' ? asset?.filesize : 0,
    }))
  );
};
