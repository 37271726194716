import cn from 'classnames';
import { useRecoilValue } from 'recoil';

import NavbarMain from '@components/navbar/NavbarMain';
import SidebarMain from '@components/navbar/SidebarMain';

import { authenticatedUserAtom } from '@store/Auth';

function Layout({ children, className }) {
  const authUser = useRecoilValue(authenticatedUserAtom);

  return (
    <div className={cn('max-w-screen-4xl mx-auto', className)}>
      {authUser && <NavbarMain />}
      {authUser && <SidebarMain />}
      <main>
        <div className="p-10">{children}</div>
      </main>
    </div>
  );
}

export default Layout;
