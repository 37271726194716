import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import LinkElement from '@components/atoms/LinkElement';
import InteractiveConsole from '@components/console/InteractiveConsole';

function OverviewServeApi() {
  const { product } = useParams();
  return (
    <>
      <Card className="p-3 mb-10">
        <Card.Body>
          <p className="uppercase-label">Serve Api</p>
          <h1>Automated Media Hosting And Storage</h1>
          <p className="text-prose text-gray-500">
            Automatically send your media files to one or more hosting and storage providers from Shotstack services,
            API requests or as part of a workflow.
          </p>
          <LinkElement
            to="https://shotstack.io/docs/guide/serving-assets/serve-api/"
            variant="light"
            type="button"
            external
          >
            Documentation
          </LinkElement>
          <InteractiveConsole product={product} />
        </Card.Body>
      </Card>
    </>
  );
}

export default OverviewServeApi;
