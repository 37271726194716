import cn from 'classnames';
import { useEffect } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { Scrollbar } from 'react-scrollbars-custom';
import { useIntercom } from 'react-use-intercom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import '@css/PanelClipProperties.css';

import { IconAnimations, IconChevronLeft, IconKeyframes, IconNotificationDot, IconWarning } from '@assets/icons';

import AliasFields from '@feature/studio/fields/AliasFields';
import PanelClipEffects from '@feature/studio/panel/PanelClipEffects';
import PanelClipKeyframes from '@feature/studio/panel/PanelClipKeyframes';
import PanelClipKeyframesActions from '@feature/studio/panel/PanelClipKeyframesActions';
import PanelClipTransitions from '@feature/studio/panel/PanelClipTransitions';
import Setting from '@feature/studio/setting/Setting';

import AlertBox from '@components/alert/AlertBox';
import ButtonElement from '@components/atoms/ButtonElement';

import { activeClipAtom } from '@store/Clips';
import { settingsTabAtom } from '@store/Edit';
import { clipErrorsSelectorFamily, clipSettingsSelectorFamily } from '@store/EditSelectors';
import { clipKeyframesForAnimationSelectorFamily } from '@store/Keyframes';
import { useDeleteClipState } from '@store/Timeline';

const ASSET_SOURCE_INTERCOM_ID = '8661585';

const intercomIdMap = {
  assetPreview: ASSET_SOURCE_INTERCOM_ID,
  mergedkeyframes: undefined,
};

function ClipErrors({ errors }) {
  const { showArticle } = useIntercom();
  if (!Array.isArray(errors) || errors.length === 0) return null;

  return errors.map((error, index) => (
    <div key={index} className="asset-source__note is-warning">
      <div className="asset-source__note-left">
        <IconWarning size={24} />
      </div>
      <div className="asset-source__note-right">
        <div className="asset-source__note-title">{error.title}</div>
        <small className="d-block">
          {error.detail}
          {intercomIdMap[error.intercomErrorKey] && (
            <span className="asset-source__note-help" onClick={() => showArticle(ASSET_SOURCE_INTERCOM_ID)}>
              Learn more...
            </span>
          )}
        </small>
      </div>
    </div>
  ));
}

function PanelClipProperties({ id }) {
  const clipErrors = useRecoilValue(clipErrorsSelectorFamily(id));
  const deleteClip = useDeleteClipState();
  const setActiveClip = useSetRecoilState(activeClipAtom);
  const clip = useRecoilValue(clipSettingsSelectorFamily(id));
  const keyframes = useRecoilValue(clipKeyframesForAnimationSelectorFamily(id));
  const [selectedSettingsTab, setSelectedSettingsTab] = useRecoilState(settingsTabAtom);

  useEffect(() => {
    if (selectedSettingsTab !== 'properties') {
      setSelectedSettingsTab('properties');
    }
  }, [id]);

  const hidePanel = !clip['asset:type'];
  const animationDisabled = ['caption', 'audio', 'text-to-speech', 'shape'].includes(clip['asset:type']);

  if (hidePanel) {
    return null;
  }

  return (
    <div className="workspace__panel-inner">
      <Scrollbar className="workspace__panel-scrollbar" noScrollX style={{ width: '100%', height: '100%' }}>
        <Tabs className="settings-tabs" activeKey={selectedSettingsTab}>
          <Tab title="Properties" eventKey="properties" className="properties">
            <button className="unstyled mb-4 d-flex align-items-center gap-2" onClick={() => setActiveClip()}>
              <IconChevronLeft size={18} />
              <div className="capitalize">Edit {clip['asset:type']}</div>
            </button>

            <div className="mb-4">
              <ButtonElement
                className="panel-button w-100"
                variant="light"
                handleEvent={() => setSelectedSettingsTab('animations')}
                disabled={animationDisabled}
              >
                <div className="flex align-items-center gap-2 p-4 text-lg">
                  <div className="relative">
                    <IconNotificationDot
                      size={32}
                      className={cn('notification-dot', {
                        active: keyframes.custom > 0 || clip.effect || clip['transition:in'] || clip['transition:out'],
                      })}
                    />
                    <IconAnimations size={32} />
                  </div>
                  <div>Animations</div>
                </div>
              </ButtonElement>

              <ClipErrors errors={clipErrors} />
            </div>

            {/* ------------ SETTINGS PANEL ------------- */}
            <Setting id={id} type={clip['asset:type']} />
            {/* ------------ SETTINGS PANEL ------------- */}

            <AliasFields id={id} />

            <Button variant="danger" onClick={() => deleteClip(id)} className="w-100">
              Delete Clip
            </Button>
          </Tab>
          <Tab title="Animations" eventKey="animations">
            <button
              className="unstyled mb-4 d-flex align-items-center gap-2"
              onClick={() => setSelectedSettingsTab('properties')}
            >
              <IconChevronLeft size={18} />
              <div>Animations</div>
            </button>

            <div className="mb-4">
              <ButtonElement
                className="panel-button w-100"
                variant="light"
                handleEvent={() => setSelectedSettingsTab('keyframes')}
                disabled={animationDisabled}
              >
                <div className="flex align-items-center gap-2 p-4 text-lg">
                  <div className="relative">
                    <IconNotificationDot size={32} className={cn('notification-dot', { active: keyframes.custom })} />
                    <IconKeyframes size={32} />
                  </div>
                  <div>Custom Keyframes</div>
                </div>
              </ButtonElement>
            </div>

            {keyframes.custom && (
              <AlertBox
                className="mb-10"
                type="warning"
                message="This clip has custom keyframes, which disable motion effects and transitions."
              />
            )}

            <div className={keyframes.custom && 'settings--disabled'}>
              <div>
                <div className="font-bold mb-4">Motion Effects</div>
                <PanelClipEffects id={id} />
              </div>

              <hr className="my-10" />

              <div>
                <div className="font-bold mb-4">Transitions</div>
                <PanelClipTransitions id={id} />
              </div>
            </div>
          </Tab>
          <Tab title="Keyframes" eventKey="keyframes">
            <div className="d-flex mb-4 align-items-center justify-content-between">
              <button
                className="unstyled d-flex align-items-center gap-2"
                onClick={() => setSelectedSettingsTab('animations')}
              >
                <IconChevronLeft size={18} />
                <div>Keyframes</div>
              </button>
              <PanelClipKeyframesActions id={id} />
            </div>
            <PanelClipKeyframes id={id} />
          </Tab>
        </Tabs>
      </Scrollbar>
    </div>
  );
}

export default PanelClipProperties;
