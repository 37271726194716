import Modal from '@components/modal/BaseModal';
import ModalFooter from '@components/modal/ModalFooter';

function AudioModal({ children, renderUrl, renderId }) {
  return (
    <Modal
      visible={false}
      size="lg"
      title={<span>AUDIO PREVIEW</span>}
      body={<audio controls src={renderUrl} />}
      footer={<ModalFooter renderId={renderId} renderUrl={renderUrl} />}
    >
      {(showModal) => children(showModal)}
    </Modal>
  );
}

export default AudioModal;
