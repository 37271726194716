export const BOUNDING_BOX_HEIGHT_PIXELS = 72;
export const BOUNDING_BOX_WIDTH_PIXELS = 400;
export const MAX_WIDTH = 7680;
export const MAX_HEIGHT = 7680;

// Old: html asset
export const TEXT = 'HELLO WORLD';
export const COLOR = '#000000';
export const TEXT_ALIGN = 'center';
export const TEXT_SCALE = false;

// New: text asset
export const DEFAULT_TEXT = 'HELLO WORLD';
export const TEXT_ALIGN_HORIZONTAL = 'center';
export const TEXT_ALIGN_VERTICAL = 'center';
export const FONT_SIZE = '32';
export const FONT_FAMILY = 'Montserrat ExtraBold';
export const FONT_COLOR = '#000000';
export const FONT_LINE_HEIGHT = 1;
export const FONT_OPACITY = 1;
export const BACKGROUND_COLOR = '#FFFFFF';
export const BACKGROUND_OPACITY = 1;
export const BACKGROUND_PADDING = 10;
export const BACKGROUND_BORDER_RADIUS = 10;

export const CAPTION_COLOR = '#FFFFFF';
export const CAPTION_FONT_SIZE = 16;
