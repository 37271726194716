import { useCallback, useEffect, useState } from 'react';

const useTour = () => {
  const [tours, setTours] = useState([]);
  const [currentTour, setCurrentTour] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);

  const createTour = useCallback((id, steps) => {
    setTours((prevTours) => [...prevTours, { id, steps }]);
  }, []);

  const startTour = useCallback(
    (id) => {
      const tour = tours.find((t) => t.id === id);
      if (tour) {
        setCurrentTour(tour);
        setCurrentStep(0);
      }
    },
    [tours]
  );

  const nextStep = useCallback(() => {
    if (currentTour && currentStep < currentTour.steps.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  }, [currentTour, currentStep]);

  const prevStep = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  }, [currentStep]);

  const endTour = useCallback(() => {
    setCurrentTour(null);
    setCurrentStep(0);
  }, []);

  const getCurrentStepContent = useCallback(() => {
    if (!currentTour || !currentTour.steps[currentStep]) return null;
    return currentTour.steps[currentStep];
  }, [currentTour, currentStep]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!currentTour) return;

      switch (event.key) {
        case 'ArrowRight':
        case 'Tab':
          nextStep();
          break;
        case 'ArrowLeft':
          prevStep();
          break;
        case 'Escape':
          endTour();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentTour, nextStep, prevStep, endTour]);

  return {
    tours,
    currentTour,
    currentStep,
    createTour,
    startTour,
    nextStep,
    prevStep,
    endTour,
    getCurrentStepContent,
  };
};

export default useTour;
