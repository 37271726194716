import packageJson from '../package.json';

const baseConfig = {
  development: {
    stage: 'dev',
    proxy: 'whptxx64dert6ffkbi2v3gdnta0btbad',
    stages: {
      integration: [
        ['dev', 'Development'],
        ['v1', 'Production'],
      ],
      serve: [
        ['dev', 'Development'],
        ['v1', 'Production'],
      ],
      edit: [
        ['dev', 'Development'],
        ['stage', 'Sandbox'],
        ['v1', 'Production'],
      ],
    },
    auth0Domain: 'shotstack-dev.au.auth0.com',
    auth0ClientId: 'n9o1fsWdF3ZX75McErMNUQkL15gkELn5',
    stripeKey: 'pk_test_Pb7Aipr6LMmP8Z90TWXhJyPv007nxaB4sE',
    segmentWriteKey: 'aJimgA6CIJSzsgmSC7uIfNJxcPRvK7MK',
    segmentWriteKeySdk: '---dev-key-here----',
    gtm: {
      id: 'GTM-TMRXW9S',
      preview: 'env-8',
      auth: 'Ah7QvwXSNcHwscbfzHQI1g',
    },
    hotjarId: 3327150,
    sdk: {
      url: 'https://3rmnrrsrei.execute-api.ap-southeast-2.amazonaws.com',
    },
    oauth: {
      google: {
        clientId: '302265021480-8tmp8kn4jeqmel2hu19jo09spoam7pbd.apps.googleusercontent.com',
        redirectUri: 'https://dashboard-dev.shotstack.io/integrations/google-drive',
        scope: 'https://www.googleapis.com/auth/drive.file',
      },
      vimeo: {
        clientId: 'ea5a9d1557e09e01a5b26901a7216742f80bfa4d',
        redirectUri: 'http://localhost:3000/integrations/vimeo',
        scope: 'upload',
      },
      tiktok: {
        clientKey: 'awoc8ftu050oamot',
        redirectUri: 'https://dashboard-dev.shotstack.io/integrations/tiktok',
        scopes: 'user.info.basic,video.upload',
      },
    },
    intercom: {
      appId: 'kve2maxg',
      firstVideoTutorial: '8115272',
      mergeVideoTutorial: '8118574',
      apiVideoTutorial: '8118575',
    },
  },
  production: {
    stage: 'v1',
    proxy: 'kt7qcky26qjvkulsxfi7usitxi0acpyx',
    stages: {
      integration: [
        ['stage', 'Sandbox'],
        ['v1', 'Production'],
      ],
      serve: [
        ['dev', 'Development'],
        ['v1', 'Production'],
      ],
      edit: [
        ['stage', 'Sandbox'],
        ['v1', 'Production'],
      ],
    },
    auth0Domain: 'auth.shotstack.io',
    auth0ClientId: 'oRFYPQsCP5OvNaRByoB7nUIgJq5HQXG6',
    stripeKey: 'pk_live_3WLjEh246eA6yhjR4xK6UVeH00Q0VFCu5l',
    segmentWriteKey: 'R6tpTnuu5wGYGn62HCnDzXrzjkLaOdNJ',
    segmentWriteKeySdk: 'kfxXwq9MlL7ADFHq0UxgeA1okplvnsSE',
    gtm: {
      id: 'GTM-TMRXW9S',
      preview: 'env-1',
      auth: 'fJtBBXO4uDF6XAr3lfXx0g',
    },
    hotjarId: 3322612,
    sdk: {
      url: 'https://7h1ke16jql.execute-api.ap-southeast-2.amazonaws.com',
    },
    oauth: {
      google: {
        clientId: '75809834525-1uoss6keboigrmndv6ct21ol5df7m27c.apps.googleusercontent.com',
        redirectUri: 'https://dashboard.shotstack.io/integrations/google-drive',
        scope: 'https://www.googleapis.com/auth/drive.file',
      },
      vimeo: {
        clientId: 'ea5a9d1557e09e01a5b26901a7216742f80bfa4d',
        redirectUri: 'https://dashboard.shotstack.io/integrations/vimeo',
        scope: 'upload',
      },
      tiktok: {
        clientKey: 'awoc8ftu050oamot',
        redirectUri: 'https://dashboard.shotstack.io/integrations/tiktok',
        scopes: 'user.info.basic,video.upload',
      },
    },
    intercom: {
      appId: 'du1bntg0',
      firstVideoTutorial: '8009759',
      mergeVideoTutorial: '8010130',
      apiVideoTutorial: '8010146',
    },
  },
};

const getConfig = (env) => {
  const config = baseConfig[env];

  if (!config) {
    throw new Error(`No configuration found for env: ${env}`);
  }

  return {
    env,
    headers: {
      stage: config.stage,
      userAgent: 'ShotstackEditor/0.5.0',
    },
    onboarding: {
      url: `https://onboard.shotstack.io/${config.stage}/`,
    },
    api: {
      url: `https://data-api.shotstack.io/${config.stage}/dashboard/`,
    },
    balance: {
      url: `https://api.shotstack.io/balance/${config.stage}/dashboard/`,
    },
    sdk: {
      url: `${config.sdk.url}/${config.stage}/`,
    },
    proxy: {
      url: `https://${config.proxy}.lambda-url.ap-southeast-2.on.aws`,
    },
    integration: {
      url: `https://integrate.shotstack.io/${config.stage}/`,
      stages: config.stages.integration.map(([stage, name]) => ({ stage, name })),
    },
    edit: {
      url: `https://api.shotstack.io/${config.stage}/dashboard/`,
      stages: config.stages.edit.map(([stage, name]) => ({ stage, name })),
    },
    serve: {
      url: `https://api.shotstack.io/serve/${config.stage}/dashboard/`,
      stages: config.stages.serve.map(([stage, name]) => ({ stage, name })),
    },
    ingest: {
      url: `https://api.shotstack.io/ingest/${config.stage}/dashboard/`,
    },
    create: {
      url: `https://api.shotstack.io/create/${config.stage}/dashboard/`,
    },
    workflow: {
      url: `https://workflow.shotstack.io/`,
    },
    auth0: {
      domain: config.auth0Domain,
      clientId: config.auth0ClientId,
      audience: `https://data-api.shotstack.io/${config.stage}/dashboard`,
    },
    stripe: {
      publishableKey: config.stripeKey,
    },
    analytics: {
      gtm: {
        id: config.gtm.id,
        preview: config.gtm.preview,
        auth: config.gtm.auth,
      },
      hotjar: {
        id: config.hotjarId,
        version: 6,
      },
      segment: {
        writeKey: config.segmentWriteKey,
        writeKeySdk: config.segmentWriteKeySdk,
      },
    },
    oauth: {
      google: {
        clientId: config.oauth.google.clientId,
        redirectUri: config.oauth.google.redirectUri,
        scope: config.oauth.google.scope,
      },
      vimeo: {
        clientId: config.oauth.vimeo.clientId,
        redirectUri: config.oauth.vimeo.redirectUri,
        scope: config.oauth.vimeo.scope,
      },
      tiktok: {
        clientKey: config.oauth.tiktok.clientKey,
        redirectUri: config.oauth.tiktok.redirectUri,
        scopes: config.oauth.tiktok.scopes,
      },
    },
    intercom: {
      appId: config.intercom.appId,
      firstVideoTutorial: config.intercom.firstVideoTutorial,
      mergeVideoTutorial: config.intercom.mergeVideoTutorial,
      apiVideoTutorial: config.intercom.apiVideoTutorial,
    },
  };
};

const env = import.meta.env.VITE_STAGE;
const sdkVersion = packageJson.shotstack.sdkVersion;

export default {
  isDev: env === 'development',
  sdkVersion,
  ...getConfig(env),
};
