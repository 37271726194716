import { debounce } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import '@css/ColorPicker.css';

function ColorPicker({ className, name, handleEvent, value, disabled }) {
  const [color, setColor] = useState(value);
  const classes = ['color-picker', className, !value && 'isTransparent'].filter(Boolean).join(' ');

  const handleDebounce = (event) => {
    handleEvent(event);
  };

  const debounceColorChange = useCallback(debounce(handleDebounce, 100), [handleEvent]);

  const onChange = (event) => {
    setColor(event.target.value);
    debounceColorChange(event);
  };

  useEffect(() => {
    if (value !== color) {
      setColor(value);
    }
  }, [value]);

  return (
    <Form.Control
      name={name}
      type="color"
      value={color}
      onChange={onChange}
      className={classes}
      data-content={color}
      disabled={disabled}
    />
  );
}

export default ColorPicker;
