import { Link } from 'react-router-dom';

import CombinationMark from '@assets/img/logo/combination-mark.svg';

import TemplateNameField from '@feature/studio/template/TemplateName';

function WorkspaceActionsLeft() {
  return (
    <div className="d-flex gap-2">
      <Link to="/studio">
        <img src={CombinationMark} alt="Shotstack" style={{ height: '33px' }} />
      </Link>
      <div className="ms-3 me-3 vr" />
      <TemplateNameField />
    </div>
  );
}

export default WorkspaceActionsLeft;
