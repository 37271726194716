export const AVATARS = [
  'Angela',
  'Bill',
  'Daisy',
  'Derek',
  'Eva',
  'Jake',
  'Jeff',
  'Jerome',
  'Joon',
  'Kayla',
  'Kent',
  'Luna',
  'Mark',
  'Matthew',
  'Monica',
  'Peter',
  'Selina',
  'Tanya',
  'Thomas',
  'Tina',
  'Tyler',
  'Vanessa',
  'Vera',
  'Wilson',
  'Zoey',
];

export const AVATAR_STYLE = ['Normal', 'Circle'];

export const VOICES = [
  'Abbi - Natural',
  'Adam - Natural',
  'Aiston - Friendly',
  'Alice - Newscaster',
  'Alison - Cheerful',
  'Amber - Friendly',
  'Amy - Warm',
  'Ana - Cheerful',
  'Antoni - Friendly',
  'Aria - Newscaster',
  'Arnold - Cheerful',
  'Arthur - Natural',
  'Bella - Friendly',
  'Belle - Natural',
  'Brandon - Warm',
  'Brian - Natural',
  'Bruce - Natural',
  'Cerise - Cheerful',
  'Christopher - Calm',
  'Clara - Professional',
  'Connor - Natural',
  'Dahlia - Friendly',
  'Davis - Professional',
  'Dean - Natural',
  'Delbert - Cheerful',
  'Edward - Friendly',
  'Elaine - Calm',
  'Emily - Natural',
  'Emma - Newscaster',
  'Eric - Newscaster',
  'Grace - Natural',
  'Hailey - Calm',
  'Indira - Cheerful',
  'Isabella - Cheerful',
  'Jacob - Natural',
  'Jahmai - Friendly',
  'Jane - Serious',
  'Jason - Serious',
  'Jelle - Friendly',
  'Jen - Natural',
  'Jenny - Professional',
  'Jodi - Cheerful',
  'Joey - Calm',
  'Johan - Friendly',
  'Josie - Cheerful',
  'Keanan - Natural',
  'Keith - Cheerful',
  'Kellie - Friendly',
  'Lauren - Friendly',
  'Leah - Natural',
  'Liam - Professional',
  'Libby - Natural',
  'Lily - Professional',
  'Lucas - Natural',
  'Luke - Professional',
  'Luna - Natural',
  'Marieke - Natural',
  'Matthew - Professional',
  'Michelle - Natural',
  'Mitchell - Natural',
  'Molly - Newscaster',
  'Monica - Calm',
  'Natasha - Professional',
  'Neerja - Newscaster',
  'Noah - Serious',
  'Oliver - Newscaster',
  'Olivia - Calm',
  'Paul - Natural',
  'Prabhat - Natural',
  'Raveena - Natural',
  'Rudi - Friendly',
  'Ryan - Professional',
  'Sam - Natural',
  'Sara - Cheerful',
  'Sherry - Friendly',
  'Sonia - Warm',
  'Thomas - Natural',
  'Todd - Professional',
  'Tony - Professional',
  'Tracy - Cheerful',
  'Wayne - Natural',
  'Wilder - Natural',
  'Wille - Natural',
  'William - Friendly',
];

export const AVATARS_SELECT = AVATARS.map((avatar) => ({
  label: avatar,
  value: avatar,
}));

export const VOICES_SELECT = VOICES.map((voice) => ({
  label: voice,
  value: voice,
}));

export const AVATAR_STYLE_SELECT = AVATAR_STYLE.map((style) => ({
  label: style,
  value: style.toLowerCase(),
}));
