import { Component, ErrorInfo, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  message?: string;
}

type State = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  message: string;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { 
      error: null, 
      errorInfo: null, 
      message: 'An error occurred.' 
    };
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      error,
      errorInfo,
      message: this.props.message || this.state.message,
    });

    console.error(error, errorInfo);
  }

  override render(): ReactNode {
    if (this.state.errorInfo) {
      return (
        <div>
          <h3>Oh no, something went wrong.</h3>
          <p>{this.state.message}</p>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary; 