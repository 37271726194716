import { ButtonGroup, Col, InputGroup, Row, ToggleButton } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  IconAlignBottom,
  IconAlignCenterVertically,
  IconAlignTop,
  IconFontSize,
  IconFormatColorFill,
  IconFormatColorReset,
  IconFormatColorText,
  IconTextAlignCenter,
  IconTextAlignLeft,
  IconTextAlignRight,
} from '@assets/icons';

import OffsetFields from '@feature/studio/fields/OffsetFields';
import TimingFields from '@feature/studio/fields/TimingFields';
import VisualEffectsFields from '@feature/studio/fields/VisualEffectsFields';

import ColorPicker from '@components/atoms/ColorPicker';
import InputCheckbox from '@components/controls/InputCheckbox';
import InputNumber from '@components/controls/InputNumber';
import InputText from '@components/controls/InputText';
import FontSelect from '@components/fonts/FontSelect';
import OverrideMenu from '@components/mergeFields/OverrideMenu';

import { clipSettingsSelectorFamily } from '@store/EditSelectors';
import { templateIdAtom } from '@store/Template';

import { COLOR, MAX_HEIGHT, MAX_WIDTH } from '@constants/TextAssetDefaults';

import TransformFields from '../fields/TransformFields';

const MIN_FONT_SIZE = 1;

const assetPropertyTransform = (prop, type, value) => {
  if (type === 'number' && prop !== 'asset:fontSize') {
    return value ? parseInt(value, 10) : undefined;
  }
  return value || undefined;
};

const textAlignOptions = [
  { value: 'left', icon: <IconTextAlignLeft size={18} /> },
  { value: 'center', icon: <IconTextAlignCenter size={18} /> },
  { value: 'right', icon: <IconTextAlignRight size={18} /> },
];

const positionOptions = [
  { value: 'top', icon: <IconAlignTop size={18} /> },
  { value: 'center', icon: <IconAlignCenterVertically size={18} /> },
  { value: 'bottom', icon: <IconAlignBottom size={18} /> },
];

function SettingAssetHtml({ id }) {
  const [clip, setClip] = useRecoilState(clipSettingsSelectorFamily(id));
  const templateId = useRecoilValue(templateIdAtom);

  const tracking = {
    properties: {
      Id: templateId,
    },
  };

  const handleClipUpdate =
    ({ prop, value }) =>
    (event) => {
      const { type, value: eventValue } = event.target;
      setClip({ [prop]: assetPropertyTransform(prop, type, value ?? eventValue) });
    };

  const handleMetaUpdate =
    ({ prop, value }) =>
    (event) => {
      const { type, value: eventValue } = event.target;
      setClip((prevClip) => ({
        ...prevClip,
        'asset:meta': { ...prevClip['asset:meta'], [prop]: assetPropertyTransform(prop, type, value ?? eventValue) },
      }));
    };

  const handleTextScaleChange = (event) => {
    const { checked } = event.target;
    setClip({ 'asset:textScale': checked ? 'shrink' : undefined });
  };

  const handleEmptyFontSize = (event) => {
    const { value } = event.target;
    if (!value) {
      setClip({ 'asset:fontSize': MIN_FONT_SIZE });
    }
  };

  return (
    <>
      <div className="html-input-wrapper mb-10">
        <div className="text-content">
          <InputText
            id="text"
            placeholder="{{ MY_VARIABLE }}"
            value={clip['asset:meta']?.text}
            handleChange={handleMetaUpdate({ prop: 'text' })}
          />
        </div>

        <div className="mt-2">
          <InputCheckbox
            id="text-scale"
            name="text-scale"
            label="Text Fit"
            checked={clip['asset:textScale'] || false}
            handleChange={handleTextScaleChange}
            tracking={{ name: 'Check Text Scale', ...tracking }}
          />
        </div>
      </div>

      <h6>Style</h6>

      <div className="mb-10">
        <FontSelect id={id} type="html" />

        <div className="text-size d-flex align-items-center justify-content-between gap-2 mb-2">
          <InputNumber
            min={1}
            id="fontSize"
            title="Font Size"
            handleBlur={handleEmptyFontSize}
            handleChange={handleClipUpdate({ prop: 'asset:fontSize' })}
            prefix={<IconFontSize />}
            suffix="px"
            value={clip['asset:fontSize']}
          />
          <OverrideMenu path="asset:fontSize" />
        </div>

        <Row className="mb-2">
          <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
            <ButtonGroup>
              {textAlignOptions.map(({ value, icon }) => (
                <ToggleButton
                  key={value}
                  id={`textAlign-${value}`}
                  type="radio"
                  name="textAlign"
                  value={value}
                  checked={clip['asset:textAlign'] === value}
                  onChange={handleClipUpdate({ prop: 'asset:textAlign', value })}
                >
                  {icon}
                </ToggleButton>
              ))}
            </ButtonGroup>
            <OverrideMenu path="asset:textAlign" />
          </Col>

          <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
            <ButtonGroup>
              {positionOptions.map(({ value, icon }) => (
                <ToggleButton
                  key={value}
                  id={`position-${value}`}
                  type="radio"
                  name="position"
                  value={value}
                  checked={clip['asset:position'] === value || (!clip['asset:position'] && value === 'center')}
                  onChange={handleClipUpdate({ prop: 'asset:position', value })}
                >
                  {icon}
                </ToggleButton>
              ))}
            </ButtonGroup>
            <OverrideMenu path="asset:position" />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
            <InputGroup>
              <InputGroup.Text className="prefix" title="Text Color">
                <IconFormatColorText size={18} />
              </InputGroup.Text>

              <ColorPicker
                className="color"
                value={clip['asset:color'] || COLOR}
                handleEvent={handleClipUpdate({ prop: 'asset:color' })}
              />
            </InputGroup>
            <OverrideMenu path="asset:color" />
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
            <InputGroup>
              <InputGroup.Text className="prefix">
                <button
                  type="button"
                  name="background"
                  className="unstyled"
                  disabled={!clip['asset:background']}
                  onClick={handleClipUpdate({ prop: 'asset:background' })}
                >
                  {clip['asset:background'] ? (
                    <IconFormatColorReset size={18} title="Transparent Background Color" />
                  ) : (
                    <IconFormatColorFill size={18} title="Background Color" />
                  )}
                </button>
              </InputGroup.Text>
              <ColorPicker
                name="background"
                className="background"
                value={clip['asset:background']}
                handleEvent={handleClipUpdate({ prop: 'asset:background' })}
              />
            </InputGroup>
            <OverrideMenu path="asset:background" />
          </Col>
        </Row>

        <VisualEffectsFields id={id} />
      </div>

      <h6>Size &amp; Position</h6>

      <div className="mb-10">
        <Row className="mb-2">
          <Col className="d-flex align-items-center justify-content-between gap-2">
            <InputNumber
              id="width"
              name="width"
              title="Width (px)"
              value={clip['asset:width']}
              min={0}
              max={MAX_WIDTH}
              step={1}
              prefix="W"
              placeholder="0"
              handleChange={handleClipUpdate({ prop: 'asset:width' })}
            />
            <OverrideMenu path="asset:width" />
          </Col>
          <Col className="d-flex align-items-center justify-content-between gap-2">
            <InputNumber
              id="height"
              name="height"
              title="Height (px)"
              value={clip['asset:height']}
              min={0}
              max={MAX_HEIGHT}
              step={1}
              prefix="H"
              placeholder="0"
              handleChange={handleClipUpdate({ prop: 'asset:height' })}
            />
            <OverrideMenu path="asset:height" />
          </Col>
        </Row>

        <OffsetFields id={id} />
      </div>

      <h6>Transform</h6>
      <div className="mb-10">
        <TransformFields id={id} />
      </div>

      <h6>Timing</h6>
      <TimingFields id={id} />
    </>
  );
}

export default SettingAssetHtml;
