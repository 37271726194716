import { useRecoilValue, useSetRecoilState } from 'recoil';

import WorkflowPanelHeader from '@feature/workflows/panel/WorkflowPanelHeader';
import WorkflowPanelTriggerDataList from '@feature/workflows/panel/WorkflowPanelTriggerDataList';

import CSVUploader from '@components/csv/CSVUploader';

import { activeWorkflowAtom, workflowDataMappingAtomFamily, workflowDataSampleAtomFamily } from '@store/Workflows';

function WorkflowPanelTriggerCSV() {
  const activeWorkflow = useRecoilValue(activeWorkflowAtom);
  const setWorkflowData = useSetRecoilState(workflowDataSampleAtomFamily(activeWorkflow));
  const setWorkflowMapping = useSetRecoilState(workflowDataMappingAtomFamily(activeWorkflow));

  const handleCSVUpload = (data) => {
    const [headerRow, ...rows] = data;
    const [firstRow] = rows;
    const mapping = headerRow.reduce((acc, key) => ({ ...acc, [key]: key }), {});
    const sampleData = firstRow.reduce((acc, value, index) => {
      const key = headerRow[index];
      return { ...acc, [key]: value };
    }, {});

    setWorkflowMapping(mapping);
    setWorkflowData(JSON.stringify(sampleData, null, 2));
  };

  return (
    <div className="form-container">
      <WorkflowPanelHeader
        name="Test Data"
        description="Upload a sample CSV file to use as the test data source for this workflow."
      />
      <CSVUploader text="Upload Test Data" onUpload={handleCSVUpload} />
      <WorkflowPanelTriggerDataList />
    </div>
  );
}

export default WorkflowPanelTriggerCSV;
