import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Ratio } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';

import '@assets/css/TourManager.css';

import rendersService from '@api/services/renders';

import { onboardingRenderAtom } from '@store/Renders';

import useBodyClass from '@hooks/useBodyClass.js';
import useFetchAndPoll from '@hooks/useFetchAndPoll.js';
import usePopoverPosition from '@hooks/usePopoverPosition.js';
import useTour from '@hooks/useTour.js';

import { getStudioSteps } from '@constants/Onboarding';

const renderContent = ({ src, controls = false, isVideo = true }) => (
  <Ratio aspectRatio="16x9" className="popover-media">
    {isVideo ? (
      <video src={src} controls={controls} autoPlay muted loop className="w-100 h-100 object-fit-cover" />
    ) : (
      <img src={src} alt="Tour step" className="w-100 h-100 object-fit-cover" />
    )}
  </Ratio>
);

const renderLoading = () => (
  <Ratio aspectRatio="16x9" className="popover-media">
    <div className="d-flex flex-column align-items-center justify-content-center h-100">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <p className="mt-4 font-bold">We&apos;ve made you a video... hang tight!</p>
    </div>
  </Ratio>
);

const PopoverMedia = ({ media }) => {
  const { data: render } = useFetchAndPoll({
    id: media.renderId,
    key: `edit/render/${media.renderId}`,
    fetch: rendersService.get,
  });

  if (!media.renderId && !media.url) return null;

  if (media.renderId && !render) return renderLoading();

  if (media.url) {
    const isVideo = media.type !== 'image';
    return renderContent({ src: media.url, isVideo });
  }

  if (render) {
    return renderContent({ src: render.url, controls: true });
  }

  return null;
};

function StudioTourManager({ ready }) {
  const { currentTour, currentStep, createTour, startTour, nextStep, prevStep, endTour, getCurrentStepContent } =
    useTour();
  const onboardingRender = useRecoilValue(onboardingRenderAtom);
  const [target, setTarget] = useState(null);
  const popoverRef = useRef(null);

  usePopoverPosition(target, popoverRef);
  useBodyClass('tour-active', !!currentTour);

  useEffect(() => {
    if (!onboardingRender?.id) return;
    createTour('studio-intro', getStudioSteps({ renderId: onboardingRender.id }));
  }, [onboardingRender, createTour]);

  useEffect(() => {
    if (ready && onboardingRender?.id) {
      startTour('studio-intro');
    }
  }, [ready, onboardingRender, startTour]);

  useEffect(() => {
    if (!currentTour || !currentTour.steps[currentStep]) return;

    const { targetSelector, center } = currentTour.steps[currentStep];
    const targetElement = center ? document.body : document.querySelector(targetSelector);

    if (targetElement) {
      targetElement.classList.add('tour-active-step');
    }

    setTarget(targetElement);

    return () => {
      if (targetElement) {
        targetElement.classList.remove('tour-active-step');
      }
    };
  }, [currentTour, currentStep]);

  if (!currentTour) return null;

  const { text, center, size, media } = getCurrentStepContent();

  return (
    <>
      <div className="tour-scrim"></div>
      <div id="tour-popover" className={cn('custom-popover', { center, [size]: size })} ref={popoverRef}>
        <div className="popover-content">
          <p className="step-counter">
            <small>
              {currentStep + 1} of {currentTour.steps.length}
            </small>
          </p>
          <p className="step-text" dangerouslySetInnerHTML={{ __html: text }} />
          {media && <PopoverMedia media={media} />}
          <div className="button-group">
            <Button variant="link" onClick={endTour} className="ps-0">
              Skip
            </Button>
            {currentStep === currentTour.steps.length - 1 ? (
              <Button variant="primary" onClick={endTour}>
                Finish
              </Button>
            ) : (
              <div className="d-flex gap-2">
                <Button variant="light" onClick={prevStep} disabled={currentStep === 0}>
                  Back
                </Button>
                <Button variant="primary" onClick={nextStep}>
                  Next
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default StudioTourManager;
