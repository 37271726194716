import { Badge } from 'react-bootstrap';

import { stageColorsMap, stageLabelsMap } from '@constants/Stage';

function KeysData({ keys, label, testid }) {
  return (
    <tr data-testid={testid}>
      <td>
        <Badge bg={stageColorsMap[label]}>{stageLabelsMap[label].toUpperCase()}</Badge>
      </td>

      {keys.key_id ? (
        <>
          <td className="font-mono text-small" data-testid="keys-id">
            {keys.key_id}
          </td>
          <td className="font-mono text-small" data-testid="keys-api">
            <strong>{keys.key}</strong>
          </td>
        </>
      ) : (
        <td colSpan={3}>
          <p data-testid="keys-error">
            Your {label} API key has been disabled due to inactivity. Contact us to{' '}
            <a href="https://docs.google.com/forms/d/1POTqp3IqE4t0Sx9_glX0MBGh97VQJdDK7fj_M0x3Jpg">
              re-enable your keys
            </a>
            .
          </p>
        </td>
      )}
    </tr>
  );
}

export default KeysData;
