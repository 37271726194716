import { IconCheckLg } from '@assets/icons';

function GettingStartedProgress({ steps, activeStep }) {
  return (
    <div className="welcome__progress">
      <div className={`bar active-step-${activeStep}`} />
      {Object.entries(steps || {}).map(([key, item]) => {
        const currentStep = parseInt(key, 10);
        const isComplete = currentStep < activeStep;
        const isActive = currentStep === activeStep;

        return (
          <div key={key} className={`circle ${isActive ? 'active' : ''} ${isComplete ? 'complete' : ''}`}>
            {isComplete ? <IconCheckLg size={24} /> : key}
            <span className="text">{item.label}</span>
          </div>
        );
      })}
    </div>
  );
}

export default GettingStartedProgress;
