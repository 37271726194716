import { useEffect, useState } from 'react';
import { Badge, Col, ListGroup, Row } from 'react-bootstrap';

import RenderDetailsAsset from '@feature/renders/RenderDetailsAsset';

import Content from '@components/content/Content';
import StatusBadge from '@components/status/StatusBadge';

const typeMap = {
  thumb: 'THUMBNAIL',
  poster: 'POSTER',
  render: 'MEDIA',
};

function RenderDetailsAssets({ assets }) {
  const [selectedAsset, setSelectedAsset] = useState();

  const handleItemClick = (assetId) => {
    const asset = assets?.find(({ id }) => id === assetId);
    setSelectedAsset(asset);
  };

  useEffect(() => {
    if (assets?.length) {
      setSelectedAsset(assets[0]);
    }
  }, [assets]);

  if (!assets?.length) {
    return null;
  }

  return (
    <Content heading="HOSTED ASSETS" className="asset-listing mt-4">
      <Row>
        <Col xs={12} md={4}>
          <ListGroup>
            {(assets || []).map(({ id, provider, type, status }) => (
              <ListGroup.Item
                key={id}
                as="li"
                className="d-flex justify-content-between align-items-start"
                onClick={() => handleItemClick(id)}
                active={selectedAsset?.id === id}
              >
                <div className="ms-2 me-auto">
                  <small className="uppercase-heading">{provider}</small>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <Badge bg="light" text="dark">
                    {typeMap[type]}
                  </Badge>
                  <StatusBadge status={status} />
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col xs={12} md={8}>
          <RenderDetailsAsset asset={selectedAsset} />
        </Col>
      </Row>
    </Content>
  );
}

export default RenderDetailsAssets;
