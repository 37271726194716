import { useCallback, useMemo } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { renderTemplate } from '@api/services/template';

import { stageAtom } from '@store/Auth';
import { isValidJsonAtom } from '@store/Edit';
import { renderSubmittedAtom, rendersAtom, rendersPanelAtom } from '@store/Renders';
import { useRenderTemplate } from '@store/Template';
import { errorAtom } from '@store/UI';

import { getRenderErrorMessage } from '@utils/errors/render';

const useRender = () => {
  const stage = useRecoilValue(stageAtom);
  const isValid = useRecoilValue(isValidJsonAtom);
  const setRenders = useSetRecoilState(rendersAtom);
  const setDisplayPanel = useSetRecoilState(rendersPanelAtom);
  const setRenderSubmitted = useSetRecoilState(renderSubmittedAtom);
  const resetRenderSubmitted = useResetRecoilState(renderSubmittedAtom);
  const setErrorMessage = useSetRecoilState(errorAtom);
  const resetErrorMessage = useResetRecoilState(errorAtom);
  const getTemplate = useRenderTemplate();

  const submitRender = useCallback(async () => {
    resetErrorMessage();

    try {
      if (!isValid) {
        throw new Error('Invalid JSON');
      }

      setRenderSubmitted(true);
      setDisplayPanel(true);

      const render = await renderTemplate(getTemplate());

      if (!render?.id) {
        throw new Error('Render request failed');
      }

      setRenders((currentRenders) => [
        {
          id: render.id,
          stage,
        },
        ...currentRenders,
      ]);

      resetRenderSubmitted();
      return render.id;
    } catch (error) {
      console.error({ error });
      setErrorMessage(getRenderErrorMessage(error));
      resetRenderSubmitted();
      return undefined;
    }
  }, [
    isValid,
    resetErrorMessage,
    resetRenderSubmitted,
    setDisplayPanel,
    setErrorMessage,
    setRenderSubmitted,
    setRenders,
    getTemplate,
    stage,
  ]);

  return useMemo(() => ({ submitRender }), [submitRender]);
};

export default useRender;
