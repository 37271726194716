import editClient from '@api/clients/edit';
import { getBuiltInTemplate } from '@api/services/builtInTemplate';
import { transformTemplateAxios } from '@api/transform/template';
import { transformTimeline } from '@api/transform/utils/timeline';

import defaultTemplate from '@data/template/default.json';

export const transformGalleryTemplate = async (galleryTemplate) => {
  const { name, template } = galleryTemplate;
  const transformFn = transformTimeline(template);
  const timeline = await transformFn(template?.timeline);
  return { name, template: { ...template, timeline } };
};

export const createTemplate = async (template) => {
  const url = `templates`;
  const { data } = await editClient.post(url, template);
  return data?.body?.data;
};

export const saveTemplate = async (id, template) => {
  const url = `templates/${id}`;
  const { data } = await editClient.put(url, template);
  return data?.body?.data;
};

export const deleteTemplate = async (templateId) => {
  const url = `templates/${templateId}`;
  const { data } = await editClient.delete(url);
  return data?.body?.data;
};

export const deleteTemplates = async (templateIds) => {
  return Promise.allSettled(templateIds.map((id) => deleteTemplate(id)));
};

export const createTemplateFromDefault = async () => {
  const template = {
    name: 'Untitled',
    template: {
      ...defaultTemplate,
    },
  };

  const url = `templates`;
  const { data } = await editClient.post(url, template);
  const { id } = data?.body?.data || {};
  return id;
};

export const getTemplate = async (id) => {
  if (!id) {
    return defaultTemplate;
  }

  const builtInStudioTemplate = await getBuiltInTemplate('studio', id);

  if (builtInStudioTemplate?.data) {
    return transformGalleryTemplate({ name: builtInStudioTemplate.heading, template: builtInStudioTemplate.data });
  }

  const requestConfig = {
    transformResponse: transformTemplateAxios,
  };

  const url = `templates/${id}`;
  const { data } = await editClient.get(url, requestConfig);
  return data?.body?.data;
};

export const getTemplateRaw = async (id) => {
  if (!id) {
    return defaultTemplate;
  }

  const builtInStudioTemplate = await getBuiltInTemplate('studio', id);

  if (builtInStudioTemplate?.data) {
    return { name: builtInStudioTemplate.heading, template: builtInStudioTemplate.data };
  }

  const url = `templates/${id}`;
  const { data } = await editClient.get(url);
  return data?.body?.data;
};

export const getTemplates = async () => {
  const url = `templates?data=true`;
  const { data } = await editClient.get(url);
  return data?.body?.data;
};

export const duplicateTemplate = async (id, name) => {
  const { template: referenceTemplate, name: referenceName } = await getTemplateRaw(id);

  const template = {
    template: referenceTemplate,
    name: name ?? `${referenceName} (Copy)`,
  };

  const url = `templates`;
  const { data } = await editClient.post(url, template);
  const { id: newId } = data?.body?.data || {};
  const { name: newName, template: newTemplate } = template;
  return {
    id: newId,
    name: newName,
    template: newTemplate,
  };
};

export const renderTemplate = async (template) => {
  const url = `render`;
  const { data } = await editClient.post(url, template);
  return data?.body?.data;
};
