import config from '@config';
import { Suspense, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import '@css/Editor.css';

import { getTemplate } from '@api/services/template';

import { useIsTrackingHistory } from '@context/RecoilUndo';

import CanvasResolutionWarning from '@feature/studio/canvas/CanvasResolutionWarning';
import PanelRenders from '@feature/studio/panel/PanelRenders';
import Workspace from '@feature/studio/workspace/Workspace';

import { useAnalytics } from '@components/analytics/Analytics';
import AssetLoader from '@components/assetLoader/AssetLoader';
import StageConfirmation from '@components/modal/StageConfirmation';
import StudioTourManager from '@components/tours/StudioTourManager';

import { assetLoaderIsLoadingAtom } from '@store/Assets';
import { showStageConfirmationModalAtom } from '@store/Auth';
import { activeClipAtom } from '@store/Clips';
import { templateMediaState, useHydrateStoreFromTemplate } from '@store/Edit';
import { useResetStudio } from '@store/Reset';
import { sdkOptionsSelector } from '@store/Sdk';
import { templateLoadingAtom, templateSelector } from '@store/Template';
import { errorAtom } from '@store/UI';

const extractAssetSrcs = (obj) => {
  const srcs = [];
  if (typeof obj === 'object' && obj !== null) {
    Object.entries(obj).forEach(([key, value]) => {
      if (key === 'asset:src') {
        srcs.push(value);
      } else if (typeof value === 'object') {
        srcs.push(...extractAssetSrcs(value));
      }
      if (srcs.length >= 4) return;
    });
  }
  return srcs.slice(0, 4);
};

function WorkspaceRoot() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { trackEvent } = useAnalytics();
  const hydrateStore = useHydrateStoreFromTemplate();
  const resetStudio = useResetStudio();

  const { isSdkEnabled } = useRecoilValue(sdkOptionsSelector);

  const { setIsTrackingHistory } = useIsTrackingHistory();

  const showStageConfirmationModal = useRecoilValue(showStageConfirmationModalAtom);
  const setAssetsLoading = useSetRecoilState(assetLoaderIsLoadingAtom);
  const setTemplateState = useSetRecoilState(templateSelector);
  const [templateLoading, setTemplateLoading] = useRecoilState(templateLoadingAtom);
  const resetActiveClip = useResetRecoilState(activeClipAtom);
  const setErrorMessage = useSetRecoilState(errorAtom);
  const setTemplateMedia = useSetRecoilState(templateMediaState(id));

  const [templateId, setTemplateId] = useState();
  const [showTour, setShowTour] = useState(false);

  useEffect(() => {
    trackEvent('View Editor', { Id: id });

    if (searchParams.has('showTour')) {
      setShowTour(true);
      searchParams.delete('showTour');
      setSearchParams(searchParams);
      navigate(`/studio/editor/${id}`, {
        replace: true,
      });
    }
  }, []);

  const getTemplateData = async () => {
    setTemplateLoading(true);
    setTemplateId(id);
    setAssetsLoading(true);

    try {
      const data = await getTemplate(id);
      await hydrateStore(data.template);

      const assetSrcs = extractAssetSrcs(data.template);
      setTemplateMedia({ loading: false, status: 'done', data: assetSrcs });

      setTemplateState(data);
      resetActiveClip();
    } catch (error) {
      setErrorMessage({ message: error.message });
    } finally {
      setTemplateLoading(false);
      const setUndoRedoTimeout = setTimeout(() => {
        clearTimeout(setUndoRedoTimeout);
        setIsTrackingHistory(true);
      }, 1000);
    }
  };

  useEffect(() => {
    if (id !== templateId) {
      getTemplateData();
    }
    return () => {
      resetStudio();
    };
  }, [id]);

  return (
    <div className="editor">
      {!isSdkEnabled && !config.isDev && <CanvasResolutionWarning />}
      <PanelRenders />

      {showStageConfirmationModal && <StageConfirmation />}
      {!showStageConfirmationModal && (
        <Suspense fallback={<div />}>
          <div className="editor__main">
            <AssetLoader />
            {showTour && <StudioTourManager ready={!templateLoading} />}
            <Workspace templateLoading={templateLoading} />
          </div>
        </Suspense>
      )}
    </div>
  );
}

export default WorkspaceRoot;
