import cn from 'classnames';
import { Link } from 'react-router-dom';

import { IconExternal } from '@assets/icons';

import { useAnalytics } from '@components/analytics/Analytics';

const defaultTrackingProperties = {
  Context: 'Inline Text Link',
};

function LinkElement({
  to,
  title,
  content,
  className = '',
  tracking,
  type,
  size,
  external,
  variant = 'primary',
  children,
  disabled,
}) {
  const { trackEvent } = useAnalytics();
  const isButton = type === 'button';
  const elementClasses = cn(className, {
    btn: isButton,
    [`btn-${variant}`]: isButton && variant,
    [`btn-${size}`]: isButton && size,
    'pr-3': isButton && external,
    disabled,
  });

  const handleClick = () => {
    if (tracking) {
      trackEvent(tracking.name, { ...defaultTrackingProperties, ...tracking.properties });
    }
  };

  return external ? (
    <a href={to} onClick={handleClick} className={elementClasses} target="_blank" rel="noreferrer" title={title}>
      {content || children}
      <IconExternal size={12} className="external-link-icon" />
    </a>
  ) : (
    <Link to={to} onClick={handleClick} className={elementClasses} title={title}>
      {content || children}
    </Link>
  );
}

export default LinkElement;
