import { useEffect, useState } from 'react';

const useFontCheck = (fontFamily) => {
  const [isFontLoaded, setIsFontLoaded] = useState(false);

  const checkLoaded = async () => {
    setIsFontLoaded(false);
    try {
      await document.fonts.load(`1em ${fontFamily}`);
      setIsFontLoaded(true);
    } catch (e) {
      setIsFontLoaded(false);
    }
  };

  useEffect(() => {
    checkLoaded();
  }, [fontFamily]);

  return isFontLoaded;
};

export default useFontCheck;
