type IndexedDBConfig = {
  db: string;
  table: string;
  version: string;
};

class IndexedDBUtil {
  private db: string;
  private table: string;
  private version: string;
  private name: string;

  constructor({ db, table, version }: IndexedDBConfig) {
    this.db = db;
    this.table = table;
    this.version = version;
    this.name = `${table}_${version}`;
  }

  async openDB(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      // Todo: This is being used as a string atm
      // @ts-ignore
      const request = indexedDB.open(this.db, this.version);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);

      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = (event.target as IDBOpenDBRequest).result;

        // Clean up old stores if they exist
        this.cleanupOldStores(db);

        // Create a new object store for the new version if it doesn't exist
        if (!db.objectStoreNames.contains(this.name)) {
          db.createObjectStore(this.name);
        }
      };
    });
  }

  private cleanupOldStores(db: IDBDatabase): void {
    const existingStores = Array.from(db.objectStoreNames);
    existingStores.forEach((store) => {
      if (store.startsWith(this.table) && store !== this.name) {
        db.deleteObjectStore(store);
      }
    });
  }

  async setItem<T>(key: string, value: T): Promise<void> {
    const db = await this.openDB();
    const transaction = db.transaction(this.name, 'readwrite');
    const store = transaction.objectStore(this.name);
    const request = store.put(value, key);

    return new Promise((resolve, reject) => {
      request.onsuccess = () => resolve();
      request.onerror = () => reject(request.error);
    });
  }

  async getItem<T>(key: string): Promise<T | undefined> {
    const db = await this.openDB();
    const transaction = db.transaction(this.name, 'readonly');
    const store = transaction.objectStore(this.name);
    const request = store.get(key);

    return new Promise((resolve, reject) => {
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }
}

export default IndexedDBUtil;
