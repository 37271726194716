import { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { IconClipboard, IconClipboardCheck } from '@assets/icons';

import WorkflowPanelHeader from '@feature/workflows/panel/WorkflowPanelHeader';

import { useAnalytics } from '@components/analytics/Analytics';
import PrismSnippet from '@components/code/PrismSnippet';

import { activeWorkflowAtom, workflowWebhookSnippetSelector } from '@store/Workflows';

function WorkflowPanelTriggerWebhook() {
  const { state, contents } = useRecoilValueLoadable(workflowWebhookSnippetSelector);
  const activeWorkflow = useRecoilValue(activeWorkflowAtom);
  const [copied, setCopied] = useState(false);
  const { trackEvent } = useAnalytics();

  const tracking = {
    name: 'Select Copy Webhook Snippet',
    properties: {
      Id: activeWorkflow,
    },
  };

  const handleCopy = () => {
    setCopied(true);
    trackEvent(tracking.name, { Context: 'Button', ...tracking.properties });

    const resetCopiedTimeout = setTimeout(() => {
      setCopied(false);
      clearTimeout(resetCopiedTimeout);
    }, 2000);
  };

  return (
    <div className="form-container">
      <WorkflowPanelHeader
        name="Usage"
        description="To trigger this workflow, send a POST request with the <strong>api key in the header</strong>. <br /><br />The request body should contain the data you want to use in your workflow. Use the Test Data section above to structure the data you will send to the webhook for use in your workflow."
      />

      <div className="workflow-panel__trigger-webhook">
        {state === 'loading' && <Spinner animation="border" variant="secondary" />}
        {state === 'hasValue' && (
          <div>
            <div>
              <strong>
                <small>Method:</small>
              </strong>
              <code>
                <pre>POST</pre>
              </code>
            </div>
            <div>
              <strong>
                <small>Endpoint:</small>
              </strong>
              <code>
                <pre>{contents.url}</pre>
              </code>
            </div>
            <div>
              <strong>
                <small>Headers:</small>
              </strong>
              <code>
                <pre>x-api-key: {contents.key}</pre>
              </code>
            </div>
            <div>
              <strong>
                <small>Data:</small>
              </strong>
              <code>
                <pre>{JSON.stringify(contents.data, null, 2)}</pre>
              </code>
            </div>
          </div>
        )}
      </div>

      <div>
        {state === 'loading' && <Spinner animation="border" variant="secondary" />}
        {state === 'hasValue' && (
          <>
            <small className="d-block">CURL Snippet</small>
            <div className="snippet-wrapper">
              <CopyToClipboard text={contents.snippet} onCopy={handleCopy}>
                <Button
                  className="btn-copy"
                  title="Copy to clipboard"
                  variant={copied ? 'primary' : 'light'}
                  type="button"
                >
                  {copied ? <IconClipboardCheck size="16" /> : <IconClipboard size="16" />}
                </Button>
              </CopyToClipboard>
              <PrismSnippet text={contents.snippet} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default WorkflowPanelTriggerWebhook;
