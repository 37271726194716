import config from '@config';
import { Col, Row } from 'react-bootstrap';

import GoogleDriveForm from '@feature/integrations/providers/googledrive/GoogleDriveForm';

import AlertBox from '@components/alert/AlertBox';
import ContentCard from '@components/cards/ContentCard';
import Header from '@components/header/Header';

function GoogleDrive() {
  // Todo: Remove filter once state is implemented via nonce
  const Forms = config.integration.stages
    .filter((stage) => ['dev', 'v1'].includes(stage.stage))
    .map((stage, index) => (
      <GoogleDriveForm key={stage.stage} stage={stage.stage} stageName={stage.name} index={index} />
    ));

  return (
    <>
      <Header title="Google Drive">Transfer files to your Google Drive.</Header>
      <Row className="mb-4">
        <Col md={4}>
          <ContentCard image={`/img/provider/gdrive.png`}>
            Google Drive is a file storage service developed by Google.
          </ContentCard>
        </Col>
        <Col md={8}>
          <ContentCard heading="SETUP">
            <p>
              Connect your Google Drive by authorising Shotstack to access your Google account. We will only have access
              to the files that are created by Shotstack.
            </p>
          </ContentCard>
          <ContentCard heading="CREDENTIALS">
            {Forms}
            <AlertBox
              type="info"
              name={'Production Only'}
              message={'This integration is currently only available in the production environment.'}
            ></AlertBox>
          </ContentCard>
          <ContentCard heading="INTEGRATION">
            <h5>Basic setup</h5>
            <p>To send files to Google Drive add the following to the output parameter of the render payload:</p>
            <pre>{`"destinations": [{
    "provider": "google-drive"
}]`}</pre>
            <p>
              The following will send the rendered files to a <b>folder</b> on your Google Drive with the ID{' '}
              <b>1r-eTY8OLO8tzQRKwMyq-fIrQ_7AQEI6A</b>. If the rendered file is an mp4, it will be named{' '}
              <b>video.mp4</b>:
            </p>
            <pre>{`"destinations": [{
    "provider": "google-drive",
    "options": {
        "filename": "video",
        "folderId": "1r-eTY8OLO8tzQRKwMyq-fIrQ_7AQEI6A"
    }
  }]`}</pre>
            <p>
              You can retrieve the folder ID from the URL e.g.{' '}
              <code>
                https://drive.google.com/drive/u/0/folders/
                <b>1r-eTY8OLO8tzQRKwMyq-fIrQ_7AQEI6A</b>
              </code>
            </p>
            <h5>Opting out from the Shotstack destination</h5>
            <p>
              By default all generated files are sent to the Shotstack destination. You may wish to opt out from hosting
              you videos with Shotstack.
            </p>
            <pre>{`"destinations": [{
    "provider": "google-drive",
    "options": {
      "filename": "video",
      "folderId": "my-folder-id"
  }
},{
    "provider": "shotstack",
    "exclude": true
}]`}</pre>
          </ContentCard>
        </Col>
      </Row>
    </>
  );
}

export default GoogleDrive;
