import CanvasAudioPlayer from '@feature/studio/canvas/CanvasAudioPlayer';

type CanvasAudioProps = {
  id: string;
};

function CanvasAudio({ id }: CanvasAudioProps) {
  return <CanvasAudioPlayer id={id} />;
}

export default CanvasAudio;
