import WorkflowPanelTriggerDataInput from '@feature/workflows/panel/WorkflowPanelTriggerDataInput';
import WorkflowPanelTriggerDataList from '@feature/workflows/panel/WorkflowPanelTriggerDataList';
import WorkflowPanelTriggerWebhookUsage from '@feature/workflows/panel/WorkflowPanelTriggerWebhookUsage';

function WorkflowPanelTriggerWebhook() {
  return (
    <>
      <WorkflowPanelTriggerDataInput />
      <WorkflowPanelTriggerDataList />
      <WorkflowPanelTriggerWebhookUsage />
    </>
  );
}

export default WorkflowPanelTriggerWebhook;
