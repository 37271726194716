import { ButtonGroup, ButtonToolbar } from 'react-bootstrap';

import SaveTemplateButton from '@feature/studio/template/TemplateSave';

import HelpButton from '@components/controls/HelpButton';
import MergeFieldsButton from '@components/controls/MergeFieldsButton';

function ToolbarRight() {
  return (
    <ButtonToolbar className="d-flex gap-2">
      <ButtonGroup>
        <HelpButton />
      </ButtonGroup>
      <ButtonGroup>
        <MergeFieldsButton />
      </ButtonGroup>
      <ButtonGroup>
        <SaveTemplateButton />
      </ButtonGroup>
    </ButtonToolbar>
  );
}

export default ToolbarRight;
