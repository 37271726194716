import dayjs from 'dayjs';
import { isNumber } from 'lodash-es';
import { Card, Col, Row } from 'react-bootstrap';

import '@css/TableData.css';

import { IconExternal } from '@assets/icons';

import AlertBox from '@components/alert/AlertBox';
import AssetDetailsPreview from '@components/asset/AssetDetailsPreview';
import PrismSnippet from '@components/code/PrismSnippet';
import Content from '@components/content/Content';
import StatusBadge from '@components/status/StatusBadge';

import formatFileSize from '@utils/formatFileSize';

const getRenderUrlValue = (url, isExpired) => {
  if (!url) {
    return '—';
  }

  if (isExpired) {
    return <StatusBadge status="expired" />;
  }

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {url}
      <IconExternal />
    </a>
  );
};

const getRenderMetadata = (render, url) => {
  const { created, id, width, height, aspect, fps, format, filesize, status, quality, executionTime, expired } =
    render || {};

  return [
    { label: 'Render ID', value: id },
    { label: 'Status', value: <StatusBadge status={status || 'loading'} /> },
    {
      label: 'URL',
      value: getRenderUrlValue(url, expired),
    },
    { label: 'Aspect Ratio', value: aspect && `${aspect}` },
    { label: 'Width', value: width && `${width}px` },
    { label: 'Height', value: height && `${height}px` },
    { label: 'Quality', value: quality },
    format === 'mp4' ? { label: 'FPS', value: fps } : null,
    { label: 'Format', value: format && format.toUpperCase() },
    {
      label: 'File Size',
      value: `${formatFileSize(filesize * 1024, 'value')}${formatFileSize(filesize * 1024, 'suffix')}`,
    },
    { label: 'Render Time', value: isNumber(executionTime) && `${Math.round(executionTime / 10) / 100}s` },
    { label: 'Created', value: dayjs(created).format('DD MMM YYYY HH:mm:ss.SSS A') },
  ].filter((item) => item && item?.value);
};

function RenderDetailsRender({ render }) {
  const { error, format, url, thumbnail, poster, expired, json } = render || {};

  const renderMetadata = getRenderMetadata(render, url);

  const renderAssets = [
    { name: 'Thumbnail', url: thumbnail },
    { name: 'Poster', url: poster },
  ];

  return (
    <>
      <Content heading="MEDIA" className="asset-listing mt-4">
        <Card>
          <Card.Body>
            {error && <AlertBox type="error" message={error} className="mb-3" />}
            <Row>
              <Col xs={12} md={6}>
                <AssetDetailsPreview url={url} format={format} showPlaceholder={expired} />
                {expired && (
                  <AlertBox
                    type="warning"
                    message="The render URL is no longer valid as it has exceeded the 24-hour validity period. To ensure your output remains accessible, please add a destination to your template."
                    className="mb-3"
                  />
                )}

                <div className="render-asset-tiles mb-2">
                  {renderAssets.map(({ name, url }) => {
                    if (expired || !url) {
                      return (
                        <div className="render-asset-tile" key={name}>
                          <div className="render-asset-tile__image" />
                          <div className="description">
                            <div>
                              <strong>{name}</strong>
                            </div>
                            <div>{expired ? 'Expired' : 'Not set'}</div>
                          </div>
                        </div>
                      );
                    }

                    return (
                      <div className="render-asset-tile" key={name}>
                        <div className="render-asset-tile__image">
                          <img src={url} alt="" />
                        </div>
                        <div className="description">
                          <div>
                            <strong>{name}</strong>
                          </div>
                          <div>
                            <a href={url} target="_blank" rel="noopener noreferrer">
                              Open <IconExternal />
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Col>

              <Col xs={12} md={6}>
                <table className="metadata">
                  <tbody>
                    {renderMetadata.map(({ label, value }) => (
                      <tr key={label}>
                        <td className="metadata__key">{label}</td>
                        <td className="metadata__value">{value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Content>
      <Content heading="JSON" className="asset-listing mt-4">
        <Card>
          <Card.Body>
            <PrismSnippet compact text={JSON.stringify(json, null, 2)} className="mb-2" />
          </Card.Body>
        </Card>
      </Content>
    </>
  );
}

export default RenderDetailsRender;
