import onboardingClient from '@api/clients/onboarding';

const getUser = async () => {
  const { data } = await onboardingClient.get('userinfo');
  return data?.body?.data;
};

const getKeys = async () => {
  const { data } = await onboardingClient.get('keys');
  return data?.body?.data;
};

const subscribeUser = async (subscriptionCode, discountCode) => {
  const { data } = await onboardingClient.post('payment/subscribe', {
    subscriptionCode,
    discountCode,
  });
  return data?.body?.data;
};

const getSubscription = async (subscriptionCode) => {
  const { data } = await onboardingClient.get(`payment/subscription/${subscriptionCode}`);
  return data?.body?.data;
};

const getSubscriptions = async () => {
  const { data } = await onboardingClient.get('payment/subscriptions');
  return data?.body?.data;
};

const getSubscriber = async (stripeId) => {
  const { data } = await onboardingClient.get(`payment/customer`, {
    params: { customerId: stripeId },
  });
  return data?.body?.data;
};

export default {
  getUser,
  getKeys,
  subscribeUser,
  getSubscription,
  getSubscriptions,
  getSubscriber,
};
