const adjustmentMatrix = {
  left: {
    left: (pos, size) => pos + size.width / 2,
    right: (pos, size) => pos + size.width / 2,
    top: (pos) => pos,
    bottom: (pos) => pos,
    center: (pos, size) => pos + size.width / 2,
  },
  right: {
    left: (pos, size) => pos - size.width / 2,
    right: (pos, size) => pos - size.width / 2,
    top: (pos) => pos,
    bottom: (pos) => pos,
    center: (pos, size) => pos - size.width / 2,
  },
  top: {
    left: (pos) => pos,
    right: (pos) => pos,
    top: (pos, size) => pos + size.height / 2,
    bottom: (pos, size) => pos + size.height / 2,
    center: (pos, size) => pos + size.height / 2,
  },
  bottom: {
    left: (pos) => pos,
    right: (pos) => pos,
    top: (pos, size) => pos - size.height / 2,
    bottom: (pos, size) => pos - size.height / 2,
    center: (pos, size) => pos - size.height / 2,
  },
  center: {
    left: (pos) => pos,
    right: (pos) => pos,
    top: (pos) => pos,
    bottom: (pos) => pos,
    center: (pos) => pos,
  },
};

const getAdjustedPosition = (pos, sourceEdge, elementEdge, sourceSize) => {
  return adjustmentMatrix[sourceEdge][elementEdge](pos, sourceSize);
};

const snapToElement = ({ bounds, elements, threshold }) => {
  if (!bounds) return;

  const center = {
    x: (bounds.topLeft.x + bounds.bottomRight.x) / 2,
    y: (bounds.topLeft.y + bounds.bottomRight.y) / 2,
  };
  const width = bounds.bottomRight.x - bounds.topLeft.x;
  const height = bounds.bottomRight.y - bounds.topLeft.y;
  const snapPoints = [
    { point: center, edge: 'center' },
    { point: { x: center.x - width / 2, y: center.y }, edge: 'left' },
    { point: { x: center.x + width / 2, y: center.y }, edge: 'right' },
    { point: { x: center.x, y: center.y - height / 2 }, edge: 'top' },
    { point: { x: center.x, y: center.y + height / 2 }, edge: 'bottom' },
  ];

  let closestXSnap = { distance: Infinity, x: null, snappedXLine: null };
  let closestYSnap = { distance: Infinity, y: null, snappedYLine: null };

  for (const element of elements) {
    if (!element.bounds || Object.keys(element.bounds).length === 0) continue;

    const elementCenter = {
      x: (element.bounds.topLeft.x + element.bounds.bottomRight.x) / 2,
      y: (element.bounds.topLeft.y + element.bounds.bottomRight.y) / 2,
    };
    const elementWidth = element.bounds.bottomRight.x - element.bounds.topLeft.x;
    const elementHeight = element.bounds.bottomRight.y - element.bounds.topLeft.y;
    const elementPoints = [
      { point: elementCenter, edge: 'center' },
      { point: { x: elementCenter.x - elementWidth / 2, y: elementCenter.y }, edge: 'left' },
      { point: { x: elementCenter.x + elementWidth / 2, y: elementCenter.y }, edge: 'right' },
      { point: { x: elementCenter.x, y: elementCenter.y - elementHeight / 2 }, edge: 'top' },
      { point: { x: elementCenter.x, y: elementCenter.y + elementHeight / 2 }, edge: 'bottom' },
    ];

    for (const { point: snapPoint, edge: sourceEdge } of snapPoints) {
      for (const { point: elementPoint, edge: elementEdge } of elementPoints) {
        const xDistance = Math.abs(snapPoint.x - elementPoint.x);
        const yDistance = Math.abs(snapPoint.y - elementPoint.y);

        if (xDistance < threshold && xDistance < closestXSnap.distance) {
          closestXSnap = {
            distance: xDistance,
            x: getAdjustedPosition(elementPoint.x, sourceEdge, elementEdge, { width, height }),
            snappedXLine: elementPoint.x,
          };
        }

        if (yDistance < threshold && yDistance < closestYSnap.distance) {
          closestYSnap = {
            distance: yDistance,
            y: getAdjustedPosition(elementPoint.y, sourceEdge, elementEdge, { width, height }),
            snappedYLine: elementPoint.y,
          };
        }
      }
    }
  }

  return {
    x: closestXSnap.x || center.x,
    y: closestYSnap.y || center.y,
    snappedX: closestXSnap.x !== null,
    snappedXLine: closestXSnap.snappedXLine,
    snappedY: closestYSnap.y !== null,
    snappedYLine: closestYSnap.snappedYLine,
  };
};

const snapPosition = ({ position, bounds, center, size, canvasSize, angle, elements, threshold = 5 }) => {
  const newPosition = { ...position };
  let newAngle = angle;
  const snappingLines = { x: false, y: false };

  const {
    x: snapX,
    y: snapY,
    snappedX,
    snappedXLine,
    snappedY,
    snappedYLine,
  } = snapToElement({ bounds, elements, threshold });

  if (Math.abs(position.x - center.x) < threshold) {
    newPosition.x = center.x;
    snappingLines.x = 'center';
  } else if (snappedX) {
    newPosition.x = snapX;
    snappingLines.x = snappedXLine;
  } else if (Math.abs(position.x - size.width / 2) < threshold) {
    newPosition.x = size.width / 2;
    snappingLines.x = 0;
  } else if (Math.abs(position.x - (canvasSize.width - size.width / 2)) < threshold) {
    newPosition.x = canvasSize.width - size.width / 2;
    snappingLines.x = canvasSize.width;
  }
  if (Math.abs(position.y - center.y) < threshold) {
    newPosition.y = center.y;
    snappingLines.y = 'center';
  } else if (snappedY) {
    newPosition.y = snapY;
    snappingLines.y = snappedYLine;
  } else if (Math.abs(position.y - size.height / 2) < threshold) {
    newPosition.y = size.height / 2;
    snappingLines.y = 0;
  } else if (Math.abs(position.y - (canvasSize.height - size.height / 2)) < threshold) {
    newPosition.y = canvasSize.height - size.height / 2;
    snappingLines.y = canvasSize.height;
  }

  if (Math.abs(angle % 45) < threshold) {
    newAngle = Math.round(angle / 45) * 45;
  }

  return { position: newPosition, angle: newAngle, snappingLines };
};

export { snapPosition };
