import { Card, Row } from 'react-bootstrap';

import { IconMusicNoteBeamed } from '@assets/icons';

import AudioModal from '@components/modal/AudioModal';

function RenderPreviewAudio({ render }) {
  return (
    <AudioModal renderId={render.id} renderUrl={render.url}>
      {(showModal) => (
        <Card className="mb-2 editor-thumbnail-container" onClick={() => showModal(true)}>
          <Row className="editor-thumbnail" onClick={showModal} role="button" src={render.url}>
            <div className="d-flex flex-column align-items-center justify-content-center h-100">
              <IconMusicNoteBeamed style={{ fontSize: '2rem' }} />
            </div>
          </Row>
        </Card>
      )}
    </AudioModal>
  );
}

export default RenderPreviewAudio;
