import { memo } from 'react';

import { IconCaptions } from '@assets/icons';

import TimelineClipInfo from '@feature/studio/timeline/TimelineClipInfo';

function TimelineClipCaption({ id, clip }) {
  return (
    <div className="clip__inner">
      <div className="clip__header">
        <span className="clip__icon">
          <IconCaptions size={16} />
        </span>
        <TimelineClipInfo id={id} />
        <span className="clip__type">{clip['asset:type']}</span>
      </div>
    </div>
  );
}

export default memo(TimelineClipCaption);
