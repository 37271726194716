const getFileTypeFromUrl = (url) => {
  try {
    const extension = url.split('.').pop();
    let type;

    switch (extension) {
      case 'jpg':
      case 'png':
      case 'bmp':
      case 'gif':
      case 'webp':
        type = 'image';
        break;

      case 'mp3':
      case 'wav':
        type = 'audio';
        break;

      default:
        type = 'video';
    }

    return type;
  } catch (error) {
    return null;
  }
};

export default getFileTypeFromUrl;
