export const SHOTSTACK_TTS_VOICES = [
  { label: 'Hala - Arabic', value: 'Hala', languageCode: 'ar-AE' },
  { label: 'Arlet - Catalan', value: 'Arlet', languageCode: 'ca-ES' },
  { label: 'Hiujin - Chinese - Cantonese', value: 'Hiujin', languageCode: 'yue-CN' },
  { label: 'Zhiyu - Chinese - Mandarin', value: 'Zhiyu', languageCode: 'cmn-CN' },
  { label: 'Sofie - Danish', value: 'Sofie', languageCode: 'da-DK' },
  { label: 'Lisa - Belgian', value: 'Lisa', languageCode: 'nl-BE' },
  { label: 'Laura - Dutch', value: 'Laura', languageCode: 'nl-NL' },
  { label: 'Olivia - English - Australian', value: 'Olivia', languageCode: 'en-AU' },
  { label: 'Amy - English - British', value: 'Amy', languageCode: 'en-GB' },
  { label: 'Emma - English - British', value: 'Emma', languageCode: 'en-GB' },
  { label: 'Brian - English - British', value: 'Brian', languageCode: 'en-GB' },
  { label: 'Arthur - English - British', value: 'Arthur', languageCode: 'en-GB' },
  { label: 'Kajal - English - Indian', value: 'Kajal', languageCode: 'en-IN' },
  { label: 'Niamh - English - Ireland', value: 'Niamh', languageCode: 'en-IE' },
  { label: 'Aria - English - New Zealand', value: 'Aria', languageCode: 'en-NZ' },
  { label: 'Ayanda - English - South African', value: 'Ayanda', languageCode: 'en-ZA' },
  { label: 'Ivy - English - US', value: 'Ivy', languageCode: 'en-US' },
  { label: 'Joanna - English - US', value: 'Joanna', languageCode: 'en-US' },
  { label: 'Kendra - English - US', value: 'Kendra', languageCode: 'en-US' },
  { label: 'Kimberly - English - US', value: 'Kimberly', languageCode: 'en-US' },
  { label: 'Salli - English - US', value: 'Salli', languageCode: 'en-US' },
  { label: 'Joey - English - US', value: 'Joey', languageCode: 'en-US' },
  { label: 'Justin - English - US', value: 'Justin', languageCode: 'en-US' },
  { label: 'Kevin - English - US', value: 'Kevin', languageCode: 'en-US' },
  { label: 'Matthew - English - US', value: 'Matthew', languageCode: 'en-US' },
  { label: 'Ruth - English - US', value: 'Ruth', languageCode: 'en-US' },
  { label: 'Stephen - English - US', value: 'Stephen', languageCode: 'en-US' },
  { label: 'Suvi - Finnish', value: 'Suvi', languageCode: 'fi-FI' },
  { label: 'Léa - French', value: 'Léa', languageCode: 'fr-FR' },
  { label: 'Rémi - French', value: 'Rémi', languageCode: 'fr-FR' },
  { label: 'Gabrielle - French - Canadian', value: 'Gabrielle', languageCode: 'fr-CA' },
  { label: 'Liam - French - Canadian', value: 'Liam', languageCode: 'fr-CA' },
  { label: 'Vicki - German', value: 'Vicki', languageCode: 'de-DE' },
  { label: 'Daniel - German', value: 'Daniel', languageCode: 'de-DE' },
  { label: 'Hannah - Austrian', value: 'Hannah', languageCode: 'de-AT' },
  { label: 'Kajal - Hindi', value: 'Kajal', languageCode: 'hi-IN' },
  { label: 'Bianca - Italian', value: 'Bianca', languageCode: 'it-IT' },
  { label: 'Adriano - Italian', value: 'Adriano', languageCode: 'it-IT' },
  { label: 'Takumi - Japanese', value: 'Takumi', languageCode: 'ja-JP' },
  { label: 'Kazuha - Japanese', value: 'Kazuha', languageCode: 'ja-JP' },
  { label: 'Tomoko - Japanese', value: 'Tomoko', languageCode: 'ja-JP' },
  { label: 'Seoyeon - Korean', value: 'Seoyeon', languageCode: 'ko-KR' },
  { label: 'Ida - Norwegian', value: 'Ida', languageCode: 'nb-NO' },
  { label: 'Ola - Polish', value: 'Ola', languageCode: 'pl-PL' },
  { label: 'Camila - Portuguese - Brazilian', value: 'Camila', languageCode: 'pt-BR' },
  { label: 'Vitória - Portuguese - Brazilian', value: 'Vitória', languageCode: 'pt-BR' },
  { label: 'Thiago - Portuguese - Brazilian', value: 'Thiago', languageCode: 'pt-BR' },
  { label: 'Inês - Portuguese - European', value: 'Inês', languageCode: 'pt-PT' },
  { label: 'Lucia - Spanish', value: 'Lucia', languageCode: 'es-ES' },
  { label: 'Sergio - Spanish', value: 'Sergio', languageCode: 'es-ES' },
  { label: 'Mia - Spanish - Mexican', value: 'Mia', languageCode: 'es-MX' },
  { label: 'Andrés - Spanish - Mexican', value: 'Andrés', languageCode: 'es-MX' },
  { label: 'Lupe - Spanish - US', value: 'Lupe', languageCode: 'es-US' },
  { label: 'Pedro - Spanish - US', value: 'Pedro', languageCode: 'es-US' },
  { label: 'Elin - Swedish', value: 'Elin', languageCode: 'sv-SE' },
];

export const SHOTSTACK_TTS_LANGUAGES = [
  { label: 'Arabic', value: 'ar-AE' },
  { label: 'Catalan', value: 'ca-ES' },
  { label: 'Chinese - Mandarin', value: 'cmn-CN' },
  { label: 'Chinese - Cantonese', value: 'yue-CN' },
  { label: 'Danish', value: 'da-DK' },
  { label: 'German', value: 'de-DE' },
  { label: 'German - Austrian', value: 'de-AT' },
  { label: 'English - Australian', value: 'en-AU' },
  { label: 'English - British', value: 'en-GB' },
  { label: 'English - Indian', value: 'en-IN' },
  { label: 'English - Ireland', value: 'en-IE' },
  { label: 'English - New Zealand', value: 'en-NZ' },
  { label: 'English - US', value: 'en-US' },
  { label: 'English - South African', value: 'en-ZA' },
  { label: 'Spanish', value: 'es-ES' },
  { label: 'Spanish - Mexican', value: 'es-MX' },
  { label: 'Spanish - US', value: 'es-US' },
  { label: 'Finnish', value: 'fi-FI' },
  { label: 'French - Canadian', value: 'fr-CA' },
  { label: 'French', value: 'fr-FR' },
  { label: 'Hindi', value: 'hi-IN' },
  { label: 'Italian', value: 'it-IT' },
  { label: 'Japanese', value: 'ja-JP' },
  { label: 'Korean', value: 'ko-KR' },
  { label: 'Norwegian', value: 'nb-NO' },
  { label: 'Dutch - Belgian', value: 'nl-BE' },
  { label: 'Ducth', value: 'nl-NL' },
  { label: 'Polish', value: 'pl-PL' },
  { label: 'Portuguese - Brazilian', value: 'pt-BR' },
  { label: 'Portuguese - European', value: 'pt-PT' },
  { label: 'Swedish', value: 'sv-SE' },
];

export const ELEVENLABS_TTS_VOICES = ['Adam', 'Antoni', 'Arnold', 'Bella', 'Domi', 'Elli', 'Josh', 'Rachel', 'Sam'].map(
  (voice) => ({ label: voice, value: voice })
);
