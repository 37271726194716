export const snippetLangOptions = {
  http: {
    syntax: 'javascript', // looks less broken then bash
    label: 'cURL',
    types: ['blob', 'id'],
  },
  nodesdk: {
    syntax: 'javascript',
    label: 'Node / SDK',
    types: ['id'],
  },
  nodeaxios: {
    syntax: 'javascript',
    label: 'Node / Axios',
    types: ['blob', 'id'],
  },
  pythonsdk: {
    syntax: 'py',
    label: 'Python / SDK',
    types: ['id'],
  },
  phpsdk: {
    syntax: 'php',
    label: 'PHP / SDK',
    types: ['id'],
  },
  rubysdk: {
    syntax: 'rb',
    label: 'Ruby / SDK',
    types: ['id'],
  },
};

export const snippetTypes = {
  blob: 'JSON',
  id: 'Template ID',
};
