import { AxiosInstance, AxiosResponse } from 'axios';

import { startPolling } from '@api/utils/axiosPoll';

type PollConfig = {
  predicate: (response: AxiosResponse) => boolean;
  delayTime?: number;
};

const removePollParam = (url: string): string => {
  return url.replace(/(polling=[^&]*&?)|(&?polling=[^&]*)/, '');
};

const defaultPollPredicate = (response: AxiosResponse) => {
  const { status } = response.data?.body?.data || {};
  return !status || ['done', 'ready', 'failed'].includes(status);
};

const workflowTaskPollPredicate = (response: AxiosResponse) => {
  const tasks = response.data?.body?.data || [];
  const statuses = tasks.map(({ status }: { status: string }) => status);
  return statuses.every((s: string) => ['done', 'ready', 'failed'].includes(s));
};

const renditionsPollPredicate = (response: AxiosResponse) => {
  const {
    status: fileStatus,
    outputs: { renditions, transcription },
  } = response.data?.body?.data;

  const statuses = [
    fileStatus,
    ...(renditions?.map(({ status }: { status: string }) => status) || []),
    transcription?.status,
  ].filter(Boolean);

  return statuses.every((s) => ['ready', 'failed'].includes(s));
};

const pollConfigMap = new Map<string, PollConfig>([
  ['ingest/renditions', { predicate: renditionsPollPredicate, delayTime: 5000 }],
  ['workflows/tasks', { predicate: workflowTaskPollPredicate }],
  ['edit/render', { predicate: defaultPollPredicate, delayTime: 1500 }],
]);

const getPollConfig = (dataKey: string): PollConfig => {
  return pollConfigMap.get(dataKey) || { predicate: defaultPollPredicate };
};

const responsePollingInterceptors = (client: AxiosInstance) => [
  async (response: AxiosResponse) => {
    if (!response.request) return response;

    try {
      const { searchParams } = new URL(response.request.responseURL);

      if (!searchParams.has('polling')) return response;

      const dataKey = searchParams.get('polling') as string;
      const { predicate, delayTime } = getPollConfig(dataKey);
      const url = removePollParam(response.config?.url ?? '');

      return startPolling(client, url, predicate, dataKey, delayTime);
    } catch (error) {
      console.error(error);
      return response;
    }
  },
  (error: any) => Promise.reject(error),
];

export default responsePollingInterceptors;
