export const pythonsdk = ({ id, stage, key, merge = [] }) => {
  let mergeFields = '';
  const mergeSetters = [];

  merge.forEach((field, index) => {
    mergeFields += `
  mergeField${index + 1} = MergeField(
    find = "${field.find}",
    replace = "${field.replace}"
  )`;
    mergeSetters.push(`mergeField${index + 1}`);
  });

  return `import shotstack_sdk as shotstack; # Install the Shotstack SDK using \`pip install shotstack-sdk\`

import os
import sys

from shotstack_sdk.api import edit_api
from shotstack_sdk.model.template_render import TemplateRender
from shotstack_sdk.model.merge_field import MergeField

configuration = shotstack.Configuration(host = "https://api.shotstack.io/${stage}")

configuration.api_key['DeveloperKey'] = "${key}"

with shotstack.ApiClient(configuration) as api_client:
  api_instance = edit_api.EditApi(api_client)
  ${mergeFields}

  template = TemplateRender(
    id = "${id}",
    ${mergeSetters.length ? `merge = [${mergeSetters.join(', ')}]` : ''}
  )

try:
  api_response = api_instance.post_template_render(template)
  message = api_response['response']['message']
  id = api_response['response']['id']
  print(f"{message}")
except Exception as e:
  print(f"Unable to resolve API call: {e}")
`;
};
