import { Card } from 'react-bootstrap';

import AlertBox from '@components/alert/AlertBox';
import AssetDetailsPreview from '@components/asset/AssetDetailsPreview';
import StatusBadge from '@components/status/StatusBadge';

import formatFileSize from '@utils/formatFileSize';

const getAssetDetails = (asset) => {
  const { id, status, service, provider, providerId, url, filesize } = asset || {};

  const details = Object.entries({
    'Asset ID': id,
    Status: <StatusBadge status={status || 'loading'} />,
    URL: url && (
      <a href={url} title={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    ),
    'Shotstack Service': service,
    Provider: provider,
    'Provider ID': providerId,
    'File Size': filesize > 0 && (
      <>
        {formatFileSize(filesize, 'value')}
        {formatFileSize(filesize, 'suffix')}
      </>
    ),
  })
    .filter(([, value]) => value)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  return details;
};

function RenderDetailsAsset({ asset }) {
  const { url, error } = asset || {};

  const assetDetails = getAssetDetails(asset);

  return (
    <Card>
      <Card.Body>
        {error && <AlertBox type="error" message={error} className="mb-3" />}
        <AssetDetailsPreview url={url} />
        <table className="metadata mb-4">
          <tbody>
            {Object.entries(assetDetails).map(([key, value]) => (
              <tr key={key}>
                <td className="metadata__key">{key}</td>
                <td className="metadata__value">{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card.Body>
    </Card>
  );
}

export default RenderDetailsAsset;
