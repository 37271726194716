import AssetSourceInput from '@components/controls/AssetSourceInput';

import { useAddFontState } from '@store/Edit';

function SettingFont({ id }) {
  const addFont = useAddFontState();

  const handleUploadComplete = ({ source: src }) => addFont({ src });

  return (
    <AssetSourceInput
      key={id}
      id={id}
      linkUploadEnabled={false}
      onUploadComplete={handleUploadComplete}
      type="font"
      accepts=".ttf,.otf"
      overrideEnabled={false}
    />
  );
}

export default SettingFont;
