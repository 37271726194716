import cn from 'classnames';

import { IconArrowReturnRight } from '@assets/icons';

import WorkflowTaskDeleteButton from '@feature/workflows/buttons/WorkflowTaskDeleteButton';
import WorkflowTaskBlock from '@feature/workflows/task/WorkflowTaskBlock';

import { MODULE_ATTRIBUTES, getModuleCategoryGroup } from '@constants/Workflows';

function Action({ type, module, name, outputs, connected, active, handleAddTask, handleEditTask, handleRemoveTask }) {
  const { category, outputKey } = MODULE_ATTRIBUTES[module] || {};
  const categoryGroup = getModuleCategoryGroup(category);
  const output = outputs[outputKey];

  return (
    <WorkflowTaskBlock
      type={type}
      categoryGroup={categoryGroup}
      active={active}
      connected={connected}
      onClick={handleEditTask}
      onAddTask={handleAddTask}
    >
      <WorkflowTaskBlock.Header
        type={type}
        module={module}
        actions={<WorkflowTaskDeleteButton handleDeleteTask={handleRemoveTask}>Remove</WorkflowTaskDeleteButton>}
      >
        {name || 'Select an Action'}
      </WorkflowTaskBlock.Header>
      <WorkflowTaskBlock.Footer>
        {!module && <code>An action is run after the workflow starts</code>}
        {module && (
          <>
            <IconArrowReturnRight size={18} />
            <span
              className={cn('workflow-tasks__output-key', {
                warning: !output,
              })}
            >
              {output || 'Not Set'}
            </span>
          </>
        )}
      </WorkflowTaskBlock.Footer>
    </WorkflowTaskBlock>
  );
}

export default Action;
