import { useCallback, useRef, useState } from 'react';

const useVideoHover = () => {
  const videoRef = useRef(null);
  const timeoutId = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleMouseEnter = useCallback(() => {
    timeoutId.current = setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play();
        setIsPlaying(true);
      }
    }, 300);
  }, []);

  const handleMouseLeave = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      videoRef.current.load();
      setIsPlaying(false);
    }
  }, []);

  return { videoRef, handleMouseEnter, handleMouseLeave, isPlaying };
};

export default useVideoHover;
