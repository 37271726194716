import { Card, Image, Row } from 'react-bootstrap';

import ImageModal from '@components/modal/ImageModal';

function RenderPreviewImage({ render }) {
  return (
    <ImageModal renderId={render.id} renderUrl={render.url}>
      {(showModal) => (
        <Card className="mb-2 editor-thumbnail-container" onClick={() => showModal(true)}>
          <Row className="editor-thumbnail d-flex justify-content-center align-items-center">
            <Image src={render.url} />
          </Row>
        </Card>
      )}
    </ImageModal>
  );
}

export default RenderPreviewImage;
