export const rubysdk = ({ id, stage, key, merge = [] }) => {
  let mergeFields = '';
  const mergeSetters = [];

  merge.forEach((field, index) => {
    mergeFields += `
mergeField${index + 1} = Shotstack::MergeField.new(find: "${field.find}", replace: "${field.replace}")
`;

    mergeSetters.push(`mergeField${index + 1}`);
  });

  return `require "shotstack" # Install the Shotstack SDK using: \`gem install shotstack\`

Shotstack.configure do |config|
  config.api_key['x-api-key'] = "${key}"
  config.host = "https://api.shotstack.io"
  config.base_path = "${stage}"
end

api_client = Shotstack::EditApi.new
${mergeFields}
template = Shotstack::TemplateRender.new(
  id: "${id}",${mergeSetters.length ? `\n  merge: [${mergeSetters.join(', ')}]` : ''}
)

begin
  response = api_client.post_template_render(template).response
rescue => error
  abort("Request failed: #{error.message}")
end

puts response.message
`;
};
