import _ from 'lodash-es';
import { memo, useRef } from 'react';
import { Dropdown, NavLink, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Scrollbar } from 'react-scrollbars-custom';
import { useRecoilState, useRecoilValue } from 'recoil';

import '@css/OverrideMenu.css';

import { IconOverride, IconOverriden } from '@assets/icons';

import OverrideMenuItem from '@components/mergeFields/OverrideMenuItem';
import OverrideMenuToggle from '@components/mergeFields/OverrideMenuToggle';

import { activeClipAtom } from '@store/Clips';
import { overridesAtomFamily } from '@store/Edit';
import { clipSettingsSelectorFamily } from '@store/EditSelectors';
import { mergeFieldKeysSelector, mergeIdsAtom, useAddMergeField } from '@store/Merge';
import { sdkOptionsSelector } from '@store/Sdk';

import AssetFields from '@constants/AssetFields';

import { formatMergeReplaceToAssetType } from '@utils/merge';

const generateMergeFieldKey = ({ path, assetType, mergeFieldKeys }) => {
  if (!path || !_.isString(path)) return 'PROPERTY';

  const parts = [
    assetType ? `${assetType.toUpperCase()}_` : '',
    _.chain(path).replace('asset:', '').replace(/:/g, '_').toUpper().value(),
  ];

  const baseLabel = parts.join('');
  const existingLabels = _.filter(mergeFieldKeys, (key) => _.startsWith(key, baseLabel));
  return existingLabels.length === 0 ? baseLabel : `${baseLabel}_${existingLabels.length + 1}`;
};

function OverrideMenu({ path, isButton = false, isAnimated = false }) {
  const clipId = useRecoilValue(activeClipAtom);
  const { isSdkEnabled } = useRecoilValue(sdkOptionsSelector);

  const [overrides, setOverrides] = useRecoilState(overridesAtomFamily(clipId));
  const [clip, setClip] = useRecoilState(clipSettingsSelectorFamily(clipId));
  const mergeFieldsIds = useRecoilValue(mergeIdsAtom);
  const mergeFieldKeys = useRecoilValue(mergeFieldKeysSelector);
  const addMergeField = useAddMergeField();

  const dropdownToggleRef = useRef();
  const clipAssetFields = AssetFields[clip['asset:type']] || {};
  const activeMergeKey = overrides[path];
  const mergeFieldKeyForFind = generateMergeFieldKey({ assetType: clip['asset:type'], path, mergeFieldKeys });

  const handleCreateAndSelectMergeField = () => {
    const value = clip[path];
    const mergeField = { find: mergeFieldKeyForFind, replace: value };
    addMergeField(mergeField);
    handleSelectOverride(mergeField);
  };

  const handleSelectOverride = (mergeField) => {
    const { find: key, replace: value } = mergeField;
    setOverrides((prevState) => ({ ...prevState, [path]: key }));

    const assetFieldsType = AssetFields[clip['asset:type']][path];
    const update = { [path]: formatMergeReplaceToAssetType(assetFieldsType, value) };

    setClip(update);
  };

  const handleClearOverride = () => {
    setOverrides((prevState) => {
      const { [path]: omit, ...rest } = prevState;
      return rest;
    });
  };

  if (isSdkEnabled) {
    return null;
  }

  if (isAnimated || !clipAssetFields[path]) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={`tooltip-${path}`}>
            {isAnimated
              ? 'This property cannot be merged while being animated'
              : 'This property can be merged only in JSON'}
          </Tooltip>
        }
      >
        <span>
          <IconOverride size={12} style={{ cursor: 'help', opacity: 0.6 }} />
        </span>
      </OverlayTrigger>
    );
  }

  return (
    <Dropdown className="overrideMenu">
      <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${path}`}>Select Merge Field</Tooltip>}>
        <Dropdown.Toggle as={NavLink} align="end" ref={dropdownToggleRef}>
          <OverrideMenuToggle
            path={path}
            isButton={isButton}
            isActive={activeMergeKey}
            Icon={IconOverride}
            IconActive={IconOverriden}
          />
        </Dropdown.Toggle>
      </OverlayTrigger>

      <Dropdown.Menu align="end">
        <Scrollbar className="overrideMenu__scrollbar" noScrollX>
          <Dropdown.Header>Merge Fields</Dropdown.Header>
          <Dropdown.Item onClick={handleCreateAndSelectMergeField}>+ Create &amp; Select</Dropdown.Item>
          {mergeFieldKeys.length > 0 && (
            <>
              <Dropdown.Divider />
              {mergeFieldsIds.map((id) => (
                <OverrideMenuItem
                  key={id}
                  id={id}
                  activeMergeKey={activeMergeKey}
                  handleSelectOverride={handleSelectOverride}
                />
              ))}
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleClearOverride}>Clear</Dropdown.Item>
            </>
          )}
        </Scrollbar>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default memo(OverrideMenu);
