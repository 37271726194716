import { debounce } from 'lodash-es';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from 'recoil';

import { IconReplace } from '@assets/icons';

import MediaListingPreview from '@components/asset/MediaListingPreview';
import ButtonElement from '@components/atoms/ButtonElement';

import { activeClipAtom } from '@store/Clips';
import { clipSettingsSelectorFamily } from '@store/EditSelectors';
import { mediaLoadingAtom, mediaMetaAtom, mediaSelectorFamily, useFetchMediaCallback } from '@store/Media';

function AssetSelector({ type, onSelect }) {
  const mediaList = useRecoilValue(mediaSelectorFamily(type));
  const mediaMeta = useRecoilValue(mediaMetaAtom);
  const mediaLoading = useRecoilValue(mediaLoadingAtom);
  const fetchMedia = useFetchMediaCallback();
  const endRef = useRef(null);

  const observerCallback = useCallback(
    debounce((entries) => {
      const [entry] = entries;
      if (entry?.isIntersecting && !mediaLoading && mediaMeta.next !== null) {
        fetchMedia(mediaMeta);
      }
    }, 500),
    [mediaLoading, fetchMedia, mediaMeta]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, { threshold: 1 });
    if (endRef.current) observer.observe(endRef.current);

    return () => {
      if (endRef.current) observer.unobserve(endRef.current);
    };
  }, [observerCallback]);

  return (
    <div className="media-listing">
      <div className="media-listing__container">
        {(mediaList?.assets || []).map((media) => (
          <MediaListingPreview key={media.id} media={media} onClick={() => onSelect(media)} />
        ))}
      </div>

      {!mediaList?.length && !mediaLoading && (
        <div className="text-center">
          <small>No Items</small>
        </div>
      )}

      {mediaLoading && (
        <div className="text-center">
          <small>Loading...</small>
        </div>
      )}

      <div ref={endRef} style={{ height: '20px' }} />
    </div>
  );
}

function ReplaceAssetModal({ path }) {
  const [showModal, setShowModal] = useState(false);
  const clipId = useRecoilValue(activeClipAtom);
  const [clip, setClip] = useRecoilState(clipSettingsSelectorFamily(clipId));

  const handleClose = () => setShowModal(false);

  const handleShow = () => setShowModal(true);

  const handleSelect = (asset) => {
    setClip({
      [path]: asset.proxy.url,
      'asset:meta': {
        proxied: true,
        source: asset.source,
        width: asset.proxy.width,
        height: asset.proxy.height,
        proxyScaleMultiplier: 1,
      },
    });
    handleClose();
  };

  return (
    <>
      <ButtonElement handleEvent={handleShow} variant="light" title="Replace Asset">
        <IconReplace size={20} />
      </ButtonElement>

      <Modal show={showModal} onHide={handleClose} size="lg" className="replace-asset-modal">
        <Modal.Header closeButton>
          <Modal.Title>Replace Asset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AssetSelector type={clip['asset:type']} onSelect={handleSelect} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default memo(ReplaceAssetModal);
