import { debounce } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ButtonGroup, Overlay, Popover } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';

import ButtonElement from '@components/atoms/ButtonElement';
import SaveToStage from '@components/stageSelect/SaveToStage';

import { workspaceTabAtom } from '@store/Edit';
import {
  templateJsonEditAtom,
  templateReadyAtom,
  templateSavingAtom,
  templateSelector,
  useSaveTemplate,
} from '@store/Template';

function TemplateSave() {
  const documentRef = useRef(document);
  const saving = useRecoilValue(templateSavingAtom);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const templateReady = useRecoilValue(templateReadyAtom);
  const templateJsonReady = useRecoilValue(templateJsonEditAtom);
  const workspaceTab = useRecoilValue(workspaceTabAtom);
  const templateData = useRecoilValue(templateSelector);
  const saveTemplate = useSaveTemplate();

  const saveDisabled = useMemo(
    () => !templateReady || !templateJsonReady || workspaceTab !== 'design',
    [templateReady, templateJsonReady, workspaceTab]
  );

  const debouncedSaveTemplate = useMemo(() => {
    const options = { maxWait: 3000, leading: false, trailing: true };
    return debounce(saveTemplate, 1500, options);
  }, [saveTemplate]);

  useEffect(() => {
    if (!saveDisabled) debouncedSaveTemplate();
    return () => debouncedSaveTemplate.cancel();
  }, [templateData]);

  const tracking = {
    name: 'Select Save Template',
    properties: {
      Id: templateData.id,
      'Template Name': templateData.name,
    },
  };

  const handleSaveMenu = (event) => {
    setShow(!show);
    setTarget(show ? null : event.target);
  };

  return (
    <>
      <ButtonGroup>
        <ButtonElement
          handleEvent={() => saveTemplate()}
          variant="light"
          loading={saving}
          tracking={tracking}
          style={{ minWidth: 75 }}
          disabled={saveDisabled}
        >
          Save
        </ButtonElement>

        <ButtonElement
          title="Save Menu"
          handleEvent={handleSaveMenu}
          className="btn-arrow"
          tracking={{ ...tracking, name: 'Select Save Template Menu' }}
          variant="light"
          type="button"
          disabled={saveDisabled}
        />
      </ButtonGroup>

      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={documentRef.current?.body}
        containerPadding={20}
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover className="popover-controls popover-preview-settings">
          <Popover.Body>
            <div className="mb-3">
              <strong>Save and copy to...</strong>
            </div>
            <SaveToStage />
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
}

export default TemplateSave;
