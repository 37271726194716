import { useRecoilValue } from 'recoil';

import WorkflowTaskAction from '@feature/workflows/task/WorkflowTaskAction';
import WorkflowTaskEnd from '@feature/workflows/task/WorkflowTaskEnd';
import WorkflowTaskTrigger from '@feature/workflows/task/WorkflowTaskTrigger';

import { taskSelectorFamily, useAddTask, useEditTask, useRemoveTask } from '@store/Workflows';

const TaskComponentMap = {
  action: WorkflowTaskAction,
  end: WorkflowTaskEnd,
  trigger: WorkflowTaskTrigger,
};

function WorkflowTask({ id }) {
  const task = useRecoilValue(taskSelectorFamily(id));
  const addTask = useAddTask();
  const editTask = useEditTask();
  const removeTask = useRemoveTask();

  const handleAddTask = () => addTask(id);
  const handleEditTask = () => editTask(id);
  const handleRemoveTask = () => removeTask(id);

  const TaskComponent = TaskComponentMap[task?.type] || null;
  if (!TaskComponent) {
    return null;
  }

  return (
    <TaskComponent
      {...task}
      handleAddTask={handleAddTask}
      handleEditTask={handleEditTask}
      handleRemoveTask={handleRemoveTask}
    />
  );
}

export default WorkflowTask;
