import axios from 'axios';
import { omit, pick } from 'lodash-es';

import { convertCSSToJSON, convertHTMLToJSON, convertJSONToHTML } from '@utils/editor/htmlPropertyConversion';
import get720pResolutionDynamic from '@utils/get720pResolutionDynamic';
import { isNumber } from '@utils/isNumber';
import { flattenToColonSeperatedProperties } from '@utils/properties';

const defaultSourceRegex = /\/source\.\w+$/;
const overlaySourceRegex = /\/([^/]+)\/source\.(mov|qt)$/;

const getAssetVolume = (asset) => {
  if (isNumber(asset?.volume) && asset.volume >= 0 && asset.volume <= 1) {
    return asset.volume;
  }
  if (typeof asset?.volume === 'string' && asset.volume.trim().startsWith('{{')) {
    return asset.volume;
  }
  return 1;
};

const checkFileExists = async (url) => {
  try {
    const { status } = await axios.head(url);
    return status === 200;
  } catch (error) {
    console.warn(`${url} not accessible`, error.toJSON());
    return false;
  }
};

const getFirstExistingUrl = async (urls) => {
  const results = await Promise.all(urls.map((url) => checkFileExists(url)));
  const found = results.indexOf(true);
  return urls[found] || null;
};

const transformOverlay = async (asset) => {
  const newAsset = { ...asset };
  const overlayRenditionSrc = asset.src.replace('-sources', '-renditions');
  const overlaySrcDeprecated = overlayRenditionSrc.replace(overlaySourceRegex, '/$1/overlay.webm');
  const overlaySrcNew = overlayRenditionSrc.replace(overlaySourceRegex, '/$1/shotstack-overlay.webm');
  const overlaySrc = await getFirstExistingUrl([overlaySrcNew, overlaySrcDeprecated]);
  const proxied =
    asset.src.includes('ingest-api') || asset.src.includes('templates.shotstack.io') ? Boolean(overlaySrc) : false;

  newAsset.type = 'overlay';
  newAsset.meta = {
    ...(asset.meta || {}),
    type: 'video',
    source: asset.src,
    proxied,
  };

  if (proxied) {
    newAsset.src = overlaySrc;
  }
  return newAsset;
};

const transformVideo = async (asset) => {
  const newAsset = { ...asset };
  const proxySrc = asset.src.replace('-sources', '-renditions').replace(defaultSourceRegex, '/shotstack-proxy.mp4');
  const proxied = asset.src.includes('ingest-api') ? await checkFileExists(proxySrc) : false;

  newAsset.volume = getAssetVolume(asset);

  newAsset.meta = {
    ...(asset.meta || {}),
    source: asset.src,
    proxied,
  };

  if (proxied) {
    newAsset.src = proxySrc;
  }

  return newAsset;
};

const transformVideoIncoming = async (asset) => {
  if (!asset || !asset.src) {
    return asset;
  }
  const isOverlayVideo = asset.src.match(overlaySourceRegex)?.length;
  if (isOverlayVideo) {
    return transformOverlay(asset);
  }
  return transformVideo(asset);
};

const transformLumaIncoming = async (asset) => {
  const newAsset = { ...asset, type: 'mask' };
  const maskRenditionSrc = asset.src.replace('-sources', '-renditions');
  // I'm sorry, FML. Lumas can be images and video, idk, idc.
  const maskSrcWebmDeprecated = maskRenditionSrc.replace(defaultSourceRegex, '/shotstack-proxy.webm');
  const maskSrcWebmNew = maskRenditionSrc.replace(defaultSourceRegex, '/shotstack-mask.webm');
  const maskSrcPng = maskRenditionSrc.replace(defaultSourceRegex, '/shotstack-mask.png');
  const maskSrc = await getFirstExistingUrl([maskSrcWebmNew, maskSrcWebmDeprecated, maskSrcPng]);
  const proxied = asset.src.includes('ingest-api') ? Boolean(maskSrc) : false;

  newAsset.meta = {
    ...(asset.meta || {}),
    source: asset.src,
    type: 'luma',
    proxied,
  };

  if (proxied) {
    newAsset.src = maskSrc;
  }
  return newAsset;
};

const transformImageIncoming = async (asset) => {
  const newAsset = { ...asset };
  const proxySrcPng = asset.src.replace('-sources', '-renditions').replace(defaultSourceRegex, '/shotstack-proxy.png');
  const proxySrcWebp = asset.src
    .replace('-sources', '-renditions')
    .replace(defaultSourceRegex, '/shotstack-proxy.webp');
  const proxySrc = await getFirstExistingUrl([proxySrcWebp, proxySrcPng]);
  const proxied = asset.src.includes('ingest-api') ? !!proxySrc : false;

  newAsset.meta = {
    ...(asset.meta || {}),
    source: asset.src,
    proxied,
  };

  if (proxied) {
    newAsset.src = proxySrc;
  }
  return newAsset;
};

const cssTransformMap = {
  fontSize: (value) => {
    try {
      const fontSize = parseInt(value, 10);
      if (!isNumber(fontSize)) {
        return value;
      }
      return String(fontSize);
    } catch {
      return value;
    }
  },
  fontFamily: (value) => {
    return value.replace(/'|\"/g, '');
  },
  default: (value) => value,
};

const transformHTMLIncoming = (asset) => {
  const { css, html, ...newAsset } = asset || {};

  if (!html || !html.match(/data-html-type=['"]text['"]/)) {
    return { ...asset, meta: { unsupported: true } };
  }

  try {
    const htmlProps = convertHTMLToJSON(html);
    const cssProps = Object.entries(convertCSSToJSON(css)).reduce((acc, [key, value]) => {
      const transform = cssTransformMap[key] || cssTransformMap.default;
      acc[key] = transform(value);
      return acc;
    }, {});
    return { ...newAsset, ...htmlProps, ...cssProps, meta: { ...htmlProps } };
  } catch (e) {
    console.error('transformHTMLIncoming', e);
    return asset;
  }
};

const transformTextIncoming = (asset) => {
  const transformedAsset = flattenToColonSeperatedProperties(asset);
  return { ...transformedAsset, meta: { text: asset?.text } };
};

const transformAudioIncoming = (asset) => {
  const newAsset = { ...asset };
  newAsset.volume = getAssetVolume(asset);
  return newAsset;
};

function timeStringToSeconds(timeString) {
  try {
    const [hours, minutes, secondsAndMilliseconds] = timeString.split(':');
    const [seconds, milliseconds] = secondsAndMilliseconds.split(',');
    return parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseFloat(seconds) + parseInt(milliseconds || 0) / 1000;
  } catch (e) {
    console.error('timeStringToSeconds', e);
    return 0;
  }
}

function parseSrt(content) {
  return content.split('\n\n').reduce((captions, entry) => {
    const lines = entry.split('\n');
    if (lines.length < 2) return captions;

    const lineNumberExists = lines[0].trim().match(/^\d+$/);
    const [timeString, textStartIndex] = lineNumberExists ? [lines[1], 2] : [lines[0], 1];

    const text = lines.slice(textStartIndex).join('\n').trimEnd();
    const [startTime, endTime] = timeString.split(' --> ');
    const start = timeStringToSeconds(startTime);
    const length = timeStringToSeconds(endTime) - start;

    captions.push({ start, length, text });
    return captions;
  }, []);
}

const transformCaptionIncoming = async (asset, mergeData) => {
  let src = asset?.src || '';

  if (!src || asset?.placeholder || src.trim().startsWith('alias://')) {
    const placeholder = `This is a placeholder for the captions`;
    return {
      ...asset,
      src,
      meta: {
        placeholder: true,
        source: asset?.src || '',
        content: placeholder,
        captions: [{ start: 0, length: 7, text: placeholder }],
        duration: 7,
      },
    };
  }

  if (src.trim().startsWith('{{')) {
    const key = (asset.src || '').replace(/{{\s*([^}\s]+)\s*}}/, '$1');
    src = (mergeData || []).find((field) => field.find === key)?.replace || '';
  }

  try {
    if (!src) {
      throw new Error('No src found');
    }

    const response = await axios.get(src);
    const content = response.data;
    const captions = parseSrt(content);
    const lastCaption = captions[captions.length - 1];
    const duration = lastCaption ? lastCaption.start + lastCaption.length : 0;
    return { ...asset, src, meta: { source: asset.src, content, captions, duration } };
  } catch (error) {
    console.error('Error fetching SRT file:', error);
    return { ...asset, error: 'Failed to fetch SRT file' };
  }
};

const transformTextToSpeechIncoming = (asset) => {
  return { ...asset, meta: { text: asset.text || '' } };
};

const transformTextToImageIncoming = (asset) => {
  return { ...asset, meta: { text: asset.prompt || '' } };
};

const transformImageToVideoIncoming = (asset) => {
  const { width, height } = get720pResolutionDynamic(asset.aspectRatio);
  return { ...asset, meta: { text: asset.prompt || '', placeholder: !asset?.src, width, height } };
};

const transformShapeIncoming = (asset) => {
  const { shape, stroke } = asset;
  const strokeWidth = parseInt(stroke?.width, 10) || 0;

  let dimensions = {};
  if (shape === 'rectangle') {
    const assetWidth = asset.rectangle?.width || asset.width || 0;
    const assetHeight = asset.rectangle?.height || asset.height || 0;
    dimensions = {
      width: assetWidth,
      height: assetHeight,
      rectangle: {
        ...asset.rectangle,
        width: Math.max(0, assetWidth - strokeWidth * 2),
        height: Math.max(0, assetHeight - strokeWidth * 2),
      },
    };
  } else if (shape === 'circle') {
    let diameter;
    if (asset.circle?.radius) {
      const radius = asset.circle.radius;
      diameter = (radius + strokeWidth / 2) * 2;
    } else if (asset.width) {
      diameter = asset.width;
    } else {
      diameter = 0;
    }
    dimensions = {
      width: diameter + strokeWidth / 2,
      height: diameter + strokeWidth / 2,
      circle: {
        radius: Math.max(0, (diameter - strokeWidth / 2) / 2),
      },
    };
  } else if (shape === 'line') {
    dimensions = {
      width: asset.line?.length || 0,
      height: asset.line?.thickness || 0,
      line: {
        length: asset.line?.length || 0,
        thickness: asset.line?.thickness || 0,
      },
    };
  }

  return { ...asset, ...dimensions };
};

const transformAssetIncomingMap = {
  image: transformImageIncoming,
  video: transformVideoIncoming,
  luma: transformLumaIncoming,
  mask: transformLumaIncoming,
  html: transformHTMLIncoming,
  text: transformTextIncoming,
  title: transformTextIncoming,
  audio: transformAudioIncoming,
  caption: transformCaptionIncoming,
  shape: transformShapeIncoming,
  'text-to-speech': transformTextToSpeechIncoming,
  'text-to-image': transformTextToImageIncoming,
  'image-to-video': transformImageToVideoIncoming,
  default: (asset) => asset,
};

export const transformAssetIncoming = (asset, mergeData) => {
  const transformAsset = transformAssetIncomingMap[asset?.type] || transformAssetIncomingMap.default;
  const transformedAsset = transformAsset(asset, mergeData);
  return transformedAsset;
};

const transformHTMLOutgoing = (asset) => {
  const baseHtmlAssetProps = pick(asset, ['type', 'width', 'height', 'background', 'position']);
  const htmlCssProps = omit(asset, ['id', 'type', 'width', 'height', 'background', 'position', 'meta', 'text']);

  const { html, css } = convertJSONToHTML({ ...htmlCssProps, text: asset?.meta?.text });

  return { ...baseHtmlAssetProps, html, css };
};

const typeTransforms = {
  html: (asset) => transformHTMLOutgoing(asset),
  title: (asset) => ({ ...asset, text: asset?.meta?.text }),
  'text-to-image': (asset) => ({ ...asset, prompt: asset?.meta?.text || '' }),
  'image-to-video': (asset) => ({ ...asset, prompt: asset?.meta?.text || '' }),
  'text-to-speech': (asset) => ({ ...asset, text: asset?.meta?.text || '' }),
  text: (asset) => ({ ...asset, text: asset.meta?.text }),
  mask: (asset) => ({ ...asset, type: 'luma' }),
  video: (asset) => {
    const newAsset = { ...asset };
    if (newAsset.chromaKey && !newAsset.chromaKey?.color) delete newAsset.chromaKey;
    return newAsset;
  },
  overlay: (asset) => ({ ...asset, type: 'video' }),
};

export const transformAssetOutgoing = (asset) => {
  if (asset?.meta?.unsupported) return asset;

  const transform = typeTransforms[asset?.type];
  const transformedAsset = transform ? transform(asset) : asset;

  if (asset?.meta?.proxied) transformedAsset.src = asset?.meta?.source;

  return transformedAsset;
};
