import { useLocation, useParams } from 'react-router-dom';

import ApisOverview from '@pages/Overview/OverviewApis';
import StudioOverview from '@pages/Overview/OverviewStudio';
import WorkflowsOverview from '@pages/Overview/OverviewWorkflows';

import Layout from '@components/layout/Layout';

const OverviewComponents = {
  studio: StudioOverview,
  workflows: WorkflowsOverview,
  apis: ApisOverview,
  default: () => null,
};

function Overview() {
  const { product } = useParams();
  const location = useLocation();
  const [, basePath] = location.pathname.split('/'); // Extracts 'studio', 'workflows', or 'apis' from the path
  const OverviewComponent = OverviewComponents[basePath] || OverviewComponents.default;

  return (
    <Layout>
      <OverviewComponent product={product} />
    </Layout>
  );
}

export default Overview;
