import integrationClient from '@api/clients/integration';
import { transformIntegrationAxios } from '@api/transform/integration';

import IntegrationProviders from '@constants/IntegrationProviders';

const integrationProviders = IntegrationProviders.filter(({ slug }) => slug !== 'youtube')
  .map(({ provider, apiKeys }) => apiKeys && provider)
  .filter(Boolean);

export const getIntegration = async (integrationId) => {
  const requestConfig = {
    transformResponse: transformIntegrationAxios,
  };
  const { data } = await integrationClient.get(`integration/${integrationId}`, requestConfig);
  return data?.body?.data;
};

export const getAllIntegrations = async () => {
  const response = await Promise.all(integrationProviders.map((integrationId) => getIntegration(integrationId)));
  const integratedData = response.reduce((acc, data, index) => {
    const integrationId = integrationProviders[index];
    acc[integrationId] = data;
    return acc;
  }, {});
  return integratedData;
};
