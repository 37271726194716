import cn from 'classnames';
import { memo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { IconDragVertical, IconExclamationTriangle } from '@assets/icons';

import JsonEditor from '@feature/studio/editor/Editor';
import PanelAIDefault from '@feature/studio/panel/PanelAIDefault';
import PanelCaptionDefault from '@feature/studio/panel/PanelCaptionDefault';
import PanelClipProperties from '@feature/studio/panel/PanelClipProperties';
import PanelElementsDefault from '@feature/studio/panel/PanelElementsDefault';
import PanelMaskDefault from '@feature/studio/panel/PanelMaskDefault';
import PanelMediaDefault from '@feature/studio/panel/PanelMediaDefault';
import PanelOverlayDefault from '@feature/studio/panel/PanelOverlayDefault';
import PanelSettings from '@feature/studio/panel/PanelSettings';
import PanelShapeDefault from '@feature/studio/panel/PanelShapeDefault';
import PanelTextDefault from '@feature/studio/panel/PanelTextDefault';
import SettingDestinations from '@feature/studio/setting/SettingDestinations';

import EmptyState from '@components/atoms/EmptyState';

import { clipSettingsSelectorFamily } from '@store/EditSelectors';
import { studioActiveTabHoverAtom } from '@store/UI';

import { getPanelType } from '@utils/studio/sidebar';

const panels = {
  text: {
    default: PanelTextDefault,
    edit: PanelClipProperties,
  },
  shape: {
    default: PanelShapeDefault,
    edit: PanelClipProperties,
  },
  media: {
    default: PanelMediaDefault,
    edit: PanelClipProperties,
  },
  overlay: {
    default: PanelOverlayDefault,
    edit: PanelClipProperties,
  },
  mask: {
    default: PanelMaskDefault,
    edit: PanelClipProperties,
  },
  elements: {
    default: PanelElementsDefault,
    edit: PanelClipProperties,
  },
  caption: {
    default: PanelCaptionDefault,
    edit: PanelClipProperties,
  },
  ai: {
    default: PanelAIDefault,
  },
  'text-to-speech': {
    default: PanelAIDefault,
    edit: PanelClipProperties,
  },
  'text-to-image': {
    default: PanelAIDefault,
    edit: PanelClipProperties,
  },
  'image-to-video': {
    default: PanelAIDefault,
    edit: PanelClipProperties,
  },
  integrate: {
    default: SettingDestinations,
  },
  setting: {
    default: PanelSettings,
  },
  json: {
    default: JsonEditor,
  },
};

function WorkspaceSidebarPanel({ activeClipId, activeTab, width, getRef }) {
  const clip = useRecoilValue(clipSettingsSelectorFamily(activeClipId));
  const [activeTabHover, setActiveTabHover] = useRecoilState(studioActiveTabHoverAtom);

  const panelType = getPanelType(clip['asset:type']);
  const activePanel = panelType || activeTab || activeTabHover;
  const panelConfig = panels[activePanel];

  let PanelComponent;
  if (panelConfig) {
    if (activeClipId && !activeTabHover) {
      PanelComponent = panelConfig.edit;
    } else {
      PanelComponent = panelConfig.default;
    }
  }

  if (clip['asset:type'] === 'title' || clip?.meta?.unsupported) {
    return (
      <EmptyState Icon={IconExclamationTriangle}>
        <h5>Unsupported clip type</h5>
        <p>Clip type is not supported in the editor.</p>
      </EmptyState>
    );
  }

  return (
    <>
      {activeTabHover && <div className="workspace__panel-scrim" onMouseEnter={() => setActiveTabHover(null)} />}
      <div
        style={{ width }}
        className={cn('workspace__panel', clip['asset:type'], activeTab, {
          static: activeTab,
          floating: activeTabHover,
        })}
      >
        {getRef && (
          <div ref={getRef} className="sidebar__resize-handle">
            <div className="sidebar__resize-notch">
              <IconDragVertical />
            </div>
          </div>
        )}
        {PanelComponent && <PanelComponent id={activeClipId} />}
      </div>
    </>
  );
}

export default memo(WorkspaceSidebarPanel);
