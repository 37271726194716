import { Form } from 'react-bootstrap';

import CheckboxElement from '@components/atoms/CheckboxElement';

import camelCase from '@utils/text/camelCase';

function InputCheckbox({ id, label, name, checked, disabled = false, handleChange, tracking }) {
  const inputId = id || camelCase(label);

  return (
    <Form.Group controlId={inputId}>
      <CheckboxElement
        name={name}
        checked={checked}
        disabled={disabled}
        handleEvent={handleChange}
        label={label}
        tracking={tracking}
      />
    </Form.Group>
  );
}

export default InputCheckbox;
