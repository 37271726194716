const isFormatSupported = (format) => {
  const supportedFormats = ['jpg', 'png', 'gif', 'mp4', 'webm', 'mp3', 'webp'];
  return !supportedFormats.includes(format);
};

function AssetDetailsPreview({ url, format, showPlaceholder, showControls = true }) {
  const fileFormat = format || (typeof url === 'string' ? url.split('.').pop() : '');
  const formatNotSupported = isFormatSupported(fileFormat);

  if (!url || showPlaceholder || formatNotSupported) {
    return <div className="asset-details-preview__placeholder" />;
  }

  switch (fileFormat) {
    case 'jpg':
    case 'png':
    case 'gif':
    case 'webp':
      return <img src={url} alt="Render preview" className="asset-details-preview" />;
    case 'mp4':
    case 'webm':
      return (
        <video controls={showControls} className="asset-details-preview">
          <source src={url} type={`video/${fileFormat}`} />
        </video>
      );
    case 'mp3':
      return <audio src={url} controls={showControls} type="audio/mpeg" className="asset-details-preview" />;
    default:
      return null;
  }
}

export default AssetDetailsPreview;
