import qs from 'qs';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAnalytics } from '@components/analytics/Analytics';

function PageTracking() {
  const location = useLocation();

  const { trackPage } = useAnalytics();

  useEffect(() => {
    trackPage();
  }, [location]);

  const path = location.pathname.split('/').filter(Boolean);
  const bodyClasses = path.length > 0 ? path : ['dashboard'];
  const { classList } = document.body;
  classList.remove(...Array.from(classList));
  classList.add(...bodyClasses);

  const queryString = location.search[0] === '?' ? location.search.slice(1) : location.search;
  const queryStringParams = qs.parse(queryString);

  if (queryStringParams.error) {
    return <Navigate to="/error" />;
  }
}

export default PageTracking;
