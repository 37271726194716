import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useRecoilState } from 'recoil';

import TransformFields from '@feature/studio/fields/SizeFields';
import TimingFields from '@feature/studio/fields/TimingFields';

import InputSelect from '@components/controls/InputSelect';
import InputTextarea from '@components/controls/InputTextarea';

import { clipSettingsSelectorFamily } from '@store/EditSelectors';

import TextToImageDimensions from '@constants/TextToImageDimensions';

import OffsetFields from '../fields/OffsetFields';

function SettingAssetTextToImage({ id }) {
  const [clip, setClip] = useRecoilState(clipSettingsSelectorFamily(id));

  const handleAssetPromptUpdate = () => (event) => {
    const { value } = event.target;
    setClip({ 'asset:meta': { ...clip['asset:meta'], text: value } });
  };

  const handleDimensionChange = useCallback(
    (event) => {
      const { id, value } = event.target;
      setClip({ [`asset:${id}`]: value });
    },
    [setClip]
  );

  return (
    <>
      <h6>Prompt</h6>
      <div className="mb-10">
        <Row>
          <Col md={12}>
            <InputTextarea
              id="prompt"
              placeholder="Imagine {{ NAME }} riding a giant pineapple through a field of rainbow-colored flowers."
              rows={5}
              value={clip['asset:meta'].text}
              handleChange={handleAssetPromptUpdate()}
            />
          </Col>
        </Row>
      </div>

      <h6>Size &amp; Position</h6>
      <div className="mb-10">
        <div className="mb-2">
          <Row>
            <Col md={6}>
              <InputSelect
                id="width"
                title="Width"
                selected={clip['asset:width'] || 1280}
                values={TextToImageDimensions}
                prefix={'W'}
                handleChange={handleDimensionChange}
              />
            </Col>
            <Col md={6}>
              <InputSelect
                id="height"
                title="Height"
                selected={clip['asset:height'] || 1280}
                values={TextToImageDimensions}
                prefix={'H'}
                handleChange={handleDimensionChange}
              />
            </Col>
          </Row>
        </div>
        <OffsetFields id={id} />
      </div>

      <h6>Transform</h6>
      <div className="mb-10">
        <TransformFields id={id} />
      </div>

      <h6>Timing</h6>

      <TimingFields id={id} />
    </>
  );
}

export default SettingAssetTextToImage;
