import api from '@api';
import config from '@config';

import { jsonParse } from '@utils/jsonParse';

const workflowsTransformResponse = [
  (response) => {
    const { data } = jsonParse(response);
    return data;
  },
];

const workflowClient = api.init({
  baseURL: config.workflow.url,
  transformResponse: workflowsTransformResponse,
});

export default workflowClient;
