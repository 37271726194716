/**
 * @type {(start: number, end: number, progress: number, easingFunction?: (t: number) => number) => number}
 */
export const bezierInterpolation = (start, end, progress, easingFunction = (t) => t) => {
  const controlPoint1 = start + (end - start) / 3;
  const controlPoint2 = end - (end - start) / 3;

  const easedProgress = easingFunction(progress);

  const inverseProgress = 1 - easedProgress;
  const progressSquared = easedProgress * easedProgress;
  const inverseProgressSquared = inverseProgress * inverseProgress;
  const inverseProgressCubed = inverseProgressSquared * inverseProgress;
  const progressCubed = progressSquared * easedProgress;

  const interpolatedStart = start * inverseProgressCubed;
  const interpolatedControlPoint1 = controlPoint1 * 3 * inverseProgressSquared * easedProgress;
  const interpolatedControlPoint2 = controlPoint2 * 3 * inverseProgress * progressSquared;
  const interpolatedEnd = end * progressCubed;

  return interpolatedStart + interpolatedControlPoint1 + interpolatedControlPoint2 + interpolatedEnd;
};
