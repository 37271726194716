import { useRef, useState } from 'react';
import { ButtonGroup, Overlay, Popover } from 'react-bootstrap';

import { IconTrash } from '@assets/icons';

import ButtonElement from '@components/atoms/ButtonElement';

import useDeleteWorkflow from '@hooks/useDeleteWorkflow';

function WorkflowDeleteButton({ id }) {
  const documentRef = useRef(document);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const { handleDelete, loading } = useDeleteWorkflow({
    id,
    onSuccess: () => setShow(false),
  });

  const deleteTracking = {
    name: 'Select Delete Template',
    properties: {
      Id: id,
    },
  };

  const confirmDeleteTracking = {
    name: 'Select Confirm Delete Template',
    properties: {
      Id: id,
    },
  };

  const togglePopover = (event) => {
    setShow(!show);
    setTarget(show ? null : event.target);
  };

  return (
    <>
      <ButtonElement
        title="Delete"
        handleEvent={togglePopover}
        variant="light"
        tracking={deleteTracking}
        disabled={show}
      >
        <IconTrash size="18" />
      </ButtonElement>

      <Overlay
        show={show}
        target={target}
        placement="top"
        container={documentRef.current?.body}
        containerPadding={20}
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover className="popover-duplicate-template">
          <Popover.Body>
            <ButtonGroup>
              <ButtonElement handleEvent={togglePopover} variant="light">
                Cancel
              </ButtonElement>
              <ButtonElement
                handleEvent={handleDelete}
                variant="danger"
                tracking={confirmDeleteTracking}
                loading={loading}
              >
                Delete
              </ButtonElement>
            </ButtonGroup>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
}

export default WorkflowDeleteButton;
