import createClient from '@api/clients/create';
import dataClient from '@api/clients/data';
import { transformGeneratedAssets } from '@api/transform/generated';

export const getGeneratedAsset = async (id, config) => {
  const url = `assets/${id}`;
  const { data } = await createClient.get(url, config);
  return data?.body?.data;
};

export const getAllGeneratedAssets = async (filterParams) => {
  const url = `create/assets`;
  const { data } = await dataClient.get(url, { params: filterParams });
  return transformGeneratedAssets(data?.body);
};

export default {
  get: getGeneratedAsset,
  getAll: getAllGeneratedAssets,
};
