import { useMemo } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';

import { IconTrash } from '@assets/icons';

import { useTimelinePlayheadContext } from '@context/TimelinePlaybackProvider';

import { clipsAtomFamily } from '@store/Edit';
import {
  activeClipKeyframeIdSelectorFamily,
  useAddClipKeyframe,
  useClearClipKeyframes,
  useRemoveClipKeyframe,
} from '@store/Keyframes';

function PanelClipKeyframesActions({ id }) {
  const clip = useRecoilValue(clipsAtomFamily(id));
  const activeKeyframeId = useRecoilValue(activeClipKeyframeIdSelectorFamily(id));
  const addKeyframe = useAddClipKeyframe(id);
  const removeKeyframe = useRemoveClipKeyframe(id);
  const clearKeyframes = useClearClipKeyframes(id);
  const { playhead } = useTimelinePlayheadContext();

  const canToggleKeyframe = useMemo(
    () => playhead >= clip.start && playhead <= clip.start + clip.length,
    [clip.start, clip.length, playhead]
  );

  const handleToggleKeyframe = () => {
    if (!canToggleKeyframe) {
      return;
    }

    if (activeKeyframeId) {
      removeKeyframe();
    } else {
      addKeyframe();
    }
  };

  const handleClearKeyframes = () => {
    clearKeyframes();
  };

  return (
    <div className="d-flex gap-2">
      <Button
        variant={activeKeyframeId ? 'danger' : 'primary'}
        onClick={handleToggleKeyframe}
        disabled={!canToggleKeyframe}
      >
        {activeKeyframeId ? 'Delete' : 'Add'}
      </Button>
      <OverlayTrigger placement="top" overlay={<Tooltip id="clear-keyframes-tooltip">Clear all keyframes</Tooltip>}>
        <Button variant="ghost" onClick={handleClearKeyframes}>
          <IconTrash size={16} />
        </Button>
      </OverlayTrigger>
    </div>
  );
}

export default PanelClipKeyframesActions;
