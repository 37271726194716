import { Alert } from 'react-bootstrap';

import BillingCardDetails from '@feature/billing/BillingCardDetails';

import Skeleton from '@components/skeletons/Skeleton';

function BillingCardDefault({ card, error }) {
  if (!card) {
    return <Skeleton />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return <BillingCardDetails card={card} />;
}

export default BillingCardDefault;
