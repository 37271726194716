import { Button } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { IconClipboard, IconExternal } from '@assets/icons';

function ModalFooter({ renderId, renderUrl }) {
  return (
    <div>
      <small className="m-0">RENDER ID</small>
      <p className="m-0 mb-2">
        <strong>{renderId}</strong>
        <CopyToClipboard text={renderId}>
          <Button className="btn-copy ms-2" title="Copy to clipboard" type="button" variant="light" size="sm">
            <IconClipboard size="14" />
          </Button>
        </CopyToClipboard>
      </p>
      <small className="m-0">DOWNLOAD URL (expires in 24 hours)</small>
      <p className="m-0">
        <a href={renderUrl} target="_blank" rel="noreferrer" className="me-2">
          {renderUrl}
        </a>
        <IconExternal />
      </p>
    </div>
  );
}

export default ModalFooter;
