import { orderBy } from 'lodash-es';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { Badge, Col, ListGroup, Row } from 'react-bootstrap';

import SourceDetailsAsset from '@feature/sources/SourceDetailsAsset';

import Content from '@components/content/Content';
import Skeleton from '@components/skeletons/Skeleton';
import StatusBadge from '@components/status/StatusBadge';

const typeMap = {
  renditions: 'Rendition',
  transcription: 'Transcription',
};

const getOrderedOutputs = (outputs) => {
  const newOutputs = Object.entries(outputs || {}).reduce((acc, [type, output]) => {
    const outputWithType = Array.isArray(output)
      ? output.map((item) => ({ ...item, type, listId: nanoid() }))
      : [{ ...output, type, listId: nanoid() }];
    return acc.concat(outputWithType);
  }, []);

  return orderBy(newOutputs, [(output) => output.type !== 'transcription', 'created']);
};

function SourceDetailsAssets({ outputs, loaded }) {
  const orderedOutputs = getOrderedOutputs(outputs || {});
  const [selectedOutput, setSelectedOutput] = useState();

  const handleItemClick = (selectedId) => {
    const rendition = orderedOutputs.find(({ listId }) => listId === selectedId);
    setSelectedOutput(rendition);
  };

  useEffect(() => {
    if (orderedOutputs?.length) {
      const [first] = orderedOutputs;
      setSelectedOutput(first);
    }
  }, [outputs]);

  if (!loaded) {
    return (
      <div className="mt-4">
        <Skeleton className="item-skeleton" />
      </div>
    );
  }

  if (!orderedOutputs?.length) {
    return null;
  }

  return (
    <Content heading="HOSTED RENDITIONS" className="asset-listing mt-4">
      <Row>
        <Col xs={12} md={4}>
          <ListGroup>
            {(orderedOutputs || []).map(({ listId, transformation, type, status }) => (
              <ListGroup.Item
                key={listId}
                as="li"
                className="d-flex justify-content-between align-items-start"
                onClick={() => handleItemClick(listId)}
                active={selectedOutput?.listId === listId}
              >
                <div className="ms-2 me-auto">
                  <small className="uppercase-heading">{typeMap[type]}</small>
                </div>
                <div className="d-flex align-items-center gap-2">
                  {transformation?.format && (
                    <Badge bg="light" text="dark">
                      {transformation.format}
                    </Badge>
                  )}
                  <StatusBadge status={status} />
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col xs={12} md={8}>
          <SourceDetailsAsset output={selectedOutput} />
        </Col>
      </Row>
    </Content>
  );
}

export default SourceDetailsAssets;
