import { useRecoilValue } from 'recoil';

import '@css/TimelineHeader.css';

import { IconAddTrack, IconUnlocked, IconVisible } from '@assets/icons';

import TimelineMarkers from '@feature/studio/timeline/TimelineMarkers';
import TimelinePlayhead from '@feature/studio/timeline/TimelinePlayhead';
import TimelineRange from '@feature/studio/timeline/TimelineRange';
import TimelineScrub from '@feature/studio/timeline/TimelineScrub';

import ButtonElement from '@components/atoms/ButtonElement';

import { templateIdAtom, templateNameAtom } from '@store/Template';
import {
  useAddTrackState,
  useToggleAllTrackLockedCallback,
  useToggleAllTrackVisibilityCallback,
} from '@store/Timeline';

const TimelineHeader = () => {
  const templateId = useRecoilValue(templateIdAtom);
  const templateName = useRecoilValue(templateNameAtom);
  const toggleAllTrackVisibility = useToggleAllTrackVisibilityCallback();
  const toggleAllTrackLocked = useToggleAllTrackLockedCallback();

  const addTrack = useAddTrackState();

  const addTrackTracking = {
    name: 'Add Track',
    properties: {
      Id: templateId,
      Title: templateName,
    },
  };

  return (
    <div className="timeline-header">
      <div className="timeline-header__actions">
        <div className="timeline-header__addtrack">
          <ButtonElement handleEvent={addTrack} variant="light" type="button" size="sm" tracking={addTrackTracking}>
            <IconAddTrack size={16} />
          </ButtonElement>
        </div>
        <div className="timeline-header__controls">
          <ButtonElement variant="ghost" handleEvent={toggleAllTrackVisibility} className="track__visibility-toggle">
            <IconVisible size={16} />
          </ButtonElement>
          <ButtonElement variant="ghost" handleEvent={toggleAllTrackLocked} className="track__locked-toggle">
            <IconUnlocked size={16} />
          </ButtonElement>
        </div>
      </div>
      <TimelineRange />
      <TimelineMarkers />
      <TimelinePlayhead />
      <TimelineScrub />
    </div>
  );
};

export default TimelineHeader;
