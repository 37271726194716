import { Container, Graphics, Text } from '@inlet/react-pixi';
import { isNumber } from 'lodash-es';
import { TextStyle } from 'pixi.js';
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import withCanvasMovable from '@feature/studio/canvas/CanvasMovable';

import ArtboardMask from '@components/masks/ArtboardMask';

import { playheadAtom } from '@store/Timeline';

import useFontCheck from '@hooks/useFontCheck';

import {
  CAPTION_COLOR,
  CAPTION_FONT_SIZE,
  COLOR,
  FONT_FAMILY,
  FONT_LINE_HEIGHT,
  TEXT_ALIGN,
} from '@constants/TextAssetDefaults';

import formatWebGlColor from '@utils/formatWebGlColor';

const MIN_LINE_HEIGHT = 0.1;

const getOrientation = (aspectRatio) => {
  if (aspectRatio > 1) return 'landscape';
  if (aspectRatio < 1) return 'portrait';
  if (aspectRatio === 1) return 'square';
};

const CanvasCaptionPlayer = forwardRef((props, ref) => {
  const { clip, visible, width, height, alpha, scale, zIndex, angle, aspectRatio, canvasWidth, canvasHeight } = props;

  const textRef = useRef();
  const maskRef = useRef(null);

  const isFontLoaded = useFontCheck(clip['asset:font:family']);
  const playhead = useRecoilValue(playheadAtom);

  /** @type {ReturnType<typeof import('react').useState<{ [key: string]: any }>>} */
  const [currentText, setCurrentText] = useState('');
  const [currentCaptionIndex, setCurrentCaptionIndex] = useState(-1);
  const [textBounds, setTextBounds] = useState({ width: 0, height: 0 });

  const orientation = useMemo(() => getOrientation(aspectRatio), [aspectRatio]);

  const [textAnchorX, setTextAnchorX] = useState(0.5);
  const [textOffsetX, setTextOffsetX] = useState(0);
  const [, forceUpdate] = useState({});

  const {
    ['asset:trim']: trim = 0,
    ['asset:alignment:horizontal']: horizontalAlign = TEXT_ALIGN,
    ['asset:alignment:vertical']: verticalAlign = TEXT_ALIGN,
    ['asset:font:color']: color = CAPTION_COLOR,
    ['asset:font:family']: fontFamily = FONT_FAMILY,
    ['asset:font:size']: fontSize = CAPTION_FONT_SIZE,
    ['asset:font:lineHeight']: lineHeight = FONT_LINE_HEIGHT,
    ['asset:font:opacity']: fontOpacity = 1,
    ['asset:background:color']: backgroundColor,
    ['asset:background:opacity']: backgroundOpacity = 1,
    ['asset:background:padding']: backgroundPadding = 0,
    ['asset:background:borderRadius']: backgroundBorderRadius = 0,
    ['asset:stroke:color']: strokeColor,
    ['asset:stroke:width']: strokeWidth = 0,
  } = clip;

  useEffect(() => {
    setTimeout(() => {
      forceUpdate({});
    }, 100);
  }, [fontFamily]);

  const { captions = [] } = clip['asset:meta'] || {};

  const clipStart = useMemo(() => {
    return clip?.start ? clip.start - (trim || 0) : 0;
  }, [clip?.start, trim]);

  const adjustedFontSize = useMemo(() => {
    return +fontSize;
  }, [fontSize]);

  const adjustedLineHeight = useMemo(() => {
    return lineHeight ? adjustedFontSize * lineHeight : adjustedFontSize * FONT_LINE_HEIGHT;
  }, [adjustedFontSize, lineHeight]);

  const adjustedBackgroundPadding = useMemo(() => {
    return backgroundPadding + 0.5 * +backgroundPadding;
  }, [backgroundPadding]);

  const boundingBoxWidth = useMemo(() => {
    const maxWidth = canvasWidth * 0.9;
    const calculatedWidth = textBounds.width + 2 * adjustedBackgroundPadding;
    return Math.min(calculatedWidth, maxWidth);
  }, [textBounds.width, adjustedBackgroundPadding, canvasWidth]);

  const boundingBoxHeight = useMemo(() => {
    return textBounds.height + 2 * adjustedBackgroundPadding;
  }, [textBounds.height, adjustedBackgroundPadding]);

  const textContainerPosition = useMemo(() => {
    const halfWidth = boundingBoxWidth / 2;
    const halfHeight = boundingBoxHeight / 2;
    const maxX = canvasWidth / 2 - halfWidth;
    const maxY = canvasHeight / 2 - halfHeight;
    const anchors = {
      topLeft: [-maxX, -maxY],
      top: [0, -maxY],
      topRight: [maxX, -maxY],
      left: [-maxX, 0],
      center: [0, 0],
      right: [maxX, 0],
      bottomLeft: [-maxX, maxY],
      bottom: [0, maxY],
      bottomRight: [maxX, maxY],
    };
    return anchors[clip?.position] || anchors.bottom;
  }, [clip?.position, canvasWidth, canvasHeight, boundingBoxWidth, boundingBoxHeight]);

  const containerPosition = useMemo(() => {
    const offsetY = !clip?.position ? -canvasHeight * 0.05 : 0;
    return [canvasWidth / 2, canvasHeight / 2 + offsetY];
  }, [canvasWidth, canvasHeight, clip?.position]);

  const wordWrapWidth = useMemo(() => {
    const multiplier = orientation === 'landscape' ? 0.4 : 0.6;
    return width - (multiplier * width) / 2;
  }, [width, orientation]);

  let bgColor = null;
  let bgOpacity = 0.0001; // if bgOpacity is 0, element is not interactive, can not be dragged.

  if (backgroundColor && backgroundColor !== 'transparent') {
    bgColor = formatWebGlColor(backgroundColor);
    bgOpacity = backgroundOpacity;
  }

  // multiplying to closely match the stroke width of the text in the edit api
  const strokeWidthForDisplay = useMemo(
    () => (strokeWidth && isNumber(strokeWidth) ? Math.floor(strokeWidth * 2.5) : 0),
    [strokeWidth]
  );

  useEffect(() => {
    if (ref.current && maskRef.current) {
      ref.current.mask = maskRef.current;
    }
  }, [ref]);

  useEffect(() => {
    if (!captions || captions.length === 0) {
      setCurrentText('');
      setCurrentCaptionIndex(-1);
      return;
    }

    const newIndex = captions.findIndex(
      (caption) => playhead >= clipStart + caption.start && playhead < clipStart + caption.start + caption.length
    );

    if (newIndex !== currentCaptionIndex) {
      setCurrentCaptionIndex(newIndex);
      setCurrentText(newIndex !== -1 ? captions[newIndex].text : '');
    }
  }, [captions, playhead, currentCaptionIndex, clipStart]);

  useEffect(() => {
    if (textRef.current && currentText !== '') {
      const bounds = textRef.current.getLocalBounds();
      setTextBounds({ width: bounds.width, height: bounds.height });
    }
  }, [currentText, isFontLoaded, aspectRatio, fontSize, lineHeight, clipStart]);

  useEffect(() => {
    const alignmentConfig = {
      horizontal: {
        left: { anchorX: 0, offsetX: -boundingBoxWidth / 2 + backgroundPadding },
        right: { anchorX: 1, offsetX: boundingBoxWidth / 2 - backgroundPadding },
        center: { anchorX: 0.5, offsetX: 0 },
      },
    };

    const { anchorX, offsetX } = alignmentConfig.horizontal[horizontalAlign] || alignmentConfig.horizontal.center;

    setTextAnchorX(anchorX);
    setTextOffsetX(offsetX);
  }, [horizontalAlign, verticalAlign, boundingBoxWidth, boundingBoxHeight, backgroundPadding]);

  const boundingBox = useCallback(
    (graphics) => {
      graphics.clear();
      graphics.beginFill(bgColor, bgOpacity);

      const x = -boundingBoxWidth / 2;
      const y = -boundingBoxHeight / 2;
      const r = backgroundBorderRadius;

      if (r > 0) {
        graphics.moveTo(x + r, y);
        graphics.arcTo(x + boundingBoxWidth, y, x + boundingBoxWidth, y + boundingBoxHeight, r);
        graphics.arcTo(x + boundingBoxWidth, y + boundingBoxHeight, x, y + boundingBoxHeight, r);
        graphics.arcTo(x, y + boundingBoxHeight, x, y, r);
        graphics.arcTo(x, y, x + boundingBoxWidth, y, r);
      } else {
        graphics.drawRect(x, y, boundingBoxWidth, boundingBoxHeight);
      }

      graphics.endFill();
    },
    [bgColor, bgOpacity, boundingBoxWidth, boundingBoxHeight, backgroundBorderRadius]
  );

  const textStyle = new TextStyle({
    align: horizontalAlign,
    fontFamily: fontFamily || FONT_FAMILY,
    fontSize: `${adjustedFontSize || 1}px`,
    fill: color || COLOR,
    lineHeight: lineHeight === 0 ? MIN_LINE_HEIGHT : adjustedLineHeight,
    wordWrap: true,
    wordWrapWidth,
    textBaseline: 'alphabetic',
    stroke: strokeColor || 'transparent',
    strokeThickness: strokeWidthForDisplay || 0,
  });

  return (
    <>
      <Container
        ref={ref}
        anchor={0.5}
        position={containerPosition}
        visible={visible}
        width={width}
        height={height}
        alpha={alpha}
        scale={scale}
        zIndex={zIndex}
        angle={angle}
      >
        {currentText && isFontLoaded ? (
          <Container position={textContainerPosition}>
            <Graphics draw={boundingBox} />
            <Text
              ref={textRef}
              text={currentText}
              style={textStyle}
              alpha={fontOpacity}
              x={textOffsetX}
              y={0.5}
              anchor={[textAnchorX, 0.5]}
            />
          </Container>
        ) : null}
      </Container>
      <ArtboardMask ref={maskRef} />
    </>
  );
});

export default withCanvasMovable(CanvasCaptionPlayer);
