import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';

import { getBalance, getUsage } from '@api/services/balance';

import LinkElement from '@components/atoms/LinkElement';
import Skeleton from '@components/skeletons/Skeleton';

const UsageData = () => {
  const [data, setData] = useState([]);
  const [balance, setBalance] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsageData = async () => {
      const response = await getUsage({ limit: 2000 });
      const sortedData = processData(response).sort((a, b) => new Date(b.created) - new Date(a.created));
      setData(sortedData);
      setLoading(false);
    };
    fetchUsageData();
  }, []);

  useEffect(() => {
    const fetchBalanceData = async () => {
      const response = await getBalance();
      setBalance(response);
    };
    fetchBalanceData();
  }, []);

  const processData = (data) =>
    data.map(({ identifier, cost, created }) => {
      const [type, ...rest] = identifier.split('#');
      const createdDate = new Date(created);
      return {
        type,
        uuid: rest.join('#'),
        cost,
        created: createdDate.toISOString(),
        formattedCreated: `${createdDate.toLocaleDateString()} ${createdDate.toLocaleTimeString()}`,
      };
    });

  const generateLinkUrl = (type, uuid) => {
    if (type === 'render') {
      return <LinkElement to={`/apis/edit-api/assets/${uuid}`} title={uuid} content={uuid} />;
    } else if (type.startsWith('shotstack-')) {
      return <LinkElement to={`/apis/create-api/assets/${uuid}`} title={uuid} content={uuid} />;
    } else if (type === 'rendition') {
      return <LinkElement to={`/apis/ingest-api/assets/${uuid}`} title={uuid} content={uuid} />;
    } else {
      return uuid;
    }
  };

  const downloadCSV = () => {
    const csvData = data.map(({ type, uuid, cost, created }) => ({
      Type: type,
      UUID: uuid,
      Credits: cost,
      Created: created,
    }));

    const headers = Object.keys(csvData[0]).join(',');
    const rows = csvData.map((row) => Object.values(row).join(',')).join('\n');
    const csvContent = `data:text/csv;charset=utf-8,${headers}\n${rows}`;

    const link = document.createElement('a');
    link.href = encodeURI(csvContent);
    link.download = 'usage_data.csv';
    link.click();
  };

  return (
    <div>
      {loading ? (
        <Skeleton length={3} />
      ) : (
        <>
          {balance.length > 0 && (
            <div className="balance-summary mb-4">
              <h4>Credits</h4>
              <Table className="metadata" striped bordered hover>
                <thead>
                  <tr>
                    <th>Grant date</th>
                    <th>Credits</th>
                    <th>Used</th>
                    <th>Remaining</th>
                    <th>Expiration</th>
                  </tr>
                </thead>
                <tbody>
                  {balance.map((bal) => (
                    <tr key={bal.id}>
                      <td>
                        {new Date(bal.created).toLocaleDateString('en-GB', {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                        })}
                      </td>
                      <td>{bal.initial}</td>
                      <td>{(bal.initial - bal.current).toFixed(2)}</td>
                      <td>{bal.current.toFixed(2)}</td>
                      <td>
                        {new Date(bal.expiration).toLocaleDateString('en-GB', {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </>
      )}

      <div className="d-flex justify-content-between mt-5 mb-3">
        <h4>History</h4>
        <Button onClick={downloadCSV} variant="primary" disabled={loading}>
          Download CSV
        </Button>
      </div>

      {loading ? (
        <Skeleton length={5} />
      ) : (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th style={{ width: '15%' }}>Type</th>
                <th style={{ width: '50%' }}>ID</th>
                <th style={{ width: '15%' }}>Credits</th>
                <th style={{ width: '20%' }}>Created</th>
              </tr>
            </thead>
            <tbody>
              {data.map(({ type, uuid, cost, formattedCreated }, index) => (
                <tr key={index}>
                  <td>{type}</td>
                  <td>{generateLinkUrl(type, uuid)}</td>
                  <td>{cost}</td>
                  <td>{formattedCreated}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};

export default UsageData;
