import { useRecoilState, useRecoilValue } from 'recoil';

import { useAnalytics } from '@components/analytics/Analytics';
import ColorPicker from '@components/atoms/ColorPicker';

import { backgroundAtom } from '@store/Edit';
import { templateIdAtom } from '@store/Template';

function SettingBackground() {
  const { trackEvent } = useAnalytics();
  const [background, setBackground] = useRecoilState(backgroundAtom);
  const templateId = useRecoilValue(templateIdAtom);

  const tracking = {
    name: 'Change Background Color',
    properties: {
      Id: templateId,
    },
  };

  const handleBackgroundChange = (event) => {
    setBackground(event.target.value);
    trackEvent(tracking.name, { ...tracking.properties });
  };

  return (
    <div>
      <h6>Background Color</h6>
      <ColorPicker className="background" name="background" value={background} handleEvent={handleBackgroundChange} />
    </div>
  );
}

export default SettingBackground;
