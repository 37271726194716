import cn from 'classnames';
import { useRecoilState } from 'recoil';

import '@css/TimelineControls.css';

import { IconPause, IconPlay, IconSeekBack, IconStop, IconVolumeFull, IconVolumeMute } from '@assets/icons';

import { useTimelinePlaybackContext } from '@context/TimelinePlaybackProvider';

import { playbackMuteAtom } from '@store/Timeline';

function TimelineControls() {
  const { isPlayable, isPlaying, handlePlayPause, handleStop, setPlayheadStart } = useTimelinePlaybackContext();
  const [isMuted, setIsMuted] = useRecoilState(playbackMuteAtom);

  const handleMute = () => {
    setIsMuted((prev) => !prev);
  };

  if (!isPlayable) {
    return null;
  }

  return (
    <div className="controls__container">
      <div className="controls__playback">
        <button type="button" className="controls__playpause" onClick={handlePlayPause}>
          {!isPlaying ? <IconPlay size={32} /> : <IconPause size={32} />}
        </button>

        <button type="button" className="controls__stop" onClick={setPlayheadStart}>
          <IconSeekBack size={32} />
        </button>
        {/* <button type="button" className={cn('controls__mute', { muted: isMuted })} onClick={handleMute}>
          {isMuted ? (
            <div className="controls__mute-inner">
              <IconVolumeMute size={12} />
              <span>Unmute</span>
            </div>
          ) : (
            <div className="controls__mute-inner">
              <IconVolumeFull size={12} />
              <span>Mute</span>
            </div>
          )}
        </button> */}
      </div>
    </div>
  );
}

export default TimelineControls;
