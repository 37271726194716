import { forwardRef, memo } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import { useAnalytics } from '@components/analytics/Analytics';

const defaultTrackingProperties = {
  Context: 'Button',
};

const ButtonElement = forwardRef(
  ({ handleEvent = () => {}, tracking, disabled, loading, size = 'md', title = '', children, ...rest }, ref) => {
    const { trackEvent } = useAnalytics();

    const handleClick = (event) => {
      if (disabled) {
        event.preventDefault();
        return;
      }

      if (tracking) {
        trackEvent(tracking.name, { ...defaultTrackingProperties, ...tracking.properties });
      }
      handleEvent(event);
    };

    return (
      <Button ref={ref} onClick={handleClick} size={size} disabled={disabled} title={title} {...rest}>
        {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : children}
      </Button>
    );
  }
);

ButtonElement.displayName = 'ButtonElement';

export default memo(ButtonElement);
