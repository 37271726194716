import { useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useRecoilValueLoadable } from 'recoil';

import { useAnalytics } from '@components/analytics/Analytics';
import ButtonElement from '@components/atoms/ButtonElement';
import CSVUploader from '@components/csv/CSVUploader';

import { workflowSelectorFamily } from '@store/Workflows';

import useRunWorkflow from '@hooks/useRunWorkflow';

import { delay } from '@utils/delay';

const BATCH_SIZE = 10;

const batchArray = (arr) => {
  const newArr = [];
  for (let i = 0; i < arr.length; i += BATCH_SIZE) {
    newArr.push(arr.slice(i, i + BATCH_SIZE));
  }
  return newArr;
};

const getSampleData = ({ data }) => {
  if (!data?.sample) {
    return undefined;
  }

  try {
    const sampleData = JSON.parse(data?.sample);
    return Array.isArray(sampleData) ? sampleData[0] : sampleData;
  } catch (error) {
    return undefined;
  }
};

const isTriggerCSV = ({ configuration }) => {
  return configuration?.trigger?.module === 'shotstack:csv';
};

function RunOnceModal({ children, show, onHide }) {
  return (
    <Modal show={show} onHide={onHide} size="lg" centered dialogClassName="asset-modal">
      <Modal.Header closeButton>
        <Modal.Title>Run Workflow</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}

function EmptyJobData() {
  return (
    <div>
      <p>No job data available. Please upload a CSV file or provide sample webhook data.</p>
      <p>You can add sample data via the workflow trigger configuration.</p>
    </div>
  );
}

function JobDataPreview({ headers, jobsPreview, jobs, loading, handleRunOneJob }) {
  return (
    <>
      <p>
        We will use the following data to run your workflow. (Showing {jobsPreview.length} of {jobs.length} lines)
      </p>
      <div className="mb-4 overflow-auto hide-scroll">
        <Table className="mb-0" striped bordered hover>
          <thead>
            <tr>
              {jobs.length > 1 && <th style={{ width: '1%' }}>&nbsp;</th>}
              {headers.map((key, index) => (
                <th key={index}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {jobsPreview.map((data, index) => (
              <tr key={index}>
                {jobs.length > 1 && (
                  <td>
                    <ButtonElement
                      handleEvent={handleRunOneJob(index)}
                      loading={loading}
                      disabled={loading}
                      className="me-2"
                    >
                      Run
                    </ButtonElement>
                  </td>
                )}
                {Object.entries(data).map(([key, value]) => (
                  <td key={`${index}-${key}`} style={{ minWidth: 300 }}>
                    {value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

function WorkflowListRunTestButton({ id, onRunSuccess }) {
  const { trackEvent } = useAnalytics();
  const { contents } = useRecoilValueLoadable(workflowSelectorFamily(id));
  const [headers, setHeaders] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [jobsPreview, setJobsPreview] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showRunOnceModal, setShowRunOnceModal] = useState(false);
  const { handleRun } = useRunWorkflow();

  const isCSV = isTriggerCSV(contents);

  const handleCloseModal = () => {
    setShowRunOnceModal(false);
    setHeaders([]);
    setJobs([]);
    setJobsPreview([]);
    setLoading(false);
  };

  const handleCSVUpload = async (data) => {
    const [headerRow, ...rows] = data;
    const jobData = rows.map((row) => row.reduce((acc, value, index) => ({ ...acc, [headerRow[index]]: value }), {}));

    setShowRunOnceModal(true);
    setHeaders(headerRow);
    setJobs(jobData);
    setJobsPreview(jobData.slice(0, 5));
  };

  const handleWebhookData = () => {
    const data = getSampleData(contents);
    const jobData = data ? (Array.isArray(data) ? data : [data]) : [];

    setShowRunOnceModal(true);
    setHeaders(jobData.length > 0 ? Object.keys(jobData[0]) : []);
    setJobs(jobData);
    setJobsPreview(jobData.slice(0, 5));
  };

  const handleRunJobs = async () => {
    setLoading(true);
    for (const batch of batchArray(jobs)) {
      await handleRun(id, batch);
      await delay(7500);
    }

    trackEvent('Run Workflow', { Id: id });
    handleCloseModal();
    if (onRunSuccess) {
      onRunSuccess();
    }
  };

  const handleRunOneJob = (index) => async () => {
    setLoading(true);
    await handleRun(id, [jobs[index]]);
    await delay(7500);
    handleCloseModal();
    if (onRunSuccess) {
      onRunSuccess();
    }
  };

  return (
    <>
      {isCSV ? (
        <CSVUploader text="Upload and Run Workflow" onUpload={handleCSVUpload} />
      ) : (
        <ButtonElement className="whitespace-nowrap" handleEvent={handleWebhookData}>
          Run Workflow
        </ButtonElement>
      )}

      <RunOnceModal show={showRunOnceModal} onHide={handleCloseModal}>
        {jobs.length === 0 ? (
          <EmptyJobData />
        ) : (
          <JobDataPreview
            headers={headers}
            jobsPreview={jobsPreview}
            jobs={jobs}
            loading={loading}
            handleRunOneJob={handleRunOneJob}
          />
        )}

        {jobs.length !== 0 && (
          <div className="d-flex align-items-center justify-content-end">
            <ButtonElement handleEvent={handleRunJobs} loading={loading} disabled={loading}>
              {jobs.length > 0 ? (jobs.length === 1 ? 'Run 1 Job' : `Run ${jobs.length} Jobs`) : 'Run'}
            </ButtonElement>
          </div>
        )}
      </RunOnceModal>
    </>
  );
}

export default WorkflowListRunTestButton;
