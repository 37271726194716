import { useCallback, useRef, useState } from 'react';
import { Button, Col, Row, Spinner, Table } from 'react-bootstrap';

import { useAuth0 } from '@context/Auth0ReactSPA';

import Content from '@components/content/Content';
import Layout from '@components/layout/Layout';

import useAssetUpload from '@hooks/useAssetUpload';
import useCreateTemplate from '@hooks/useCreateTemplate';

function AssetRow({ asset, updateAsset }) {
  const inputRef = useRef();
  const { isUploading, uploadError, onFileChange, onBrowseFiles } = useAssetUpload({
    inputRef,
    onUploadComplete: (attributes) => {
      updateAsset(asset.mergeField, attributes.source);
    },
  });

  return (
    <tr>
      <td>{asset.mergeField}</td>
      <td>{`${asset.path}/${asset.filename}`}</td>
      <td>{asset.status || 'Pending'}</td>
      <td>
        <input type="file" style={{ display: 'none' }} ref={inputRef} onChange={onFileChange} />
        <Button onClick={onBrowseFiles} disabled={isUploading}>
          {isUploading ? <Spinner size="sm" animation="border" /> : 'Upload'}
        </Button>
        {uploadError && <div>{uploadError}</div>}
      </td>
      <td>
        {asset.status === 'ready' && (
          <a href={asset.source} target="_blank" rel="noopener noreferrer">
            View File
          </a>
        )}
      </td>
    </tr>
  );
}

function Uploader() {
  const { loading: authLoading, user } = useAuth0();
  const [assets, setAssets] = useState([]);
  const [templateData, setTemplateData] = useState(null);
  const [templateName, setTemplateName] = useState(null);
  const [templateId, setTemplateId] = useState(null);
  const [creatingSkeletonTemplate, setCreatingSkeletonTemplate] = useState(false);
  const [creatingTemplate, setCreatingTemplate] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);

  const { handleCreateWithData } = useCreateTemplate({
    onSuccess: (response) => {
      setTemplateId(response.id);
    },
  });

  const updateAsset = useCallback((mergeField, source) => {
    setAssets((prevAssets) =>
      prevAssets.map((assetItem) =>
        assetItem.mergeField === mergeField ? { ...assetItem, status: 'ready', source } : assetItem
      )
    );
  }, []);

  const handleFileUpload = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        try {
          const result = JSON.parse(evt.target.result);
          setTemplateData(result.templateData);
          setTemplateName(result.name);
          setAssets(result.assetsData.map((asset) => ({ ...asset, status: 'pending' })));
          setFileUploaded(true);
        } catch (error) {
          console.error('Invalid JSON format.', error);
        }
      };
      reader.readAsText(file);
    }
  }, []);

  const handleCreateSkeletonTemplate = async () => {
    setCreatingSkeletonTemplate(true);
    const updatedTemplateData = {
      ...templateData,
      merge: [],
    };

    await handleCreateWithData(`${templateName}-skeleton`, updatedTemplateData);
    setCreatingSkeletonTemplate(false);
  };

  const handleCreateTemplate = async () => {
    setCreatingTemplate(true);
    const updatedTemplateData = {
      ...templateData,
      merge: templateData.merge.map((mergeItem) => {
        const matchingAsset = assets.find((asset) => asset.mergeField === mergeItem.find);
        if (matchingAsset) {
          return { ...mergeItem, replace: matchingAsset.source };
        }
        return mergeItem;
      }),
    };

    await handleCreateWithData(templateName, updatedTemplateData);
    setCreatingTemplate(false);
  };

  const areAllAssetsUploaded = assets.every((asset) => asset.status === 'ready');

  if (authLoading || !user) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <Row>
        <Col md={4}>
          <input type="file" onChange={handleFileUpload} accept=".json" />
        </Col>
        <Col md={8} className="d-flex justify-content-end">
          <Button
            className="me-2"
            onClick={handleCreateSkeletonTemplate}
            disabled={!fileUploaded || creatingSkeletonTemplate}
          >
            {creatingSkeletonTemplate ? <Spinner animation="border" /> : 'Create Skeleton Template'}
          </Button>
          <Button
            className="me-2"
            onClick={handleCreateTemplate}
            disabled={!fileUploaded || !areAllAssetsUploaded || creatingTemplate}
          >
            {creatingTemplate ? <Spinner animation="border" /> : 'Create Template'}
          </Button>
          {templateId && (
            <p>
              <a href={`/studio/editor/${templateId}`} target="_blank" rel="noopener noreferrer">
                View Template
              </a>
            </p>
          )}
        </Col>
      </Row>
      <Row>
        <Content>
          <p>
            <b>Composition name:</b> {templateName}
          </p>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>MergeField</th>
                <th>File Location</th>
                <th>Status</th>
                <th>Action</th>
                <th>File Link</th>
              </tr>
            </thead>
            <tbody>
              {assets.map((asset) => (
                <AssetRow key={asset.mergeField} asset={asset} updateAsset={updateAsset} />
              ))}
            </tbody>
          </Table>
        </Content>
      </Row>
    </Layout>
  );
}

export default Uploader;
