import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function SubscriptionCurrentDetails({ subscription }) {
  const history = useNavigate();

  function handleRoute(path) {
    history(path);
  }

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col xs={12} lg={3}>
            <p className="uppercase-label">Plan</p>
            <p className="text-lg font-bold">{subscription.name}</p>
          </Col>
          <Col>
            <Row>
              <Col>
                <p className="uppercase-label">Credits</p>
                <p className="text-lg font-bold">{subscription.credits}</p>
              </Col>
              <Col>
                <p className="uppercase-label">Storage</p>
                <p className="text-lg font-bold">{subscription.storage ? `${subscription.storage}GB` : '—'}</p>
              </Col>
              <Col>
                <p className="uppercase-label">Bandwidth</p>
                <p className="text-lg font-bold">{subscription.bandwidth ? `${subscription.bandwidth}GB` : '—'}</p>
              </Col>
              <Col>
                <p className="uppercase-label">Cost</p>
                <p className="text-lg font-bold">${subscription.price}/m</p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={3}>
            <Button className="w-full mt-2" variant="light" onClick={() => handleRoute('/subscription')}>
              Manage Subscription
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default SubscriptionCurrentDetails;
