import { useParams } from 'react-router-dom';

import '@css/Renders.css';

import CreateApiDetail from '@pages/Resource/CreateApiDetail';
import EditApiDetail from '@pages/Resource/EditApiDetail';
import IngestApiDetail from '@pages/Resource/IngestApiDetail';
import ServeApiDetail from '@pages/Resource/ServeApiDetail';

const productServiceMap = {
  'edit-api': EditApiDetail,
  'ingest-api': IngestApiDetail,
  'create-api': CreateApiDetail,
  'serve-api': ServeApiDetail,
  default: () => null,
};

function ResourceDetail() {
  const { product } = useParams();
  const DetailComponent = productServiceMap[product] || productServiceMap.default;
  return <DetailComponent />;
}

export default ResourceDetail;
