import { transformOutput } from '@api/transform/utils/output';
import { transformTimeline } from '@api/transform/utils/timeline';

import { zipObject } from '@utils/zipObject';

const getTransformMap = (template) => ({
  timeline: transformTimeline(template),
  output: transformOutput,
  default: (value) => value,
});

export const transformTemplate = async (template) => {
  if (!template) {
    return {};
  }
  const transformFns = getTransformMap(template);
  const templateEntries = Object.entries(template);
  const transformPromises = templateEntries.map(async ([key, value]) => {
    const transform = transformFns[key] || transformFns.default;
    return transform(value);
  });
  const templateTransformed = await Promise.all(transformPromises);
  return zipObject(
    templateEntries.map(([key]) => key),
    templateTransformed
  );
};
