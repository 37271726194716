import axios from 'axios';
import cn from 'classnames';
import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import { useAuth0 } from '@context/Auth0ReactSPA';

import ButtonElement from '@components/atoms/ButtonElement';
import InputTextarea from '@components/controls/InputTextarea';

const formSubmitUrl = 'https://hook.us1.make.com/jys8v2vjdpydfcpz8n7wgshasby7bf7y';

const ratingOptions = [
  { id: 'experience-terrible', value: 'terrible', emoji: '😡', label: 'Terrible' },
  { id: 'experience-bad', value: 'bad', emoji: '😟', label: 'Bad' },
  { id: 'experience-neutral', value: 'neutral', emoji: '😐', label: 'Neutral' },
  { id: 'experience-good', value: 'good', emoji: '🙂', label: 'Good' },
  { id: 'experience-great', value: 'great', emoji: '😀', label: 'Great' },
];

const reasonOptions = [
  'Too expensive',
  'Missing features',
  'Needed only for a short-term project',
  'Poor customer support',
  'Better alternatives',
];

function SubscriptionDowngradeModal({ showDowngradeModal, setShowDowngradeModal, onSubmitSuccess }) {
  const { user } = useAuth0();
  const [selectedExperienceReason, setSelectedExperienceReason] = useState();
  const [selectedExperienceRating, setSelectedExperienceRating] = useState();
  const [selectedExperienceNPS, setSelectedExperienceNPS] = useState();
  const [experienceFeedback, setExperienceFeedback] = useState();
  const [formError, setFormError] = useState('');
  const [formSubmited, setFormSubmited] = useState(false);

  const handleSelectExperienceRating = (e) => {
    const { label } = ratingOptions.find((option) => option.value === e.target.value);
    setSelectedExperienceRating(label);
  };

  const handleExperienceReason = (e) => {
    setSelectedExperienceReason(e.target.name);
  };

  const handleExperienceNPS = (e) => {
    setSelectedExperienceNPS(parseInt(e.target.name, 10));
  };

  const handleExperienceFeedback = (e) => {
    setExperienceFeedback(e.target.value);
  };

  const validateForm = () => {
    return [selectedExperienceReason, selectedExperienceRating, selectedExperienceNPS].every(
      (value) => value !== undefined && value !== null && value !== ''
    );
  };

  const handleSubmit = async () => {
    setFormSubmited(true);

    if (!validateForm()) {
      setFormSubmited(false);
      setFormError('Please complete all required fields.');
      return;
    }

    setFormError('');

    const id = user?.sub.split('|')[1];

    const data = {
      id,
      email: user?.email,
      selectedExperienceRating,
      selectedExperienceNPS,
      selectedExperienceReason,
      experienceFeedback: experienceFeedback || 'No Feedback Given.',
    };

    try {
      const response = await axios.post(formSubmitUrl, data);
      if (response.status === 200) {
        setShowDowngradeModal(false);
        onSubmitSuccess();
      }
    } catch (error) {
      setFormError('Something went wrong. Please try again.');
      console.error('Error submitting form: ', error);
    } finally {
      setFormSubmited(false);
    }
  };

  return (
    <Modal
      className="downgrade-modal"
      show={showDowngradeModal}
      onHide={() => setShowDowngradeModal(false)}
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title className="downgrade-modal__title">Help us do better</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="experience-form">
          <div>
            <p className="text-small">
              We get it, changes are part of the journey — please help us understand your decision to switch plans.
            </p>
          </div>

          <div>
            <p>
              <strong>
                How do you rate your overall experience? <span className="required">*</span>
              </strong>
            </p>
            <div className="d-flex justify-content-around">
              {ratingOptions.map((option) => (
                <Form.Group className="text-center" key={option.id}>
                  <Form.Check
                    type="radio"
                    id={option.id}
                    name="experience"
                    value={option.value}
                    style={{ display: 'none' }}
                    onChange={handleSelectExperienceRating}
                  />
                  <label
                    htmlFor={option.id}
                    className={cn('experience-emoji__label', {
                      'experience-emoji__label--active': option.label === selectedExperienceRating,
                    })}
                  >
                    <div className="experience-emoji">{option.emoji}</div>
                    <span>{option.label}</span>
                  </label>
                </Form.Group>
              ))}
            </div>
          </div>

          <div>
            <p>
              <strong>
                How likely are you to recommend us to a friend or colleague? <span className="required">*</span>
              </strong>
            </p>

            <div className="d-flex justify-content-between">
              {Array.from(Array(11).keys()).map((option) => (
                <Button
                  variant="outline-secondary"
                  name={option}
                  key={option}
                  className="experience-button"
                  onClick={handleExperienceNPS}
                  active={option === selectedExperienceNPS}
                >
                  {option}
                </Button>
              ))}
            </div>

            <div className="d-flex justify-content-between mt-2">
              <span className="experience-nps__label">Least likely</span>
              <span className="experience-nps__label">Most likely</span>
            </div>
          </div>

          <div>
            <p>
              <strong>
                What&apos;s prompting the change? <span className="required">*</span>
              </strong>
            </p>

            <div className="d-flex flex-wrap gap-2">
              {reasonOptions.map((option) => (
                <Button
                  variant="outline-secondary"
                  name={option}
                  key={option}
                  className="experience-button"
                  onClick={handleExperienceReason}
                  active={option === selectedExperienceReason}
                >
                  {option}
                </Button>
              ))}
            </div>
          </div>

          <div>
            <p>
              <strong>Additional feedback</strong>
            </p>
            <p className="text-small">
              Anything else you would like to share that could help us improve is really appreciated.
            </p>
            <InputTextarea id="experience-feedback" rows={5} handleChange={handleExperienceFeedback} />
          </div>

          <div>
            <div>{formError && <p className="text-small text-danger">{formError}</p>}</div>

            <div className="d-flex justify-content-end">
              <ButtonElement
                variant="primary"
                className="experience-button"
                handleEvent={handleSubmit}
                loading={formSubmited}
              >
                Submit
              </ButtonElement>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SubscriptionDowngradeModal;
