import { useRecoilState, useRecoilValue } from 'recoil';

import WorkflowPanelHeader from '@feature/workflows/panel/WorkflowPanelHeader';
import WorkflowPanelMessage from '@feature/workflows/panel/WorkflowPanelMessage';

import InputText from '@components/controls/InputText';

import { activeTaskAtom, taskModuleAtomFamily, taskOutputsAtomFamily } from '@store/Workflows';

import { MODULE_ATTRIBUTES } from '@constants/Workflows';

function formatOutputValue(value) {
  const normalizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const spaceReplacedValue = normalizedValue.replace(/\s+/g, '_');
  const alphanumericValue = spaceReplacedValue.replace(/\W/g, '');
  const outputValue = alphanumericValue.toUpperCase();
  return outputValue;
}

function WorkflowModuleOutputs({ name, description }) {
  const activeTask = useRecoilValue(activeTaskAtom);
  const taskModule = useRecoilValue(taskModuleAtomFamily(activeTask));
  const [taskOutputs, setTaskOutputs] = useRecoilState(taskOutputsAtomFamily(activeTask));
  const { outputKey } = MODULE_ATTRIBUTES[taskModule];

  const onChange = (event) => {
    const { value } = event.target;
    const formattedValue = formatOutputValue(value);
    setTaskOutputs({ ...taskOutputs, [outputKey]: formattedValue });
  };

  if (!taskModule || !outputKey) {
    return null;
  }

  const outputValue = taskOutputs[outputKey];

  return (
    <div className="workflow-panel__output">
      <WorkflowPanelHeader
        name={name}
        description={[description, '<strong>Reference this data label in other Actions.</strong>'].join('<br />')}
      />
      <div className="mt-2 mb-4">
        <InputText id="output" value={outputValue} maxLength={255} handleChange={onChange} />
      </div>
      <WorkflowPanelMessage
        name="NOTE"
        message="Changing this label will require re-configuration of steps using this label."
      />
    </div>
  );
}

export default WorkflowModuleOutputs;
