import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { mediaCaptureAtomFamily } from '@store/Media';

import captureFrames from '@utils/editor/captureFrames';

function useCaptureFrames({ src, options }) {
  const [frames, setFrames] = useRecoilState(mediaCaptureAtomFamily(src));

  const capture = useCallback(async () => {
    try {
      setFrames({ loading: true, status: 'processing', data: null });
      const data = await captureFrames({ src, options });
      setFrames({ loading: false, status: 'done', data });
    } catch (error) {
      console.error(error);
      setFrames({ loading: false, status: 'failed-capture', data: null });
    }
  }, [src, options]);

  useEffect(() => {
    if (['failed-restore'].includes(frames.status)) {
      capture();
    }
  }, [frames]);

  return useMemo(() => ({ frames }), [frames]);
}

export default useCaptureFrames;
