import { useRecoilValue } from 'recoil';

import InputText from '@components/controls/InputText';

import { templateIdAtom } from '@store/Template';

const GoogleDriveOptions = [
  { label: 'filename', placeholder: 'The filename of your video' },
  { label: 'folderId', placeholder: 'A description of your video' },
];

function GoogleDrive({ destination, setDestination }) {
  const templateId = useRecoilValue(templateIdAtom);

  const tracking = {
    properties: {
      Id: templateId,
    },
  };

  const handleOptionsChange = (event) => {
    const { name, value } = event.target;
    setDestination((prevState) => ({
      ...prevState,
      options: {
        ...prevState.options,
        [name]: value,
      },
    }));
  };

  return (
    <div className="d-flex flex-column gap-2">
      {GoogleDriveOptions.map(({ label, placeholder }) => (
        <InputText
          key={label}
          name={label}
          label={label}
          placeholder={placeholder}
          value={destination?.options?.[label]}
          handleChange={handleOptionsChange}
          tracking={{ name: `Change GoogleDrive ${label}`, ...tracking }}
        />
      ))}
    </div>
  );
}

export default GoogleDrive;
