import ReactPrismjs from '@uiw/react-prismjs';
import cn from 'classnames';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-ruby';

import '@css/PrismSnippet.css';

function PrismSnippet({ text, className, compact, language = 'javascript' }) {
  return <ReactPrismjs prefixCls="ss-prism" className={cn(className, { compact })} language={language} source={text} />;
}

export default PrismSnippet;
