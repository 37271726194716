import { IconTrash } from '@assets/icons';

import ButtonElement from '@components/atoms/ButtonElement';

import useDeleteTemplate from '@hooks/useDeleteTemplate';

function TemplateDelete({ templateId }) {
  const { handleDelete } = useDeleteTemplate({
    templateId,
  });

  const deleteTracking = {
    name: 'Select Delete Template',
    properties: {
      Id: templateId,
    },
  };

  return (
    <ButtonElement
      title="Delete"
      handleEvent={handleDelete}
      variant="danger"
      size="sm"
      tracking={deleteTracking}
      className="d-flex align-items-center gap-2 text-center"
    >
      <IconTrash size="18" /> Delete
    </ButtonElement>
  );
}

export default TemplateDelete;
