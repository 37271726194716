/**
 * @typedef {{
 *  trim?: number;
 *  volume?: number;
 * }} VideoInitializerOptions
 */

/**
 * @type {(video: HTMLVideoElement) => boolean}
 */
export const isVideoPlaying = (video) => {
  return video.currentTime > 0 && !video.paused && !video.ended && video.readyState > video.HAVE_CURRENT_DATA;
};

/**
 * @type {(videoTexture: import('pixi.js').Texture<import('pixi.js').VideoResource>, options: VideoInitializerOptions) => void}
 */
export const initializeVideo = (videoTexture, options) => {
  const { trim, volume } = options;
  const videoResource = videoTexture.baseTexture.resource;

  videoResource.source.autoplay = false;
  videoResource.source.preload = 'auto';
  videoResource.source.currentTime = trim;
  videoResource.source.volume = volume;

  videoResource.source.pause();
  videoTexture.update();
};

/**
 * @type {(videoTexture: import('pixi.js').Texture<import('pixi.js').VideoResource>) => void}
 */
export const pauseVideo = (videoTexture) => {
  const videoResource = videoTexture.baseTexture.resource;
  const video = videoResource.source;

  if (isVideoPlaying(video)) {
    video.pause();
  }

  videoTexture.update();
};

/**
 * @type {(videoTexture: import('pixi.js').Texture<import('pixi.js').VideoResource>, seek: number, trim: number, visible: boolean, isPlaying: boolean) => void}
 */
export const seekVideo = (videoTexture, seek, trim, visible, isPlaying) => {
  const video = videoTexture.baseTexture.resource.source;
  const isVideoEnded = !visible && video.currentTime !== trim;

  try {
    if (isVideoEnded) {
      video.currentTime = trim;
      pauseVideo(videoTexture);
      return;
    }

    if (visible && !isPlaying && !Number.isNaN(seek)) {
      video.currentTime = seek;
      pauseVideo(videoTexture);
      return;
    }

    if (visible && isPlaying && !isVideoPlaying(video)) {
      video
        .play()
        .then(() => videoTexture.update())
        .catch(console.error);
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * @type {(videoTexture: import('pixi.js').Texture<import('pixi.js').VideoResource>) => void}
 */
export const handleVideoError = (videoTexture) => {
  const video = videoTexture.baseTexture.resource.source;
  console.error(`Failed to load asset. ${video.src}`);
};
