import { useRecoilState, useRecoilValue } from 'recoil';

import PanelHeader from '@feature/workflows/panel/WorkflowPanelHeader';

import InputText from '@components/controls/InputText';

import { activeTaskAtom, taskNameAtomFamily } from '@store/Workflows';

function WorkflowModuleName() {
  const activeTask = useRecoilValue(activeTaskAtom);
  const [taskName, setTaskName] = useRecoilState(taskNameAtomFamily(activeTask));

  const onChange = (event) => {
    const { value } = event.target;
    setTaskName(value);
  };

  return (
    <div>
      <PanelHeader name="Name" description="Use this to label your task something memorable." />
      <div className="mt-3 mb-2">
        <InputText id="output" value={taskName} maxLength={255} handleChange={onChange} />
      </div>
    </div>
  );
}

export default WorkflowModuleName;
