import config from '@config';
import axios from 'axios';
import _ from 'lodash-es';
import { useCallback, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import ButtonElement from '@components/atoms/ButtonElement';

import { authTokenAtom, stageAtom } from '@store/Auth';

import useAxiosPoll from '@hooks/useAxiosPoll';
import useGetMediaDimensions from '@hooks/useGetMediaDimensions';

import { RENDITION_WIDTH } from '@constants/Uploads';

const UPLOAD_STATUS_READY = 'ready';
const UPLOAD_STATUS_FAILED = 'failed';
const UPLOAD_FILE_ENDPOINT = 'upload';
const UPLOAD_REQUEST_URL = `${config.ingest.url}${UPLOAD_FILE_ENDPOINT}`;
const UPLOAD_POLLING_INTERVAL = 2000;

const getVideoRenditionConfig = ({ width }) => ({
  format: 'webm',
  keyframeInterval: 25,
  size: {
    width: _.isNumber(width) ? Math.min(width, RENDITION_WIDTH) : RENDITION_WIDTH,
  },
});

const getImageRenditionConfig = ({ width }) => ({
  format: 'png',
  size: {
    width: _.isNumber(width) ? Math.min(width, RENDITION_WIDTH) : RENDITION_WIDTH,
  },
});

const renditionConfigMap = {
  video: getVideoRenditionConfig,
  image: getImageRenditionConfig,
};

const getRequestOptions = ({ type, options }) => {
  const getRenditionConfig = renditionConfigMap[type];
  if (!getRenditionConfig) {
    return null;
  }

  const rendition = getRenditionConfig(options);

  return {
    outputs: {
      renditions: [{ ...rendition, filename: 'shotstack-mask' }],
    },
  };
};

function MaskSourceInput({ onUploadComplete }) {
  const fileInput = useRef(null);
  const token = useRecoilValue(authTokenAtom);
  const stage = useRecoilValue(stageAtom);
  const { startPolling } = useAxiosPoll();
  const { getMediaDimensions } = useGetMediaDimensions();

  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState();
  const [uploadStatus, setUploadStatus] = useState('Upload');

  const requestHeaders = {
    headers: {
      Authorization: `Bearer ${token}`,
      stage,
    },
  };

  const onPollCheck = (response) => {
    const {
      attributes: {
        status,
        outputs: {
          renditions: [proxy],
        },
      },
    } = response.data.data;
    return [status, proxy?.status].every((s) => [UPLOAD_STATUS_READY, UPLOAD_STATUS_FAILED].includes(s));
  };

  const getStatus = async ({ sourceId }) => {
    const url = `${config.ingest.url}sources/${sourceId}`;
    return startPolling({
      url,
      config: requestHeaders,
      interval: UPLOAD_POLLING_INTERVAL,
      onPollCheck,
    });
  };

  const getFileUploadAttrs = async ({ data }) => {
    const response = await axios.post(UPLOAD_REQUEST_URL, data, requestHeaders);
    const { attributes } = response.data.data;
    if (!attributes?.id) {
      throw new Error('Upload request failed. No attribute id returned.');
    }
    return attributes;
  };

  const uploadFile = ({ sourceUrl, file }) =>
    axios.put(sourceUrl, file, {
      headers: {
        'Content-Type': '',
      },
    });

  const handleBrowseFiles = (e) => {
    e.stopPropagation();
    fileInput.current.click();
  };

  const handleFileChange = useCallback(async () => {
    const [file] = fileInput.current?.files || [];
    if (!file) return;

    setIsUploading(true);
    setUploadStatus('Uploading...');

    try {
      const [type] = file.type.split('/');
      const options = await getMediaDimensions(file);
      const requestOptions = getRequestOptions({ type, options });
      if (!requestOptions) throw new Error('Unsupported file type');

      const { id: sourceId, url: sourceUrl } = await getFileUploadAttrs({ data: requestOptions });
      await uploadFile({ sourceUrl, file });

      setUploadStatus('Processing...');

      const response = await getStatus({ sourceId });
      onUploadComplete({ ...response.data.data.attributes, type: 'mask' });
    } catch (error) {
      console.error(error);
      setUploadError('Sorry, there was a problem uploading your file.');
    } finally {
      setUploadStatus('Upload');
      setIsUploading(false);
      // Reset the file input value to allow selecting the same file again
      if (fileInput.current) {
        fileInput.current.value = '';
      }
    }
  }, [fileInput, getMediaDimensions, getFileUploadAttrs, uploadFile, getStatus, onUploadComplete]);

  return (
    <>
      <ButtonElement className="w-100" handleEvent={handleBrowseFiles} variant="primary" disabled={isUploading}>
        {uploadStatus}
      </ButtonElement>
      <input
        ref={fileInput}
        type="file"
        accept="image/*, video/mp4"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      {uploadError && <p className="input-error">{uploadError}</p>}
    </>
  );
}
export default MaskSourceInput;
