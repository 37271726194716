import { checkRangeOverlap } from '@utils/tracks';

export const getClipEnd = (clip) => clip.start + clip.length;

export const getClipsGroupedByIntersect = (clips, newClip) =>
  clips
    .sort((a, b) => a.start - b.start)
    .reduce(
      (acc, trackClip) => {
        const { isIntersecting, intersection } = checkRangeOverlap(newClip, trackClip);
        const group = isIntersecting ? 'intersecting' : 'complements';
        const data = { ...trackClip, intersection };
        acc[group].push(data);
        return acc;
      },
      { intersecting: [], complements: [] }
    );

export const multipleIntersectingClips = ({ intersecting, newClip }) => {
  const [firstTarget, secondTarget] = intersecting;
  const firstTargetEnd = getClipEnd(firstTarget);

  const targetDiff = secondTarget.start - firstTargetEnd;
  const space = newClip.length - targetDiff;

  return { newStart: firstTargetEnd, space };
};

export const singleIntersectingClip = ({ intersecting, complements, newClip }) => {
  const [target] = intersecting;
  const { start, whole, exact } = target?.intersection || {};

  let space = 0;
  let newStart = newClip.start;

  if ([start, whole, exact].some(Boolean)) {
    // start, whole or exact intersection
    // shuffle all remaining clips
    space = getClipEnd(newClip) - target.start;
  } else {
    // end or middle intersection
    // shuffle all clips after new clip start
    newStart = target.start + target.length;
    const newEnd = newStart + newClip.length;

    const nextClip = complements.find((c) => c.start >= newStart) || {};
    const overlappingNext = newEnd > nextClip.start;

    space = overlappingNext ? newEnd - nextClip.start : 0;
  }

  return { newStart, space };
};
