import { Card } from 'react-bootstrap';

import Content from '@components/content/Content';
import ResourceDeleteButton from '@components/resources/ResourceDeleteButton';

function ResourceDangerZone({ id, service, resource }) {
  if (!resource?.status) {
    return null;
  }

  return (
    <Content heading="DELETE ASSET" className="mt-4">
      <Card>
        <Card.Body>
          <p className="text-small">
            This action cannot be undone. It will permanently delete the source and its assets.
          </p>

          <ResourceDeleteButton id={id} service={service} disabled={resource?.status === 'deleted'} />
        </Card.Body>
      </Card>
    </Content>
  );
}

export default ResourceDangerZone;
