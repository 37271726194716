import config from '@config';
import { Col, Row } from 'react-bootstrap';

import DidForm from '@feature/integrations/providers/did/DidForm';

import AlphaBadge from '@components/badge/alpha';
import ContentCard from '@components/cards/ContentCard';
import Header from '@components/header/Header';

function Did() {
  const Forms = config.integration.stages.map((stage, index) => (
    <DidForm key={stage.stage} stage={stage.stage} stageName={stage.name} index={index} />
  ));

  return (
    <div className="integration">
      <Header title="D-ID Text-to-Avatar">
        Create and interact with talking avatars at the touch of a button, to increase engagement and reduce costs.
      </Header>
      <Row className="mb-4">
        <Col md={4}>
          <ContentCard image={`/img/provider/d-id.png`}>
            <AlphaBadge />
            D-ID provides you with text-to-avatar functionality. Bring an extra dimension to your videos or applications
            with dynamic avatars.
            <br /> <br />
            *Note that this integration is currently on alpha and is subject to change.
          </ContentCard>
        </Col>
        <Col md={8}>
          <ContentCard heading="SETUP">
            <p>
              Sign up to <a href="https://www.d-id.com/">D-ID</a> if you do not already have an account. Copy your API
              Key and paste it in the form below.
            </p>
          </ContentCard>
          <ContentCard heading="CREDENTIALS">{Forms}</ContentCard>
          <ContentCard heading="INTEGRATION">
            <p>
              Select a <a href="https://shotstack.io/docs/api/#tocs_didtexttoavataroptions">voice</a> and send the
              following JSON payload in the body of the request to generate an asset:
            </p>
            <pre>
              <b>POST</b> https://api.shotstack.io/create/&#123;ENV&#125;/assets
            </pre>
            <p>
              Replace <code>&#123;ENV&#125;</code> with either stage or v1 in the URL above depending on the environment
              and API key you are using.
            </p>
            <p>Send the following JSON payload in the body of the request to generate an asset:</p>
            <pre>{`{
    "provider": "d-id",
    "options": {
        "type": "text-to-avatar",
        "text": "This is a text to avatar asset generated by D-ID",
        "avatar": "amy",
        "background": "#ffffff"
    }
}
`}</pre>
            <p>
              Check the <a href="https://shotstack.io/docs/api/#tocs_didgeneratedasset">API docs</a> for more
              information.
            </p>
          </ContentCard>
        </Col>
      </Row>
    </div>
  );
}

export default Did;
