import '@css/OutputPresetButtons.css';

import OutputPresetButton from '@feature/studio/configuration/OutputPresetButton';

function OutputPresetButtons({ presets, selectedType, handleSelectPreset }) {
  return (
    <>
      {Object.entries(presets).map(([type, { title, icon, caption }]) => (
        <OutputPresetButton
          key={type}
          type={type}
          title={title}
          icon={icon}
          caption={caption}
          selected={selectedType === type}
          onClick={() => handleSelectPreset(type)}
          onDoubleClick={() => handleSelectPreset(type, true)}
        />
      ))}
    </>
  );
}

export default OutputPresetButtons;
