import { useNavigate } from 'react-router-dom';

import ButtonElement from '@components/atoms/ButtonElement';

function WorkflowEditButton({ id, name, children }) {
  const history = useNavigate();

  const handleEditClick = () => {
    history(`/workflows/${id}/edit`);
  };

  const editTracking = {
    name: 'Select Edit Workflow',
    properties: {
      Id: id,
      'Workflow Name': name,
    },
  };

  return (
    <ButtonElement
      className="whitespace-nowrap"
      title="Edit"
      handleEvent={handleEditClick}
      variant="light"
      tracking={editTracking}
    >
      {children || 'Edit Workflow'}
    </ButtonElement>
  );
}

export default WorkflowEditButton;
