import cn from 'classnames';

import { IconSlashCircle } from '@assets/icons';

import VideoHover from '@components/video/VideoHover';

function Effect({ effect, label, value, active, onSelect = () => {} }) {
  const isNone = label === 'None';
  const isDisabled = !effect && !isNone;
  if (isDisabled) {
    return null;
  }
  return (
    <div className={cn('property-preview effect', { active, empty: !effect })}>
      <button type="button" className="unstyled" onClick={() => onSelect(value)}>
        {isNone ? (
          <span className="property-preview__placeholder">
            <IconSlashCircle size={44} />
          </span>
        ) : (
          <VideoHover src={effect} muted />
        )}
        <p className="uppercase-label">{label}</p>
      </button>
    </div>
  );
}

export default Effect;
