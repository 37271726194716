import { useEffect, useState } from 'react';

import { listBuiltInTemplates } from '@api/services/builtInTemplate';

/** @typedef {import('@api/services/builtInTemplate').Template} WorkflowTemplate */

const useWorkflowTemplates = () => {
  /** @type {ReturnType<typeof import('react').useState<WorkflowTemplate[]>>} */
  const [workflowTemplates, setWorkflowTemplates] = useState([]);

  const loadTemplates = async () => {
    const templates = await listBuiltInTemplates('workflow');
    setWorkflowTemplates(templates);
  };

  useEffect(() => {
    loadTemplates();
  }, []);

  return workflowTemplates;
};

export default useWorkflowTemplates;
