import { useRecoilState } from 'recoil';

import CheckboxElement from '@components/atoms/CheckboxElement';

import { renderFastPreviewAtom } from '@store/Renders';

function PreviewQualityFields(tracking) {
  const [previewQuality, setPreviewQuality] = useRecoilState(renderFastPreviewAtom);

  const toggleQuality = (e) => {
    const { checked } = e.target;
    setPreviewQuality(checked);
  };

  return (
    <div className="popover-controls__inner">
      <div className="popover-controls__row d-flex align-items-center gap-2">
        <span className="uppercase-label mb-0">NORMAL</span>
        <CheckboxElement
          name="quality"
          type="switch"
          checked={previewQuality}
          handleEvent={toggleQuality}
          tracking={{
            ...tracking,
            name: 'Change Quality',
          }}
        />
        <span className="uppercase-label mb-0">FAST</span>
      </div>
    </div>
  );
}

export default PreviewQualityFields;
