import { IconCode } from '@assets/icons';

import Asset from '@components/asset/Asset';

function AssetHtml() {
  return (
    <Asset icon={<IconCode />}>
      <IconCode size={16} color="#212529" className="p-2" />
    </Asset>
  );
}

export default AssetHtml;
