import { useState } from 'react';

import { IconFiles } from '@assets/icons';

import ButtonElement from '@components/atoms/ButtonElement';

import useDuplicateTemplate from '@hooks/useDuplicateTemplate';

function TemplateDuplicate({ templateId }) {
  const [show, setShow] = useState(false);
  const { handleDuplicate, loading } = useDuplicateTemplate({
    onSuccess: () => setShow(false),
  });

  return (
    <ButtonElement
      title="Duplicate"
      handleEvent={() => handleDuplicate(templateId)}
      variant="light"
      size="sm"
      loading={loading}
      disabled={show}
      className="d-flex align-items-center gap-2"
    >
      <IconFiles size="18" /> Duplicate
    </ButtonElement>
  );
}

export default TemplateDuplicate;
