export const transformIntegrationAxios = [
  async (response) => {
    const { data: integration } = JSON.parse(response);
    return integration.reduce((acc, cur) => {
      if (cur?.attributes?.stage) {
        acc[cur.attributes.stage] = cur.attributes;
      }
      return acc;
    }, {});
  },
];
