export const PRESETS_SELECT = [
  { value: 'conference', label: 'Conference' },
  { value: 'interview', label: 'Interview' },
  { value: 'lecture', label: 'Lecture' },
  { value: 'meeting', label: 'Meeting' },
  { value: 'mobile_phone', label: 'Mobile Phone' },
  { value: 'music', label: 'Music' },
  { value: 'podcast', label: 'Podcast' },
  { value: 'studio', label: 'Studio' },
  { value: 'voice_over', label: 'Voice Over' },
];
