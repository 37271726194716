import cn from 'classnames';

import { IconDoorOpen, IconLightningCharge, IconNutBolt } from '@assets/icons';

import { MODULE_ATTRIBUTES, TRIGGER_OPTIONS } from '@constants/Workflows';

const HeaderIconMap = {
  trigger: IconLightningCharge,
  end: IconDoorOpen,
  default: IconNutBolt,
};

function WorkflowTaskIcon(props) {
  const { type, module, containerClass, ...restProps } = props;
  const { logoUrl } = MODULE_ATTRIBUTES[module] || TRIGGER_OPTIONS[module] || {};
  const HeaderIcon = HeaderIconMap[type] || HeaderIconMap.default;

  return (
    <div className={cn(containerClass, { isImg: Boolean(logoUrl) })}>
      {logoUrl ? <img src={logoUrl} alt="" /> : <HeaderIcon {...restProps} />}
    </div>
  );
}

export default WorkflowTaskIcon;
