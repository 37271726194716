import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';

import '@css/Renders.css';

import GeneratedService from '@api/services/generated';
import RendersService from '@api/services/renders';
import ServeService from '@api/services/serve';
import SourcesService from '@api/services/sources';

import { useAnalytics } from '@components/analytics/Analytics';
import Layout from '@components/layout/Layout';
import ResourceFilter from '@components/resources/ResourceFilter';
import ResourceHistory from '@components/resources/ResourceHistory';

import useGetResourceHistory from '@hooks/useGetResourceHistory';

const productMap = {
  'edit-api': {
    label: 'Edit API',
    title: 'Rendered Assets',
    service: RendersService,
  },
  'ingest-api': {
    label: 'Ingest API',
    title: 'Uploads & Renditions',
    service: SourcesService,
  },
  'create-api': {
    label: 'Create API',
    title: 'Generated Assets',
    service: GeneratedService,
  },
  'serve-api': {
    label: 'Serve API',
    title: 'Hosted Assets',
    service: ServeService,
  },
};

const defaultValues = {
  startDateRange: dayjs().subtract(1, 'months').startOf('month').format('YYYY-MM-01T00:00'),
  endDateRange: dayjs().add(24, 'hours').format('YYYY-MM-DDT00:00'),
  status: '',
  limit: '',
  offset: '',
};

function ResourceList() {
  const { trackEvent } = useAnalytics();
  const { product } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, loading, fetchData } = useGetResourceHistory(productMap[product].service);

  const updateFilter = (params) => {
    const newSearchParams = new URLSearchParams(searchParams);
    ['startDateRange', 'endDateRange', 'status', 'limit', 'offset'].forEach((key) =>
      newSearchParams.set(key, params[key])
    );
    setSearchParams(newSearchParams, { replace: true });
    fetchData(params);
  };

  useEffect(() => {
    trackEvent('View Render History');
  }, [trackEvent]);

  useEffect(() => {
    updateFilter({
      startDateRange: searchParams.get('startDateRange') || defaultValues.startDateRange,
      endDateRange: searchParams.get('endDateRange') || defaultValues.endDateRange,
      status: searchParams.get('status') || defaultValues.status,
      limit: searchParams.get('limit') || defaultValues.limit,
      offset: searchParams.get('offset') || defaultValues.offset,
    });
  }, [searchParams]);

  return (
    <Layout>
      <Card className="p-3 mb-10">
        <Card.Body>
          <p className="uppercase-label">{productMap[product].label}</p>
          <h1>{productMap[product].title}</h1>

          <ResourceFilter
            product={product}
            startDateRange={searchParams.get('startDateRange')}
            endDateRange={searchParams.get('endDateRange')}
            status={searchParams.get('status')}
            limit={searchParams.get('limit')}
            offset={searchParams.get('offset')}
            onFilterChange={updateFilter}
          />
        </Card.Body>
      </Card>

      <ResourceHistory data={data} loading={loading} product={product} />
    </Layout>
  );
}

export default ResourceList;
