import { selector } from 'recoil';

import onboardingService from '@api/services/onboarding';

import { authReadySelector, authenticatedUserAtom } from '@store/Auth';

// Define types for the API responses
type Subscription = {
  id: string;
  status: string;
  current_period_end: string;
  current_period_start: string;
  plan?: {
    id: string;
    name: string;
    amount: number;
  };
  // Add other subscription fields as needed
};

type Subscriber = {
  id: string;
  email?: string;
  stripe_id?: string | null;
  subscriptions?: Subscription[];
  // Add other subscriber fields as needed
};

/**
 * Selector to fetch all available subscriptions
 * Used by subscription-related components to display available plans
 */
export const subscriptionsSelector = selector<Subscription[]>({
  key: 'subscriptions/all',
  get: async () => {
    const response = await onboardingService.getSubscriptions();
    return response;
  },
});

/**
 * Selector to fetch the current user's subscriber information
 * Used to display current subscription status and manage subscriptions
 */
export const subscriberSelector = selector<Subscriber | null>({
  key: 'subscriptions/subscriber',
  get: async ({ get }) => {
    await get(authReadySelector);
    const authUser = get(authenticatedUserAtom);
    const response = await onboardingService.getSubscriber(authUser?.['stripe_id'] ?? null);
    return response;
  },
});
