import { useRecoilValue } from 'recoil';

import WorkflowTaskBlock from '@feature/workflows/task/WorkflowTaskBlock';

import { workflowWebhookUrlSelector } from '@store/Workflows';

import { TRIGGER_OPTIONS } from '@constants/Workflows';

function Trigger({ active, module, handleEditTask, handleAddTask }) {
  const webhookUrl = useRecoilValue(workflowWebhookUrlSelector);

  return (
    <WorkflowTaskBlock
      type="trigger"
      active={active}
      connected={false}
      onClick={handleEditTask}
      onAddTask={handleAddTask}
    >
      <WorkflowTaskBlock.Header type="trigger" module={module}>
        {TRIGGER_OPTIONS[module]?.label || 'Select a Trigger'}
      </WorkflowTaskBlock.Header>
      <WorkflowTaskBlock.Footer>
        {!module && <code>A trigger will begin your workflow</code>}
        {module === 'shotstack:webhook' && <code>{webhookUrl}</code>}
        {module === 'shotstack:csv' && <code>Manual Upload</code>}
      </WorkflowTaskBlock.Footer>
    </WorkflowTaskBlock>
  );
}

export default Trigger;
