import { Card, Col, Row } from 'react-bootstrap';

function BillingCardDetails(props) {
  const { card } = props;

  if (!card.number) {
    return <span>No default payment method found. Please add a new card.</span>;
  }
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={4}>
            <p className="uppercase-label">Credit Card</p>
            <p className="text-lg font-bold">
              {card.brand} ending in {card.number}
            </p>
          </Col>
          <Col md={4}>
            <p className="uppercase-label">Name on Card</p>
            <p className="text-lg font-bold">{card.name}</p>
          </Col>
          <Col md={4}>
            <p className="uppercase-label">Expires on</p>
            <p className="text-lg font-bold">{card.expiry}</p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default BillingCardDetails;
