import InputSelect from '@components/controls/InputSelect';
import InputText from '@components/controls/InputText';

import useDestinationFormValidation from '@hooks/useDestinationFormValidation';

const viewOptions = [
  { value: 'anybody', text: 'Anybody' },
  { value: 'nobody', text: 'Nobody' },
  { value: 'contacts', text: 'Contacts' },
  { value: 'password', text: 'Password' },
  { value: 'unlisted', text: 'Unlisted' },
];

const embedOptions = [
  { value: 'public', text: 'Public' },
  { value: 'private', text: 'Private' },
  { value: 'whitelist', text: 'Whitelist' },
];

const commentsOptions = [
  { value: 'anybody', text: 'Anybody' },
  { value: 'nobody', text: 'Nobody' },
  { value: 'contacts', text: 'Contacts' },
];

const vimeoOptions = [
  { label: 'name', type: 'text', placeholder: 'The name of your video', required: true },
  { label: 'description', type: 'text', placeholder: 'A description of your video', required: true },
  { label: 'view', type: 'select', options: viewOptions, placeholder: 'Anybody', required: false },
  { label: 'embed', type: 'select', options: embedOptions, placeholder: 'Public', required: false },
  { label: 'comments', type: 'select', options: commentsOptions, placeholder: 'Anybody', required: false },
];

function Vimeo({ destination, setDestination, onValidationChange }) {
  const { invalidFields, handleOptionsChange } = useDestinationFormValidation(
    vimeoOptions,
    destination,
    setDestination,
    onValidationChange
  );

  return (
    <div className="d-flex flex-column gap-2">
      {vimeoOptions.map(({ label, placeholder, type, options }) =>
        type === 'select' ? (
          <>
            <label htmlFor={label} className={`form-label ${invalidFields.includes(label) ? 'text-danger' : ''}`}>
              {label}
            </label>
            <InputSelect
              key={label}
              name={label}
              id={label}
              selected={destination?.options?.[label]}
              placeholder={''}
              title={label}
              values={options}
              handleChange={handleOptionsChange}
              isInvalid={invalidFields.includes(label)}
            />
          </>
        ) : (
          <InputText
            key={label}
            name={label}
            label={label}
            placeholder={placeholder}
            value={destination?.options?.[label]}
            handleChange={handleOptionsChange}
            isInvalid={invalidFields.includes(label)}
          />
        )
      )}
    </div>
  );
}

export default Vimeo;
