import cn from 'classnames';
import { memo } from 'react';
import { useRecoilValue } from 'recoil';

import { IconEnd, IconStart } from '@assets/icons';

import { clipTimelineSelectorFamily, useUpdateClipTimingProperty } from '@store/EditSelectors';

function TimelineClipInfo({ id }) {
  const clip = useRecoilValue(clipTimelineSelectorFamily(id));
  const updateClipTimingProperty = useUpdateClipTimingProperty(id);
  const updateTiming = (prop) => () => updateClipTimingProperty(prop);

  return (
    <div className="clip__info">
      <div
        className={cn('clip__attr', `clip__timing clip__timing--${clip?.meta?.start}`)}
        onDoubleClick={updateTiming('start')}
      >
        <IconStart size={12} />
        <span>{clip?.meta?.start === 'number' ? '123' : clip?.meta?.start}</span>
      </div>

      <div
        className={cn('clip__attr', `clip__timing clip__timing--${clip?.meta?.length}`)}
        onDoubleClick={updateTiming('length')}
      >
        <IconEnd size={12} />
        <span>{clip?.meta?.length === 'number' ? '123' : clip?.meta?.length}</span>
      </div>
    </div>
  );
}

export default memo(TimelineClipInfo);
