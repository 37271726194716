/* eslint-disable react/destructuring-assignment */
import { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, message: 'An error occurred.' };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
      message: this.props.message,
    });

    console.error(error, errorInfo);
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <h3>Oh no, something went wrong.</h3>
          <p>{this.state.message}</p>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
