import Preload from 'preload-it';
import { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import '@css/AssetLoader.css';

import {
  assetLoaderErrorAtom,
  assetLoaderIsLoadingAtom,
  assetLoaderProgressAtom,
  assetLoaderRegistryAtom,
} from '@store/Assets';
import { assetListSelector } from '@store/EditSelectors';

import { getFontFace } from '@utils/fonts';

const DOWNLOAD_TIMEOUT = 30000;

const loadFonts = async (fonts) => {
  const fontsLoaded = await Promise.all(fonts.map((src) => getFontFace({ src })));
  fontsLoaded.forEach((font) => document.fonts.add(font));
};

const getAssetsToPreload = (assets, assetsLoadedRegistry) =>
  assets.filter((src) => src && !assetsLoadedRegistry.includes(src)).map((src) => src);

function AssetLoader() {
  const setLoaderError = useSetRecoilState(assetLoaderErrorAtom);
  const setAssetLoaderProgress = useSetRecoilState(assetLoaderProgressAtom);
  const setAssetsLoading = useSetRecoilState(assetLoaderIsLoadingAtom);
  const [assetsLoadedRegistry, setAssetsLoadedRegistry] = useRecoilState(assetLoaderRegistryAtom);
  const assets = useRecoilValue(assetListSelector);
  const preloadTimeout = useRef(null);

  useEffect(() => {
    // seperated media and fonts to load fonts after media has been loaded
    const mediaToPreload = getAssetsToPreload(assets.media, assetsLoadedRegistry);
    const fontsToPreload = getAssetsToPreload(assets.fonts, assetsLoadedRegistry);
    const assetsToPreload = [...mediaToPreload, ...fontsToPreload];

    if (!assetsToPreload.length) {
      setAssetsLoading(false);
      return;
    }

    if (!preloadTimeout.current) {
      preloadTimeout.current = setTimeout(() => {
        setAssetsLoading(false);
      }, DOWNLOAD_TIMEOUT);
    }

    setAssetsLoading(true);
    setAssetsLoadedRegistry((prevState) => [...prevState, ...assetsToPreload]);

    const preload = Preload();
    preload.fetch(assetsToPreload);

    preload.onprogress = (event) => {
      setAssetLoaderProgress(event.progress);
    };

    preload.oncomplete = () => {
      setAssetLoaderProgress(100);
      setAssetsLoading(false);
      if (fontsToPreload.length) {
        loadFonts(fontsToPreload);
      }
    };

    preload.onerror = () => {
      setAssetsLoading(false);
      setLoaderError(true);

      clearTimeout(preloadTimeout.current);
    };

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(preloadTimeout.current);
    };
  }, [assets]);

  return null;
}

export default AssetLoader;
