import { useEffect } from 'react';
import { Button } from 'react-bootstrap';

import { useAuth0 } from '@context/Auth0ReactSPA';

import UsageData from '@feature/usage/UsageData';

import { useAnalytics } from '@components/analytics/Analytics';
import Layout from '@components/layout/Layout';

function Usage() {
  const { loading, user } = useAuth0();
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent('View Usage');
  }, [trackEvent]);

  if (loading || !user) {
    return <div />;
  }

  return (
    <Layout>
      <div className="text-prose mb-16">
        <p className="uppercase-label">Billing</p>
        <h1>Usage</h1>
        <p className="text-gray-500">
          <Button href="/subscription">Purchase more credits</Button>
        </p>
      </div>

      <UsageData />
    </Layout>
  );
}

export default Usage;
