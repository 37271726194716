import cn from 'classnames';
import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { stageAtom } from '@store/Auth';

import { stageColorsMap, stageLabelsMap } from '@constants/Stage';

function NavbarEnvIndicator() {
  const [stage] = useRecoilState(stageAtom);
  const color = stageColorsMap[stage?.toUpperCase()] || stageColorsMap[stage];
  const label = stageLabelsMap[stage?.toUpperCase()] || stageLabelsMap[stage];

  return (
    <div className={cn('env-indicator', stage)}>
      <Link to="/keys">
        <Badge bg={color}>{label?.toUpperCase()}</Badge>
      </Link>
    </div>
  );
}

export default NavbarEnvIndicator;
