import axios from 'axios';

import requestInterceptors from '@api/interceptors/request';
import responseInterceptors from '@api/interceptors/response';
import pollingInterceptors from '@api/interceptors/responsePolling';
import { transformResponseAxiosDefault } from '@api/transform/default';

export default {
  init: (config) => {
    const axiosConfig = {
      transformResponse: transformResponseAxiosDefault,
      ...config,
    };
    const instance = axios.create(axiosConfig);
    instance.interceptors.request.use(...requestInterceptors);
    instance.interceptors.response.use(...responseInterceptors);
    instance.interceptors.response.use(...pollingInterceptors(instance));
    return instance;
  },
};
