import { Scrollbar } from 'react-scrollbars-custom';

import { IconCircle, IconLine, IconRectangle } from '@assets/icons';

import ButtonElement from '@components/atoms/ButtonElement';

import { useAddClipToTimelineCallback } from '@store/Media';

const shapeExamples = [
  {
    label: 'Rectangle',
    Icon: IconRectangle,
    properties: {
      shape: 'rectangle',
    },
  },
  {
    label: 'Circle',
    Icon: IconCircle,
    properties: {
      shape: 'circle',
    },
  },
  {
    label: 'Line',
    Icon: IconLine,
    properties: {
      shape: 'line',
    },
  },
];

const ShapeButton = ({ shape, handleAddShape }) => (
  <ButtonElement variant="light" className="panel-button" handleEvent={handleAddShape(shape.properties)}>
    <shape.Icon size={44} />
    <span className="muted">{shape.label}</span>
  </ButtonElement>
);

function PanelShapeDefault() {
  const addClipToTimeline = useAddClipToTimelineCallback();

  const handleAddShape = (properties) => () => {
    addClipToTimeline({ data: properties, type: 'shape' });
  };

  return (
    <div className="workspace__panel-inner">
      <Scrollbar noScrollX style={{ width: '100%', height: '100%' }}>
        <div className="mb-4">Add Shape</div>
        <div className="d-flex flex-col gap-2 mb-10">
          <div className="preset-group">
            {shapeExamples.map((shape, index) => (
              <ShapeButton key={index} shape={shape} handleAddShape={handleAddShape} />
            ))}
          </div>
        </div>
      </Scrollbar>
    </div>
  );
}

export default PanelShapeDefault;
