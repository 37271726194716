import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';

import '@css/Dashboard.css';

import LinkElement from '@components/atoms/LinkElement';
import ContentCard from '@components/cards/ContentCard';
import CodeEditor from '@components/code/CodeEditor';
import PrismSnippet from '@components/code/PrismSnippet';
import Layout from '@components/layout/Layout';
import StudioEditor from '@components/studio-sdk/StudioEditor';

import data from '@data/template/studio-sdk-example.json';

function StudioSdk() {
  const [isJsonValid, setIsJsonValid] = useState(true);

  const [editorData, setEditorData] = useState(data);
  const [eventHandlerData, setEventHandlerData] = useState(
    '"Interact with the Studio SDK to see the event handler in action"'
  );

  const [interactive, setInteractive] = useState(true);
  const [timeline, setTimeline] = useState(true);
  const [sidepanel, setSidepanel] = useState(true);
  const [controls, setControls] = useState(true);
  const [style, setStyle] = useState({
    colors: {
      primaryColor: '#2ecc71',
      secondaryColor: '#fd79a8',
    },
    logo: {
      url: 'https://shotstack-assets.s3.amazonaws.com/icons/unicorn.svg',
    },
  });

  const handleUpdateEvent = (data) => {
    // Your event handling logic here
    setEventHandlerData(JSON.stringify(data, null, 2));
  };

  const generateEmbedSnippet = () => {
    return `<script src="https://js.shotstack.io/studio/0.5.6/shotstack.min.js"></script>
<script>
  window.shotstack.create('studio', template, {
    owner: 'oknugu1pfd',
    interactive: true,
    timeline: true,
    sidepanel: true,
    controls: true,
    style: {
      logo: {
        url: 'https://shotstack-assets.s3.amazonaws.com/icons/unicorn.svg'
      },
      stylesheet: 'https://shotstack-studio-sdk.s3.amazonaws.com/styles/sdk-custom.css'
    },
  });
</script>`;
  };

  const generateLoadSnippet = () => {
    return `<script>
  window.shotstack.load(targetId, json, {});
</script>`;
  };

  const generateEventHandlerSnippet = () => {
    return `window.shotstack.on('update', (data) => {
  console.log(data);
});`;
  };

  const codeEditorChanges = (newJsonValue) => {
    try {
      const parsedData = JSON.parse(newJsonValue);
      setEditorData(parsedData);
      setIsJsonValid(true);
    } catch (error) {
      setIsJsonValid(false);
    }
  };

  const refreshEditor = () => {
    if (window.shotstack) {
      window.shotstack.refresh('studio-sdk-editor', { interactive, sidepanel, controls, timeline, style });
    }
  };

  const loadJson = () => {
    if (window.shotstack) {
      window.shotstack.load('studio-sdk-editor', editorData, {});
    }
  };

  const handleOptionChange = (option, value) => {
    if (option === 'interactive') setInteractive(value);
    if (option === 'timeline') setTimeline(value);
    if (option === 'sidepanel') setSidepanel(value);
    if (option === 'controls') setControls(value);
    if (option === 'primaryColor' || option === 'secondaryColor') {
      setStyle({
        ...style,
        colors: {
          [option]: value,
        },
      });
    }
    refreshEditor();
  };

  useEffect(() => {
    refreshEditor();
  }, [interactive, sidepanel, controls, timeline, style]);

  return (
    <Layout>
      <Card className="p-3 mb-10">
        <Card.Body>
          <div className="flex items-center justify-between">
            <div>
              <p className="uppercase-label">Studio</p>
              <h1>Studio SDK</h1>
              <p>
                The Studio SDK is a white-label video editor that you can embed into your applications, providing your
                users with video editing capabilities.
              </p>
            </div>
            <div>
              <div>
                <LinkElement
                  to="https://shotstack.io/docs/guide/studio-sdk/embedding-the-studio/"
                  title="View Documentation"
                  type="button"
                  external
                >
                  Documentation
                </LinkElement>
              </div>
              <div className="mt-2">
                <LinkElement
                  variant="light"
                  type="button"
                  external
                  to="https://github.com/shotstack/shotstack-studio-sdk"
                >
                  Github
                </LinkElement>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <ContentCard heading="CONFIGURE">
        <p>Use the options below to configure the Studio SDK.</p>
        <Row className="mx-auto my-2">
          <Col xl={12}>
            <Form>
              <div className="d-flex flex-wrap align-items-center">
                <Form.Check
                  className="me-3"
                  type="switch"
                  id="interactive-toggle"
                  label="Interactive"
                  checked={interactive}
                  onChange={(e) => handleOptionChange('interactive', e.target.checked)}
                />
                <Form.Check
                  className="me-3"
                  type="switch"
                  id="controls-toggle"
                  label="Controls"
                  checked={controls}
                  onChange={(e) => handleOptionChange('controls', e.target.checked)}
                />
                <Form.Check
                  className="me-3"
                  type="switch"
                  id="sidepanel-toggle"
                  label="Sidepanel"
                  checked={sidepanel}
                  onChange={(e) => handleOptionChange('sidepanel', e.target.checked)}
                />
                <Form.Check
                  className="me-3"
                  type="switch"
                  id="timeline-toggle"
                  label="Timeline"
                  checked={timeline}
                  onChange={(e) => handleOptionChange('timeline', e.target.checked)}
                />
                <div className="me-3 d-flex align-items-center">
                  <Form.Label className="me-2">Primary Color:</Form.Label>
                  <Form.Control
                    type="color"
                    value={style.colors.primaryColor}
                    onChange={(e) => handleOptionChange('primaryColor', e.target.value)}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <Form.Label className="me-2">Secondary Color:</Form.Label>
                  <Form.Control
                    type="color"
                    value={style.colors.secondaryColor}
                    onChange={(e) => handleOptionChange('secondaryColor', e.target.value)}
                  />
                </div>
              </div>
            </Form>
          </Col>
        </Row>
        <StudioEditor
          owner="oknugu1pfd"
          interactive={interactive}
          timeline={timeline}
          sidepanel={sidepanel}
          controls={controls}
          style={style}
          template={data}
          onUpdateEvent={handleUpdateEvent}
        />
      </ContentCard>
      <ContentCard heading="EMBED">
        <Row className="mx-auto mt-5">
          <Col xl={12}>
            <p>
              Embed the Studio SDK into your application by adding the following script to your HTML, referencing a
              valid Shotstack JSON template.
            </p>
            <p>
              Make sure to reference your <code>owner ID</code>, which you can find with your{' '}
              <LinkElement to="/keys" title="API Keys">
                API keys
              </LinkElement>
              . Only on a subscription can you modify the Shotstack branding to your own.
            </p>
            <PrismSnippet text={generateEmbedSnippet()} language="javascript" className="mb-2" />
          </Col>
        </Row>
      </ContentCard>
      <ContentCard heading="INTERACT">
        <Row className="mx-auto mt-5">
          <Col xl={12}>
            <p>
              Interact with the editor or load JSON directly using the <code>window.shotstack.load()</code> method.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <CodeEditor value={editorData} onChange={codeEditorChanges} />
          </Col>
          <Col xl={6}>
            <PrismSnippet text={generateLoadSnippet()} language="javascript" className="mb-2" />
            <Button className="my-3" variant="primary" onClick={loadJson} disabled={!isJsonValid}>
              Load JSON
            </Button>
          </Col>
        </Row>
      </ContentCard>
      <ContentCard heading="EVENT HANDLERS">
        <Row className="mx-auto mt-5">
          <Col xl={12}>
            <p>Set up event handlers to run code in your application when users interact with the editor.</p>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <PrismSnippet text={generateEventHandlerSnippet()} language="javascript" className="mb-2" />
          </Col>
          <Col xl={6}>
            <PrismSnippet compact text={eventHandlerData} language="javascript" className="mb-2" />
          </Col>
        </Row>
      </ContentCard>
      <ContentCard heading="RENDER">
        <Row className="mx-auto mt-5">
          <Col xl={12}>
            <p>
              Interactions with the Studio update the <code>window.shotstack.edit</code> object. You can use this object
              to render your video using the Edit API.
            </p>
          </Col>
        </Row>
      </ContentCard>
    </Layout>
  );
}

export default StudioSdk;
