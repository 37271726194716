import { useEffect } from 'react';

import '@css/Register.css';

import logo from '@assets/img/logo.png';

import { useAuth0 } from '@context/Auth0ReactSPA';

import { useAnalytics } from '@components/analytics/Analytics';

function Register() {
  const { loginWithRedirect } = useAuth0();
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent('View Registration');
  }, []);

  loginWithRedirect({
    screen_hint: 'signup',
  });

  return (
    <section>
      <img src={logo} alt="Shotstack" className="logo" />
      <h3 className="text-center">Redirecting to registration...</h3>
    </section>
  );
}

export default Register;
