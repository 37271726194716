import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import generatedService from '@api/services/generated';

import GeneratedDetailsGenerated from '@feature/generated/GeneratedDetailsGenerated';

import { useAnalytics } from '@components/analytics/Analytics';
import Header from '@components/header/Header';
import Layout from '@components/layout/Layout';

import useFetchAndPoll from '@hooks/useFetchAndPoll';

function CreateApiDetail() {
  const { id } = useParams();
  const { trackEvent } = useAnalytics();
  const { data: generated } = useFetchAndPoll({ id, key: `create/generate/${id}`, fetch: generatedService.get });

  useEffect(() => {
    trackEvent('View Source Details', {
      Id: id,
    });
  }, []);

  return (
    <Layout className="pb-4">
      <Header title="Generated Asset Details" />
      <GeneratedDetailsGenerated generated={generated} />
    </Layout>
  );
}

export default CreateApiDetail;
