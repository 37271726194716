const formatFileSize = (size, format) => {
  if ((!size || size <= 0) && format === 'value') {
    return 0;
  }

  if ((!size || size <= 0) && format === 'suffix') {
    return 'GB';
  }

  const integer = Math.floor(Math.log(size) / Math.log(1024));

  if (format === 'value') {
    return `${(size / 1024 ** integer).toFixed(2) * 1}`;
  }

  if (format === 'suffix') {
    return `${['B', 'KB', 'MB', 'GB', 'TB', 'PB'][integer]}`;
  }

  return `${(size / 1024 ** integer).toFixed(1) * 1} ${['B', 'KB', 'MB', 'GB', 'TB', 'PB'][integer]}`;
};

export default formatFileSize;
