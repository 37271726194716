export const flattenToColonSeperatedProperties = (obj, prefix = '') => {
  return Object.keys(obj).reduce((acc, key) => {
    const newKey = prefix ? `${prefix}:${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      if (key === 'meta') {
        acc[newKey] = obj[key];
      } else {
        Object.assign(acc, flattenToColonSeperatedProperties(obj[key], newKey));
      }
    } else {
      acc[newKey] = obj[key];
    }
    return acc;
  }, {});
};

export const expandColonSeperatedProperties = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (key.includes(':')) {
      const parts = key.split(':');
      let current = acc;
      for (let i = 0; i < parts.length - 1; i++) {
        if (!current[parts[i]]) {
          current[parts[i]] = {};
        }
        current = current[parts[i]];
      }
      current[parts[parts.length - 1]] = obj[key];
    } else {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};
