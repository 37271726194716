import { IconFonts } from '@assets/icons';

import Asset from '@components/asset/Asset';

function AssetFont() {
  return (
    <Asset icon={<IconFonts />}>
      <IconFonts size={16} color="#212529" className="p-2" />
    </Asset>
  );
}

export default AssetFont;
