const SCOTT_KO_VIDEO_URL = 'https://github.com/shotstack/test-media/raw/main/captioning/scott-ko.mp4';
const EARTH_IMAGE_URL = 'https://shotstack-assets.s3-ap-southeast-2.amazonaws.com/images/earth.jpg';

export const ingestResizeVideoData = {
  url: SCOTT_KO_VIDEO_URL,
  outputs: {
    renditions: [
      {
        size: {
          width: 1280,
          height: 720,
        },
      },
    ],
  },
};

export const ingestResizeCropVideoData = {
  url: SCOTT_KO_VIDEO_URL,
  outputs: {
    renditions: [
      {
        size: {
          width: 720,
          height: 720,
        },
        fit: 'crop',
      },
    ],
  },
};

export const ingestConvertVideoFormat = {
  url: SCOTT_KO_VIDEO_URL,
  outputs: {
    renditions: [
      {
        format: 'webm',
      },
    ],
  },
};
export const ingestConvertImageFormat = {
  url: EARTH_IMAGE_URL,
  outputs: {
    renditions: [
      {
        format: 'webp',
      },
    ],
  },
};

export const ingestCreateTranscriptData = {
  url: SCOTT_KO_VIDEO_URL,
  outputs: {
    transcription: {
      format: 'srt',
    },
  },
};
