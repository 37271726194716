import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import ButtonElement from '@components/atoms/ButtonElement';

import { bulkDeleteAtom, useRemoveWorkflow } from '@store/Workflows';

function WorkflowDeleteBulkButton() {
  const [loading, setLoading] = useState(false);
  const bulkDelete = useRecoilValue(bulkDeleteAtom);
  const removeWorkflows = useRemoveWorkflow();

  const handleDelete = async () => {
    setLoading(true);
    await removeWorkflows();
    setLoading(false);
  };

  return (
    <ButtonElement
      handleEvent={handleDelete}
      variant="light"
      loading={loading}
      disabled={loading || bulkDelete.length === 0}
    >
      {bulkDelete.length === 0
        ? 'Bulk Delete Workflows'
        : `Bulk Delete ${bulkDelete.length} Workflow${bulkDelete.length > 1 ? 's' : ''}`}
    </ButtonElement>
  );
}

export default WorkflowDeleteBulkButton;
