import axios from 'axios';

import { transformProbeAxios } from '@api/transform/probe';

import getImageDimensions from '@utils/editor/getImageDimensions';

const fetchAssetMetaData = async (src, type) => {
  if (type === 'image') {
    const { width, height } = await getImageDimensions(src);
    return { attributes: { width, height } };
  }

  const url = `https://api.shotstack.io/v1/probe/${encodeURIComponent(src)}`;
  const { data } = await axios.get(url, { transformResponse: transformProbeAxios });
  return data;
};

export const getAssetMetaData = async (asset, mergeData) => {
  const notMediaAsset = !['video', 'audio', 'image'].includes(asset?.type);
  const isMergeAsset = (asset?.src || '').trim().startsWith('{{');
  if (!asset?.src || notMediaAsset) {
    return { attributes: {} };
  }

  let src = asset?.src;
  if (isMergeAsset) {
    const key = (asset?.src || '').replace(/{{\s*([^}\s]+)\s*}}/, '$1');
    src = (mergeData || []).find((field) => field.find === key)?.replace || '';
  }

  if (!src) {
    return {
      attributes: {},
    };
  }

  try {
    const response = await fetchAssetMetaData(src, asset?.type);
    return response;
  } catch (error) {
    console.error(error);
    return {
      attributes: {},
    };
  }
};
