import { isEqual } from 'lodash-es';
import { minimatch } from 'minimatch';
import { createContext, memo, useCallback, useContext, useMemo, useRef } from 'react';
import { useGotoRecoilSnapshot, useRecoilTransactionObserver_UNSTABLE } from 'recoil';

const UndoContext = createContext(null);

export const RecoilUndoRoot = memo(({ children, allowedKeys = [] }) => {
  const gotoSnapshot = useGotoRecoilSnapshot();

  const historyRef = useRef({
    past: [],
    present: {},
    future: [],
  });
  const isTrackingHistoryRef = useRef(false);
  const isUndoingRef = useRef(false);
  const isBatchingRef = useRef(false);

  function retainSnapshot(snapshot) {
    try {
      const release = snapshot.retain();
      return { snapshot, release };
    } catch (error) {
      // console.error('Error retaining snapshot:', error);
      return { snapshot };
    }
  }

  function releaseSnapshot({ release } = {}) {
    if (release) {
      release();
    }
  }

  useRecoilTransactionObserver_UNSTABLE(({ snapshot, previousSnapshot }) => {
    if (isUndoingRef.current || !isTrackingHistoryRef.current) {
      isUndoingRef.current = false;
      return;
    }

    if (isBatchingRef.current) {
      return;
    }

    // Compare snapshots, allowing the specified keys
    const modifiedNodes = snapshot.getNodes_UNSTABLE({ isModified: true, isInitialized: true });
    const hasChanged = Array.from(modifiedNodes || []).some((node) => {
      const key = node.key;
      if (!allowedKeys.some((pattern) => minimatch(key, pattern, { dot: true }))) {
        return false;
      }
      const { loadable, isSet, isModified } = snapshot.getInfo_UNSTABLE(node);
      const oldLoadable = previousSnapshot.getLoadable(node);
      return isSet && isModified && !isEqual(loadable.contents, oldLoadable.contents);
    });

    if (!hasChanged) {
      return;
    }

    releaseSnapshot(historyRef.current.present);
    historyRef.current = {
      past: [...historyRef.current.past, retainSnapshot(previousSnapshot)],
      present: retainSnapshot(snapshot),
      future: [],
    };
  });

  const undo = useCallback(() => {
    if (historyRef.current.past.length === 0) return;

    isUndoingRef.current = true;
    const previous = historyRef.current.past[historyRef.current.past.length - 1];

    if (!previous.snapshot) {
      // Reset history if there's no snapshot to revert to
      console.error('No snapshot available to undo.');
      historyRef.current = {
        past: [],
        present: historyRef.current.present,
        future: [...historyRef.current.future],
      };
      return;
    }

    const release = previous.snapshot.retain();

    // Ensure snapshot is retained and valid
    try {
      gotoSnapshot(previous.snapshot);
    } catch (error) {
      console.error('Error going to snapshot:', error);
      return; // Prevent state update if the snapshot is invalid
    } finally {
      if (release) {
        release();
      }
    }

    historyRef.current = {
      past: historyRef.current.past.slice(0, -1),
      present: retainSnapshot(previous.snapshot), // Re-retain the snapshot for current
      future: [retainSnapshot(historyRef.current.present.snapshot), ...historyRef.current.future], // Re-retain snapshots for future
    };
  }, [gotoSnapshot]);

  const redo = useCallback(() => {
    if (historyRef.current.future.length === 0) return;

    isUndoingRef.current = true;
    const next = historyRef.current.future[0];

    // Ensure snapshot is retained and valid
    try {
      gotoSnapshot(next.snapshot);
    } catch (error) {
      console.error('Error going to snapshot:', error);
      return; // Prevent state update if the snapshot is invalid
    } finally {
      releaseSnapshot(next);
    }

    historyRef.current = {
      past: [...historyRef.current.past, retainSnapshot(historyRef.current.present.snapshot)], // Re-retain snapshots for past
      present: retainSnapshot(next.snapshot), // Re-retain the snapshot for current
      future: historyRef.current.future.slice(1).map((item) => retainSnapshot(item.snapshot)), // Re-retain future snapshots
    };
  }, [gotoSnapshot]);

  const canUndo = () => historyRef.current.past.length > 0;
  const canRedo = () => historyRef.current.future.length > 0;

  const startBatch = useCallback(() => {
    isBatchingRef.current = true;
    historyRef.current = {
      ...historyRef.current,
      past: [...historyRef.current.past, retainSnapshot(historyRef.current.present.snapshot)],
    };
  }, []);

  const endBatch = useCallback(() => {
    isBatchingRef.current = false;
    historyRef.current = {
      ...historyRef.current,
      past: [...historyRef.current.past, retainSnapshot(historyRef.current.present.snapshot)],
    };
  }, []);

  const getIsTrackingHistory = useCallback(() => isTrackingHistoryRef.current, []);
  const setIsTrackingHistory = useCallback((value) => (isTrackingHistoryRef.current = value), []);

  const value = useMemo(() => {
    return {
      undo,
      redo,
      canUndo,
      canRedo,
      startBatch,
      endBatch,
      setIsTrackingHistory,
      getIsTrackingHistory,
    };
  }, [undo, redo, startBatch, endBatch, setIsTrackingHistory, getIsTrackingHistory]);

  return <UndoContext.Provider value={value}>{children}</UndoContext.Provider>;
});

export function useUndo() {
  const { undo, canUndo } = useContext(UndoContext);
  return { undo, canUndo };
}

export function useRedo() {
  const { redo, canRedo } = useContext(UndoContext);
  return { redo, canRedo };
}

export function useBatching() {
  const { startBatch, endBatch } = useContext(UndoContext);
  return { startBatch, endBatch };
}

export function useIsTrackingHistory() {
  const { setIsTrackingHistory, getIsTrackingHistory } = useContext(UndoContext);
  return { setIsTrackingHistory, getIsTrackingHistory };
}
