import { atom, selectorFamily } from 'recoil';

import { clipIdsAtom, clipsAtomFamily, clipsTracksAtomFamily } from '@store/Edit';
import { clipVisibilitySelectorFamily } from '@store/EditSelectors';

import { ASSET_TYPES_MASK } from '@constants/AssetTypes';

export const activeClipAtom = atom({
  key: 'activeClipAtom',
  default: null,
});

export const activeTrackAtom = atom({
  key: 'activeTrackAtom',
  default: null,
});

export const clipMaskState = selectorFamily({
  key: 'clipMaskState',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get:
    (clipId) =>
    ({ get }) => {
      const clips = get(clipIdsAtom).map((id) => ({
        /** @ts-ignore @type {{ start: number; length: number; }} */
        info: get(clipsAtomFamily(id)),
        /** @ts-ignore @type {{ trackIndex: number; clipId: number; }} */
        track: get(clipsTracksAtomFamily(id)),
        visible: get(clipVisibilitySelectorFamily(id)),
      }));

      const clipTrackIdx = clips.find((clip) => clip.track.clipId === clipId)?.track?.trackIndex;
      const activeMask = clips.find((clip) => {
        const isMask = ASSET_TYPES_MASK.includes(clip?.info['asset:type']);
        const isSameTrack = clip.track?.trackIndex === clipTrackIdx;
        const isClipVisible = clip.visible;
        return isMask && isSameTrack && isClipVisible;
      });

      return activeMask;
    },
});
