import { useState } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';

import MediaListingPreview from '@components/asset/MediaListingPreview';

import { useAddElementsToTimelineCallback } from '@store/Media';

import useIngestElement from '@hooks/useIngestElement';

import { lowerThirds } from '@constants/Elements';
import { masks } from '@constants/Masks';

const colors = {
  misc: 'Misc',
  lightorange: 'Light Orange',
  orange: 'Orange',
  red: 'Red',
  purple: 'Purple',
  pastel: 'Pastel',
  neon: 'Neon',
  blue: 'Blue',
  monochrome: 'Monochrome',
};

function PanelElementsDefault() {
  const [selectedColor, setSelectedColor] = useState('misc');
  const elements = lowerThirds[selectedColor];

  const addElementsToTimeline = useAddElementsToTimelineCallback();
  const { ingestElement } = useIngestElement();
  const [loading, setLoading] = useState(false);

  const handleAddElementToTimeline = (element) => async () => {
    if (loading) return;

    try {
      setLoading(element.id);
      const elementIn = await ingestElement({ source: element.in });
      const elementOut = await ingestElement({ source: element.out });
      addElementsToTimeline({ elements: [elementIn, elementOut] });
      setLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddBuiltInElementToTimeline = (elementUrl) => async () => {
    addElementsToTimeline({ elements: [{ id: elementUrl, source: elementUrl, type: 'mask' }] });
  };

  return (
    <div className="workspace__panel-inner">
      <Scrollbar noScrollX style={{ width: '100%', height: '100%' }}>
        <div className="mb-4">Add Element</div>

        <div className="media-listing">
          <h6>Lower Thirds</h6>
          <div className="flex gap-2 overflow-x-auto pb-2">
            <div className="flex gap-2">
              {Object.entries(colors).map(([key, text]) => (
                <button
                  key={key}
                  onClick={() => setSelectedColor(key)}
                  className={`btn whitespace-nowrap flex-shrink-0 ${selectedColor === key ? 'btn-primary' : 'btn-light'}`}
                >
                  {text}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="media-listing">
          <div className="media-listing__container">
            {elements &&
              elements.map((data) => (
                <MediaListingPreview
                  key={data.id}
                  media={data}
                  loading={loading === data.id}
                  onClick={handleAddElementToTimeline(data)}
                />
              ))}
          </div>
        </div>

        <div className="media-listing">
          <h6>Masks</h6>
        </div>

        <div className="media-listing">
          <div className="media-listing__container">
            {masks &&
              masks.map((maskUrl) => (
                <MediaListingPreview
                  key={maskUrl}
                  media={{ id: maskUrl, source: maskUrl, type: 'mask' }}
                  loading={false}
                  onClick={handleAddBuiltInElementToTimeline(maskUrl)}
                />
              ))}
          </div>
        </div>
      </Scrollbar>
    </div>
  );
}

export default PanelElementsDefault;
