import { useMemo, useState } from 'react';

const createFilter = ({ startDateRange, endDateRange, status, limit, offset }) => {
  const params = {
    filter: {
      created: {
        since: startDateRange,
        until: endDateRange,
      },
      ...(status && { status }),
    },
  };

  if (limit || offset) {
    params.page = {
      limit,
      offset,
    };
  }

  return params;
};

const useGetResourceHistory = (service) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useMemo(
    () => async (params) => {
      const hasNoParams = Object.values(params).every((value) => value === null);

      if (loading || hasNoParams) {
        return;
      }

      setLoading(true);
      try {
        const filters = createFilter(params);
        const response = await service.getAll(filters);
        setData(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [service]
  );

  return {
    data,
    loading,
    fetchData,
  };
};

export default useGetResourceHistory;
