import { Card, Row, Spinner } from 'react-bootstrap';

import { IconExclamationTriangleFill } from '@assets/icons';

function RenderPreviewStatus(props) {
  const { status } = props;

  return (
    <Card className="mb-2 editor-thumbnail-container">
      <Card.Body className="editor-thumbnail">
        <div className="d-flex flex-column align-items-center justify-content-center h-100">
          <Row>
            {status !== 'failed' ? (
              <Spinner animation="border" variant="secondary" />
            ) : (
              <IconExclamationTriangleFill style={{ fontSize: '2rem' }} />
            )}
          </Row>
          <Row className="mt-2">
            <small>{status.toUpperCase()}</small>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
}

export default RenderPreviewStatus;
