import WorkflowTaskBlock from '@feature/workflows/task/WorkflowTaskBlock';

import StatusBadge from '@components/status/StatusBadge';

function WorkflowJobTaskList({ tasks, activeTaskId, onClick }) {
  return (tasks || []).map((task, index) => {
    const { module, status } = task || {};

    return (
      <WorkflowTaskBlock
        key={task.id}
        active={activeTaskId === task.id}
        type={index === tasks.length - 1 ? 'end' : 'action'}
        onClick={() => onClick(task.id)}
        connected={true}
        readOnly
      >
        <WorkflowTaskBlock.Header type="action" module={module} actions={<StatusBadge status={status} />}>
          {task?.name}
        </WorkflowTaskBlock.Header>
      </WorkflowTaskBlock>
    );
  });
}

export default WorkflowJobTaskList;
