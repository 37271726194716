import { useRef } from 'react';
import { Card } from 'react-bootstrap';

function RenderPreviewVideo({ render }) {
  const videoRef = useRef();
  const previewUrl = `${render.url}#t=1.5`;

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
    }
  };

  return (
    <Card className="mb-2 editor-thumbnail-container">
      <div className="editor-thumbnail d-flex justify-content-center align-items-center">
        <video ref={videoRef} key={render.url} controls onPlay={handlePlay}>
          <source src={previewUrl} type="video/mp4" />
        </video>
      </div>
    </Card>
  );
}

export default RenderPreviewVideo;
