import config from '@config';
import { Col, Row } from 'react-bootstrap';

import GoogleCloudStorageForm from '@feature/integrations/providers/googleCloudStorage/GoogleCloudStorageForm';

import ContentCard from '@components/cards/ContentCard';
import Header from '@components/header/Header';

function GoogleCloudStorage() {
  const Forms = config.integration.stages.map((stage, index) => (
    <GoogleCloudStorageForm key={stage.stage} stage={stage.stage} stageName={stage.name} index={index} />
  ));

  return (
    <>
      <Header title="Google Cloud Storage">
        Send files directly to one or more Google Cloud Storage buckets directly from Shotstack.
      </Header>
      <Row className="mb-4">
        <Col md={4}>
          <ContentCard image={`/img/provider/gcp.png`}>
            Google Cloud Storage is an object storage service offering industry-leading scalability, data availability,
            security, and performance. Host your assets in the cloud and serve them using Cloud CDN.
          </ContentCard>
        </Col>
        <Col md={8}>
          <ContentCard heading="SETUP">
            <p>
              You will need to create a bucket in your own{' '}
              <a href="https://cloud.google.com/storage/docs/creating-buckets">GCP account</a>. A Service Account with{' '}
              <b>Storage Object Creator</b> or <b>Storage Object Admin</b> (file overwrite) permissions are needed to
              send files to your account from Shotstack. We recommend creating a new Service Account that only allow
              access to the buckets needed. Do not use a user with root or global permissions. Once that&apos;s been
              configured, you can add the Service Account JSON below.
            </p>
          </ContentCard>
          <ContentCard heading="CREDENTIALS">{Forms}</ContentCard>
          <ContentCard heading="INTEGRATION">
            <h5>Basic setup</h5>
            <p>
              To send files to Google Cloud Storage add the following to the output parameter of the render payload:
            </p>
            <pre>{`"destinations": [{
    "provider": "google-cloud-storage",
    "options": {
        "bucket": "my-gcp-bucket"
    }
}]`}</pre>
            <p>
              This will send the rendered files to the <b>my-gcp-bucket</b>. The filename will be the render id plus
              extension, i.e. <b>edc47d30-a504-4f16-8439-24c863a7a782.mp4</b> and will be saved to the root level of the
              bucket. Thumbnail and poster images will also be transferred.
            </p>
            <pre>{`"destinations": [{
    "provider": "google-cloud-storage",
    "options": {
        "prefix": "testing",
        "filename": "video",
        "bucket": "my-gcp-bucket"
    }
  }]`}</pre>
            <h5>Opting out from the Shotstack destination</h5>
            <p>
              By default all generated files are sent to the Shotstack destination. If you are using S3 you may wish to
              opt out from hosting you videos with Shotstack.
            </p>
            <pre>{`"destinations": [{
    "provider": "google-cloud-storage",
    "options": {
      "prefix": "testing",
      "filename": "video",
      "bucket": "my-gcp-bucket"
    }
},{
    "provider": "shotstack",
    "exclude": true
}]`}</pre>
            <p>
              This will send the rendered files to the <b>my-gcp-bucket</b>. If the rendered file is an mp4, the
              filename will be <b>video.mp4</b> and will be saved with a prefix <b>testing</b> (virtual path in the
              console).
            </p>
          </ContentCard>
        </Col>
      </Row>
    </>
  );
}

export default GoogleCloudStorage;
