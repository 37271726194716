import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ContentCard from '@components/cards/ContentCard';
import Header from '@components/header/Header';

function Hosting() {
  return (
    <>
      <Header title="Shotstack Hosting">
        Shotstack provides a media hosting service and CDN for all assets generated by the API. The service is enabled
        by default.
      </Header>
      <Row className="mb-4">
        <Col md={4}>
          <ContentCard image={`/img/provider/shotstack.png`}>
            Shotstack provides a media hosting service and CDN for all assets generated by the API. The service is
            enabled by default.
          </ContentCard>
        </Col>
        <Col md={8}>
          <ContentCard heading="SETUP">
            <p>
              Shotstack hosting and CDN is automatically set up with every account. All videos rendered via the API are
              automatically transferred to the hosting service. This lets you serve media files directly to your users
              without worrying about setting up another hosting service or copying files.
            </p>
            <p>
              You can use{' '}
              <a href="https://shotstack.io/docs/guide/serving-assets/self-host">your own hosting service</a> or one of
              our other <Link to="/integrations">destinations</Link>.
            </p>
            <p>
              The <a href="https://shotstack.io/docs/guide/serving-assets/hosting">Serve API</a> docs provide more
              information about Shotstack Hosting and CDN service.
            </p>
          </ContentCard>
          <ContentCard heading="INTEGRATION">
            All files are transferred to Shotstack hosting automatically, you do not need to do anything. If you wish to
            opt-out of Shotstack hosting you can add the following to the output parameter of the JSON:
            <pre>{`"destinations": [{
    "provider": "shotstack",
    "exclude": true
}]`}</pre>
          </ContentCard>
        </Col>
      </Row>
    </>
  );
}

export default Hosting;
