import CurrentSubscriptionDetails from '@feature/subscription/SubscriptionCurrentDetails';

import Skeleton from '@components/skeletons/Skeleton';

function SubscriptionCurrent({ subscription }) {
  if (!subscription) {
    return <Skeleton />;
  }

  return <CurrentSubscriptionDetails subscription={subscription} />;
}

export default SubscriptionCurrent;
