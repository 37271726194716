import cn from 'classnames';
import { memo, useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { IconKeyframe, IconKeyframeSelected } from '@assets/icons';

import ContextMenu from '@components/menus/ContextMenu';

import {
  activeClipKeyframeIdSelectorFamily,
  clipKeyframesSelectorFamily,
  useAddClipKeyframe,
  useRemoveClipKeyframe,
  useSelectClipKeyframe,
} from '@store/Keyframes';
import { timelineScaleAtom } from '@store/Timeline';

import getPixelSeconds from '@utils/getPixelSeconds';

function TimelineClipKeyframes({ id, x, y, clip, width }) {
  const keyframes = useRecoilValue(clipKeyframesSelectorFamily(id));
  const activeKeyframeId = useRecoilValue(activeClipKeyframeIdSelectorFamily(id));
  const timelineScale = useRecoilValue(timelineScaleAtom);
  const addKeyframe = useAddClipKeyframe(id);
  const removeKeyframe = useRemoveClipKeyframe(id);
  const selectKeyframe = useSelectClipKeyframe(id);
  const pixelSeconds = getPixelSeconds(timelineScale);

  const [contextMenu, setContextMenu] = useState(null);

  const points = keyframes.map(({ id, time }) => ({
    id,
    pos: pixelSeconds(time), // Todo: find a better way to position the keyframe under the playhead
    time,
  }));

  const clipStyles = {
    transform: `translate(${x}px, ${y}px)`,
    width: `${width}px`,
  };

  const handleAddKeyframe = (time) => () => {
    addKeyframe({ time });
    setContextMenu(null);
  };

  const handleKeyframeClick = (time) => () => {
    selectKeyframe(time);
  };

  const handleDeleteKeyframe = (time) => () => {
    removeKeyframe(time);
    setContextMenu(null);
  };

  const handleClipRightClick = useCallback(
    (e) => {
      e.preventDefault();
      const rect = e.currentTarget.getBoundingClientRect();
      const time = (e.clientX - rect.left) / pixelSeconds(1);

      setContextMenu({
        x: e.clientX,
        y: e.clientY,
        options: [
          {
            label: 'Add Keyframe',
            onClick: handleAddKeyframe(time),
          },
        ],
      });
    },
    [handleAddKeyframe, pixelSeconds]
  );

  const handleKeyframeRightClick = useCallback(
    (e, point) => {
      e.preventDefault();
      e.stopPropagation();
      setContextMenu({
        x: e.clientX,
        y: e.clientY,
        options: [
          {
            label: 'Delete Keyframe',
            onClick: handleDeleteKeyframe(point),
          },
        ],
      });
    },
    [handleDeleteKeyframe, pixelSeconds]
  );

  return (
    <div className={cn('clip', 'active', 'keyframing', clip['asset:type'])} style={clipStyles}>
      <div className="clip__inner">
        <div className="clip__keyframes" onContextMenu={handleClipRightClick}>
          {points.map(({ id, pos, time }) => (
            <button
              key={id}
              style={{ '--x': `${pos}px` }}
              className="unstyled clip__keyframe-point"
              onClick={handleKeyframeClick(time)}
              onContextMenu={(e) => handleKeyframeRightClick(e, time)}
            >
              {id === activeKeyframeId ? <IconKeyframeSelected /> : <IconKeyframe />}
            </button>
          ))}
        </div>
        {contextMenu && <ContextMenu instance={contextMenu} onClose={() => setContextMenu(null)} />}
      </div>
    </div>
  );
}

export default memo(TimelineClipKeyframes);
