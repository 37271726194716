import { Col, Row } from 'react-bootstrap';

import ContentCard from '@components/cards/ContentCard';
import Header from '@components/header/Header';

function TextToSpeech() {
  return (
    <>
      <Header title="Shotstack Text-to-Speech">
        Shotstack provides an AI text-to-speech service that converts text to spoken audio files.
      </Header>
      <Row className="mb-4">
        <Col md={4}>
          <ContentCard image={`/img/provider/shotstack.png`}>
            Generate voice overs in over 30 languages for your videos and applications using the Shotstack
            text-to-speech integration.
          </ContentCard>
        </Col>
        <Col md={8}>
          <ContentCard heading="SETUP">
            <p>
              Shotstack&apos;s text-to-speech technology is available to all paid subscribers via the Create API. Simply
              provide the text to be narrated, select the voice to use and Shotstack will automatically generate an mp3
              audio file that can be used in your video or application.
            </p>
          </ContentCard>
          <ContentCard heading="INTEGRATION">
            <h5>Basic setup</h5>
            <p>
              To generate an audio file from text, POST the following JSON payload to the Create API assets endpoint:
            </p>
            <pre>
              <b>POST</b> https://api.shotstack.io/create/&#123;ENV&#125;/assets
            </pre>
            <p>
              Replace <code>&#123;ENV&#125;</code> with either stage or v1 in the URL above depending on the environment
              and API key you are using.
            </p>
            <p>
              Select a <a href="https://shotstack.io/docs/api/#tocs_shotstacktexttospeechoptions">voice</a> and send the
              following JSON payload in the body of the request to generate an asset:
            </p>
            <pre>{`{
    "provider": "shotstack",
    "options": {
        "type": "text-to-speech",
        "text": "This is a text to speech asset generated by Shotstack",
        "voice": "Matthew"
    }
}`}</pre>

            <h5>Multiple Language Support</h5>
            <p>
              Optionally, you can also specify a language and the generated asset will automatically be converted. You
              can check the available languages{' '}
              <a href="https://shotstack.io/docs/api/#tocs_shotstacktexttospeechoptions">here</a>.
            </p>
            <pre>{`{
    "provider": "shotstack",
    "options": {
        "type": "text-to-speech",
        "text": "This is a text to speech asset generated by Shotstack",
        "voice": "Seoyeon",
        "language": "ko-KR"
    }
}`}</pre>

            <h5>Newscaster</h5>
            <p>
              A newscaster mode is also available to generate assets in the style of a TV or radio newscaster. You can
              view a list of newscaster compatible voices{' '}
              <a href="https://shotstack.io/docs/api/#tocs_shotstacktexttospeechoptions">here</a>.
            </p>
            <pre>{`{
    "provider": "shotstack",
    "options": {
        "type": "text-to-speech",
        "text": "This is a text to speech asset generated by Shotstack",
        "voice": "Matthew",
        "newscaster": true
    }
}`}</pre>
            <p>
              Check the <a href="https://shotstack.io/docs/api/#tocs_shotstackgeneratedasset">API docs</a> for more
              information.
            </p>
          </ContentCard>
        </Col>
      </Row>
    </>
  );
}

export default TextToSpeech;
