import { useRef, useState } from 'react';
import { ButtonGroup, Overlay, Popover } from 'react-bootstrap';

import GeneratedService from '@api/services/generated';
import RendersService from '@api/services/renders';
import ServeService from '@api/services/serve';
import SourcesService from '@api/services/sources';

import ButtonElement from '@components/atoms/ButtonElement';

const serviceMap = {
  renders: RendersService,
  sources: SourcesService,
  generated: GeneratedService,
  serve: ServeService,
};

function ResourceDeleteButton({ id, service, disabled }) {
  const documentRef = useRef(document);
  const [show, setShow] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [target, setTarget] = useState(null);
  const [loading, setLoading] = useState(false);

  const deleteTracking = {
    name: `Select Delete ${service} asset`,
    properties: {
      Id: id,
    },
  };

  const confirmDeleteTracking = {
    name: `Select Confirm Delete ${service} asset`,
    properties: {
      Id: id,
    },
  };

  const togglePopover = (event) => {
    setShow(!show);
    setTarget(show ? null : event.target);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await serviceMap[service].delete(id);
      setLoading(false);
      setDeleted(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setShow(false);
    }
  };

  return (
    <>
      <ButtonElement
        title="Delete"
        handleEvent={togglePopover}
        variant="danger"
        tracking={deleteTracking}
        disabled={disabled || deleted || show}
      >
        {deleted ? 'Asset Deleted' : 'Delete'}
      </ButtonElement>

      <Overlay
        show={show}
        target={target}
        placement="top"
        container={documentRef.current?.body}
        containerPadding={20}
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover className="popover-duplicate-template">
          <Popover.Body>
            <ButtonGroup>
              <ButtonElement handleEvent={togglePopover} variant="light">
                Cancel
              </ButtonElement>
              <ButtonElement
                handleEvent={handleDelete}
                variant="danger"
                tracking={confirmDeleteTracking}
                loading={loading}
              >
                Confirm
              </ButtonElement>
            </ButtonGroup>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
}

export default ResourceDeleteButton;
