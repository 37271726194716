import { Scrollbar } from 'react-scrollbars-custom';

import { IconImage, IconVoiceChat } from '@assets/icons';

import ButtonElement from '@components/atoms/ButtonElement';

import { useAddClipToTimelineCallback } from '@store/Media';

function PanelTextToImageDefault() {
  const addClipToTimeline = useAddClipToTimelineCallback();

  const handleAddClip = (type, properties) => () => {
    addClipToTimeline({ data: properties, type });
  };

  const buttons = [
    {
      type: 'text-to-speech',
      icon: <IconVoiceChat size={28} />,
      text: 'Text to Speech',
      properties: {
        text: `Hey {{ name }}, ready to see your words become visuals? Let's make it happen.`,
        voice: 'Amy',
      },
    },
    {
      type: 'text-to-image',
      icon: <IconImage size={28} />,
      text: 'Text to Image',
      properties: {
        text: 'Create a majestic {{ animal }} riding a skateboard on a rainbow',
        width: 1280,
        height: 720,
      },
    },
    {
      type: 'image-to-video',
      icon: <IconImage size={28} />,
      text: 'Image to Video',
      properties: {
        placeholder: true,
        text: 'Slowly orbit left around the object',
        aspectRatio: '16:9',
        width: 1280,
        height: 720,
      },
    },
  ];

  return (
    <div className="workspace__panel-inner">
      <Scrollbar noScrollX style={{ width: '100%', height: '100%' }}>
        <div className="mb-4">Add AI generated assets</div>
        <div className="d-flex flex-col gap-2 mb-10">
          {buttons.map(({ type, icon, text, properties }) => (
            <ButtonElement
              key={type}
              variant="light"
              className="panel-button w-100"
              handleEvent={handleAddClip(type, properties)}
            >
              <div className="flex align-items-center gap-2 p-4 text-lg">
                {icon}
                <span>{text}</span>
              </div>
            </ButtonElement>
          ))}
        </div>
      </Scrollbar>
    </div>
  );
}

export default PanelTextToImageDefault;
