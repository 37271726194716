import { Button } from 'react-bootstrap';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { IconClose } from '@assets/icons';

import WorkflowPanelAction from '@feature/workflows/panel/WorkflowPanelAction';
import WorkflowPanelEnd from '@feature/workflows/panel/WorkflowPanelEnd';
import WorkflowPanelIntro from '@feature/workflows/panel/WorkflowPanelIntro';
import WorkflowPanelTrigger from '@feature/workflows/panel/WorkflowPanelTrigger';

import { activeTaskAtom, taskTypeAtomFamily } from '@store/Workflows';

import { PANEL_TITLES } from '@constants/Workflows';

const WorkflowPanelMap = {
  action: WorkflowPanelAction,
  trigger: WorkflowPanelTrigger,
  end: WorkflowPanelEnd,
  default: WorkflowPanelIntro,
};

function WorkflowPanel() {
  const activeTask = useRecoilValue(activeTaskAtom);
  const taskType = useRecoilValue(taskTypeAtomFamily(activeTask));
  const resetActiveTask = useResetRecoilState(activeTaskAtom);

  const WorkflowPanelComponent = WorkflowPanelMap[taskType] || WorkflowPanelMap.default;

  if (!taskType) return null;

  return (
    <div className="workflow-panel">
      <div className="workflow-panel__header">
        <div>
          <h4 className="workflow-panel__title">{PANEL_TITLES[taskType]}</h4>
        </div>
        <div>
          <Button bsPrefix="workflow-panel__back" onClick={resetActiveTask}>
            <IconClose size={32} />
          </Button>
        </div>
      </div>
      <WorkflowPanelComponent />
    </div>
  );
}

export default WorkflowPanel;
