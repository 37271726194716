import { memo } from 'react';

import { IconMask } from '@assets/icons';

function TimelineClipMask() {
  return (
    <div className="clip__inner">
      <div className="clip__header">
        <span className="clip__icon">
          <IconMask size={10} />
        </span>
      </div>
    </div>
  );
}

export default memo(TimelineClipMask);
