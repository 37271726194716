import { useRecoilValue } from 'recoil';

import WorkflowTask from '@feature/workflows/task/WorkflowTask';

import { taskIdsAtom } from '@store/Workflows';

function WorkflowTasks() {
  const taskIds = useRecoilValue(taskIdsAtom);

  return (
    <div className="workflow-tasks">
      <div className="workflow-tasks__track">
        {taskIds.map((id) => (
          <WorkflowTask key={id} id={id} />
        ))}
      </div>
    </div>
  );
}

export default WorkflowTasks;
