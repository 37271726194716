import config from '@config';
import { Col, Row } from 'react-bootstrap';

import OpenAIForm from '@feature/integrations/providers/openai/OpenAIForm';

import ContentCard from '@components/cards/ContentCard';
import Header from '@components/header/Header';

function OpenAI() {
  const Forms = config.integration.stages.map((stage, index) => (
    <OpenAIForm key={stage.stage} stage={stage.stage} stageName={stage.name} index={index} />
  ));

  return (
    <>
      <Header title="OpenAI">Generate text using OpenAI Text-Generator API.</Header>
      <Row className="mb-4">
        <Col md={4}>
          <ContentCard image={`/img/provider/openai.png`}>
            OpenAI provides advanced text generation to your applications. Enhance user engagement with AI-powered,
            contextually relevant content.
          </ContentCard>
        </Col>
        <Col md={8}>
          <ContentCard heading="SETUP">
            <p>
              Sign up to <a href="https://platform.openai.com">OpenAI</a> if you do not already have an account. Create
              and copy the API Key and paste it in the form below.
            </p>
          </ContentCard>
          <ContentCard heading="CREDENTIALS">{Forms}</ContentCard>
          <ContentCard heading="INTEGRATION">
            <h5>Basic setup</h5>
            <p>
              Send the following JSON payload containing your prompt in the body of the request to generate a text
              asset. You can choose between the following models `gpt-3.5-turbo, gpt-4`.
            </p>
            <pre>
              <b>POST</b> https://api.shotstack.io/create/&#123;ENV&#125;/assets
            </pre>
            <p>
              Replace <code>&#123;ENV&#125;</code> with either stage or v1 in the URL above depending on the environment
              and API key you are using.
            </p>
            <pre>{`{
    "provider": "openai",
    "options": {
        "engine": "gpt-4",
        "type": "text-generator",
        "prompt": "Write a 20 word greeting for the user John."
    }
}`}</pre>
          </ContentCard>
        </Col>
      </Row>
    </>
  );
}

export default OpenAI;
