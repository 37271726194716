import config from '@config';
import { Col, Row } from 'react-bootstrap';

import TiktokForm from '@feature/integrations/providers/tiktok/TiktokForm';

import ContentCard from '@components/cards/ContentCard';
import Header from '@components/header/Header';

function Tiktok() {
  const Forms = config.integration.stages.map((stage, index) => (
    <TiktokForm key={stage.stage} stage={stage.stage} stageName={stage.name} index={index} />
  ));

  return (
    <>
      <Header title="TikTok">Upload directly to TikTok.</Header>
      <Row className="mb-4">
        <Col md={4}>
          <ContentCard image={'/img/provider/tiktok.png'}>
            TikTok is a social media application developed by ByteDance.
          </ContentCard>
        </Col>
        <Col md={8}>
          <ContentCard heading="SETUP">
            <p>Upload videos directly to your TikTok account by authorising Shotstack to access your account.</p>
          </ContentCard>
          <ContentCard heading="CREDENTIALS">{Forms}</ContentCard>
          <ContentCard heading="INTEGRATION">
            <h5>Basic setup</h5>
            <p>To send files to TikTok add the following to the output parameter of the render payload:</p>
            <pre>{`"destinations": [{
    "provider": "tiktok"
}]`}</pre>
          </ContentCard>
          <ContentCard heading="INSTRUCTIONS">
            <p>
              Your uploaded videos will appear inside your TikTok inbox where you can further prepare them for
              publication.
            </p>
          </ContentCard>
        </Col>
      </Row>
    </>
  );
}

export default Tiktok;
