import { useRecoilState } from 'recoil';

import { IconDimensions } from '@assets/icons';

import InputSelect from '@components/controls/InputSelect';

import { outputPresetSelector } from '@store/Output';

import OutputPresets from '@constants/OutputPresets';

function OutputPresetSelect() {
  const [preset, setPreset] = useRecoilState(outputPresetSelector);

  const handleChange = (event) => {
    const { value } = event.target;
    setPreset(value);
  };

  return (
    <InputSelect
      id="preset"
      title="Preset"
      selected={preset.value || 'none'}
      values={OutputPresets}
      prefix={<IconDimensions size={18} />}
      handleChange={handleChange}
    />
  );
}

export default OutputPresetSelect;
