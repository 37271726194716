import { isNumber } from '@utils/isNumber';

const formatRange = (range) => {
  return Object.entries(range || {}).reduce((acc, [key, value]) => {
    if (value !== '' && isNumber(value)) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export default formatRange;
