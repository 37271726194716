import { Col, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { useRecoilState, useRecoilValue } from 'recoil';

import WorkflowModuleName from '@feature/workflows/module/WorkflowModuleName';
import WorkflowModuleOutputs from '@feature/workflows/module/WorkflowModuleOutputs';
import WorkflowPanelHeader from '@feature/workflows/panel/WorkflowPanelHeader';
import WorkflowPanelMessage from '@feature/workflows/panel/WorkflowPanelMessage';

import ColorPicker from '@components/atoms/ColorPicker';
import InputTextElement from '@components/atoms/InputTextElement';

import {
  activeTaskAtom,
  taskInputsAtomFamily,
  taskOptionsAtomFamily,
  workflowDataListSelector,
} from '@store/Workflows';

import { AVATARS_SELECT } from '@constants/DId';
import { noOptionsMessage } from '@constants/Workflows';

function WorkflowModuleTtvDid() {
  const activeTask = useRecoilValue(activeTaskAtom);
  const [taskInputs, setTaskInputs] = useRecoilState(taskInputsAtomFamily(activeTask));
  const [taskOptions, setTaskOptions] = useRecoilState(taskOptionsAtomFamily(activeTask));
  const { list, grouped } = useRecoilValue(workflowDataListSelector);

  const setStateMap = {
    inputs: setTaskInputs,
    options: setTaskOptions,
  };

  const getSelectedValue = (data, key) => {
    return data.find(({ value }) => value === key) || null;
  };

  const handleChange = (field, type) => {
    const setState = setStateMap[type];
    return ({ value }) => {
      setState((prevState) => ({ ...prevState, [field]: value }));
    };
  };

  const handleBackgroundChange = () => {
    const changeFn = handleChange('background', 'options');
    return (event) => {
      const { value } = event.target;
      changeFn({ value });
    };
  };

  return (
    <>
      <div className="form-container">
        <WorkflowModuleName />
      </div>
      <div className="form-container sm">
        <WorkflowPanelHeader
          name="Configuration"
          description="The input text to convert and avatar configuration settings."
        />
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Text</label>
          </Col>
          <Col>
            <ReactSelect
              unstyled
              className="react-select__inputs form-control"
              classNamePrefix="react-select"
              id="text"
              name="text"
              placeholder="Select input"
              noOptionsMessage={noOptionsMessage('data')}
              defaultValue={getSelectedValue(list, taskInputs?.text)}
              options={grouped}
              onChange={handleChange('text', 'inputs')}
            />
          </Col>
        </Row>
        <hr />
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Avatar</label>
          </Col>
          <Col>
            <ReactSelect
              unstyled
              className="form-control"
              classNamePrefix="react-select"
              id="avatar"
              name="avatar"
              placeholder="Select avatar"
              noOptionsMessage={noOptionsMessage()}
              value={getSelectedValue(AVATARS_SELECT, taskOptions?.avatar)}
              options={AVATARS_SELECT}
              onChange={handleChange('avatar', 'options')}
            />
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Background</label>
          </Col>
          <Col>
            <Row className="d-flex align-items-center">
              <Col xs={9}>
                <InputTextElement
                  name="background"
                  value={taskOptions?.background}
                  placeholder="#FFFFFF"
                  handleChange={handleBackgroundChange()}
                />
              </Col>
              <Col>
                <ColorPicker
                  className="color"
                  value={taskOptions?.background || '#FFFFFF'}
                  handleEvent={handleBackgroundChange()}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <WorkflowPanelMessage
          name="API KEYS"
          message="Add your D-ID API keys in the <a href='/integrations/d-id' target='_blank' rel='noopener'>integrations page</a> for this module to work."
        />
      </div>
      <div className="form-container">
        <WorkflowModuleOutputs
          name="Output"
          description="This action generates an MP4 video file and outputs the file URL."
        />
      </div>
    </>
  );
}

export default WorkflowModuleTtvDid;
