import '@css/TimelinePlayhead.css';

import Playhead from '@assets/img/icons/playhead.svg?react';

import withPlayheadReactable from '@hocs/withPlayheadReactable';

function TimelinePlayhead({ x, getRef }) {
  return (
    <div className="timeline-playhead" ref={getRef} style={{ transform: `translateX(${x}px)` }}>
      <div className="timeline-playhead__icon">
        <Playhead />
      </div>
    </div>
  );
}

export default withPlayheadReactable(TimelinePlayhead);
