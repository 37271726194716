import { useState } from 'react';
import { Modal } from 'react-bootstrap';

function BaseModal({ children, visible, size, isCentered = true, title, body, footer }) {
  const [show, setShow] = useState(visible);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size={size} centered={isCentered} dialogClassName="asset-modal">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>{footer}</Modal.Footer>
      </Modal>
      {children(setShow)}
    </>
  );
}

export default BaseModal;
