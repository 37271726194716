import * as videoTransitionsIn from '@assets/videos/transitions/in';
import * as videoTransitionsOut from '@assets/videos/transitions/out';

import Transition from '@components/transitions/Transition';

import allTransitions from '@constants/Transitions';

const videoTransitions = {
  in: videoTransitionsIn,
  out: videoTransitionsOut,
};

const getFullTransitionValue = ({ value, speed }) => {
  if (!value) {
    return undefined;
  }
  return [value, speed].join('');
};

function Transitions({ selected, dir, speed, onTransitionChange }) {
  return (
    <>
      <div className="property-previews transitions">
        {allTransitions.map(({ value, text }) => {
          const transitionLabel = getFullTransitionValue({ value, speed });
          const transitionVideo = videoTransitions[dir][transitionLabel];
          return (
            <Transition
              key={value}
              label={text}
              value={value}
              active={selected === value}
              transition={transitionVideo}
              onSelect={onTransitionChange}
            />
          );
        })}
      </div>
    </>
  );
}

export default Transitions;
