import { useRecoilValue } from 'recoil';

import { useRedo, useUndo } from '@context/RecoilUndo';

import KeyboardHelpButton from '@components/controls/KeyboardHelpButton';
import UndoRedoButtons from '@components/controls/UndoRedoButtons';
import ZoomButtons from '@components/controls/ZoomButtons';

import { sdkOptionsSelector } from '@store/Sdk';

function WorkspaceActionsLeft() {
  const { isSdkEnabled } = useRecoilValue(sdkOptionsSelector);
  const { undo, canUndo } = useUndo();
  const { redo, canRedo } = useRedo();

  return (
    <div className="d-flex gap-2 align-items-center">
      {!isSdkEnabled && (
        <>
          <UndoRedoButtons undo={undo} undoDisabled={!canUndo()} redo={redo} redoDisabled={!canRedo()} />
          <KeyboardHelpButton />
        </>
      )}
      <div className="ms-3">
        <ZoomButtons />
      </div>
    </div>
  );
}

export default WorkspaceActionsLeft;
