import AssetPreview from '@components/asset/AssetPreview';
import OverrideMenu from '@components/mergeFields/OverrideMenu';

function AssetSource({ id, type }) {
  return (
    <div className="asset-source">
      <div className="asset-source__body">
        <div className="asset-source__preview">
          <AssetPreview id={id} type={type} />
        </div>
        <div className="asset-source__actions">
          <OverrideMenu path="asset:src" isButton />
        </div>
      </div>
    </div>
  );
}

export default AssetSource;
