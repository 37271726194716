import React from 'react';
import ReactDOM from 'react-dom';

import '@assets/css/ContextMenu.css';

function ContextMenu({ instance, onClose }) {
  const { x, y, options } = instance;

  return ReactDOM.createPortal(
    <React.Fragment>
      <div className="context-menu-overlay" onClick={onClose} />
      <div className="context-menu" style={{ '--top': `${y}px`, '--left': `${x}px` }}>
        {options.map(({ label, onClick }, index) => (
          <div
            key={index}
            onClick={() => {
              if (onClick) onClick();
              onClose();
            }}
            className="context-menu-option"
          >
            {label}
          </div>
        ))}
      </div>
    </React.Fragment>,
    document.body
  );
}

export default ContextMenu;
