import getAspectRatioResolutionSize from '@utils/getAspectRatioResolutionSize';
import getResolution from '@utils/getResolution';

const formatOutputDimensions = (output) => {
  const { resolution, aspectRatio = '16:9', ...updatedOutput } = output || {};

  let size = output?.size || { width: 1024, height: 576 };

  if (output?.format && resolution) {
    const [width, height] = getResolution(resolution);
    const [aspectWidth, aspectHeight] = getAspectRatioResolutionSize(width, height, aspectRatio);

    size = {
      width: aspectWidth,
      height: aspectHeight,
    };
  }

  return {
    ...updatedOutput,
    size,
  };
};

export default formatOutputDimensions;
