import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { IconAlias } from '@assets/icons';

import InputText from '@components/controls/InputText';

import { clipSettingsSelectorFamily } from '@store/EditSelectors';

function AliasFields({ id }) {
  const [clip, setClip] = useRecoilState(clipSettingsSelectorFamily(id));

  const handleAliasChange = useCallback(
    (event) => {
      const { value } = event.target;
      setClip({ alias: value });
    },
    [setClip]
  );

  return (
    <div className="mt-10 mb-10">
      <h6>Alias</h6>
      <InputText
        id="clip-alias"
        title="Clip Alias"
        placeholder="MY_ALIAS"
        prefix={<IconAlias size={12} />}
        value={clip.alias || ''}
        handleChange={handleAliasChange}
      />
    </div>
  );
}

export default AliasFields;
