import { Col, Image, Row } from 'react-bootstrap';

import Modal from '@components/modal/BaseModal';
import ModalFooter from '@components/modal/ModalFooter';

function ImageModal({ children, renderUrl, renderId }) {
  return (
    <Modal
      visible={false}
      size="xl"
      title={<span>IMAGE PREVIEW</span>}
      body={
        <Row>
          <Col className="d-flex flex-column align-items-center justify-content-center">
            <Image fluid src={renderUrl} />
          </Col>
        </Row>
      }
      footer={<ModalFooter renderId={renderId} renderUrl={renderUrl} />}
    >
      {(showModal) => children(showModal)}
    </Modal>
  );
}

export default ImageModal;
