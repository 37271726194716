import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { IconKeyboard } from '@assets/icons';

// TODO: combine this with actual studio shortcuts
const shortcuts = [
  { keys: 'cmd/ctrl+s', description: 'Save Template' },
  { keys: 'cmd/ctrl+z', description: 'Undo' },
  { keys: 'cmd/ctrl+shift+z', description: 'Redo' },
  { keys: 'cmd/ctrl+alt+Drag', description: 'Pan' },
  { keys: 'cmd/ctrl+Mouse Wheel Up', description: 'Zoom Out' },
  { keys: 'cmd/ctrl+Mouse Wheel Down', description: 'Zoom In' },
  { keys: 'cmd/ctrl+c', description: 'Copy Clip' },
  { keys: 'cmd/ctrl+v', description: 'Paste Clip' },
  { keys: 'Backspace/Delete', description: 'Delete Clip or Track' },
  { keys: 'Enter', description: 'Stop Playback' },
  { keys: 'Space', description: 'Play/Pause' },
  { keys: 'cmd/ctrl+←', description: 'Set Playhead Start' },
  { keys: 'cmd/ctrl+→', description: 'Set Playhead End' },
  { keys: 'cmd/ctrl+shift+←', description: 'Seek Back <span>(Clip Start/End Points)</span>' },
  { keys: 'cmd/ctrl+shift+→', description: 'Seek Forward <span>(Clip Start/End Points)</span>' },
  { keys: 'alt+shift+←', description: 'Seek Back <span>(FPS)</span>' },
  { keys: 'alt+shift+→', description: 'Seek Forward <span>(FPS)</span>' },
  { keys: 'shift+←', description: 'Seek Back <span>(10% Timeline Duration)</span>' },
  { keys: 'shift+→', description: 'Seek Forward <span>(10% Timeline Duration)</span>' },
];

function KeyboardHelpButton() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button className="unstyled" onClick={handleShow}>
        <IconKeyboard size={20} />
      </button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Keyboard Shortcuts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-6">
            <ul className="shortcut-list">
              {shortcuts.map(({ keys, description }) => (
                <li key={keys}>
                  <div className="shortcut-description" dangerouslySetInnerHTML={{ __html: description }} />
                  <div className="shortcut-keys">
                    {keys.split('+').map((key, index) => (
                      <React.Fragment key={`${key}-${index}`}>
                        <kbd>{key}</kbd>
                        {index !== keys.split('+').length - 1 && ' + '}
                      </React.Fragment>
                    ))}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default KeyboardHelpButton;
