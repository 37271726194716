export const METHODS = [
  {
    label: 'POST',
    value: 'POST',
  },
];

export const CONTENT_TYPES = [
  {
    label: 'application/json',
    value: 'application/json',
  },
];
