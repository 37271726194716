import { Form } from 'react-bootstrap';

import { useAnalytics } from '@components/analytics/Analytics';

function InputNumberElement(props) {
  const {
    disabled = false,
    handleBlur,
    handleChange,
    placeholder,
    max = undefined,
    min = undefined,
    step = 1,
    tracking,
    value,
    name,
  } = props;

  const { trackEvent } = useAnalytics();

  const handleOnBlur = (event) => {
    const inputValue = event.target.value;

    if (tracking && inputValue) {
      trackEvent(tracking.name, {
        ...tracking.properties,
        Value: inputValue,
      });
    }

    if (handleBlur) {
      handleBlur(event);
    }
  };

  return (
    <Form.Control
      name={name}
      type="number"
      placeholder={placeholder}
      min={min}
      max={max}
      step={step}
      value={value}
      disabled={disabled}
      onBlur={handleOnBlur}
      onChange={handleChange}
    />
  );
}

export default InputNumberElement;
