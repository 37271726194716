const get720pResolutionDynamic = (aspectRatio) => {
  const [widthRatio, heightRatio] = aspectRatio.split(':').map(Number);

  if (isNaN(widthRatio) || isNaN(heightRatio) || widthRatio === 0 || heightRatio === 0) {
    throw new Error('Invalid aspect ratio format. Please use "width:height" format with positive numbers.');
  }

  // Determine if the video is portrait or landscape based on the aspect ratio
  const isLandscape = widthRatio >= heightRatio;

  if (isLandscape) {
    // Fix height at 720 pixels
    const scaleFactor = 720 / heightRatio;
    return {
      width: Math.round(widthRatio * scaleFactor),
      height: 720,
    };
  }
  // Fix width at 720 pixels
  const scaleFactor = 720 / widthRatio;
  return {
    width: 720,
    height: Math.round(heightRatio * scaleFactor),
  };
};

export default get720pResolutionDynamic;
