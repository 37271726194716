import formatOutputDimensions from '@utils/editor/formatOutputDimensions';
import formatRange from '@utils/editor/formatRange';

export const getTemplateForPreview = ({ template = {}, range, isPreviewQuality = false }) => {
  const { output, ...restTemplate } = template;
  const formattedOutput = formatOutputDimensions(output);
  const previewRange = formatRange(range);

  let previewTemplate = {
    ...restTemplate,
    output: formattedOutput,
  };

  if (isPreviewQuality) {
    previewTemplate = {
      ...previewTemplate,
      output: {
        ...previewTemplate.output,
        scaleTo: 'preview',
        fps: 12,
        quality: 'low',
      },
    };
  }

  if (Object.keys(previewRange).length > 0) {
    previewTemplate.output.range = previewRange;
  }

  if (previewTemplate.output.destinations) {
    delete previewTemplate.output.destinations;
  }

  return previewTemplate;
};
