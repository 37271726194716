import { useRecoilState, useRecoilValue } from 'recoil';

import InputTextElement from '@components/atoms/InputTextElement';

import { activeWorkflowAtom, workflowNameAtomFamily } from '@store/Workflows';

function WorkflowName() {
  const activeWorkflowId = useRecoilValue(activeWorkflowAtom);
  const [workflowName, setWorkflowName] = useRecoilState(workflowNameAtomFamily(activeWorkflowId));

  const tracking = {
    name: 'Change Workflow Name',
    properties: {
      Id: activeWorkflowId,
    },
  };

  const handleChange = (event) => {
    setWorkflowName(event.target.value);
  };

  return <InputTextElement inputType="text" handleChange={handleChange} tracking={tracking} value={workflowName} />;
}

export default WorkflowName;
