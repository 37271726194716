import dataClient from '@api/clients/data';
import editClient from '@api/clients/edit';
import { transformTimeline, transformTimelineAssets, transformTimelines } from '@api/transform/renders';

export const getTimelineAssets = async (id, config) => {
  const url = `assets/render/${id}`;
  const { data } = await dataClient.get(url, config);
  return transformTimelineAssets(data?.body?.data);
};

const getRenderAssets = async (id) => {
  try {
    const { data } = await editClient.get(`render/${id}?data=true&merged=true`);
    const { url, poster, thumbnail } = data?.body?.data || {};
    return {
      json: data?.body?.data?.data,
      render: url,
      poster,
      thumbnail,
    };
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const getTimeline = async (id, config) => {
  const url = `timelines/${id}`;
  const { data } = await dataClient.get(url, config);
  const { json, render, poster, thumbnail } = await getRenderAssets(id);
  const transformedTimeline = await transformTimeline(data?.body?.data);

  return {
    ...transformedTimeline,
    url: render,
    poster,
    thumbnail,
    json,
  };
};

export const getTimelines = async (filterParams) => {
  const url = `timelines`;
  const { data } = await dataClient.get(url, { params: filterParams });
  return transformTimelines(data?.body);
};

export default {
  get: getTimeline,
  getAll: getTimelines,
  assets: {
    getAll: getTimelineAssets,
  },
};
