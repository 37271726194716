import _ from 'lodash-es';

import { getAssetMetaData } from '@api/services/asset';
import { transformAssetIncoming, transformAssetOutgoing } from '@api/transform/utils/assets';

import { RENDITION_WIDTH } from '@constants/Uploads';

import { expandColonSeperatedProperties } from '@utils/properties';
import { removeMetaDataRecursive } from '@utils/template';

const DEFAULT_AUTO_CLIP_LENGTH = 3;

export const transformClipIncoming = async (clip, mergeData) => {
  const { asset } = clip;
  const { attributes } = await getAssetMetaData(asset, mergeData);
  const transformedAsset = await transformAssetIncoming(asset, mergeData);
  const originalScale = clip.scale || 1;
  let proxyScaleMultiplier = originalScale;

  if (transformedAsset.meta?.proxied && attributes.width && ['video', 'image'].includes(transformedAsset.type)) {
    proxyScaleMultiplier = attributes.width / RENDITION_WIDTH;
  }

  const newAsset = {
    ...transformedAsset,
    meta: {
      ...(transformedAsset?.meta || {}),
      ...(attributes || {}),
    },
  };

  if (['video', 'image'].includes(newAsset.type)) {
    newAsset.meta.proxyScaleMultiplier = proxyScaleMultiplier;
  }

  return { ...clip, meta: { start: 'number', length: 'number' }, asset: newAsset };
};

export const transformClipAutoProperties = (clip, trackClips, index) => {
  const { asset, start, length } = clip;

  const transformedClip = { ...clip, meta: { ...(clip?.meta || {}) } };

  if (length === 'auto') {
    transformedClip.length = asset?.meta?.duration ?? DEFAULT_AUTO_CLIP_LENGTH;
    transformedClip.meta.length = 'auto';
  }

  if (start === 'auto') {
    const autoStartTime = trackClips.slice(0, index).reduce((acc, cur) => {
      return (cur.length === 'auto' ? cur.asset?.meta?.duration ?? DEFAULT_AUTO_CLIP_LENGTH : cur.length) + acc;
    }, 0);
    transformedClip.start = autoStartTime;
    transformedClip.meta.start = 'auto';
  }

  return transformedClip;
};

export const transformClipEndProperties = (clip, timelineDuration) => {
  const { start, length } = clip;

  const transformedClip = { ...clip, meta: { ...(clip?.meta || {}) } };

  if (length === 'end') {
    transformedClip.length = timelineDuration - start;
    transformedClip.meta.length = 'end';
  }

  return transformedClip;
};

const transformClipOutgoingMap = {
  asset: transformAssetOutgoing,
  offset: (offset) => (offset.x === 0 && offset.y === 0 ? undefined : offset),
  transition: (transition) => (Object.keys(transition).length === 0 ? undefined : transition),
  transform: (transform) => {
    const transformCopy = Object.entries(transform).reduce((acc, [key, value]) => {
      if (value && value.angle !== 0 && !_.isNaN(value.angle)) {
        acc[key] = value;
      }
      return acc;
    }, {});
    return Object.keys(transformCopy).length === 0 ? undefined : transformCopy;
  },
  alias: (alias) => (alias === '' ? undefined : alias),
};

export const transformClipOutgoing = (clip) => {
  const normalisedClip = expandColonSeperatedProperties(clip);
  const sanitizedClip = Object.entries(normalisedClip).reduce((acc, [key, value]) => {
    const transformFn = transformClipOutgoingMap[key];
    const transformedValue = transformFn ? transformFn(value) : value;
    if (transformedValue !== undefined && transformedValue !== null) {
      acc[key] = transformedValue;
    }
    return acc;
  }, {});
  return removeMetaDataRecursive(sanitizedClip);
};
