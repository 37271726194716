import createClient from '@api/clients/create';
import editClient from '@api/clients/edit';
import ingestClient from '@api/clients/ingest';
import serveClient from '@api/clients/serve';

import { jsonParse } from '@utils/jsonParse';

const GENERIC_ERROR_RESPONSE = 'Something went wrong. Please check the JSON and try again.';

const apiBaseUrlMap = {
  'edit-api': 'https://api.shotstack.io/edit/',
  'ingest-api': 'https://api.shotstack.io/ingest/',
  'create-api': 'https://api.shotstack.io/create/',
  'serve-api': 'https://api.shotstack.io/serve/',
};

const apiClientMap = {
  'edit-api': editClient,
  'ingest-api': ingestClient,
  'create-api': createClient,
  'serve-api': serveClient,
};

class OperationStrategyBase {
  constructor({ config }) {
    if (new.target === OperationStrategyBase) {
      throw new TypeError('OperationStrategyBase is an abstract class and cannot be instantiated directly');
    }

    this.client = apiClientMap[config.api];
    this.config = config;
  }

  // eslint-disable-next-line no-unused-vars
  getDisplayUrl(stage) {
    throw new Error("Method 'getDisplayUrl' must be implemented.");
  }

  // eslint-disable-next-line no-unused-vars
  async submit(payload) {
    throw new Error("Method 'submit' must be implemented.");
  }
}

class OperationStrategy extends OperationStrategyBase {
  getDisplayUrl(stage) {
    return `${apiBaseUrlMap[this.config.api]}${stage}${this.config.url}`;
  }

  async submit(payload) {
    try {
      const response = await this.client.post(this.config.url, payload);
      const { data } = response.data.body;
      const rawResponse = response.request ? jsonParse(response.request.responseText) : null;

      return {
        data,
        rawResponse,
      };
    } catch (error) {
      console.error(`Error submitting operation: ${this.config.name}`, error);
      const rawResponse = error.response ? jsonParse(error.request.responseText) : GENERIC_ERROR_RESPONSE;
      return {
        data: null,
        rawResponse,
      };
    }
  }
}

export default OperationStrategy;
