import api from '@api';
import config from '@config';

// TODO: Add types for data sent to and received from the onboarding API
type OnboardingClient = {
  get: <T>(url: string, config?: object) => Promise<T>;
  post: <T>(url: string, data?: object, config?: object) => Promise<T>;
  put: <T>(url: string, data?: object, config?: object) => Promise<T>;
  delete: <T>(url: string, config?: object) => Promise<T>;
};

const onboardingClient = api.init({ baseURL: config.onboarding.url }) as OnboardingClient;

export type { OnboardingClient };
export default onboardingClient;
