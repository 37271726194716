import { Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';

import SelectElement from '@components/atoms/SelectElement';

import camelCase from '@utils/text/camelCase';

function InputSelect({
  id,
  label,
  name,
  title,
  selected,
  values,
  disabled = false,
  showLabel = true,
  handleChange,
  tracking,
  helperText,
  prefix,
  Action,
}) {
  const inputId = id || camelCase(label);

  return (
    <Form.Group controlId={inputId} className="form-group">
      {(label || Action) && (
        <div className="d-flex align-items-center justify-content-between">
          <div>{showLabel === true && <Form.Label>{label}</Form.Label>}</div>
          <div>{Action || null}</div>
        </div>
      )}
      <InputGroup>
        {prefix && (
          <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${inputId}`}>{title}</Tooltip>}>
            <InputGroup.Text className="prefix" title={title}>
              {prefix}
            </InputGroup.Text>
          </OverlayTrigger>
        )}
        <SelectElement
          name={name}
          disabled={disabled}
          options={values}
          selected={selected}
          handleEvent={handleChange}
          tracking={tracking}
        />
      </InputGroup>
      {helperText && (
        <Form.Text className="helper-text text-muted">
          <span dangerouslySetInnerHTML={{ __html: helperText }} />
        </Form.Text>
      )}
    </Form.Group>
  );
}

export default InputSelect;
