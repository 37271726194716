import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import ButtonElement from '@components/atoms/ButtonElement';

import ResourceStatuses from '@constants/ResourceStatuses';

import capitaliseWords from '@utils/text/capitaliseWords';

function ResourceFilter({ product, startDateRange, endDateRange, status, limit, offset, onFilterChange }) {
  const [tracking, setTracking] = useState();
  const statusOptions = ResourceStatuses[product] || [];

  const [filter, setFilter] = useState({
    startDateRange: startDateRange || '',
    endDateRange: endDateRange || '',
    status: status || '',
    limit: limit || '',
    offset: offset || '',
  });

  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      startDateRange: startDateRange || prevFilter.startDateRange,
      endDateRange: endDateRange || prevFilter.endDateRange,
      status: status || prevFilter.status,
      limit: limit || prevFilter.limit,
      offset: offset || prevFilter.offset,
    }));
  }, [startDateRange, endDateRange, status, limit, offset]);

  const handleChange = (event) => {
    setFilter({
      ...filter,
      [event.target.id]: event.target.value,
    });
  };

  const handleClick = (event) => {
    event.preventDefault();
    onFilterChange(filter);
  };

  useEffect(() => {
    setTracking({
      name: `Select Filter ${product} History`,
      properties: {
        'Start Date': filter.startDateRange,
        'End Date': filter.endDateRange,
        'Render Status': filter.status ? capitaliseWords(filter.status) : 'All',
      },
    });
  }, [filter]);

  return (
    <Form>
      <Row>
        <Col lg={4} xl={4}>
          <Form.Group controlId="startDateRange">
            <Form.Label>Start Date</Form.Label>
            <Form.Control type="datetime-local" onChange={handleChange} value={filter.startDateRange || ''} />
          </Form.Group>
        </Col>
        <Col lg={4} xl={4}>
          <Form.Group controlId="endDateRange">
            <Form.Label>End Date</Form.Label>
            <Form.Control type="datetime-local" onChange={handleChange} value={filter.endDateRange || ''} />
          </Form.Group>
        </Col>
        <Col lg={4} xl={2}>
          <Form.Group controlId="status">
            <Form.Label>Status</Form.Label>
            <Form.Select value={filter.status || ''} onChange={handleChange}>
              <option value="">All</option>
              {Object.entries(statusOptions).map(([option, label]) => (
                <option key={option} value={option}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xl={2} className="d-flex align-items-end">
          <ButtonElement
            handleEvent={handleClick}
            variant="primary"
            type="submit"
            tracking={tracking}
            className="w-full whitespace-nowrap mt-2"
          >
            Filter
          </ButtonElement>
        </Col>
      </Row>
    </Form>
  );
}

export default ResourceFilter;
