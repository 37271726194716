class PollDataManager {
  constructor() {
    this.topics = {};
  }

  subscribe(topic, listener) {
    if (!this.topics[topic]) {
      this.topics[topic] = [];
    }
    this.topics[topic].push(listener);
    const index = this.topics[topic].length - 1;

    // Return unsubscribe function
    return () => {
      this.topics[topic].splice(index, 1);
    };
  }

  publish(topic, data) {
    if (!this.topics[topic] || this.topics[topic].length === 0) {
      return;
    }
    this.topics[topic].forEach((listener) => listener(data));
  }
}

export const pollDataManager = new PollDataManager();
