import { useEffect, useRef, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import { useAuth0 } from '@context/Auth0ReactSPA';

import SelectElement from '@components/atoms/SelectElement';
import AssetSourceInput from '@components/controls/AssetSourceInput';
import Layout from '@components/layout/Layout';

const playbackSpeeds = [
  {
    value: 0.25,
    text: '0.25',
  },
  {
    value: 0.5,
    text: '0.5',
  },
  {
    value: 1,
    text: '1',
  },
  {
    value: 1.5,
    text: '1.5',
  },
  {
    value: 2,
    text: '2',
  },
];

function VideoToMp3() {
  const { loading, user } = useAuth0();
  const [sourceVideo, setSourceVideo] = useState(null);
  const [speed, setSpeed] = useState(1);
  const [preservePitch, setPreservePitch] = useState(true);
  const [renditionVideo, setRenditionVideo] = useState(null);
  const videoRef = useRef(null);

  const handleUploadComplete = (data) => {
    if (data.status === 'ready' && data.outputs && data.outputs.renditions.length > 0) {
      const rendition = data.outputs.renditions.find((r) => r.status === 'ready');
      if (rendition) {
        setRenditionVideo({ src: rendition.url });
      }
    }
  };

  const handleDelete = () => setSourceVideo({ src: '' });

  const handleSpeedChange = (event) => setSpeed(event.target.value);
  const handlePreservePitchChange = (event) => setPreservePitch(event.target.checked);

  const requestOptions = {
    outputs: {
      renditions: [
        {
          format: 'mp3',
          speed: {
            speed: parseFloat(speed),
            preservePitch: !!preservePitch,
          },
        },
      ],
    },
    destinations: [
      {
        provider: 'shotstack',
      },
    ],
  };

  useEffect(() => {
    if (renditionVideo && renditionVideo.src && videoRef.current) {
      videoRef.current.src = renditionVideo.src;
      videoRef.current.play();
    }
  }, [renditionVideo]);

  if (loading || !user) {
    return <div />;
  }

  return (
    <Layout>
      <div className="text-prose mb-16">
        <p className="uppercase-label">Convert your videos to MP3 files</p>
        <h1>Video To MP3 Converter</h1>
      </div>
      <Row>
        <Col lg={6} className="my-2">
          <Card>
            <Card.Body className="px-5">
              <Card.Title className="fw-bold mt-3 mb-4">Input</Card.Title>
              <Card.Text>Configure your settings and upload your video</Card.Text>
              <Row>
                <Col>
                  <label>
                    <b>Playback Speed</b>
                  </label>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-group">
                    <SelectElement options={playbackSpeeds} selected={speed} handleEvent={handleSpeedChange} />
                    <div className="form-check mt-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="preserve-pitch"
                        checked={preservePitch}
                        onChange={handlePreservePitchChange}
                      />
                      <label className="form-check-label" htmlFor="preserve-pitch">
                        Preserve Pitch
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="alert alert-danger d-none" role="alert" id="errors"></div>

              <AssetSourceInput
                asset={sourceVideo}
                onUploadComplete={handleUploadComplete}
                overrideEnabled={false}
                requestOptions={requestOptions}
                onDelete={handleDelete}
                type="video"
                accepts="video/*"
                assetHolder={false}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} className="my-2 video-container">
          <Card>
            <Card.Body className="px-5">
              <Card.Title className="fw-bold mt-3 mb-4">Output</Card.Title>
              <div id="status" className="row justify-content-center align-items-center">
                <div className="col-6 text-center">
                  <i className="fas fa-2x"></i>
                </div>
              </div>
              <audio id="player" ref={videoRef} controls autoPlay style={{ width: '100%' }}></audio>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default VideoToMp3;
