import { debounce } from 'lodash-es';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

const MIN_WIDTH = 1110;

function CanvasResolutionWarning() {
  const [showModal, setShowModal] = useState(false);

  const resolutionWarning = debounce(
    () => {
      setShowModal(window.innerWidth < MIN_WIDTH);
    },
    300,
    { maxWait: 3000, leading: false, trailing: true }
  );

  useEffect(() => {
    setShowModal(window.innerWidth < MIN_WIDTH);
    window.addEventListener('resize', resolutionWarning);
    return () => window.removeEventListener('resize', resolutionWarning);
  }, []);

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size="md" centered="true" dialogClassName="asset-modal">
      <Modal.Header closeButton>
        <Modal.Title>
          <span>Warning</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The Studio has not been optimized for your screen size. For an optimal experience, please use a desktop
          computer.
        </p>
      </Modal.Body>
    </Modal>
  );
}

export default CanvasResolutionWarning;
