import { bezierInterpolation } from '@animation/interpolation/bezier';
import { easeInInterpolation, easeOutInterpolation } from '@animation/interpolation/cubic';
import * as easings from '@animation/interpolation/easings';
import { linearInterpolation } from '@animation/interpolation/linear';

/**
 * @typedef {'linear' | 'easeIn' | 'easeOut' | 'bezier'} Interpolation
 */

/**
 * @template T
 *
 * @typedef {{
 *  from: T
 *  to: T
 *  start: number;
 *  end: number;
 *  interpolation?: import('@animation/keyframe').Interpolation;
 *  easing?: string;
 * }} Keyframe<T>
 */

/**
 * @typedef {(interpolation: Interpolation) => (start: number, end: number, progress: number) => number}
 */
export const getInterpolator = (interpolation = 'linear') => {
  switch (interpolation) {
    case 'linear':
      return linearInterpolation;
    case 'easeIn':
      return easeInInterpolation;
    case 'easeOut':
      return easeOutInterpolation;
    case 'bezier':
      return bezierInterpolation;
    default:
      return linearInterpolation;
  }
};

/**
 * Retrieves the easing function based on the provided easing name.
 *
 * @type {(easing: string) => (progress: number) => number}
 */
const getEasingFunction = (easing) => {
  const easeFunction = easings[easing] || easings.linear;
  return easeFunction;
};

/**
 * @type {(value: number) => number}
 */
export const roundKeyframeValue = (value) => Math.round(value * 10000) / 10000;

/**
 * @type {(keyframe: Keyframe<{}>, time: number) => Record<string, number>}
 */
export const interpolateKeyframe = (keyframe, time) => {
  const { from, to, start, end, interpolation = {}, easing = {} } = keyframe;

  /** @type {Record<string, number>} */
  const keyframeValues = {};
  Object.keys(from).forEach((key) => {
    const fromValue = from[key];
    const toValue = to[key] ?? fromValue;

    if (![fromValue, toValue].every((value) => typeof value === 'number')) return;

    const interpolate = getInterpolator(interpolation[key]);
    const easeFunction = getEasingFunction(easing[key]);

    const keyframeProgress = (time - start) / (end - start);
    const easedProgress = easeFunction(keyframeProgress);
    keyframeValues[key] = roundKeyframeValue(interpolate(fromValue, toValue, easedProgress));
  });

  return keyframeValues;
};
