import { atom, selectorFamily, useRecoilCallback } from 'recoil';

import { outputDimensionsSelector } from '@store/Output';
import { sdkOptionsSelector } from '@store/Sdk';

import { MAX_ZOOM, MIN_ZOOM } from '@constants/Canvas';

import { isNumber } from '@utils/isNumber';

export const canvasAtom = atom({
  key: 'canvas/dimensions',
  default: {
    width: undefined,
    height: undefined,
  },
});

export const canvasStageAtom = atom({
  key: 'stage/dimensions',
  default: {
    width: undefined,
    height: undefined,
    scale: 1,
  },
});

export const canvasZoomAtom = atom({
  key: 'canvas/zoom',
  default: 1,
});

export const canvasStageSelectorFamily = selectorFamily({
  key: 'canvasStage/dimensions',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get:
    () =>
    ({ get }) => {
      const canvas = get(canvasAtom);
      const stage = get(canvasStageAtom);

      const values = [...Object.values(canvas), ...Object.values(stage)];
      const ready = values.filter((value) => !isNumber(value)).length === 0;

      return {
        ...canvas,
        stage,
        ready,
      };
    },
  set:
    () =>
    ({ get, set }, { containerWidth, containerHeight }) => {
      const output = get(outputDimensionsSelector);
      const { isSdkEnabled, isTimeline } = get(sdkOptionsSelector);

      const containerMinPadding = isSdkEnabled && !isTimeline ? 0 : 0.1 * containerHeight;
      const targetStageHeight = containerHeight - containerMinPadding * 2;
      const targetStageWidth = containerWidth - containerMinPadding * 2;
      const outputScaleToStage = Math.min(targetStageHeight / output.size.height, targetStageWidth / output.size.width);

      set(canvasAtom, {
        width: output.size.width,
        height: output.size.height,
      });

      set(canvasStageAtom, {
        width: outputScaleToStage * output.size.width,
        height: outputScaleToStage * output.size.height,
        scale: outputScaleToStage || 1,
      });
    },
});

const zoomInCallback = (callbackArgs) => {
  const { set } = callbackArgs;
  return () => set(canvasZoomAtom, (prev) => Math.min(prev + 0.05, MAX_ZOOM));
};

const zoomOutCallback = (callbackArgs) => {
  const { set } = callbackArgs;
  return () => set(canvasZoomAtom, (prev) => Math.max(prev - 0.05, MIN_ZOOM));
};

const zoomResetCallback = (callbackArgs) => {
  const { set } = callbackArgs;
  return () => set(canvasZoomAtom, 1);
};

export const useZoomIn = () => useRecoilCallback(zoomInCallback);
export const useZoomOut = () => useRecoilCallback(zoomOutCallback);
export const useZoomReset = () => useRecoilCallback(zoomResetCallback);
