import { useRef, useState } from 'react';
import { ButtonGroup, Overlay, Popover } from 'react-bootstrap';

import { IconFiles } from '@assets/icons';

import ButtonElement from '@components/atoms/ButtonElement';

import useDuplicateWorkflow from '@hooks/useDuplicateWorkflow';

function WorkflowDuplicateButton({ id }) {
  const documentRef = useRef(document);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const { handleDuplicate, loading } = useDuplicateWorkflow({
    onSuccess: () => setShow(false),
  });

  const tracking = {
    name: 'Select Duplicate Template',
    properties: {
      Id: id,
    },
  };

  const togglePopover = (event) => {
    setShow(!show);
    setTarget(show ? null : event.target);
  };

  return (
    <>
      <ButtonElement title="Duplicate" handleEvent={togglePopover} variant="light" disabled={show}>
        <IconFiles size="18" />
      </ButtonElement>
      <Overlay
        show={show}
        target={target}
        placement="top"
        container={documentRef.current?.body}
        containerPadding={20}
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover className="popover-duplicate-template">
          <Popover.Body>
            <ButtonGroup>
              <ButtonElement handleEvent={togglePopover} variant="light">
                Cancel
              </ButtonElement>
              <ButtonElement
                handleEvent={() => handleDuplicate(id)}
                variant="primary"
                tracking={tracking}
                loading={loading}
              >
                Duplicate
              </ButtonElement>
            </ButtonGroup>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
}

export default WorkflowDuplicateButton;
