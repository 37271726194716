import { memo } from 'react';
import { useRecoilValue } from 'recoil';

import AssetAudio from '@components/asset/AssetAudio';
import AssetCaption from '@components/asset/AssetCaption';
import AssetFont from '@components/asset/AssetFont';
import AssetHtml from '@components/asset/AssetHtml';
import AssetImage from '@components/asset/AssetImage';
import AssetMask from '@components/asset/AssetMask';
import AssetOverlay from '@components/asset/AssetOverlay';
import AssetText from '@components/asset/AssetText';
import AssetTextToSpeech from '@components/asset/AssetTextToSpeech';
import AssetTitle from '@components/asset/AssetTitle';
import AssetVideo from '@components/asset/AssetVideo';

import { clipSettingsSelectorFamily } from '@store/EditSelectors';

const AssetPreviewComponentMap = {
  audio: AssetAudio,
  font: AssetFont,
  html: AssetHtml,
  image: AssetImage,
  mask: AssetMask,
  text: AssetText,
  title: AssetTitle,
  video: AssetVideo,
  overlay: AssetOverlay,
  caption: AssetCaption,
  'text-to-speech': AssetTextToSpeech,
};

function AssetPreview({ id, type }) {
  const clip = useRecoilValue(clipSettingsSelectorFamily(id));
  const AssetPreviewComponent = AssetPreviewComponentMap[type || clip['asset:type']];

  if (!AssetPreviewComponent) {
    return null;
  }

  return <AssetPreviewComponent id={id} />;
}

export default memo(AssetPreview);
