import Editor from '@monaco-editor/react';
import { useEffect, useState } from 'react';

import '@assets/css/CodeEditor.css';

import { theme } from '@constants/CodeEditorTheme';

function CodeEditor({ value, options, language = 'json', onChange }) {
  const [editorValue, setEditorValue] = useState('');

  useEffect(() => {
    setEditorValue(JSON.stringify(value || {}, null, 2));
  }, [value]);

  const beforeMount = (monaco) => {
    monaco.editor.defineTheme('shotstack', theme);
  };

  return (
    <Editor
      theme="shotstack"
      className="code-editor"
      language={language}
      beforeMount={beforeMount}
      options={{
        minimap: {
          enabled: false,
        },
        scrollBeyondLastLine: false,
        automaticLayout: true,
        ...options,
      }}
      value={editorValue}
      onChange={(newValue) => {
        setEditorValue(newValue);
        if (onChange) {
          onChange(newValue);
        }
      }}
    />
  );
}

export default CodeEditor;
