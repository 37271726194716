import { memo } from 'react';

import { IconText } from '@assets/icons';

import TimelineClipInfo from '@feature/studio/timeline/TimelineClipInfo';

function TimelineClipText({ id, clip }) {
  return (
    <div className="clip__inner">
      <div className="clip__header">
        <span className="clip__icon">
          <IconText size={14} />
        </span>
        <TimelineClipInfo id={id} />

        {clip['asset:text'] ? (
          <div className="clip__content-raw" dangerouslySetInnerHTML={{ __html: clip['asset:text'] }} />
        ) : (
          <span className="clip__type">{clip['asset:type']}</span>
        )}
      </div>
    </div>
  );
}

export default memo(TimelineClipText);
