import cn from 'classnames';

import '@css/WorkflowTaskBlock.css';

import WorkflowTaskIcon from '@feature/workflows/task/WorkflowTaskIcon';

import ButtonElement from '@components/atoms/ButtonElement';

function Header({ children, type, module, actions }) {
  const [, category] = (module || '').split(':');
  const tags = [type, category].filter(Boolean);

  return (
    <div className="workflow-taskblock__header">
      <div className="workflow-taskblock__title">
        <WorkflowTaskIcon type={type} module={module} containerClass="workflow-taskblock__icon" />
        <div className="workflow-taskblock__text">
          <div className="workflow__tags">
            {tags.map((tag) => (
              <div key={tag} className="workflow__tag">
                {tag}
              </div>
            ))}
          </div>
          <h2 className="workflow-taskblock__name">{children}</h2>
        </div>
      </div>
      <div className="workflow-taskblock__actions">{actions}</div>
    </div>
  );
}

function Body({ children }) {
  return <div className="workflow-taskblock__body">{children}</div>;
}

function Footer({ children }) {
  return <div className="workflow-taskblock__footer">{children}</div>;
}

function Connector({ connected, onAddTask }) {
  return (
    <div className={cn('workflow-taskblock__connector', { connected })}>
      <div className="workflow-connector__line" />
      {!connected && (
        <ButtonElement className="workflow-connector__action" handleEvent={onAddTask}>
          +
        </ButtonElement>
      )}
    </div>
  );
}

function WorkflowTaskBlock({ children, active, type, categoryGroup, connected, readOnly, onClick, onAddTask }) {
  return (
    <div
      className={cn('workflow-taskblock', type, {
        active,
        [categoryGroup]: Boolean(categoryGroup),
        'read-only': readOnly,
      })}
    >
      <div className="workflow-taskblock__inner" onClick={onClick}>
        {children}
      </div>
      {type !== 'end' && <Connector connected={connected} onAddTask={onAddTask} />}
    </div>
  );
}

WorkflowTaskBlock.Header = Header;
WorkflowTaskBlock.Body = Body;
WorkflowTaskBlock.Footer = Footer;
WorkflowTaskBlock.Connector = Connector;

export default WorkflowTaskBlock;
