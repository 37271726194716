import { useCallback, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useRecoilState } from 'recoil';

import { IconEnd, IconVolumeFull } from '@assets/icons';

import ChromaKeyFields from '@feature/studio/fields/ChromaKeyFields';
import OffsetFields from '@feature/studio/fields/OffsetFields';
import SizeFields from '@feature/studio/fields/SizeFields';
import TimingFields from '@feature/studio/fields/TimingFields';
import TransformFields from '@feature/studio/fields/TransformFields';
import VisualEffectsFields from '@feature/studio/fields/VisualEffectsFields';

import AssetSource from '@components/controls/AssetSource';
import InputNumber from '@components/controls/InputNumber';
import OverrideMenu from '@components/mergeFields/OverrideMenu';

import { clipSettingsSelectorFamily } from '@store/EditSelectors';

const CHROMA_KEY_DEFAULTS = {
  'asset:chromaKey:color': '#00FF00',
  'asset:chromaKey:threshold': 150,
  'asset:chromaKey:halo': 50,
};
const CHROMA_KEY_EMPTY = {
  'asset:chromaKey:color': undefined,
  'asset:chromaKey:threshold': undefined,
  'asset:chromaKey:halo': undefined,
};

function SettingAssetVideo({ id }) {
  const [clip, setClip] = useRecoilState(clipSettingsSelectorFamily(id));
  const [chromaKeyDisabled, setChromaKeyDisabled] = useState(Boolean(!clip['asset:chromaKey:color']));

  const handleTrimChange = useCallback(
    (event) => {
      const { value: trim } = event.target;

      let newValue = '';
      if (trim.length > 0 && trim >= 0) {
        newValue = parseFloat(trim);
      }

      setClip({ 'asset:trim': newValue });
    },
    [setClip]
  );

  const handleVolumeChange = useCallback(
    (event) => {
      const { value: volume } = event.target;

      let newValue = '';
      if (volume.length > 0 && volume >= 0 && volume <= 1) {
        newValue = parseFloat(volume);
      }
      setClip({ 'asset:volume': newValue });
    },
    [setClip]
  );

  const handleChromaKeyChange = useCallback(
    (event) => {
      const { checked } = event.target;
      setChromaKeyDisabled(!checked);
      setClip({
        ...(checked ? CHROMA_KEY_DEFAULTS : CHROMA_KEY_EMPTY),
      });
    },
    [setClip]
  );

  return (
    <>
      <div className="mb-10">
        <AssetSource id={id} type="video" />
        <VisualEffectsFields id={id} />
      </div>

      <h6>Trim & Volume</h6>
      <Row className="mb-10">
        <Col className="d-flex align-items-center justify-content-between gap-2">
          <InputNumber
            id="trim"
            name="trim"
            title="Trim Start"
            value={clip['asset:trim'] >= 0 ? clip['asset:trim'] : ''}
            placeholder="0"
            min={0}
            step={0.01}
            prefix={<IconEnd size={14} />}
            handleChange={handleTrimChange}
          />
          <OverrideMenu path="asset:trim" />
        </Col>
        <Col className="d-flex align-items-center justify-content-between gap-2">
          <InputNumber
            id="volume"
            name="volume"
            title="Volume"
            max={1}
            min={0}
            step={0.01}
            value={clip['asset:volume'] >= 0 ? clip['asset:volume'] : ''}
            placeholder="1"
            prefix={<IconVolumeFull size={14} />}
            handleChange={handleVolumeChange}
          />
          <OverrideMenu path="asset:volume" />
        </Col>
      </Row>

      <h6>Size &amp; Position</h6>
      <div className="mb-10">
        <div className="mb-2">
          <SizeFields id={id} />
        </div>
        <OffsetFields id={id} />
      </div>

      <h6>Transform</h6>
      <div className="mb-10">
        <TransformFields id={id} />
      </div>

      <h6>Timing</h6>
      <div className="mb-10">
        <TimingFields id={id} />
      </div>

      <h6 className="d-flex align-items-center justify-content-between gap-2">
        <div>Chroma Key</div>
        <Form.Check
          className="mb-0"
          type="switch"
          id="chroma-toggle"
          checked={!chromaKeyDisabled}
          onChange={handleChromaKeyChange}
        />
      </h6>
      <ChromaKeyFields id={id} disabled={chromaKeyDisabled} />
    </>
  );
}

export default SettingAssetVideo;
