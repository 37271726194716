const setPrecision = (value, precision) => {
  if (precision < 0) {
    return value;
  }

  const factor = 10 ** precision;
  return Math.round(value * factor) / factor;
};

export default setPrecision;
