import config from '@config';
import { snakeCase, uniq } from 'lodash-es';
import { atom, atomFamily, selector, selectorFamily, useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';

import { getTemplateRaw } from '@api/services/template';
import WorkflowService from '@api/services/workflow';

import { authKeysSelector, authPlanSelector, authReadySelector, stageAtom } from '@store/Auth';
import { allTemplatesListSelector } from '@store/Template';

import { PLAN_NAMES, WORKFLOW_LIMITS, getNextLimit } from '@constants/PlanLimits';
import { stageMap } from '@constants/Stage';
import { MODULE_ATTRIBUTES, MODULE_INPUTS, MODULE_OPTIONS, TASK_DEFAULTS } from '@constants/Workflows';

import { getSnippet } from '@utils/editor/getSnippet';
import { formatDate } from '@utils/formatDate';
import { jsonParse } from '@utils/jsonParse';

/*
 * States
 */

export const activeWorkflowAtom = atom({
  key: 'workflow/active',
  default: null,
});

export const taskIdsAtom = atom({
  key: 'workflow/task/ids',
  default: [],
});

export const activeTaskAtom = atom({
  key: 'workflow/task/active',
  default: null,
});

export const bulkDeleteAtom = atom({
  key: 'workflow/delete/bulk',
  default: [],
});

export const upgradeModalAtom = atom({
  key: 'workflow/modal/upgrade',
  default: false,
});

// workflowPanelState, WorkflowProcessingState

export const taskNameAtomFamily = atomFamily({
  key: 'workflow/task/name',
  default: '',
});

export const taskModuleAtomFamily = atomFamily({
  key: 'workflow/task/module',
  default: '',
});

export const taskTypeAtomFamily = atomFamily({
  key: 'workflow/task/type',
  default: null,
});

export const taskProviderAtomFamily = atomFamily({
  key: 'workflow/task/provider',
  default: null,
});

export const taskConnectedAtomFamily = atomFamily({
  key: 'workflow/task/connected',
  default: false,
});

export const taskOptionsAtomFamily = atomFamily({
  key: 'workflow/task/options',
  default: '',
});

export const taskInputsAtomFamily = atomFamily({
  key: 'workflow/task/inputs',
  default: '',
});

export const taskOutputsAtomFamily = atomFamily({
  key: 'workflow/task/outputs',
  default: '',
});

export const workflowNameAtomFamily = atomFamily({
  key: 'workflow/name',
  default: '',
});

export const workflowDataSampleAtomFamily = atomFamily({
  key: 'workflow/data/sample',
  default: '',
});

export const workflowDataMappingAtomFamily = atomFamily({
  key: 'workflow/data/mappings',
  default: {},
});

/*
 * Selectors
 */

export const workflowModulesSelector = selector({
  key: 'workflow/modules',
  get: ({ get }) => {
    const taskIds = get(taskIdsAtom);
    const modules = taskIds.map((id) => get(taskModuleAtomFamily(id)));
    return modules;
  },
});

export const taskSelectorFamily = selectorFamily({
  key: 'workflow/task',
  get:
    (id) =>
    ({ get }) => {
      const task = {
        id,
        active: get(activeTaskAtom) === id,
        type: get(taskTypeAtomFamily(id)),
        provider: get(taskProviderAtomFamily(id)),
        name: get(taskNameAtomFamily(id)),
        module: get(taskModuleAtomFamily(id)),
        options: get(taskOptionsAtomFamily(id)),
        inputs: get(taskInputsAtomFamily(id)),
        outputs: get(taskOutputsAtomFamily(id)),
      };
      return task;
    },
});

export const workflowTriggerSelector = selector({
  key: 'workflow/trigger',
  get: ({ get }) => {
    const taskIds = get(taskIdsAtom);
    const triggerTaskId = taskIds[0];
    const { module } = get(taskSelectorFamily(triggerTaskId));
    return module;
  },
});

export const workflowWebhookUrlSelector = selector({
  key: 'workflow/webhook/url',
  get: ({ get }) => {
    const stage = get(stageAtom);
    const activeWorkflowId = get(activeWorkflowAtom);
    return `${config.workflow.url}${stage}/workflows/${activeWorkflowId}/jobs`;
  },
});

export const workflowWebhookSnippetSelector = selector({
  key: 'workflow/webhook/snippet',
  get: async ({ get }) => {
    const url = get(workflowWebhookUrlSelector);
    const keys = await get(authKeysSelector);
    const currentStage = get(stageAtom);
    const activeWorkflow = get(activeWorkflowAtom);
    const workflowData = get(workflowDataSampleAtomFamily(activeWorkflow));
    const workflowDataJson = jsonParse(workflowData || '{}');

    const stage = currentStage || '';
    const key = keys[stageMap[stage]]?.key || '';

    const snippet = getSnippet({
      data: {
        json: workflowDataJson,
        key,
        url,
      },
      language: 'http',
    });

    return {
      url,
      key,
      snippet,
      data: workflowDataJson,
    };
  },
});

export const workflowDataSelector = selector({
  key: 'workflow/data',
  get: ({ get }) => {
    const taskIds = get(taskIdsAtom);
    const activeTaskId = get(activeTaskAtom);
    const activeWorkflowId = get(activeWorkflowAtom);
    const workflowDataMappings = get(workflowDataMappingAtomFamily(activeWorkflowId));

    const priorTaskIds = taskIds.slice(0, taskIds.indexOf(activeTaskId));
    const workflowOutputParams = priorTaskIds
      .map((taskId) => Object.values(get(taskOutputsAtomFamily(taskId)) || {}))
      .filter(Boolean)
      .flat();

    return {
      inputs: Object.keys(workflowDataMappings),
      outputs: workflowOutputParams,
    };
  },
});

export const workflowDataInputsListSelector = selector({
  key: 'workflow/data/inputs/list',
  get: ({ get }) => {
    const { inputs } = get(workflowDataSelector);
    return inputs.filter((value) => value).map((value) => ({ label: value, value }));
  },
});

export const workflowDataOutputsListSelector = selector({
  key: 'workflow/data/outputs/list',
  get: ({ get }) => {
    const { outputs } = get(workflowDataSelector);
    return outputs.filter((value) => value).map((value) => ({ label: value, value }));
  },
});

export const workflowDataListSelector = selector({
  key: 'workflow/data/list',
  get: ({ get }) => {
    const inputsList = get(workflowDataInputsListSelector);
    const outputsList = get(workflowDataOutputsListSelector);
    const list = [...inputsList, ...outputsList];

    return {
      list,
      grouped: [
        {
          label: 'Trigger Data',
          options: inputsList,
        },
        {
          label: 'Action Data',
          options: outputsList,
        },
      ],
    };
  },
});

export const taskRenderTemplateListSelectorFamily = selectorFamily({
  key: 'workflow/tasks/module/render',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get:
    (id) =>
    async ({ get }) => {
      const allTemplates = await get(allTemplatesListSelector);
      const taskOptions = get(taskOptionsAtomFamily(id));

      const { templates } = allTemplates;
      const options = (templates || [])?.map((template) => ({
        label: template.name,
        value: template.id,
      }));
      const selected = options?.find(({ value }) => value === taskOptions.templateId) || null;

      return { options, selected };
    },
});

export const allWorkflowsSelector = selector({
  key: 'workflow/all',
  get: async ({ get }) => {
    await get(authReadySelector);

    const { workflows } = await WorkflowService.list();
    return workflows;
  },
});

export const allWorkflowsListSelector = selector({
  key: 'workflow/all/list',
  get: async ({ get }) => {
    const plan = await get(authPlanSelector);
    const allWorkflows = await get(allWorkflowsSelector);
    const isFreePlan = plan === 'free';

    let workflows = allWorkflows || [];

    if (isFreePlan && workflows?.length) {
      const oldest = [...workflows].pop();
      workflows = [oldest];
    }

    return {
      list: allWorkflows,
      meta: {
        isFreePlan: false,
        isRestricted: false,
      },
    };
  },
});

export const workflowActionsLimitSelector = selector({
  key: 'workflow/actions/limit',
  get: async ({ get }) => {
    const plan = await get(authPlanSelector);
    const limits = WORKFLOW_LIMITS.actions;
    const actionsLimit = limits[plan];
    const actionsCount = get(taskIdsAtom).length - 1;
    const actionsRemaining = actionsLimit - actionsCount;
    const isLimitReached = actionsRemaining <= 0;
    const nextPlan = getNextLimit(limits, plan);

    return {
      plan: PLAN_NAMES[plan],
      nextPlan,
      actionsLimit,
      isLimitReached,
    };
  },
});

export const workflowSelectorFamily = selectorFamily({
  key: 'workflow',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get:
    (id) =>
    async ({ get }) => {
      const ready = await get(authReadySelector);
      if (!ready) {
        return {};
      }

      const stage = get(stageAtom);

      try {
        const { workflow } = await WorkflowService.get(id);
        const created = formatDate(workflow?.created) || 'Today';
        const url = `${config.workflow.url}${stage}/workflows/${id}/jobs`;
        const trigger = workflow?.configuration?.trigger?.module;

        return { ...workflow, meta: { created, url: trigger === 'shotstack:webhook' ? url : undefined } };
      } catch (error) {
        return {};
      }
    },
});

export const workflowJobDetailSelectorFamily = selectorFamily({
  key: 'workflow/job/detail',
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get:
    ({ id, workflowId }) =>
    async ({ get }) => {
      const ready = await get(authReadySelector);
      if (!ready) {
        return {};
      }

      const { job } = await WorkflowService.jobs.get(workflowId, id);
      const { tasks } = await WorkflowService.jobs.tasks(workflowId, id);
      return { job, tasks };
    },
});

/*
 * Callbacks
 */

const addTaskCallback = (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return async (siblingTaskId) => {
    const { isLimitReached } = await snapshot.getLoadable(workflowActionsLimitSelector).contents;
    if (isLimitReached) {
      set(upgradeModalAtom, true);
      return null;
    }

    const taskId = uuid();
    set(taskIdsAtom, (ids) => {
      const index = ids.indexOf(siblingTaskId);
      return [...ids.slice(0, index + 1), taskId, ...ids.slice(index + 1)];
    });
    set(taskNameAtomFamily(taskId), TASK_DEFAULTS.name);
    set(taskModuleAtomFamily(taskId), TASK_DEFAULTS.module);
    set(taskTypeAtomFamily(taskId), TASK_DEFAULTS.type);
    set(taskProviderAtomFamily(taskId), TASK_DEFAULTS.provider);
    set(taskConnectedAtomFamily(taskId), TASK_DEFAULTS.connected);
    set(taskOutputsAtomFamily(taskId), TASK_DEFAULTS.mappings.outputs);
    set(taskInputsAtomFamily(taskId), TASK_DEFAULTS.mappings.inputs);

    const activateStepTimeout = setTimeout(() => {
      clearTimeout(activateStepTimeout);
      set(activeTaskAtom, taskId);
    }, 0);
    return taskId;
  };
};

const removeTaskCallback = (callbackArgs) => {
  const { set, reset, snapshot } = callbackArgs;
  return (id) => {
    const taskIds = snapshot.getLoadable(taskIdsAtom).contents;
    const activeTaskId = taskIds[taskIds.indexOf(id) - 1];
    const updatedTaskIds = taskIds.filter((taskId) => taskId !== id);

    set(taskIdsAtom, updatedTaskIds);
    reset(taskNameAtomFamily(id));
    reset(taskModuleAtomFamily(id));
    reset(taskTypeAtomFamily(id));
    reset(taskProviderAtomFamily(id));
    reset(taskConnectedAtomFamily(id));
    reset(taskOutputsAtomFamily(id));
    reset(taskInputsAtomFamily(id));

    const activateStepTimeout = setTimeout(() => {
      clearTimeout(activateStepTimeout);
      set(activeTaskAtom, activeTaskId);
    }, 0);
  };
};

const editTaskCallback = (callbackArgs) => {
  const { set } = callbackArgs;
  return (id) => {
    set(activeTaskAtom, id);
  };
};

const selectModuleCallback = (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return (module) => {
    const id = snapshot.getLoadable(activeTaskAtom).contents;
    const workflowModules = snapshot.getLoadable(workflowModulesSelector).contents;
    const siblingModulesCount = workflowModules.filter((m) => m === module).length + 1;
    const { title, type, provider, outputKey } = MODULE_ATTRIBUTES[module];
    const outputLabel = uniq([provider, type, outputKey, siblingModulesCount]).join(' ');
    const outputValue = snakeCase(outputLabel).toUpperCase();
    const moduleOptions = MODULE_OPTIONS[module];
    const moduleInputs = MODULE_INPUTS[module];

    set(taskNameAtomFamily(id), title);
    set(taskModuleAtomFamily(id), module);
    set(taskProviderAtomFamily(id), provider);
    set(taskOptionsAtomFamily(id), moduleOptions);
    set(taskInputsAtomFamily(id), moduleInputs);
    set(taskOutputsAtomFamily(id), { [outputKey]: outputValue });
  };
};

const nextPrevTaskCallback = (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return (direction) => {
    const id = snapshot.getLoadable(activeTaskAtom).contents;
    const taskIds = snapshot.getLoadable(taskIdsAtom).contents;
    const currentIndex = taskIds.indexOf(id);
    let nextPrevIndex;
    if (direction === 'next') {
      nextPrevIndex = currentIndex + 1;
    } else if (direction === 'prev') {
      nextPrevIndex = currentIndex - 1;
    }
    const nextPrevTaskId = nextPrevIndex >= 0 && nextPrevIndex < taskIds.length ? taskIds[nextPrevIndex] : id;
    set(activeTaskAtom, nextPrevTaskId);
  };
};

const activateTaskOfTypeCallback = (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return (type) => {
    const taskIds = snapshot.getLoadable(taskIdsAtom).contents;
    const taskId = taskIds.find((id) => snapshot.getLoadable(taskTypeAtomFamily(id)).contents === type);
    if (type === 'action') {
      const addTask = addTaskCallback(callbackArgs);
      const lastTaskId = taskIds[taskIds.length - 1];
      addTask(lastTaskId);
    }
    set(activeTaskAtom, taskId);
  };
};

const resetModuleCallback = (callbackArgs) => {
  const { snapshot, reset } = callbackArgs;
  return () => {
    const id = snapshot.getLoadable(activeTaskAtom).contents;

    reset(taskNameAtomFamily(id));
    reset(taskModuleAtomFamily(id));
    reset(taskProviderAtomFamily(id));
    reset(taskInputsAtomFamily(id));
    reset(taskOutputsAtomFamily(id));
  };
};

const selectRenderTemplateCallback = (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return async (templateId) => {
    const id = snapshot.getLoadable(activeTaskAtom).contents;
    const templateData = await getTemplateRaw(templateId);
    const {
      template: { merge },
    } = templateData;
    const inputs = (merge || []).reduce((acc, { find }) => {
      acc[find] = null;
      return acc;
    }, {});
    set(taskInputsAtomFamily(id), inputs);
    set(taskOptionsAtomFamily(id), { templateId });
  };
};

const hydrateWorkflowCallback = (callbackArgs) => {
  const { set, reset } = callbackArgs;
  return ({ id, tasks, name, data }) => {
    if (!id) return;
    const { sample, mappings } = data || {};

    set(workflowNameAtomFamily(id), name || '');
    set(workflowDataSampleAtomFamily(id), sample || '');
    set(workflowDataMappingAtomFamily(id), mappings || {});

    reset(taskIdsAtom);
    (tasks || []).forEach((task) => {
      const taskId = uuid();
      set(taskIdsAtom, (ids) => [...ids, taskId]);
      set(taskNameAtomFamily(taskId), task.name);
      set(taskModuleAtomFamily(taskId), task.module);
      set(taskTypeAtomFamily(taskId), task.type);
      set(taskProviderAtomFamily(taskId), task.provider);
      set(taskConnectedAtomFamily(taskId), task.connected);
      set(taskOptionsAtomFamily(taskId), task.options);
      set(taskInputsAtomFamily(taskId), task.inputs);
      set(taskOutputsAtomFamily(taskId), task.outputs);
    });

    set(activeWorkflowAtom, id);
  };
};

const resetWorkflowsCallback = (callbackArgs) => {
  const { set, reset, refresh } = callbackArgs;
  return (id) => {
    set(taskIdsAtom, (ids) => ids.filter((taskId) => taskId !== id));
    refresh(workflowSelectorFamily(id));
    reset(workflowNameAtomFamily(id));
    reset(workflowDataSampleAtomFamily(id));
    reset(workflowDataMappingAtomFamily(id));
    reset(taskNameAtomFamily(id));
    reset(taskModuleAtomFamily(id));
    reset(taskTypeAtomFamily(id));
    reset(taskProviderAtomFamily(id));
    reset(taskConnectedAtomFamily(id));
    reset(taskOutputsAtomFamily(id));
    reset(taskOptionsAtomFamily(id));
    reset(taskInputsAtomFamily(id));
    reset(activeWorkflowAtom);
    reset(activeTaskAtom);
  };
};

const removeWorkflowCallback = (callbackArgs) => {
  const { snapshot, refresh, reset } = callbackArgs;
  return async () => {
    const deleteable = snapshot.getLoadable(bulkDeleteAtom).contents;
    const workflowList = snapshot.getLoadable(allWorkflowsSelector).contents;
    const updatedState = workflowList.filter((id) => !deleteable.includes(id));

    await WorkflowService.delete(deleteable);

    refresh(allWorkflowsSelector);
    reset(bulkDeleteAtom);

    return updatedState;
  };
};

const toggleDeleteWorkflowCallback = (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return (id) => {
    let deleteable = snapshot.getLoadable(bulkDeleteAtom).contents;
    if (deleteable.includes(id)) {
      deleteable = deleteable.filter((templateId) => templateId !== id);
    } else {
      deleteable = [...deleteable, id];
    }
    set(bulkDeleteAtom, deleteable);
    return deleteable;
  };
};

const getDerivedWorkflow = (callbackArgs) => {
  const { snapshot } = callbackArgs;
  return () => {
    const id = snapshot.getLoadable(activeWorkflowAtom).contents;
    const name = snapshot.getLoadable(workflowNameAtomFamily(id)).contents;
    const taskIds = snapshot.getLoadable(taskIdsAtom).contents;
    const tasks = taskIds.map((taskId) => snapshot.getLoadable(taskSelectorFamily(taskId)).contents);
    const sampleData = snapshot.getLoadable(workflowDataSampleAtomFamily(id)).contents;
    const mappings = snapshot.getLoadable(workflowDataMappingAtomFamily(id)).contents;

    const workflow = {
      id,
      name,
      tasks,
      sampleData,
      mappings,
    };
    return workflow;
  };
};

const saveWorkflowCallback = (callbackArgs) => {
  const { snapshot, refresh } = callbackArgs;
  return async () => {
    const id = snapshot.getLoadable(activeWorkflowAtom).contents;
    const derivedWorkflow = getDerivedWorkflow(callbackArgs)();

    await WorkflowService.update(id, derivedWorkflow);

    refresh(allWorkflowsSelector);
  };
};

export const useAddTask = () => useRecoilCallback(addTaskCallback);
export const useRemoveTask = () => useRecoilCallback(removeTaskCallback);
export const useEditTask = () => useRecoilCallback(editTaskCallback);
export const useSelectMolule = () => useRecoilCallback(selectModuleCallback);
export const useNextPrevTask = () => useRecoilCallback(nextPrevTaskCallback);
export const useActivateTaskOfType = () => useRecoilCallback(activateTaskOfTypeCallback);
export const useResetModule = () => useRecoilCallback(resetModuleCallback);
export const useSelectRenderTemplate = () => useRecoilCallback(selectRenderTemplateCallback);
export const useHydrateWorkflow = () => useRecoilCallback(hydrateWorkflowCallback);
export const useGetDerivedWorkflow = () => useRecoilCallback(getDerivedWorkflow);
export const useResetWorkflows = () => useRecoilCallback(resetWorkflowsCallback);
export const useRemoveWorkflow = () => useRecoilCallback(removeWorkflowCallback);
export const useSaveWorkflow = () => useRecoilCallback(saveWorkflowCallback);
export const useToggleDeleteWorkflow = () => useRecoilCallback(toggleDeleteWorkflowCallback);
