import { deserialize } from '@api/utils/deserializer';

const responseInterceptors = [
  async (response) => {
    const hasBodyStructure = Boolean(response?.data?.body);
    const { data, ...restBodyContent } = hasBodyStructure ? response.data.body : { data: response.data };
    const deserializedData = await deserialize(data);

    const newResponse = {
      ...response,
      data: {
        body: {
          ...restBodyContent,
          data: deserializedData,
        },
      },
    };

    // if (config.isDev) {
    //   /* eslint-disable no-console */
    //   console.group('Response Debugging: ', response.request.responseURL);
    //   console.debug({ original: response, deserialised: newResponse });
    //   console.groupEnd();
    //   /* eslint-enable no-console */
    // }

    return newResponse;
  },
  (error) => {
    if (!error.toJSON) {
      return Promise.reject(error);
    }
    const errorJson = error.toJSON();
    const { status, config } = errorJson;

    // Todo: move this to a separate interceptor
    if (status === 404) {
      if (config.url.includes('templates')) {
        return Promise.resolve({ config, status: 200, data: { body: [] } });
      }
    }

    return Promise.reject(error);
  },
];

export default responseInterceptors;
