import SubscriptionListItemDetails from '@feature/subscription/SubscriptionListItemDetails';

function CancelItem({ subscription, user, subscriber, hideButton }) {
  if (!subscription) {
    return null;
  }

  return (
    <SubscriptionListItemDetails
      subscription={subscription}
      user={user}
      subscriber={subscriber}
      hideButton={hideButton}
    />
  );
}

export default CancelItem;
