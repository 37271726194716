export const VIMEO_VIEW_OPTIONS = ['anybody', 'nobody', 'contacts', 'password', 'unlisted']
  .sort()
  .map((view) => ({ label: view, value: view }));

export const VIMEO_EMBED_OPTIONS = ['public', 'private', 'whitelist']
  .sort()
  .map((embed) => ({ label: embed, value: embed }));

export const VIMEO_COMMENT_OPTIONS = ['anybody', 'nobody', 'contacts']
  .sort()
  .map((comment) => ({ label: comment, value: comment }));
