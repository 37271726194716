/**
 * @typedef {import('@animation/animate').AnimationProperties} AnimationProperties
 */

/**
 * @template T
 * @typedef {import('@animation/keyframe').Keyframe<T>} Keyframe<T>
 */

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:x']: number; opacity: number; }>}
 */
export const slideLeftTransitionInKeyframe = (start, end, properties) => {
  const slidePercent = 0.025;

  const { clip } = properties;

  const slideFrom = clip['offset:x'] || 0 + slidePercent;
  const slideTo = clip['offset:x'] || 0;

  return {
    from: {
      ['offset:x']: slideFrom,
      opacity: 0,
    },
    to: {
      ['offset:x']: slideTo,
      opacity: 1,
    },
    start,
    end,
    interpolation: {
      ['offset:x']: 'easeIn',
      opacity: 'easeIn',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:x']: number; opacity: number; }>}
 */
export const slideLeftTransitionOutKeyframe = (start, end, properties) => {
  const slidePercent = 0.025;

  const { clip } = properties;

  const slideFrom = clip['offset:x'] || 0;
  const slideTo = clip['offset:x'] || 0 - slidePercent;

  return {
    from: {
      ['offset:x']: slideFrom,
      opacity: 1,
    },
    to: {
      ['offset:x']: slideTo,
      opacity: 0,
    },
    start,
    end,
    interpolation: {
      ['offset:x']: 'easeOut',
      opacity: 'easeOut',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:x']: number; opacity: number; }>}
 */
export const slideRightTransitionInKeyframe = (start, end, properties) => {
  const slidePercent = 0.025;

  const { clip } = properties;
  const offsetX = clip['offset:x'] || 0;

  const slideFrom = offsetX - slidePercent;
  const slideTo = offsetX;

  return {
    from: {
      ['offset:x']: slideFrom,
      opacity: 0,
    },
    to: {
      ['offset:x']: slideTo,
      opacity: 1,
    },
    start,
    end,
    interpolation: {
      ['offset:x']: 'easeIn',
      opacity: 'easeIn',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:x']: number; opacity: number; }>}
 */
export const slideRightTransitionOutKeyframe = (start, end, properties) => {
  const slidePercent = 0.025;

  const { clip } = properties;
  const offsetX = clip['offset:x'] || 0;

  const slideFrom = offsetX;
  const slideTo = offsetX + slidePercent;

  return {
    from: {
      ['offset:x']: slideFrom,
      opacity: 1,
    },
    to: {
      ['offset:x']: slideTo,
      opacity: 0,
    },
    start,
    end,
    interpolation: {
      ['offset:x']: 'easeOut',
      opacity: 'easeOut',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:y']: number; opacity: number; }>}
 */
export const slideUpTransitionInKeyframe = (start, end, properties) => {
  const slidePercent = 0.025;

  const { clip } = properties;
  const offsetY = clip['offset:y'] || 0;

  const slideFrom = offsetY - slidePercent;
  const slideTo = offsetY;

  return {
    from: {
      ['offset:y']: slideFrom,
      opacity: 0,
    },
    to: {
      ['offset:y']: slideTo,
      opacity: 1,
    },
    start,
    end,
    interpolation: {
      ['offset:y']: 'easeIn',
      opacity: 'easeIn',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:y']: number; opacity: number; }>}
 */
export const slideUpTransitionOutKeyframe = (start, end, properties) => {
  const slidePercent = 0.025;

  const { clip } = properties;
  const offsetY = clip['offset:y'] || 0;

  const slideFrom = offsetY;
  const slideTo = offsetY + slidePercent;

  return {
    from: {
      ['offset:y']: slideFrom,
      opacity: 1,
    },
    to: {
      ['offset:y']: slideTo,
      opacity: 0,
    },
    start,
    end,
    interpolation: {
      ['offset:y']: 'easeIn',
      opacity: 'easeIn',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:y']: number; opacity: number; }>}
 */
export const slideDownTransitionInKeyframe = (start, end, properties) => {
  const slidePercent = 0.025;

  const { clip } = properties;
  const offsetY = clip['offset:y'] || 0;

  const slideFrom = offsetY + slidePercent;
  const slideTo = offsetY;

  return {
    from: {
      ['offset:y']: slideFrom,
      opacity: 0,
    },
    to: {
      ['offset:y']: slideTo,
      opacity: 1,
    },
    start,
    end,
    interpolation: {
      ['offset:y']: 'easeOut',
      opacity: 'easeOut',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ ['offset:y']: number; opacity: number; }>}
 */
export const slideDownTransitionOutKeyframe = (start, end, properties) => {
  const slidePercent = 0.025;

  const { clip } = properties;
  const offsetY = clip['offset:y'] || 0;

  const slideFrom = offsetY;
  const slideTo = offsetY - slidePercent;

  return {
    from: {
      ['offset:y']: slideFrom,
      opacity: 1,
    },
    to: {
      ['offset:y']: slideTo,
      opacity: 0,
    },
    start,
    end,
    interpolation: {
      ['offset:y']: 'easeOut',
      opacity: 'easeOut',
    },
  };
};
