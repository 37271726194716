import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import LinkElement from '@components/atoms/LinkElement';
import InteractiveConsole from '@components/console/InteractiveConsole';

function OverviewIngestApi() {
  const { product } = useParams();
  return (
    <>
      <Card className="p-3 mb-10">
        <Card.Body>
          <p className="uppercase-label">Ingest Api</p>
          <h1>Media Upload, Storage And Transformations</h1>
          <p className="text-prose text-gray-500">
            Upload, transform and transcode your media in one place using the Ingest API.
          </p>
          <LinkElement
            to="https://shotstack.io/docs/guide/ingesting-footage/ingestion/"
            variant="light"
            type="button"
            external
          >
            Documentation
          </LinkElement>
          <InteractiveConsole product={product} />
        </Card.Body>
      </Card>
    </>
  );
}

export default OverviewIngestApi;
