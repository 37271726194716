/**
 * @typedef {import('@animation/animate').AnimationProperties} AnimationProperties
 */

/**
 * @template T
 * @typedef {import('@animation/keyframe').Keyframe<T>} Keyframe<T>
 */

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ scale: number; opacity: number; }>}
 */
export const zoomTransitionInKeyframe = (start, end, properties) => {
  const { clip, canvas } = properties;
  const scale = clip.scale || 1;
  // TODO: Fix this, shouldn't need to be a different width for text and html
  const assetWidth = ['text', 'html'].includes(clip['asset:type']) ? clip['asset:width'] : clip['asset:meta'].width;

  return {
    from: {
      scale: canvas.width / (assetWidth / 2),
      opacity: 0,
    },
    to: {
      scale,
      opacity: 1,
    },
    start,
    end,
    interpolation: {
      scale: 'easeIn',
      opacity: 'easeIn',
    },
  };
};

/**
 * @type {(start: number, end: number, properties: AnimationProperties) => Keyframe<{ scale: number; opacity: number; }>}
 */
export const zoomTransitionOutKeyframe = (start, end, properties) => {
  const { clip, canvas } = properties;
  const scale = clip.scale || 1;
  // TODO: Fix this, shouldn't need to be a different width for text and html
  const assetWidth = ['text', 'html'].includes(clip['asset:type']) ? clip['asset:width'] : clip['asset:meta'].width;

  return {
    from: {
      scale,
      opacity: 1,
    },
    to: {
      scale: canvas.width / (assetWidth / 2),
      opacity: 0,
    },
    start,
    end,
    interpolation: {
      scale: 'easeOut',
      opacity: 'easeOut',
    },
  };
};
