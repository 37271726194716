import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import '@css/TableData.css';

import { IconExternal } from '@assets/icons';

import AlertBox from '@components/alert/AlertBox';
import AssetDetailsPreview from '@components/asset/AssetDetailsPreview';
import Content from '@components/content/Content';
import Skeleton from '@components/skeletons/Skeleton';
import StatusBadge from '@components/status/StatusBadge';

import { videoFormats } from '@constants/Formats';

import formatFileSize from '@utils/formatFileSize';

const getSourceMetadata = (source) => {
  const { created, id, url, width, height, fps, filesize, status, duration, extension, error } = source || {};
  const hasError = Boolean(error);
  const isVideo = videoFormats.includes(extension);

  return Object.entries({
    'Source ID': id,
    Status: <StatusBadge status={status || 'loading'} />,
    URL: !hasError && url && (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
        <IconExternal />
      </a>
    ),
    Width: !hasError && !!width && `${width}px`,
    Height: !hasError && !!height && `${height}px`,
    FPS: !hasError && isVideo ? fps : null,
    Format: !hasError && extension,
    'File Size': !hasError && `${formatFileSize(filesize, 'value')}${formatFileSize(filesize, 'suffix')}`,
    Duration: !hasError && isVideo ? `${duration}s` : null,
    Created: dayjs(created).format('DD MMM YYYY HH:mm:ss.SSS A'),
  }).filter(([, value]) => value);
};

function SourceDetailsSource({ source, url, loaded }) {
  const [sourceMetadata, setSourceMetadata] = useState([]);
  const { error, extension } = source || {};

  useEffect(() => {
    setSourceMetadata(getSourceMetadata({ ...source, url }));
  }, [source, url]);

  if (!loaded) {
    return (
      <div className="mt-4">
        <Skeleton className="item-skeleton" />
      </div>
    );
  }

  return (
    <Content heading="MEDIA" className="asset-listing mt-4">
      <Card>
        <Card.Body>
          {error && <AlertBox type="error" message={error} className="mb-3" />}
          <Row>
            <Col xs={12} md={6}>
              <AssetDetailsPreview url={url} format={extension} showPlaceholder={!url} />
            </Col>

            <Col xs={12} md={6}>
              {sourceMetadata.length === 0 && <AlertBox type="info" message="No metadata available" className="mb-3" />}
              {sourceMetadata.length > 0 && (
                <table className="metadata">
                  <tbody>
                    {sourceMetadata.map(([label, value]) => (
                      <tr key={label}>
                        <td className="metadata__key">{label}</td>
                        <td className="metadata__value">{value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Content>
  );
}

export default SourceDetailsSource;
