import { useRecoilState, useRecoilValue } from 'recoil';

import InputCheckbox from '@components/controls/InputCheckbox';

import { cacheAtom } from '@store/Edit';
import { templateIdAtom } from '@store/Template';

function SettingCache() {
  const [cache, setCache] = useRecoilState(cacheAtom);

  const templateId = useRecoilValue(templateIdAtom);

  const tracking = {
    name: 'Check Caching',
    properties: {
      Id: templateId,
    },
  };

  const handleCacheChange = (event) => {
    setCache(event.target.checked);
  };

  return (
    <div>
      <h6>Caching</h6>

      <InputCheckbox label="Cache Assets" checked={cache} handleChange={handleCacheChange} tracking={tracking} />
    </div>
  );
}

export default SettingCache;
