import { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { IconTransparent } from '@assets/icons';

import InputRange from '@components/controls/InputRange';
import OverrideMenu from '@components/mergeFields/OverrideMenu';

import { clipSettingsSelectorFamily } from '@store/EditSelectors';
import { clipKeyframesPropertiesEnabledSelectorFamily } from '@store/Keyframes';

function VisualEffectsFields({ id }) {
  const [clip, setClip] = useRecoilState(clipSettingsSelectorFamily(id));
  const propertiesKeyframed = useRecoilValue(clipKeyframesPropertiesEnabledSelectorFamily(id));

  const handleOpacityChange = (event) => {
    const { value } = event.target;

    if (value.length > 0 && value >= 0 && value <= 1) {
      setClip({ opacity: parseFloat(value) });
    } else {
      setClip({ opacity: undefined });
    }
  };

  const isOpacityAnimated = useMemo(() => propertiesKeyframed.includes('opacity'), [propertiesKeyframed]);

  return (
    <div className="d-flex align-items-center justify-content-between gap-2 mb-4">
      <InputRange
        id="opacity"
        title={`Opacity${isOpacityAnimated ? ' (Animated)' : ''}`}
        value={clip?.opacity >= 0 ? clip?.opacity : 1}
        placeholder="1"
        disabled={isOpacityAnimated}
        min={0}
        max={1}
        step={0.05}
        prefix={<IconTransparent size={18} />}
        handleChange={handleOpacityChange}
      />
      <OverrideMenu path="opacity" isAnimated={isOpacityAnimated} />
    </div>
  );
}

export default VisualEffectsFields;
