const SCOTT_KO_VIDEO_URL = 'https://github.com/shotstack/test-media/raw/main/captioning/scott-ko.mp4';

export const heyGenTextToAvatarData = {
  provider: 'heygen',
  options: {
    type: 'text-to-avatar',
    avatar: 'Angela',
    voice: 'Abbi - Natural',
    text: 'This is an avatar video asset generated from heygen by shotstack',
  },
};

export const sendUrlToMux = {
  url: SCOTT_KO_VIDEO_URL,
  id: 'YOUR_UNIQUE_ID_HERE',
  destinations: [
    {
      provider: 'mux',
      options: {
        playbackPolicy: ['public'],
      },
    },
  ],
};

export const sendUrlToS3 = {
  url: SCOTT_KO_VIDEO_URL,
  id: 'YOUR_UNIQUE_ID_HERE',
  destinations: [
    {
      provider: 's3',
      options: {
        region: 'YOUR_REGION_HERE',
        bucket: 'YOUR_BUCKET_HERE',
        prefix: 'YOUR_PREFIX_HERE',
        filename: 'YOUR_FILENAME_HERE',
        acl: 'YOUR_ACL_HERE',
      },
    },
  ],
};

export const sendUrlToGCP = {
  url: SCOTT_KO_VIDEO_URL,
  id: 'YOUR_UNIQUE_ID_HERE',
  destinations: [
    {
      provider: 'google-cloud-storage',
      options: {
        bucket: 'YOUR_BUCKET_HERE',
        prefix: 'YOUR_PREFIX_HERE',
        filename: 'YOUR_FILENAME_HERE',
      },
    },
  ],
};

export const sendUrlToShotstack = {
  url: SCOTT_KO_VIDEO_URL,
  id: 'YOUR_UNIQUE_ID_HERE',
  destinations: [
    {
      provider: 'shotstack',
    },
  ],
};

export const sendUrlToVimeo = {
  url: SCOTT_KO_VIDEO_URL,
  id: 'YOUR_UNIQUE_ID_HERE',
  destinations: [
    {
      provider: 'vimeo',
      options: {
        name: 'My Automated Video',
        description: 'This video was uploaded via Shotstack',
        permissions: {
          view: 'anybody',
          embed: 'public',
          comments: 'anybody',
        },
      },
    },
  ],
};

export const sendUrlToGoogleDrive = {
  url: SCOTT_KO_VIDEO_URL,
  id: 'YOUR_UNIQUE_ID_HERE',
  destinations: [
    {
      provider: 'google-drive',
      options: {
        filename: 'my-video',
        folderId: '2t-eTY12LO8tzQLAwMug-fIrK_7AQBI6B',
      },
    },
  ],
};

export const sendUrlToTikTok = {
  url: SCOTT_KO_VIDEO_URL,
  id: 'YOUR_UNIQUE_ID_HERE',
  destinations: [
    {
      provider: 'tiktok',
    },
  ],
};
