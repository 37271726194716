import { memo } from 'react';

import { IconMagicWand, IconVoiceChat } from '@assets/icons';

import TimelineClipInfo from '@feature/studio/timeline/TimelineClipInfo';

function TimelineClipTextToSpeech({ id }) {
  return (
    <div className="clip__inner">
      <div className="clip__header">
        <span className="clip__icon">
          <IconMagicWand size={14} />
        </span>
        <IconVoiceChat size={14} />
        <TimelineClipInfo id={id} />
      </div>
    </div>
  );
}

export default memo(TimelineClipTextToSpeech);
