import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';

import WorkflowRecipeGallery from '@feature/workflows/gallery/WorkflowRecipeGallery';
import WorkflowRecipeGalleryDetail from '@feature/workflows/gallery/WorkflowRecipeGalleryDetail';

function WorkflowRecipeGalleryModal({ baseUrl }) {
  const history = useNavigate();
  const [searchParams] = useSearchParams();
  const browse = searchParams.get('browse');
  const workflowId = searchParams.get('workflowId');
  const [show, setShow] = useState(false);

  useEffect(() => setShow(browse === 'open'), [browse]);

  const handleHide = () => {
    setShow(false);
  };

  const handleExited = () => {
    history(baseUrl);
  };

  return (
    <Modal
      className="template-gallery-modal"
      show={show}
      onHide={handleHide}
      onExited={handleExited}
      size="xl"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <div className="uppercase-heading">
          <strong>{workflowId ? 'Preview Workflow' : 'Browse Workflows'}</strong>
        </div>
      </Modal.Header>
      <Modal.Body>
        {workflowId ? (
          <WorkflowRecipeGalleryDetail workflowId={workflowId} />
        ) : (
          <WorkflowRecipeGallery randomize={false} responsive />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default WorkflowRecipeGalleryModal;
