import { Card } from 'react-bootstrap';

import AlertBox from '@components/alert/AlertBox';
import AssetDetailsPreview from '@components/asset/AssetDetailsPreview';
import StatusBadge from '@components/status/StatusBadge';

import { videoFormats } from '@constants/Formats';

const getRenditionDetails = (output) => {
  const { id, duration, fps, height, status, width, url, transformation } = output || {};
  const { format } = transformation || {};
  const isReady = status === 'ready';
  const isVideo = videoFormats.includes(format);

  const details = Object.entries({
    'Rendition ID': id,
    Status: <StatusBadge status={status || 'loading'} />,
    URL: isReady && url && (
      <a href={url} title={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    ),
    Width: isReady && !!width && `${width}px`,
    Height: isReady && !!height && `${height}px`,
    Duration: isReady && duration > 0 ? `${duration}s` : null,
    Format: isReady && format,
    FPS: isReady && isVideo ? fps : null,
  }).filter(([, value]) => value);

  return details;
};

const getTranscriptionDetails = (output) => {
  const { status, url } = output || {};
  const isReady = status === 'ready';

  const details = Object.entries({
    Status: <StatusBadge status={status || 'loading'} />,
    URL: isReady && url && (
      <a href={url} title={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    ),
  }).filter(([, value]) => value);

  return details;
};

const outputDetailsMap = {
  renditions: getRenditionDetails,
  transcription: getTranscriptionDetails,
  default: () => [],
};

const getOutputDetails = (output) => {
  const { type } = output || {};
  const detailsFn = outputDetailsMap[type] || outputDetailsMap.default;
  return detailsFn(output);
};

function SourceDetailsAsset({ output }) {
  const { url, error } = output || {};
  const outputDetails = getOutputDetails(output);

  return (
    <Card>
      <Card.Body>
        {error && <AlertBox type="error" message={error} className="mb-3" />}
        <AssetDetailsPreview url={url} />
        <table className="metadata mb-4">
          <tbody>
            {outputDetails.map(([key, value]) => (
              <tr key={key}>
                <td className="metadata__key">{key}</td>
                <td className="metadata__value">{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card.Body>
    </Card>
  );
}

export default SourceDetailsAsset;
