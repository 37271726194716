const editApi = {
  done: 'Done',
  failed: 'Failed',
  rendering: 'Rendering',
  queued: 'Queued',
  fetching: 'Fetching',
  saving: 'Saving',
};

const ingestApi = {
  queued: 'Queued',
  importing: 'Importing',
  ready: 'Ready',
  failed: 'Failed',
  deleted: 'Deleted',
};

const serveApi = {
  waiting: 'Waiting',
  queued: 'Queued',
  processing: 'Processing',
  ready: 'Ready',
  failed: 'Failed',
  deleted: 'Deleted',
  overwritte: 'Overwritten',
};

const createApi = {
  queued: 'Queued',
  processing: 'Processing',
  saving: 'Saving',
  done: 'Done',
  failed: 'Failed',
};

const workflowJobs = {
  queued: 'Queued',
  processing: 'Processing',
  saving: 'Saving',
  done: 'Done',
  failed: 'Failed',
};

export default {
  'edit-api': editApi,
  'ingest-api': ingestApi,
  'serve-api': serveApi,
  'create-api': createApi,
  'workflow-jobs': workflowJobs,
};
