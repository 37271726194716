import { BOUNDING_BOX_HEIGHT_PIXELS, BOUNDING_BOX_WIDTH_PIXELS } from '@constants/TextAssetDefaults';

import get720pResolutionDynamic from '@utils/get720pResolutionDynamic';

const getAssetDimensions = ({ clip, canvas, resource }) => {
  switch (clip['asset:type']) {
    case 'image-to-video': {
      const { width, height } = get720pResolutionDynamic(clip['asset:aspectRatio']);
      return {
        assetWidth: width,
        assetHeight: height,
      };
    }
    case 'image': {
      return {
        assetWidth: clip['asset:meta']?.width || resource?.width,
        assetHeight: clip['asset:meta']?.height || resource?.height,
      };
    }

    case 'overlay':
    case 'video': {
      const videoResource = resource?.baseTexture?.resource?.source;
      return {
        assetWidth: clip['asset:meta']?.width || videoResource.videoWidth,
        assetHeight: clip['asset:meta']?.height || videoResource.videoHeight,
      };
    }
    case 'shape': {
      return {
        assetWidth: clip['asset:width'] ?? canvas.width,
        assetHeight: clip['asset:height'] ?? canvas.height,
      };
    }
    case 'caption': {
      return {
        assetWidth: undefined,
        assetHeight: undefined,
      };
    }
    default: {
      return {
        assetWidth: clip['asset:width'] || BOUNDING_BOX_WIDTH_PIXELS,
        assetHeight: clip['asset:height'] || BOUNDING_BOX_HEIGHT_PIXELS,
      };
    }
  }
};

export default getAssetDimensions;
