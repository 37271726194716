import { atom, selectorFamily } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { FEATURE_FLAGS } from '@constants/FeatureFlags';

const { persistAtom } = recoilPersist();

export const errorAtom = atom({
  key: 'errorAtom',
  default: {
    type: 'error',
    title: '',
    message: '',
  },
});

export const studioActiveTabAtom = atom({
  key: 'studioActiveTabAtom',
  default: null,
});

export const studioActiveTabHoverAtom = atom({
  key: 'studioActiveTabHoverAtom',
  default: null,
});

export const showTemplateConfigurationAtom = atom({
  key: 'showTemplateConfigurationAtom',
  default: false,
});

export const dismissibleAtom = atom({
  key: 'dismissible/atom',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const featureFlagsAtom = atom({
  key: 'featureFlagsAtom',
  default: FEATURE_FLAGS,
});

export const featureFlagEnabledSelectorFamily = selectorFamily({
  key: 'features',
  get:
    (flag) =>
    ({ get }) => {
      const features = get(featureFlagsAtom);
      return features[flag] === true;
    },
});

export const dismissibleSelector = selectorFamily({
  key: 'dismissible/selector',
  get:
    (id) =>
    ({ get }) =>
      get(dismissibleAtom).includes(id),
  set:
    (id) =>
    ({ set }) => {
      set(dismissibleAtom, (prevItems) =>
        prevItems.includes(id) ? prevItems.filter((item) => item !== id) : [...prevItems, id]
      );
    },
});
