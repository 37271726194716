export default [
  {
    value: 'Hala',
    text: 'Hala (Arabic, Gulf)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Hala.mp3',
  },
  {
    value: 'Lisa',
    text: 'Lisa (Dutch, Belgian)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Lisa.mp3',
  },
  {
    value: 'Arlet',
    text: 'Arlet (Catalan)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Arlet.mp3',
  },
  {
    value: 'Hiujin',
    text: 'Hiujin (Chinese, Cantonese)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Hiujin.mp3',
  },
  {
    value: 'Zhiyu',
    text: 'Zhiyu (Chinese, Mandarin)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Zhiyu.mp3',
  },
  {
    value: 'Sofie',
    text: 'Sofie (Danish)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Sofie.mp3',
  },
  {
    value: 'Laura',
    text: 'Laura (Dutch)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Laura.mp3',
  },
  {
    value: 'Olivia',
    text: 'Olivia (English, Australian)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Olivia.mp3',
  },
  {
    value: 'Amy',
    text: 'Amy (English, British)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Amy.mp3',
  },
  {
    value: 'Emma',
    text: 'Emma (English, British)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Emma.mp3',
  },
  {
    value: 'Brian',
    text: 'Brian (English, British)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Brian.mp3',
  },
  {
    value: 'Arthur',
    text: 'Arthur (English, British)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Arthur.mp3',
  },
  {
    value: 'Kajal',
    text: 'Kajal (English, Indian)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Kajal.mp3',
  },
  {
    value: 'Niamh',
    text: 'Niamh (English, Ireland)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Niamh.mp3',
  },
  {
    value: 'Aria',
    text: 'Aria (English, New Zealand)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Aria.mp3',
  },
  {
    value: 'Ayanda',
    text: 'Ayanda (English, South African)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Ayanda.mp3',
  },
  {
    value: 'Ivy',
    text: 'Ivy (English, US)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Ivy.mp3',
  },
  {
    value: 'Joanna',
    text: 'Joanna (English, US)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Joanna.mp3',
  },
  {
    value: 'Kendra',
    text: 'Kendra (English, US)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Kendra.mp3',
  },
  {
    value: 'Kimberly',
    text: 'Kimberly (English, US)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Kimberly.mp3',
  },
  {
    value: 'Salli',
    text: 'Salli (English, US)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Salli.mp3',
  },
  {
    value: 'Joey',
    text: 'Joey (English, US)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Joey.mp3',
  },
  {
    value: 'Justin',
    text: 'Justin (English, US)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Justin.mp3',
  },
  {
    value: 'Kevin',
    text: 'Kevin (English, US)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Kevin.mp3',
  },
  {
    value: 'Matthew',
    text: 'Matthew (English, US)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Matthew.mp3',
  },
  {
    value: 'Ruth',
    text: 'Ruth (English, US)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Ruth.mp3',
  },
  {
    value: 'Stephen',
    text: 'Stephen (English, US)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Stephen.mp3',
  },
  {
    value: 'Suvi',
    text: 'Suvi (Finnish)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Suvi.mp3',
  },
  { value: 'Léa', text: 'Léa (French)', sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Lea.mp3' },
  {
    value: 'Rémi',
    text: 'Rémi (French)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Remi.mp3',
  },
  {
    value: 'Gabrielle',
    text: 'Gabrielle (French, Canadian)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Gabrielle.mp3',
  },
  {
    value: 'Liam',
    text: 'Liam (French, Canadian)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Liam.mp3',
  },
  {
    value: 'Vicki',
    text: 'Vicki (German)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Vicki.mp3',
  },
  {
    value: 'Daniel',
    text: 'Daniel (German)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Daniel.mp3',
  },
  {
    value: 'Hannah',
    text: 'Hannah (German, Austrian)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Hannah.mp3',
  },
  {
    value: 'Bianca',
    text: 'Bianca (Italian)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Bianca.mp3',
  },
  {
    value: 'Adriano',
    text: 'Adriano (Italian)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Adriano.mp3',
  },
  {
    value: 'Takumi',
    text: 'Takumi (Japanese)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Takumi.mp3',
  },
  {
    value: 'Kazuha',
    text: 'Kazuha (Japanese)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Kazuha.mp3',
  },
  {
    value: 'Tomoko',
    text: 'Tomoko (Japanese)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Tomoko.mp3',
  },
  {
    value: 'Seoyeon',
    text: 'Seoyeon (Korean)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Seoyeon.mp3',
  },
  {
    value: 'Ida',
    text: 'Ida (Norwegian)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Ida.mp3',
  },
  { value: 'Ola', text: 'Ola (Polish)', sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Ola.mp3' },
  {
    value: 'Camila',
    text: 'Camila (Portuguese, Brazilian)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Camila.mp3',
  },
  {
    value: 'Vitoria',
    text: 'Vitória/Vitoria (Portuguese, Brazilian)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Vittoria.mp3',
  },
  {
    value: 'Thiago',
    text: 'Thiago (Portuguese, Brazilian)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Thiago.mp3',
  },
  {
    value: 'Inês',
    text: 'Inês/Ines (Portuguese, European)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Ines.mp3',
  },
  {
    value: 'Lucia',
    text: 'Lucia (Spanish, European)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Lucia.mp3',
  },
  {
    value: 'Sergio',
    text: 'Sergio (Spanish, European)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Sergio.mp3',
  },
  {
    value: 'Mia',
    text: 'Mia (Spanish, Mexican)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Mia.mp3',
  },
  {
    value: 'Andrés',
    text: 'Andrés (Spanish, Mexican)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Andres.mp3',
  },
  {
    value: 'Lupe',
    text: 'Lupe (Spanish, US)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Lupe.mp3',
  },
  {
    value: 'Pedro',
    text: 'Pedro (Spanish, US)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Pedro.mp3',
  },
  {
    value: 'Elin',
    text: 'Elin (Swedish)',
    sample: 'https://shotstack-video-hosting.s3.amazonaws.com/voiceovers/Elin.mp3',
  },
];
