import config from '@config';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import { Navigate, Routes as RDRoutes, Route } from 'react-router-dom';

import PrivateRoute from '@src/PrivateRoute';

import ApiPlayground from '@pages/ApiPlayground';
import Billing from '@pages/Billing';
import Dashboard from '@pages/Dashboard';
import Editor from '@pages/Editor';
import EditorSdk from '@pages/EditorSdk';
import Error from '@pages/Error';
import GettingStarted from '@pages/GettingStarted';
import Integration from '@pages/Integration';
import Integrations from '@pages/Integrations';
import Keys from '@pages/Keys';
import NotFound from '@pages/NotFound';
import Onboarding from '@pages/Onboarding';
import Overview from '@pages/Overview/Overview';
import Register from '@pages/Register';
import ResourceDetail from '@pages/Resource/ResourceDetail';
import ResourceList from '@pages/Resource/ResourceList';
import StudioSdk from '@pages/StudioSdk';
import Subscribe from '@pages/Subscribe';
import Subscription from '@pages/Subscription';
import TemplatesGallery from '@pages/TemplatesGallery';
import TemplatesList from '@pages/TemplatesList';
import VideoToMp3 from '@pages/Tools/VideoToMp3';
import Usage from '@pages/Usage';
import WorkflowDetail from '@pages/WorkflowDetail';
import WorkflowEdit from '@pages/WorkflowEdit';
import WorkflowJobDetail from '@pages/WorkflowJobDetail';
import WorkflowList from '@pages/WorkflowList';
import WorkflowRecipes from '@pages/WorkflowRecipes';

const SentryRoutes = withSentryReactRouterV6Routing(RDRoutes);

const privateRoutes = [
  { props: { path: '/', exact: true }, Component: Dashboard },
  { props: { path: '/getting-started' }, Component: GettingStarted },
  { props: { path: '/keys' }, Component: Keys },
  { props: { path: '/payment-details' }, Component: Billing },
  { props: { path: '/studio' }, Component: Overview },
  { props: { path: '/studio/overview' }, Component: () => <Navigate to="/studio" replace /> },
  { props: { path: '/studio/my-templates' }, Component: TemplatesList },
  { props: { path: '/studio/templates' }, Component: TemplatesGallery },
  { props: { path: '/studio/sdk' }, Component: StudioSdk },
  { props: { path: '/studio/editor' }, Component: Editor },
  { props: { path: '/studio/editor/:id' }, Component: Editor },
  { props: { path: '/studio/editor/:id/:page' }, Component: Editor },
  { props: { path: '/subscription' }, Component: Subscription },
  { props: { path: '/subscribe/:code' }, Component: Subscribe },
  { props: { path: '/usage' }, Component: Usage },
  { props: { path: '/integrations' }, Component: Integrations },
  { props: { path: '/integrations/:provider' }, Component: Integration },
  { props: { path: '/integrations/:provider/:stage' }, Component: Integration },
  { props: { path: '/workflows' }, Component: Overview },
  { props: { path: '/workflows/overview' }, Component: () => <Navigate to="/workflows" replace /> },
  { props: { path: '/workflows/my-workflows' }, Component: WorkflowList },
  { props: { path: '/workflows/templates' }, Component: WorkflowRecipes },
  { props: { path: '/workflows/:id' }, Component: WorkflowDetail },
  { props: { path: '/workflows/:id/edit' }, Component: WorkflowEdit },
  { props: { path: '/workflows/:id/jobs/:jobId' }, Component: WorkflowJobDetail },
  { props: { path: '/onboarding' }, Component: Onboarding },
  { props: { path: '/apis' }, Component: () => <Navigate to="/apis/edit-api/overview" replace /> },
  { props: { path: '/apis/:product/overview' }, Component: Overview },
  { props: { path: '/apis/:product/assets' }, Component: ResourceList },
  { props: { path: '/apis/:product/assets/:id' }, Component: ResourceDetail },
  { props: { path: '/apis/playground/:product' }, Component: ApiPlayground },
  { props: { path: '/utilities/video-to-mp3' }, Component: VideoToMp3 },
];

export const Routes = () => {
  return (
    <SentryRoutes>
      <Route path="/register" element={<Register />} />
      <Route path="/error" element={<Error />} />
      <Route path="/not-found" element={<NotFound />} />

      {privateRoutes.map(({ props, Component }) => (
        <Route
          key={props.path}
          {...props}
          element={
            <PrivateRoute>
              <Component />
            </PrivateRoute>
          }
        />
      ))}
      <Route path="*" element={<Navigate to="/not-found" replace />} />
    </SentryRoutes>
  );
};

export const SdkRoute = () => {
  return (
    <SentryRoutes>
      <Route path={`/${config.sdkVersion}`} element={<EditorSdk />} />
      <Route path="*" element={<div />} />
    </SentryRoutes>
  );
};
