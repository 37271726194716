import { useRef, useState } from 'react';
import { ButtonGroup, Overlay, Popover } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';

import ButtonElement from '@components/atoms/ButtonElement';
import PreviewQualityFields from '@components/preview/PreviewQualityFields';
import PreviewRangeFields from '@components/preview/PreviewRangeFields';

import { isValidJsonAtom } from '@store/Edit';
import { renderSubmittedAtom } from '@store/Renders';
import { templateIdAtom, templateJsonEditAtom, templateNameAtom } from '@store/Template';

import useRender from '@hooks/useRender';

function PreviewSubmit() {
  const documentRef = useRef(document);
  const { submitRender } = useRender();
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const isValid = useRecoilValue(isValidJsonAtom);
  const renderSubmitted = useRecoilValue(renderSubmittedAtom);
  const templateId = useRecoilValue(templateIdAtom);
  const templateName = useRecoilValue(templateNameAtom);
  const templateJsonReady = useRecoilValue(templateJsonEditAtom);

  const tracking = {
    properties: {
      Id: templateId,
      Title: templateName,
    },
  };

  const handleClick = (event) => {
    setShow(!show);
    setTarget(show ? null : event.target);
  };

  const handleRender = () => {
    submitRender();
  };

  return (
    <>
      <ButtonGroup>
        <ButtonElement
          handleEvent={handleRender}
          variant="primary"
          type="submit"
          disabled={renderSubmitted || !isValid || !templateJsonReady}
          tracking={{ ...tracking, name: 'Select Preview Render' }}
          data-tour="render"
        >
          Preview
        </ButtonElement>

        <ButtonElement
          title="Preview Settings"
          handleEvent={handleClick}
          className="btn-arrow"
          tracking={{ ...tracking, name: 'Select Preview Settings Panel' }}
          disabled={renderSubmitted || !isValid || !templateJsonReady}
          variant="primary"
          type="button"
        />
      </ButtonGroup>

      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={documentRef.current?.body}
        containerPadding={20}
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover className="popover-controls popover-preview-settings">
          <Popover.Body>
            <div className="mb-3">
              <p className="mb-0">
                <strong>RENDER</strong>
              </p>
              <small className="d-block mb-3">
                Use <strong>FAST</strong> to quickly render a preview using a reduced resolution and frame rate. Use{' '}
                <strong>NORMAL</strong> to render a preview using the original resolution and frame rate.
              </small>
              <PreviewQualityFields />
            </div>
            <div>
              <p className="mb-0">
                <strong>RANGE</strong>
              </p>
              <small className="d-block mb-3">
                Specify the start and length in seconds to render a section of the video
              </small>
              <PreviewRangeFields />
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
}

export default PreviewSubmit;
