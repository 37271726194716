import cn from 'classnames';
import dayjs from 'dayjs';
import { Badge } from 'react-bootstrap';

import { IconWorkflow } from '@assets/icons';

import Item from '@components/listing/Item';
import StatusBadge from '@components/status/StatusBadge';

function ResourceListItemWorkflowJob({ item, onItemClick }) {
  const { id, status, created } = item || {};

  return (
    <Item className="list-item">
      <Item.Header style={{ cursor: 'pointer' }} onClick={() => onItemClick(id)}>
        <Item.HeaderLeft>
          <div className="list-item__icon">
            <IconWorkflow />
            <div
              className={cn('list-item__indicator', {
                [status]: true,
              })}
            />
          </div>
        </Item.HeaderLeft>
        <Item.HeaderMiddle>
          <div>
            <Badge className="bg-subtle">ID: {id}</Badge>
          </div>
          <div>
            {' — '}
            <StatusBadge status={status} />
          </div>
        </Item.HeaderMiddle>
        <Item.HeaderRight>
          <div className="list-item__text">
            <strong>Created:</strong> {dayjs(created).format('DD MMM YYYY HH:mm:ss.SSS A')} <br />
          </div>
        </Item.HeaderRight>
      </Item.Header>
    </Item>
  );
}

export default ResourceListItemWorkflowJob;
