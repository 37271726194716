function getCleanUrl(originalUrl) {
  try {
    const url = new URL(originalUrl);
    url.search = '';
    url.hash = '';
    return url.toString();
  } catch (e) {
    return null;
  }
}

export default getCleanUrl;
