class AbortControllerManager {
  constructor() {
    this.controllers = new Map();
  }

  getController(id) {
    if (!this.controllers.has(id)) {
      this.controllers.set(id, new AbortController());
    }
    return this.controllers.get(id);
  }

  abort(id) {
    if (this.controllers.has(id)) {
      const controller = this.controllers.get(id);
      controller.abort();
      this.controllers.delete(id);
    }
  }

  abortAll() {
    for (let controller of this.controllers.values()) {
      controller.abort();
    }
    this.controllers.clear();
  }
}

export const controllerManager = new AbortControllerManager();
