const MAX_IMAGE_WIDTH = 200;

function generateThumbnail({ src }) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // CORS

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      // Calculate the new dimensions while maintaining the aspect ratio
      const aspectRatio = img.width / img.height;
      const newWidth = Math.min(img.width, MAX_IMAGE_WIDTH);
      const newHeight = newWidth / aspectRatio;

      // Set canvas dimensions
      canvas.width = newWidth;
      canvas.height = newHeight;

      // Draw the image onto the canvas
      context.drawImage(img, 0, 0, newWidth, newHeight);

      // Get the thumbnail data URL
      let thumbnailUrl;
      try {
        thumbnailUrl = canvas.toDataURL('image/jpg');
      } catch (error) {
        reject(new Error('generateThumbnail: Failed to export canvas to data URL.'));
        return;
      }

      // Destroy the image element
      img.src = '';
      img.remove();

      resolve({
        width: newWidth,
        height: newHeight,
        url: thumbnailUrl,
      });
    };

    img.onerror = () => {
      // Destroy the image element
      img.src = '';
      img.remove();

      reject(new Error('generateThumbnail: There was an error loading the image.'));
    };

    img.src = src;
  });
}

export default generateThumbnail;
