import config from '@config';
import { Col, Row } from 'react-bootstrap';

import StabilityAiForm from '@feature/integrations/providers/stabilityai/StabilityAiForm';

import AlphaBadge from '@components/badge/alpha';
import ContentCard from '@components/cards/ContentCard';
import Header from '@components/header/Header';

function StabilityAi() {
  const Forms = config.integration.stages.map((stage, index) => (
    <StabilityAiForm key={stage.stage} stage={stage.stage} stageName={stage.name} index={index} />
  ));

  return (
    <div className="integration">
      <Header title="StabilityAI Text-to-Image">
        Generate images from text using the Stability AI Text-to-Image API.
      </Header>
      <Row className="mb-4">
        <Col md={4}>
          <ContentCard image={`/img/provider/stabilityai.png`}>
            <AlphaBadge />
            Stability AI provides you with text-to-image functionality. Bring an extra dimension to your videos or
            applications with dynamic images.
            <br /> <br />
            *Note that this integration is currently on alpha and is subject to change.
          </ContentCard>
        </Col>
        <Col md={8}>
          <ContentCard heading="SETUP">
            <p>
              Sign up to <a href="https://platform.stability.ai/">Stability AI</a> if you do not already have an
              account. Copy your API Key and paste it in the form below.
            </p>
          </ContentCard>
          <ContentCard heading="CREDENTIALS">{Forms}</ContentCard>
          <ContentCard heading="INTEGRATION">
            <p>
              Send the following JSON payload containing your prompt in the body of the request to generate an asset:
            </p>
            <pre>
              <b>POST</b> https://api.shotstack.io/create/&#123;ENV&#125;/assets
            </pre>
            <p>
              Replace <code>&#123;ENV&#125;</code> with either stage or v1 in the URL above depending on the environment
              and API key you are using.
            </p>
            <p>Send the following JSON payload in the body of the request to generate an asset:</p>
            <pre>{`{
    "provider": "stability-ai",
    "options": {
        "type": "text-to-image",
        "prompt": "A realistic photo of the planet Mars with a black outer space background",
    }
}
`}</pre>
            <p>
              Check the <a href="https://shotstack.io/docs/api/">API docs</a> for more information.
            </p>
          </ContentCard>
        </Col>
      </Row>
    </div>
  );
}

export default StabilityAi;
