import { useState } from 'react';
import { useRecoilRefresher_UNSTABLE, useResetRecoilState, useSetRecoilState } from 'recoil';

import { deleteTemplate } from '@api/services/template';

import { allTemplatesSelector } from '@store/Template';
import { errorAtom } from '@store/UI';

const useDeleteTemplate = ({ templateId, onSuccess } = {}) => {
  const [loading, setLoading] = useState(false);
  const setErrorMessage = useSetRecoilState(errorAtom);
  const resetErrorMessage = useResetRecoilState(errorAtom);
  const refreshAllTemplateState = useRecoilRefresher_UNSTABLE(allTemplatesSelector);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteTemplate(templateId);
      refreshAllTemplateState();
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      setErrorMessage({ message: error.message });
      setTimeout(() => {
        resetErrorMessage();
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  return { loading, handleDelete };
};

export default useDeleteTemplate;
