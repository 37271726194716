import {
  transformClipAutoProperties,
  transformClipEndProperties,
  transformClipIncoming,
} from '@api/transform/utils/clips';

import { flattenToColonSeperatedProperties } from '@utils/properties';

const getTimelineDuration = async (tracks) => {
  const outPoints = tracks
    .map(({ clips }) => clips)
    .flat()
    .map(({ start, length }) => (typeof start === 'number' && typeof length === 'number' ? start + length : null))
    .filter((val) => !Number.isNaN(val));

  const duration = Math.max.apply(null, outPoints);

  if (!Number.isFinite(duration)) {
    return 0.0;
  }

  return parseFloat(duration);
};

export const transformTracks = (mergeData) => async (tracks) => {
  const tracksWithAssetMeta = await Promise.all(
    (tracks || []).map(async ({ clips: trackClips }) => {
      const clips = await Promise.all((trackClips || []).map((clip) => transformClipIncoming(clip, mergeData)));
      return { clips };
    })
  );

  const tracksAutoUpdated = (tracksWithAssetMeta || []).map(({ clips: trackClips }) => {
    const clips = (trackClips || []).map((clip, index) => transformClipAutoProperties(clip, trackClips, index));
    return { clips };
  });

  const timelineDuration = await getTimelineDuration(tracksAutoUpdated);

  const tracksEndUpdated = (tracksAutoUpdated || []).map(({ clips: trackClips }) => {
    const clips = (trackClips || []).map((clip) => transformClipEndProperties(clip, timelineDuration));
    return { clips };
  });

  const flattened = tracksEndUpdated.map(({ clips }) => ({
    clips: clips.map((clip) => flattenToColonSeperatedProperties(clip)),
  }));

  return flattened;
};
