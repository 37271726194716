import { debounce } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import '@assets/css/PanelMediaListing.css';

import MediaListingPreview from '@components/asset/MediaListingPreview';

import {
  mediaLoadingAtom,
  mediaMetaAtom,
  mediaSelectorFamily,
  useAddClipToTimelineCallback,
  useDeleteMediaCallback,
  useFetchMediaCallback,
} from '@store/Media';

function PanelMediaListing({ type }) {
  const mediaList = useRecoilValue(mediaSelectorFamily(type));
  const mediaMeta = useRecoilValue(mediaMetaAtom);
  const mediaLoading = useRecoilValue(mediaLoadingAtom);
  const fetchMedia = useFetchMediaCallback();
  const addClipToTimeline = useAddClipToTimelineCallback();
  const deleteMedia = useDeleteMediaCallback();
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [activeTab, setActiveTab] = useState('image');
  const endRef = useRef(null);

  const observerCallback = useCallback(
    debounce((entries) => {
      const [entry] = entries;
      if (entry?.isIntersecting && !mediaLoading && mediaMeta.next !== null) {
        fetchMedia(mediaMeta);
      }
    }, 500),
    [mediaLoading, fetchMedia, mediaMeta]
  );

  const fetchMediaButton = () => {
    if (mediaMeta.next !== null) {
      fetchMedia(mediaMeta);
    }
  };

  const handleOnSelect = ({ id, selected }) => {
    const assets = type === 'media' ? mediaList?.assets?.[activeTab] : mediaList?.assets;
    const media = assets?.find((m) => m.id === id);
    const mediaIds = [media?.id, media?.proxy?.id].filter(Boolean);

    if (selected) {
      setSelectedMedia((prev) => [...prev, ...mediaIds]);
    } else {
      setSelectedMedia((prev) => prev.filter((i) => !mediaIds.includes(i)));
    }
  };

  const handleDeleteSelected = () => {
    deleteMedia(selectedMedia);
    setSelectedMedia([]);
  };

  const handleOnClick = (media) => {
    addClipToTimeline({ data: media, type: media.type });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, { threshold: 1 });
    if (endRef.current) observer.observe(endRef.current);

    return () => {
      if (endRef.current) observer.unobserve(endRef.current);
    };
  }, [observerCallback]);

  const assets = type === 'media' ? mediaList?.assets?.[activeTab] : mediaList?.assets || [];

  return (
    <div className="media-listing">
      <div className="d-flex justify-content-between align-items-center mb-4">
        {type === 'media' ? (
          <ul className="nav nav-underline">
            {[
              ['image', 'Images'],
              ['video', 'Videos'],
              ['audio', 'Audio'],
            ].map(([tabKey, label]) => (
              <li className="nav-item" key={tabKey}>
                <button
                  className={`nav-link ${activeTab === tabKey ? 'active' : ''}`}
                  onClick={() => setActiveTab(tabKey)}
                >
                  {label}
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <h6 className="mb-0">Library</h6>
        )}
        <button onClick={handleDeleteSelected} className="btn btn-danger btn-sm" disabled={selectedMedia.length === 0}>
          Delete {selectedMedia.length !== 0 ? `${selectedMedia.length} Selected` : ''}
        </button>
      </div>

      <div className="media-listing__container">
        {assets?.map((media, idx) => (
          <MediaListingPreview key={media.id + idx} media={media} onClick={handleOnClick} onSelect={handleOnSelect} />
        ))}
      </div>

      {(!assets?.length || !mediaList?.length) && !mediaLoading && (
        <div className="text-center">
          <small>No Items</small>
        </div>
      )}

      {mediaLoading && (
        <div className="text-center">
          <small>Loading...</small>
        </div>
      )}

      <div ref={endRef} style={{ height: '20px' }} />

      {mediaMeta.next !== null && (
        <div className="text-center">
          <button onClick={() => fetchMediaButton()} className="btn btn-light mx-auto">
            Load more media
          </button>
        </div>
      )}
    </div>
  );
}

export default PanelMediaListing;
