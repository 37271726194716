import dataClient from '@api/clients/data';
import serveClient from '@api/clients/serve';
import { transformServeAssets } from '@api/transform/serve';

export const getServeAsset = async (id) => {
  const url = `assets/${id}`;
  const { data } = await dataClient.get(url);
  return data?.body?.data || {};
};

export const getServeAssets = async (filterParams) => {
  const url = `serve/assets`;
  const { data } = await dataClient.get(url, { params: filterParams });
  return transformServeAssets(data?.body);
};

export const deleteServeAsset = async (id) => {
  const url = `assets/${id}`;
  return serveClient.delete(url);
};

export default {
  get: getServeAsset,
  getAll: getServeAssets,
  delete: deleteServeAsset,
};
