import cn from 'classnames';

import '@css/TimelineTrack.css';

import TimelineTrackHeader from '@feature/studio/timeline/TimelineTrackHeader';

function TimelineTrack({ id, active, index, getRef }) {
  return (
    <div ref={getRef} className={cn('track', { active })} data-track-id={id} data-track-index={index}>
      <TimelineTrackHeader id={id} />
      <div className="track__subtrack" />
    </div>
  );
}

export default TimelineTrack;
