import { useEffect } from 'react';
import { Badge, Button, ButtonGroup, Card, Col, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useRecoilValueLoadable, useResetRecoilState } from 'recoil';

import { IconPencilSquare } from '@assets/icons';

import WorkflowCreateButton from '@feature/workflows/buttons/WorkflowCreateButton';
import WorkflowDeleteButton from '@feature/workflows/buttons/WorkflowDeleteButton';
import WorkflowDuplicateButton from '@feature/workflows/buttons/WorkflowDuplicateButton';
import WorkflowEditButton from '@feature/workflows/buttons/WorkflowEditButton';

import CheckboxElement from '@components/atoms/CheckboxElement';
import Item from '@components/listing/Item';
import Skeleton from '@components/skeletons/Skeleton';

import { allWorkflowsListSelector, bulkDeleteAtom, useToggleDeleteWorkflow } from '@store/Workflows';

function WorkflowListItems() {
  const { state, contents } = useRecoilValueLoadable(allWorkflowsListSelector);
  const resetBulkDelete = useResetRecoilState(bulkDeleteAtom);

  const toggleDelete = useToggleDeleteWorkflow();

  useEffect(() => () => resetBulkDelete(), []);

  if (state === 'loading') {
    return <Skeleton length={5} />;
  }

  if (state === 'hasError' || contents.list.length === 0) {
    return (
      <Card>
        <Card.Body>
          <Row>
            <Col className="align-self-center text-center" style={{ padding: '5rem 0' }}>
              <h4 className="mb-4">Get started with your first workflow!</h4>
              <WorkflowCreateButton tracking={{ Position: 'Inside Template List' }} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      {contents.list.map(({ id, name }) => (
        <Item key={id}>
          <Item.Header>
            <Item.HeaderLeft>
              <CheckboxElement checked={false} disabled={false} handleEvent={() => toggleDelete(id)} />
            </Item.HeaderLeft>
            <Item.HeaderMiddle>
              <LinkContainer to={`/workflows/${id}`} style={{ cursor: 'pointer' }}>
                <span className="h5 fw-bold">{name}</span>
              </LinkContainer>
              <Card.Subtitle className="mb-1 mt-2">
                <Badge className="bg-subtle">ID: {id}</Badge>
              </Card.Subtitle>
            </Item.HeaderMiddle>
            <Item.HeaderRight>
              <ButtonGroup className="me-2">
                <WorkflowDuplicateButton id={id} />
                <WorkflowEditButton id={id} name={name}>
                  <IconPencilSquare size="18" />
                </WorkflowEditButton>
              </ButtonGroup>
              <WorkflowDeleteButton id={id} title="Delete" />
            </Item.HeaderRight>
          </Item.Header>
        </Item>
      ))}

      {contents.meta.isFreePlan && (
        <Card>
          <Card.Body>
            <Row>
              <Col className="align-self-center text-center">
                <p>
                  {contents.meta.isRestricted ? (
                    <strong>Upgrade to access all of your workflows in the studio.</strong>
                  ) : (
                    <strong>Your free plan includes one template. Upgrade for unlimited workflows.</strong>
                  )}
                </p>
                <Button href="/subscription">Upgrade</Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default WorkflowListItems;
