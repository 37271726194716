import { Button, ButtonToolbar } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { IconQuestionCircle } from '@assets/icons';

import WorkflowRunTestButton from '@feature/workflows/buttons/WorkflowEditRunTestButton';
import WorkflowSaveButton from '@feature/workflows/buttons/WorkflowSaveButton';

function WorkflowToolbarRight() {
  const { id } = useParams();
  const { show } = useIntercom();

  return (
    <>
      <ButtonToolbar>
        <Button onClick={show} variant="light" className="me-2">
          <IconQuestionCircle size={18} />
        </Button>
        <div className="d-flex me-2">
          <WorkflowSaveButton />
        </div>
        <div className="d-flex me-2">
          <WorkflowRunTestButton id={id} />
        </div>
      </ButtonToolbar>
    </>
  );
}

export default WorkflowToolbarRight;
