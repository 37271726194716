import config from '@config';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { featureFlagsAtom } from '@store/UI';

import { FEATURE_FLAGS_NAMES, FeatureFlagKey } from '@constants/FeatureFlags';

const FeatureFlagToggle = () => {
  if (!config.isDev) return null;

  const [featureFlags, setFeatureFlags] = useRecoilState(featureFlagsAtom);

  const handleToggle = useCallback(
    (flag: FeatureFlagKey) => {
      setFeatureFlags((prevFlags) => ({
        ...prevFlags,
        [flag]: !prevFlags[flag],
      }));
    },
    [setFeatureFlags]
  );

  return (
    <div className="feature-flag-toggle">
      <div className="text-sm font-bold mb-2">Feature Flags</div>
      {Object.entries(featureFlags).length === 0 ? (
        <div className="text-sm text-gray-500">No feature flags set</div>
      ) : (
        Object.entries(featureFlags).map(([flag, value]) => (
          <div key={flag}>
            <label className="form-checkbox">
              <input type="checkbox" checked={value} onChange={() => handleToggle(flag as FeatureFlagKey)} />
              <span className="ml-1">{FEATURE_FLAGS_NAMES[flag as FeatureFlagKey] || flag}</span>
            </label>
          </div>
        ))
      )}
    </div>
  );
};

export default FeatureFlagToggle;
