import { useNavigate } from 'react-router-dom';

import ButtonElement from '@components/atoms/ButtonElement';

import useCreateTemplate from '@hooks/useCreateTemplate';

function TemplateCreate({ tracking, btnVariant = 'primary', children }) {
  const history = useNavigate();
  const { handleCreate, loading } = useCreateTemplate({
    onSuccess: (templateId) => history(`/studio/editor/${templateId}?isNew`),
  });

  const createTemplateTracking = {
    name: 'Select Create Template',
    properties: {
      ...tracking,
      template_id: null,
    },
  };

  return (
    <ButtonElement
      handleEvent={handleCreate}
      variant={btnVariant}
      loading={loading}
      style={{ minWidth: 100 }}
      tracking={createTemplateTracking}
    >
      {children || 'Create Blank Template'}
    </ButtonElement>
  );
}

export default TemplateCreate;
