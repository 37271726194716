import { useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useAnalytics } from '@components/analytics/Analytics';
import ButtonElement from '@components/atoms/ButtonElement';

import { useSaveWorkflow, workflowDataSampleAtomFamily } from '@store/Workflows';

import useRunWorkflow from '@hooks/useRunWorkflow';

import { delay } from '@utils/delay';

const BATCH_SIZE = 10;

const batchArray = (arr) => {
  const newArr = [];
  for (let i = 0; i < arr.length; i += BATCH_SIZE) {
    newArr.push(arr.slice(i, i + BATCH_SIZE));
  }
  return newArr;
};

const getSampleData = ({ data }) => {
  if (!data) {
    return undefined;
  }

  try {
    const sampleData = JSON.parse(data);
    return Array.isArray(sampleData) ? sampleData : [sampleData];
  } catch (error) {
    return undefined;
  }
};

function RunOnceModal({ children, show, onHide }) {
  return (
    <Modal show={show} onHide={onHide} size="lg" centered dialogClassName="asset-modal">
      <Modal.Header closeButton>
        <Modal.Title>Run Test</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}

function EmptyJobData() {
  return (
    <div>
      <p>No job data available. Please provide sample data.</p>
      <p>You can add sample data via the workflow trigger configuration.</p>
    </div>
  );
}

function JobDataPreview({ headers, jobsPreview, jobs, loading, handleRunOneJob }) {
  return (
    <>
      <p>
        We will use the following data to run your workflow. (Showing {jobsPreview.length} of {jobs.length} lines)
      </p>
      <div className="mb-4 overflow-auto hide-scroll">
        <Table className="mb-0" striped bordered hover>
          <thead>
            <tr>
              {jobs.length > 1 && <th style={{ width: '1%' }}>&nbsp;</th>}
              {headers.map((key, index) => (
                <th key={index}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {jobsPreview.map((data, index) => (
              <tr key={index}>
                {jobs.length > 1 && (
                  <td>
                    <ButtonElement
                      handleEvent={handleRunOneJob(index)}
                      loading={loading}
                      disabled={loading}
                      className="me-2"
                    >
                      Run
                    </ButtonElement>
                  </td>
                )}
                {Object.entries(data).map(([key, value]) => (
                  <td key={`${index}-${key}`} style={{ minWidth: 300 }}>
                    {value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

function WorkflowEditRunTestButton({ id }) {
  const { trackEvent } = useAnalytics();
  const sampleData = useRecoilValue(workflowDataSampleAtomFamily(id));
  const navigate = useNavigate();
  const [headers, setHeaders] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [jobsPreview, setJobsPreview] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showRunOnceModal, setShowRunOnceModal] = useState(false);
  const saveWorkflow = useSaveWorkflow();
  const { handleRun } = useRunWorkflow();

  const handleCloseModal = () => {
    setShowRunOnceModal(false);
    setHeaders([]);
    setJobs([]);
    setJobsPreview([]);
    setLoading(false);
  };

  const handleRunTest = () => {
    const data = getSampleData({ data: sampleData });
    const jobData = data || [];

    setShowRunOnceModal(true);
    setHeaders(jobData.length > 0 ? Object.keys(jobData[0]) : []);
    setJobs(jobData);
    setJobsPreview(jobData.slice(0, 5));
  };

  const handleRunJobs = async () => {
    setLoading(true);
    trackEvent('Run Workflow', { Id: id });
    await saveWorkflow();

    for (const batch of batchArray(jobs)) {
      await handleRun(id, batch);
      await delay(1000);
    }

    setLoading(false);
    handleCloseModal();
    navigate(`/workflows/${id}`);
  };

  const handleRunOneJob = (index) => async () => {
    setLoading(true);
    trackEvent('Run Workflow', { Id: id });
    await saveWorkflow();

    await handleRun(id, [jobs[index]]);
    await delay(1000);

    setLoading(false);
    handleCloseModal();
    navigate(`/workflows/${id}`);
  };

  return (
    <>
      <div className="flex gap-2">
        <ButtonElement className="whitespace-nowrap" handleEvent={handleRunTest}>
          Run Test
        </ButtonElement>
      </div>

      <RunOnceModal show={showRunOnceModal} onHide={handleCloseModal}>
        {jobs.length === 0 ? (
          <EmptyJobData />
        ) : (
          <JobDataPreview
            headers={headers}
            jobsPreview={jobsPreview}
            jobs={jobs}
            loading={loading}
            handleRunOneJob={handleRunOneJob}
          />
        )}

        {jobs.length !== 0 && (
          <div className="d-flex align-items-center justify-content-end">
            <ButtonElement handleEvent={handleRunJobs} loading={loading} disabled={loading}>
              Save and {jobs.length > 0 ? (jobs.length === 1 ? 'Run 1 Job' : `Run ${jobs.length} Jobs`) : 'Run'}
            </ButtonElement>
          </div>
        )}
      </RunOnceModal>
    </>
  );
}

export default WorkflowEditRunTestButton;
