export const jsonStringifyTemplate = ({
  background,
  soundtrack,
  merge,
  callback,
  disk,
  cache,
  fonts,
  output,
  tracks,
}) => {
  let timeline = { background };
  let edit = { timeline, output };

  if (merge?.length) edit = { ...edit, merge };
  if (callback) edit = { ...edit, callback };
  if (disk === 'mount') edit = { ...edit, disk };
  if (cache === false) timeline = { ...timeline, cache };
  if (fonts?.length) timeline = { fonts, ...timeline };
  if (soundtrack?.src) timeline = { soundtrack, ...timeline };
  if (tracks.length) edit = { ...edit, timeline: { ...timeline, tracks } };

  return JSON.stringify(edit, null, 4);
};

export const replaceTextOverrideValues = (text, replacements) => {
  try {
    return Object.entries(replacements).reduce((acc, [find, replacement]) => {
      const escapedFind = find.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(`{{\\s*${escapedFind}\\s*}}`, 'gi');
      return acc.replace ? acc.replace(regex, replacement) : acc;
    }, text || '');
  } catch (error) {
    console.error(error);
    return text;
  }
};

export const removeMetaDataRecursive = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => removeMetaDataRecursive(item));
  } else if (typeof data === 'object' && data !== null) {
    const { id, meta, ...restData } = data;
    return Object.entries(restData).reduce((acc, [key, value]) => {
      acc[key] = removeMetaDataRecursive(value);
      return acc;
    }, {});
  } else {
    return data;
  }
};

export const addHandleBars = (data) => {
  return Object.entries(data).reduce((acc, [find, replace]) => {
    acc[find] = `{{ ${replace} }}`;
    return acc;
  }, {});
};

export const removeEmptyEntriesRecursive = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.filter((item) => item !== '' && item !== null && item !== undefined).map(removeEmptyEntriesRecursive);
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    const sanitizedValue = removeEmptyEntriesRecursive(value);
    if (
      sanitizedValue !== '' &&
      sanitizedValue !== null &&
      sanitizedValue !== undefined &&
      !(Array.isArray(sanitizedValue) && sanitizedValue.length === 0) &&
      !(typeof sanitizedValue === 'object' && Object.keys(sanitizedValue).length === 0)
    ) {
      acc[key] = sanitizedValue;
    }
    return acc;
  }, {});
};
