import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { IconInfo, IconInvisible, IconVisible } from '@assets/icons';

import consoleService from '@api/services/console';

import AlertBox from '@components/alert/AlertBox';
import { useAnalytics } from '@components/analytics/Analytics';
import ButtonElement from '@components/atoms/ButtonElement';
import CodeEditor from '@components/code/CodeEditor';
import PrismSnippet from '@components/code/PrismSnippet';

import { authKeysSelector, stageAtom } from '@store/Auth';

import { stageMap } from '@constants/Stage';

import { delay } from '@utils/delay';

const getAssetUrl = (product, id) => {
  if (!id) {
    return '';
  }

  return `/apis/${product}/assets/${id}`;
};

const ConsoleInfo = ({ text, className, children }) => {
  return (
    <div className={cn(className, 'flex')}>
      <div>
        <IconInfo size={24} className="mr-2" />
      </div>
      <p className="mb-0">
        <span className="font-bold" dangerouslySetInnerHTML={{ __html: text }} />
        {children}
      </p>
    </div>
  );
};

function ConfigButtons({ serviceConfigs, request, handleConfigChange }) {
  return (
    <div className="flex flex-wrap gap-2">
      {Object.entries(serviceConfigs).map(([key, operation]) => (
        <Button
          key={key}
          onClick={() => handleConfigChange(key)}
          variant={operation.config.name === request?.config?.name ? 'primary' : 'light'}
          size="sm"
          className="rounded-full whitespace-nowrap"
        >
          {operation.config.name}
        </Button>
      ))}
    </div>
  );
}

function EndpointDisplay({ url }) {
  return (
    <div>
      <p className="uppercase-label">Endpoint</p>
      <PrismSnippet text={`POST ${url}`} language="ruby" className="mb-2" />
    </div>
  );
}

function ApiKeyDisplay({ keys, stage, showApiKey, setShowApiKey }) {
  if (!keys || !keys[stage]) {
    return null;
  }

  const currentKey = keys[stageMap[stage]];

  return (
    <div className="flex flex-col gap-2 mb-2">
      <div className="flex flex-col">
        <p className="uppercase-label">API Key</p>
        <div className="flex justify-between">
          <PrismSnippet text={showApiKey ? currentKey?.key : '••••••••••••••••'} className="w-100 me-2" />
          <Button variant="ghost" onClick={() => setShowApiKey(!showApiKey)}>
            {showApiKey ? <IconVisible /> : <IconInvisible />}
          </Button>
        </div>
      </div>
    </div>
  );
}

function PayloadEditor({ data, helperText, handleEditorChange }) {
  return (
    <div>
      {helperText && <ConsoleInfo text={helperText} className="mt-4 mb-4" />}
      <p className="uppercase-label">Payload</p>
      <CodeEditor value={data} onChange={handleEditorChange} />
    </div>
  );
}

function ResponseSection({ response, handleViewAsset }) {
  if (!response) {
    return null;
  }

  const { data, rawResponse } = response;
  return (
    <div>
      <div className="my-2">
        <p className="uppercase-label">Response</p>
        <PrismSnippet compact text={JSON.stringify(rawResponse, null, 2)} />
        {data && (
          <ButtonElement handleEvent={handleViewAsset} className="my-2 p-3">
            View Asset
          </ButtonElement>
        )}
      </div>
    </div>
  );
}

function InteractiveConsole({ product, compact = false, initialPayload = null, initialConfig = null }) {
  const navigate = useNavigate();
  const responseRef = useRef(null);
  const [request, setRequest] = useState(null);
  const [payload, setPayload] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showApiKey, setShowApiKey] = useState(false);
  const [serviceConfigs, setServiceConfigs] = useState({});
  const [api, setApi] = useState(product);
  const currentStage = useRecoilValue(stageAtom);
  const { contents: authKeys } = useRecoilValueLoadable(authKeysSelector);
  const { trackEvent } = useAnalytics();

  const updateRequestData = (request, newPayload) => {
    setRequest(request);
    setPayload(newPayload || request?.config?.data || {});
    setResponse(null);
  };

  useEffect(() => {
    const configs = consoleService[api];
    let request;
    if (initialConfig && configs[initialConfig]) {
      request = configs[initialConfig];
    } else {
      request = configs && Object.values(configs)[0];
    }
    setServiceConfigs(configs || {});
    updateRequestData(request, initialPayload);
  }, [api, initialPayload, initialConfig]);

  const handleConfigChange = (key) => {
    const request = serviceConfigs[key] || {};
    updateRequestData(request);
  };

  const scrollToResponse = () => {
    if (!responseRef.current) {
      return;
    }

    const animTimeout = setTimeout(() => {
      responseRef.current.scrollIntoView({ behavior: 'smooth' });
      clearTimeout(animTimeout);
    }, 100);
  };

  const sendRequest = async () => {
    if (!request) {
      return;
    }

    try {
      setLoading(true);
      setResponse(null);
      const data = await request.submit(payload);
      await delay(1000);
      trackEvent('API Playground Request');
      setResponse(data);
    } catch (error) {
      setResponse(error);
    } finally {
      setLoading(false);
      scrollToResponse();
    }
  };

  const handleViewAsset = () => {
    const { data } = response || [];
    const url = getAssetUrl(api, data?.id);
    navigate(url);
  };

  const handleEditorChange = (value) => {
    try {
      setPayload(JSON.parse(value));
    } catch (e) {
      console.error({ e });
    }
  };

  return (
    <div className="interactive-console w-full">
      {!compact && (
        <Card className="p-3 mb-4">
          <Card.Body>
            <p className="uppercase-label">Tools</p>
            <h1 className="mb-8">API Playground</h1>

            <div className="flex items-start gap-4">
              <select
                className="form-select"
                onChange={(e) => setApi(e.target.value)}
                style={{ display: 'inline', maxWidth: '200px' }}
              >
                <option value="edit-api">Edit API</option>
                <option value="ingest-api">Ingest API</option>
              </select>

              <ConfigButtons
                serviceConfigs={serviceConfigs}
                request={request}
                handleConfigChange={handleConfigChange}
              />
            </div>
          </Card.Body>
        </Card>
      )}

      <ConsoleInfo text={request?.config?.description} className="mb-8">
        {request?.config?.integrationLink && (
          <span className="text-sm">
            <br />
            <Link to={`/integrations${request?.config?.integrationLink}`}>Configure the integration</Link> to use within
            the API Playground.
          </span>
        )}
      </ConsoleInfo>

      {currentStage === 'stage' && api === 'create-api' && (
        <div className="my-3">
          <AlertBox
            type="warning"
            name="Not available in sandbox"
            message="This API is not available in the sandbox environment. Please use the production environment to use this API."
          />
        </div>
      )}

      <EndpointDisplay url={request?.getDisplayUrl(currentStage)} />

      <ApiKeyDisplay keys={authKeys} stage={currentStage} showApiKey={showApiKey} setShowApiKey={setShowApiKey} />

      <PayloadEditor
        data={payload}
        helperText={request?.config?.payloadHelperText}
        handleEditorChange={handleEditorChange}
      />

      <ButtonElement
        handleEvent={sendRequest}
        disabled={loading || (currentStage === 'stage' && api === 'create-api')}
        className="my-2 p-3"
      >
        {loading ? 'Sending...' : 'Send Request'}
      </ButtonElement>

      <div ref={responseRef}>
        <ResponseSection response={response} handleViewAsset={handleViewAsset} />
      </div>
    </div>
  );
}

export default InteractiveConsole;
