import { Spinner } from 'react-bootstrap';

import '@css/Editor.css';

import { RecoilUndoRoot } from '@context/RecoilUndo';
import { TimelinePlaybackProvider } from '@context/TimelinePlaybackProvider';

import WorkspaceRoot from '@feature/studio/workspace/WorkspaceRoot';

import useSdkIntegration from '@hooks/useSdkIntegration';

const allowedKeys = ['edit/timeline/**'];

const SDK = ({ children }) => {
  const { sdkLoading } = useSdkIntegration();

  if (sdkLoading) {
    return (
      <div className="flex justify-center align-items-center vh-full">
        <Spinner />
      </div>
    );
  }

  return children;
};

function EditorSdk() {
  return (
    <RecoilUndoRoot allowedKeys={allowedKeys}>
      <TimelinePlaybackProvider>
        <SDK>
          <WorkspaceRoot />
        </SDK>
      </TimelinePlaybackProvider>
    </RecoilUndoRoot>
  );
}

export default EditorSdk;
