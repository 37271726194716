import { useCallback } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValueLoadable, useResetRecoilState, useSetRecoilState } from 'recoil';

import '@css/UpgradeModal.css';

import { IconExternal } from '@assets/icons';

import { errorAtom } from '@store/UI';
import { upgradeModalAtom, useSaveWorkflow, workflowActionsLimitSelector } from '@store/Workflows';

import { getErrorMessage } from '@utils/getErrorMessage';

function UpgradeModal() {
  const history = useNavigate();
  const saveWorkflow = useSaveWorkflow();

  const setErrorMessage = useSetRecoilState(errorAtom);
  const resetErrorMessage = useResetRecoilState(errorAtom);
  const [modalShow, setModalShow] = useRecoilState(upgradeModalAtom);
  const { state, contents } = useRecoilValueLoadable(workflowActionsLimitSelector);

  const handleUpgrade = useCallback(async () => {
    resetErrorMessage();
    try {
      await saveWorkflow();
      setModalShow(false);
      history('/subscription');
    } catch (error) {
      setErrorMessage({ message: getErrorMessage(error) });
    }
  }, [resetErrorMessage, saveWorkflow, setErrorMessage]);

  const handleHide = () => {
    setModalShow(false);
  };

  return (
    <Modal onHide={handleHide} show={modalShow} size="xl" centered className="upgrade-modal">
      <div className="upgrade-modal__body">
        <div className="upgrade-modal__content">
          <Modal.Header>
            <Modal.Title>Upgrade For More Actions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {state === 'loading' && (
              <div className="upgrade-modal__loading">
                <Spinner animation="border" variant="secondary" />
              </div>
            )}
            {state === 'hasError' && (
              <div className="upgrade-modal__error">
                <p>There was an error loading your subscription.</p>
              </div>
            )}
            {state === 'hasValue' && (
              <>
                <p>
                  To add more actions, you need to upgrade your plan. You are currently on the{' '}
                  <code>{contents.plan}</code> plan and are limited to <code>{contents.actionsLimit}</code> actions.
                </p>
                {contents.nextPlan && (
                  <p>
                    Upgrade to the <code>{contents.nextPlan.name}</code> plan and add up to{' '}
                    <code>{contents.nextPlan.limit}</code> actions!
                  </p>
                )}
                <div className="upgrade-modal__actions">
                  <Button onClick={handleUpgrade}>Save &amp; Upgrade</Button>
                  <div className="upgrade-modal__link">
                    <a target="_black" href="https://shotstack.io/pricing/" rel="noopener noreferrer">
                      View Pricing
                    </a>
                    <IconExternal size={12} />
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
        </div>

        <div>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleHide} />
          <img className="upgrade-modal__image" alt="" src={`/img/actions.png`} />
        </div>
      </div>
    </Modal>
  );
}

export default UpgradeModal;
