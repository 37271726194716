import { Button, Col, Row } from 'react-bootstrap';
import { useIntercom } from 'react-use-intercom';
import { useRecoilState } from 'recoil';

import { IconAspectRatio } from '@assets/icons';

import OffsetFields from '@feature/studio/fields/OffsetFields';
import SizeFields from '@feature/studio/fields/SizeFields';
import TimingFields from '@feature/studio/fields/TimingFields';
import TransformFields from '@feature/studio/fields/TransformFields';
import VisualEffectsFields from '@feature/studio/fields/VisualEffectsFields';

import AssetSourceInput from '@components/controls/AssetSourceInput';
import InputSelect from '@components/controls/InputSelect';
import InputTextarea from '@components/controls/InputTextarea';
import OverrideMenu from '@components/mergeFields/OverrideMenu';

import { clipSettingsSelectorFamily } from '@store/EditSelectors';

import ImageToVideoAspectRatios from '@constants/ImageToVideoAspectRatios';
import { RENDITION_WIDTH } from '@constants/Uploads';

const requestOptions = {
  outputs: {
    renditions: [
      {
        format: 'webp',
        filename: 'shotstack-proxy',
        quality: 85,
        size: {
          width: RENDITION_WIDTH,
        },
      },
    ],
  },
  destinations: [
    {
      provider: 'shotstack',
    },
  ],
};

function SettingAssetImageToVideo({ id }) {
  const [clip, setClip] = useRecoilState(clipSettingsSelectorFamily(id));
  const { showArticle } = useIntercom();

  const handleUploadComplete = (attributes) => {
    const {
      source,
      width,
      height,
      outputs: {
        renditions: [proxy],
      },
    } = attributes;

    return setClip({
      ['asset:src']: proxy.url,
      ['asset:meta']: {
        ...clip['asset:meta'],
        source,
        width,
        height,
        placeholder: false,
        proxied: true,
        proxyScaleMultiplier: width / proxy?.width,
      },
    });
  };

  const handleAssetPromptUpdate = () => (event) => {
    const { value } = event.target;
    setClip({ 'asset:meta': { ...clip['asset:meta'], text: value } });
  };

  const handleAspectRatioChange = (event) => {
    const { value } = event.target;

    if (value.length > 0) {
      setClip({ ['asset:aspectRatio']: value });
    } else {
      setClip({ ['asset:aspectRatio']: undefined });
    }
  };

  return (
    <>
      <div className="mb-10">
        <AssetSourceInput
          id={id}
          clip={clip}
          requestOptions={requestOptions}
          onUploadComplete={handleUploadComplete}
          type="image"
          accepts="image/*"
        />
        <Button variant="dark" className="mt-5" onClick={() => showArticle(9929198)}>
          How does image to video work?
        </Button>
      </div>

      <h6>Prompt</h6>
      <div className="mb-10">
        <Row>
          <Col md={12}>
            <InputTextarea
              id="prompt"
              placeholder="Imagine {{ NAME }} riding a giant pineapple through a field of rainbow-colored flowers."
              rows={5}
              value={clip['asset:meta'].text}
              handleChange={handleAssetPromptUpdate()}
            />
          </Col>
        </Row>
      </div>

      <h6>Aspect Ratio</h6>
      <div className="mb-10">
        <Row>
          <Col md={12} className="d-flex align-items-center justify-content-between gap-2">
            <InputSelect
              id="aspect-ratio"
              title="Aspect Ratio"
              selected={clip['asset:aspectRatio'] || '16:9'}
              values={ImageToVideoAspectRatios}
              prefix={<IconAspectRatio size={18} />}
              handleChange={handleAspectRatioChange}
            />
            <OverrideMenu path="asset:aspectRatio" />
          </Col>
        </Row>
      </div>

      <h6>Size &amp; Position</h6>
      <div className="mb-10">
        <div className="mb-2">
          <SizeFields id={id} />
        </div>
        <OffsetFields id={id} />
      </div>

      <h6>Transform</h6>
      <div className="mb-2">
        <TransformFields id={id} />
      </div>
      <div className="mb-10">
        <VisualEffectsFields id={id} />
      </div>

      <h6>Timing</h6>
      <TimingFields id={id} />
    </>
  );
}

export default SettingAssetImageToVideo;
