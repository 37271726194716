import '@css/VideoCard.css';

import useVideoHover from '@hooks/useVideoHover';

const VideoCard = ({ videoSrc, posterSrc, ...props }) => {
  const { videoRef, handleMouseEnter, handleMouseLeave } = useVideoHover();

  return (
    <video
      {...props}
      ref={videoRef}
      src={videoSrc}
      poster={posterSrc}
      muted
      loop
      className="video"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};

export default VideoCard;
