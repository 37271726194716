export const spriteMaskFragment = `
  varying vec2 vMaskCoord;
  varying vec2 vTextureCoord;

  uniform sampler2D uSampler;
  uniform sampler2D mask;
  uniform float alpha;
  uniform float npmAlpha;
  uniform vec4 maskClamp;

  void main(void)
  {
    float clip = step(3.5,
      step(maskClamp.x, vMaskCoord.x) +
      step(maskClamp.y, vMaskCoord.y) +
      step(vMaskCoord.x, maskClamp.z) +
      step(vMaskCoord.y, maskClamp.w)
    );

    vec4 original = texture2D(uSampler, vTextureCoord);
    vec4 maskY = texture2D(mask, vMaskCoord);
    float alphaMul = 1.0 - npmAlpha * (1.0 - maskY.a);

    original *= 1.0 - (alphaMul * maskY.r * alpha * clip);

    gl_FragColor = original;
  }
`;
