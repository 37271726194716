import { Card } from 'react-bootstrap';

import '@css/TemplateGalleryCard.css';

import VideoCard from '@components/video/VideoCard';

function TemplateGalleryCard({ data, withDetails = false, onClick }) {
  const { heading, media, thumbnail, description, tags } = data;

  const handleClick = () => {
    onClick(data);
  };

  return (
    <>
      <div className="template-gallery__card-wrapper">
        <Card className="template-gallery__card" onClick={handleClick}>
          <VideoCard videoSrc={media} posterSrc={thumbnail} />
        </Card>
      </div>
      <h6 className="template-gallery__title link" onClick={handleClick}>
        {heading}
      </h6>
      {withDetails && (
        <div className="template-gallery__details">
          <p>
            <small>{description}</small>
          </p>
          <div className="d-flex align-items-center gap-2 mb-2">
            <div className="tag">{tags}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default TemplateGalleryCard;
