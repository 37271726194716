import '@css/WorkflowEditorToolbar.css';

import WorkflowEditorEditorToolbarLeft from '@feature/workflows/toolbar/WorkflowEditorToolbarLeft';
import WorkflowEditorEditorToolbarRight from '@feature/workflows/toolbar/WorkflowEditorToolbarRight';

function WorkflowEditorToolbar() {
  return (
    <div className="workflow-editor-toolbar">
      <div className="workflow-editor-toolbar__left">
        <WorkflowEditorEditorToolbarLeft />
      </div>
      <div className="workflow-editor-toolbar__middle" />
      <div className="workflow-editor-toolbar__right">
        <WorkflowEditorEditorToolbarRight />
      </div>
    </div>
  );
}

export default WorkflowEditorToolbar;
