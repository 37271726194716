import OverlaySourceInput from '@components/controls/OverlaySourceInput';

import { useAddMediaCallback } from '@store/Media';

function PanelMediaUpload() {
  const addMedia = useAddMediaCallback();

  const onUploadComplete = (media) => {
    addMedia(media);
  };

  return (
    <>
      <OverlaySourceInput onUploadComplete={onUploadComplete} />
      <p className="helper-text centered">
        <span>
          Upload a QuickTime animation with alpha channel to create animated overlays, transition effects, lower thirds,
          and more. Only QuickTime <strong>.mov</strong> or <strong>.qt</strong> files can be used as overlays.
        </span>
      </p>
    </>
  );
}

export default PanelMediaUpload;
