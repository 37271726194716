import config from '@config';
import { memo } from 'react';
import { IntercomProvider } from 'react-use-intercom';

import { useAuth0 } from '@context/Auth0ReactSPA';

import Loader from '@components/loader/Loader';

const AUTH0_NAMESPACE = 'https://dashboard.shotstack.io';
const AUTH0_PROPERTY = 'hash';

function Intercom({ children, isSdk }) {
  const { loading, user } = useAuth0();

  if (loading && !isSdk) {
    return <Loader />;
  }

  const userId = user?.sub.split('|')[1];

  if (isSdk) {
    return <IntercomProvider appId={config.intercom.appId}>{children}</IntercomProvider>;
  }

  return (
    <IntercomProvider
      appId={config.intercom.appId}
      autoBoot
      autoBootProps={{
        userId,
        name: user?.name,
        apiBase: 'https://api-iam.intercom.io',
        email: user?.email,
        userHash: user?.[`${AUTH0_NAMESPACE}/${AUTH0_PROPERTY}`],
      }}
    >
      {children}
    </IntercomProvider>
  );
}

export default memo(Intercom);
