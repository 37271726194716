import { Col, Row } from 'react-bootstrap';

import ContentCard from '@components/cards/ContentCard';
import Header from '@components/header/Header';

function Studio() {
  return (
    <>
      <Header title="Studio SDK">Embed video editing capabilities into your application.</Header>
      <Row className="mb-4">
        <Col md={4}>
          <ContentCard image={`/img/provider/shotstack.png`}>
            The Shotstack Studio SDK provides a powerful and flexible way to embed video editing capabilities directly
            into your web applications, offering users a rich set of video editing features without leaving your site.
          </ContentCard>
        </Col>
        <Col md={8}>
          <ContentCard heading="DEMO">
            <p>
              See the <a href="https://demo.shotstack.io/studio-sdk/">Shotstack Studio SDK demo</a> in action.
            </p>
            <img src="/img/provider/studio-sdk.png" alt="" srcSet="" />
          </ContentCard>
          <ContentCard heading="SETUP">
            <p>
              Apply for early access to the Studio SDK by filling out{' '}
              <a href="https://forms.gle/TiX4ZSuuYbtJ1C2X8">this form</a>.
            </p>
            <p>
              Please note, the Studio SDK currently only supports player mode, which allows you to preview your videos
              in your application. Interactive editing features will be added in time.
            </p>
          </ContentCard>
        </Col>
      </Row>
    </>
  );
}

export default Studio;
