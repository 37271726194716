import { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { useRecoilState, useRecoilValue } from 'recoil';

import WorkflowModuleName from '@feature/workflows/module/WorkflowModuleName';
import WorkflowModuleOutputs from '@feature/workflows/module/WorkflowModuleOutputs';
import WorkflowPanelHeader from '@feature/workflows/panel/WorkflowPanelHeader';

import InputCheckbox from '@components/controls/InputCheckbox';
import InputText from '@components/controls/InputText';

import {
  activeTaskAtom,
  taskInputsAtomFamily,
  taskModuleAtomFamily,
  taskOptionsAtomFamily,
  workflowDataListSelector,
} from '@store/Workflows';

import { MODULE_DESTINATION_FIELDS, noOptionsMessage } from '@constants/Workflows';

function WorkflowModuleDestinations() {
  const activeTask = useRecoilValue(activeTaskAtom);
  const taskModule = useRecoilValue(taskModuleAtomFamily(activeTask));
  const [taskInputs, setTaskInputs] = useRecoilState(taskInputsAtomFamily(activeTask));
  const [taskOptions, setTaskOptions] = useRecoilState(taskOptionsAtomFamily(activeTask));
  const { list, grouped } = useRecoilValue(workflowDataListSelector);

  const setStateMap = {
    inputs: setTaskInputs,
    options: setTaskOptions,
  };

  const getSelectedValue = (data, key) => {
    return data.find(({ value }) => value === key) || null;
  };

  const handleChange = (field, type) => {
    const setState = setStateMap[type];
    return (e) => {
      if (!e) {
        setState((prevState) => ({ ...prevState, [field]: undefined }));
        return;
      }

      const { value } = e.target || e;
      setState((prevState) => ({ ...prevState, [field]: value }));
    };
  };

  const handleCheckboxChange = (field, type) => {
    const setState = setStateMap[type];
    return (e) => {
      const { checked } = e.target;
      setState((prevState) => ({ ...prevState, [field]: checked }));
    };
  };

  const fields = MODULE_DESTINATION_FIELDS[taskModule];

  if (!fields) {
    return null;
  }

  return (
    <>
      <div className="form-container">
        <WorkflowModuleName />
      </div>
      <div className="form-container sm">
        <WorkflowPanelHeader
          name="Configuration"
          description="The configuration settings and file URL to host or store."
        />
        {fields.map(({ name, label, type, placeholder, fieldType, options, isOptional }, index) => {
          if (type === 'seperator') {
            return <hr key={index} />;
          }

          const inputsSelectList = options || list;
          const inputsSelectOptions = options || grouped;

          return (
            <Fragment key={index}>
              <Row className="d-flex align-items-center">
                <Col xs={4}>
                  <label>{label || name}</label>
                </Col>
                <Col>
                  {type === 'inputs' && (
                    <ReactSelect
                      unstyled
                      isClearable={isOptional}
                      className="react-select__inputs form-control"
                      classNamePrefix="react-select"
                      key={`${activeTask}-${name}`}
                      id={name}
                      name={name}
                      placeholder={`Select input${isOptional ? ' (optional)' : ''}`}
                      noOptionsMessage={noOptionsMessage('data')}
                      defaultValue={getSelectedValue(inputsSelectList, taskInputs[name])}
                      options={inputsSelectOptions}
                      onChange={handleChange(name, type)}
                    />
                  )}
                  {type === 'options' && fieldType === 'text' && (
                    <InputText
                      key={`${activeTask}-${name}`}
                      id={name}
                      name={name}
                      defaultValue={taskOptions[name]}
                      placeholder={placeholder}
                      handleChange={handleChange(name, type)}
                    />
                  )}
                  {type === 'options' && fieldType === 'select' && (
                    <ReactSelect
                      unstyled
                      className="form-control"
                      classNamePrefix="react-select"
                      key={`${activeTask}-${name}`}
                      id={name}
                      name={name}
                      placeholder={placeholder}
                      noOptionsMessage={noOptionsMessage()}
                      defaultValue={getSelectedValue(options, taskOptions[name])}
                      options={options}
                      onChange={handleChange(name, type)}
                    />
                  )}
                  {type === 'options' && fieldType === 'checkbox' && (
                    <InputCheckbox
                      key={`${activeTask}-${name}`}
                      id={name}
                      name={name}
                      checked={taskOptions[name]}
                      handleChange={handleCheckboxChange(name, type)}
                    />
                  )}
                </Col>
              </Row>
            </Fragment>
          );
        })}
      </div>
      <div className="form-container">
        <WorkflowModuleOutputs
          name="Output"
          description="This action transfers a file and outputs the URL of the file."
        />
      </div>
    </>
  );
}

export default WorkflowModuleDestinations;
