import { atom } from 'recoil';

export const assetLoaderIsLoadingAtom = atom({
  key: 'assetLoaderIsLoadingAtom',
  default: false,
});

export const assetLoaderErrorAtom = atom({
  key: 'assetLoaderErrorAtom',
  default: false,
});

export const assetLoaderProgressAtom = atom({
  key: 'assetLoaderProgressAtom',
  default: 0,
});

export const assetLoaderRegistryAtom = atom({
  key: 'assetLoaderRegistryAtom',
  default: [],
});
