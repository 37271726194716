import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import AlertBox from '@components/alert/AlertBox';
import LinkElement from '@components/atoms/LinkElement';
import InteractiveConsole from '@components/console/InteractiveConsole';

import { stageAtom } from '@store/Auth';

function OverviewCreateApi() {
  const stage = useRecoilValue(stageAtom);
  const { product } = useParams();

  return (
    <>
      <Card className="p-3 mb-10">
        <Card.Body>
          <p className="uppercase-label">Create Api</p>
          <h1>Create Assets Using Generative AI</h1>
          <p className="text-prose text-gray-500">
            Create assets using the Create API. Tap in to native and 3rd party Generative AI services to create text,
            images, voice overs and avatars from text prompts to use in your templates and workflows.
          </p>
          <LinkElement
            to="https://shotstack.io/docs/guide/generating-assets/generative-ai/"
            variant="light"
            type="button"
            external
          >
            Documentation
          </LinkElement>
          {stage === 'stage' && (
            <div className="mt-5">
              <AlertBox
                type="warning"
                name="Not available in sandbox"
                message="This API is not available in the sandbox environment. Please use the production environment to use this API."
              />
            </div>
          )}
          <InteractiveConsole product={product} />
        </Card.Body>
      </Card>
    </>
  );
}

export default OverviewCreateApi;
