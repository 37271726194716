import { useState } from 'react';
import { useRecoilRefresher_UNSTABLE, useResetRecoilState, useSetRecoilState } from 'recoil';

import WorkflowService from '@api/services/workflow';

import { errorAtom } from '@store/UI';
import { allWorkflowsSelector } from '@store/Workflows';

const useCreateWorkflow = ({ onSuccess } = {}) => {
  const [loading, setLoading] = useState(false);
  const setErrorMessage = useSetRecoilState(errorAtom);
  const resetErrorMessage = useResetRecoilState(errorAtom);
  const refreshAllWorkflowState = useRecoilRefresher_UNSTABLE(allWorkflowsSelector);

  const handleCreate = async (workflow) => {
    setLoading(true);
    try {
      const id = await WorkflowService.create(workflow);
      refreshAllWorkflowState();
      onSuccess(id);
    } catch (error) {
      setErrorMessage({ message: error.message });
      setTimeout(() => {
        resetErrorMessage();
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  return { loading, handleCreate };
};

export default useCreateWorkflow;
