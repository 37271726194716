import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { useRecoilState, useRecoilValue } from 'recoil';

import WorkflowModuleName from '@feature/workflows/module/WorkflowModuleName';
import WorkflowModuleOutputs from '@feature/workflows/module/WorkflowModuleOutputs';
import WorkflowPanelHeader from '@feature/workflows/panel/WorkflowPanelHeader';

import AlertBox from '@components/alert/AlertBox';
import InputNumber from '@components/controls/InputNumber';

import {
  activeTaskAtom,
  taskInputsAtomFamily,
  taskOptionsAtomFamily,
  workflowDataListSelector,
} from '@store/Workflows';

import { noOptionsMessage } from '@constants/Workflows';

import { isNumber } from '@utils/isNumber';

function WorkflowModuleTtvShotstack() {
  const activeTask = useRecoilValue(activeTaskAtom);
  const [taskInputs, setTaskInputs] = useRecoilState(taskInputsAtomFamily(activeTask));
  const [taskOptions, setTaskOptions] = useRecoilState(taskOptionsAtomFamily(activeTask));
  const { list, grouped } = useRecoilValue(workflowDataListSelector);

  const [selectedImageUrl, setSelectedImageUrl] = useState(null);

  useEffect(() => {
    setSelectedImageUrl(getSelectedValue(list, taskInputs?.imageUrl));
  }, [list, taskInputs?.imageUrl]);

  const getSelectedValue = (data, key) => {
    return data.find(({ value }) => value === key) || null;
  };

  const handleChange = (field, type) => (selectedOption) => {
    const value = selectedOption?.value;
    const setState = type === 'inputs' ? setTaskInputs : setTaskOptions;
    setState((prevState) => ({ ...prevState, [field]: value }));
    if (field === 'imageUrl') {
      setSelectedImageUrl(selectedOption);
    }
  };

  const handleOptionsChange = (field) => (e) => {
    const { value } = e.target;
    const valueInt = parseFloat(value);
    const newValue = isNumber(valueInt) ? valueInt : '';
    setTaskOptions((prevState) => ({ ...prevState, [field]: newValue }));
  };

  return (
    <>
      <div className="form-container">
        <WorkflowModuleName />
      </div>
      <div className="form-container sm">
        <WorkflowPanelHeader name="Configuration" description="The input image url to create a video from." />
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Image URL</label>
          </Col>
          <Col>
            <ReactSelect
              unstyled
              className="react-select__inputs form-control"
              classNamePrefix="react-select"
              id="imageUrl"
              name="imageUrl"
              placeholder="Select input"
              noOptionsMessage={noOptionsMessage('data')}
              value={selectedImageUrl}
              options={grouped}
              onChange={handleChange('imageUrl', 'inputs')}
            />
          </Col>
        </Row>
        <AlertBox
          type="info"
          message="Supported image dimensions are 1024x576px, 576x1024px and 768x768px. Use the Image Transformation Action to resize your image if necessary."
        ></AlertBox>
        <hr />
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Guidance Scale</label>
          </Col>
          <Col>
            <InputNumber
              id="guidanceScale"
              name="guidanceScale"
              min={0}
              max={10}
              step={0.1}
              value={taskOptions.guidanceScale}
              placeholder="1.8"
              handleChange={handleOptionsChange('guidanceScale')}
            />
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Motion</label>
          </Col>
          <Col>
            <InputNumber
              id="motion"
              name="motion"
              min={1}
              max={255}
              value={taskOptions.motion}
              placeholder="127"
              handleChange={handleOptionsChange('motion')}
            />
          </Col>
        </Row>
      </div>
      <div className="form-container">
        <WorkflowModuleOutputs
          name="Output"
          description="This action generates an MP4 video file and outputs the file URL."
        />
      </div>
    </>
  );
}

export default WorkflowModuleTtvShotstack;
