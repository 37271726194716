import { Sprite } from '@inlet/react-pixi';
import React, { forwardRef, useEffect, useRef } from 'react';

import withCanvasMovable from '@feature/studio/canvas/CanvasMovable';

import ArtboardMask from '@components/masks/ArtboardMask';

const CanvasImageToVideoPlayer = forwardRef((props, ref) => {
  const { resource, visible, x, y, width, height, alpha, zIndex, angle } = props;
  const maskRef = useRef(null);

  useEffect(() => {
    if (ref.current && maskRef.current) {
      ref.current.mask = maskRef.current;
    }
  }, [ref]);

  if (!resource) {
    return null;
  }

  return (
    <>
      <Sprite
        ref={ref}
        anchor={0.5}
        image={resource.source}
        visible={visible}
        x={x}
        y={y}
        width={width}
        height={height}
        alpha={alpha}
        zIndex={zIndex}
        angle={angle}
      />
      <ArtboardMask ref={maskRef} />
    </>
  );
});

export default withCanvasMovable(CanvasImageToVideoPlayer);
