import ModuleConfiguration from '@feature/workflows/module/WorkflowModuleConfiguration';
import ModuleOutputs from '@feature/workflows/module/WorkflowModuleOutputs';
import ModuleSelector from '@feature/workflows/module/WorkflowModuleSelector';

function WorkflowPanelEnd() {
  return (
    <div className="settings">
      <ModuleSelector />
      <ModuleConfiguration />
      <ModuleOutputs />
    </div>
  );
}

export default WorkflowPanelEnd;
