import { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useRecoilState } from 'recoil';

import { IconCaptions, IconVoiceChat } from '@assets/icons';

import TimingFields from '@feature/studio/fields/TimingFields';

import InputSelect from '@components/controls/InputSelect';
import InputTextarea from '@components/controls/InputTextarea';

import { clipSettingsSelectorFamily } from '@store/EditSelectors';
import { useAddClipToTimelineCallback } from '@store/Media';

import TextToSpeechVoices from '@constants/TextToSpeechVoices';

function SettingAssetTextToSpeech({ id }) {
  const [clip, setClip] = useRecoilState(clipSettingsSelectorFamily(id));
  const addClipToTimeline = useAddClipToTimelineCallback();

  const handleAssetTextUpdate = () => (event) => {
    const { value } = event.target;
    setClip({ 'asset:meta': { ...clip['asset:meta'], text: value } });
  };

  const handleVoiceChange = useCallback(
    (event) => {
      const { value: effect } = event.target;

      let newValue;
      if (effect.length > 0) {
        newValue = effect;
      }

      setClip({ 'asset:voice': newValue });
    },
    [setClip]
  );

  const handleAddAutoCaption = useCallback(() => {
    const [alias] = clip.id.split('-');
    setClip({ alias });
    addClipToTimeline({ data: { source: `alias://${alias}`, placeholder: true }, type: 'caption' });
  }, [addClipToTimeline]);

  return (
    <>
      <h6>Text</h6>
      <div className="mb-10">
        <InputTextarea
          id="text"
          placeholder="Hi {{ NAME }}, welcome to {{ COMPANY }}."
          rows={5}
          value={clip['asset:meta'].text}
          handleChange={handleAssetTextUpdate({ prop: 'text' })}
        />
        <Button
          variant="light"
          size="sm"
          className="w-100 p-2 mt-2"
          onClick={handleAddAutoCaption}
          disabled={clip.links?.linkedClips.length > 0}
        >
          <IconCaptions size={24} className="me-2" />
          {clip.links?.linkedClips.length > 0 ? 'Auto Captions Linked' : 'Add Auto Caption'}
        </Button>
      </div>

      <h6>Voice</h6>
      <div className="mb-10">
        <InputSelect
          id="voice"
          title="Voice"
          selected={clip['asset:voice'] || ''}
          values={TextToSpeechVoices}
          prefix={<IconVoiceChat size={18} />}
          handleChange={handleVoiceChange}
        />
      </div>

      <h6>Timing</h6>

      <TimingFields id={id} />
    </>
  );
}

export default SettingAssetTextToSpeech;
