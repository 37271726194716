import Layout from '@components/layout/Layout';

function NotFound() {
  return (
    <Layout>
      <div className="text-prose mb-16">
        <p className="uppercase-label">404</p>
        <h1>The Page You&apos;re Looking For Can&apos;t Be Found</h1>
        <p>
          Sorry, but the page you were trying to access doesn&apos;t seem to exist. It might have been moved, deleted,
          or perhaps the URL was entered incorrectly. Here&apos;s what you can do next:
        </p>

        <ul>
          <li>Double-check the URL for any typos or errors.</li>
          <li>
            Return to the <a href="/">home page</a> and start your journey again.
          </li>
          <li>
            Still lost? Our customer support team is here to help you find your way.{' '}
            <a href="mailto:support@shotstack.io?subject=Dashboard support">support@shotstack.io</a>.
          </li>
        </ul>
      </div>
    </Layout>
  );
}

export default NotFound;
