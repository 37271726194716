import { Form } from 'react-bootstrap';

import { useAnalytics } from '@components/analytics/Analytics';

function CheckboxElement({ name, type = 'checkbox', checked, disabled, handleEvent, label, tracking }) {
  const { trackEvent } = useAnalytics();

  const handleChange = (event) => {
    const checkedState = event.target.checked;

    if (tracking) {
      trackEvent(tracking.name, {
        ...tracking.properties,
        Checked: checkedState,
      });
    }

    handleEvent(event);
  };

  return (
    <label className="form-checkbox">
      <Form.Check
        defaultChecked={checked}
        disabled={disabled}
        name={name}
        htmlFor={name}
        type={type}
        onChange={handleChange}
      />
      {label && <span>{label}</span>}
    </label>
  );
}

export default CheckboxElement;
