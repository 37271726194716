import { cloneElement, memo } from 'react';
import { Badge, Ratio } from 'react-bootstrap';

import '@css/Asset.css';

function Asset({ children, icon }) {
  return (
    <div className="asset-holder">
      <Badge bg="light" className="asset-holder__badge">
        {cloneElement(icon, { size: 12, color: '#212529' })}
      </Badge>
      <Ratio aspectRatio="16x9" className="asset-holder__holder">
        {children}
      </Ratio>
    </div>
  );
}

export default memo(Asset);
