import balanceClient from '@api/clients/balance';

export const getUsage = async (filterParams) => {
  const url = `usage`;
  const { data } = await balanceClient.get(url, { params: filterParams });
  return data?.body?.data;
};

export const getBalance = async () => {
  const url = `balance`;
  const { data } = await balanceClient.get(url);
  return data?.body?.data;
};

export default {
  getUsage,
  getBalance,
};
