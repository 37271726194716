import cn from 'classnames';
import { Button, ButtonGroup, Col, InputGroup, OverlayTrigger, Row, ToggleButton, Tooltip } from 'react-bootstrap';
import { useIntercom } from 'react-use-intercom';
import { useRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';

import {
  IconAlignBottom,
  IconAlignCenterVertically,
  IconAlignTop,
  IconBorderRadius,
  IconFontSize,
  IconFormatColorFill,
  IconFormatColorReset,
  IconFormatColorText,
  IconLineHeight,
  IconPadding,
  IconStroke,
  IconStrokeWidth,
  IconTextAlignCenter,
  IconTextAlignLeft,
  IconTextAlignRight,
  IconTransparent,
  IconTrimStart,
} from '@assets/icons';

import TimingFields from '@feature/studio/fields/TimingFields';
import CaptionList from '@feature/studio/setting/caption/CaptionList';

import ColorPicker from '@components/atoms/ColorPicker';
import AssetSourceInput from '@components/controls/AssetSourceInput';
import InputNumber from '@components/controls/InputNumber';
import InputRange from '@components/controls/InputRange';
import FontSelect from '@components/fonts/FontSelect';
import OverrideMenu from '@components/mergeFields/OverrideMenu';

import { clipSettingsSelectorFamily } from '@store/EditSelectors';

import { CAPTION_COLOR, CAPTION_FONT_SIZE } from '@constants/TextAssetDefaults';

const MIN_FONT_SIZE = 1;

const POSITION_BUTTON_MAP = [
  'topLeft',
  'top',
  'topRight',
  'left',
  'center',
  'right',
  'bottomLeft',
  'bottom',
  'bottomRight',
];

const textAlignHorizontalOptions = [
  { value: 'left', icon: <IconTextAlignLeft size={18} /> },
  { value: 'center', icon: <IconTextAlignCenter size={18} /> },
  { value: 'right', icon: <IconTextAlignRight size={18} /> },
];

const textAlignVerticalOptions = [
  { value: 'top', icon: <IconAlignTop size={18} /> },
  { value: 'center', icon: <IconAlignCenterVertically size={18} /> },
  { value: 'bottom', icon: <IconAlignBottom size={18} /> },
];

const assetPropertyTransform = (prop, type, value) => {
  if (['number', 'range'].includes(type) && !prop.includes('font:size')) {
    return value ? parseFloat(value) : undefined;
  }
  return value || undefined;
};

function SettingAssetCaption({ id }) {
  const [clip, setClip] = useRecoilState(clipSettingsSelectorFamily(id));
  const { showArticle } = useIntercom();

  const handleAssetUpdate =
    ({ prop, value }) =>
    (event) => {
      const { type, value: eventValue } = event.target;
      setClip({ [prop]: assetPropertyTransform(prop, type, value ?? eventValue) });
    };

  const handleEmptyFontSize = (event) => {
    const { value } = event.target;
    if (!value) {
      setClip({ 'asset:font:size': MIN_FONT_SIZE });
    }
  };

  const handlePositionUpdate = (position) => {
    setClip({ position: clip?.position === position ? undefined : position });
  };

  return (
    <>
      <AssetSourceInput
        type="caption"
        accepts=".srt,.vtt"
        clip={clip}
        uploadEnabled={false}
        aliasEnabled={true}
        linkUploadEnabled={false}
        requestOptions={{
          filename: `${uuid()}.srt`,
        }}
      />
      <Button variant="secondary" className="mt-1 mb-2 w-100" onClick={() => showArticle(9875442)}>
        How do I create automated captions?
      </Button>
      <div className="mb-10">
        {!clip['asset:meta']?.placeholder && (
          <CaptionList captions={clip['asset:meta']?.captions} trim={clip['asset:trim']} />
        )}
      </div>

      <h6>Font</h6>
      <div className="mb-10">
        <FontSelect id={id} />

        <Row className="mb-2">
          <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
            <InputNumber
              id="fontSize"
              title="Font Size"
              handleBlur={handleEmptyFontSize}
              handleChange={handleAssetUpdate({ prop: 'asset:font:size' })}
              prefix={<IconFontSize />}
              placeholder={CAPTION_FONT_SIZE}
              min={1}
              suffix="px"
              value={clip['asset:font:size']}
            />
            <OverrideMenu path="asset:font:size" />
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
            <InputNumber
              id="lineHeight"
              title="Line Height"
              placeholder="1"
              min={0}
              max={10}
              step={0.1}
              handleChange={handleAssetUpdate({ prop: 'asset:font:lineHeight' })}
              prefix={<IconLineHeight size={18} />}
              value={clip['asset:font:lineHeight']}
            />
            <OverrideMenu path="asset:font:lineHeight" />
          </Col>
        </Row>

        <Row className="mt-6">
          <Col sm={6}>
            <h6>Alignment</h6>
          </Col>
          <Col sm={6}>
            <h6>Anchor</h6>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col sm={6}>
            <Row>
              <Col className="d-flex align-items-center justify-content-between gap-2 mb-4">
                <ButtonGroup className="w-100">
                  {textAlignHorizontalOptions.map(({ value, icon }) => (
                    <ToggleButton
                      key={value}
                      id={`textAlignHorizontal-${value}`}
                      type="radio"
                      name="alignment:horizontal"
                      value={value}
                      checked={
                        clip['asset:alignment:horizontal']
                          ? clip['asset:alignment:horizontal'] === value
                          : value === 'center'
                      }
                      onChange={handleAssetUpdate({ prop: 'asset:alignment:horizontal', value })}
                    >
                      {icon}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
                <OverrideMenu path="asset:alignment:horizontal" />
              </Col>
              <Col className="d-flex align-items-center justify-content-between gap-2">
                <ButtonGroup>
                  {textAlignVerticalOptions.map(({ value, icon }) => (
                    <ToggleButton
                      key={value}
                      id={`textAlignVertical-${value}`}
                      type="radio"
                      name="alignment:vertical"
                      checked={value === 'center'}
                      disabled
                    >
                      {icon}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </Col>
            </Row>
          </Col>
          <Col sm={6} className="d-flex align-items-top justify-content-between gap-2">
            <div className="position-buttons">
              {POSITION_BUTTON_MAP.map((position) => (
                <Button
                  key={position}
                  variant="light"
                  className={cn('position-button', { active: clip.position === position })}
                  onClick={() => handlePositionUpdate(position)}
                />
              ))}
            </div>
            <OverrideMenu path="asset:position" />
          </Col>
        </Row>
      </div>

      <h6>Color</h6>
      <div className="mb-10">
        <Row className="mb-2">
          <Col className="d-flex align-items-center justify-content-between gap-2">
            <InputGroup>
              <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-font-color`}>Font Color</Tooltip>}>
                <InputGroup.Text className="prefix">
                  <IconFormatColorText size={18} />
                </InputGroup.Text>
              </OverlayTrigger>
              <ColorPicker
                className="color"
                value={clip['asset:font:color'] || CAPTION_COLOR}
                handleEvent={handleAssetUpdate({ prop: 'asset:font:color' })}
              />
            </InputGroup>
            <OverrideMenu path="asset:font:color" />
          </Col>
        </Row>

        <Row className="mb-6">
          <Col sm={12} className="d-flex align-items-center justify-content-between gap-2">
            <InputRange
              id="fontOpacity"
              title="Font Opacity"
              value={clip['asset:font:opacity'] >= 0 ? clip['asset:font:opacity'] : 1}
              placeholder="1"
              min={0}
              max={1}
              step={0.01}
              prefix={<IconTransparent size={18} />}
              handleChange={handleAssetUpdate({ prop: 'asset:font:opacity' })}
            />
            <OverrideMenu path="asset:font:opacity" />
          </Col>
        </Row>
      </div>
      <h6>Stroke</h6>
      <div className="mb-10">
        <Row className="mb-2">
          <Col sm={12} className="d-flex align-items-center justify-content-between gap-2">
            <InputGroup>
              <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-stroke-color`}>Stroke Color</Tooltip>}>
                <InputGroup.Text className="prefix">
                  <button
                    type="button"
                    name="asset:stroke:color"
                    className="unstyled"
                    disabled={!clip['asset:stroke:color']}
                    onClick={handleAssetUpdate({ prop: 'asset:stroke:color' })}
                  >
                    {clip['asset:stroke:color'] ? (
                      <IconFormatColorReset size={18} title="Transparent Stroke Color" />
                    ) : (
                      <IconStroke size={18} title="Stroke Color" />
                    )}
                  </button>
                </InputGroup.Text>
              </OverlayTrigger>

              <ColorPicker
                name="asset:stroke:color"
                className="color"
                value={clip['asset:stroke:color']}
                handleEvent={handleAssetUpdate({ prop: 'asset:stroke:color' })}
              />
            </InputGroup>
            <OverrideMenu path="asset:stroke:color" />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col className="d-flex align-items-center justify-content-between gap-2">
            <InputRange
              id="stroke-width"
              title="Stroke Width"
              value={clip['asset:stroke:width'] || 0}
              placeholder="0"
              min={0}
              max={5}
              step={1}
              prefix={<IconStrokeWidth size={18} />}
              handleChange={handleAssetUpdate({ prop: 'asset:stroke:width' })}
            />
            <OverrideMenu path="asset:stroke:width" />
          </Col>
        </Row>
      </div>

      <h6>Background</h6>
      <div className="mb-10">
        <Row className="mb-2">
          <Col sm={12} className="d-flex align-items-center justify-content-between gap-2">
            <InputGroup>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-background-color`}>Background Color</Tooltip>}
              >
                <InputGroup.Text className="prefix">
                  <button
                    type="button"
                    name="asset:background:color"
                    className="unstyled"
                    disabled={!clip['asset:background:color']}
                    onClick={handleAssetUpdate({ prop: 'asset:background:color' })}
                  >
                    {clip['asset:background:color'] ? (
                      <IconFormatColorReset size={18} title="Transparent Background Color" />
                    ) : (
                      <IconFormatColorFill size={18} title="Background Color" />
                    )}
                  </button>
                </InputGroup.Text>
              </OverlayTrigger>

              <ColorPicker
                name="asset:background:color"
                className="background"
                value={clip['asset:background:color']}
                handleEvent={handleAssetUpdate({ prop: 'asset:background:color' })}
              />
            </InputGroup>
            <OverrideMenu path="asset:background:color" />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col sm={12} className="d-flex align-items-center justify-content-between gap-2">
            <InputRange
              id="backgroundOpacity"
              title="Background Opacity"
              value={clip['asset:background:opacity'] >= 0 ? clip['asset:background:opacity'] : 1}
              placeholder="1"
              min={0}
              max={1}
              step={0.01}
              prefix={<IconTransparent size={18} />}
              handleChange={handleAssetUpdate({ prop: 'asset:background:opacity' })}
            />
            <OverrideMenu path="asset:background:opacity" />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col className="d-flex align-items-center justify-content-between gap-2">
            <InputRange
              id="backgroundPadding"
              title="Background Padding"
              value={clip['asset:background:padding']}
              min={0}
              step={1}
              placeholder="0"
              prefix={<IconPadding size={18} />}
              handleChange={handleAssetUpdate({ prop: 'asset:background:padding' })}
            />
            <OverrideMenu path="asset:background:padding" />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col className="d-flex align-items-center justify-content-between gap-2">
            <InputRange
              id="backgroundBorderRadius"
              title="Background Radius"
              value={clip['asset:background:borderRadius'] || 0}
              placeholder="0"
              min={0}
              max={100}
              step={1}
              prefix={<IconBorderRadius size={18} />}
              handleChange={handleAssetUpdate({ prop: 'asset:background:borderRadius' })}
            />
            <OverrideMenu path="asset:background:borderRadius" />
          </Col>
        </Row>
      </div>

      <h6>Trim</h6>
      <div className="mb-10">
        <Row className="mb-2">
          <Col md={12} className="d-flex align-items-center justify-content-between gap-2">
            <InputNumber
              id="trim"
              title="Trim (seconds)"
              placeholder={0}
              handleChange={handleAssetUpdate({ prop: 'asset:trim' })}
              prefix={<IconTrimStart size={18} />}
              min={0}
              value={clip['asset:trim']}
            />
            <OverrideMenu path="asset:trim" />
          </Col>
        </Row>
      </div>

      <h6>Timing</h6>
      <TimingFields id={id} />
    </>
  );
}

export default SettingAssetCaption;
