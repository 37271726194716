import { Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';

import InputNumberElement from '@components/atoms/InputNumberElement';

import camelCase from '@utils/text/camelCase';

function InputNumber({
  id,
  label,
  title,
  value,
  name,
  handleBlur,
  handleChange,
  placeholder,
  min,
  max,
  showLabel = true,
  step = 1,
  prefix,
  suffix,
  tracking,
  disabled = false,
  Action,
  handlePrefixMouseDown,
}) {
  const inputId = id || camelCase(label);

  return (
    <Form.Group controlId={inputId} className="form-group">
      {(label || Action) && (
        <div className="d-flex align-items-center justify-content-between">
          <div>{showLabel === true && <Form.Label>{label}</Form.Label>}</div>
          <div>{Action || null}</div>
        </div>
      )}
      <InputGroup>
        {prefix && (
          <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${inputId}`}>{title}</Tooltip>}>
            <InputGroup.Text
              className="prefix"
              onMouseDown={handlePrefixMouseDown}
              // style={{ cursor: disabled ? 'default' : 'ew-resize' }}
            >
              <span style={{ userSelect: 'none' }}>{prefix}</span>
            </InputGroup.Text>
          </OverlayTrigger>
        )}
        <InputNumberElement
          name={name}
          value={value}
          handleBlur={handleBlur}
          handleChange={handleChange}
          placeholder={placeholder}
          min={min}
          max={max}
          step={step}
          tracking={tracking}
          disabled={disabled}
        />
        {suffix?.length > 0 && <InputGroup.Text className="suffix">{suffix}</InputGroup.Text>}
      </InputGroup>
    </Form.Group>
  );
}

export default InputNumber;
