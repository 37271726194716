import { jsonParse } from '@utils/jsonParse';

export const getErrorMessage = (error) => {
  try {
    const errorData = jsonParse(error.request.responseText);
    const errorSubtitle = '<strong>Saving the workflow encountered the following errors:</strong>';
    const errorMessages = errorData.errors.map(({ detail }) => `<li>${detail}</li>`);
    const errors = `<ul>${errorMessages.join('')}</ul>`;
    return [errorSubtitle, errors].join('');
  } catch (e) {
    console.error('WorkflowSave getErrorMessage: ', error);
    return error.message;
  }
};
