/**
 * @type {(start: number, end: number, progress: number) => number}
 */
export const easeInInterpolation = (start, end, progress) => {
  const easeInCubic = progress ** 3;
  return start + easeInCubic * (end - start);
};

/**
 * @type {(start: number, end: number, progress: number) => number}
 */
export const easeOutInterpolation = (start, end, progress) => {
  const easeOutCubic = 1 - (1 - progress) ** 3;
  return start + easeOutCubic * (end - start);
};
