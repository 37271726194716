import { IconCaptions } from '@assets/icons';

import Asset from '@components/asset/Asset';

function AssetCaption() {
  return (
    <Asset icon={<IconCaptions />}>
      <IconCaptions size={16} color="#212529" className="p-2" />
    </Asset>
  );
}

export default AssetCaption;
