import { jsonParse } from '@utils/jsonParse';

type PreTransformResponse = (response: string, data?: unknown) => unknown;
type PostTransformResponse = {
  data?: object;
  response?: object;
};

export const transformResponseAxiosDefault: PreTransformResponse[] = [
  (response) => {
    // Return empty object if response is an empty string
    if (typeof response === 'string' && response.length === 0) {
      return {};
    }

    // Parse the response
    const parsed = jsonParse(response);

    // If not an object, return the original response
    if (typeof parsed !== 'object' || parsed === null) {
      return response;
    }

    // Extract data and responseData
    const { data, response: responseData } = parsed as PostTransformResponse;

    // Return data if present, otherwise responseData or the entire parsed object
    return data ?? responseData ?? parsed;
  },
];
