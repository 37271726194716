const pad = (num, size) => {
  return `000${num}`.slice(size * -1);
};

const formatTimecode = (timeCode, segments = 1) => {
  let time = '0.00';
  if (timeCode && !Number.isNaN(timeCode)) {
    time = parseFloat(timeCode).toFixed(2);
  }

  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor(time / 60) % 60;
  const seconds = Math.floor(time - minutes * 60);
  const milliseconds = time.slice(-3);

  if (hours || segments === 4) {
    return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}:${pad(milliseconds, 2)}`;
  }

  if (minutes || segments === 3) {
    return `${pad(minutes, 2)}:${pad(seconds, 2)}:${pad(milliseconds, 2)}`;
  }

  return `${pad(seconds, 2)}:${pad(milliseconds, 2)}`;
};

export default formatTimecode;
