import { Deserializer } from 'jsonapi-serializer';

type JsonApiData = {
  attributes?: Record<string, unknown>;
  [key: string]: unknown;
};

export const ApiDeserializer = new Deserializer({ keyForAttribute: (key: string) => key });

export const deserialize = async <T = unknown>(data: JsonApiData | JsonApiData[]): Promise<T> => {
  const processData = async (item: JsonApiData): Promise<T> => {
    if (!item?.attributes) {
      return item as T;
    }

    try {
      const deserialized = await ApiDeserializer.deserialize({ data: item });
      return deserialized as T;
    } catch (error) {
      console.error('Error deserializing response', error);
      return item as T;
    }
  };

  if (Array.isArray(data)) {
    return Promise.all(data.map(processData)) as Promise<T>;
  } else {
    return processData(data);
  }
}; 