import { useRecoilValue } from 'recoil';

import InputText from '@components/controls/InputText';

import { mergeFamily } from '@store/Merge';

function SettingMergeField({ id, handleUpdateMergeField }) {
  const mergeField = useRecoilValue(mergeFamily(id));

  const handleBlur = (key) => (event) => {
    const { value } = event.target;
    const update = {
      ...mergeField,
      [key]: value,
    };

    handleUpdateMergeField(id, update);
  };

  return (
    <div className="welcome__merge-field">
      <div className="popover-controls__row">
        <div>
          <InputText
            label="Key"
            placeholder="e.g. MY_IMAGE"
            defaultValue={mergeField.find || ''}
            handleBlur={handleBlur('find')}
            disabled
          />
        </div>
        <div>
          <InputText label="Value" defaultValue={mergeField.replace || ''} handleBlur={handleBlur('replace')} />
        </div>
      </div>
    </div>
  );
}

export default SettingMergeField;
