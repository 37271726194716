import config from '@config';
import axios from 'axios';
import _ from 'lodash-es';
import { useCallback, useRef, useState } from 'react';
import { ButtonGroup, ButtonToolbar, Form, InputGroup } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from 'recoil';

import { IconChevronLeft, IconLink } from '@assets/icons';

import { transformProbeAxios } from '@api/transform/probe';

import ButtonElement from '@components/atoms/ButtonElement';

import { authTokenAtom, stageAtom } from '@store/Auth';
import { mediaUploadingAtom } from '@store/Media';

import useAxiosPoll from '@hooks/useAxiosPoll';
import useGetMediaDimensions from '@hooks/useGetMediaDimensions';

import { RENDITION_WIDTH } from '@constants/Uploads';

const UPLOAD_STATUS_READY = 'ready';
const UPLOAD_STATUS_FAILED = 'failed';
const UPLOAD_FILE_ENDPOINT = 'upload';
const UPLOAD_LINK_ENDPOINT = 'sources';

const UPLOAD_REQUEST_URL = `${config.ingest.url}${UPLOAD_FILE_ENDPOINT}`;
const UPLOAD_LINK_URL = `${config.ingest.url}${UPLOAD_LINK_ENDPOINT}`;
const UPLOAD_POLLING_INTERVAL = 2000;

const getFileTypeFromUrl = (url) => {
  try {
    const extension = url.split('.').pop();
    let type;

    switch (extension) {
      case 'jpeg':
      case 'jpg':
      case 'png':
      case 'gif':
        type = 'image';
        break;

      case 'mp4':
      case 'mov':
      case 'avi':
      case 'mkv':
        type = 'video';
        break;

      case 'mp3':
      case 'wav':
        type = 'audio';
        break;
    }

    return type;
  } catch (error) {
    return null;
  }
};

const probeUrl = async (src) => {
  const url = `https://api.shotstack.io/v1/probe/${encodeURIComponent(src)}`;
  const { data } = await axios.get(url, { transformResponse: transformProbeAxios });
  return data;
};

const getFileTypeFromProbe = async (src) => {
  try {
    const probe = await probeUrl(src);
    const { format_name: format } = probe?.attributes || {};

    if (/image|jpeg|jpg|png|gif/.test(format)) {
      return 'image';
    }
    if (/video|mp4|mov|avi|mkv/.test(format)) {
      return 'video';
    }
    if (/audio|mp3|wav/.test(format)) {
      return 'audio';
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getVideoRenditionConfig = ({ width }) => ({
  format: 'mp4',
  keyframeInterval: 25,
  size: {
    width: _.isNumber(width) ? Math.min(width, RENDITION_WIDTH) : RENDITION_WIDTH,
  },
});

const getImageRenditionConfig = ({ width }) => ({
  format: 'webp',
  size: {
    width: _.isNumber(width) ? Math.min(width, RENDITION_WIDTH) : RENDITION_WIDTH,
  },
});

const getAudioRenditionConfig = () => ({
  format: 'mp3',
  quality: 75,
});

const renditionConfigMap = {
  video: getVideoRenditionConfig,
  image: getImageRenditionConfig,
  audio: getAudioRenditionConfig,
};

const getRequestOptions = ({ type, options }) => {
  const getRenditionConfig = renditionConfigMap[type];
  if (!getRenditionConfig) {
    return null;
  }

  const rendition = getRenditionConfig(options);

  return {
    outputs: {
      renditions: [{ ...rendition, filename: 'shotstack-proxy' }],
    },
  };
};

function MediaSourceInput({ onUploadComplete }) {
  const fileInput = useRef(null);
  const token = useRecoilValue(authTokenAtom);
  const stage = useRecoilValue(stageAtom);
  const { startPolling } = useAxiosPoll();
  const { getMediaDimensions } = useGetMediaDimensions();

  const [isUploading, setIsUploading] = useRecoilState(mediaUploadingAtom);
  const [uploadError, setUploadError] = useState();
  const [uploadStatus, setUploadStatus] = useState('Upload');
  const [uploadViaLink, setUploadViaLink] = useState(false);
  const [uploadLink, setUploadLink] = useState('');

  const requestHeaders = {
    headers: {
      Authorization: `Bearer ${token}`,
      stage,
    },
  };

  const onPollCheck = (response) => {
    const {
      attributes: {
        status,
        error,
        outputs: {
          renditions: [proxy],
        },
      },
    } = response.data.data;

    if (status === UPLOAD_STATUS_FAILED || proxy?.status === UPLOAD_STATUS_FAILED) {
      throw new Error(error);
    }

    return [status, proxy?.status].every((s) => [UPLOAD_STATUS_READY, UPLOAD_STATUS_FAILED].includes(s));
  };

  const getStatus = async ({ sourceId }) => {
    const url = `${config.ingest.url}sources/${sourceId}`;
    return startPolling({
      url,
      config: requestHeaders,
      interval: UPLOAD_POLLING_INTERVAL,
      onPollCheck,
    });
  };

  const getFileUploadAttrs = async ({ data }) => {
    const response = await axios.post(UPLOAD_REQUEST_URL, data, requestHeaders);
    const { attributes } = response.data.data;
    if (!attributes?.id) {
      throw new Error('Upload request failed. No attribute id returned.');
    }
    return attributes;
  };

  const uploadFile = ({ sourceUrl, file }) =>
    axios.put(sourceUrl, file, {
      headers: {
        'Content-Type': '',
      },
    });

  const ingestURL = async (requestOptions) => {
    const response = await axios.post(UPLOAD_LINK_URL, requestOptions, requestHeaders);
    const { id: sourceId } = response.data.data;
    if (!sourceId) {
      throw new Error('Upload request failed. No source id returned');
    }
    return response.data.data;
  };

  const getFileType = async (url) => {
    try {
      let fileTypeFromUrl = getFileTypeFromUrl(url);
      if (!fileTypeFromUrl) {
        fileTypeFromUrl = await getFileTypeFromProbe(url);
      }
      return fileTypeFromUrl;
    } catch (error) {
      console.error(error);
      throw new Error('Unsupported file type');
    }
  };

  const handleBrowseFiles = (e) => {
    e.stopPropagation();
    setUploadError();
    setUploadStatus('Upload');
    setIsUploading(false);
    fileInput.current.click();
  };
  const handleFileChange = useCallback(async () => {
    const [file] = fileInput.current?.files || [];
    if (!file) return;

    if (file.type.includes('webm')) {
      setUploadError('WebM files are not supported');
      return;
    }

    setIsUploading(true);
    setUploadStatus('Uploading...');

    try {
      const [type] = file.type.split('/');
      const options = await getMediaDimensions(file);
      const requestOptions = getRequestOptions({ type, options });
      if (!requestOptions) throw new Error('Unsupported file type');

      const { id: sourceId, url: sourceUrl } = await getFileUploadAttrs({ data: requestOptions });
      await uploadFile({ sourceUrl, file });

      setUploadStatus('Processing...');

      const response = await getStatus({ sourceId });
      onUploadComplete({ ...response.data.data.attributes, type });
    } catch (error) {
      console.error(error);
      setUploadError('Sorry, there was a problem uploading your file.');
    } finally {
      setUploadStatus('Upload');
      setIsUploading(false);
      // Reset the file input value to allow selecting the same file again
      if (fileInput.current) {
        fileInput.current.value = '';
      }
    }
  }, [fileInput, getMediaDimensions, getFileUploadAttrs, uploadFile, getStatus, onUploadComplete]);

  const handleLinkSubmit = useCallback(async () => {
    if (!uploadLink) {
      return;
    }
    setUploadError();
    setIsUploading(true);
    setUploadStatus('Fetching...');

    try {
      const type = await getFileType(uploadLink);
      const options = await getMediaDimensions(uploadLink, type);
      const requestOptions = getRequestOptions({ type, options });
      if (!requestOptions) throw new Error('Unsupported file type');

      const { id: sourceId } = await ingestURL({ ...requestOptions, url: uploadLink });

      setUploadStatus('Processing...');

      const response = await getStatus({ sourceId });
      onUploadComplete({ ...response.data.data.attributes, type });
    } catch (error) {
      console.error(error);
      setUploadError(`Sorry, there was a problem getting the requested file. ${error.message}`);
    } finally {
      setUploadLink('');
      setUploadStatus('Upload');
      setIsUploading(false);
      setUploadViaLink(false);
    }
  }, [uploadLink, getFileType, getMediaDimensions, ingestURL, getStatus, onUploadComplete]);

  const handleToggleLinkUpload = () => {
    setUploadViaLink(!uploadViaLink);
  };

  const handleLinkURLChange = (e) => {
    setUploadLink(e.target.value);
  };

  if (uploadViaLink) {
    return (
      <div className="asset-upload">
        <div className="asset-upload__panel">
          <ButtonElement handleEvent={handleToggleLinkUpload} variant="link" disabled={isUploading}>
            <IconChevronLeft size={20} /> <span>Back to file upload</span>
          </ButtonElement>

          <InputGroup className="mt-2">
            <Form.Control placeholder="Paste your URL here.." onChange={handleLinkURLChange} />
            <ButtonElement handleEvent={handleLinkSubmit} variant="primary" disabled={!uploadLink || isUploading}>
              {uploadStatus}
            </ButtonElement>
          </InputGroup>
          {uploadError && <p className="input-error centered">{uploadError}</p>}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="asset-upload">
        <div className="asset-upload__panel">
          <ButtonToolbar>
            <ButtonGroup className="asset-upload__group">
              <ButtonElement
                handleEvent={handleBrowseFiles}
                variant="primary"
                loading={isUploading}
                disabled={isUploading}
                className="asset-upload__button"
              >
                {uploadStatus}
              </ButtonElement>
              <div className="vr" />
              <ButtonElement handleEvent={handleToggleLinkUpload} variant="light" disabled={isUploading}>
                <IconLink size={20} />
              </ButtonElement>
            </ButtonGroup>
          </ButtonToolbar>
          <input
            ref={fileInput}
            type="file"
            accept="image/*,audio/*,video/mp4,video/quicktime,video/avi,video/mkv"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          {uploadError && <p className="input-error centered mt-2">{uploadError}</p>}
        </div>
      </div>
    </>
  );
}
export default MediaSourceInput;
