import { Col, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { useRecoilState, useRecoilValue } from 'recoil';

import WorkflowModuleName from '@feature/workflows/module/WorkflowModuleName';
import WorkflowModuleOutputs from '@feature/workflows/module/WorkflowModuleOutputs';
import WorkflowPanelHeader from '@feature/workflows/panel/WorkflowPanelHeader';
import WorkflowPanelMessage from '@feature/workflows/panel/WorkflowPanelMessage';

import InputNumber from '@components/controls/InputNumber';

import {
  activeTaskAtom,
  taskInputsAtomFamily,
  taskOptionsAtomFamily,
  workflowDataListSelector,
} from '@store/Workflows';

import { noOptionsMessage } from '@constants/Workflows';

import { isNumber } from '@utils/isNumber';

function WorkflowModuleTtiStabilityAi() {
  const activeTask = useRecoilValue(activeTaskAtom);
  const [taskInputs, setTaskInputs] = useRecoilState(taskInputsAtomFamily(activeTask));
  const [taskOptions, setTaskOptions] = useRecoilState(taskOptionsAtomFamily(activeTask));
  const { list, grouped } = useRecoilValue(workflowDataListSelector);

  const getSelectedValue = (data, key) => {
    return data.find(({ value }) => value === key) || null;
  };

  const handleOptionsChange = (field) => (e) => {
    const { value } = e.target;
    const valueInt = parseInt(value, 10);
    const newValue = isNumber(valueInt) ? valueInt : '';
    setTaskOptions((prevState) => ({ ...prevState, [field]: newValue }));
  };

  const handlePromptChange = ({ value }) => {
    setTaskInputs((prevState) => ({ ...prevState, prompt: value }));
  };

  return (
    <>
      <div className="form-container">
        <WorkflowModuleName />
      </div>
      <div className="form-container sm">
        <WorkflowPanelHeader
          name="Configuration"
          description="The configuration to generate an image using StabilityAi."
        />
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Prompt</label>
          </Col>
          <Col>
            <ReactSelect
              unstyled
              className="react-select__inputs form-control"
              classNamePrefix="react-select"
              id="prompt"
              name="prompt"
              placeholder="Select input"
              noOptionsMessage={noOptionsMessage('data')}
              defaultValue={getSelectedValue(list, taskInputs?.prompt)}
              options={grouped}
              onChange={handlePromptChange}
            />
          </Col>
        </Row>
        <hr />
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Width</label>
          </Col>
          <Col>
            <InputNumber
              id="width"
              name="width"
              min={16}
              max={2048}
              value={taskOptions.width}
              placeholder="1024"
              handleChange={handleOptionsChange('width')}
            />
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Height</label>
          </Col>
          <Col>
            <InputNumber
              id="height"
              name="height"
              min={16}
              max={2048}
              value={taskOptions.height}
              placeholder="1024"
              handleChange={handleOptionsChange('height')}
            />
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col xs={4}>
            <label>Steps</label>
          </Col>
          <Col>
            <InputNumber
              id="steps"
              name="steps"
              min={0}
              value={taskOptions.steps}
              placeholder="50"
              handleChange={handleOptionsChange('steps')}
            />
          </Col>
        </Row>

        <WorkflowPanelMessage
          name="API KEYS"
          message="Add your Stability AI API keys in the <a href='/integrations/stability-ai' target='_blank' rel='noopener'>integrations page</a> for this module to work."
        />
      </div>
      <div className="form-container">
        <WorkflowModuleOutputs
          name="Output"
          description="This action generates an image file and outputs the file URL."
        />
      </div>
    </>
  );
}

export default WorkflowModuleTtiStabilityAi;
