import { atom, selectorFamily } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { FEATURE_FLAGS } from '@constants/FeatureFlags';

const { persistAtom } = recoilPersist();

type ErrorState = {
  type: 'error' | 'warning' | 'info';
  title: string;
  message: string;
};

type StudioTab = 'design' | 'connect' | 'automate' | null;

type FeatureFlags = typeof FEATURE_FLAGS;

/**
 * Global error state atom
 * Used to display error messages across the application
 */
export const errorAtom = atom<ErrorState>({
  key: 'errorAtom',
  default: {
    type: 'error',
    title: '',
    message: '',
  },
});

/**
 * Active tab in the studio workspace
 * Used by WorkspaceTopbar to track which tab is currently active
 */
export const studioActiveTabAtom = atom<StudioTab>({
  key: 'studioActiveTabAtom',
  default: null,
});

/**
 * Hovered tab in the studio workspace
 * Used by WorkspaceTopbar to track which tab is being hovered
 */
export const studioActiveTabHoverAtom = atom<StudioTab>({
  key: 'studioActiveTabHoverAtom',
  default: null,
});

/**
 * Controls visibility of the template configuration panel
 * Used by TemplateConfiguration component
 */
export const showTemplateConfigurationAtom = atom<boolean>({
  key: 'showTemplateConfigurationAtom',
  default: false,
});

/**
 * List of dismissed items (e.g., notifications, tooltips)
 * Persisted to localStorage
 */
export const dismissibleAtom = atom<string[]>({
  key: 'dismissible/atom',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

/**
 * Feature flags state
 * Used by FeatureFlagToggle component to control feature visibility
 */
export const featureFlagsAtom = atom<FeatureFlags>({
  key: 'featureFlagsAtom',
  default: FEATURE_FLAGS,
});

/**
 * Selector family for checking if a feature flag is enabled
 */
export const featureFlagEnabledSelectorFamily = selectorFamily<boolean, keyof FeatureFlags>({
  key: 'features',
  get:
    (flag) =>
    ({ get }) => {
      const features = get(featureFlagsAtom);
      return features[flag] === true;
    },
});

/**
 * Selector family for checking if an item has been dismissed
 */
export const dismissibleSelector = selectorFamily<boolean, string>({
  key: 'dismissible/selector',
  get:
    (id) =>
    ({ get }) =>
      get(dismissibleAtom).includes(id),
  set:
    (id) =>
    ({ set }) => {
      set(dismissibleAtom, (prevItems) =>
        prevItems.includes(id) ? prevItems.filter((item) => item !== id) : [...prevItems, id]
      );
    },
});
