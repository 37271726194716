import { http } from '@snippets/http';
import { nodeaxios } from '@snippets/node-axios';
import { nodesdk } from '@snippets/node-sdk';
import { phpsdk } from '@snippets/php-sdk';
import { pythonsdk } from '@snippets/python-sdk';
import { rubysdk } from '@snippets/ruby-sdk';

const languageSnippetMap = {
  http,
  nodesdk,
  nodeaxios,
  pythonsdk,
  phpsdk,
  rubysdk,
};

export const getSnippet = ({ data, language }) => {
  const snippetFn = languageSnippetMap[language];
  return snippetFn ? snippetFn(data) : '';
};
