import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import '@css/TemplateGalleryCard.css';

import { useAnalytics } from '@components/analytics/Analytics';

function WorkflowRecipeGalleryCard({ baseUrl, data, withDetails = false }) {
  const { id, heading, thumbnail, description, tags } = data;

  const { trackEvent } = useAnalytics();
  const history = useNavigate();

  const handleViewDetails = () => {
    trackEvent('Select Suggested Workflow', {
      Context: 'Thumbnail Link',
      Id: id,
      'Workflow Name': heading,
    });

    history(`${baseUrl}?browse=open&workflowId=${id}`);
  };

  return (
    <>
      <div className="template-gallery__card-wrapper">
        <Card className="template-gallery__card" onClick={handleViewDetails}>
          <img src={thumbnail} alt={heading} className="template-gallery__thumbnail" />
        </Card>
      </div>
      <h6 className="template-gallery__title link" onClick={handleViewDetails}>
        {heading}
      </h6>
      {withDetails && (
        <div className="template-gallery__details">
          <p>
            <small>{description}</small>
          </p>
          <div className="d-flex align-items-center gap-2 mb-2">
            <div className="tag">{tags}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default WorkflowRecipeGalleryCard;
