import { ASSET_TYPES_MASK } from '@constants/AssetTypes';

const isNegativeNumber = (num) => {
  const sign = Math.sign(num);
  return typeof num === 'number' && (sign === -1 || Object.is(sign, -0));
};

export const checkRangeOverlap = (clip, ref) => {
  const clipStart = clip.start;
  const clipEnd = clip.start + clip.length;
  const refStart = ref.start;
  const refEnd = ref.start + ref.length;

  if (clipStart >= refEnd || clipEnd <= refStart) {
    return { isIntersecting: false };
  }

  const diff = {
    start: clipStart - refStart,
    end: clipEnd - refEnd,
  };

  const intersection = {
    exact: diff.start === 0 && diff.end === 0,
    whole: isNegativeNumber(diff.start) && !isNegativeNumber(diff.end),
    start: isNegativeNumber(diff.start) && isNegativeNumber(diff.end),
    end: !isNegativeNumber(diff.start) && !isNegativeNumber(diff.end),
    middle: !isNegativeNumber(diff.start) && isNegativeNumber(diff.end),
  };

  return {
    diff,
    intersection,
    isIntersecting: Object.values(intersection).some(Boolean),
  };
};

const isIntersectingAnyInTrack = (clip, track) => {
  if (ASSET_TYPES_MASK.includes(clip['asset:type'])) {
    return false;
  }
  const trackFiltered = track.filter((c) => !ASSET_TYPES_MASK.includes(c['asset:type']));
  return trackFiltered.some((clipInTrack) => {
    const { isIntersecting } = checkRangeOverlap(clip, clipInTrack);
    return isIntersecting;
  });
};

const getSubTrackIndex = (clip, tracks) => {
  let subTrackIndex = tracks.length;
  for (let i = 0; i < tracks.length; i++) {
    if (!isIntersectingAnyInTrack(clip, tracks[i])) {
      subTrackIndex = i;
      break;
    }
  }
  return subTrackIndex;
};

const partitionTracksByOverlappingRanges = (clips) => {
  const sortedClips = clips.sort((a, b) => a.start - b.start);
  const subTrack = [[]];

  for (let i = 0, l = sortedClips.length; i < l; i++) {
    const clip = sortedClips[i];
    const subTrackIndex = getSubTrackIndex(clip, subTrack);
    subTrack[subTrackIndex] = subTrack[subTrackIndex] || [];
    subTrack[subTrackIndex].push(clip);
  }
  return subTrack;
};

export const getClipStartTime = (clips, newClip) => {
  const isMaskClip = newClip['asset:type'] === 'mask';
  const filteredClips = clips.filter(({ ['asset:type']: type }) => {
    return isMaskClip ? type === 'mask' : type !== 'mask';
  });

  if (filteredClips.length === 0) {
    return newClip.start;
  }

  const newClipDuration = newClip.start + newClip.length;
  const sortedClips = filteredClips.sort((a, b) => a.start - b.start);
  const clipTimes = sortedClips.map(({ start, length }) => ({ start, end: start + length }));

  // loop through clipTimes and compare the start time of the current clip to the end time of the previous clip and
  // break if it is greater than or equal to the newClipDuration
  for (let i = 0, l = clipTimes.length; i < l; i++) {
    const { start } = clipTimes[i];
    const previousClip = clipTimes[i - 1];
    const previousClipEnd = previousClip ? previousClip.end : 0;
    if (start - previousClipEnd >= newClipDuration) {
      return previousClipEnd;
    }
  }

  // else return the end time of the last clip
  const lastClip = clipTimes[clipTimes.length - 1];
  return lastClip.end;
};

export const reorganiseTracks = (tracks) => {
  const organisedTracks = (tracks ?? [])
    .map(({ clips }) => clips.length && partitionTracksByOverlappingRanges(clips))
    .filter(Boolean)
    .flat();
  if (organisedTracks.length === 0) {
    return [[]];
  }
  return organisedTracks;
};
