const MAX_FRAME_INTERVAL = 20;
const MAX_FRAME_WIDTH = 160;
const CAPTURE_MIMETYPE = 'image/png';
const CAPTURE_QUALITY = 0.4;

const createVideoElement = (src, type = 'video/mp4') => {
  const video = document.createElement('video');
  video.crossOrigin = 'anonymous';
  video.classList.add('video-capture');

  const source = document.createElement('source');
  source.src = src;
  source.type = type;

  video.appendChild(source);
  document.getElementById('root').appendChild(video);

  return video;
};

const captureFrames = ({ src, options }) => {
  if (src === undefined || src.endsWith('.mov')) {
    return Promise.resolve({});
  }

  const { frameRate = 1, time } = options || {};

  const videoElement = createVideoElement(src);

  return new Promise((resolve, reject) => {
    videoElement.addEventListener(
      'error',
      () => {
        resolve({});
      },
      true
    );

    videoElement.addEventListener('loadedmetadata', () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const frames = [];

      // Calculate the frame interval based on the desired frame rate
      const frameInterval = videoElement.duration / Math.min(videoElement.duration * frameRate, MAX_FRAME_INTERVAL);

      // Determine the new width and height, keeping aspect ratio
      const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
      const newWidth = Math.min(videoElement.videoWidth, MAX_FRAME_WIDTH); // limit width to 200px
      const newHeight = newWidth / aspectRatio;

      // Resize the canvas
      canvas.width = newWidth;
      canvas.height = newHeight;

      function capture() {
        try {
          context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          frames.push(canvas.toDataURL(CAPTURE_MIMETYPE, CAPTURE_QUALITY));
        } catch (e) {
          console.error('Frame capture failed: ', e);
          reject(e);
        }

        if (time === undefined && frames.length < Math.min(videoElement.duration * frameRate, MAX_FRAME_INTERVAL)) {
          // Seek to the next frame time
          videoElement.currentTime += frameInterval;
        } else {
          // We are done, remove event listener
          videoElement.removeEventListener('seeked', capture);
          videoElement.remove();

          // Stitch the frames together into a sprite sheet
          const spriteCanvas = document.createElement('canvas');
          spriteCanvas.width = newWidth * frames.length;
          spriteCanvas.height = newHeight;
          const spriteContext = spriteCanvas.getContext('2d');
          const loadImage = (frame, index) => {
            return new Promise((resolve) => {
              const img = new Image();
              img.onload = () => {
                spriteContext.drawImage(img, index * newWidth, 0, newWidth, newHeight);
                resolve();
              };
              img.src = frame;
            });
          };

          const loadImages = async () => {
            for (let i = 0; i < frames.length; i++) {
              await loadImage(frames[i], i);
            }
            resolve({
              width: newWidth,
              height: newHeight,
              count: frames.length,
              url: spriteCanvas.toDataURL(CAPTURE_MIMETYPE, CAPTURE_QUALITY),
              data: frames,
            });
          };

          loadImages();
        }
      }

      // Add event listener and start capturing frames
      videoElement.addEventListener('seeked', capture);
      videoElement.currentTime = time || 0;
    });
  });
};

export default captureFrames;
