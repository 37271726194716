import { useState } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { IconInfo } from '@assets/icons';

import ButtonElement from '@components/atoms/ButtonElement';
import SelectElement from '@components/atoms/SelectElement';

function SubscriptionListItem(params) {
  const navigate = useNavigate();
  const { subscriptions, professionalSubscriptions, subscriber, sdkUsers } = params;
  const [selectedSubPlan, setSelectedSubPlan] = useState(subscriptions?.plus200);
  const [users, setUsers] = useState({ users: 10 });
  if (!subscriptions) {
    return null;
  }

  function onSubSelectChange(event) {
    const subscription = subscriptions[event.target.value];
    setUsers(sdkUsers[event.target.value]);
    setSelectedSubPlan(subscription);
  }
  const handleSelectSubscription = () => {
    navigate(`/subscribe/${selectedSubPlan.code}`);
  };

  return (
    <Row>
      <Col sm={12}>
        <Row>
          <Col sm={12}>
            <h3 className="text-xl font-bold text-center">Subscription</h3>
          </Col>
          <Col sm={12} style={{ height: '100px' }}>
            <p className="text-center underline">
              Roll over up to 3x your monthly credit allowance as long as you&apos;re subscribed
            </p>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="overlay-example">Fully brandable and embeddable white-label video editor </Tooltip>}
            >
              <p className="text-center underline">
                Customised Studio SDK styling <IconInfo></IconInfo>
              </p>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="overlay-example">
                  Up to {users.users} unique users per month can make use of the embeddable white-label video editor
                </Tooltip>
              }
            >
              <p className="text-center underline">
                Up to {users.users} unique active users per month using the Studio SDK <IconInfo></IconInfo>
              </p>
            </OverlayTrigger>
          </Col>
          <Col sm={12}>
            <p className="text-xl text-center mt-5">
              <span className="font-bold" style={{ fontSize: '3em', color: '#25d3d0' }}>
                ${selectedSubPlan?.unitPrice?.toFixed(2) || '0.20'}
              </span>{' '}
              / minute
            </p>
          </Col>
        </Row>
        <Col className="m-auto" sm={6}>
          <>
            <SelectElement
              data-testid="select-stage"
              options={professionalSubscriptions
                .filter((subscription) => subscription)
                .map((subscription) => ({
                  value: subscription?.code || 'plus200',
                  text: `${subscription?.name} / month` || '200 Credits / month',
                  disabled: subscription?.code === subscriber?.subscription?.code,
                }))}
              selected={selectedSubPlan?.code || 'plus200'}
              handleEvent={onSubSelectChange}
            />
            <ButtonElement handleEvent={handleSelectSubscription} variant="primary" className="mt-2 w-100">
              Pay ${selectedSubPlan?.price?.toFixed(2) || '39.00'}
            </ButtonElement>
          </>
        </Col>
      </Col>
    </Row>
  );
}

export default SubscriptionListItem;
