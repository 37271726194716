import { useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useRecoilValue } from 'recoil';

import { IconQuestionCircle } from '@assets/icons';

import ButtonElement from '@components/atoms/ButtonElement';

import { templateIdAtom } from '@store/Template';

function HelpButton() {
  const { show } = useIntercom();

  const templateId = useRecoilValue(templateIdAtom);

  const tracking = {
    name: 'Select Help',
    properties: {
      Id: templateId,
    },
  };

  const handleClick = useCallback(() => {
    show();
  }, [show]);

  return (
    <ButtonElement
      title="Help"
      handleEvent={handleClick}
      tracking={tracking}
      variant="light"
      type="button"
      className="unstyled"
    >
      <IconQuestionCircle size={20} />
    </ButtonElement>
  );
}

export default HelpButton;
