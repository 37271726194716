import { createClipAnimation } from '@animation/animate';
import { debounce } from 'lodash-es';
import { useEffect, useMemo, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import CanvasEditFrame from '@feature/studio/canvas/CanvasEditFrame';
import CanvasMask from '@feature/studio/canvas/CanvasMask';
import CanvasSnapLines from '@feature/studio/canvas/CanvasSnapLines';

import { canvasStageSelectorFamily } from '@store/Canvas';
import { activeClipAtom } from '@store/Clips';
import { clipBoundsAtomFamily } from '@store/Edit';
import { clipCanvasSelectorFamily, clipTrackSelectorFamily, clipVisibilitySelectorFamily } from '@store/EditSelectors';
import { clipKeyframesForAnimationSelectorFamily } from '@store/Keyframes';
import { playheadAtom, trackLockedAtomFamily } from '@store/Timeline';

import useMovableAsset from '@hooks/useMovableAsset';

import getAssetDimensions from '@utils/getAssetDImensions';

function withCanvasMovable(CanvasComponent) {
  function WithCanvasMovable(props) {
    const { id, index, resource } = props;
    const spriteRef = useRef();
    const canvas = useRecoilValue(canvasStageSelectorFamily());
    const clip = useRecoilValue(clipCanvasSelectorFamily(id));
    const setClipBounds = useSetRecoilState(clipBoundsAtomFamily(id));
    const { trackId } = useRecoilValue(clipTrackSelectorFamily(id));
    const activeClipId = useRecoilValue(activeClipAtom);
    const visible = useRecoilValue(clipVisibilitySelectorFamily(id));
    const playhead = useRecoilValue(playheadAtom);
    const keyframes = useRecoilValue(clipKeyframesForAnimationSelectorFamily(id));
    const isLocked = useRecoilValue(trackLockedAtomFamily(trackId));

    const { assetWidth, assetHeight } = useMemo(
      () => getAssetDimensions({ clip, canvas, resource }),
      [clip, canvas, resource]
    );

    const calculatedClip = createClipAnimation({
      playhead,
      clip,
      keyframes,
      asset: { width: assetWidth, height: assetHeight }, // not sure if this is needed
    });

    const movableAssetConfig = {
      id,
      assetWidth,
      assetHeight,
      canvasWidth: canvas.width,
      canvasHeight: canvas.height,
      calculatedClip,
      spriteRef,
    };

    const movableAsset = useMovableAsset(movableAssetConfig);

    const {
      bounds,
      dimensions: { width = 1, height = 1, aspectRatio },
      isMoving,
      position: { x, y },
      scale,
      angle,
      snappingLines,
      handlePositionGrab,
      handlePositionDrop,
      handlePositionDrag,
      handleRotateGrab,
      handleRotateDrag,
      handleRotateDrop,
      handleResizeDrag,
      handleResizeDrop,
      handleResizeGrab,
      handleScaleGrab,
      handleScaleDrag,
      handleScaleDrop,
    } = movableAsset;

    const debouncedSetClip = debounce(() => setClipBounds(bounds), 2000, { trailing: true });

    useEffect(() => {
      debouncedSetClip();

      return () => {
        debouncedSetClip.cancel();
      };
    }, [bounds]);

    const canScale = useMemo(
      () => ['image', 'video', 'overlay', 'image-to-video'].includes(clip['asset:type']),
      [clip['asset:type']]
    );
    const canResize = useMemo(() => ['text', 'html', 'shape'].includes(clip['asset:type']), [clip['asset:type']]);
    const canRotate = useMemo(
      () => ['text', 'html', 'image', 'video', 'shape', 'overlay', 'image-to-video'].includes(clip['asset:type']),
      [clip['asset:type']]
    );
    const canDrag = useMemo(() => clip['asset:type'] !== 'caption', [clip['asset:type']]);
    const hasFrame = useMemo(() => !isLocked, [isLocked]);

    const isSelected = activeClipId === id;
    const opacity = calculatedClip.opacity ?? 1;

    return (
      <>
        <CanvasMask x={x} y={y} width={width + 1} height={height + 1} angle={angle} clipId={id}>
          <CanvasComponent
            ref={spriteRef}
            clip={calculatedClip}
            x={x}
            y={y}
            width={width}
            height={height}
            canvasWidth={canvas.width}
            canvasHeight={canvas.height}
            alpha={opacity}
            zIndex={index}
            angle={angle}
            scale={scale}
            visible={visible}
            aspectRatio={aspectRatio}
            resource={resource}
          />
        </CanvasMask>

        {hasFrame && (
          <CanvasEditFrame
            bounds={bounds}
            width={width}
            height={height}
            isDragging={isMoving}
            scale={scale}
            selected={isSelected}
            inView={visible}
            zIndex={index}
            angle={angle}
            handlePositionGrab={handlePositionGrab}
            handlePositionDrag={canDrag && handlePositionDrag}
            handlePositionDrop={canDrag && handlePositionDrop}
            handleRotateGrab={canRotate && handleRotateGrab}
            handleRotateDrag={canRotate && handleRotateDrag}
            handleRotateDrop={canRotate && handleRotateDrop}
            handleResizeGrab={canResize && handleResizeGrab}
            handleResizeDrag={canResize && handleResizeDrag}
            handleResizeDrop={canResize && handleResizeDrop}
            handleScaleGrab={canScale && handleScaleGrab}
            handleScaleDrag={canScale && handleScaleDrag}
            handleScaleDrop={canScale && handleScaleDrop}
          />
        )}
        {snappingLines && <CanvasSnapLines width={canvas.width} height={canvas.height} coords={snappingLines} />}
      </>
    );
  }

  return WithCanvasMovable;
}

export default withCanvasMovable;
