import { useRecoilValue } from 'recoil';

import CanvasClip from '@feature/studio/canvas/CanvasClip';

import { trackClipIdsSelectorFamily } from '@store/EditSelectors';
import { trackVisibleAtomFamily } from '@store/Timeline';

const getClipIndex = (trackIndex, clipIndex) => {
  const index = trackIndex + clipIndex / 100;
  return index;
};

function CanvasTrack({ id, index: trackIndex }) {
  const clipIds = useRecoilValue(trackClipIdsSelectorFamily(id));
  const trackClips = clipIds.slice().reverse().filter(Boolean);
  const isVisible = useRecoilValue(trackVisibleAtomFamily(id));

  if (!isVisible) return null;

  return trackClips.map((clipId, clipIndex) => (
    <CanvasClip key={clipId} id={clipId} index={getClipIndex(trackIndex, clipIndex)} />
  ));
}

export default CanvasTrack;
