import { useRecoilValue } from 'recoil';

import { IconMinusCircle } from '@assets/icons';

import ButtonElement from '@components/atoms/ButtonElement';
import InputText from '@components/controls/InputText';

import { mergeFamily } from '@store/Merge';

function SettingMergeField({ id, handleUpdateMergeField, handleDeleteMergeField }) {
  const mergeField = useRecoilValue(mergeFamily(id));

  const handleChange = (key) => (event) => {
    const { value } = event.target;

    handleUpdateMergeField(id, {
      [key]: value,
    });
  };

  const handleMergeFieldDelete = () => {
    handleDeleteMergeField(id);
  };

  return (
    <div className={`popover-controls__row type-${mergeField?.meta?.type}`}>
      <div style={{ width: '150px' }}>
        <InputText id="find" placeholder="MY_IMAGE" value={mergeField.find || ''} handleChange={handleChange('find')} />
      </div>
      <div style={{ flexGrow: 1 }}>
        <InputText
          id="value"
          placeholder="https://my.domain.com/image.png"
          value={mergeField.replace ?? ''}
          handleChange={handleChange('replace')}
        />
      </div>
      <div>
        <ButtonElement className="unstyled mt-1" handleEvent={handleMergeFieldDelete} variant="light">
          <IconMinusCircle size={18} />
        </ButtonElement>
      </div>
    </div>
  );
}

export default SettingMergeField;
