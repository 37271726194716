import { useRecoilValue } from 'recoil';

import OffsetFields from '@feature/studio/fields/OffsetFields';
import SizeFields from '@feature/studio/fields/SizeFields';
import TimingFields from '@feature/studio/fields/TimingFields';
import TransformFields from '@feature/studio/fields/TransformFields';
import VisualEffectsFields from '@feature/studio/fields/VisualEffectsFields';

import AssetSourceInput from '@components/controls/AssetSourceInput';

import { clipSettingsSelectorFamily } from '@store/EditSelectors';

import { RENDITION_WIDTH } from '@constants/Uploads';

const requestOptions = {
  outputs: {
    renditions: [
      {
        format: 'webp',
        filename: 'shotstack-proxy',
        quality: 85,
        size: {
          width: RENDITION_WIDTH,
        },
      },
    ],
  },
  destinations: [
    {
      provider: 'shotstack',
    },
  ],
};

function SettingAssetImage({ id }) {
  const clip = useRecoilValue(clipSettingsSelectorFamily(id));

  return (
    <>
      <div className="mb-10">
        <AssetSourceInput id={id} clip={clip} requestOptions={requestOptions} type="image" accepts="image/*" />
        <VisualEffectsFields id={id} />
      </div>

      <h6>Size &amp; Position</h6>
      <div className="mb-10">
        <div className="mb-2">
          <SizeFields id={id} />
        </div>
        <OffsetFields id={id} />
      </div>

      <h6>Transform</h6>
      <div className="mb-10">
        <TransformFields id={id} />
      </div>

      <h6>Timing</h6>
      <TimingFields id={id} />
    </>
  );
}

export default SettingAssetImage;
