import { useRecoilValue } from 'recoil';

import WorkflowPanelHeader from '@feature/workflows/panel/WorkflowPanelHeader';

import {
  activeTaskAtom,
  activeWorkflowAtom,
  taskModuleAtomFamily,
  workflowDataMappingAtomFamily,
} from '@store/Workflows';

const MAPPING_DATA_MESSAGES = {
  'shotstack:csv':
    'The first row (header row) of the CSV file will be used as labels. Reference these labels in the workflow Actions to access the data.',
  default:
    'The keys from the JSON body will be used as labels. Reference these labels in the workflow Actions to access the data.',
};

function WorkflowPanelTriggerDataList() {
  const activeTask = useRecoilValue(activeTaskAtom);
  const activeWorkflow = useRecoilValue(activeWorkflowAtom);
  const workflowMapping = useRecoilValue(workflowDataMappingAtomFamily(activeWorkflow));
  const taskModule = useRecoilValue(taskModuleAtomFamily(activeTask));
  const hasData = (Object.keys(workflowMapping) || []).length > 0;

  return (
    <div className="form-container">
      <WorkflowPanelHeader
        name="Trigger Data"
        description={MAPPING_DATA_MESSAGES[taskModule] || MAPPING_DATA_MESSAGES.default}
      />

      <div className="workflow-mapping">
        <small>The following Trigger data labels have been set:</small>
        {!hasData && (
          <div className="workflow-mapping__item">
            <div>
              <strong>No data labels set.</strong>
            </div>
          </div>
        )}
        {hasData &&
          Object.keys(workflowMapping).map((key) => (
            <div key={key} className="workflow-mapping__item">
              <div>
                <strong>{key}</strong>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default WorkflowPanelTriggerDataList;
