import { useCallback, useEffect, useRef } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import WorkflowPanel from '@feature/workflows/WorkflowPanel';
import WorkflowTasks from '@feature/workflows/WorkflowTasks';

import { activeTaskAtom, useHydrateWorkflow, useNextPrevTask } from '@store/Workflows';

function WorkflowCanvas({ workflow }) {
  const activeTask = useRecoilValue(activeTaskAtom);
  const nextPrevTask = useNextPrevTask();
  const resetActiveTask = useResetRecoilState(activeTaskAtom);
  const hydrateWorkflow = useHydrateWorkflow();
  const windowRef = useRef(window);

  useEffect(() => {
    hydrateWorkflow(workflow);
  }, []);

  const handleKeyDown = useCallback(
    (event) => {
      const { key, metaKey, target } = event;
      const isInput = ['INPUT', 'TEXTAREA'].includes(target.tagName);
      if (!activeTask || metaKey || isInput) return;

      event.preventDefault();

      if (key === 'Escape') {
        resetActiveTask();
      } else if (key === 'ArrowUp') {
        nextPrevTask('prev');
      } else if (key === 'ArrowDown') {
        nextPrevTask('next');
      }
    },
    [activeTask, nextPrevTask, resetActiveTask]
  );

  useEffect(() => {
    const ref = windowRef.current;
    ref.addEventListener('keydown', handleKeyDown);

    return () => {
      ref.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className="workflow-canvas hide-scroll">
      <WorkflowTasks />
      <WorkflowPanel />
    </div>
  );
}

export default WorkflowCanvas;
