import cn from 'classnames';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import '@css/Content.css';

function Content({ heading, children, button, summary, className }) {
  const history = useNavigate();

  const handleClick = () => {
    history(button.path);
  };

  return (
    <Row className={cn('content', className)}>
      <Col>
        {button ? (
          <Row>
            {heading && (
              <Col className="align-self-center">
                <h6 className="heading">{heading}</h6>
                {summary && <p>{summary}</p>}
              </Col>
            )}
            <Col className="float-right text-end mb-2">
              {button.name && button.path ? (
                <Button variant="light" onClick={handleClick}>
                  {button.name}
                </Button>
              ) : null}
            </Col>
          </Row>
        ) : (
          heading && (
            <>
              <h6 className="heading">{heading}</h6>
              {summary && <p className="mb-4">{summary}</p>}
            </>
          )
        )}
        {children}
      </Col>
    </Row>
  );
}

export default Content;
