import dayjs from 'dayjs';
import { Card, Col, Row } from 'react-bootstrap';

import '@css/TableData.css';

import { IconExternal } from '@assets/icons';

import AlertBox from '@components/alert/AlertBox';
import AssetDetailsPreview from '@components/asset/AssetDetailsPreview';
import Content from '@components/content/Content';
import StatusBadge from '@components/status/StatusBadge';

const getMetadata = (asset) => {
  const { id, owner, provider, type, url, status, error, created } = asset || {};
  const hasError = Boolean(error);

  return Object.entries({
    'Asset ID': id,
    Status: <StatusBadge status={status || 'loading'} />,
    Owner: owner,
    Provider: provider,
    Type: type,
    URL: !hasError && url && (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
        <IconExternal />
      </a>
    ),
    Created: dayjs(created).format('DD MMM YYYY HH:mm:ss.SSS A'),
  }).filter(([, value]) => value);
};

function ServeAssetDetails({ asset }) {
  const { error, url } = asset || {};

  const assetMetaData = getMetadata(asset);

  return (
    <Content heading="MEDIA" className="asset-listing mt-4">
      <Card>
        <Card.Body>
          {error && <AlertBox type="error" message={error} className="mb-3" />}
          <Row>
            <Col xs={12} md={6}>
              <AssetDetailsPreview url={url} />
            </Col>
            <Col xs={12} md={6}>
              <table className="metadata">
                <tbody>
                  {assetMetaData.map(([label, value]) => (
                    <tr key={label}>
                      <td className="metadata__key">{label}</td>
                      <td className="metadata__value">{value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Content>
  );
}

export default ServeAssetDetails;
