import { groupBy, mapValues, orderBy } from 'lodash-es';

import OperationStrategy from '@api/utils/OperationStrategy';

import configs from '@constants/ConsoleOperationConfigs';

const orderedConfigs = orderBy(
  Object.entries(configs),
  [([, config]) => config.name.includes('Shotstack'), ([, config]) => config.api],
  ['desc', 'asc']
);

const groupedConfigs = groupBy(orderedConfigs, ([, config]) => config.api);

const operationStrategies = mapValues(groupedConfigs, (configEntries) => {
  return configEntries.reduce((acc, [key, config]) => {
    acc[key] = new OperationStrategy({ config });
    return acc;
  }, {});
});

export default operationStrategies;
