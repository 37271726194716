import qs from 'qs';
import { useEffect, useState } from 'react';

import useApi from '@hooks/useApi';

import RequestStatus from '@constants/RequestStatus';

const useGetUsageData = (params) => {
  const { path, filter } = params;
  const [loaded, setLoaded] = useState(false);

  const { data, request, status, tokenLoaded } = useApi({
    api: 'api',
    ignoreSelectedStage: params?.ignoreSelectedStage,
  });

  useEffect(() => {
    async function fetchData() {
      await request({
        method: 'get',
        path,
        query: qs.stringify(filter),
      });
    }

    if (tokenLoaded) {
      fetchData();
    }
  }, [tokenLoaded]);

  useEffect(() => {
    if (status === RequestStatus.SUCCESS || status === RequestStatus.FAILED || status === RequestStatus.NOT_FOUND) {
      setLoaded(true);
    } else {
      setLoaded(false);
    }
  }, [status]);

  return {
    data,
    loaded,
    status,
  };
};

export default useGetUsageData;
