import config from '@config';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useRecoilState, useRecoilValue } from 'recoil';

import { IconClipboard, IconMinusCircle } from '@assets/icons';

import RenderPreviewAudio from '@feature/studio/render/RenderPreviewAudio';
import RenderPreviewImage from '@feature/studio/render/RenderPreviewImage';
import RenderPreviewStatus from '@feature/studio/render/RenderPreviewStatus';
import RenderPreviewVideo from '@feature/studio/render/RenderPreviewVideo';

import LinkElement from '@components/atoms/LinkElement';

import { authTokenAtom } from '@store/Auth';
import { rendersAtom } from '@store/Renders';

import { getRenderErrorMessage } from '@utils/errors/render';
import getFileTypeFromUrl from '@utils/getFileTypeFromUrl';

const RENDER_ENDPOINT = 'render';
const POLL_DELAY = 2500;
const STATUS_DONE = 'done';
const STATUS_QUEUED = 'queued';
const STATUS_FAILED = 'failed';

const RenderPreviewComponentMap = {
  audio: RenderPreviewAudio,
  image: RenderPreviewImage,
  video: RenderPreviewVideo,
};

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const createPoll = (setStatus, abortController) => {
  const pollRef = axios.create();

  pollRef.interceptors.response.use(async (response) => {
    const { status } = response.data.response;
    setStatus(status);

    if (status === STATUS_DONE || status === STATUS_FAILED) {
      return response;
    }

    await delay(POLL_DELAY);

    return pollRef.request({
      ...response.config,
      signal: abortController.signal,
    });
  });

  return pollRef;
};

const pollRender = (pollRef, token, stage, renderId, signal) => {
  if (!pollRef) return;

  return pollRef.get(`${config.edit.url}${RENDER_ENDPOINT}/${renderId}?data=false`, {
    headers: {
      Authorization: `Bearer ${token}`,
      stage,
    },
    signal,
  });
};

function RenderPreview({ renderData, handleErrors }) {
  const { id: renderId, stage } = renderData;
  const token = useRecoilValue(authTokenAtom);
  const [renders, setRenders] = useRecoilState(rendersAtom);
  const [status, setStatus] = useState('submitted');
  const [render, setRender] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [errorSent, setErrorSent] = useState(false);
  const pollRef = useRef(null);
  const abortController = useRef(null);

  const setRenderComplete = ({ id, url }) => {
    setRender({
      id,
      url,
    });
    setStatus(STATUS_DONE);
    pollRef.current = null;
  };

  const handleRemoveRender = () => {
    setRenders((currentState) => currentState.filter((renderItem) => renderItem.id !== render.id));
  };

  useEffect(() => {
    if (!errorSent && errorMessage) {
      const message = typeof errorMessage === 'object' && errorMessage.message ? errorMessage.message : errorMessage;
      handleErrors(`<b>${message}</b>`);
      setErrorSent(true);
    }
  }, [handleErrors, errorMessage, errorSent]);

  useEffect(() => {
    abortController.current = new AbortController();

    async function fetchRender() {
      try {
        if (!token || !token.length) {
          throw new Error('No token');
        }
        const renderIndex = renders.findIndex((renderItem) => renderItem.id === renderId);
        const { url: renderUrl } = renders[renderIndex] || {};

        if (renderUrl) {
          setRenderComplete({ id: renderId, url: renderUrl });
          return;
        }

        if (!pollRef.current) {
          pollRef.current = createPoll(setStatus, abortController.current);
        }

        setStatus(STATUS_QUEUED);
        await delay(POLL_DELAY);

        const response = await pollRender(pollRef.current, token, stage, renderId, abortController.current.signal);
        const { status: renderStatus, error, id, url } = response?.data?.response || {};

        if (renderStatus === STATUS_FAILED) {
          setErrorMessage(error);
          pollRef.current = null;
        }

        setRender({
          id,
          url,
        });

        setRenders((currentState) => {
          const updatedState = currentState.map((renderItem) =>
            renderItem.id === id
              ? {
                  ...renderItem,
                  url,
                }
              : renderItem
          );

          return updatedState;
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          setErrorMessage(getRenderErrorMessage(error));
          setStatus(STATUS_FAILED);
          pollRef.current = null;
        }
      }
    }

    fetchRender();

    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
      pollRef.current = null;
    };
  }, [token, renderId, stage, setRenders, renders]);

  if (status === STATUS_DONE && render) {
    const fileTypeFromUrl = getFileTypeFromUrl(render.url);
    const Component = RenderPreviewComponentMap[fileTypeFromUrl];
    if (Component) {
      return (
        <div className="render-preview">
          <Component render={render} />

          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <Badge className="bg-subtle">Render ID: {render.id}</Badge>
              <CopyToClipboard text={render.id}>
                <Button className="btn-copy ms-1" title="Copy ID clipboard" type="button" variant="light">
                  <IconClipboard size="14" />
                </Button>
              </CopyToClipboard>
            </div>

            <div className="d-flex align-items-center gap-2">
              <Button variant="light" size="sm" onClick={handleRemoveRender} title="Remove render">
                <IconMinusCircle size="14" />
              </Button>
              <LinkElement type="button" to={render.url} variant="light" external size="sm">
                Open
              </LinkElement>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  return status && status !== STATUS_DONE && <RenderPreviewStatus status={status} />;
}

export default RenderPreview;
