import Papa from 'papaparse';
import { useRef, useState } from 'react';

import ButtonElement from '@components/atoms/ButtonElement';

function CSVUploader({ text, onUpload }) {
  const [error, setError] = useState(null);
  const fileInput = useRef(null);

  const handleBrowseFiles = (e) => {
    e.stopPropagation();
    fileInput.current.click();
  };

  const handleFileChange = () => {
    setError(null);

    const [file] = fileInput.current?.files || [];
    if (!file) {
      return;
    }

    if (!file) {
      setError('No file selected!');
      return;
    }

    Papa.parse(file, {
      skipEmptyLines: true,
      complete: ({ data }) => onUpload(data),
      error: (err) => {
        console.error(err);
        setError(err.message);
      },
    });
  };

  return (
    <div>
      <ButtonElement className="whitespace-nowrap" handleEvent={handleBrowseFiles} variant="primary">
        {text || 'Upload'}
      </ButtonElement>
      <input ref={fileInput} type="file" accept=".csv" onChange={handleFileChange} style={{ display: 'none' }} />
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
}

export default CSVUploader;
